import React, { useEffect, useState } from "react";
import { ProfileCard, FormNavigator, Stepper, NotesModal, LoadingIndicator } from "../../../components/";
import { Success, WarningIcon } from "../../../assets/images/";
import { Root } from "../../../stores";
import medicalConsentSchema from "./medicalDetailsValidation";
import { dataStorePageKeys } from "../../../stores/dataStore";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { differenceInYears, format } from "date-fns";
import { inject, observer } from "mobx-react";
import SignatureCanvas from "react-signature-canvas";

import {
  Grid,
  Typography,
  Card,
  FormControlLabel,
  Container,
  OutlinedInput,
  Button,
  FormLabel,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  FormControl,
  Link,
  Checkbox,
} from "@material-ui/core";
import { useAuth } from "oidc-react";
import { useHistory } from "react-router";
import { ASSESSMENTDETAILS, DASHBOARD, INFORMEDCONSENT } from "../../../routes/paths";
import ApiService from "../../../services/ApiService";
import axios from "axios";
import { tokenInterceptor } from "../../../services/auth/tokenInterceptor";
import { Upload } from "./FileUpload";

interface MedicalDetailsProps {
  rootStore?: Root;
  clearanceGenerated: boolean;
}
export enum eMedicalClearanceRequired {
  "SELECT" = "Select",
  "YES" = "Yes - MC Required",
  "NO" = "No - MC Required",
}
export enum eMedicalClearanceType {
  "SELECT" = "Select MC",
  "EXISTINGMC" = "MC Required- Existing",
  "PAPERMC" = "MC Required- Paper",
  "NOTPROVIDED" = "MC Required- Not Provided",
  "NOTREQUIRED" = "MC Not Required",
}
export interface MCRequired {
  isRequired: boolean;
  condition: string;
  conditionText: string;
  noteItems: NoteItem[];
}
export interface NoteItem {
  isDeleted: boolean;
  note: string;
  year: string;
  month: string;
  monthDiff: number | undefined;
  mcType: string;
  viewedMC: string;
  isMCRequired: boolean;
}
export class MedicalNote {
  public static noteItem(): NoteItem {
    const isDeleted = false;
    const note = "";
    const year = "";
    const month = "";
    const monthDiff = undefined;
    const mcType = eMedicalClearanceType.SELECT;
    const viewedMC = "";
    const isMCRequired = true;
    return { isDeleted, note, year, month, monthDiff, mcType, viewedMC, isMCRequired };
  }
}

export interface PaperMC {
  condition: string;
  conditionText: string;
  status: string;
}
export interface PaperUploadMC {
  condition: string;
  id: string;
  fileName: string;
  medicalClearanceId: string;
  isCancelled: boolean;
}
export class MedicalClearanceRequired {
  public static item(conditionName: string, conditionTextStr: string, noteItemList: any[], isRequiredMC: boolean): MCRequired {
    const condition = conditionName;
    const conditionText = conditionTextStr;
    const noteItems = noteItemList;
    const isRequired = isRequiredMC;
    return { condition, conditionText, noteItems, isRequired };
  }
  public static paperMC(condition: string, conditionText: string, status: string): PaperMC {
    return { condition, conditionText, status };
  }
  public static paperUploadMC(condition: string, id: string, fileName: string, medicalClearanceId: string, isCancelled: boolean): PaperUploadMC {
    return { condition, id, fileName, medicalClearanceId, isCancelled };
  }
}
const MedicalDetails = inject("rootStore")(
  observer((props: MedicalDetailsProps) => {
    let pefaAssessment: any;
    let prevData: any = null;
    let referralDetails: any = null;
    let participantAgeYears = 0;
    let participantGender = "";
    let endAssessment: any = null;
    const history = useHistory();
    const auth = useAuth();
    tokenInterceptor(axios, null, auth);

    let participantSignature: SignatureCanvas | null = null;

    useEffect(() => {
      if (state.isLoading) {
        //Populate the signature pads if there is any existing signatures.
        participantSignature?.fromDataURL(state.participantSignature);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [participantSignature]);

    if (props.rootStore?.pefaStore.selectedPefa) {
      pefaAssessment = props.rootStore?.pefaStore.selectedPefa[0];
      if (props.rootStore?.dataStore.pefaData.hasOwnProperty(pefaAssessment.id)) {
        prevData = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.MEDICAL_DETAILS);
        referralDetails = props.rootStore?.dataStore.getCheckInData(pefaAssessment.id, dataStorePageKeys.REFERRAL_DETAILS);
        endAssessment = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.END_ASSESSMENT);
        // user most likely checked in on a different device, read the pefa booking check information
        if (referralDetails == null && pefaAssessment.checkinInformation) {
          referralDetails = JSON.parse(pefaAssessment.checkinInformation.content);
          props.rootStore?.dataStore.saveCheckInData(referralDetails, pefaAssessment.id, dataStorePageKeys.REFERRAL_DETAILS);
          if (pefaAssessment.informedConsent) {
            props.rootStore?.dataStore.saveCheckInData(JSON.parse(pefaAssessment.informedConsent), pefaAssessment.id, dataStorePageKeys.INFORMED_CONSENT);
          }
        }
        participantAgeYears = differenceInYears(new Date(), new Date(referralDetails?.dob ?? pefaAssessment.pefArequest.worker.dateOfBirth));
      } else {
        participantAgeYears = differenceInYears(new Date(), new Date(pefaAssessment.pefArequest.worker.dateOfBirth));
      }
      participantGender = referralDetails?.biologicalSex ?? pefaAssessment.pefArequest.worker.gender?.toUpperCase();
    } else {
      history.push(DASHBOARD);
      return <></>;
    }
    const defaultNoteItem = MedicalNote.noteItem();
    const mcs = props.rootStore?.pefaStore.getSelectedPefaMedicalClearances();
    const medicalClearances = mcs
      .filter((x) => x.medicalCertificate !== null && x.otherInfo?.indexOf("Paper") < 0)
      .map((mc) => ({
        ...mc,
        unaccepted: false,
        otherInfo: mc.otherInfo && mc.otherInfo.indexOf("|") > -1 ? mc.otherInfo.substring(0, mc.otherInfo.length - 1).replaceAll("|", ", ") : mc.otherInfo,
      }));
    const bpDia = pefaAssessment.pefArequest.company.bpDia || 145;
    const bpSys = pefaAssessment.pefArequest.company.bpSys || 95;
    const bpMessageLimit = "Yes - MC required if higher than " + bpDia + "/" + bpSys + " mmHg during the PEFA";
    const yearData = props.rootStore?.pefaStore.getLast20Years();
    const monthData = props.rootStore?.pefaStore.getMonths();
    const { register, handleSubmit, errors, setValue } = useForm({
      resolver: yupResolver(medicalConsentSchema),
    });
    // eslint-disable-next-line @typescript-eslint/no-redeclare
    const [state, setState] = useState({
      isLoading: true,
      isUploading: false,
      clearanceGenerated: true,
      clearanceDownloaded: prevData ? prevData.clearanceDownloaded : false,
      enabledEndPEFA: false,
      mcRequiredList: prevData ? prevData.mcRequiredList : [],
      //MC not required
      highBloodPressure: prevData ? prevData.highBloodPressure : "",
      highBloodPressureNote: prevData ? prevData.highBloodPressureNote : "",
      highBloodPressureMCType: prevData ? prevData.highBloodPressureMCType : "",
      highBloodPressureViewedMC: prevData ? prevData.highBloodPressureViewedMC : "",
      lowBloodPressure: prevData ? prevData.lowBloodPressure : "",
      lowBloodPressureNote: prevData ? prevData.lowBloodPressureNote : "",
      arthritis: prevData ? prevData.arthritis : "",
      arthritisNote: prevData ? prevData.arthritisNote : "",
      cancer: prevData ? prevData.cancer : "",
      cancerNote: prevData ? prevData.cancerNote : "",
      asthma: prevData ? prevData.asthma : "",
      otherLungConditionNote: prevData ? prevData.otherLungConditionNote : "",
      neurological: prevData ? prevData.neurological : "",
      neurologicalNote: prevData ? prevData.neurologicalNote : "",
      infectious: prevData ? prevData.infectious : "",
      infectiousConditionNote: prevData ? prevData.infectiousConditionNote : "",
      other: prevData ? prevData.other : "",
      otherNote: prevData ? prevData.otherNote : "",
      //with MC required
      pacemaker: prevData ? prevData.pacemaker : "",
      otherHeartCondition: prevData ? prevData.otherHeartCondition : "",
      boneSurgeries: prevData ? prevData.boneSurgeries : "",
      fractures: prevData ? prevData.fractures : "",
      dislocations: prevData ? prevData.dislocations : "",
      hernia: prevData ? prevData.hernia : "",
      otherSurgeries: prevData ? prevData.otherSurgeries : "",
      epilepsy: prevData ? prevData.epilepsy : "",
      epilepsyMCAssessor: prevData ? prevData.epilepsyMCAssessor : eMedicalClearanceRequired.SELECT,
      epilepsyNote: prevData ? prevData.epilepsyNote : "",
      epilepsyMCType: prevData ? prevData.epilepsyMCType : eMedicalClearanceType.SELECT,
      epilepsyViewedMC: prevData ? prevData.epilepsyViewedMC : "",
      diabetes: prevData ? prevData.diabetes : "",
      diabetesMCAssessor: prevData ? prevData.diabetesMCAssessor : eMedicalClearanceRequired.SELECT,
      diabetesNote: prevData ? prevData.diabetesNote : "",
      diabetesMCType: prevData ? prevData.diabetesMCType : eMedicalClearanceType.SELECT,
      diabetesViewedMC: prevData ? prevData.diabetesViewedMC : "",
      anyInjuries6wks: prevData ? prevData.anyInjuries6wks : "",
      medCertOrWrkRstrctns: prevData ? prevData.medCertOrWrkRstrctns : "",
      pregnancy: prevData ? prevData.pregnancy : "",
      pregnancyMCType: prevData ? prevData.pregnancyMCType : eMedicalClearanceType.SELECT,
      pregnancyViewedMC: prevData ? prevData.pregnancyViewedMC : "",
      pregnancyNote: prevData ? prevData.pregnancyNote : "",
      pacemaker_noteItems: prevData ? prevData.pacemaker_noteItems : [defaultNoteItem],
      otherHeartCondition_noteItems: prevData ? prevData.otherHeartCondition_noteItems : [defaultNoteItem],
      hernia_noteItems: prevData ? prevData.hernia_noteItems : [defaultNoteItem],
      boneSurgeries_noteItems: prevData ? prevData.boneSurgeries_noteItems : [defaultNoteItem],
      medCertOrWrkRstrctns_noteItems: prevData ? prevData.medCertOrWrkRstrctns_noteItems : [defaultNoteItem],
      anyInjuries6wks_noteItems: prevData ? prevData.anyInjuries6wks_noteItems : [defaultNoteItem],
      fractures_noteItems: prevData ? prevData.fractures_noteItems : [defaultNoteItem],
      dislocations_noteItems: prevData ? prevData.dislocations_noteItems : [defaultNoteItem],
      otherSurgeries_noteItems: prevData ? prevData.otherSurgeries_noteItems : [defaultNoteItem],

      participantName: pefaAssessment.pefArequest.worker.firstName + " " + pefaAssessment.pefArequest.worker.lastName,

      participantSignature: prevData ? prevData.participantSignature : "",

      highBloodPressureMCs:
        prevData && prevData.highBloodPressureMCs?.length > 0 ? prevData.highBloodPressureMCs : medicalClearances?.filter((x) => x.condition?.indexOf("High blood") > -1),
      lowBloodPressureMCs:
        prevData && prevData.lowBloodPressureMCs?.length > 0 ? prevData.lowBloodPressureMCs : medicalClearances?.filter((x) => x.condition?.indexOf("Low blood") > -1),
      arthritisMCs: prevData && prevData.arthritisMCs?.length > 0 ? prevData.arthritisMCs : medicalClearances?.filter((x) => x.condition?.indexOf("Arthritis") > -1),
      cancerMCs: prevData && prevData.cancerMCs?.length > 0 ? prevData.cancerMCs : medicalClearances?.filter((x) => x.condition?.indexOf("Cancer") > -1),
      asthmaMCs:
        prevData && prevData.asthmaMCs?.length > 0
          ? prevData.asthmaMCs
          : medicalClearances?.filter((x) => x.condition?.indexOf("Asthma") > -1 || x.condition?.indexOf("other lung condition") > -1),
      neurologicalMCs:
        prevData && prevData.neurologicalMCs?.length > 0 ? prevData.neurologicalMCs : medicalClearances?.filter((x) => x.condition?.indexOf("Neurological conditions") > -1),
      infectiousMCs: prevData && prevData.infectiousMCs?.length > 0 ? prevData.infectiousMCs : medicalClearances?.filter((x) => x.condition?.indexOf("Infectious Condition") > -1),
      pacemakerMCs: prevData && prevData.pacemakerMCs?.length > 0 ? prevData.pacemakerMCs : medicalClearances?.filter((x) => x.condition?.indexOf("Pacemaker") > -1),
      otherHeartConditionMCs:
        prevData && prevData.otherHeartConditionMCs?.length > 0 ? prevData.otherHeartConditionMCs : medicalClearances?.filter((x) => x.condition?.indexOf("Heart") > -1),
      boneSurgeriesMCs:
        prevData && prevData.boneSurgeriesMCs?.length > 0
          ? prevData.boneSurgeriesMCs
          : medicalClearances?.filter((x) => x.condition?.indexOf("Bone") > -1 || x.condition?.indexOf("Joint") > -1),
      fracturesMCs: prevData && prevData.fracturesMCs?.length > 0 ? prevData.fracturesMCs : medicalClearances?.filter((x) => x.condition?.indexOf("Fractures") > -1),
      dislocationsMCs: prevData && prevData.dislocationsMCs?.length > 0 ? prevData.dislocationsMCs : medicalClearances?.filter((x) => x.condition?.indexOf("Dislocation") > -1),
      herniaMCs: prevData && prevData.herniaMCs?.length > 0 ? prevData.herniaMCs : medicalClearances?.filter((x) => x.condition?.indexOf("Hernia") > -1),
      otherSurgeriesMCs:
        prevData && prevData.otherSurgeriesMCs?.length > 0 ? prevData.otherSurgeriesMCs : medicalClearances?.filter((x) => x.condition?.indexOf("Any other Surgeries") > -1),
      epilepsyMCs: prevData && prevData.epilepsyMCs?.length > 0 ? prevData.epilepsyMCs : medicalClearances?.filter((x) => x.condition?.indexOf("Epilepsy") > -1),
      diabetesMCs: prevData && prevData.diabetesMCs?.length > 0 ? prevData.diabetesMCs : medicalClearances?.filter((x) => x.condition?.indexOf("Diabetes") > -1),
      anyInjuries6wksMCs:
        prevData && prevData.anyInjuries6wksMCs?.length > 0
          ? prevData.anyInjuries6wksMCs
          : medicalClearances?.filter((x) => x.condition?.indexOf("Injury in past 6wks with treatment") > -1),
      medCertOrWrkRstrctnsMCs:
        prevData && prevData.medCertOrWrkRstrctnsMCs?.length > 0
          ? prevData.medCertOrWrkRstrctnsMCs
          : medicalClearances?.filter((x) => x.condition?.indexOf("Current medical certificate with work restrictions") > -1),
      pregnancyMCs: prevData && prevData.pregnancyMCs?.length > 0 ? prevData.pregnancyMCs : medicalClearances?.filter((x) => x.condition?.indexOf("Pregnancy") > -1),
      otherMCs: prevData && prevData.otherMCs?.length > 0 ? prevData.otherMCs : medicalClearances?.filter((x) => x.condition?.indexOf("Other") > -1),

      highBloodPressurePaperMCs: prevData && prevData.highBloodPressurePaperMCs?.length > 0 ? prevData.highBloodPressurePaperMCs : [],
      lowBloodPressurePaperMCs: prevData && prevData.lowBloodPressurePaperMCs?.length > 0 ? prevData.lowBloodPressurePaperMCs : [],
      arthritisPaperMCs: prevData && prevData.arthritisPaperMCs?.length > 0 ? prevData.arthritisPaperMCs : [],
      cancerPaperMCs: prevData && prevData.cancerPaperMCs?.length > 0 ? prevData.cancerPaperMCs : [],
      asthmaPaperMCs: prevData && prevData.asthmaPaperMCs?.length > 0 ? prevData.asthmaPaperMCs : [],
      neurologicalPaperMCs: prevData && prevData.neurologicalPaperMCs?.length > 0 ? prevData.neurologicalPaperMCs : [],
      infectiousPaperMCs: prevData && prevData.infectiousPaperMCs?.length > 0 ? prevData.infectiousPaperMCs : [],
      pacemakerPaperMCs: prevData && prevData.pacemakerPaperMCs?.length > 0 ? prevData.pacemakerPaperMCs : [],
      otherHeartConditionPaperMCs: prevData && prevData.otherHeartConditionPaperMCs?.length > 0 ? prevData.otherHeartConditionPaperMCs : [],
      boneSurgeriesPaperMCs: prevData && prevData.boneSurgeriesPaperMCs?.length > 0 ? prevData.boneSurgeriesPaperMCs : [],
      fracturesPaperMCs: prevData && prevData.fracturesPaperMCs?.length > 0 ? prevData.fracturesPaperMCs : [],
      dislocationsPaperMCs: prevData && prevData.dislocationsPaperMCs?.length > 0 ? prevData.dislocationsPaperMCs : [],
      herniaPaperMCs: prevData && prevData.herniaPaperMCs?.length > 0 ? prevData.herniaPaperMCs : [],
      otherSurgeriesPaperMCs: prevData && prevData.otherSurgeriesPaperMCs?.length > 0 ? prevData.otherSurgeriesPaperMCs : [],
      epilepsyPaperMCs: prevData && prevData.epilepsyPaperMCs?.length > 0 ? prevData.epilepsyPaperMCs : [],
      diabetesPaperMCs: prevData && prevData.diabetesPaperMCs?.length > 0 ? prevData.diabetesPaperMCs : [],
      anyInjuries6wksPaperMCs: prevData && prevData.anyInjuries6wksPaperMCs?.length > 0 ? prevData.anyInjuries6wksPaperMCs : [],
      medCertOrWrkRstrctnsPaperMCs: prevData && prevData.medCertOrWrkRstrctnsPaperMCs?.length > 0 ? prevData.medCertOrWrkRstrctnsPaperMCs : [],
      pregnancyPaperMCs: prevData && prevData.pregnancyPaperMCs?.length > 0 ? prevData.pregnancyPaperMCs : [],
      otherPaperMCs: prevData && prevData.otherPaperMCs?.length > 0 ? prevData.otherPaperMCs : [],

      medicalClearanceRequired: prevData ? prevData.medicalClearanceRequired : false,
      medicalClearanceKeys: prevData ? prevData.medicalClearanceKeys : [],
      medicalPractitioner: prevData ? prevData.medicalPractitioner : "",
      medicalPractice: prevData ? prevData.medicalPractice : "",
      clearanceRequired: false,
      dateToday: format(new Date(), "dd-LLL-y"),
    } as { [x: string]: any });

    useEffect(() => {
      const timeout = setTimeout(() => {
        props.rootStore?.dataStore.savePartialData(state, pefaAssessment.id, dataStorePageKeys.MEDICAL_DETAILS);
      }, 500);

      return () => clearTimeout(timeout);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      state.highBloodPressure,
      state.highBloodPressureNote,
      state.highBloodPressureMCType,
      state.highBloodPressureViewedMC,
      state.lowBloodPressure,
      state.lowBloodPressureNote,
      state.participantName,
      state.pacemaker,
      state.pacemakerNote,
      state.otherHeartCondition,
      state.boneSurgeries,
      state.otherSurgeries,
      state.otherSurgeriesNote,
      state.surgeries,
      state.fractures,
      state.dislocations,
      state.hernia,
      state.arthritis,
      state.arthritisNote,
      state.asthma,
      state.otherLungConditionNote,
      state.cancer,
      state.cancerNote,
      state.epilepsy,
      state.epilepsyNote,
      state.epilepsyMCAssessor,
      state.epilepsyMCType,
      state.epilepsyViewedMC,
      state.diabetes,
      state.diabetesNote,
      state.diabetesMCAssessor,
      state.diabetesMCType,
      state.diabetesViewedMC,
      state.infectious,
      state.infectiousConditionNote,
      state.other,
      state.otherNote,
      state.anyInjuries6wks,
      state.medCertOrWrkRstrctns,
      state.pregnancy,
      state.pregnancyMCType,
      state.pregnancyViewedMC,
      state.pregnancyNote,
      state.pacemaker_noteItems,
      state.otherHeartCondition_noteItems,
      state.hernia_noteItems,
      state.boneSurgeries_noteItems,
      state.medCertOrWrkRstrctns_noteItems,
      state.anyInjuries6wks_noteItems,
      state.fractures_noteItems,
      state.dislocations_noteItems,
      state.otherSurgeries_noteItems,
      state.medication,
      state.additionalInformation,
      state.participantName,
      state.participantSignature,
      state.medicalPractitioner,
      state.medicalPractice,
      state.highBloodPressureMCs,
      state.lowBloodPressureMCs,
      state.arthritisMCs,
      state.cancerMCs,
      state.asthmaMCs,
      state.neurologicalMCs,
      state.infectiousMCs,
      state.pacemakerMCs,
      state.otherHeartConditionMCs,
      state.boneSurgeriesMCs,
      state.fracturesMCs,
      state.dislocationsMCs,
      state.herniaMCs,
      state.otherSurgeriesMCs,
      state.epilepsyMCs,
      state.diabetesMCs,
      state.anyInjuries6wksMCs,
      state.medCertOrWrkRstrctnsMCs,
      state.pregnancyMCs,
      state.otherMCs,
      state.highBloodPressurePaperMCs,
      state.lowBloodPressurePaperMCs,
      state.arthritisPaperMCs,
      state.cancerPaperMCs,
      state.asthmaPaperMCs,
      state.neurologicalPaperMCs,
      state.infectiousPaperMCs,
      state.pacemakerPaperMCs,
      state.otherHeartConditionPaperMCs,
      state.boneSurgeriesPaperMCs,
      state.fracturesPaperMCs,
      state.dislocationsPaperMCs,
      state.herniaPaperMCs,
      state.otherSurgeriesPaperMCs,
      state.epilepsyPaperMCs,
      state.diabetesPaperMCs,
      state.anyInjuries6wksPaperMCs,
      state.medCertOrWrkRstrctnsPaperMCs,
      state.pregnancyPaperMCs,
      state.otherPaperMCs,
      state.medicalClearanceRequired,
      state.medicalClearanceKeys,
      state.medicalPractitioner,
      state.medicalPractice,
      state.clearanceRequired,
      state.mcRequiredList,
      state.clearanceDownloaded,
      state.enabledEndPEFA,
    ]);

    const handleValueChange = (event: any) => {
      event.persist();
      setState((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
        enabledEndPEFA: false,
        clearanceDownloaded: false,
      }));
      if (event.target.value === "denied") {
        setState((prevState) => ({
          ...prevState,
          [`${event.target.name}Note`]: "",
        }));
      }
    };
    const handleClickEdit = (elementName: string) => {
      const element = document.getElementById(elementName);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    };
    const handleHerniaMCRequiredChange = (event: any) => {
      event.persist();
      setState((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
        hernia_noteItems: [defaultNoteItem],
      }));

      if (event.target.value !== "denied") {
        const isRequired = true;
        //check if already existing
        const indexRequired = state.mcRequiredList ? state.mcRequiredList.findIndex((x) => x.condition === "hernia") : -1;
        if (indexRequired > -1) {
          updateMCRequiredList([defaultNoteItem], "hernia", isRequired);
        } else {
          const mcItem = MedicalClearanceRequired.item("hernia", "Hernias", [defaultNoteItem], isRequired);
          const filteredItem = state.mcRequiredList && state.mcRequiredList.length > 0 ? state.mcRequiredList?.filter((x) => x.condition !== "hernia") : [];
          setState((prevState) => ({
            ...prevState,
            enabledEndPEFA: false,
            clearanceDownloaded: false,
            mcRequiredList: [...filteredItem, mcItem],
          }));
        }
      } else {
        // if exists update the list and make it not required
        updateMCRequiredList([defaultNoteItem], "hernia", false);
      }
    };
    const handleOtherMCRequiredChange = (event: any, mcItems: any[], conditionText: string, mcName: string) => {
      event.persist();
      setState((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
      }));
      //resets mcAssessor field
      if (event.target.name === "diabetes" || event.target.name === "epilepsy") {
        setState((prevState) => ({
          ...prevState,
          [`${mcName}MCAssessor`]: eMedicalClearanceRequired.SELECT,
        }));
      }
      if ((event.target.name === "diabetes" || event.target.name === "epilepsy" || event.target.name === "pregnancy") && event.target.value === "denied") {
        setState((prevState) => ({
          ...prevState,
          [`${event.target.name}Note`]: "",
        }));
      }
      if (event.target.value === "yesMCRequired" || event.target.value === eMedicalClearanceRequired.YES) {
        const isRequired = true;
        const mcType = mcItems.length > 0 ? eMedicalClearanceType.EXISTINGMC : eMedicalClearanceType.SELECT;
        const mcItem = MedicalClearanceRequired.item(mcName, conditionText, [], isRequired);
        const filteredItem = state.mcRequiredList && state.mcRequiredList.length > 0 ? state.mcRequiredList?.filter((x) => x.condition !== mcName) : [];
        setState((prevState) => ({
          ...prevState,
          [`${mcName}MCType`]: mcType,
          enabledEndPEFA: false,
          clearanceDownloaded: false,
          mcRequiredList: [...filteredItem, mcItem],
        }));
      } else {
        //check if exists in mcRequiredList
        // if exists update the list and make it not required
        const indexRequired = state.mcRequiredList ? state.mcRequiredList.findIndex((x) => x.condition === mcName) : -1;
        if (indexRequired > -1) {
          const mcRequiredList = state.mcRequiredList;
          mcRequiredList[indexRequired].isRequired = false;
          setState((prevState) => ({
            ...prevState,
            mcRequiredList: mcRequiredList,
            enabledEndPEFA: false,
            clearanceDownloaded: false,
            [`${mcName}MCType`]: eMedicalClearanceType.SELECT,
            [`${mcName}ViewedMC`]: "",
          }));
        }
      }
    };
    const updatePaperMC = (response: any, condition: string, paperMCs: any[]) => {
      if (response) {
        const defaultPaperMC = MedicalClearanceRequired.paperUploadMC(response.condition, response.medicalCertificate.id, response.medicalCertificate.filename, response.id, false);
        const paperMCItems = [...paperMCs, defaultPaperMC];
        setState((prevState) => ({
          ...prevState,
          [`${condition}PaperMCs`]: paperMCItems,
          enabledEndPEFA: false,
          clearanceDownloaded: false,
          isUploading: false,
        }));
      }
    };
    const startUpload = (isUploading: boolean) => {
      if (isUploading) {
        setState((prevState) => ({
          ...prevState,
          isUploading: isUploading,
        }));
      }
    };
    const cancelPaperMC = async (condition: string, id: number, medicalClearanceId: number) => {
      setState((prevState) => ({
        ...prevState,
        [`${condition}PaperMCs`]: [],
        [`${condition}ViewedMC`]: "",
        enabledEndPEFA: false,
        clearanceDownloaded: false,
      }));
      updateMCRequiredList([], condition, true);
      if (id > 0 && medicalClearanceId > 0) {
        await ApiService.cancelPaperMC(id.toString(), pefaAssessment?.pefArequestID, medicalClearanceId.toString());
      }
    };
    const cancelPaperMCNoteItems = async (condition: string, items: NoteItem[], id: number, medicalClearanceId: number) => {
      items.forEach((x) => {
        if (x.mcType === eMedicalClearanceType.PAPERMC && !x.isDeleted) {
          x.viewedMC = "";
          x.isMCRequired = true;
        }
      });
      setState((prevState) => ({
        ...prevState,
        [`${condition}PaperMCs`]: [],
        [`${condition}_noteItems`]: items,
        enabledEndPEFA: false,
        clearanceDownloaded: false,
      }));
      const isRequired = items.filter((x) => x.isMCRequired).length > 0 ? true : false;
      updateMCRequiredList(items, condition, isRequired);
      if (id > 0 && medicalClearanceId > 0) {
        await ApiService.cancelPaperMC(id.toString(), pefaAssessment?.pefArequestID, medicalClearanceId?.toString());
      }
    };
    const handleValueChangeNote = (event: any, index: number, items: NoteItem[], noteItemName: string) => {
      event.persist();
      items[index].note = event.target.value;
      setState((prevState) => ({
        ...prevState,
        [`${noteItemName}`]: items,
      }));
      // if exists update the list with latest note items
      const condition = noteItemName.substring(0, noteItemName.indexOf("_"));
      updateMCRequiredList(items, condition);
    };
    const handleValueChangeYearAndMonth = (items: NoteItem[], condition: string) => {
      updateMCRequiredList(items, condition);
    };
    const updateMCRequiredList = (items: NoteItem[], condition: string, isRequired: boolean | undefined = undefined) => {
      // if exists update the list with latest noteitems
      const indexRequired = state.mcRequiredList ? state.mcRequiredList.findIndex((x) => x.condition === condition) : -1;
      if (indexRequired > -1) {
        const mcRequiredList = state.mcRequiredList;
        mcRequiredList[indexRequired].noteItems = items;
        if (isRequired !== undefined) {
          mcRequiredList[indexRequired].isRequired = isRequired;
        }
        setState((prevState) => ({
          ...prevState,
          mcRequiredList: mcRequiredList,
          enabledEndPEFA: false,
          clearanceDownloaded: false,
        }));
      }
    };
    const isValidMCRequiredList = (): boolean => {
      return (
        state.mcRequiredList?.filter(
          (x) =>
            x.isRequired === true &&
            x.condition !== "pregnancy" &&
            x.condition !== "diabetes" &&
            x.condition !== "epilepsy" &&
            (x.noteItems.length === 0 ||
              (x.noteItems.length > 0 &&
                x.noteItems.filter(
                  (n) =>
                    !n.isDeleted &&
                    n.isMCRequired &&
                    (n.note === "" ||
                      n.year === "" ||
                      n.month === "" ||
                      (n.mcType !== eMedicalClearanceType.NOTPROVIDED && n.viewedMC === "") ||
                      n.mcType === eMedicalClearanceType.SELECT)
                ).length > 0))
        ).length > 0
      );
    };
    const isNotValidForm = (): boolean => {
      return (
        isValidMCRequiredList() ||
        !state.highBloodPressure ||
        (state.highBloodPressureNote === "" && state.highBloodPressure === "yesMC") ||
        (state.highBloodPressure === "yesMC" && state.highBloodPressureMCType !== eMedicalClearanceType.NOTPROVIDED && state.highBloodPressureViewedMC === "") ||
        (state.highBloodPressure === "yesMC" && state.highBloodPressureMCType === eMedicalClearanceType.SELECT) ||
        !state.lowBloodPressure ||
        !state.pacemaker ||
        !state.otherHeartCondition ||
        !state.boneSurgeries ||
        !state.fractures ||
        !state.dislocations ||
        !state.hernia ||
        !state.otherSurgeries ||
        !state.arthritis ||
        (state.arthritisNote === "" && state.arthritis === "yesMCNotRequired") ||
        !state.asthma ||
        (state.otherLungConditionNote === "" && state.asthma === "yesMCNotRequired") ||
        !state.cancer ||
        (state.cancerNote === "" && state.cancer === "yesMCNotRequired") ||
        !state.epilepsy ||
        (state.epilepsyNote === "" && (state.epilepsy === "yesControlled" || state.epilepsy === "yesNotControlled")) ||
        ((state.epilepsyMCAssessor === "" || state.epilepsyMCAssessor === eMedicalClearanceRequired.SELECT) && state.epilepsy === "yesNotControlled") ||
        !state.neurological ||
        (state.neurologicalNote === "" && state.neurological === "yesMCNotRequired") ||
        !state.diabetes ||
        (state.diabetesNote === "" && (state.diabetes === "yesControlled" || state.diabetes === "yesNotControlled")) ||
        ((state.diabetesMCAssessor === "" || state.diabetesMCAssessor === eMedicalClearanceRequired.SELECT) && state.diabetes === "yesNotControlled") ||
        !state.infectious ||
        (state.infectiousConditionNote === "" && state.infectious === "yesMCNotRequired") ||
        !state.other ||
        (state.otherNote === "" && state.other === "yesMCNotRequired") ||
        !state.anyInjuries6wks ||
        !state.medCertOrWrkRstrctns ||
        (participantGender === "FEMALE" && state.pregnancy !== null && state.pregnancy === "")
      );
    };
    const handleValueChangeForNoteAndDate = (event: any, mcItems: any[], conditionText: string) => {
      setState((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
        clearanceRequired: false,
      }));
      const noteItem = defaultNoteItem;
      noteItem.mcType = mcItems.length > 0 ? eMedicalClearanceType.EXISTINGMC : eMedicalClearanceType.SELECT;
      if (event.target.value.indexOf("yesMCRequired") > -1) {
        setState((prevState) => ({
          ...prevState,
          [`${event.target.name}_noteItems`]: [noteItem],
        }));
        if (event.target.value === "yesMCRequired") {
          // when yesMCRequired default to be added to mc required list
          const mcItem = MedicalClearanceRequired.item(event.target.name, conditionText, [noteItem], true);
          const filteredItem = state.mcRequiredList && state.mcRequiredList.length > 0 ? state.mcRequiredList?.filter((x) => x.condition !== event.target.name) : [];
          setState((prevState) => ({
            ...prevState,
            mcRequiredList: [...filteredItem, mcItem],
            enabledEndPEFA: false,
            clearanceDownloaded: false,
          }));
        }
      } else {
        //check if exists in mcRequiredList
        // if exists update the list and make it not required
        const indexRequired = state.mcRequiredList ? state.mcRequiredList.findIndex((x) => x.condition === event.target.name) : -1;
        if (indexRequired > -1) {
          const mcRequiredList = state.mcRequiredList;
          mcRequiredList[indexRequired].isRequired = false;
          mcRequiredList[indexRequired].noteItems.forEach((x) => {
            x.isDeleted = true;
            x.isMCRequired = false;
          });
          setState((prevState) => ({
            ...prevState,
            mcRequiredList: mcRequiredList,
            enabledEndPEFA: false,
            clearanceDownloaded: false,
          }));
        }
      }
    };

    const clearParticipantSignature = () => {
      participantSignature?.clear();
      setState((prevState) => ({
        ...prevState,
        participantSignature: "",
      }));
    };

    const handleParticipantSignatureChanged = () => {
      setState((prevState) => ({
        ...prevState,
        participantSignature: participantSignature?.getCanvas().toDataURL("image/png"),
      }));
    };

    const handleCertificateDownload = async (id: number, fileName: string) => {
      await ApiService.getMedicalCertificate(id, fileName);
    };

    const submitForm = (data: any, forwardTarget: any) => {
      const {
        highBloodPressure,
        highBloodPressureMCType,
        highBloodPressureViewedMC,
        lowBloodPressure,
        highBloodPressureNote,
        lowBloodPressureNote,
        participantName,
        pacemaker,
        pacemakerNote,
        otherHeartCondition,
        boneSurgeries,
        otherSurgeries,
        otherSurgeriesNote,
        surgeries,
        surgeriesNote,
        fractures,
        dislocations,
        hernia,
        arthritis,
        arthritisNote,
        asthma,
        otherLungConditionNote,
        cancer,
        cancerNote,
        epilepsy,
        epilepsyNote,
        epilepsyMCAssessor,
        epilepsyMCType,
        epilepsyViewedMC,
        diabetes,
        diabetesNote,
        diabetesMCAssessor,
        diabetesMCType,
        diabetesViewedMC,
        infectious,
        infectiousConditionNote,
        other,
        otherNote,
        anyInjuries6wks,
        medCertOrWrkRstrctns,
        pregnancy,
        pregnancyMCType,
        pregnancyViewedMC,
        pregnancyNote,
        pacemaker_noteItems,
        otherHeartCondition_noteItems,
        hernia_noteItems,
        boneSurgeries_noteItems,
        medCertOrWrkRstrctns_noteItems,
        anyInjuries6wks_noteItems,
        fractures_noteItems,
        dislocations_noteItems,
        otherSurgeries_noteItems,
        medication,
        additionalInformation,
        participantSignature,
        medicalPractitioner,
        medicalPractice,
        highBloodPressureMCs,
        lowBloodPressureMCs,
        arthritisMCs,
        cancerMCs,
        asthmaMCs,
        neurologicalMCs,
        infectiousMCs,
        pacemakerMCs,
        otherHeartConditionMCs,
        boneSurgeriesMCs,
        fracturesMCs,
        dislocationsMCs,
        herniaMCs,
        otherSurgeriesMCs,
        epilepsyMCs,
        diabetesMCs,
        anyInjuries6wksMCs,
        medCertOrWrkRstrctnsMCs,
        pregnancyMCs,
        otherMCs,
        highBloodPressurePaperMCs,
        lowBloodPressurePaperMCs,
        arthritisPaperMCs,
        cancerPaperMCs,
        asthmaPaperMCs,
        neurologicalPaperMCs,
        infectiousPaperMCs,
        pacemakerPaperMCs,
        otherHeartConditionPaperMCs,
        boneSurgeriesPaperMCs,
        fracturesPaperMCs,
        dislocationsPaperMCs,
        herniaPaperMCs,
        otherSurgeriesPaperMCs,
        epilepsyPaperMCs,
        diabetesPaperMCs,
        anyInjuries6wksPaperMCs,
        medCertOrWrkRstrctnsPaperMCs,
        pregnancyPaperMCs,
        otherPaperMCs,
        medicalClearanceRequired,
        medicalClearanceKeys,
        dateToday,
        mcRequiredList,
      } = state;
      props.rootStore?.dataStore.saveData(data, pefaAssessment.id, dataStorePageKeys.MEDICAL_DETAILS);

      props.rootStore?.dataStore.saveData(
        {
          ...data,
          highBloodPressure,
          lowBloodPressure,
          highBloodPressureNote,
          highBloodPressureMCType,
          highBloodPressureViewedMC,
          lowBloodPressureNote,
          participantName,
          pacemaker,
          pacemakerNote,
          otherHeartCondition,
          boneSurgeries,
          otherSurgeries,
          otherSurgeriesNote,
          surgeries,
          surgeriesNote,
          fractures,
          dislocations,
          hernia,
          arthritis,
          arthritisNote,
          asthma,
          otherLungConditionNote,
          cancer,
          cancerNote,
          epilepsy,
          epilepsyNote,
          epilepsyMCAssessor,
          epilepsyMCType,
          epilepsyViewedMC,
          diabetes,
          diabetesNote,
          diabetesMCAssessor,
          diabetesMCType,
          diabetesViewedMC,
          infectious,
          infectiousConditionNote,
          other,
          otherNote,
          anyInjuries6wks,
          medCertOrWrkRstrctns,
          pregnancy,
          pregnancyNote,
          pregnancyMCType,
          pregnancyViewedMC,
          pacemaker_noteItems,
          otherHeartCondition_noteItems,
          hernia_noteItems,
          boneSurgeries_noteItems,
          medCertOrWrkRstrctns_noteItems,
          anyInjuries6wks_noteItems,
          fractures_noteItems,
          dislocations_noteItems,
          otherSurgeries_noteItems,
          medication,
          additionalInformation,
          participantSignature,
          medicalPractitioner,
          medicalPractice,
          highBloodPressureMCs,
          lowBloodPressureMCs,
          arthritisMCs,
          cancerMCs,
          asthmaMCs,
          neurologicalMCs,
          infectiousMCs,
          pacemakerMCs,
          otherHeartConditionMCs,
          boneSurgeriesMCs,
          fracturesMCs,
          dislocationsMCs,
          herniaMCs,
          otherSurgeriesMCs,
          epilepsyMCs,
          diabetesMCs,
          anyInjuries6wksMCs,
          medCertOrWrkRstrctnsMCs,
          pregnancyMCs,
          otherMCs,
          highBloodPressurePaperMCs,
          lowBloodPressurePaperMCs,
          arthritisPaperMCs,
          cancerPaperMCs,
          asthmaPaperMCs,
          neurologicalPaperMCs,
          infectiousPaperMCs,
          pacemakerPaperMCs,
          otherHeartConditionPaperMCs,
          boneSurgeriesPaperMCs,
          fracturesPaperMCs,
          dislocationsPaperMCs,
          herniaPaperMCs,
          otherSurgeriesPaperMCs,
          epilepsyPaperMCs,
          diabetesPaperMCs,
          anyInjuries6wksPaperMCs,
          medCertOrWrkRstrctnsPaperMCs,
          pregnancyPaperMCs,
          otherPaperMCs,
          medicalClearanceRequired,
          medicalClearanceKeys,
          dateToday,
          mcRequiredList,
        },
        pefaAssessment.id,
        dataStorePageKeys.MEDICAL_DETAILS
      );

      if (!state.medicalClearanceRequired) {
        history.push(forwardTarget);
      }
    };
    const handlePrepareMedicalClearance = async (): Promise<void> => {
      //prepare the final list in Dictionary<string, string> format
      let requestData = {};

      state.mcRequiredList
        .filter((x) => x.isRequired)
        .forEach((mc) => {
          //prepare the noteItems in single string
          let mcNoteItem = "";
          if (mc.condition === "pregnancy") {
            mcNoteItem = state.pregnancyNote;
          } else if (mc.condition === "highBloodPressure") {
            mcNoteItem = state.highBloodPressureNote;
          } else if (mc.condition === "epilepsy") {
            mcNoteItem = state.epilepsyNote;
          } else if (mc.condition === "diabetes") {
            mcNoteItem = state.diabetesNote;
          } else {
            const mcNoteItems = mc.noteItems?.filter((x) => !x.isDeleted && x.isMCRequired);
            if (mcNoteItems.length > 0) {
              mcNoteItems.forEach((ni) => {
                mcNoteItem += ni.note + " " + (ni.year === 21 ? "More than 20 years" : ni.year) + " " + ni.month + "|";
              });
            }
          }
          requestData = { ...requestData, [mc.conditionText]: mcNoteItem };
        });
      setState((prevState) => ({ ...prevState, isUploading: true }));
      await ApiService.getEndPEFAMedicalClearanceDocument(
        pefaAssessment.id,
        `MedicalClearance - ${pefaAssessment.pefArequest.worker.firstName + " " + pefaAssessment.pefArequest.worker.lastName}.docx`,
        requestData,
        state.medicalPractitioner,
        state.medicalPractice
      );
      setState((prevState) => ({ ...prevState, clearanceDownloaded: true, isUploading: false }));
    };
    const handleRemoveFromList = (index: number, noteItems: any[], mcName: string) => {
      noteItems[index].isDeleted = true;
      noteItems[index].isMCRequired = false;
      setState((prevState) => ({
        ...prevState,
        [`${mcName}`]: noteItems,
      }));
      // if exists update the list with latest noteitems
      const condition = mcName.substring(0, mcName.indexOf("_"));
      const isRequired = noteItems.filter((x) => !x.isDeleted && x.isMCRequired).length > 0 ? true : false;
      updateMCRequiredList(noteItems, condition, isRequired);
    };
    const handleViewMC = (event: any, index: number, items: any[], mcItems: any[]) => {
      event.persist();
      const condition = event.target.name.substring(0, event.target.name.indexOf("_"));
      const isNoteRequired = event.target.value === "ViewedAccepted" ? false : true;
      items[index].viewedMC = event.target.value;
      items[index].isMCRequired = isNoteRequired;
      setState((prevState) => ({
        ...prevState,
        [`${condition}_noteItems`]: items,
      }));
      const isRequired = items.filter((x) => x.isMCRequired).length > 0 ? true : false;
      updateMCRequiredList(items, condition, isRequired);
    };
    const handleOtherViewMC = (event: any) => {
      event.persist();
      setState((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
      }));
      const condition = event.target.name.substring(0, event.target.name.indexOf("ViewedMC"));
      const isRequired = event.target.value === "ViewedAccepted" ? false : true;
      updateMCRequiredList([], condition, isRequired);
    };
    const handleOtherMCType = (event: any) => {
      event.persist();
      const condition = event.target.name.substring(0, event.target.name.indexOf("MCType"));
      setState((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
        [`${condition}ViewedMC`]: "",
        enabledEndPEFA: false,
        clearanceDownloaded: false,
      }));
    };
    const handleMCType = (event: any, index: number, items: any[]) => {
      event.persist();
      const condition = event.target.name.substring(0, event.target.name.indexOf("_"));
      items[index].mcType = event.target.value;
      items[index].isMCRequired = event.target.value === eMedicalClearanceType.NOTREQUIRED ? false : true;
      items[index].viewedMC = "";
      setState((prevState) => ({
        ...prevState,
        [`${condition}_noteItems`]: items,
      }));
      const isRequired = items.filter((x) => x.isMCRequired).length > 0 ? true : false;
      updateMCRequiredList(items, condition, isRequired);
    };
    const handleAddFromList = (noteItems: any[], mcName: string) => {
      // if exists update the list with latest noteitems
      const newNoteItems = [...noteItems, defaultNoteItem];
      setState((prevState) => ({
        ...prevState,
        [`${mcName}`]: newNoteItems,
      }));
      const condition = mcName.substring(0, mcName.indexOf("_"));
      const isRequired = newNoteItems.filter((x) => x.isMCRequired).length > 0 ? true : false;
      updateMCRequiredList(newNoteItems, condition, isRequired);
    };
    const monthDiff = (year: number, month: number) => {
      const today = new Date(new Date().getFullYear(), new Date().getMonth());
      const inputDate = new Date(year, month);
      return today.getMonth() - inputDate.getMonth() + 12 * (today.getFullYear() - inputDate.getFullYear());
    };
    const calculateYearAndMonthForClearance = (index: number, event: any, condition: string, noteItems: any[], period: number, conditionText: string, mcItems: any[]) => {
      if (event.target.name.indexOf("year") > -1) {
        noteItems[index].year = event.target.value;
        noteItems[index].month = event.target.value !== 21 && noteItems[index].month === "N/A" ? "" : noteItems[index].month;
      } else {
        noteItems[index].month = event.target.value;
      }
      noteItems[index].viewedMC = "";
      noteItems[index].mcType = mcItems.length > 0 ? eMedicalClearanceType.EXISTINGMC : eMedicalClearanceType.SELECT;
      if (noteItems[index].year !== "" && noteItems[index].month !== "") {
        const monthsDifference = monthDiff(Number(noteItems[index].year), monthData.indexOf(noteItems[index].month));
        noteItems[index].monthDiff = monthsDifference;
        noteItems[index].isMCRequired = monthsDifference < period;
        const noteItemRequired = noteItems.filter((x) => x.monthDiff !== null && x.monthDiff < period && x.isMCRequired && !x.isDeleted);
        if (noteItemRequired.length > 0) {
          const isRequired = true;
          const mcItem = MedicalClearanceRequired.item(condition, conditionText, noteItemRequired, isRequired);
          const filteredItem = state.mcRequiredList && state.mcRequiredList.length > 0 ? state.mcRequiredList?.filter((x) => x.condition !== condition) : [];
          setState((prevState) => ({
            ...prevState,
            mcRequiredList: [...filteredItem, mcItem],
            enabledEndPEFA: false,
            clearanceDownloaded: false,
          }));
        } else {
          updateMCRequiredList(noteItemRequired, condition, false);
        }
      }
      setState((prevState) => ({
        ...prevState,
        [`${condition}_noteItems`]: noteItems,
      }));
      setValue(`${event.target.name}`, event.target.value, { shouldValidate: true });
    };
    return (
      <div className="FormStyler">
        <div className="MedicalConsent">
          <Grid item container direction="column" xs={12}>
            <Stepper targetStep={"Assessment Details"} />
            <Container maxWidth={"lg"}>
              <Grid item container xs={12} direction="row">
                <Grid item container sm={12} md={9}>
                  <form>
                    <Card className="uCard" variant="outlined">
                      <Typography variant="h2" className="heroHeading">
                        <img src={Success} alt="Success" /> Medical Details
                      </Typography>
                      <Typography variant="body1">
                        <span>Please read to the participant: </span>
                        "I need to ask you some questions about your medical history. This is only to protect your health and safety during the PEFA. This information will not be
                        released to your current or prospective employer without your consent."
                      </Typography>
                      <div className="longWrapper">
                        <FormControlLabel
                          control={<OutlinedInput color="primary" name="medicalPractitioner" autoComplete="off" className="fullWidth" defaultValue={state.medicalPractitioner} />}
                          label="Medical Practitioner"
                          labelPlacement="top"
                          className="halfWidth"
                          onChange={handleValueChange}
                        />
                        <FormControlLabel
                          control={<OutlinedInput color="primary" name="medicalPractice" autoComplete="off" className="fullWidth" defaultValue={state.medicalPractice} />}
                          label="Practice"
                          labelPlacement="top"
                          className="halfWidth"
                          onChange={handleValueChange}
                        />
                      </div>
                    </Card>

                    <Card className="medicalHistory" variant="outlined">
                      <Typography className="medicalHistoryTitle">Medical History</Typography>
                      {/* High Blood */}
                      <div className="flexer">
                        <FormLabel className="bold blueText height-adjusted" component="legend">
                          High blood Pressure *
                        </FormLabel>
                      </div>
                      <RadioGroup
                        aria-label="high-blood"
                        name="highBloodPressure"
                        className="flexer"
                        row
                        value={state.highBloodPressure}
                        onChange={(event) => {
                          setState((prevState) => ({
                            ...prevState,
                            highBloodPressure: event.target.value,
                            highBloodPressureNote: "",
                            highBloodPressureViewedMC: "",
                            highBloodPressureMCType:
                              event.target.value === "yesMC" && state.highBloodPressureMCs && state.highBloodPressureMCs.length > 0
                                ? eMedicalClearanceType.EXISTINGMC
                                : eMedicalClearanceType.SELECT,
                          }));
                        }}
                      >
                        <FormControlLabel
                          control={<Radio color="primary" name="highBloodPressure" id="highBloodPressureDenied" value="denied" />}
                          value={state.highBloodPressure}
                          inputRef={register}
                          label="Denied"
                        />
                        <FormControlLabel
                          control={<Radio color="primary" name="highBloodPressure" id="highBloodPressureYes" value="yesMC" />}
                          inputRef={register}
                          label={bpMessageLimit}
                        />
                      </RadioGroup>
                      {state.highBloodPressure === "yesMC" ? (
                        <OutlinedInput
                          color="primary"
                          name="highBloodPressureNote"
                          autoComplete="off"
                          className="med-note"
                          inputRef={register}
                          placeholder="If Yes, describe condition here*"
                          defaultValue={state.highBloodPressureNote}
                          onChange={handleValueChange}
                        />
                      ) : (
                        <></>
                      )}
                      {state.highBloodPressure === "yesMC" ? (
                        <RadioGroup
                          aria-label="highBloodPressureViewedMC"
                          name="highBloodPressureViewedMC"
                          className="flexer"
                          row
                          value={state.highBloodPressureViewedMC}
                          onChange={(event) => {
                            setState((prevState) => ({
                              ...prevState,
                              highBloodPressureViewedMC: event.target.value,
                            }));
                          }}
                        >
                          <FormControl variant="outlined" className="selectOveride">
                            <Select
                              name="highBloodPressureMCType"
                              color="primary"
                              className="DayChanger"
                              placeholder="Select"
                              value={state.highBloodPressureMCType}
                              MenuProps={{ PaperProps: { style: { maxHeight: "18%" } } }}
                              onChange={(event) => {
                                setValue(`highBloodPressureMCType`, event.target.value, { shouldValidate: true });
                                setState((prevState) => ({
                                  ...prevState,
                                  highBloodPressureMCType: event.target.value,
                                  highBloodPressureViewedMC: "",
                                }));
                              }}
                            >
                              <MenuItem value={eMedicalClearanceType.SELECT}>{eMedicalClearanceType.SELECT}</MenuItem>
                              {state.highBloodPressureMCs && state.highBloodPressureMCs.length > 0 ? (
                                <MenuItem value={eMedicalClearanceType.EXISTINGMC}>{eMedicalClearanceType.EXISTINGMC}</MenuItem>
                              ) : (
                                <></>
                              )}
                              <MenuItem value={eMedicalClearanceType.PAPERMC}>{eMedicalClearanceType.PAPERMC}</MenuItem>
                              <MenuItem value={eMedicalClearanceType.NOTPROVIDED}>{eMedicalClearanceType.NOTPROVIDED}</MenuItem>
                            </Select>
                            <input type="text" style={{ visibility: "hidden" }} name={`highBloodPressureMCType`} defaultValue={state.highBloodPressureMCType} ref={register} />
                          </FormControl>
                          {state.highBloodPressureMCType === eMedicalClearanceType.EXISTINGMC ||
                          (state.highBloodPressureMCType === eMedicalClearanceType.PAPERMC && state.highBloodPressurePaperMCs.length > 0) ? (
                            <>
                              <FormControlLabel
                                control={<Radio color="primary" name="highBloodPressureViewedMC" id="highBloodPressure_viewedMC_accepted" value="ViewedAccepted" />}
                                inputRef={register}
                                label="MC Viewed and Accepted"
                              />
                              <FormControlLabel
                                control={<Radio color="primary" name="highBloodPressureViewedMC" id="highBloodPressure_viewedMC_notAccepted" value="NotAccepted" />}
                                inputRef={register}
                                label="MC Viewed and Not Accepted"
                              />
                            </>
                          ) : (
                            <></>
                          )}
                          {state.highBloodPressureMCType === eMedicalClearanceType.PAPERMC && (!state.highBloodPressurePaperMCs || state.highBloodPressurePaperMCs.length < 1) ? (
                            <div className="uploadLink">
                              <Upload
                                condition={"highBloodPressure"}
                                conditionText={"High blood Pressure"}
                                paperMCs={state.highBloodPressurePaperMCs}
                                otherInfo={state.highBloodPressureNote}
                                pefaId={pefaAssessment?.pefArequestID}
                                bookingId={pefaAssessment?.id}
                                doctor={state.medicalPractitioner}
                                practice={state.medicalPractice}
                                startUpload={startUpload}
                                handlePaperMC={updatePaperMC}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                        </RadioGroup>
                      ) : (
                        <></>
                      )}
                      {errors.highBloodPressure?.message ? (
                        <div className="invalid-feedback">{errors.highBloodPressure?.message}</div>
                      ) : (state.highBloodPressure === "yesMC" && state.highBloodPressureMCType !== eMedicalClearanceType.NOTPROVIDED && state.highBloodPressureViewedMC === "") ||
                        (state.highBloodPressure === "yesMC" && state.highBloodPressureMCType === eMedicalClearanceType.SELECT) ||
                        (state.highBloodPressure === "yesMC" && state.highBloodPressureNote === "") ? (
                        <div className="invalid-medical">Input is required</div>
                      ) : (
                        <></>
                      )}
                      {state.highBloodPressureMCs && state.highBloodPressureMCs.length > 0 ? (
                        <>
                          {state.highBloodPressureMCs
                            .filter((x) => x.medicalCertificate !== null)
                            .map((medicalClearance: any, index: number) => (
                              <>
                                <div className="viewMedicalConditionFile" key={index}>
                                  <Typography className="medicalConditionName mr-2">
                                    {medicalClearance.condition} {medicalClearance.outcome ? ` - ${medicalClearance.outcome.toUpperCase()} ` : ""}
                                  </Typography>
                                </div>
                                <div className="viewMedicalConditionFile" key={index}>
                                  <Typography className="medicalConditionName ellipsis ml-3 mr-2">{medicalClearance.otherInfo}</Typography>
                                </div>
                                <div className="viewMedicalConditionFile" key={index}>
                                  {medicalClearance.medicalCertificate ? (
                                    <>
                                      <Link
                                        className="viewMC"
                                        underline="always"
                                        onClick={() => handleCertificateDownload(medicalClearance.medicalCertificate.id, medicalClearance.medicalCertificate.filename)}
                                      >
                                        Review Existing Medical Clearance
                                      </Link>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </>
                            ))}
                        </>
                      ) : (
                        <></>
                      )}
                      {state.highBloodPressurePaperMCs && state.highBloodPressurePaperMCs.length > 0 ? (
                        <>
                          {state.highBloodPressurePaperMCs.map((medicalClearance: any, index: number) => (
                            <>
                              <div className="viewMedicalConditionFile" key={index}>
                                <Typography className="medicalConditionName mr-2">{`${medicalClearance.condition} - PaperMC`}</Typography>
                                <Link
                                  className="cancelPaperMC"
                                  underline="always"
                                  onClick={() => cancelPaperMC("highBloodPressure", medicalClearance.id, medicalClearance.medicalClearanceId)}
                                >
                                  Cancel
                                </Link>
                              </div>
                              <div className="viewMedicalConditionFile" key={index}>
                                {medicalClearance ? (
                                  <>
                                    <Link className="viewMC" underline="always" onClick={() => handleCertificateDownload(medicalClearance.id, medicalClearance.fileName)}>
                                      Review Paper Medical Clearance
                                    </Link>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                      <hr className="separator"></hr>
                      {/* High Blood */}
                      {/* Low Blood */}
                      <div className="flexer">
                        <FormLabel className="bold blueText height-adjusted" component="legend">
                          Low blood Pressure*
                        </FormLabel>
                      </div>
                      <RadioGroup aria-label="low-blood" name="lowBloodPressure" className="flexer" row value={state.lowBloodPressure} onChange={handleValueChange}>
                        <FormControlLabel
                          control={<Radio color="primary" name="lowBloodPressure" id="lowBloodDenied" value="denied" />}
                          value={state.lowBloodPressure}
                          inputRef={register}
                          label="Denied"
                        />
                        <FormControlLabel
                          control={<Radio color="primary" name="lowBloodPressure" id="lowBloodYes" value="yesMCNotRequired" />}
                          inputRef={register}
                          label="Yes - MC not required"
                        />
                      </RadioGroup>
                      {state.lowBloodPressure === "yesMCNotRequired" ? (
                        <OutlinedInput
                          color="primary"
                          name="lowBloodPressureNote"
                          autoComplete="off"
                          className="med-note"
                          inputRef={register}
                          placeholder="If Yes, describe condition here"
                          defaultValue={state.lowBloodPressureNote}
                          onChange={handleValueChange}
                        />
                      ) : (
                        <></>
                      )}
                      {errors.lowBloodPressure?.message !== "" ? <div className="invalid-feedback">{errors.lowBloodPressure?.message}</div> : <></>}
                      {state.lowBloodPressureMCs && state.lowBloodPressureMCs.length > 0 ? (
                        <>
                          {state.lowBloodPressureMCs
                            .filter((x) => x.medicalCertificate !== null)
                            .map((medicalClearance: any, index: number) => (
                              <>
                                <div className="viewMedicalConditionFile" key={index}>
                                  <Typography className="medicalConditionName mr-2">
                                    {medicalClearance.condition} {medicalClearance.outcome ? ` - ${medicalClearance.outcome.toUpperCase()} ` : ""}
                                  </Typography>
                                </div>
                                <div className="viewMedicalConditionFile" key={index}>
                                  <Typography className="medicalConditionName ellipsis ml-3 mr-2">{medicalClearance.otherInfo}</Typography>
                                </div>
                                <div className="viewMedicalConditionFile" key={index}>
                                  {medicalClearance.medicalCertificate ? (
                                    <>
                                      <Link
                                        className="viewMC"
                                        underline="always"
                                        onClick={() => handleCertificateDownload(medicalClearance.medicalCertificate.id, medicalClearance.medicalCertificate.filename)}
                                      >
                                        Review Existing Medical Clearance
                                      </Link>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </>
                            ))}
                        </>
                      ) : (
                        <></>
                      )}
                      {state.lowBloodPressurePaperMCs && state.lowBloodPressurePaperMCs.length > 0 ? (
                        <>
                          {state.lowBloodPressurePaperMCs.map((medicalClearance: any, index: number) => (
                            <>
                              <div className="viewMedicalConditionFile" key={index}>
                                <Typography className="medicalConditionName mr-2">{`${medicalClearance.condition} - PaperMC`}</Typography>
                                <Link
                                  className="cancelPaperMC"
                                  underline="always"
                                  onClick={() => cancelPaperMC("lowBloodPressure", medicalClearance.id, medicalClearance.medicalClearanceId)}
                                >
                                  Cancel
                                </Link>
                              </div>
                              <div className="viewMedicalConditionFile" key={index}>
                                {medicalClearance ? (
                                  <>
                                    <Link className="viewMC" underline="always" onClick={() => handleCertificateDownload(medicalClearance.id, medicalClearance.fileName)}>
                                      Review Paper Medical Clearance
                                    </Link>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                      <hr className="separator"></hr>
                      {/* Low Blood */}
                      {/* Pacemaker */}
                      <div className="flexer" id="pacemaker">
                        <FormLabel className="bold blueText height-adjusted" component="legend">
                          Pacemaker*
                        </FormLabel>
                      </div>
                      <RadioGroup
                        aria-label="pacemaker"
                        name="pacemaker"
                        className="flexer"
                        row
                        value={state.pacemaker}
                        onChange={(event) => {
                          handleValueChangeForNoteAndDate(event, state.pacemakerMCs, "Pacemaker");
                        }}
                      >
                        <FormControlLabel
                          control={<Radio color="primary" name="pacemaker" id="pacemakerDenied" value="denied" />}
                          value={state.pacemaker}
                          inputRef={register}
                          label="Denied"
                        />
                        <FormControlLabel
                          control={<Radio color="primary" name="pacemaker" id="pacemakerYes" value="yesMCRequired" />}
                          inputRef={register}
                          label="Yes – MC required"
                        />
                      </RadioGroup>

                      {state.pacemaker === "yesMCRequired" &&
                        state.pacemaker_noteItems.map((element, index) => (
                          <>
                            {!element.isDeleted ? (
                              <>
                                <div className="note-item">
                                  <OutlinedInput
                                    key={index}
                                    color="primary"
                                    name={`pacemaker_noteItems_note${index}`}
                                    value={element.name}
                                    autoComplete="off"
                                    className="small-note"
                                    inputRef={register}
                                    placeholder="If Yes, describe condition here*"
                                    defaultValue={element.note}
                                    onChange={(event) => handleValueChangeNote(event, index, state.pacemaker_noteItems, "pacemaker_noteItems")}
                                  />
                                  <FormControl variant="outlined" className="selectMC">
                                    <Select
                                      name={`pacemaker_noteItems_year${index}`}
                                      color="primary"
                                      className="DayChanger"
                                      placeholder="YYYY"
                                      value={element.year}
                                      MenuProps={{ PaperProps: { style: { maxHeight: "18%" } } }}
                                      onChange={(event) => {
                                        const noteItems = state.pacemaker_noteItems;
                                        noteItems[index].year = event.target.value;
                                        noteItems[index].month = event.target.value !== 21 && noteItems[index].month === "N/A" ? "" : noteItems[index].month;
                                        setState((prevState) => ({
                                          ...prevState,
                                          pacemaker_noteItems: noteItems,
                                          enabledEndPEFA: false,
                                          clearanceDownloaded: false,
                                        }));
                                        setValue(`pacemaker_noteItems_year${index}`, event.target.value, { shouldValidate: true });
                                        handleValueChangeYearAndMonth(noteItems, "pacemaker");
                                      }}
                                    >
                                      {yearData.map((value) => {
                                        return (
                                          <MenuItem value={value} key={value}>
                                            {value}
                                          </MenuItem>
                                        );
                                      })}
                                      <MenuItem value={21}>More than 20 years</MenuItem>
                                    </Select>
                                    <input type="text" style={{ visibility: "hidden" }} name={`pacemaker_noteItems_year${index}`} defaultValue={element.year} ref={register} />
                                  </FormControl>
                                  <FormControl variant="outlined" className="selectMC">
                                    <Select
                                      name={`pacemaker_noteItems_month${index}`}
                                      color="primary"
                                      className="DayChanger"
                                      placeholder="MMM"
                                      value={element.month}
                                      MenuProps={{
                                        PaperProps: { style: { maxHeight: "18%" } },
                                      }}
                                      onChange={(event) => {
                                        const noteItems = state.pacemaker_noteItems;
                                        noteItems[index].month = event.target.value;
                                        setState((prevState) => ({
                                          ...prevState,
                                          pacemaker_noteItems: noteItems,
                                          enabledEndPEFA: false,
                                          clearanceDownloaded: false,
                                        }));
                                        setValue(`pacemaker_noteItems_month${index}`, event.target.value, { shouldValidate: true });
                                        handleValueChangeYearAndMonth(noteItems, "pacemaker");
                                      }}
                                    >
                                      {state.pacemaker_noteItems[index].year === 21 ? (
                                        <MenuItem value={"N/A"} key={"N/A"}>
                                          {"N/A"}
                                        </MenuItem>
                                      ) : (
                                        <></>
                                      )}
                                      {monthData.map((value) => {
                                        return (
                                          <MenuItem value={value} key={value}>
                                            {value}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                    <input type="text" style={{ visibility: "hidden" }} name={`pacemaker_noteItems_month${index}`} defaultValue={element.month} ref={register} />
                                  </FormControl>
                                  {index === 0 ? (
                                    <Link
                                      className="addButton"
                                      onClick={() => {
                                        handleAddFromList(state.pacemaker_noteItems, "pacemaker_noteItems");
                                      }}
                                    >
                                      +
                                    </Link>
                                  ) : (
                                    <Link
                                      className="medicalConditionCancel"
                                      onClick={() => {
                                        handleRemoveFromList(index, state.pacemaker_noteItems, "pacemaker_noteItems");
                                      }}
                                    >
                                      Cancel
                                    </Link>
                                  )}
                                </div>
                                <RadioGroup
                                  aria-label="pacemaker_viewedMC"
                                  name="pacemaker_viewedMC"
                                  className="flexer"
                                  row
                                  value={element.viewedMC}
                                  onChange={(event) => {
                                    handleViewMC(event, index, state.pacemaker_noteItems, state.pacemakerMCs);
                                  }}
                                >
                                  <FormControl variant="outlined" className="selectOveride">
                                    <Select
                                      name="pacemaker_mcType"
                                      color="primary"
                                      className="DayChanger"
                                      placeholder="Select"
                                      value={element.mcType}
                                      MenuProps={{ PaperProps: { style: { maxHeight: "18%" } } }}
                                      onChange={(event) => {
                                        handleMCType(event, index, state.pacemaker_noteItems);
                                      }}
                                    >
                                      <MenuItem value={eMedicalClearanceType.SELECT}>{eMedicalClearanceType.SELECT}</MenuItem>
                                      {state.pacemakerMCs && state.pacemakerMCs.length > 0 ? (
                                        <MenuItem value={eMedicalClearanceType.EXISTINGMC}>{eMedicalClearanceType.EXISTINGMC}</MenuItem>
                                      ) : (
                                        <></>
                                      )}
                                      <MenuItem value={eMedicalClearanceType.PAPERMC}>{eMedicalClearanceType.PAPERMC}</MenuItem>
                                      <MenuItem value={eMedicalClearanceType.NOTPROVIDED}>{eMedicalClearanceType.NOTPROVIDED}</MenuItem>
                                    </Select>
                                    <input type="text" style={{ visibility: "hidden" }} name={`pacemaker_noteItems_mcType${index}`} defaultValue={element.mcType} ref={register} />
                                  </FormControl>
                                  {element.mcType === eMedicalClearanceType.EXISTINGMC ||
                                  (element.mcType === eMedicalClearanceType.PAPERMC && state.pacemakerPaperMCs.length > 0) ? (
                                    <>
                                      <FormControlLabel
                                        control={<Radio color="primary" name="pacemaker_viewedMC" id="pacemaker_viewedMC_accepted" value="ViewedAccepted" />}
                                        inputRef={register}
                                        label="MC Viewed and Accepted"
                                      />
                                      <FormControlLabel
                                        control={<Radio color="primary" name="pacemaker_viewedMC" id="pacemaker_viewedMC_notAccepted" value="NotAccepted" />}
                                        inputRef={register}
                                        label="MC Viewed and Not Accepted"
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {element.mcType === eMedicalClearanceType.PAPERMC && (!state.pacemakerPaperMCs || state.pacemakerPaperMCs.length < 1) ? (
                                    <div className="uploadLink">
                                      <Upload
                                        condition={"pacemaker"}
                                        conditionText={"Pacemaker"}
                                        paperMCs={state.pacemakerPaperMCs}
                                        otherInfo={element.note}
                                        pefaId={pefaAssessment?.pefArequestID}
                                        bookingId={pefaAssessment?.id}
                                        doctor={state.medicalPractitioner}
                                        practice={state.medicalPractice}
                                        startUpload={startUpload}
                                        handlePaperMC={updatePaperMC}
                                      />
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </RadioGroup>
                                {element.isMCRequired ? (
                                  <>
                                    <span className="addedList">
                                      <img src={WarningIcon} alt="Warning Icon" />
                                      <span>Please find this condition on the New Medical clearance request at the bottom of the list</span>
                                    </span>
                                  </>
                                ) : (
                                  <></>
                                )}
                                {element.note === "" ||
                                element.year === "" ||
                                element.month === "" ||
                                (element.mcType !== eMedicalClearanceType.NOTPROVIDED && element.viewedMC === "") ||
                                element.mcType === eMedicalClearanceType.SELECT ? (
                                  <div className="invalid-medical">Input is required</div>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        ))}

                      {errors.pacemaker?.message !== "" ? <div className="invalid-feedback">{errors.pacemaker?.message}</div> : <></>}
                      {errors.pacemaker_noteItems?.message !== "" ? <div className="invalid-feedback">{errors.pacemaker_noteItems?.message}</div> : <></>}
                      {state.pacemakerMCs && state.pacemakerMCs.length > 0 ? (
                        <>
                          {state.pacemakerMCs
                            .filter((x) => x.medicalCertificate !== null)
                            .map((medicalClearance: any, index: number) => (
                              <>
                                <div className="viewMedicalConditionFile" key={index}>
                                  <Typography className="medicalConditionName mr-2">
                                    {medicalClearance.condition} {medicalClearance.outcome ? ` - ${medicalClearance.outcome.toUpperCase()} ` : ""}
                                  </Typography>
                                </div>
                                <div className="viewMedicalConditionFile" key={index}>
                                  <Typography className="medicalConditionName ellipsis ml-3 mr-2">{medicalClearance.otherInfo}</Typography>
                                </div>
                                <div className="viewMedicalConditionFile" key={index}>
                                  {medicalClearance.medicalCertificate ? (
                                    <>
                                      <Link
                                        className="viewMC"
                                        underline="always"
                                        onClick={() => handleCertificateDownload(medicalClearance.medicalCertificate.id, medicalClearance.medicalCertificate.filename)}
                                      >
                                        Review Existing Medical Clearance
                                      </Link>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </>
                            ))}
                        </>
                      ) : (
                        <></>
                      )}
                      {state.pacemakerPaperMCs && state.pacemakerPaperMCs.length > 0 ? (
                        <>
                          {state.pacemakerPaperMCs.map((medicalClearance: any, index: number) => (
                            <>
                              <div className="viewMedicalConditionFile" key={index}>
                                <Typography className="medicalConditionName mr-2">{`${medicalClearance.condition} - PaperMC`}</Typography>
                                <Link
                                  className="cancelPaperMC"
                                  underline="always"
                                  onClick={() => cancelPaperMCNoteItems("pacemaker", state.pacemaker_noteItems, medicalClearance.id, medicalClearance.medicalClearanceId)}
                                >
                                  Cancel
                                </Link>
                              </div>
                              <div className="viewMedicalConditionFile" key={index}>
                                {medicalClearance ? (
                                  <>
                                    <Link className="viewMC" underline="always" onClick={() => handleCertificateDownload(medicalClearance.id, medicalClearance.fileName)}>
                                      Review Paper Medical Clearance
                                    </Link>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                      <hr className="separator"></hr>
                      {/* Pacemaker */}
                      {/* Heart Condition or Heart surgery */}
                      <div className="flexer" id="otherHeartCondition">
                        <FormLabel className="bold blueText height-adjusted" component="legend">
                          Heart Condition or Heart surgery*
                        </FormLabel>
                      </div>
                      <RadioGroup
                        aria-label="otherHeartCondition"
                        name="otherHeartCondition"
                        className="flexer"
                        row
                        value={state.otherHeartCondition}
                        onChange={(event) => {
                          handleValueChangeForNoteAndDate(event, state.otherHeartConditionMCs, "Heart Condition or Heart surgery");
                        }}
                      >
                        <FormControlLabel
                          control={<Radio color="primary" name="otherHeartCondition" id="otherHeartConditionDenied" value="denied" />}
                          value={state.lowBlood}
                          inputRef={register}
                          label="Denied"
                        />
                        <FormControlLabel
                          control={<Radio color="primary" name="otherHeartCondition" id="otherHeartConditionYes" value="yesMCRequired" />}
                          inputRef={register}
                          label="Yes – MC required"
                        />
                      </RadioGroup>
                      {state.otherHeartCondition === "yesMCRequired" &&
                        state.otherHeartCondition_noteItems.map((element, index) => (
                          <>
                            {!element.isDeleted ? (
                              <>
                                <div className="note-item">
                                  <OutlinedInput
                                    key={index}
                                    color="primary"
                                    name="otherHeartCondition_noteItems"
                                    value={element.name}
                                    autoComplete="off"
                                    className="small-note"
                                    inputRef={register}
                                    placeholder="If Yes, describe condition here*"
                                    defaultValue={element.note}
                                    onChange={(event) => handleValueChangeNote(event, index, state.otherHeartCondition_noteItems, "otherHeartCondition_noteItems")}
                                  />
                                  <FormControl variant="outlined" className="selectMC">
                                    <Select
                                      name={`otherHeartCondition_noteItems_year${index}`}
                                      color="primary"
                                      className="DayChanger"
                                      placeholder="YYYY"
                                      value={element.year}
                                      MenuProps={{ PaperProps: { style: { maxHeight: "18%" } } }}
                                      onChange={(event) => {
                                        const noteItems = state.otherHeartCondition_noteItems;
                                        noteItems[index].year = event.target.value;
                                        noteItems[index].month = event.target.value !== 21 && noteItems[index].month === "N/A" ? "" : noteItems[index].month;
                                        setState((prevState) => ({
                                          ...prevState,
                                          otherHeartCondition_noteItems: noteItems,
                                          enabledEndPEFA: false,
                                          clearanceDownloaded: false,
                                        }));
                                        setValue(`otherHeartCondition_noteItems_year${index}`, event.target.value, { shouldValidate: true });
                                        handleValueChangeYearAndMonth(noteItems, "otherHeartCondition");
                                      }}
                                    >
                                      {yearData.map((value) => {
                                        return (
                                          <MenuItem value={value} key={value}>
                                            {value}
                                          </MenuItem>
                                        );
                                      })}
                                      <MenuItem value={21}>More than 20 years</MenuItem>
                                    </Select>
                                    <input
                                      type="text"
                                      style={{ visibility: "hidden" }}
                                      name={`otherHeartCondition_noteItems_year${index}`}
                                      defaultValue={element.year}
                                      ref={register}
                                    />
                                  </FormControl>
                                  <FormControl variant="outlined" className="selectMC">
                                    <Select
                                      name={`otherHeartCondition_noteItems_month${index}`}
                                      color="primary"
                                      className="DayChanger"
                                      placeholder="MMM"
                                      value={element.month}
                                      MenuProps={{ PaperProps: { style: { maxHeight: "18%" } } }}
                                      onChange={(event) => {
                                        const noteItems = state.otherHeartCondition_noteItems;
                                        noteItems[index].month = event.target.value;
                                        setState((prevState) => ({
                                          ...prevState,
                                          otherHeartCondition_noteItems: noteItems,
                                          enabledEndPEFA: false,
                                          clearanceDownloaded: false,
                                        }));
                                        setValue(`otherHeartCondition_noteItems_month${index}`, event.target.value, { shouldValidate: true });
                                        handleValueChangeYearAndMonth(noteItems, "otherHeartCondition");
                                      }}
                                    >
                                      {state.otherHeartCondition_noteItems[index].year === 21 ? (
                                        <MenuItem value={"N/A"} key={"N/A"}>
                                          {"N/A"}
                                        </MenuItem>
                                      ) : (
                                        <></>
                                      )}
                                      {monthData.map((value) => {
                                        return (
                                          <MenuItem value={value} key={value}>
                                            {value}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                    <input
                                      type="text"
                                      style={{ visibility: "hidden" }}
                                      name={`otherHeartCondition_noteItems_month${index}`}
                                      defaultValue={element.month}
                                      ref={register}
                                    />
                                  </FormControl>
                                  {index === 0 ? (
                                    <Link
                                      className="addButton"
                                      onClick={() => {
                                        handleAddFromList(state.otherHeartCondition_noteItems, "otherHeartCondition_noteItems");
                                      }}
                                    >
                                      +
                                    </Link>
                                  ) : (
                                    <Link
                                      className="medicalConditionCancel"
                                      onClick={() => {
                                        handleRemoveFromList(index, state.otherHeartCondition_noteItems, "otherHeartCondition_noteItems");
                                      }}
                                    >
                                      Cancel
                                    </Link>
                                  )}
                                </div>
                                <RadioGroup
                                  aria-label="otherHeartCondition_viewedMC"
                                  name="otherHeartCondition_viewedMC"
                                  className="flexer"
                                  row
                                  value={element.viewedMC}
                                  onChange={(event) => {
                                    handleViewMC(event, index, state.otherHeartCondition_noteItems, state.otherHeartConditionMCs);
                                  }}
                                >
                                  <FormControl variant="outlined" className="selectOveride">
                                    <Select
                                      name="otherHeartCondition_mcType"
                                      color="primary"
                                      className="DayChanger"
                                      placeholder="Select"
                                      value={element.mcType}
                                      MenuProps={{ PaperProps: { style: { maxHeight: "18%" } } }}
                                      onChange={(event) => {
                                        handleMCType(event, index, state.otherHeartCondition_noteItems);
                                      }}
                                    >
                                      <MenuItem value={eMedicalClearanceType.SELECT}>{eMedicalClearanceType.SELECT}</MenuItem>
                                      {state.otherHeartConditionMCs && state.otherHeartConditionMCs.length > 0 ? (
                                        <MenuItem value={eMedicalClearanceType.EXISTINGMC}>{eMedicalClearanceType.EXISTINGMC}</MenuItem>
                                      ) : (
                                        <></>
                                      )}
                                      <MenuItem value={eMedicalClearanceType.PAPERMC}>{eMedicalClearanceType.PAPERMC}</MenuItem>
                                      <MenuItem value={eMedicalClearanceType.NOTPROVIDED}>{eMedicalClearanceType.NOTPROVIDED}</MenuItem>
                                    </Select>
                                    <input
                                      type="text"
                                      style={{ visibility: "hidden" }}
                                      name={`otherHeartCondition_noteItems_mcType${index}`}
                                      defaultValue={element.mcType}
                                      ref={register}
                                    />
                                  </FormControl>
                                  {element.mcType === eMedicalClearanceType.EXISTINGMC ||
                                  (element.mcType === eMedicalClearanceType.PAPERMC && state.otherHeartConditionPaperMCs.length > 0) ? (
                                    <>
                                      <FormControlLabel
                                        control={<Radio color="primary" name="otherHeartCondition_viewedMC" id="otherHeartCondition_viewedMC_accepted" value="ViewedAccepted" />}
                                        inputRef={register}
                                        label="MC Viewed and Accepted"
                                      />
                                      <FormControlLabel
                                        control={<Radio color="primary" name="otherHeartCondition_viewedMC" id="otherHeartCondition_viewedMC_notAccepted" value="NotAccepted" />}
                                        inputRef={register}
                                        label="MC Viewed and Not Accepted"
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {element.mcType === eMedicalClearanceType.PAPERMC && (!state.otherHeartConditionPaperMCs || state.otherHeartConditionPaperMCs.length < 1) ? (
                                    <div className="uploadLink">
                                      <Upload
                                        condition={"otherHeartCondition"}
                                        conditionText={"Heart Condition or Heart surgery"}
                                        paperMCs={state.otherHeartConditionPaperMCs}
                                        otherInfo={element.note}
                                        pefaId={pefaAssessment?.pefArequestID}
                                        bookingId={pefaAssessment?.id}
                                        doctor={state.medicalPractitioner}
                                        practice={state.medicalPractice}
                                        startUpload={startUpload}
                                        handlePaperMC={updatePaperMC}
                                      />
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </RadioGroup>
                                {element.isMCRequired ? (
                                  <>
                                    <span className="addedList">
                                      <img src={WarningIcon} alt="Warning Icon" />
                                      <span>Please find this condition on the New Medical clearance request at the bottom of the list</span>
                                    </span>
                                  </>
                                ) : (
                                  <></>
                                )}
                                {element.note === "" ||
                                element.year === "" ||
                                element.month === "" ||
                                (element.mcType !== eMedicalClearanceType.NOTPROVIDED && element.viewedMC === "") ||
                                element.mcType === eMedicalClearanceType.SELECT ? (
                                  <div className="invalid-medical">Input is required</div>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        ))}
                      {errors.otherHeartCondition?.message !== "" ? <div className="invalid-feedback">{errors.otherHeartCondition?.message}</div> : <></>}
                      {errors.otherHeartCondition_noteItems?.message !== "" ? <div className="invalid-feedback">{errors.otherHeartCondition_noteItems?.message}</div> : <></>}
                      {state.otherHeartConditionMCs && state.otherHeartConditionMCs.length > 0 ? (
                        <>
                          {state.otherHeartConditionMCs
                            .filter((x) => x.medicalCertificate !== null)
                            .map((medicalClearance: any, index: number) => (
                              <>
                                <div className="viewMedicalConditionFile" key={index}>
                                  <Typography className="medicalConditionName mr-2">
                                    {medicalClearance.condition} {medicalClearance.outcome ? ` - ${medicalClearance.outcome.toUpperCase()} ` : ""}
                                  </Typography>
                                </div>
                                <div className="viewMedicalConditionFile" key={index}>
                                  <Typography className="medicalConditionName ellipsis ml-3 mr-2">{medicalClearance.otherInfo}</Typography>
                                </div>
                                <div className="viewMedicalConditionFile" key={index}>
                                  {medicalClearance.medicalCertificate ? (
                                    <>
                                      <Link
                                        className="viewMC"
                                        underline="always"
                                        onClick={() => handleCertificateDownload(medicalClearance.medicalCertificate.id, medicalClearance.medicalCertificate.filename)}
                                      >
                                        Review Existing Medical Clearance
                                      </Link>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </>
                            ))}
                        </>
                      ) : (
                        <></>
                      )}
                      {state.otherHeartConditionPaperMCs && state.otherHeartConditionPaperMCs.length > 0 ? (
                        <>
                          {state.otherHeartConditionPaperMCs.map((medicalClearance: any, index: number) => (
                            <>
                              <div className="viewMedicalConditionFile" key={index}>
                                <Typography className="medicalConditionName mr-2">{`${medicalClearance.condition} - PaperMC`}</Typography>
                                <Link
                                  className="cancelPaperMC"
                                  underline="always"
                                  onClick={() =>
                                    cancelPaperMCNoteItems("otherHeartCondition", state.otherHeartCondition_noteItems, medicalClearance.id, medicalClearance.medicalClearanceId)
                                  }
                                >
                                  Cancel
                                </Link>
                              </div>
                              <div className="viewMedicalConditionFile" key={index}>
                                {medicalClearance ? (
                                  <>
                                    <Link className="viewMC" underline="always" onClick={() => handleCertificateDownload(medicalClearance.id, medicalClearance.fileName)}>
                                      Review Paper Medical Clearance
                                    </Link>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                      <hr className="separator"></hr>
                      {/* Heart Condition or Heart surgery */}
                      {/* Bone or Joint Surgeries */}
                      <div className="flexer" id="boneSurgeries">
                        <FormLabel className="bold blueText height-adjusted" component="legend">
                          Bone or Joint Surgeries*
                        </FormLabel>
                      </div>
                      <RadioGroup
                        aria-label="boneSurgeries"
                        name="boneSurgeries"
                        className="flexer"
                        row
                        value={state.boneSurgeries}
                        onChange={(event) => {
                          handleValueChangeForNoteAndDate(event, state.boneSurgeriesMCs, "Bone or Joint Surgeries");
                        }}
                      >
                        <FormControlLabel
                          control={<Radio color="primary" name="boneSurgeries" id="boneSurgeriesDenied" value="denied" />}
                          value={state.lowBlood}
                          inputRef={register}
                          label="Denied"
                        />
                        <FormControlLabel
                          control={<Radio color="primary" name="boneSurgeries" id="boneSurgeriesYes" value="yesMCRequired6Months" />}
                          inputRef={register}
                          label="Yes – MC required if less than 6 months"
                        />
                      </RadioGroup>
                      {state.boneSurgeries === "yesMCRequired6Months" &&
                        state.boneSurgeries_noteItems.map((element, index) => (
                          <>
                            {!element.isDeleted ? (
                              <>
                                <div className="note-item">
                                  <OutlinedInput
                                    key={index}
                                    color="primary"
                                    name="boneSurgeries_noteItems"
                                    value={element.name}
                                    autoComplete="off"
                                    className="small-note"
                                    inputRef={register}
                                    placeholder="If Yes, describe condition here*"
                                    defaultValue={element.note}
                                    onChange={(event) => handleValueChangeNote(event, index, state.boneSurgeries_noteItems, "boneSurgeries_noteItems")}
                                  />
                                  <FormControl variant="outlined" className="selectMC">
                                    <Select
                                      name={`boneSurgeries_noteItems_year${index}`}
                                      color="primary"
                                      className="DayChanger"
                                      placeholder="YYYY"
                                      value={element.year}
                                      MenuProps={{ PaperProps: { style: { maxHeight: "18%" } } }}
                                      onChange={(event) => {
                                        calculateYearAndMonthForClearance(
                                          index,
                                          event,
                                          "boneSurgeries",
                                          state.boneSurgeries_noteItems,
                                          6,
                                          "Bone or Joint Surgeries",
                                          state.boneSurgeriesMCs
                                        );
                                      }}
                                    >
                                      {yearData.map((value) => {
                                        return (
                                          <MenuItem value={value} key={value}>
                                            {value}
                                          </MenuItem>
                                        );
                                      })}
                                      <MenuItem value={21}>More than 20 years</MenuItem>
                                    </Select>
                                    <input type="text" style={{ visibility: "hidden" }} name={`boneSurgeries_noteItems_year${index}`} defaultValue={element.year} ref={register} />
                                  </FormControl>
                                  <FormControl variant="outlined" className="selectMC">
                                    <Select
                                      name={`boneSurgeries_noteItems_month${index}`}
                                      color="primary"
                                      className="DayChanger"
                                      placeholder="MMM"
                                      value={element.month}
                                      MenuProps={{ PaperProps: { style: { maxHeight: "18%" } } }}
                                      onChange={(event) => {
                                        calculateYearAndMonthForClearance(
                                          index,
                                          event,
                                          "boneSurgeries",
                                          state.boneSurgeries_noteItems,
                                          6,
                                          "Bone or Joint Surgeries",
                                          state.boneSurgeriesMCs
                                        );
                                      }}
                                    >
                                      {state.boneSurgeries_noteItems[index].year === 21 ? (
                                        <MenuItem value={"N/A"} key={"N/A"}>
                                          {"N/A"}
                                        </MenuItem>
                                      ) : (
                                        <></>
                                      )}
                                      {monthData.map((value) => {
                                        return (
                                          <MenuItem value={value} key={value}>
                                            {value}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                    <input
                                      type="text"
                                      style={{ visibility: "hidden" }}
                                      name={`boneSurgeries_noteItems_month${index}`}
                                      defaultValue={element.month}
                                      ref={register}
                                    />
                                  </FormControl>
                                  {index === 0 ? (
                                    <Link
                                      className="addButton"
                                      onClick={() => {
                                        handleAddFromList(state.boneSurgeries_noteItems, "boneSurgeries_noteItems");
                                      }}
                                    >
                                      +
                                    </Link>
                                  ) : (
                                    <Link
                                      className="medicalConditionCancel"
                                      onClick={() => {
                                        handleRemoveFromList(index, state.boneSurgeries_noteItems, "boneSurgeries_noteItems");
                                      }}
                                    >
                                      Cancel
                                    </Link>
                                  )}
                                </div>
                                {element.monthDiff < 6 ? (
                                  <RadioGroup
                                    aria-label="boneSurgeries_viewedMC"
                                    name="boneSurgeries_viewedMC"
                                    className="flexer"
                                    row
                                    value={element.viewedMC}
                                    onChange={(event) => {
                                      handleViewMC(event, index, state.boneSurgeries_noteItems, state.boneSurgeriesMCs);
                                    }}
                                  >
                                    <FormControl variant="outlined" className="selectOveride">
                                      <Select
                                        name="boneSurgeries_mcType"
                                        color="primary"
                                        className="DayChanger"
                                        placeholder="Select"
                                        value={element.mcType}
                                        MenuProps={{ PaperProps: { style: { maxHeight: "18%" } } }}
                                        onChange={(event) => {
                                          handleMCType(event, index, state.boneSurgeries_noteItems);
                                        }}
                                      >
                                        <MenuItem value={eMedicalClearanceType.SELECT}>{eMedicalClearanceType.SELECT}</MenuItem>
                                        {state.boneSurgeriesMCs && state.boneSurgeriesMCs.length > 0 ? (
                                          <MenuItem value={eMedicalClearanceType.EXISTINGMC}>{eMedicalClearanceType.EXISTINGMC}</MenuItem>
                                        ) : (
                                          <></>
                                        )}
                                        <MenuItem value={eMedicalClearanceType.PAPERMC}>{eMedicalClearanceType.PAPERMC}</MenuItem>
                                        <MenuItem value={eMedicalClearanceType.NOTPROVIDED}>{eMedicalClearanceType.NOTPROVIDED}</MenuItem>
                                      </Select>
                                      <input
                                        type="text"
                                        style={{ visibility: "hidden" }}
                                        name={`boneSurgeries_noteItems_mcType${index}`}
                                        defaultValue={element.mcType}
                                        ref={register}
                                      />
                                    </FormControl>
                                    {element.mcType === eMedicalClearanceType.EXISTINGMC ||
                                    (element.mcType === eMedicalClearanceType.PAPERMC && state.boneSurgeriesPaperMCs.length > 0) ? (
                                      <>
                                        <FormControlLabel
                                          control={<Radio color="primary" name="boneSurgeries_viewedMC" id="boneSurgeries_viewedMC_accepted" value="ViewedAccepted" />}
                                          inputRef={register}
                                          label="MC Viewed and Accepted"
                                        />
                                        <FormControlLabel
                                          control={<Radio color="primary" name="boneSurgeries_viewedMC" id="boneSurgeries_viewedMC_notAccepted" value="NotAccepted" />}
                                          inputRef={register}
                                          label="MC Viewed and Not Accepted"
                                        />
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {element.mcType === eMedicalClearanceType.PAPERMC && (!state.boneSurgeriesPaperMCs || state.boneSurgeriesPaperMCs.length < 1) ? (
                                      <div className="uploadLink">
                                        <Upload
                                          condition={"boneSurgeries"}
                                          conditionText={"Bone or Joint Surgeries"}
                                          paperMCs={state.boneSurgeriesPaperMCs}
                                          otherInfo={element.note}
                                          pefaId={pefaAssessment?.pefArequestID}
                                          bookingId={pefaAssessment?.id}
                                          doctor={state.medicalPractitioner}
                                          practice={state.medicalPractice}
                                          startUpload={startUpload}
                                          handlePaperMC={updatePaperMC}
                                        />
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </RadioGroup>
                                ) : (
                                  <></>
                                )}
                                {element.monthDiff < 6 && element.isMCRequired ? (
                                  <>
                                    <span className="addedList">
                                      <img src={WarningIcon} alt="Warning Icon" />
                                      <span>Please find this condition on the New Medical clearance request at the bottom of the list</span>
                                    </span>
                                  </>
                                ) : (
                                  <></>
                                )}
                                {element.note === "" ||
                                element.year === "" ||
                                element.month === "" ||
                                (element.monthDiff < 6 && element.mcType !== eMedicalClearanceType.NOTPROVIDED && element.viewedMC === "") ||
                                (element.monthDiff < 6 && element.mcType === eMedicalClearanceType.SELECT) ? (
                                  <div className="invalid-medical">Input is required</div>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        ))}
                      {errors.boneSurgeries?.message !== "" ? <div className="invalid-feedback">{errors.boneSurgeries?.message}</div> : <></>}
                      {state.boneSurgeriesMCs && state.boneSurgeriesMCs.length > 0 ? (
                        <>
                          {state.boneSurgeriesMCs
                            .filter((x) => x.medicalCertificate !== null)
                            .map((medicalClearance: any, index: number) => (
                              <>
                                <div className="viewMedicalConditionFile" key={index}>
                                  <Typography className="medicalConditionName mr-2">
                                    {medicalClearance.condition} {medicalClearance.outcome ? ` - ${medicalClearance.outcome.toUpperCase()} ` : ""}
                                  </Typography>
                                </div>
                                <div className="viewMedicalConditionFile" key={index}>
                                  <Typography className="medicalConditionName ellipsis ml-3 mr-2">{medicalClearance.otherInfo}</Typography>
                                </div>
                                <div className="viewMedicalConditionFile" key={index}>
                                  {medicalClearance.medicalCertificate ? (
                                    <>
                                      <Link
                                        className="viewMC"
                                        underline="always"
                                        onClick={() => handleCertificateDownload(medicalClearance.medicalCertificate.id, medicalClearance.medicalCertificate.filename)}
                                      >
                                        Review Existing Medical Clearance
                                      </Link>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </>
                            ))}
                        </>
                      ) : (
                        <></>
                      )}
                      {state.boneSurgeriesPaperMCs && state.boneSurgeriesPaperMCs.length > 0 ? (
                        <>
                          {state.boneSurgeriesPaperMCs.map((medicalClearance: any, index: number) => (
                            <>
                              <div className="viewMedicalConditionFile" key={index}>
                                <Typography className="medicalConditionName mr-2">{`${medicalClearance.condition} - PaperMC`}</Typography>
                                <Link
                                  className="cancelPaperMC"
                                  underline="always"
                                  onClick={() => cancelPaperMCNoteItems("boneSurgeries", state.boneSurgeries_noteItems, medicalClearance.id, medicalClearance.medicalClearanceId)}
                                >
                                  Cancel
                                </Link>
                              </div>
                              <div className="viewMedicalConditionFile" key={index}>
                                {medicalClearance ? (
                                  <>
                                    <Link className="viewMC" underline="always" onClick={() => handleCertificateDownload(medicalClearance.id, medicalClearance.fileName)}>
                                      Review Paper Medical Clearance
                                    </Link>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                      <hr className="separator"></hr>
                      {/* Bone or Joint Surgeries */}
                      {/* Fractures  */}
                      <div className="flexer" id="fractures">
                        <FormLabel className="bold blueText height-adjusted" component="legend">
                          Fractures*
                        </FormLabel>
                      </div>
                      <RadioGroup
                        aria-label="fractures"
                        name="fractures"
                        className="flexer"
                        row
                        value={state.fractures}
                        onChange={(event) => {
                          handleValueChangeForNoteAndDate(event, state.fracturesMCs, "Fractures");
                        }}
                      >
                        <FormControlLabel
                          control={<Radio color="primary" name="fractures" id="fracturesDenied" value="denied" />}
                          value={state.fractures}
                          inputRef={register}
                          label="Denied"
                        />
                        <FormControlLabel
                          control={<Radio color="primary" name="fractures" id="fracturesYes" value="yesMCRequired6Months" />}
                          inputRef={register}
                          label="Yes – MC required if less than 6 months"
                        />
                      </RadioGroup>
                      {state.fractures === "yesMCRequired6Months" &&
                        state.fractures_noteItems.map((element, index) => (
                          <>
                            {!element.isDeleted ? (
                              <>
                                <div className="note-item">
                                  <OutlinedInput
                                    key={index}
                                    color="primary"
                                    name="fractures_noteItems"
                                    value={element.name}
                                    autoComplete="off"
                                    className="small-note"
                                    inputRef={register}
                                    placeholder="If Yes, describe condition here*"
                                    defaultValue={element.note}
                                    onChange={(event) => handleValueChangeNote(event, index, state.fractures_noteItems, "fractures_noteItems")}
                                  />
                                  <FormControl variant="outlined" className="selectMC">
                                    <Select
                                      name={`fractures_noteItems_year${index}`}
                                      color="primary"
                                      className="DayChanger"
                                      placeholder="YYYY"
                                      value={element.year}
                                      MenuProps={{ PaperProps: { style: { maxHeight: "18%" } } }}
                                      onChange={(event) => {
                                        calculateYearAndMonthForClearance(index, event, "fractures", state.fractures_noteItems, 6, "Fractures", state.fracturesMCs);
                                      }}
                                    >
                                      {yearData.map((value) => {
                                        return (
                                          <MenuItem value={value} key={value}>
                                            {value}
                                          </MenuItem>
                                        );
                                      })}
                                      <MenuItem value={21}>More than 20 years</MenuItem>
                                    </Select>
                                    <input type="text" style={{ visibility: "hidden" }} name={`fractures_noteItems_year${index}`} defaultValue={element.year} ref={register} />
                                  </FormControl>
                                  <FormControl variant="outlined" className="selectMC">
                                    <Select
                                      name={`fractures_noteItems_month${index}`}
                                      color="primary"
                                      className="DayChanger"
                                      placeholder="MMM"
                                      value={element.month}
                                      MenuProps={{ PaperProps: { style: { maxHeight: "18%" } } }}
                                      onChange={(event) => {
                                        calculateYearAndMonthForClearance(index, event, "fractures", state.fractures_noteItems, 6, "Fractures", state.fracturesMCs);
                                      }}
                                    >
                                      {state.fractures_noteItems[index].year === 21 ? (
                                        <MenuItem value={"N/A"} key={"N/A"}>
                                          {"N/A"}
                                        </MenuItem>
                                      ) : (
                                        <></>
                                      )}
                                      {monthData.map((value) => {
                                        return (
                                          <MenuItem value={value} key={value}>
                                            {value}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                    <input type="text" style={{ visibility: "hidden" }} name={`fractures_noteItems_month${index}`} defaultValue={element.month} ref={register} />
                                  </FormControl>
                                  {index === 0 ? (
                                    <Link
                                      className="addButton"
                                      onClick={() => {
                                        handleAddFromList(state.fractures_noteItems, "fractures_noteItems");
                                      }}
                                    >
                                      +
                                    </Link>
                                  ) : (
                                    <Link
                                      className="medicalConditionCancel"
                                      onClick={() => {
                                        handleRemoveFromList(index, state.fractures_noteItems, "fractures_noteItems");
                                      }}
                                    >
                                      Cancel
                                    </Link>
                                  )}
                                </div>
                                {element.monthDiff < 6 ? (
                                  <RadioGroup
                                    aria-label="fractures_viewedMC"
                                    name="fractures_viewedMC"
                                    className="flexer"
                                    row
                                    value={element.viewedMC}
                                    onChange={(event) => {
                                      handleViewMC(event, index, state.fractures_noteItems, state.fracturesMCs);
                                    }}
                                  >
                                    <FormControl variant="outlined" className="selectOveride">
                                      <Select
                                        name="fractures_mcType"
                                        color="primary"
                                        className="DayChanger"
                                        placeholder="Select"
                                        value={element.mcType}
                                        MenuProps={{ PaperProps: { style: { maxHeight: "18%" } } }}
                                        onChange={(event) => {
                                          handleMCType(event, index, state.fractures_noteItems);
                                        }}
                                      >
                                        <MenuItem value={eMedicalClearanceType.SELECT}>{eMedicalClearanceType.SELECT}</MenuItem>
                                        {state.fracturesMCs && state.fracturesMCs.length > 0 ? (
                                          <MenuItem value={eMedicalClearanceType.EXISTINGMC}>{eMedicalClearanceType.EXISTINGMC}</MenuItem>
                                        ) : (
                                          <></>
                                        )}
                                        <MenuItem value={eMedicalClearanceType.PAPERMC}>{eMedicalClearanceType.PAPERMC}</MenuItem>
                                        <MenuItem value={eMedicalClearanceType.NOTPROVIDED}>{eMedicalClearanceType.NOTPROVIDED}</MenuItem>
                                      </Select>
                                      <input
                                        type="text"
                                        style={{ visibility: "hidden" }}
                                        name={`fractures_noteItems_mcType${index}`}
                                        defaultValue={element.mcType}
                                        ref={register}
                                      />
                                    </FormControl>
                                    {element.mcType === eMedicalClearanceType.EXISTINGMC ||
                                    (element.mcType === eMedicalClearanceType.PAPERMC && state.fracturesPaperMCs.length > 0) ? (
                                      <>
                                        <FormControlLabel
                                          control={<Radio color="primary" name="fractures_viewedMC" id="fractures_viewedMC_accepted" value="ViewedAccepted" />}
                                          inputRef={register}
                                          label="MC Viewed and Accepted"
                                        />
                                        <FormControlLabel
                                          control={<Radio color="primary" name="fractures_viewedMC" id="fractures_viewedMC_notAccepted" value="NotAccepted" />}
                                          inputRef={register}
                                          label="MC Viewed and Not Accepted"
                                        />
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {element.mcType === eMedicalClearanceType.PAPERMC && (!state.fracturesPaperMCs || state.fracturesPaperMCs.length < 1) ? (
                                      <div className="uploadLink">
                                        <Upload
                                          condition={"fractures"}
                                          conditionText={"Fractures"}
                                          paperMCs={state.fracturesPaperMCs}
                                          otherInfo={element.note}
                                          pefaId={pefaAssessment?.pefArequestID}
                                          bookingId={pefaAssessment?.id}
                                          doctor={state.medicalPractitioner}
                                          practice={state.medicalPractice}
                                          startUpload={startUpload}
                                          handlePaperMC={updatePaperMC}
                                        />
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </RadioGroup>
                                ) : (
                                  <></>
                                )}
                                {element.monthDiff < 6 && element.isMCRequired ? (
                                  <>
                                    <span className="addedList">
                                      <img src={WarningIcon} alt="Warning Icon" />
                                      <span>Please find this condition on the New Medical clearance request at the bottom of the list</span>
                                    </span>
                                  </>
                                ) : (
                                  <></>
                                )}
                                {element.note === "" ||
                                element.year === "" ||
                                element.month === "" ||
                                (element.monthDiff < 6 && element.mcType !== eMedicalClearanceType.NOTPROVIDED && element.viewedMC === "") ||
                                (element.monthDiff < 6 && element.mcType === eMedicalClearanceType.SELECT) ? (
                                  <div className="invalid-medical">Input is required</div>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        ))}
                      {errors.fractures?.message !== "" ? <div className="invalid-feedback">{errors.fractures?.message}</div> : <></>}
                      {errors.fractures_noteItems?.message !== "" ? <div className="invalid-feedback">{errors.fractures_noteItems?.message}</div> : <></>}
                      {state.fracturesMCs && state.fracturesMCs.length > 0 ? (
                        <>
                          {state.fracturesMCs
                            .filter((x) => x.medicalCertificate !== null)
                            .map((medicalClearance: any, index: number) => (
                              <>
                                <div className="viewMedicalConditionFile" key={index}>
                                  <Typography className="medicalConditionName mr-2">
                                    {medicalClearance.condition} {medicalClearance.outcome ? ` - ${medicalClearance.outcome.toUpperCase()} ` : ""}
                                  </Typography>
                                </div>
                                <div className="viewMedicalConditionFile" key={index}>
                                  <Typography className="medicalConditionName ellipsis ml-3 mr-2">{medicalClearance.otherInfo}</Typography>
                                </div>
                                <div className="viewMedicalConditionFile" key={index}>
                                  {medicalClearance.medicalCertificate ? (
                                    <>
                                      <Link
                                        className="viewMC"
                                        underline="always"
                                        onClick={() => handleCertificateDownload(medicalClearance.medicalCertificate.id, medicalClearance.medicalCertificate.filename)}
                                      >
                                        Review Existing Medical Clearance
                                      </Link>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </>
                            ))}
                        </>
                      ) : (
                        <></>
                      )}
                      {state.fracturesPaperMCs && state.fracturesPaperMCs.length > 0 ? (
                        <>
                          {state.fracturesPaperMCs.map((medicalClearance: any, index: number) => (
                            <>
                              <div className="viewMedicalConditionFile" key={index}>
                                <Typography className="medicalConditionName mr-2">{`${medicalClearance.condition} - PaperMC`}</Typography>
                                <Link
                                  className="cancelPaperMC"
                                  underline="always"
                                  onClick={() => cancelPaperMCNoteItems("fractures", state.fractures_noteItems, medicalClearance.id, medicalClearance.medicalClearanceId)}
                                >
                                  Cancel
                                </Link>
                              </div>
                              <div className="viewMedicalConditionFile" key={index}>
                                {medicalClearance ? (
                                  <>
                                    <Link className="viewMC" underline="always" onClick={() => handleCertificateDownload(medicalClearance.id, medicalClearance.fileName)}>
                                      Review Paper Medical Clearance
                                    </Link>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                      <hr className="separator"></hr>
                      {/* Fractures */}
                      {/* Dislocations  */}
                      <div className="flexer" id="dislocations">
                        <FormLabel className="bold blueText height-adjusted" component="legend">
                          Dislocations*
                        </FormLabel>
                      </div>
                      <RadioGroup
                        aria-label="dislocations"
                        name="dislocations"
                        className="flexer"
                        row
                        value={state.dislocations}
                        onChange={(event) => {
                          handleValueChangeForNoteAndDate(event, state.dislocationsMCs, "Dislocations");
                        }}
                      >
                        <FormControlLabel
                          control={<Radio color="primary" name="dislocations" id="dislocationsDenied" value="denied" />}
                          value={state.dislocations}
                          inputRef={register}
                          label="Denied"
                        />
                        <FormControlLabel
                          control={<Radio color="primary" name="dislocations" id="dislocationsYes" value="yesMCRequired6Months" />}
                          inputRef={register}
                          label="Yes – MC required if less than 6 months"
                        />
                      </RadioGroup>
                      {state.dislocations === "yesMCRequired6Months" &&
                        state.dislocations_noteItems.map((element, index) => (
                          <>
                            {!element.isDeleted ? (
                              <>
                                <div className="note-item">
                                  <OutlinedInput
                                    key={index}
                                    color="primary"
                                    name="dislocations_noteItems"
                                    value={element.name}
                                    autoComplete="off"
                                    className="small-note"
                                    inputRef={register}
                                    placeholder="If Yes, describe condition here*"
                                    defaultValue={element.note}
                                    onChange={(event) => handleValueChangeNote(event, index, state.dislocations_noteItems, "dislocations_noteItems")}
                                  />
                                  <FormControl variant="outlined" className="selectMC">
                                    <Select
                                      name={`dislocations_noteItems_year${index}`}
                                      color="primary"
                                      className="DayChanger"
                                      placeholder="YYYY"
                                      value={element.year}
                                      MenuProps={{ PaperProps: { style: { maxHeight: "18%" } } }}
                                      onChange={(event) => {
                                        calculateYearAndMonthForClearance(index, event, "dislocations", state.dislocations_noteItems, 6, "Dislocations", state.dislocationsMCs);
                                      }}
                                    >
                                      {yearData.map((value) => {
                                        return (
                                          <MenuItem value={value} key={value}>
                                            {value}
                                          </MenuItem>
                                        );
                                      })}
                                      <MenuItem value={21}>More than 20 years</MenuItem>
                                    </Select>
                                    <input type="text" style={{ visibility: "hidden" }} name={`dislocations_noteItems_year${index}`} defaultValue={element.year} ref={register} />
                                  </FormControl>
                                  <FormControl variant="outlined" className="selectMC">
                                    <Select
                                      name={`dislocations_noteItems_month${index}`}
                                      color="primary"
                                      className="DayChanger"
                                      placeholder="MMM"
                                      value={element.month}
                                      MenuProps={{ PaperProps: { style: { maxHeight: "18%" } } }}
                                      onChange={(event) => {
                                        calculateYearAndMonthForClearance(index, event, "dislocations", state.dislocations_noteItems, 6, "Dislocations", state.dislocationsMCs);
                                      }}
                                    >
                                      {state.dislocations_noteItems[index].year === 21 ? (
                                        <MenuItem value={"N/A"} key={"N/A"}>
                                          {"N/A"}
                                        </MenuItem>
                                      ) : (
                                        <></>
                                      )}
                                      {monthData.map((value) => {
                                        return (
                                          <MenuItem value={value} key={value}>
                                            {value}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                    <input type="text" style={{ visibility: "hidden" }} name={`dislocations_noteItems_month${index}`} defaultValue={element.month} ref={register} />
                                  </FormControl>
                                  {index === 0 ? (
                                    <Link
                                      className="addButton"
                                      onClick={() => {
                                        handleAddFromList(state.dislocations_noteItems, "dislocations_noteItems");
                                      }}
                                    >
                                      +
                                    </Link>
                                  ) : (
                                    <Link
                                      className="medicalConditionCancel"
                                      onClick={() => {
                                        handleRemoveFromList(index, state.dislocations_noteItems, "dislocations_noteItems");
                                      }}
                                    >
                                      Cancel
                                    </Link>
                                  )}
                                </div>
                                {element.monthDiff < 6 ? (
                                  <RadioGroup
                                    aria-label="dislocations_viewedMC"
                                    name="dislocations_viewedMC"
                                    className="flexer"
                                    row
                                    value={element.viewedMC}
                                    onChange={(event) => {
                                      handleViewMC(event, index, state.dislocations_noteItems, state.dislocationsMCs);
                                    }}
                                  >
                                    <FormControl variant="outlined" className="selectOveride">
                                      <Select
                                        name="dislocations_mcType"
                                        color="primary"
                                        className="DayChanger"
                                        placeholder="Select"
                                        value={element.mcType}
                                        MenuProps={{ PaperProps: { style: { maxHeight: "18%" } } }}
                                        onChange={(event) => {
                                          handleMCType(event, index, state.dislocations_noteItems);
                                        }}
                                      >
                                        <MenuItem value={eMedicalClearanceType.SELECT}>{eMedicalClearanceType.SELECT}</MenuItem>
                                        {state.dislocationsMCs && state.dislocationsMCs.length > 0 ? (
                                          <MenuItem value={eMedicalClearanceType.EXISTINGMC}>{eMedicalClearanceType.EXISTINGMC}</MenuItem>
                                        ) : (
                                          <></>
                                        )}
                                        <MenuItem value={eMedicalClearanceType.PAPERMC}>{eMedicalClearanceType.PAPERMC}</MenuItem>
                                        <MenuItem value={eMedicalClearanceType.NOTPROVIDED}>{eMedicalClearanceType.NOTPROVIDED}</MenuItem>
                                      </Select>
                                      <input
                                        type="text"
                                        style={{ visibility: "hidden" }}
                                        name={`dislocations_noteItems_mcType${index}`}
                                        defaultValue={element.mcType}
                                        ref={register}
                                      />
                                    </FormControl>
                                    {element.mcType === eMedicalClearanceType.EXISTINGMC ||
                                    (element.mcType === eMedicalClearanceType.PAPERMC && state.dislocationsPaperMCs.length > 0) ? (
                                      <>
                                        <FormControlLabel
                                          control={<Radio color="primary" name="dislocations_viewedMC" id="dislocations_viewedMC_accepted" value="ViewedAccepted" />}
                                          inputRef={register}
                                          label="MC Viewed and Accepted"
                                        />
                                        <FormControlLabel
                                          control={<Radio color="primary" name="dislocations_viewedMC" id="dislocations_viewedMC_notAccepted" value="NotAccepted" />}
                                          inputRef={register}
                                          label="MC Viewed and Not Accepted"
                                        />
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {element.mcType === eMedicalClearanceType.PAPERMC && (!state.dislocationsPaperMCs || state.dislocationsPaperMCs.length < 1) ? (
                                      <div className="uploadLink">
                                        <Upload
                                          condition={"dislocations"}
                                          conditionText={"Dislocations"}
                                          paperMCs={state.dislocationsPaperMCs}
                                          otherInfo={element.note}
                                          pefaId={pefaAssessment?.pefArequestID}
                                          bookingId={pefaAssessment?.id}
                                          doctor={state.medicalPractitioner}
                                          practice={state.medicalPractice}
                                          startUpload={startUpload}
                                          handlePaperMC={updatePaperMC}
                                        />
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </RadioGroup>
                                ) : (
                                  <></>
                                )}
                                {element.monthDiff < 6 && element.isMCRequired ? (
                                  <>
                                    <span className="addedList">
                                      <img src={WarningIcon} alt="Warning Icon" />
                                      <span>Please find this condition on the New Medical clearance request at the bottom of the list</span>
                                    </span>
                                  </>
                                ) : (
                                  <></>
                                )}
                                {element.note === "" ||
                                element.year === "" ||
                                element.month === "" ||
                                (element.monthDiff < 6 && element.mcType !== eMedicalClearanceType.NOTPROVIDED && element.viewedMC === "") ||
                                (element.monthDiff < 6 && element.mcType === eMedicalClearanceType.SELECT) ? (
                                  <div className="invalid-medical">Input is required</div>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        ))}
                      {errors.dislocations?.message !== "" ? <div className="invalid-feedback">{errors.dislocations?.message}</div> : <></>}
                      {errors.dislocations_noteItems?.message !== "" ? <div className="invalid-feedback">{errors.dislocations_noteItems?.message}</div> : <></>}
                      {state.dislocationsMCs && state.dislocationsMCs.length > 0 ? (
                        <>
                          {state.dislocationsMCs
                            .filter((x) => x.medicalCertificate !== null)
                            .map((medicalClearance: any, index: number) => (
                              <>
                                <div className="viewMedicalConditionFile" key={index}>
                                  <Typography className="medicalConditionName mr-2">
                                    {medicalClearance.condition} {medicalClearance.outcome ? ` - ${medicalClearance.outcome.toUpperCase()} ` : ""}
                                  </Typography>
                                </div>
                                <div className="viewMedicalConditionFile" key={index}>
                                  <Typography className="medicalConditionName ellipsis ml-3 mr-2">{medicalClearance.otherInfo}</Typography>
                                </div>
                                <div className="viewMedicalConditionFile" key={index}>
                                  {medicalClearance.medicalCertificate ? (
                                    <>
                                      <Link
                                        className="viewMC"
                                        underline="always"
                                        onClick={() => handleCertificateDownload(medicalClearance.medicalCertificate.id, medicalClearance.medicalCertificate.filename)}
                                      >
                                        Review Existing Medical Clearance
                                      </Link>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </>
                            ))}
                        </>
                      ) : (
                        <></>
                      )}
                      {state.dislocationsPaperMCs && state.dislocationsPaperMCs.length > 0 ? (
                        <>
                          {state.dislocationsPaperMCs.map((medicalClearance: any, index: number) => (
                            <>
                              <div className="viewMedicalConditionFile" key={index}>
                                <Typography className="medicalConditionName mr-2">{`${medicalClearance.condition} - PaperMC`}</Typography>
                                <Link
                                  className="cancelPaperMC"
                                  underline="always"
                                  onClick={() => cancelPaperMCNoteItems("dislocations", state.dislocations_noteItems, medicalClearance.id, medicalClearance.medicalClearanceId)}
                                >
                                  Cancel
                                </Link>
                              </div>
                              <div className="viewMedicalConditionFile" key={index}>
                                {medicalClearance ? (
                                  <>
                                    <Link className="viewMC" underline="always" onClick={() => handleCertificateDownload(medicalClearance.id, medicalClearance.fileName)}>
                                      Review Paper Medical Clearance
                                    </Link>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                      <hr className="separator"></hr>
                      {/* Dislocations */}
                      {/* Hernias */}
                      <div className="flexer" id="hernia">
                        <FormLabel className="bold blueText height-adjusted" component="legend">
                          Hernias*
                        </FormLabel>
                      </div>
                      <RadioGroup
                        aria-label="hernia"
                        name="hernia"
                        className="flexer"
                        row
                        value={state.hernia}
                        onChange={(event) => {
                          handleHerniaMCRequiredChange(event);
                        }}
                      >
                        <FormControlLabel
                          control={<Radio color="primary" name="hernia" id="herniaDenied" value="denied" />}
                          value={state.hernia}
                          inputRef={register}
                          label="Denied"
                        />
                        <FormControlLabel
                          control={<Radio color="primary" name="hernia" id="herniaYes" value="yesMCRequired" />}
                          inputRef={register}
                          label="Yes – MC Assessor discretion"
                        />
                      </RadioGroup>
                      {state.hernia === "yesMCRequired" &&
                        state.hernia_noteItems.map((element, index) => (
                          <>
                            {!element.isDeleted ? (
                              <>
                                <div className="note-item">
                                  <OutlinedInput
                                    key={index}
                                    color="primary"
                                    name="hernia_noteItems"
                                    value={element.name}
                                    autoComplete="off"
                                    className="small-note"
                                    inputRef={register}
                                    placeholder="If Yes, describe condition here*"
                                    defaultValue={element.note}
                                    onChange={(event) => handleValueChangeNote(event, index, state.hernia_noteItems, "hernia_noteItems")}
                                  />
                                  <FormControl variant="outlined" className="selectMC">
                                    <Select
                                      name={`hernia_noteItems_year${index}`}
                                      color="primary"
                                      className="DayChanger"
                                      placeholder="YYYY"
                                      value={element.year}
                                      MenuProps={{ PaperProps: { style: { maxHeight: "18%" } } }}
                                      onChange={(event) => {
                                        const noteItems = state.hernia_noteItems;
                                        noteItems[index].year = event.target.value;
                                        noteItems[index].month = event.target.value !== 21 && noteItems[index].month === "N/A" ? "" : noteItems[index].month;
                                        setState((prevState) => ({
                                          ...prevState,
                                          hernia_noteItems: noteItems,
                                          enabledEndPEFA: false,
                                          clearanceDownloaded: false,
                                        }));
                                        setValue(`hernia_noteItems_year${index}`, event.target.value, { shouldValidate: true });
                                        handleValueChangeYearAndMonth(noteItems, "hernia");
                                      }}
                                    >
                                      {yearData.map((value) => {
                                        return (
                                          <MenuItem value={value} key={value}>
                                            {value}
                                          </MenuItem>
                                        );
                                      })}
                                      <MenuItem value={21}>More than 20 years</MenuItem>
                                    </Select>
                                    <input type="text" style={{ visibility: "hidden" }} name={`hernia_noteItems_year${index}`} defaultValue={element.year} ref={register} />
                                  </FormControl>
                                  <FormControl variant="outlined" className="selectMC">
                                    <Select
                                      name={`hernia_noteItems_month${index}`}
                                      color="primary"
                                      className="DayChanger"
                                      placeholder="MMM"
                                      value={element.month}
                                      MenuProps={{ PaperProps: { style: { maxHeight: "18%" } } }}
                                      onChange={(event) => {
                                        const noteItems = state.hernia_noteItems;
                                        noteItems[index].month = event.target.value;
                                        setState((prevState) => ({
                                          ...prevState,
                                          hernia_noteItems: noteItems,
                                          enabledEndPEFA: false,
                                          clearanceDownloaded: false,
                                        }));
                                        setValue(`hernia_noteItems_month${index}`, event.target.value, { shouldValidate: true });
                                        handleValueChangeYearAndMonth(noteItems, "hernia");
                                      }}
                                    >
                                      {state.hernia_noteItems[index].year === 21 ? (
                                        <MenuItem value={"N/A"} key={"N/A"}>
                                          {"N/A"}
                                        </MenuItem>
                                      ) : (
                                        <></>
                                      )}
                                      {monthData.map((value) => {
                                        return (
                                          <MenuItem value={value} key={value}>
                                            {value}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                    <input type="text" style={{ visibility: "hidden" }} name={`hernia_noteItems_month${index}`} defaultValue={element.month} ref={register} />
                                  </FormControl>
                                  {index === 0 ? (
                                    <Link
                                      className="addButton"
                                      onClick={() => {
                                        handleAddFromList(state.hernia_noteItems, "hernia_noteItems");
                                      }}
                                    >
                                      +
                                    </Link>
                                  ) : (
                                    <Link
                                      className="medicalConditionCancel"
                                      onClick={() => {
                                        handleRemoveFromList(index, state.hernia_noteItems, "hernia_noteItems");
                                      }}
                                    >
                                      Cancel
                                    </Link>
                                  )}
                                </div>
                                <RadioGroup
                                  aria-label="hernia_viewedMC"
                                  name="hernia_viewedMC"
                                  className="flexer"
                                  row
                                  value={element.viewedMC}
                                  onChange={(event) => {
                                    handleViewMC(event, index, state.hernia_noteItems, state.herniaMCs);
                                  }}
                                >
                                  <FormControl variant="outlined" className="selectOveride">
                                    <Select
                                      name="hernia_mcType"
                                      color="primary"
                                      className="DayChanger"
                                      placeholder="Select"
                                      value={element.mcType}
                                      MenuProps={{ PaperProps: { style: { maxHeight: "18%" } } }}
                                      onChange={(event) => {
                                        handleMCType(event, index, state.hernia_noteItems);
                                      }}
                                    >
                                      <MenuItem value={eMedicalClearanceType.SELECT}>{eMedicalClearanceType.SELECT}</MenuItem>
                                      {state.herniaMCs && state.herniaMCs.length > 0 ? (
                                        <MenuItem value={eMedicalClearanceType.EXISTINGMC}>{eMedicalClearanceType.EXISTINGMC}</MenuItem>
                                      ) : (
                                        <></>
                                      )}
                                      <MenuItem value={eMedicalClearanceType.PAPERMC}>{eMedicalClearanceType.PAPERMC}</MenuItem>
                                      <MenuItem value={eMedicalClearanceType.NOTPROVIDED}>{eMedicalClearanceType.NOTPROVIDED}</MenuItem>
                                      <MenuItem value={eMedicalClearanceType.NOTREQUIRED}>{eMedicalClearanceType.NOTREQUIRED}</MenuItem>
                                    </Select>
                                    <input type="text" style={{ visibility: "hidden" }} name={`hernia_noteItems_mcType${index}`} defaultValue={element.mcType} ref={register} />
                                  </FormControl>
                                  {element.mcType === eMedicalClearanceType.EXISTINGMC || (element.mcType === eMedicalClearanceType.PAPERMC && state.herniaPaperMCs.length > 0) ? (
                                    <>
                                      <FormControlLabel
                                        control={<Radio color="primary" name="hernia_viewedMC" id="hernia_viewedMC_accepted" value="ViewedAccepted" />}
                                        inputRef={register}
                                        label="MC Viewed and Accepted"
                                      />
                                      <FormControlLabel
                                        control={<Radio color="primary" name="hernia_viewedMC" id="hernia_viewedMC_notAccepted" value="NotAccepted" />}
                                        inputRef={register}
                                        label="MC Viewed and Not Accepted"
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {element.mcType === eMedicalClearanceType.PAPERMC && (!state.herniaPaperMCs || state.herniaPaperMCs.length < 1) ? (
                                    <div className="uploadLink">
                                      <Upload
                                        condition={"hernia"}
                                        conditionText={"Hernias"}
                                        paperMCs={state.herniaPaperMCs}
                                        otherInfo={element.note}
                                        pefaId={pefaAssessment?.pefArequestID}
                                        bookingId={pefaAssessment?.id}
                                        doctor={state.medicalPractitioner}
                                        practice={state.medicalPractice}
                                        startUpload={startUpload}
                                        handlePaperMC={updatePaperMC}
                                      />
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </RadioGroup>
                                {element.isMCRequired ? (
                                  <>
                                    <span className="addedList">
                                      <img src={WarningIcon} alt="Warning Icon" />
                                      <span>Please find this condition on the New Medical clearance request at the bottom of the list</span>
                                    </span>
                                  </>
                                ) : (
                                  <></>
                                )}
                                {element.note === "" ||
                                element.year === "" ||
                                element.month === "" ||
                                (element.mcType !== eMedicalClearanceType.NOTREQUIRED && element.mcType !== eMedicalClearanceType.NOTPROVIDED && element.viewedMC === "") ||
                                element.mcType === eMedicalClearanceType.SELECT ? (
                                  <div className="invalid-medical">Input is required</div>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        ))}
                      {errors.hernia?.message !== "" ? <div className="invalid-feedback">{errors.hernia?.message}</div> : <></>}
                      {errors.hernia_noteItems?.message !== "" ? <div className="invalid-feedback">{errors.hernia_noteItems?.message}</div> : <></>}
                      {state.herniaMCs && state.herniaMCs.length > 0 ? (
                        <>
                          {state.herniaMCs
                            .filter((x) => x.medicalCertificate !== null)
                            .map((medicalClearance: any, index: number) => (
                              <>
                                <div className="viewMedicalConditionFile" key={index}>
                                  <Typography className="medicalConditionName mr-2">
                                    {medicalClearance.condition} {medicalClearance.outcome ? ` - ${medicalClearance.outcome.toUpperCase()} ` : ""}
                                  </Typography>
                                </div>
                                <div className="viewMedicalConditionFile" key={index}>
                                  <Typography className="medicalConditionName ellipsis ml-3 mr-2">{medicalClearance.otherInfo}</Typography>
                                </div>
                                <div className="viewMedicalConditionFile" key={index}>
                                  {medicalClearance.medicalCertificate ? (
                                    <>
                                      <Link
                                        className="viewMC"
                                        underline="always"
                                        onClick={() => handleCertificateDownload(medicalClearance.medicalCertificate.id, medicalClearance.medicalCertificate.filename)}
                                      >
                                        Review Existing Medical Clearance
                                      </Link>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </>
                            ))}
                        </>
                      ) : (
                        <></>
                      )}
                      {state.herniaPaperMCs && state.herniaPaperMCs.length > 0 ? (
                        <>
                          {state.herniaPaperMCs.map((medicalClearance: any, index: number) => (
                            <>
                              <div className="viewMedicalConditionFile" key={index}>
                                <Typography className="medicalConditionName mr-2">{`${medicalClearance.condition} - PaperMC`}</Typography>
                                <Link
                                  className="cancelPaperMC"
                                  underline="always"
                                  onClick={() => cancelPaperMCNoteItems("hernia", state.hernia_noteItems, medicalClearance.id, medicalClearance.medicalClearanceId)}
                                >
                                  Cancel
                                </Link>
                              </div>
                              <div className="viewMedicalConditionFile" key={index}>
                                {medicalClearance ? (
                                  <>
                                    <Link className="viewMC" underline="always" onClick={() => handleCertificateDownload(medicalClearance.id, medicalClearance.fileName)}>
                                      Review Paper Medical Clearance
                                    </Link>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                      <hr className="separator"></hr>
                      {/* Hernias */}
                      {/* Any other Surgeries  */}
                      <div className="flexer" id="otherSurgeries">
                        <FormLabel className="bold blueText height-adjusted" component="legend">
                          Any other Surgeries*
                        </FormLabel>
                      </div>
                      <RadioGroup
                        aria-label="otherSurgeries"
                        name="otherSurgeries"
                        className="flexer"
                        row
                        value={state.otherSurgeries}
                        onChange={(event) => {
                          handleValueChangeForNoteAndDate(event, state.otherSurgeriesMCs, "Any other Surgeries");
                        }}
                      >
                        <FormControlLabel
                          control={<Radio color="primary" name="otherSurgeries" id="otherSurgeriesDenied" value="denied" />}
                          value={state.otherSurgeries}
                          inputRef={register}
                          label="Denied"
                        />
                        <FormControlLabel
                          control={<Radio color="primary" name="otherSurgeries" id="otherSurgeriesYes" value="yesMCRequired3Months" />}
                          inputRef={register}
                          label="Yes – MC required if less than 3 months"
                        />
                      </RadioGroup>
                      {state.otherSurgeries === "yesMCRequired3Months" &&
                        state.otherSurgeries_noteItems.map((element, index) => (
                          <>
                            {!element.isDeleted ? (
                              <>
                                <div className="note-item">
                                  <OutlinedInput
                                    key={index}
                                    color="primary"
                                    name="otherSurgeries_noteItems"
                                    value={element.name}
                                    autoComplete="off"
                                    className="small-note"
                                    inputRef={register}
                                    placeholder="If Yes, describe condition here*"
                                    defaultValue={element.note}
                                    onChange={(event) => handleValueChangeNote(event, index, state.otherSurgeries_noteItems, "otherSurgeries_noteItems")}
                                  />
                                  <FormControl variant="outlined" className="selectMC">
                                    <Select
                                      name={`otherSurgeries_noteItems_year${index}`}
                                      color="primary"
                                      className="DayChanger"
                                      placeholder="YYYY"
                                      value={element.year}
                                      MenuProps={{ PaperProps: { style: { maxHeight: "18%" } } }}
                                      onChange={(event) => {
                                        calculateYearAndMonthForClearance(
                                          index,
                                          event,
                                          "otherSurgeries",
                                          state.otherSurgeries_noteItems,
                                          3,
                                          "Any other Surgeries",
                                          state.otherSurgeriesMCs
                                        );
                                      }}
                                    >
                                      {yearData.map((value) => {
                                        return (
                                          <MenuItem value={value} key={value}>
                                            {value}
                                          </MenuItem>
                                        );
                                      })}
                                      <MenuItem value={21}>More than 20 years</MenuItem>
                                    </Select>
                                    <input type="text" style={{ visibility: "hidden" }} name={`otherSurgeries_noteItems_year${index}`} defaultValue={element.year} ref={register} />
                                  </FormControl>
                                  <FormControl variant="outlined" className="selectMC">
                                    <Select
                                      name={`otherSurgeries_noteItems_month${index}`}
                                      color="primary"
                                      className="DayChanger"
                                      placeholder="MMM"
                                      value={element.month}
                                      MenuProps={{ PaperProps: { style: { maxHeight: "18%" } } }}
                                      onChange={(event) => {
                                        calculateYearAndMonthForClearance(
                                          index,
                                          event,
                                          "otherSurgeries",
                                          state.otherSurgeries_noteItems,
                                          3,
                                          "Any other Surgeries",
                                          state.otherSurgeriesMCs
                                        );
                                      }}
                                    >
                                      {state.otherSurgeries_noteItems[index].year === 21 ? (
                                        <MenuItem value={"N/A"} key={"N/A"}>
                                          {"N/A"}
                                        </MenuItem>
                                      ) : (
                                        <></>
                                      )}
                                      {monthData.map((value) => {
                                        return (
                                          <MenuItem value={value} key={value}>
                                            {value}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                    <input
                                      type="text"
                                      style={{ visibility: "hidden" }}
                                      name={`otherSurgeries_noteItems_month${index}`}
                                      defaultValue={element.month}
                                      ref={register}
                                    />
                                  </FormControl>
                                  {index === 0 ? (
                                    <Link
                                      className="addButton"
                                      onClick={() => {
                                        handleAddFromList(state.otherSurgeries_noteItems, "otherSurgeries_noteItems");
                                      }}
                                    >
                                      +
                                    </Link>
                                  ) : (
                                    <Link
                                      className="medicalConditionCancel"
                                      onClick={() => {
                                        handleRemoveFromList(index, state.otherSurgeries_noteItems, "otherSurgeries_noteItems");
                                      }}
                                    >
                                      Cancel
                                    </Link>
                                  )}
                                </div>
                                {element.monthDiff < 3 ? (
                                  <RadioGroup
                                    aria-label="otherSurgeries_viewedMC"
                                    name="otherSurgeries_viewedMC"
                                    className="flexer"
                                    row
                                    value={element.viewedMC}
                                    onChange={(event) => {
                                      handleViewMC(event, index, state.otherSurgeries_noteItems, state.otherSurgeriesMCs);
                                    }}
                                  >
                                    <FormControl variant="outlined" className="selectOveride">
                                      <Select
                                        name="otherSurgeries_mcType"
                                        color="primary"
                                        className="DayChanger"
                                        placeholder="Select"
                                        value={element.mcType}
                                        MenuProps={{ PaperProps: { style: { maxHeight: "18%" } } }}
                                        onChange={(event) => {
                                          handleMCType(event, index, state.otherSurgeries_noteItems);
                                        }}
                                      >
                                        <MenuItem value={eMedicalClearanceType.SELECT}>{eMedicalClearanceType.SELECT}</MenuItem>
                                        {state.otherSurgeriesMCs && state.otherSurgeriesMCs.length > 0 ? (
                                          <MenuItem value={eMedicalClearanceType.EXISTINGMC}>{eMedicalClearanceType.EXISTINGMC}</MenuItem>
                                        ) : (
                                          <></>
                                        )}
                                        <MenuItem value={eMedicalClearanceType.PAPERMC}>{eMedicalClearanceType.PAPERMC}</MenuItem>
                                        <MenuItem value={eMedicalClearanceType.NOTPROVIDED}>{eMedicalClearanceType.NOTPROVIDED}</MenuItem>
                                      </Select>
                                      <input
                                        type="text"
                                        style={{ visibility: "hidden" }}
                                        name={`otherSurgeries_noteItems_mcType${index}`}
                                        defaultValue={element.mcType}
                                        ref={register}
                                      />
                                    </FormControl>
                                    {element.mcType === eMedicalClearanceType.EXISTINGMC ||
                                    (element.mcType === eMedicalClearanceType.PAPERMC && state.otherSurgeriesPaperMCs.length > 0) ? (
                                      <>
                                        <FormControlLabel
                                          control={<Radio color="primary" name="otherSurgeries_viewedMC" id="otherSurgeries_viewedMC_accepted" value="ViewedAccepted" />}
                                          inputRef={register}
                                          label="MC Viewed and Accepted"
                                        />
                                        <FormControlLabel
                                          control={<Radio color="primary" name="otherSurgeries_viewedMC" id="otherSurgeries_viewedMC_notAccepted" value="NotAccepted" />}
                                          inputRef={register}
                                          label="MC Viewed and Not Accepted"
                                        />
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {element.mcType === eMedicalClearanceType.PAPERMC && (!state.otherSurgeriesPaperMCs || state.otherSurgeriesPaperMCs.length < 1) ? (
                                      <div className="uploadLink">
                                        <Upload
                                          condition={"otherSurgeries"}
                                          conditionText={"Any other Surgeries"}
                                          paperMCs={state.otherSurgeriesPaperMCs}
                                          otherInfo={element.note}
                                          pefaId={pefaAssessment?.pefArequestID}
                                          bookingId={pefaAssessment?.id}
                                          doctor={state.medicalPractitioner}
                                          practice={state.medicalPractice}
                                          startUpload={startUpload}
                                          handlePaperMC={updatePaperMC}
                                        />
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </RadioGroup>
                                ) : (
                                  <></>
                                )}
                                {element.monthDiff < 3 && element.isMCRequired ? (
                                  <>
                                    <span className="addedList">
                                      <img src={WarningIcon} alt="Warning Icon" />
                                      <span>Please find this condition on the New Medical clearance request at the bottom of the list</span>
                                    </span>
                                  </>
                                ) : (
                                  <></>
                                )}
                                {element.note === "" ||
                                element.year === "" ||
                                element.month === "" ||
                                (element.monthDiff < 3 && element.mcType !== eMedicalClearanceType.NOTPROVIDED && element.viewedMC === "") ||
                                (element.monthDiff < 3 && element.mcType === eMedicalClearanceType.SELECT) ? (
                                  <div className="invalid-medical">Input is required</div>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        ))}
                      {errors.otherSurgeries?.message !== "" ? <div className="invalid-feedback">{errors.otherSurgeries?.message}</div> : <></>}
                      {errors.otherSurgeries_noteItems?.message !== "" ? <div className="invalid-feedback">{errors.otherSurgeries_noteItems?.message}</div> : <></>}
                      {state.otherSurgeriesMCs && state.otherSurgeriesMCs.length > 0 ? (
                        <>
                          {state.otherSurgeriesMCs
                            .filter((x) => x.medicalCertificate !== null)
                            .map((medicalClearance: any, index: number) => (
                              <>
                                <div className="viewMedicalConditionFile" key={index}>
                                  <Typography className="medicalConditionName mr-2">
                                    {medicalClearance.condition} {medicalClearance.outcome ? ` - ${medicalClearance.outcome.toUpperCase()} ` : ""}
                                  </Typography>
                                </div>
                                <div className="viewMedicalConditionFile" key={index}>
                                  <Typography className="medicalConditionName ellipsis ml-3 mr-2">{medicalClearance.otherInfo}</Typography>
                                </div>
                                <div className="viewMedicalConditionFile" key={index}>
                                  {medicalClearance.medicalCertificate ? (
                                    <>
                                      <Link
                                        className="viewMC"
                                        underline="always"
                                        onClick={() => handleCertificateDownload(medicalClearance.medicalCertificate.id, medicalClearance.medicalCertificate.filename)}
                                      >
                                        Review Existing Medical Clearance
                                      </Link>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </>
                            ))}
                        </>
                      ) : (
                        <></>
                      )}
                      {state.otherSurgeriesPaperMCs && state.otherSurgeriesPaperMCs.length > 0 ? (
                        <>
                          {state.otherSurgeriesPaperMCs.map((medicalClearance: any, index: number) => (
                            <>
                              <div className="viewMedicalConditionFile" key={index}>
                                <Typography className="medicalConditionName mr-2">{`${medicalClearance.condition} - PaperMC`}</Typography>
                                <Link
                                  className="cancelPaperMC"
                                  underline="always"
                                  onClick={() => cancelPaperMCNoteItems("otherSurgeries", state.otherSurgeries_noteItems, medicalClearance.id, medicalClearance.medicalClearanceId)}
                                >
                                  Cancel
                                </Link>
                              </div>
                              <div className="viewMedicalConditionFile" key={index}>
                                {medicalClearance ? (
                                  <>
                                    <Link className="viewMC" underline="always" onClick={() => handleCertificateDownload(medicalClearance.id, medicalClearance.fileName)}>
                                      Review Paper Medical Clearance
                                    </Link>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                      <hr className="separator"></hr>
                      {/* Any other Surgeries */}
                      {/* Arthritis */}
                      <div className="flexer" id="arthritis">
                        <FormLabel className="bold blueText height-adjusted" component="legend">
                          Arthritis*
                        </FormLabel>
                      </div>
                      <RadioGroup aria-label="arthritis" name="arthritis" className="flexer" row value={state.arthritis} onChange={handleValueChange}>
                        <FormControlLabel
                          control={<Radio color="primary" name="arthritis" id="arthritisDenied" value="denied" />}
                          value={state.arthritis}
                          inputRef={register}
                          label="Denied"
                        />
                        <FormControlLabel
                          control={<Radio color="primary" name="arthritis" id="arthritisYes" value="yesMCNotRequired" />}
                          inputRef={register}
                          label="Yes - MC not required"
                        />
                      </RadioGroup>
                      {state.arthritis === "yesMCNotRequired" ? (
                        <OutlinedInput
                          color="primary"
                          name="arthritisNote"
                          autoComplete="off"
                          className="med-note"
                          inputRef={register}
                          placeholder="If Yes, describe condition here*"
                          defaultValue={state.arthritisNote}
                          onChange={handleValueChange}
                        />
                      ) : (
                        <></>
                      )}
                      {errors.arthritis?.message !== "" ? <div className="invalid-feedback">{errors.arthritis?.message}</div> : <></>}
                      {errors.arthritisNote?.message !== "" ? <div className="invalid-feedback">{errors.arthritisNote?.message}</div> : <></>}
                      {state.arthritisMCs && state.arthritisMCs.length > 0 ? (
                        <>
                          {state.arthritisMCs
                            .filter((x) => x.medicalCertificate !== null)
                            .map((medicalClearance: any, index: number) => (
                              <>
                                <div className="viewMedicalConditionFile" key={index}>
                                  <Typography className="medicalConditionName mr-2">
                                    {medicalClearance.condition} {medicalClearance.outcome ? ` - ${medicalClearance.outcome.toUpperCase()} ` : ""}
                                  </Typography>
                                </div>
                                <div className="viewMedicalConditionFile" key={index}>
                                  <Typography className="medicalConditionName ellipsis ml-3 mr-2">{medicalClearance.otherInfo}</Typography>
                                </div>
                                <div className="viewMedicalConditionFile" key={index}>
                                  {medicalClearance.medicalCertificate ? (
                                    <>
                                      <Link
                                        className="viewMC"
                                        underline="always"
                                        onClick={() => handleCertificateDownload(medicalClearance.medicalCertificate.id, medicalClearance.medicalCertificate.filename)}
                                      >
                                        Review Existing Medical Clearance
                                      </Link>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </>
                            ))}
                        </>
                      ) : (
                        <></>
                      )}
                      {state.arthritisPaperMCs && state.arthritisPaperMCs.length > 0 ? (
                        <>
                          {state.arthritisPaperMCs.map((medicalClearance: any, index: number) => (
                            <>
                              <div className="viewMedicalConditionFile" key={index}>
                                <Typography className="medicalConditionName mr-2">{`${medicalClearance.condition} - PaperMC`}</Typography>
                                <Link
                                  className="cancelPaperMC"
                                  underline="always"
                                  onClick={() => cancelPaperMC("arthritis", medicalClearance.id, medicalClearance.medicalClearanceId)}
                                >
                                  Cancel
                                </Link>
                              </div>
                              <div className="viewMedicalConditionFile" key={index}>
                                {medicalClearance ? (
                                  <>
                                    <Link className="viewMC" underline="always" onClick={() => handleCertificateDownload(medicalClearance.id, medicalClearance.fileName)}>
                                      Review Paper Medical Clearance
                                    </Link>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                      <hr className="separator"></hr>
                      {/* Arthritis */}
                      {/* Cancer */}
                      <div className="flexer">
                        <FormLabel className="bold blueText height-adjusted" component="legend">
                          Cancer*
                        </FormLabel>
                      </div>
                      <RadioGroup aria-label="cancer" name="cancer" className="flexer" row value={state.cancer} onChange={handleValueChange}>
                        <FormControlLabel
                          control={<Radio color="primary" name="cancer" id="cancerDenied" value="denied" />}
                          value={state.cancer}
                          inputRef={register}
                          label="Denied"
                        />
                        <FormControlLabel
                          control={<Radio color="primary" name="cancer" id="cancerYes" value="yesMCNotRequired" />}
                          inputRef={register}
                          label="Yes - MC not required"
                        />
                      </RadioGroup>
                      {state.cancer === "yesMCNotRequired" ? (
                        <OutlinedInput
                          color="primary"
                          name="cancerNote"
                          autoComplete="off"
                          className="med-note"
                          inputRef={register}
                          placeholder="If Yes, describe condition here*"
                          defaultValue={state.cancerNote}
                          onChange={handleValueChange}
                        />
                      ) : (
                        <></>
                      )}
                      {errors.cancer?.message !== "" ? <div className="invalid-feedback">{errors.cancer?.message}</div> : <></>}
                      {errors.cancerNote?.message !== "" ? <div className="invalid-feedback">{errors.cancerNote?.message}</div> : <></>}
                      {state.cancerMCs && state.cancerMCs.length > 0 ? (
                        <>
                          {state.cancerMCs
                            .filter((x) => x.medicalCertificate !== null)
                            .map((medicalClearance: any, index: number) => (
                              <>
                                <div className="viewMedicalConditionFile" key={index}>
                                  <Typography className="medicalConditionName mr-2">
                                    {medicalClearance.condition} {medicalClearance.outcome ? ` - ${medicalClearance.outcome.toUpperCase()} ` : ""}
                                  </Typography>
                                </div>
                                <div className="viewMedicalConditionFile" key={index}>
                                  <Typography className="medicalConditionName ellipsis ml-3 mr-2">{medicalClearance.otherInfo}</Typography>
                                </div>
                                <div className="viewMedicalConditionFile" key={index}>
                                  {medicalClearance.medicalCertificate ? (
                                    <>
                                      <Link
                                        className="viewMC"
                                        underline="always"
                                        onClick={() => handleCertificateDownload(medicalClearance.medicalCertificate.id, medicalClearance.medicalCertificate.filename)}
                                      >
                                        Review Existing Medical Clearance
                                      </Link>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </>
                            ))}
                        </>
                      ) : (
                        <></>
                      )}
                      {state.cancerPaperMCs && state.cancerPaperMCs.length > 0 ? (
                        <>
                          {state.cancerPaperMCs.map((medicalClearance: any, index: number) => (
                            <>
                              <div className="viewMedicalConditionFile" key={index}>
                                <Typography className="medicalConditionName mr-2">{`${medicalClearance.condition} - PaperMC`}</Typography>
                                <Link
                                  className="cancelPaperMC"
                                  underline="always"
                                  onClick={() => cancelPaperMC("cancer", medicalClearance.id, medicalClearance.medicalClearanceId)}
                                >
                                  Cancel
                                </Link>
                              </div>
                              <div className="viewMedicalConditionFile" key={index}>
                                {medicalClearance ? (
                                  <>
                                    <Link className="viewMC" underline="always" onClick={() => handleCertificateDownload(medicalClearance.id, medicalClearance.fileName)}>
                                      Review Paper Medical Clearance
                                    </Link>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                      <hr className="separator"></hr>
                      {/* Cancer */}
                      {/* Asthma or other lung condition */}
                      <div className="flexer">
                        <FormLabel className="bold blueText height-adjusted" component="legend">
                          Asthma or other lung condition*
                        </FormLabel>
                      </div>
                      <RadioGroup
                        aria-label="asthma"
                        name="asthma"
                        className="flexer"
                        row
                        value={state.asthma}
                        onChange={(event) => {
                          handleValueChange(event);
                          setState((prevState) => ({
                            ...prevState,
                            otherLungConditionNote: "",
                          }));
                        }}
                      >
                        <FormControlLabel
                          control={<Radio color="primary" name="asthma" id="asthmaDenied" value="denied" />}
                          value={state.asthma}
                          inputRef={register}
                          label="Denied"
                        />
                        <FormControlLabel
                          control={<Radio color="primary" name="asthma" id="asthmaYes" value="yesMCNotRequired" />}
                          inputRef={register}
                          label="Yes - MC not required"
                        />
                      </RadioGroup>
                      {state.asthma === "yesMCNotRequired" ? (
                        <OutlinedInput
                          color="primary"
                          name="otherLungConditionNote"
                          autoComplete="off"
                          className="med-note"
                          inputRef={register}
                          placeholder="If Yes, describe condition here*"
                          defaultValue={state.otherLungConditionNote}
                          onChange={handleValueChange}
                        />
                      ) : (
                        <></>
                      )}
                      {errors.asthma?.message !== "" ? <div className="invalid-feedback">{errors.asthma?.message}</div> : <></>}
                      {errors.otherLungConditionNote?.message !== "" ? <div className="invalid-feedback">{errors.otherLungConditionNote?.message}</div> : <></>}
                      {state.asthmaMCs && state.asthmaMCs.length > 0 ? (
                        <>
                          {state.asthmaMCs
                            .filter((x) => x.medicalCertificate !== null)
                            .map((medicalClearance: any, index: number) => (
                              <>
                                <div className="viewMedicalConditionFile" key={index}>
                                  <Typography className="medicalConditionName mr-2">
                                    {medicalClearance.condition} {medicalClearance.outcome ? ` - ${medicalClearance.outcome.toUpperCase()} ` : ""}
                                  </Typography>
                                </div>
                                <div className="viewMedicalConditionFile" key={index}>
                                  <Typography className="medicalConditionName ellipsis ml-3 mr-2">{medicalClearance.otherInfo}</Typography>
                                </div>
                                <div className="viewMedicalConditionFile" key={index}>
                                  {medicalClearance.medicalCertificate ? (
                                    <>
                                      <Link
                                        className="viewMC"
                                        underline="always"
                                        onClick={() => handleCertificateDownload(medicalClearance.medicalCertificate.id, medicalClearance.medicalCertificate.filename)}
                                      >
                                        Review Existing Medical Clearance
                                      </Link>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </>
                            ))}
                        </>
                      ) : (
                        <></>
                      )}
                      {state.asthmaPaperMCs && state.asthmaPaperMCs.length > 0 ? (
                        <>
                          {state.asthmaPaperMCs.map((medicalClearance: any, index: number) => (
                            <>
                              <div className="viewMedicalConditionFile" key={index}>
                                <Typography className="medicalConditionName mr-2">{`${medicalClearance.condition} - PaperMC`}</Typography>
                                <Link
                                  className="cancelPaperMC"
                                  underline="always"
                                  onClick={() => cancelPaperMC("asthma", medicalClearance.id, medicalClearance.medicalClearanceId)}
                                >
                                  Cancel
                                </Link>
                              </div>
                              <div className="viewMedicalConditionFile" key={index}>
                                {medicalClearance ? (
                                  <>
                                    <Link className="viewMC" underline="always" onClick={() => handleCertificateDownload(medicalClearance.id, medicalClearance.fileName)}>
                                      Review Paper Medical Clearance
                                    </Link>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                      <hr className="separator"></hr>
                      {/* Asthma or other lung condition */}
                      {/* Epilepsy */}
                      <div className="flexer" id="epilepsy">
                        <FormLabel className="bold blueText height-adjusted" component="legend">
                          Epilepsy*
                        </FormLabel>
                      </div>
                      <RadioGroup
                        aria-label="epilepsy"
                        name="epilepsy"
                        className="flexer"
                        row
                        value={state.epilepsy}
                        onChange={(event) => handleOtherMCRequiredChange(event, state.epilepsyMCs, "Epilepsy", "epilepsy")}
                      >
                        <div className="med-fullWidth">
                          <FormControlLabel
                            control={<Radio color="primary" name="epilepsy" id="epilepsyDenied" value="denied" />}
                            value={state.epilepsy}
                            inputRef={register}
                            label="Denied"
                          />
                          <FormControlLabel
                            control={<Radio color="primary" name="epilepsy" id="epilepsyYesControlled" value="yesControlled" />}
                            inputRef={register}
                            label="Yes - Controlled - MC not required"
                          />
                        </div>
                        <FormControlLabel
                          control={<Radio color="primary" name="epilepsy" id="epilepsyYesNotControlled" value="yesNotControlled" />}
                          inputRef={register}
                          label="Yes - Not controlled - MC assessor discretion"
                        />
                        {state.epilepsy === "yesNotControlled" ? (
                          <FormControl variant="outlined" className="selectOveride">
                            <Select
                              name="epilepsyMCAssessor"
                              color="primary"
                              className="DayChanger"
                              placeholder="Select"
                              value={state.epilepsyMCAssessor}
                              MenuProps={{ PaperProps: { style: { maxHeight: "18%" } } }}
                              onChange={(event) => {
                                setState((prevState) => ({
                                  ...prevState,
                                  epilepsyMCAssessor: event.target.value,
                                }));
                                setValue("epilepsyMCAssessor", event.target.value, { shouldValidate: true });
                                handleOtherMCRequiredChange(event, state.epilepsyMCs, "Epilepsy", "epilepsy");
                              }}
                            >
                              <MenuItem value={eMedicalClearanceRequired.SELECT}>{eMedicalClearanceRequired.SELECT}</MenuItem>
                              <MenuItem value={eMedicalClearanceRequired.NO}>{eMedicalClearanceRequired.NO}</MenuItem>
                              <MenuItem value={eMedicalClearanceRequired.YES}>{eMedicalClearanceRequired.YES}</MenuItem>
                            </Select>
                            <input type="text" style={{ visibility: "hidden" }} name="epilepsyMCAssessor" defaultValue={state.epilepsyMCAssessor} ref={register} />
                          </FormControl>
                        ) : (
                          <></>
                        )}
                      </RadioGroup>
                      {state.epilepsy === "yesControlled" || state.epilepsy === "yesNotControlled" ? (
                        <OutlinedInput
                          color="primary"
                          name="epilepsyNote"
                          autoComplete="off"
                          className="med-note"
                          inputRef={register}
                          placeholder="If Yes, describe condition here*"
                          defaultValue={state.epilepsyNote}
                          onChange={handleValueChange}
                        />
                      ) : (
                        <></>
                      )}
                      {state.epilepsyMCAssessor === eMedicalClearanceRequired.YES ? (
                        <RadioGroup
                          aria-label="epilepsyViewedMC"
                          name="epilepsyViewedMC"
                          className="flexer"
                          row
                          value={state.epilepsyViewedMC}
                          onChange={(event) => {
                            handleOtherViewMC(event);
                          }}
                        >
                          <FormControl variant="outlined" className="selectOveride">
                            <Select
                              name="epilepsyMCType"
                              color="primary"
                              className="DayChanger"
                              placeholder="Select"
                              value={state.epilepsyMCType}
                              MenuProps={{ PaperProps: { style: { maxHeight: "18%" } } }}
                              onChange={(event) => {
                                handleOtherMCType(event);
                              }}
                            >
                              <MenuItem value={eMedicalClearanceType.SELECT}>{eMedicalClearanceType.SELECT}</MenuItem>
                              {state.epilepsyMCs && state.epilepsyMCs.length > 0 ? (
                                <MenuItem value={eMedicalClearanceType.EXISTINGMC}>{eMedicalClearanceType.EXISTINGMC}</MenuItem>
                              ) : (
                                <></>
                              )}
                              <MenuItem value={eMedicalClearanceType.PAPERMC}>{eMedicalClearanceType.PAPERMC}</MenuItem>
                              <MenuItem value={eMedicalClearanceType.NOTPROVIDED}>{eMedicalClearanceType.NOTPROVIDED}</MenuItem>
                            </Select>
                            <input type="text" style={{ visibility: "hidden" }} name={`epilepsyMCType`} defaultValue={state.epilepsyMCType} ref={register} />
                          </FormControl>
                          {state.epilepsyMCType === eMedicalClearanceType.EXISTINGMC ||
                          (state.epilepsyMCType === eMedicalClearanceType.PAPERMC && state.epilepsyPaperMCs.length > 0) ? (
                            <>
                              <FormControlLabel
                                control={<Radio color="primary" name="epilepsyViewedMC" id="epilepsy_viewedMC_accepted" value="ViewedAccepted" />}
                                inputRef={register}
                                label="MC Viewed and Accepted"
                              />
                              <FormControlLabel
                                control={<Radio color="primary" name="epilepsyViewedMC" id="epilepsy_viewedMC_notAccepted" value="NotAccepted" />}
                                inputRef={register}
                                label="MC Viewed and Not Accepted"
                              />
                            </>
                          ) : (
                            <></>
                          )}
                          {state.epilepsyMCType === eMedicalClearanceType.PAPERMC && (!state.epilepsyPaperMCs || state.epilepsyPaperMCs.length < 1) ? (
                            <div className="uploadLink">
                              <Upload
                                condition={"epilepsy"}
                                conditionText={"Epilepsy"}
                                paperMCs={state.epilepsyPaperMCs}
                                otherInfo={state.epilepsyNote}
                                pefaId={pefaAssessment?.pefArequestID}
                                bookingId={pefaAssessment?.id}
                                doctor={state.medicalPractitioner}
                                practice={state.medicalPractice}
                                startUpload={startUpload}
                                handlePaperMC={updatePaperMC}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                        </RadioGroup>
                      ) : (
                        <></>
                      )}
                      {state.epilepsy === "" ||
                      state.epilepsy === "denied" ||
                      state.epilepsy === "yesControlled" ||
                      (state.epilepsy === "yesNotControlled" &&
                        (state.epilepsyMCAssessor === eMedicalClearanceRequired.SELECT || state.epilepsyMCAssessor === eMedicalClearanceRequired.NO)) ||
                      (state.epilepsy === "yesNotControlled" && state.epilepsyMCAssessor === eMedicalClearanceRequired.YES && state.epilepsyViewedMC === "ViewedAccepted") ? (
                        <></>
                      ) : (
                        <>
                          <div className="addedList">
                            <img src={WarningIcon} alt="Warning Icon" />
                            <span>Please find this condition on the New Medical clearance request at the bottom of the list</span>
                          </div>
                        </>
                      )}
                      {(state.epilepsyMCAssessor === eMedicalClearanceRequired.YES &&
                        state.epilepsyMCType !== eMedicalClearanceType.NOTPROVIDED &&
                        state.epilepsyViewedMC === "") ||
                      ((state.epilepsy === "yesControlled" || state.epilepsy === "yesNotControlled") && state.epilepsyNote === "") ||
                      (state.epilepsy === "yesNotControlled" && state.epilepsyMCAssessor === eMedicalClearanceRequired.SELECT) ||
                      (state.epilepsyMCAssessor === eMedicalClearanceRequired.YES && state.epilepsyMCType === eMedicalClearanceType.SELECT) ? (
                        <div className="invalid-medical">Input is required</div>
                      ) : errors.epilepsy?.message !== "" ? (
                        <div className="invalid-feedback">{errors.epilepsy?.message}</div>
                      ) : errors.epilepsyMCAssessor?.message !== "" ? (
                        <div className="invalid-feedback">{errors.epilepsyMCAssessor?.message}</div>
                      ) : errors.epilepsyNote?.message !== "" ? (
                        <div className="invalid-feedback">{errors.epilepsyNote?.message}</div>
                      ) : (
                        <></>
                      )}
                      {state.epilepsyMCs && state.epilepsyMCs.length > 0 ? (
                        <>
                          {state.epilepsyMCs
                            .filter((x) => x.medicalCertificate !== null)
                            .map((medicalClearance: any, index: number) => (
                              <>
                                <div className="viewMedicalConditionFile" key={index}>
                                  <Typography className="medicalConditionName mr-2">
                                    {medicalClearance.condition} {medicalClearance.outcome ? ` - ${medicalClearance.outcome.toUpperCase()} ` : ""}
                                  </Typography>
                                </div>
                                <div className="viewMedicalConditionFile" key={index}>
                                  <Typography className="medicalConditionName ellipsis ml-3 mr-2">{medicalClearance.otherInfo}</Typography>
                                </div>
                                <div className="viewMedicalConditionFile" key={index}>
                                  {medicalClearance.medicalCertificate ? (
                                    <>
                                      <Link
                                        className="viewMC"
                                        underline="always"
                                        onClick={() => handleCertificateDownload(medicalClearance.medicalCertificate.id, medicalClearance.medicalCertificate.filename)}
                                      >
                                        Review Existing Medical Clearance
                                      </Link>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </>
                            ))}
                        </>
                      ) : (
                        <></>
                      )}
                      {state.epilepsyPaperMCs && state.epilepsyPaperMCs.length > 0 ? (
                        <>
                          {state.epilepsyPaperMCs.map((medicalClearance: any, index: number) => (
                            <>
                              <div className="viewMedicalConditionFile" key={index}>
                                <Typography className="medicalConditionName mr-2">{`${medicalClearance.condition} - PaperMC`}</Typography>
                                <Link
                                  className="cancelPaperMC"
                                  underline="always"
                                  onClick={() => cancelPaperMC("epilepsy", medicalClearance.id, medicalClearance.medicalClearanceId)}
                                >
                                  Cancel
                                </Link>
                              </div>
                              <div className="viewMedicalConditionFile" key={index}>
                                {medicalClearance ? (
                                  <>
                                    <Link className="viewMC" underline="always" onClick={() => handleCertificateDownload(medicalClearance.id, medicalClearance.fileName)}>
                                      Review Paper Medical Clearance
                                    </Link>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                      <hr className="separator"></hr>
                      {/* Epilepsy */}
                      {/* Neurological conditions */}
                      <div className="flexer">
                        <FormLabel className="bold blueText height-adjusted" component="legend">
                          Neurological conditions*
                        </FormLabel>
                      </div>
                      <RadioGroup aria-label="neurological" name="neurological" className="flexer" row value={state.neurological} onChange={handleValueChange}>
                        <FormControlLabel
                          control={<Radio color="primary" name="neurological" id="neurologicalDenied" value="denied" />}
                          value={state.neurological}
                          inputRef={register}
                          label="Denied"
                        />
                        <FormControlLabel
                          control={<Radio color="primary" name="neurological" id="neurologicalYes" value="yesMCNotRequired" />}
                          inputRef={register}
                          label="Yes - MC not required"
                        />
                      </RadioGroup>
                      {state.neurological === "yesMCNotRequired" ? (
                        <OutlinedInput
                          color="primary"
                          name="neurologicalNote"
                          autoComplete="off"
                          className="med-note"
                          inputRef={register}
                          placeholder="If Yes, describe condition here*"
                          defaultValue={state.neurologicalNote}
                          onChange={handleValueChange}
                        />
                      ) : (
                        <></>
                      )}
                      {errors.neurological?.message !== "" ? <div className="invalid-feedback">{errors.neurological?.message}</div> : <></>}
                      {errors.neurologicalNote?.message !== "" ? <div className="invalid-feedback">{errors.neurologicalNote?.message}</div> : <></>}
                      {state.neurologicalMCs && state.neurologicalMCs.length > 0 ? (
                        <>
                          {state.neurologicalMCs
                            .filter((x) => x.medicalCertificate !== null)
                            .map((medicalClearance: any, index: number) => (
                              <>
                                <div className="viewMedicalConditionFile" key={index}>
                                  <Typography className="medicalConditionName mr-2">
                                    {medicalClearance.condition} {medicalClearance.outcome ? ` - ${medicalClearance.outcome.toUpperCase()} ` : ""}
                                  </Typography>
                                </div>
                                <div className="viewMedicalConditionFile" key={index}>
                                  <Typography className="medicalConditionName ellipsis ml-3 mr-2">{medicalClearance.otherInfo}</Typography>
                                </div>
                                <div className="viewMedicalConditionFile" key={index}>
                                  {medicalClearance.medicalCertificate ? (
                                    <>
                                      <Link
                                        className="viewMC"
                                        underline="always"
                                        onClick={() => handleCertificateDownload(medicalClearance.medicalCertificate.id, medicalClearance.medicalCertificate.filename)}
                                      >
                                        Review Existing Medical Clearance
                                      </Link>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </>
                            ))}
                        </>
                      ) : (
                        <></>
                      )}
                      {state.neurologicalPaperMCs && state.neurologicalPaperMCs.length > 0 ? (
                        <>
                          {state.neurologicalPaperMCs.map((medicalClearance: any, index: number) => (
                            <>
                              <div className="viewMedicalConditionFile" key={index}>
                                <Typography className="medicalConditionName mr-2">{`${medicalClearance.condition} - PaperMC`}</Typography>
                                <Link
                                  className="cancelPaperMC"
                                  underline="always"
                                  onClick={() => cancelPaperMC("neurological", medicalClearance.id, medicalClearance.medicalClearanceId)}
                                >
                                  Cancel
                                </Link>
                              </div>
                              <div className="viewMedicalConditionFile" key={index}>
                                {medicalClearance ? (
                                  <>
                                    <Link className="viewMC" underline="always" onClick={() => handleCertificateDownload(medicalClearance.id, medicalClearance.fileName)}>
                                      Review Paper Medical Clearance
                                    </Link>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                      <hr className="separator"></hr>
                      {/* Neurological conditions*/}
                      {/* Diabetes */}
                      <div className="flexer" id="diabetes">
                        <FormLabel className="bold blueText height-adjusted" component="legend">
                          Diabetes*
                        </FormLabel>
                      </div>
                      <RadioGroup
                        aria-label="diabetes"
                        name="diabetes"
                        className="flexer"
                        row
                        value={state.diabetes}
                        onChange={(event) => handleOtherMCRequiredChange(event, state.diabetesMCs, "Diabetes", "diabetes")}
                      >
                        <div className="med-fullWidth">
                          <FormControlLabel
                            control={<Radio color="primary" name="diabetes" id="diabetesDenied" value="denied" />}
                            value={state.diabetes}
                            inputRef={register}
                            label="Denied"
                          />
                          <FormControlLabel
                            control={<Radio color="primary" name="diabetes" id="diabetesControlled" value="yesControlled" />}
                            inputRef={register}
                            label="Yes - Controlled - MC not required"
                          />
                        </div>
                        <FormControlLabel
                          control={<Radio color="primary" name="diabetes" id="diabetesNotControlled" value="yesNotControlled" />}
                          inputRef={register}
                          label="Yes - Not controlled - MC assessor discretion"
                        />
                        {state.diabetes === "yesNotControlled" ? (
                          <FormControl variant="outlined" className="selectOveride">
                            <Select
                              name="diabetesMCAssessor"
                              color="primary"
                              className="DayChanger"
                              placeholder="Select"
                              value={state.diabetesMCAssessor}
                              MenuProps={{ PaperProps: { style: { maxHeight: "18%" } } }}
                              onChange={(event) => {
                                setState((prevState) => ({
                                  ...prevState,
                                  diabetesMCAssessor: event.target.value,
                                }));
                                setValue("diabetesMCAssessor", event.target.value, { shouldValidate: true });
                                handleOtherMCRequiredChange(event, state.diabetesMCs, "Diabetes", "diabetes");
                              }}
                            >
                              <MenuItem value={eMedicalClearanceRequired.SELECT}>{eMedicalClearanceRequired.SELECT}</MenuItem>
                              <MenuItem value={eMedicalClearanceRequired.NO}>{eMedicalClearanceRequired.NO}</MenuItem>
                              <MenuItem value={eMedicalClearanceRequired.YES}>{eMedicalClearanceRequired.YES}</MenuItem>
                            </Select>
                            <input type="text" style={{ visibility: "hidden" }} name="diabetesMCAssessor" defaultValue={state.diabetesMCAssessor} ref={register} />
                          </FormControl>
                        ) : (
                          <></>
                        )}
                      </RadioGroup>
                      {state.diabetes === "yesControlled" || state.diabetes === "yesNotControlled" ? (
                        <OutlinedInput
                          color="primary"
                          name="diabetesNote"
                          autoComplete="off"
                          className="med-note"
                          inputRef={register}
                          placeholder="If Yes, describe condition here*"
                          defaultValue={state.diabetesNote}
                          onChange={handleValueChange}
                        />
                      ) : (
                        <></>
                      )}
                      {state.diabetesMCAssessor === eMedicalClearanceRequired.YES ? (
                        <RadioGroup
                          aria-label="diabetesViewedMC"
                          name="diabetesViewedMC"
                          className="flexer"
                          row
                          value={state.diabetesViewedMC}
                          onChange={(event) => {
                            handleOtherViewMC(event);
                          }}
                        >
                          <FormControl variant="outlined" className="selectOveride">
                            <Select
                              name="diabetesMCType"
                              color="primary"
                              className="DayChanger"
                              placeholder="Select"
                              value={state.diabetesMCType}
                              MenuProps={{ PaperProps: { style: { maxHeight: "18%" } } }}
                              onChange={(event) => {
                                handleOtherMCType(event);
                              }}
                            >
                              <MenuItem value={eMedicalClearanceType.SELECT}>{eMedicalClearanceType.SELECT}</MenuItem>
                              {state.diabetesMCs && state.diabetesMCs.length > 0 ? (
                                <MenuItem value={eMedicalClearanceType.EXISTINGMC}>{eMedicalClearanceType.EXISTINGMC}</MenuItem>
                              ) : (
                                <></>
                              )}
                              <MenuItem value={eMedicalClearanceType.PAPERMC}>{eMedicalClearanceType.PAPERMC}</MenuItem>
                              <MenuItem value={eMedicalClearanceType.NOTPROVIDED}>{eMedicalClearanceType.NOTPROVIDED}</MenuItem>
                            </Select>
                            <input type="text" style={{ visibility: "hidden" }} name={`diabetesMCType`} defaultValue={state.diabetesMCType} ref={register} />
                          </FormControl>
                          {state.diabetesMCType === eMedicalClearanceType.EXISTINGMC ||
                          (state.diabetesMCType === eMedicalClearanceType.PAPERMC && state.diabetesPaperMCs.length > 0) ? (
                            <>
                              <FormControlLabel
                                control={<Radio color="primary" name="diabetesViewedMC" id="diabetes_viewedMC_accepted" value="ViewedAccepted" />}
                                inputRef={register}
                                label="MC Viewed and Accepted"
                              />
                              <FormControlLabel
                                control={<Radio color="primary" name="diabetesViewedMC" id="diabetes_viewedMC_notAccepted" value="NotAccepted" />}
                                inputRef={register}
                                label="MC Viewed and Not Accepted"
                              />
                            </>
                          ) : (
                            <></>
                          )}
                          {state.diabetesMCType === eMedicalClearanceType.PAPERMC && (!state.diabetesPaperMCs || state.diabetesPaperMCs.length < 1) ? (
                            <div className="uploadLink">
                              <Upload
                                condition={"diabetes"}
                                conditionText={"Diabetes"}
                                paperMCs={state.diabetesPaperMCs}
                                otherInfo={state.diabetesNote}
                                pefaId={pefaAssessment?.pefArequestID}
                                bookingId={pefaAssessment?.id}
                                doctor={state.medicalPractitioner}
                                practice={state.medicalPractice}
                                startUpload={startUpload}
                                handlePaperMC={updatePaperMC}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                        </RadioGroup>
                      ) : (
                        <></>
                      )}
                      {state.diabetes === "" ||
                      state.diabetes === "denied" ||
                      state.diabetes === "yesControlled" ||
                      (state.diabetes === "yesNotControlled" &&
                        (state.diabetesMCAssessor === eMedicalClearanceRequired.SELECT || state.diabetesMCAssessor === eMedicalClearanceRequired.NO)) ||
                      (state.diabetes === "yesNotControlled" && state.diabetesMCAssessor === eMedicalClearanceRequired.YES && state.diabetesViewedMC === "ViewedAccepted") ? (
                        <></>
                      ) : (
                        <>
                          <div className="addedList">
                            <img src={WarningIcon} alt="Warning Icon" />
                            <span>Please find this condition on the New Medical clearance request at the bottom of the list</span>
                          </div>
                        </>
                      )}
                      {(state.diabetesMCAssessor === eMedicalClearanceRequired.YES &&
                        state.diabetesMCType !== eMedicalClearanceType.NOTPROVIDED &&
                        state.diabetesViewedMC === "") ||
                      ((state.diabetes === "yesControlled" || state.diabetes === "yesNotControlled") && state.diabetesNote === "") ||
                      (state.diabetes === "yesNotControlled" && state.diabetesMCAssessor === eMedicalClearanceRequired.SELECT) ||
                      (state.diabetesMCAssessor === eMedicalClearanceRequired.YES && state.diabetesMCType === eMedicalClearanceType.SELECT) ? (
                        <div className="invalid-medical">Input is required</div>
                      ) : errors.diabetes?.message !== "" ? (
                        <div className="invalid-feedback">{errors.diabetes?.message}</div>
                      ) : errors.diabetesMCAssessor?.message !== "" ? (
                        <div className="invalid-feedback">{errors.diabetesMCAssessor?.message}</div>
                      ) : errors.diabetesNote?.message !== "" ? (
                        <div className="invalid-feedback">{errors.diabetesNote?.message}</div>
                      ) : (
                        <></>
                      )}
                      {state.diabetesMCs && state.diabetesMCs.length > 0 ? (
                        <>
                          {state.diabetesMCs
                            .filter((x) => x.medicalCertificate !== null)
                            .map((medicalClearance: any, index: number) => (
                              <>
                                <div className="viewMedicalConditionFile" key={index}>
                                  <Typography className="medicalConditionName mr-2">
                                    {medicalClearance.condition} {medicalClearance.outcome ? ` - ${medicalClearance.outcome.toUpperCase()} ` : ""}
                                  </Typography>
                                </div>
                                <div className="viewMedicalConditionFile" key={index}>
                                  <Typography className="medicalConditionName ellipsis ml-3 mr-2">{medicalClearance.otherInfo}</Typography>
                                </div>
                                <div className="viewMedicalConditionFile" key={index}>
                                  {medicalClearance.medicalCertificate ? (
                                    <>
                                      <Link
                                        className="viewMC"
                                        underline="always"
                                        onClick={() => handleCertificateDownload(medicalClearance.medicalCertificate.id, medicalClearance.medicalCertificate.filename)}
                                      >
                                        Review Existing Medical Clearance
                                      </Link>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </>
                            ))}
                        </>
                      ) : (
                        <></>
                      )}
                      {state.diabetesPaperMCs && state.diabetesPaperMCs.length > 0 ? (
                        <>
                          {state.diabetesPaperMCs.map((medicalClearance: any, index: number) => (
                            <>
                              <div className="viewMedicalConditionFile" key={index}>
                                <Typography className="medicalConditionName mr-2">{`${medicalClearance.condition} - PaperMC`}</Typography>
                                <Link
                                  className="cancelPaperMC"
                                  underline="always"
                                  onClick={() => cancelPaperMC("diabetes", medicalClearance.id, medicalClearance.medicalClearanceId)}
                                >
                                  Cancel
                                </Link>
                              </div>
                              <div className="viewMedicalConditionFile" key={index}>
                                {medicalClearance ? (
                                  <>
                                    <Link className="viewMC" underline="always" onClick={() => handleCertificateDownload(medicalClearance.id, medicalClearance.fileName)}>
                                      Review Paper Medical Clearance
                                    </Link>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                      <hr className="separator"></hr>
                      {/* Diabetes */}
                      {/* Infectious Condition */}
                      <div className="flexer">
                        <FormLabel className="bold blueText height-adjusted" component="legend">
                          Infectious Condition*
                        </FormLabel>
                      </div>
                      <RadioGroup
                        aria-label="infectious"
                        name="infectious"
                        className="flexer"
                        row
                        value={state.infectious}
                        onChange={(event) => {
                          handleValueChange(event);
                          setState((prevState) => ({
                            ...prevState,
                            infectiousConditionNote: "",
                          }));
                        }}
                      >
                        <FormControlLabel
                          control={<Radio color="primary" name="infectious" id="infectiousDenied" value="denied" />}
                          value={state.infectious}
                          inputRef={register}
                          label="Denied"
                        />
                        <FormControlLabel
                          control={<Radio color="primary" name="infectious" id="infectiousYes" value="yesMCNotRequired" />}
                          inputRef={register}
                          label="Yes - MC not required"
                        />
                      </RadioGroup>
                      {state.infectious === "yesMCNotRequired" ? (
                        <OutlinedInput
                          color="primary"
                          name="infectiousConditionNote"
                          autoComplete="off"
                          className="med-note"
                          inputRef={register}
                          placeholder="If Yes, describe condition here*"
                          defaultValue={state.infectiousConditionNote}
                          onChange={handleValueChange}
                        />
                      ) : (
                        <></>
                      )}
                      {errors.infectious?.message !== "" ? <div className="invalid-feedback">{errors.infectious?.message}</div> : <></>}
                      {errors.infectiousConditionNote?.message !== "" ? <div className="invalid-feedback">{errors.infectiousConditionNote?.message}</div> : <></>}
                      {state.infectiousMCs && state.infectiousMCs.length > 0 ? (
                        <>
                          {state.infectiousMCs
                            .filter((x) => x.medicalCertificate !== null)
                            .map((medicalClearance: any, index: number) => (
                              <>
                                <div className="viewMedicalConditionFile" key={index}>
                                  <Typography className="medicalConditionName mr-2">
                                    {medicalClearance.condition} {medicalClearance.outcome ? ` - ${medicalClearance.outcome.toUpperCase()} ` : ""}
                                  </Typography>
                                </div>
                                <div className="viewMedicalConditionFile" key={index}>
                                  <Typography className="medicalConditionName ellipsis ml-3 mr-2">{medicalClearance.otherInfo}</Typography>
                                </div>
                                <div className="viewMedicalConditionFile" key={index}>
                                  {medicalClearance.medicalCertificate ? (
                                    <>
                                      <Link
                                        className="viewMC"
                                        underline="always"
                                        onClick={() => handleCertificateDownload(medicalClearance.medicalCertificate.id, medicalClearance.medicalCertificate.filename)}
                                      >
                                        Review Existing Medical Clearance
                                      </Link>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </>
                            ))}
                        </>
                      ) : (
                        <></>
                      )}
                      {state.infectiousPaperMCs && state.infectiousPaperMCs.length > 0 ? (
                        <>
                          {state.infectiousPaperMCs.map((medicalClearance: any, index: number) => (
                            <>
                              <div className="viewMedicalConditionFile" key={index}>
                                <Typography className="medicalConditionName mr-2">{`${medicalClearance.condition} - PaperMC`}</Typography>
                                <Link
                                  className="cancelPaperMC"
                                  underline="always"
                                  onClick={() => cancelPaperMC("infectious", medicalClearance.id, medicalClearance.medicalClearanceId)}
                                >
                                  Cancel
                                </Link>
                              </div>
                              <div className="viewMedicalConditionFile" key={index}>
                                {medicalClearance ? (
                                  <>
                                    <Link className="viewMC" underline="always" onClick={() => handleCertificateDownload(medicalClearance.id, medicalClearance.fileName)}>
                                      Review Paper Medical Clearance
                                    </Link>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                      {/* Infectious Condition*/}
                      {/* Pregnancy */}
                      {participantGender === "FEMALE" ? (
                        <>
                          <hr className="separator"></hr>
                          <div className="flexer" id="pregnancy">
                            <FormLabel className="bold blueText height-adjusted" component="legend">
                              Pregnancy*
                            </FormLabel>
                          </div>
                          <RadioGroup
                            aria-label="pregnancy"
                            name="pregnancy"
                            className="flexer"
                            row
                            value={state.pregnancy}
                            onChange={(event) => handleOtherMCRequiredChange(event, state.pregnancyMCs, "Pregnancy", "pregnancy")}
                          >
                            <FormControlLabel
                              control={<Radio color="primary" name="pregnancy" id="pregnancyDenied" value="denied" />}
                              value={state.pregnancy}
                              inputRef={register}
                              label="Denied"
                            />
                            <FormControlLabel
                              control={<Radio color="primary" name="pregnancy" id="pregnancyYes" value="yesMCRequired" />}
                              inputRef={register}
                              label="Yes - MC required"
                            />
                          </RadioGroup>
                          {state.pregnancy === "yesMCRequired" ? (
                            <OutlinedInput
                              color="primary"
                              name="pregnancyNote"
                              autoComplete="off"
                              className="med-note"
                              inputRef={register}
                              placeholder="If Yes, describe condition here"
                              defaultValue={state.pregnancyNote}
                              onChange={handleValueChange}
                            />
                          ) : (
                            <></>
                          )}
                          {state.pregnancy === "yesMCRequired" ? (
                            <RadioGroup
                              aria-label="pregnancyViewedMC"
                              name="pregnancyViewedMC"
                              className="flexer"
                              row
                              value={state.pregnancyViewedMC}
                              onChange={(event) => {
                                handleOtherViewMC(event);
                              }}
                            >
                              <FormControl variant="outlined" className="selectOveride">
                                <Select
                                  name="pregnancyMCType"
                                  color="primary"
                                  className="DayChanger"
                                  placeholder="Select"
                                  value={state.pregnancyMCType}
                                  MenuProps={{ PaperProps: { style: { maxHeight: "18%" } } }}
                                  onChange={(event) => {
                                    handleOtherMCType(event);
                                  }}
                                >
                                  <MenuItem value={eMedicalClearanceType.SELECT}>{eMedicalClearanceType.SELECT}</MenuItem>
                                  {state.pregnancyMCs && state.pregnancyMCs.length > 0 ? (
                                    <MenuItem value={eMedicalClearanceType.EXISTINGMC}>{eMedicalClearanceType.EXISTINGMC}</MenuItem>
                                  ) : (
                                    <></>
                                  )}
                                  <MenuItem value={eMedicalClearanceType.PAPERMC}>{eMedicalClearanceType.PAPERMC}</MenuItem>
                                  <MenuItem value={eMedicalClearanceType.NOTPROVIDED}>{eMedicalClearanceType.NOTPROVIDED}</MenuItem>
                                </Select>
                                <input type="text" style={{ visibility: "hidden" }} name={`pregnancyMCType`} defaultValue={state.pregnancyMCType} ref={register} />
                              </FormControl>
                              {state.pregnancyMCType === eMedicalClearanceType.EXISTINGMC ||
                              (state.pregnancyMCType === eMedicalClearanceType.PAPERMC && state.pregnancyPaperMCs.length > 0) ? (
                                <>
                                  <FormControlLabel
                                    control={<Radio color="primary" name="pregnancyViewedMC" id="pregnancy_viewedMC_accepted" value="ViewedAccepted" />}
                                    inputRef={register}
                                    label="MC Viewed and Accepted"
                                  />
                                  <FormControlLabel
                                    control={<Radio color="primary" name="pregnancyViewedMC" id="pregnancy_viewedMC_notAccepted" value="NotAccepted" />}
                                    inputRef={register}
                                    label="MC Viewed and Not Accepted"
                                  />
                                </>
                              ) : (
                                <></>
                              )}
                              {state.pregnancyMCType === eMedicalClearanceType.PAPERMC && (!state.pregnancyPaperMCs || state.pregnancyPaperMCs.length < 1) ? (
                                <div className="uploadLink">
                                  <Upload
                                    condition={"pregnancy"}
                                    conditionText={"Pregnancy"}
                                    paperMCs={state.pregnancyPaperMCs}
                                    otherInfo={state.pregnancyNote}
                                    pefaId={pefaAssessment?.pefArequestID}
                                    bookingId={pefaAssessment?.id}
                                    doctor={state.medicalPractitioner}
                                    practice={state.medicalPractice}
                                    startUpload={startUpload}
                                    handlePaperMC={updatePaperMC}
                                  />
                                </div>
                              ) : (
                                <></>
                              )}
                            </RadioGroup>
                          ) : (
                            <></>
                          )}
                          {state.pregnancy === "" || state.pregnancy === "denied" || state.pregnancyViewedMC === "ViewedAccepted" ? (
                            <></>
                          ) : (
                            <>
                              <div className="addedList">
                                <img src={WarningIcon} alt="Warning Icon" />
                                <span>Please find this condition on the New Medical clearance request at the bottom of the list</span>
                              </div>
                            </>
                          )}
                          {(state.pregnancy === "yesMCRequired" &&
                            state.pregnancyMCType !== "" &&
                            state.pregnancyMCType !== eMedicalClearanceType.NOTPROVIDED &&
                            state.pregnancyViewedMC === "") ||
                          (state.pregnancy === "yesMCRequired" && state.pregnancyMCType === eMedicalClearanceType.SELECT) ? (
                            <div className="invalid-medical">Input is required</div>
                          ) : (
                            <></>
                          )}
                          {errors.pregnancy?.message !== "" ? <div className="invalid-feedback">{errors.pregnancy?.message}</div> : <></>}
                          {state.pregnancyMCs && state.pregnancyMCs.length > 0 ? (
                            <>
                              {state.pregnancyMCs
                                .filter((x) => x.medicalCertificate !== null)
                                .map((medicalClearance: any, index: number) => (
                                  <>
                                    <div className="viewMedicalConditionFile" key={index}>
                                      <Typography className="medicalConditionName mr-2">
                                        {medicalClearance.condition} {medicalClearance.outcome ? ` - ${medicalClearance.outcome.toUpperCase()} ` : ""}
                                      </Typography>
                                    </div>
                                    <div className="viewMedicalConditionFile" key={index}>
                                      <Typography className="medicalConditionName ellipsis ml-3 mr-2">{medicalClearance.otherInfo}</Typography>
                                    </div>
                                    <div className="viewMedicalConditionFile" key={index}>
                                      {medicalClearance.medicalCertificate ? (
                                        <>
                                          <Link
                                            className="viewMC"
                                            underline="always"
                                            onClick={() => handleCertificateDownload(medicalClearance.medicalCertificate.id, medicalClearance.medicalCertificate.filename)}
                                          >
                                            Review Existing Medical Clearance
                                          </Link>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </>
                                ))}
                            </>
                          ) : (
                            <></>
                          )}
                          {state.pregnancyPaperMCs && state.pregnancyPaperMCs.length > 0 ? (
                            <>
                              {state.pregnancyPaperMCs.map((medicalClearance: any, index: number) => (
                                <>
                                  <div className="viewMedicalConditionFile" key={index}>
                                    <Typography className="medicalConditionName mr-2">{`${medicalClearance.condition} - PaperMC`}</Typography>
                                    <Link
                                      className="cancelPaperMC"
                                      underline="always"
                                      onClick={() => cancelPaperMC("pregnancy", medicalClearance.id, medicalClearance.medicalClearanceId)}
                                    >
                                      Cancel
                                    </Link>
                                  </div>
                                  <div className="viewMedicalConditionFile" key={index}>
                                    {medicalClearance ? (
                                      <>
                                        <Link className="viewMC" underline="always" onClick={() => handleCertificateDownload(medicalClearance.id, medicalClearance.fileName)}>
                                          Review Paper Medical Clearance
                                        </Link>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                      <hr className="separator"></hr>
                      {/* Pregnancy */}
                      {/* Any current or past injury in the past 6 weeks that required treatment, time off work or work restrictions?* */}
                      <div className="flexer" id="anyInjuries6wks">
                        <FormLabel className="bold blueText height-adjusted" component="legend">
                          Any current or past injury in the past 6 weeks that required treatment, time off work or work restrictions?*
                        </FormLabel>
                      </div>
                      <RadioGroup
                        aria-label="anyInjuries6wks"
                        name="anyInjuries6wks"
                        className="flexer"
                        row
                        value={state.anyInjuries6wks}
                        onChange={(event) => {
                          handleValueChangeForNoteAndDate(event, state.anyInjuries6wksMCs, "Injury in past 6wks with treatment");
                        }}
                      >
                        <FormControlLabel
                          control={<Radio color="primary" name="anyInjuries6wks" id="anyInjuries6wksDenied" value="denied" />}
                          value={state.anyInjuries6wksNo}
                          inputRef={register}
                          label="Denied"
                        />
                        <FormControlLabel
                          control={<Radio color="primary" name="anyInjuries6wks" id="anyInjuries6wksYes" value="yesMCRequired" />}
                          inputRef={register}
                          label="Yes – MC required"
                        />
                      </RadioGroup>
                      {state.anyInjuries6wks === "yesMCRequired" &&
                        state.anyInjuries6wks_noteItems.map((element, index) => (
                          <>
                            {!element.isDeleted ? (
                              <>
                                <div className="note-item">
                                  <OutlinedInput
                                    color="primary"
                                    name="anyInjuries6wks_noteItems"
                                    value={element.name}
                                    autoComplete="off"
                                    className="small-note"
                                    inputRef={register}
                                    placeholder="If Yes, describe condition here*"
                                    defaultValue={element.note}
                                    onChange={(event) => handleValueChangeNote(event, index, state.anyInjuries6wks_noteItems, "anyInjuries6wks_noteItems")}
                                  />
                                  <FormControl variant="outlined" className="selectMC">
                                    <Select
                                      name={`anyInjuries6wks_noteItems_year${index}`}
                                      color="primary"
                                      className="DayChanger"
                                      placeholder="YYYY"
                                      value={element.year}
                                      MenuProps={{ PaperProps: { style: { maxHeight: "18%" } } }}
                                      onChange={(event) => {
                                        const noteItems = state.anyInjuries6wks_noteItems;
                                        noteItems[index].year = event.target.value;
                                        noteItems[index].month = event.target.value !== 21 && noteItems[index].month === "N/A" ? "" : noteItems[index].month;
                                        setState((prevState) => ({
                                          ...prevState,
                                          anyInjuries6wks_noteItems: noteItems,
                                          enabledEndPEFA: false,
                                          clearanceDownloaded: false,
                                        }));
                                        setValue(`anyInjuries6wks_noteItems_year${index}`, event.target.value, { shouldValidate: true });
                                        handleValueChangeYearAndMonth(noteItems, "anyInjuries6wks");
                                      }}
                                    >
                                      {yearData.map((value) => {
                                        return (
                                          <MenuItem value={value} key={value}>
                                            {value}
                                          </MenuItem>
                                        );
                                      })}
                                      <MenuItem value={21}>More than 20 years</MenuItem>
                                    </Select>
                                    <input
                                      type="text"
                                      style={{ visibility: "hidden" }}
                                      name={`anyInjuries6wks_noteItems_year${index}`}
                                      defaultValue={element.year}
                                      ref={register}
                                    />
                                  </FormControl>
                                  <FormControl variant="outlined" className="selectMC">
                                    <Select
                                      name={`anyInjuries6wks_noteItems_month${index}`}
                                      color="primary"
                                      className="DayChanger"
                                      placeholder="MMM"
                                      value={element.month}
                                      MenuProps={{ PaperProps: { style: { maxHeight: "18%" } } }}
                                      onChange={(event) => {
                                        const noteItems = state.anyInjuries6wks_noteItems;
                                        noteItems[index].month = event.target.value;
                                        setState((prevState) => ({
                                          ...prevState,
                                          anyInjuries6wks_noteItems: noteItems,
                                          enabledEndPEFA: false,
                                          clearanceDownloaded: false,
                                        }));
                                        setValue(`anyInjuries6wks_noteItems_month${index}`, event.target.value, { shouldValidate: true });
                                        handleValueChangeYearAndMonth(noteItems, "anyInjuries6wks");
                                      }}
                                    >
                                      {state.anyInjuries6wks_noteItems[index].year === 21 ? (
                                        <MenuItem value={"N/A"} key={"N/A"}>
                                          {"N/A"}
                                        </MenuItem>
                                      ) : (
                                        <></>
                                      )}
                                      {monthData.map((value) => {
                                        return (
                                          <MenuItem value={value} key={value}>
                                            {value}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                    <input
                                      type="text"
                                      style={{ visibility: "hidden" }}
                                      name={`anyInjuries6wks_noteItems_month${index}`}
                                      defaultValue={element.month}
                                      ref={register}
                                    />
                                  </FormControl>
                                  {index === 0 ? (
                                    <Link
                                      className="addButton"
                                      onClick={() => {
                                        handleAddFromList(state.anyInjuries6wks_noteItems, "anyInjuries6wks_noteItems");
                                      }}
                                    >
                                      +
                                    </Link>
                                  ) : (
                                    <Link
                                      className="medicalConditionCancel"
                                      onClick={() => {
                                        handleRemoveFromList(index, state.anyInjuries6wks_noteItems, "anyInjuries6wks_noteItems");
                                      }}
                                    >
                                      Cancel
                                    </Link>
                                  )}
                                </div>
                                <RadioGroup
                                  aria-label="anyInjuries6wks_viewedMC"
                                  name="anyInjuries6wks_viewedMC"
                                  className="flexer"
                                  row
                                  value={element.viewedMC}
                                  onChange={(event) => {
                                    handleViewMC(event, index, state.anyInjuries6wks_noteItems, state.anyInjuries6wksMCs);
                                  }}
                                >
                                  <FormControl variant="outlined" className="selectOveride">
                                    <Select
                                      name="anyInjuries6wks_mcType"
                                      color="primary"
                                      className="DayChanger"
                                      placeholder="Select"
                                      value={element.mcType}
                                      MenuProps={{ PaperProps: { style: { maxHeight: "18%" } } }}
                                      onChange={(event) => {
                                        handleMCType(event, index, state.anyInjuries6wks_noteItems);
                                      }}
                                    >
                                      <MenuItem value={eMedicalClearanceType.SELECT}>{eMedicalClearanceType.SELECT}</MenuItem>
                                      {state.anyInjuries6wksMCs && state.anyInjuries6wksMCs.length > 0 ? (
                                        <MenuItem value={eMedicalClearanceType.EXISTINGMC}>{eMedicalClearanceType.EXISTINGMC}</MenuItem>
                                      ) : (
                                        <></>
                                      )}
                                      <MenuItem value={eMedicalClearanceType.PAPERMC}>{eMedicalClearanceType.PAPERMC}</MenuItem>
                                      <MenuItem value={eMedicalClearanceType.NOTPROVIDED}>{eMedicalClearanceType.NOTPROVIDED}</MenuItem>
                                    </Select>
                                    <input
                                      type="text"
                                      style={{ visibility: "hidden" }}
                                      name={`anyInjuries6wks_noteItems_mcType${index}`}
                                      defaultValue={element.mcType}
                                      ref={register}
                                    />
                                  </FormControl>
                                  {element.mcType === eMedicalClearanceType.EXISTINGMC ||
                                  (element.mcType === eMedicalClearanceType.PAPERMC && state.anyInjuries6wksPaperMCs.length > 0) ? (
                                    <>
                                      <FormControlLabel
                                        control={<Radio color="primary" name="anyInjuries6wks_viewedMC" id="anyInjuries6wks_viewedMC_accepted" value="ViewedAccepted" />}
                                        inputRef={register}
                                        label="MC Viewed and Accepted"
                                      />
                                      <FormControlLabel
                                        control={<Radio color="primary" name="anyInjuries6wks_viewedMC" id="anyInjuries6wks_viewedMC_notAccepted" value="NotAccepted" />}
                                        inputRef={register}
                                        label="MC Viewed and Not Accepted"
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {element.mcType === eMedicalClearanceType.PAPERMC && (!state.anyInjuries6wksPaperMCs || state.anyInjuries6wksPaperMCs.length < 1) ? (
                                    <div className="uploadLink">
                                      <Upload
                                        condition={"anyInjuries6wks"}
                                        conditionText={"Injury in past 6wks with treatment"}
                                        paperMCs={state.anyInjuries6wksPaperMCs}
                                        otherInfo={element.note}
                                        pefaId={pefaAssessment?.pefArequestID}
                                        bookingId={pefaAssessment?.id}
                                        doctor={state.medicalPractitioner}
                                        practice={state.medicalPractice}
                                        startUpload={startUpload}
                                        handlePaperMC={updatePaperMC}
                                      />
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </RadioGroup>
                                {element.isMCRequired ? (
                                  <>
                                    <span className="addedList">
                                      <img src={WarningIcon} alt="Warning Icon" />
                                      <span>Please find this condition on the New Medical clearance request at the bottom of the list</span>
                                    </span>
                                  </>
                                ) : (
                                  <></>
                                )}
                                {element.note === "" ||
                                element.year === "" ||
                                element.month === "" ||
                                (element.mcType !== eMedicalClearanceType.NOTPROVIDED && element.viewedMC === "") ||
                                element.mcType === eMedicalClearanceType.SELECT ? (
                                  <div className="invalid-medical">Input is required</div>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        ))}
                      {errors.anyInjuries6wks?.message !== "" ? <div className="invalid-feedback">{errors.anyInjuries6wks?.message}</div> : <></>}
                      {errors.anyInjuries6wks_noteItems?.message !== "" ? <div className="invalid-feedback">{errors.anyInjuries6wks_noteItems?.message}</div> : <></>}
                      {state.anyInjuries6wksMCs && state.anyInjuries6wksMCs.length > 0 ? (
                        <>
                          {state.anyInjuries6wksMCs
                            .filter((x) => x.medicalCertificate !== null)
                            .map((medicalClearance: any, index: number) => (
                              <>
                                <div className="viewMedicalConditionFile" key={index}>
                                  <Typography className="medicalConditionName mr-2">
                                    {medicalClearance.condition} {medicalClearance.outcome ? ` - ${medicalClearance.outcome.toUpperCase()} ` : ""}
                                  </Typography>
                                </div>
                                <div className="viewMedicalConditionFile" key={index}>
                                  <Typography className="medicalConditionName ellipsis ml-3 mr-2">{medicalClearance.otherInfo}</Typography>
                                </div>
                                <div className="viewMedicalConditionFile" key={index}>
                                  {medicalClearance.medicalCertificate ? (
                                    <>
                                      <Link
                                        className="viewMC"
                                        underline="always"
                                        onClick={() => handleCertificateDownload(medicalClearance.medicalCertificate.id, medicalClearance.medicalCertificate.filename)}
                                      >
                                        Review Existing Medical Clearance
                                      </Link>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </>
                            ))}
                        </>
                      ) : (
                        <></>
                      )}
                      {state.anyInjuries6wksPaperMCs && state.anyInjuries6wksPaperMCs.length > 0 ? (
                        <>
                          {state.anyInjuries6wksPaperMCs.map((medicalClearance: any, index: number) => (
                            <>
                              <div className="viewMedicalConditionFile" key={index}>
                                <Typography className="medicalConditionName mr-2">{`${medicalClearance.condition} - PaperMC`}</Typography>
                                <Link
                                  className="cancelPaperMC"
                                  underline="always"
                                  onClick={() =>
                                    cancelPaperMCNoteItems("anyInjuries6wks", state.anyInjuries6wks_noteItems, medicalClearance.id, medicalClearance.medicalClearanceId)
                                  }
                                >
                                  Cancel
                                </Link>
                              </div>
                              <div className="viewMedicalConditionFile" key={index}>
                                {medicalClearance ? (
                                  <>
                                    <Link className="viewMC" underline="always" onClick={() => handleCertificateDownload(medicalClearance.id, medicalClearance.fileName)}>
                                      Review Paper Medical Clearance
                                    </Link>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                      <hr className="separator"></hr>
                      {/* Any current or past injury in the past 6 weeks that required treatment, time off work or work restrictions?* */}
                      {/* Is there a current Medical Certificate for any Work Restrictions?* */}
                      <div className="flexer" id="medCertOrWrkRstrctns">
                        <FormLabel className="bold blueText height-adjusted" component="legend">
                          Is there a current Medical Certificate for any Work Restrictions?*
                        </FormLabel>
                      </div>
                      <RadioGroup
                        aria-label="medCertOrWrkRstrctns"
                        name="medCertOrWrkRstrctns"
                        className="flexer"
                        row
                        value={state.medCertOrWrkRstrctns}
                        onChange={(event) => {
                          handleValueChangeForNoteAndDate(event, state.medCertOrWrkRstrctnsMCs, "Current medical certificate with work restrictions");
                        }}
                      >
                        <FormControlLabel
                          control={<Radio color="primary" name="medCertOrWrkRstrctns" id="medCertOrWrkRstrctnsNoDenied" value="denied" />}
                          value={state.medCertOrWrkRstrctns}
                          inputRef={register}
                          label="Denied"
                        />
                        <FormControlLabel
                          control={<Radio color="primary" name="medCertOrWrkRstrctns" id="medCertOrWrkRstrctnsNoYes" value="yesMCRequired" />}
                          inputRef={register}
                          label="Yes – MC required"
                        />
                      </RadioGroup>
                      {state.medCertOrWrkRstrctns === "yesMCRequired" &&
                        state.medCertOrWrkRstrctns_noteItems.map((element, index) => (
                          <>
                            {!element.isDeleted ? (
                              <>
                                <div className="note-item">
                                  <OutlinedInput
                                    key={index}
                                    color="primary"
                                    name="medCertOrWrkRstrctns_noteItems"
                                    value={element.name}
                                    autoComplete="off"
                                    className="small-note"
                                    inputRef={register}
                                    placeholder="If Yes, describe condition here*"
                                    defaultValue={element.note}
                                    onChange={(event) => handleValueChangeNote(event, index, state.medCertOrWrkRstrctns_noteItems, "medCertOrWrkRstrctns_noteItems")}
                                  />
                                  <FormControl variant="outlined" className="selectMC">
                                    <Select
                                      name={`medCertOrWrkRstrctns_noteItems_year${index}`}
                                      color="primary"
                                      className="DayChanger"
                                      placeholder="YYYY"
                                      value={element.year}
                                      MenuProps={{ PaperProps: { style: { maxHeight: "18%" } } }}
                                      onChange={(event) => {
                                        const noteItems = state.medCertOrWrkRstrctns_noteItems;
                                        noteItems[index].year = event.target.value;
                                        noteItems[index].month = event.target.value !== 21 && noteItems[index].month === "N/A" ? "" : noteItems[index].month;
                                        setState((prevState) => ({
                                          ...prevState,
                                          medCertOrWrkRstrctns_noteItems: noteItems,
                                          enabledEndPEFA: false,
                                          clearanceDownloaded: false,
                                        }));
                                        setValue(`medCertOrWrkRstrctns_noteItems_year${index}`, event.target.value, { shouldValidate: true });
                                        handleValueChangeYearAndMonth(noteItems, "medCertOrWrkRstrctns");
                                      }}
                                    >
                                      {yearData.map((value) => {
                                        return (
                                          <MenuItem value={value} key={value}>
                                            {value}
                                          </MenuItem>
                                        );
                                      })}
                                      <MenuItem value={21}>More than 20 years</MenuItem>
                                    </Select>
                                    <input
                                      type="text"
                                      style={{ visibility: "hidden" }}
                                      name={`medCertOrWrkRstrctns_noteItems_year${index}`}
                                      defaultValue={element.year}
                                      ref={register}
                                    />
                                  </FormControl>
                                  <FormControl variant="outlined" className="selectMC">
                                    <Select
                                      name={`medCertOrWrkRstrctns_noteItems_month${index}`}
                                      color="primary"
                                      className="DayChanger"
                                      placeholder="MMM"
                                      value={element.month}
                                      MenuProps={{ PaperProps: { style: { maxHeight: "18%" } } }}
                                      onChange={(event) => {
                                        const noteItems = state.medCertOrWrkRstrctns_noteItems;
                                        noteItems[index].month = event.target.value;
                                        setState((prevState) => ({
                                          ...prevState,
                                          medCertOrWrkRstrctns_noteItems: noteItems,
                                          enabledEndPEFA: false,
                                          clearanceDownloaded: false,
                                        }));
                                        setValue(`medCertOrWrkRstrctns_noteItems_month${index}`, event.target.value, { shouldValidate: true });
                                        handleValueChangeYearAndMonth(noteItems, "medCertOrWrkRstrctns");
                                      }}
                                    >
                                      {state.medCertOrWrkRstrctns_noteItems[index].year === 21 ? (
                                        <MenuItem value={"N/A"} key={"N/A"}>
                                          {"N/A"}
                                        </MenuItem>
                                      ) : (
                                        <></>
                                      )}
                                      {monthData.map((value) => {
                                        return (
                                          <MenuItem value={value} key={value}>
                                            {value}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                    <input
                                      type="text"
                                      style={{ visibility: "hidden" }}
                                      name={`medCertOrWrkRstrctns_noteItems_month${index}`}
                                      defaultValue={element.month}
                                      ref={register}
                                    />
                                  </FormControl>
                                  {index === 0 ? (
                                    <Link
                                      className="addButton"
                                      onClick={() => {
                                        handleAddFromList(state.medCertOrWrkRstrctns_noteItems, "medCertOrWrkRstrctns_noteItems");
                                      }}
                                    >
                                      +
                                    </Link>
                                  ) : (
                                    <Link
                                      className="medicalConditionCancel"
                                      onClick={() => {
                                        handleRemoveFromList(index, state.medCertOrWrkRstrctns_noteItems, "medCertOrWrkRstrctns_noteItems");
                                      }}
                                    >
                                      Cancel
                                    </Link>
                                  )}
                                </div>
                                <RadioGroup
                                  aria-label="medCertOrWrkRstrctns_viewedMC"
                                  name="medCertOrWrkRstrctns_viewedMC"
                                  className="flexer"
                                  row
                                  value={element.viewedMC}
                                  onChange={(event) => {
                                    handleViewMC(event, index, state.medCertOrWrkRstrctns_noteItems, state.medCertOrWrkRstrctnsMCs);
                                  }}
                                >
                                  <FormControl variant="outlined" className="selectOveride">
                                    <Select
                                      name="medCertOrWrkRstrctns_mcType"
                                      color="primary"
                                      className="DayChanger"
                                      placeholder="Select"
                                      value={element.mcType}
                                      MenuProps={{ PaperProps: { style: { maxHeight: "18%" } } }}
                                      onChange={(event) => {
                                        handleMCType(event, index, state.medCertOrWrkRstrctns_noteItems);
                                      }}
                                    >
                                      <MenuItem value={eMedicalClearanceType.SELECT}>{eMedicalClearanceType.SELECT}</MenuItem>
                                      {state.medCertOrWrkRstrctnsMCs && state.medCertOrWrkRstrctnsMCs.length > 0 ? (
                                        <MenuItem value={eMedicalClearanceType.EXISTINGMC}>{eMedicalClearanceType.EXISTINGMC}</MenuItem>
                                      ) : (
                                        <></>
                                      )}
                                      <MenuItem value={eMedicalClearanceType.PAPERMC}>{eMedicalClearanceType.PAPERMC}</MenuItem>
                                      <MenuItem value={eMedicalClearanceType.NOTPROVIDED}>{eMedicalClearanceType.NOTPROVIDED}</MenuItem>
                                    </Select>
                                    <input
                                      type="text"
                                      style={{ visibility: "hidden" }}
                                      name={`medCertOrWrkRstrctns_noteItems_mcType${index}`}
                                      defaultValue={element.mcType}
                                      ref={register}
                                    />
                                  </FormControl>
                                  {element.mcType === eMedicalClearanceType.EXISTINGMC ||
                                  (element.mcType === eMedicalClearanceType.PAPERMC && state.medCertOrWrkRstrctnsPaperMCs.length > 0) ? (
                                    <>
                                      <FormControlLabel
                                        control={<Radio color="primary" name="medCertOrWrkRstrctns_viewedMC" id="medCertOrWrkRstrctns_viewedMC_accepted" value="ViewedAccepted" />}
                                        inputRef={register}
                                        label="MC Viewed and Accepted"
                                      />
                                      <FormControlLabel
                                        control={<Radio color="primary" name="medCertOrWrkRstrctns_viewedMC" id="medCertOrWrkRstrctns_viewedMC_notAccepted" value="NotAccepted" />}
                                        inputRef={register}
                                        label="MC Viewed and Not Accepted"
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {element.mcType === eMedicalClearanceType.PAPERMC && (!state.medCertOrWrkRstrctnsPaperMCs || state.medCertOrWrkRstrctnsPaperMCs.length < 1) ? (
                                    <div className="uploadLink">
                                      <Upload
                                        condition={"medCertOrWrkRstrctns"}
                                        conditionText={"Current medical certificate with work restrictions"}
                                        paperMCs={state.medCertOrWrkRstrctnsPaperMCs}
                                        otherInfo={element.note}
                                        pefaId={pefaAssessment?.pefArequestID}
                                        bookingId={pefaAssessment?.id}
                                        doctor={state.medicalPractitioner}
                                        practice={state.medicalPractice}
                                        startUpload={startUpload}
                                        handlePaperMC={updatePaperMC}
                                      />
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </RadioGroup>
                                {element.isMCRequired ? (
                                  <>
                                    <span className="addedList">
                                      <img src={WarningIcon} alt="Warning Icon" />
                                      <span>Please find this condition on the New Medical clearance request at the bottom of the list</span>
                                    </span>
                                  </>
                                ) : (
                                  <></>
                                )}
                                {element.note === "" ||
                                element.year === "" ||
                                element.month === "" ||
                                (element.mcType !== eMedicalClearanceType.NOTPROVIDED && element.viewedMC === "") ||
                                element.mcType === eMedicalClearanceType.SELECT ? (
                                  <div className="invalid-medical">Input is required</div>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        ))}
                      {errors.medCertOrWrkRstrctns?.message !== "" ? <div className="invalid-feedback">{errors.medCertOrWrkRstrctns?.message}</div> : <></>}
                      {errors.medCertOrWrkRstrctns_noteItems?.message !== "" ? <div className="invalid-feedback">{errors.medCertOrWrkRstrctns_noteItems?.message}</div> : <></>}
                      {state.medCertOrWrkRstrctnsMCs && state.medCertOrWrkRstrctnsMCs.length > 0 ? (
                        <>
                          {state.medCertOrWrkRstrctnsMCs
                            .filter((x) => x.medicalCertificate !== null)
                            .map((medicalClearance: any, index: number) => (
                              <>
                                <div className="viewMedicalConditionFile" key={index}>
                                  <Typography className="medicalConditionName mr-2">
                                    {medicalClearance.condition} {medicalClearance.outcome ? ` - ${medicalClearance.outcome.toUpperCase()} ` : ""}
                                  </Typography>
                                </div>
                                <div className="viewMedicalConditionFile" key={index}>
                                  <Typography className="medicalConditionName ellipsis ml-3 mr-2">{medicalClearance.otherInfo}</Typography>
                                </div>
                                <div className="viewMedicalConditionFile" key={index}>
                                  {medicalClearance.medicalCertificate ? (
                                    <>
                                      <Link
                                        className="viewMC"
                                        underline="always"
                                        onClick={() => handleCertificateDownload(medicalClearance.medicalCertificate.id, medicalClearance.medicalCertificate.filename)}
                                      >
                                        Review Existing Medical Clearance
                                      </Link>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </>
                            ))}
                        </>
                      ) : (
                        <></>
                      )}
                      {state.medCertOrWrkRstrctnsPaperMCs && state.medCertOrWrkRstrctnsPaperMCs.length > 0 ? (
                        <>
                          {state.medCertOrWrkRstrctnsPaperMCs.map((medicalClearance: any, index: number) => (
                            <>
                              <div className="viewMedicalConditionFile" key={index}>
                                <Typography className="medicalConditionName mr-2">{`${medicalClearance.condition} - PaperMC`}</Typography>
                                <Link
                                  className="cancelPaperMC"
                                  underline="always"
                                  onClick={() =>
                                    cancelPaperMCNoteItems("medCertOrWrkRstrctns", state.medCertOrWrkRstrctns_noteItems, medicalClearance.id, medicalClearance.medicalClearanceId)
                                  }
                                >
                                  Cancel
                                </Link>
                              </div>
                              <div className="viewMedicalConditionFile" key={index}>
                                {medicalClearance ? (
                                  <>
                                    <Link className="viewMC" underline="always" onClick={() => handleCertificateDownload(medicalClearance.id, medicalClearance.fileName)}>
                                      Review Paper Medical Clearance
                                    </Link>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                      <hr className="separator"></hr>
                      {/* Is there a current Medical Certificate for any Work Restrictions?* */}
                      {/* Other */}
                      <div className="flexer">
                        <FormLabel className="bold blueText height-adjusted" component="legend">
                          Other*
                        </FormLabel>
                      </div>
                      <RadioGroup aria-label="other" name="other" className="flexer" row value={state.other} onChange={handleValueChange}>
                        <FormControlLabel control={<Radio color="primary" name="other" id="otherDenied" value="denied" />} value={state.other} inputRef={register} label="Denied" />
                        <FormControlLabel
                          control={<Radio color="primary" name="other" id="otherYes" value="yesMCNotRequired" />}
                          inputRef={register}
                          label="Yes - MC not required"
                        />
                      </RadioGroup>
                      {state.other === "yesMCNotRequired" ? (
                        <OutlinedInput
                          color="primary"
                          name="otherNote"
                          autoComplete="off"
                          className="med-note"
                          inputRef={register}
                          placeholder="If Yes, describe condition here*"
                          defaultValue={state.otherNote}
                          onChange={handleValueChange}
                        />
                      ) : (
                        <></>
                      )}
                      {errors.other?.message !== "" ? <div className="invalid-feedback">{errors.other?.message}</div> : <></>}
                      {errors.otherNote?.message !== "" ? <div className="invalid-feedback">{errors.otherNote?.message}</div> : <></>}
                      {state.otherMCs && state.otherMCs.length > 0 ? (
                        <>
                          {state.otherMCs
                            .filter((x) => x.medicalCertificate !== null)
                            .map((medicalClearance: any, index: number) => (
                              <>
                                <div className="viewMedicalConditionFile" key={index}>
                                  <Typography className="medicalConditionName mr-2">
                                    {medicalClearance.condition} {medicalClearance.outcome ? ` - ${medicalClearance.outcome.toUpperCase()} ` : ""}
                                  </Typography>
                                </div>
                                <div className="viewMedicalConditionFile" key={index}>
                                  <Typography className="medicalConditionName ellipsis ml-3 mr-2">{medicalClearance.otherInfo}</Typography>
                                </div>
                                <div className="viewMedicalConditionFile" key={index}>
                                  {medicalClearance.medicalCertificate ? (
                                    <>
                                      <Link
                                        className="viewMC"
                                        underline="always"
                                        onClick={() => handleCertificateDownload(medicalClearance.medicalCertificate.id, medicalClearance.medicalCertificate.filename)}
                                      >
                                        Review Existing Medical Clearance
                                      </Link>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </>
                            ))}
                        </>
                      ) : (
                        <></>
                      )}
                      {state.otherPaperMCs && state.otherPaperMCs.length > 0 ? (
                        <>
                          {state.otherPaperMCs.map((medicalClearance: any, index: number) => (
                            <>
                              <div className="viewMedicalConditionFile" key={index}>
                                <Typography className="medicalConditionName mr-2">{`${medicalClearance.condition} - PaperMC`}</Typography>
                                <Link className="cancelPaperMC" underline="always" onClick={() => cancelPaperMC("other", medicalClearance.id, medicalClearance.medicalClearanceId)}>
                                  Cancel
                                </Link>
                              </div>
                              <div className="viewMedicalConditionFile" key={index}>
                                {medicalClearance ? (
                                  <>
                                    <Link className="viewMC" underline="always" onClick={() => handleCertificateDownload(medicalClearance.id, medicalClearance.fileName)}>
                                      Review Paper Medical Clearance
                                    </Link>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                      <hr className="separator"></hr>
                      {/* Other */}
                      {state.mcRequiredList && state.mcRequiredList?.filter((x) => x.isRequired === true).length > 0 ? (
                        <>
                          {/* validation message for required  fields */}
                          {isNotValidForm() ? (
                            <span className="invalid-feedback">Please answer all medical history questions above to view the new medical clearance letter</span>
                          ) : (
                            <></>
                          )}
                          <div className="review" id="prepare">
                            <span>The following conditions require medical clearance:</span>
                            <br />
                          </div>
                          {state.mcRequiredList
                            .filter((x) => x.isRequired === true)
                            .map((mc: any, index: number) => (
                              <span key={index}>
                                <span className="reviewConditionName">{mc.conditionText}</span>
                                <Link className="editMC" underline="always" onClick={() => handleClickEdit(mc.condition)}>
                                  Edit
                                </Link>
                                {mc.isRequired === true &&
                                mc.condition !== "pregnancy" &&
                                mc.condition !== "diabetes" &&
                                mc.condition !== "epilepsy" &&
                                (mc.noteItems.length === 0 ||
                                  (mc.noteItems.length > 0 &&
                                    mc.noteItems.filter(
                                      (n) =>
                                        !n.isDeleted &&
                                        n.isMCRequired &&
                                        (n.note === "" ||
                                          n.year === "" ||
                                          n.month === "" ||
                                          (n.mcType !== eMedicalClearanceType.NOTPROVIDED && n.viewedMC === "") ||
                                          n.mcType === eMedicalClearanceType.SELECT)
                                    ).length > 0)) ? (
                                  <span className="invalid-condition">Note, year and month, MC selection are required</span>
                                ) : (
                                  <></>
                                )}
                                {(mc.isRequired === true &&
                                  mc.condition === "diabetes" &&
                                  (state.diabetesNote === "" ||
                                    (state.diabetesMCType !== eMedicalClearanceType.NOTPROVIDED && state.diabetesViewedMC === "") ||
                                    state.diabetesMCType === eMedicalClearanceType.SELECT)) ||
                                (mc.isRequired === true &&
                                  mc.condition === "epilepsy" &&
                                  (state.epilepsyNote === "" ||
                                    (state.epilepsyMCType !== eMedicalClearanceType.NOTPROVIDED && state.epilepsyViewedMC === "") ||
                                    state.epilepsyMCType === eMedicalClearanceType.SELECT)) ? (
                                  <span className="invalid-condition">Note and MC selection are required</span>
                                ) : (
                                  <></>
                                )}
                                <br />
                                {mc.noteItems && mc.noteItems.length > 0 ? (
                                  mc.noteItems.map((ni: any, index) => (
                                    <>
                                      {!ni.isDeleted && ni.isMCRequired && (ni.note !== "" || ni.month !== "" || ni.year !== "") ? (
                                        <>
                                          <p key={index} className="note-required">
                                            {ni.note} - {ni.year === 21 ? "More than 20 years" : ni.year} {ni.month}
                                          </p>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  ))
                                ) : (
                                  <></>
                                )}
                              </span>
                            ))}

                          <>
                            <div className="prepare-mc">
                              <Button variant="outlined" className="downloadMC" disabled={isNotValidForm()} onClick={handlePrepareMedicalClearance}>
                                Download New Medical Clearance
                              </Button>
                            </div>
                            <div className="agreeDownload">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    icon={<span className="checkboxIcon require-enableEndPEFA"></span>}
                                    checkedIcon={<span className="checkboxIconChecked"></span>}
                                    color="primary"
                                    className="mc-checkbox"
                                    name="enabledEndPEFA"
                                    checked={state.enabledEndPEFA}
                                    disabled={!state.clearanceDownloaded || isValidMCRequiredList()}
                                    onChange={(event) => {
                                      setState((prevState) => ({
                                        ...prevState,
                                        enabledEndPEFA: event.target.checked,
                                      }));
                                    }}
                                  />
                                }
                                label="Medical clearance letter downloaded, viewed and ready to send"
                                className="enableEndPEFA"
                                inputRef={register}
                              />
                            </div>
                            {!state.enabledEndPEFA ? (
                              <div className="invalid-medical">Please indicate that you have viewed Medical clearance letter and confirmed that it is ready to send</div>
                            ) : (
                              <></>
                            )}
                          </>
                        </>
                      ) : (
                        <></>
                      )}
                      <div className="amberNote">
                        <Typography className="amberNoteDetails">
                          * A JobFit System Medical Clearance is required for: Any Heart Conditions; BP {">"} {bpDia}/{bpSys}mmHg; Musculoskeletal Surgery, Fractures or Dislocation
                          within 6mths; Pregnancy: Injuries within 6wks that needed treatment, time off work or restrictions
                        </Typography>
                      </div>
                    </Card>
                    <Card className="consentCard" variant="outlined">
                      <Typography variant="h2" className="heroHeading">
                        Participant Declaration
                      </Typography>
                      <Typography variant="body1">
                        I confirm that the medical history and related information above is correct and complete and has been provided by me with the understanding that it is
                        necessary to help protect my health and safety during this PEFA, and it will not be disclosed to my employer without my consent.
                      </Typography>
                      <div className="longWrapper">
                        <FormControlLabel
                          control={
                            <OutlinedInput
                              color="primary"
                              name="participantName"
                              autoComplete="off"
                              className="fullWidth"
                              defaultValue={state.participantName}
                              onChange={handleValueChange}
                            />
                          }
                          label="Participant Name"
                          labelPlacement="top"
                          inputRef={register}
                          className="twoThirds"
                        />
                        <FormControlLabel
                          control={
                            <OutlinedInput color="primary" name="dateToday" autoComplete="off" className="fullWidth" disabled={true} value={state.dateToday} readOnly={true} />
                          }
                          label="Date"
                          labelPlacement="top"
                          inputRef={register}
                          className="oneThirdGrey"
                        />
                      </div>
                      <div style={{ marginLeft: "0px", marginTop: "15px" }} className="invalidFeedback">
                        {errors.participantName?.message}
                      </div>
                      {errors.participantName?.message ? (
                        <div className="invalidFeedbackInForm">{errors.participantName?.message}</div>
                      ) : !state.participantName ? (
                        <div className="invalidFeedbackInForm">A participant name is required.</div>
                      ) : (
                        <></>
                      )}
                      <FormControlLabel
                        label="Signature Capture"
                        control={
                          <div className="sigBlock">
                            <SignatureCanvas
                              penColor="black"
                              canvasProps={{
                                width: 500,
                                height: 200,
                                className: "sigCanvas",
                              }}
                              ref={(ref) => {
                                participantSignature = ref;
                              }}
                              onEnd={handleParticipantSignatureChanged}
                            />
                          </div>
                        }
                        labelPlacement="top"
                        className="fullWidth"
                      />
                      {errors.participantSignature?.message ? (
                        <div className="invalidFeedbackInForm">{errors.participantSignature?.message}</div>
                      ) : !state.participantSignature ? (
                        <div className="invalidFeedbackInForm">A participant signature is required.</div>
                      ) : (
                        <></>
                      )}
                      <input type="hidden" name="participantSignature" ref={register} value={state.participantSignature} />
                      <div className="sigButtons">
                        <Button variant="outlined" className="outlinedButton" color="primary" onClick={clearParticipantSignature}>
                          Clear Signature
                        </Button>
                      </div>
                    </Card>
                  </form>
                </Grid>
                <Grid item container md={3} sm={12} className="RightCol">
                  <ProfileCard
                    firstName={pefaAssessment.pefArequest.worker.firstName}
                    lastName={pefaAssessment.pefArequest.worker.lastName}
                    participantAgeYears={participantAgeYears}
                  />
                  <NotesModal pageKey={dataStorePageKeys.MEDICAL_DETAILS} pefaID={pefaAssessment.id} />
                </Grid>
              </Grid>
            </Container>
            <FormNavigator
              backTarget={INFORMEDCONSENT}
              forwardTarget={ASSESSMENTDETAILS}
              handleSubmit={handleSubmit(submitForm)}
              disabledNextButton={
                state.mcRequiredList?.filter((x) => x.isRequired === true).length > 0 ||
                (endAssessment &&
                  endAssessment.endAssessmentReason !== "" &&
                  endAssessment.endAssessmentNotes !== "" &&
                  endAssessment.endAssessmentPage === dataStorePageKeys.MEDICAL_DETAILS)
              }
              disabledEndButton={
                state.mcRequiredList?.filter((x) => x.isRequired === true).length > 0
                  ? isNotValidForm() || !state.enabledEndPEFA || !state.participantName || !state.participantSignature
                  : false
              }
              warning={"Please answer all medical history questions above."}
              selectedKey={dataStorePageKeys.MEDICAL_DETAILS}
            />
          </Grid>
        </div>
        <LoadingIndicator visible={state.isUploading} />
      </div>
    );
  })
);

export default MedicalDetails;

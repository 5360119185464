import * as React from "react";
import { List, ListItem } from "@material-ui/core";
import UploadIcon from "@material-ui/icons/FileCopy";

interface IFile {
  file: File;
}

interface FileProps {
  selectedFiles: IFile[];
}

export const File = (props: FileProps) => {
  const { selectedFiles } = props;

  return (
    <List>
      {selectedFiles?.map(({ file: { name } }) => (
        <ListItem key={name}>
          <UploadIcon></UploadIcon>
          <span className="primary">{name}</span>
        </ListItem>
      ))}
    </List>
  );
};

import formNavigator from "./navigation/FormNavigator";
import header from "./Header";
import pefaCard from "./cards/PefaCard";
import profileCard from "./cards/ProfileCard";
import linearStepper from "./navigation/LinearStepper";
import accordionComponent from "./cards/Accordion";
import iosSwitch from "./IosSwitch";
import mobilityTest from "./groupedComponents/MobilityTest";
import strengthTest from "./groupedComponents/StrengthTest";
import modalComponent from "./modals/modalBase";
import timer from "./timer/Timer";
import notesModal from "./modals/NotesModal";
import inTestMedicalClearanceModal from "./modals/InTestMedicalClearanceModal";
import participantReports from "./cards/ParticipantReports";
import notTested from "./modals/NotTestedModal";
import timerStopped from "./modals/TimerStoppedModal";
import metronome from "./auditory/Metronome";
import peeper from "./auditory/Peeper";
import drawerTimer from "./timer/DrawerTimer";
import manualHandlingTest from "./groupedComponents/ManualHandlingTest";
import assessorStoppedModal from "./modals/assessorStoppedModal";
import participantStoppedModal from "./modals/participantStoppedModal";
import postTestHeartRateModal from "./modals/postTestHeartRateModal";
import highBloodPressureModal from "./modals/HighBloodPressureModal";
import remoteStart from "./cards/RemoteStart";
import loadingIndicator from "./modals/loadingIndicator/LoadingIndicator";
import performanceSummary from "./cards/PerformanceSummary";
import scrollToTop from "./router/scrollToTop";
import telemetryProvider from "./telemetry/telemetryProvider";
import endAssessment from "./modals/EndAssessmentConfirmationModal";
import clearanceDocument from "./modals/MedicalClearanceDocumentModal";
import MedicalClearance from "./modals/MedicalClearanceConfirmationModal";

export const Metronome = metronome;
export const Peeper = peeper;
export const FormNavigator = formNavigator;
export const ParticipantReports = participantReports;
export const MobilityTest = mobilityTest;
export const StrengthTest = strengthTest;
export const Header = header;
export const PefaCard = pefaCard;
export const ProfileCard = profileCard;
export const Stepper = linearStepper;
export const Accordion = accordionComponent;
export const IOSSwitch = iosSwitch;
export const Modal = modalComponent;
export const Timer = timer;
export const NotesModal = notesModal;
export const InTestMedicalClearanceModal = inTestMedicalClearanceModal;
export const TimerStopped = timerStopped;
export const NotTested = notTested;
export const DrawerTimer = drawerTimer;
export const ManualHandlingTest = manualHandlingTest;
export const AssessorStoppedModal = assessorStoppedModal;
export const ParticipantStoppedModal = participantStoppedModal;
export const PostTestHeartRateModal = postTestHeartRateModal;
export const HighBloodPressureModal = highBloodPressureModal;
export const RemoteStart = remoteStart;
export const LoadingIndicator = loadingIndicator;
export const PerformanceSummary = performanceSummary;
export const ScrollToTop = scrollToTop;
export const TelemetryProvider = telemetryProvider;
export const EndAssessmentModal = endAssessment;
export const ClearanceDocumentModal = clearanceDocument;
export const MedicalClearanceModal = MedicalClearance;

import React, { Component, ReactNode } from "react";
import { Button, Typography, FormGroup, Grid, FormControl, TextField, Modal, Backdrop, Slide } from "@material-ui/core";
import { NotesIcon, EditIconBlue, TrashIconBlue } from "../../assets/images";
import { dataStorePageKeys, dataStorePageKeysFriendly } from "../../stores/dataStore";
import { inject, observer } from "mobx-react";
import { Root } from "../../stores";
import { Modal as ModalComponent } from "../";

interface NotesModalProps {
  rootStore?: Root;
  pageKey: dataStorePageKeys;
  pefaID: string;
}

interface NotesModalState {
  isModalOpen: boolean;
  isDeleteModalOpen: boolean;
  notesCount: number;
  notes: any;
  noteKeys: string[];
  noteText: string;
  noteHadText: boolean;
  selectedKey: string;
  selectedDeleteKey: string;
}

@inject("rootStore")
@observer
class NotesModal extends Component<NotesModalProps, NotesModalState> {
  constructor(props: NotesModalProps) {
    super(props);

    this.state = {
      isModalOpen: false,
      isDeleteModalOpen: false,
      notesCount: this.props.rootStore?.dataStore.getNotesCount(this.props.pefaID) || 0,
      notes: this.props.rootStore?.dataStore.getNotes(this.props.pefaID),
      noteKeys: this.props.rootStore?.dataStore.getNoteKeys(this.props.pefaID) || [],
      noteText: "",
      noteHadText: false,
      selectedKey: this.props.pageKey,
      selectedDeleteKey: "",
    };
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleSaveNote = this.handleSaveNote.bind(this);
    this.handleEditNote = this.handleEditNote.bind(this);
    this.handleRemoveNote = this.handleRemoveNote.bind(this);
    this.handleNoteChange = this.handleNoteChange.bind(this);
    this.handleOpenDeleteModal = this.handleOpenDeleteModal.bind(this);
    this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);
  }

  handleCloseModal(): void {
    const notes = this.props.rootStore?.dataStore.getNotes(this.props.pefaID);
    const notesCount = this.props.rootStore?.dataStore.getNotesCount(this.props.pefaID) || 0;
    this.setState((prevState) => ({
      ...prevState,
      isModalOpen: false,
      notes,
      notesCount,
    }));
  }

  handleOpenModal(): void {
    const noteText = this.state.notes[this.props.pageKey];
    this.setState((prevState) => ({
      ...prevState,
      noteText,
      noteHadText: !!noteText,
      isModalOpen: true,
    }));
  }

  handleSaveNote(): void {
    if (!!this.state.noteText) {
      this.props.rootStore?.dataStore.saveNote(this.state.noteText, this.props.pefaID, this.state.selectedKey as dataStorePageKeys);

      const notes = this.props.rootStore?.dataStore.getNotes(this.props.pefaID);
      const noteKeys = this.props.rootStore?.dataStore.getNoteKeys(this.props.pefaID) || [];

      this.setState((prevState) => ({
        ...prevState,
        notes,
        noteKeys,
      }));

      this.handleCloseModal();
    } else if (!this.state.noteText && this.state.noteHadText) {
      this.setState((prevState) => ({
        ...prevState,
        isDeleteModalOpen: true,
        selectedDeleteKey: this.state.selectedKey,
      }));
    } else {
      this.handleCloseModal();
    }
  }

  handleEditNote(key: string): void {
    const noteText = this.state.notes[key];
    this.setState((prevState) => ({
      ...prevState,
      noteText,
      selectedKey: key,
    }));
  }

  handleRemoveNote(): void {
    this.props.rootStore?.dataStore.removeNote(this.props.pefaID, this.state.selectedDeleteKey as dataStorePageKeys);
    const notes = this.props.rootStore?.dataStore.getNotes(this.props.pefaID);
    const noteKeys = this.props.rootStore?.dataStore.getNoteKeys(this.props.pefaID) || [];

    this.setState((prevState) => ({
      ...prevState,
      notes,
      noteKeys,
      noteText: "",
    }));
    this.handleCloseDeleteModal();
    this.handleCloseModal();
  }

  handleNoteChange(event: any): void {
    event.persist();
    this.setState((prevState) => ({
      ...prevState,
      noteText: event.target.value,
    }));
  }

  handleOpenDeleteModal(key: string): void {
    this.setState((prevState) => ({
      ...prevState,
      selectedDeleteKey: key,
      isDeleteModalOpen: true,
    }));
  }

  handleCloseDeleteModal(): void {
    this.setState((prevState) => ({
      ...prevState,
      isDeleteModalOpen: false,
    }));
  }

  render(): ReactNode {
    const { isModalOpen, notesCount, notes, noteText, noteKeys, selectedKey, isDeleteModalOpen } = this.state;
    return (
      <>
        <Button variant="outlined" className="extraNotesBtn" onClick={this.handleOpenModal}>
          My Extra Notes&nbsp;
          {notesCount > 0 ? <span className="bubble">{notesCount}</span> : <></>}
        </Button>
        <Modal
          aria-labelledby="modal-title"
          open={isModalOpen}
          onClose={this.handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Slide direction="up" in={isModalOpen} mountOnEnter unmountOnExit>
            <div className="modalPopNotes">
              <Button className="hideBtn" onClick={this.handleCloseModal}>
                Close
              </Button>
              <div className="notesModalText">
                <Typography variant="h2" className="notesHeader">
                  <img src={NotesIcon} alt="Notes Icon" />
                  Extra Notes
                </Typography>
                <FormGroup className="formGroupOveride">
                  <Grid xs={12} item container className="StrengthTestContainer">
                    <FormControl component="fieldset" className="fieldsetStrength">
                      <FormGroup className="formGroupOveride">
                        <FormGroup>
                          <TextField
                            id="extraNotes"
                            name="notTestedNotes"
                            multiline
                            fullWidth
                            variant="outlined"
                            className="outlineTextArea"
                            value={noteText}
                            onChange={this.handleNoteChange}
                          />
                        </FormGroup>
                      </FormGroup>
                      <span className="editingTag">
                        <span className="editingText">Editing: </span>
                        <span>{dataStorePageKeysFriendly[selectedKey]}</span>
                      </span>
                      <Button color="primary" variant="contained" className="saveNoteBtn" onClick={this.handleSaveNote}>
                        Save note
                      </Button>
                    </FormControl>
                  </Grid>
                  <Grid
                    xs={12}
                    item
                    container
                    className="StrengthTestContainer"
                    style={{
                      overflowY: "scroll",
                      maxHeight: "330px",
                    }}
                  >
                    <Typography variant="caption" className="pastNotesHeader">
                      Past notes
                    </Typography>
                    {notesCount > 0 ? (
                      noteKeys.map((noteKey: string) => (
                        <div key={noteKey} className="notesContainer">
                          <div className="notesLeft">
                            <span className="typeTag">{dataStorePageKeysFriendly[noteKey]}</span>
                            <Typography variant="body1" className="notesText">
                              {notes[noteKey]}
                            </Typography>
                          </div>
                          <div className="notesRight">
                            <Button className="roundButton" onClick={() => this.handleEditNote(noteKey)}>
                              <img src={EditIconBlue} alt="Edit" />
                            </Button>
                            <Button className="roundButton" onClick={() => this.handleOpenDeleteModal(noteKey)}>
                              <img src={TrashIconBlue} alt="Trash" />
                            </Button>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="notesContainer">
                        <div className="notesLeft">
                          <Typography variant="body1" className="notesText">
                            No Notes
                          </Typography>
                        </div>
                      </div>
                    )}
                  </Grid>
                </FormGroup>
              </div>
            </div>
          </Slide>
        </Modal>
        <ModalComponent title={"Delete Note..."} isOpen={isDeleteModalOpen} confirmation={true} handleClose={this.handleCloseDeleteModal}>
          <Typography style={{ marginBottom: "15px" }}>You are about to delete this note. Are you sure?</Typography>
          <div>
            <Button onClick={this.handleCloseDeleteModal} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={this.handleRemoveNote} color="primary" variant="contained">
              Confirm
            </Button>
          </div>
        </ModalComponent>
      </>
    );
  }
}

export default NotesModal;

import React, { Component, ReactNode } from "react";
import { addHours, format } from "date-fns";
import { Card, Typography, Button } from "@material-ui/core";
import { MaleIcon, FemaleIcon } from "../../assets/images";
import { inject, observer } from "mobx-react";
import { Root } from "../../stores";
import { dataStorePageKeys } from "../../stores/dataStore";
import Visibility from "@material-ui/icons/Visibility";

interface PefaCardProps {
  rootStore?: Root;

  pefaAssessment: any;
  handlePefaCardClick: any;
  handlePefaPreviewClick: any;
}

interface PefaCardState {
  identifier: string;
  gender: string;
  name: string;
  assessmentType: string;
  reportType: string;
  assessmentDate: string;
  duration: string;
  assessmentTime: string;
  due: string;
  status: string;
  assessor: string;
  rate: string;
}

@inject("rootStore")
@observer
class PefaCard extends Component<PefaCardProps, PefaCardState> {
  constructor(props: PefaCardProps) {
    super(props);

    const { pefaAssessment } = this.props;

    const now = new Date();
    const bookingDate = new Date(pefaAssessment.appointmentDateTime);

    const dayText = format(bookingDate, "EEE do MMM");

    const timeText = format(bookingDate, "h:mma");
    let dueTime;
    let due;

    if (pefaAssessment.pefArequest.responseTime.toUpperCase() === "STANDARD") {
      due = "COB";
      dueTime = new Date(format(bookingDate, "yyyy/MM/dd 17:00:00"));
    } else {
      dueTime = addHours(bookingDate, 3);
      due = format(dueTime, "h:mma");
    }

    let status = "statusIndicator";

    if (this.props.rootStore?.dataStore.dataExists(pefaAssessment.id, dataStorePageKeys.ASSESSMENT_DATA)) {
      status = "statusIndicatorInProgress";
    } else if (this.props.rootStore?.dataStore.checkedIn(pefaAssessment.id) || this.props.rootStore?.pefaStore.isPefaCheckedIn(pefaAssessment.id)) {
      status = "statusIndicatorCheckedIn";
    } else if (now > dueTime) {
      status = "statusIndicatorPastDue";
    }

    this.state = {
      identifier: pefaAssessment.id,
      gender: pefaAssessment.pefArequest.worker.gender,
      name: `${pefaAssessment.pefArequest.worker.firstName} ${pefaAssessment.pefArequest.worker.lastName}`,
      assessmentType: pefaAssessment.bookingType.displayName,
      reportType: pefaAssessment.pefArequest.referralType.name,
      assessmentDate: dayText,
      duration: pefaAssessment.bookingType.expectedDuration,
      assessmentTime: timeText.toLowerCase(),
      due,
      status,
      assessor: `${pefaAssessment.assessor.firstName} ${pefaAssessment.assessor.lastName}`,
      rate: pefaAssessment.providerFeesInc,
    };
  }

  render(): ReactNode {
    const { identifier, gender, name, assessmentType, reportType, assessmentDate, duration, assessmentTime, due, status, assessor, rate } = this.state;
    return (
      <Card id={identifier} className="PefaCard" onClick={(event) => this.props.handlePefaCardClick(event, identifier)} variant="outlined">
        <Button className="PefaButton" style={{ width: "100%", height: "100px" }}>
          <div className="pefaCardContainer">
            <div className="pefaCardDataGender" style={{ display: "flex" }}>
              <div className={status}></div>
              <img alt={gender} src={gender === "MALE" ? MaleIcon : FemaleIcon} width="50px" style={{ marginLeft: "15px", marginRight: "5px" }} />
            </div>
            <div className="pefaCardDataWorker">
              <Typography variant="caption" className="PefaCaption">
                Worker
              </Typography>
              <Typography variant="subtitle1" className="PefaText">
                {name}
              </Typography>
            </div>
            <div className="pefaCardDataAssessment">
              <Typography variant="caption" className="PefaCaption">
                Assessment Type
              </Typography>
              <Typography variant="subtitle1" className="PefaText">
                {assessmentType.replace("ApptType", "")}
              </Typography>
            </div>
            <div className="pefaCardDataReport">
              <Typography variant="caption" className="PefaCaption">
                Report Type
              </Typography>
              <Typography variant="subtitle1" className="PefaText">
                {reportType}
              </Typography>
            </div>
            <div className="pefaCardData">
              <Typography variant="caption" className="PefaCaption">
                When
              </Typography>
              <Typography variant="subtitle1" className="PefaText">
                {assessmentDate}
              </Typography>
            </div>
            <div className="pefaCardData">
              <Typography variant="caption" className="PefaCaption">
                Duration
              </Typography>
              <Typography variant="subtitle1" className="PefaText">
                {duration} minutes
              </Typography>
            </div>
            <div className="pefaCardDataTime">
              <Typography variant="caption" className="PefaCaption">
                Time
              </Typography>
              <Typography variant="subtitle1" className="PefaText">
                {assessmentTime}
              </Typography>
            </div>
            <div className="pefaCardDataDue">
              <Typography variant="caption" className="PefaCaption">
                Due
              </Typography>
              <Typography variant="subtitle1" className="PefaText">
                {due}
              </Typography>
            </div>
            <div className="pefaCardDataDue">
              <Typography variant="caption" className="PefaCaption">
                Assessor
              </Typography>
              <Typography variant="subtitle1" className="PefaText">
                {assessor}
              </Typography>
            </div>

            <div className="pefaCardDataDue">
              <Typography variant="caption" className="PefaCaption">
                Rate
              </Typography>
              <Typography variant="subtitle1" className="PefaText">
                AUD {rate}
              </Typography>
            </div>

            <div className="pefaCardPreview">
              <Button onClick={(event) => this.props.handlePefaPreviewClick(event, identifier)}>
                <Visibility />
              </Button>
            </div>
          </div>
        </Button>
      </Card>
    );
  }
}

export default PefaCard;

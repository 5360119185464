import { Card, Typography } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import React, { Component, ReactNode } from "react";
import { TickNoOutline } from "../../assets/images";
import { Root } from "../../stores";
import { dataStorePageKeysFriendly, dataStorePageKeys } from "../../stores/dataStore";

interface PerformanceSummaryProps {
  rootStore?: Root;

  assessmentId: string;
  isPreview?: boolean;
  assessmentType?: string;
}

interface displayedDemand {
  name: string;
  demand: string;
  demandType: string;
  met: boolean;
  result: string;
  order: number;
}

enum weightedFrequencyDemands {
  NT = 0,
  N = 1,
  O = 2,
  F = 3,
  C = 4,
  X = 5,
}

@inject("rootStore")
@observer
class PerformanceSummary extends Component<PerformanceSummaryProps> {
  private demandsList: displayedDemand[] = [];
  private demands: any;

  constructor(props: PerformanceSummaryProps) {
    super(props);

    this.buildSummaryObject = this.buildSummaryObject.bind(this);

    this.demands = this.props.rootStore?.pefaStore.selectedPefa.demands;
    this.demandsList = this.buildSummaryObject();
  }

  buildSummaryObject(): displayedDemand[] {
    const demandKeys = Object.keys(this.demands);
    const demandsList: displayedDemand[] = [];

    let manualHandlingBenchFloorMax = false;
    let manualHandlingBenchFloorWeight = -1;
    let manualHandlingBenchFloorDemandMet = false;

    demandKeys.forEach((demandKey) => {
      const demandObj = this.demands[demandKey];
      const friendlyName = dataStorePageKeysFriendly[demandKey];

      let demand = "";
      let demandType = "";
      let demandWeighted = "";
      let resultWeighted = null;
      let displayResult = "";
      let isDemandMet = false;

      //If the task is a manual handling task, use the demand value not the demand frequency.
      if (demandKey.search(/^MH/gi as RegExp) !== -1) {
        demand = demandObj.value;
        demandType = "MANUAL";
        demandWeighted = demandObj.value;
      } else {
        demand = demandObj.frequency;
        demandType = "TOLERANCE";
        demandWeighted = weightedFrequencyDemands[demandObj.frequency];
      }

      if (!this.props.isPreview) {
        //Retrieve the data for the current test.
        const storedResult = this.props.rootStore?.dataStore.pefaData[this.props.assessmentId][demandKey];

        //If there is a stored result for this demand, determine what the displayed result should be and apply a weighting to that result.
        //The weighting is primarily used for determining if a frequency outcome meets the expected frequency demand.
        //If there is no stored result for this demand, then the result will be blank and the demands will not be met.
        if (storedResult) {
          //Firstly, before we start trying to determine the result, if we are dealing with the bench-floor manual handling test, store some information about the test.
          if (demandKey === dataStorePageKeys.MH_BENCH_TO_FLOOR) {
            manualHandlingBenchFloorMax = storedResult.safeMaxBench;
            manualHandlingBenchFloorWeight = storedResult.testWeight;
          }

          //If we are dealing with PT, DT or Fitness tests with frequency outcomes.
          if (storedResult.toleranceRating) {
            displayResult = storedResult.toleranceRating;
          }

          //If we are dealing with the bench-bench manual handling test and the participant had been flagged as reaching their safe maximum lift for bench-floor.
          if (
            manualHandlingBenchFloorMax &&
            manualHandlingBenchFloorWeight !== -1 &&
            demandKey === dataStorePageKeys.MH_BENCH_TO_BENCH &&
            !displayResult &&
            !isDemandMet &&
            storedResult.name !== "Ladder Climbing"
          ) {
            displayResult = `${manualHandlingBenchFloorWeight}kg`;
          }
          //If we are dealing with a manual handling test other than bench-bench or bench-floor.
          if (!!storedResult.testWeight && !displayResult) {
            displayResult = `${storedResult.testWeight}kg`;
          }

          //If the test was flagged as not tested.
          if ((!!storedResult.isTestComplete && storedResult.notTestedReason) || (storedResult.notTestedReason && !displayResult)) {
            displayResult = "NT";
            isDemandMet = false;
          }
          if (storedResult.notTestedReason) {
            displayResult = "NT";
            isDemandMet = false;
          }
          //Finally, determine the weighted result. This will allow for testing if the demand was met.
          if (demandType === "TOLERANCE" && displayResult) {
            resultWeighted = (weightedFrequencyDemands as any)[displayResult];
          } else if (demandType === "MANUAL" && displayResult) {
            resultWeighted = storedResult.testWeight;
          }
        }
      }

      //If there is a weighted result that meets or exceeds the weighted demand, then the demand is met.
      if (resultWeighted) {
        isDemandMet = demandWeighted <= resultWeighted;
      } else if (resultWeighted === null && dataStorePageKeys.MH_BENCH_TO_BENCH && demandObj.name !== "Ladder Climbing") {
        //Otherwise, if we are dealing with bench-bench, there may not be a result for that test, so we should check if bench-floor met its demands.
        //If they have been met, we can assume the participant would meet the bench-bench demands based on the outcome of the bench-floor test.
        //Also, Rather than leaving the result empty, make sure that the result is populated with a value.
        isDemandMet = manualHandlingBenchFloorDemandMet;
        displayResult = `${manualHandlingBenchFloorWeight}kg`;
      }

      //When looping through, store the outcome of the bench-floor test if it exists. We may need it again for the bench-bench test demands.
      if (demandKey === dataStorePageKeys.MH_BENCH_TO_FLOOR) {
        manualHandlingBenchFloorDemandMet = isDemandMet;
      }
      if (demandType === "MANUAL" || demandType === "TOLERANCE") {
        demandsList.push({
          name: friendlyName,
          demand,
          demandType,
          met: isDemandMet,
          result: displayResult,
          order: demandObj.functionalOrder,
        });
      }
    });
    return demandsList;
  }

  render(): ReactNode {
    return (
      <Card className="uCard" variant="outlined">
        {this.props.assessmentType ? (
          <div className="flexer">
            <Typography variant="h3" className="minorHeading">
              Assessment Type:
            </Typography>
            <Typography variant="h3" className="minorSubHeading">
              &nbsp; &nbsp;{this.props.assessmentType}
            </Typography>
          </div>
        ) : (
          <Typography variant="h3" className="minorHeading">
            Performance Summary
          </Typography>
        )}
        <div className="summaryGroup">
          <div className="summaryContent">
            <div>
              <div className="flexer">
                <Typography className="summaryActivityTitle heading">Activity</Typography>
                <Typography className="summaryDemandTitle heading">Job</Typography>
                {!this.props.isPreview ? (
                  <>
                    <Typography className="summaryDemandTitle heading">W</Typography>
                    <Typography className="summaryDemandTitle heading">Match?</Typography>
                  </>
                ) : (
                  <></>
                )}
              </div>
              {this.demandsList.map((demand) => {
                if (demand.demandType === "TOLERANCE") {
                  return (
                    <div className="flexer">
                      <Typography className="summaryActivityTitle">{demand.name}</Typography>
                      <Typography className="summaryDemandTitle">{demand.demand}</Typography>
                      {!this.props.isPreview ? (
                        <>
                          <Typography className="summaryDemandTitle">{demand.result === "-1kg" ? "NT" : demand.result}</Typography>
                          <Typography className={`summaryDemandMatch ${demand.met ? "tick" : "cross"}`}>
                            {demand.met ? <img src={TickNoOutline} alt="Tick" /> : "❌"}
                          </Typography>{" "}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  );
                }
              })}
            </div>
          </div>
          <div className="summaryContent" style={{ marginLeft: "30px" }}>
            <div>
              <div className="flexer">
                <Typography className="summaryActivityTitle heading">Activity</Typography>
                <Typography className="summaryDemandTitle heading">Job</Typography>
                {!this.props.isPreview ? (
                  <>
                    <Typography className="summaryDemandTitle heading">W</Typography>
                    <Typography className="summaryDemandTitle heading">Match?</Typography>
                  </>
                ) : (
                  <></>
                )}
              </div>
              {this.demandsList.map((demand) => {
                if (demand.demandType === "MANUAL") {
                  return (
                    <div className="flexer">
                      <Typography className="summaryActivityTitle">{demand.name}</Typography>
                      <Typography className="summaryDemandTitle">{`${demand.demand}kg`}</Typography>
                      {!this.props.isPreview ? (
                        <>
                          <Typography className="summaryDemandTitle">{demand.result && demand.result === "-1kg" ? "NT" : demand.result}</Typography>
                          <Typography className={`summaryDemandMatch ${demand.met && demand.result !== "-1kg" ? "tick" : "cross"}`}>
                            {demand.met && demand.result !== "NT" ? <img src={TickNoOutline} alt="Tick" /> : "❌"}
                          </Typography>{" "}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </Card>
    );
  }
}

export default PerformanceSummary;

export interface environmentConfig {
  configUrl: string;
  apiUrl: string;
  isProduction: boolean;
  applicationInsightKey: string;
  hubUrl: string;
}

export class Constants {
  public static applicationName = "PEFA_App";
  public static rootStoreKey = "root";
  public static cryptoStoreKey = "j28aw1i@PCs5%yjx";
  public static applicationInsightsKey = "";

  private static hostname = window && window.location && window.location.hostname;

  public static environment(): environmentConfig {
    let configUrl = "";
    let apiUrl = "";
    let isProduction = false;
    let applicationInsightKey = "";
    let hubUrl = "";

    if (this.hostname === "pefaapp.jobfitsystem.com") {
      configUrl = "https://hub.jobfitsystem.com/_configuration/PEFAApp";
      apiUrl = "https://console.jobfitsystem.com";
      isProduction = true;
      applicationInsightKey = "5910167c-ee9c-4fb8-9a16-5dfb58c56668";
      hubUrl = "https://hub.jobfitsystem.com/";
    } else {
      configUrl = "https://hubtest.azurewebsites.net/_configuration/PEFAApp";
      apiUrl = "https://console-test.azurewebsites.net";
      applicationInsightKey = "2045ac55-b677-4fc5-a129-c6539f1a71be";
      hubUrl = "https://hubtest.azurewebsites.net/";
    }

    return {
      configUrl,
      apiUrl,
      isProduction,
      applicationInsightKey,
      hubUrl,
    };
  }
}

import React, { useEffect, useState } from "react";
import { withStyles, WithStyles, Theme } from "@material-ui/core/styles";
import { AppBar, Toolbar, Typography, IconButton, Button, MenuItem, Menu, ListItem } from "@material-ui/core";
import { JFLogo, PefaLogo } from "../assets/images";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { inject, observer } from "mobx-react";
import { Root } from "../stores";
import { useAuth } from "oidc-react";
import { Constants } from "../helpers/Constants";

const styles = (theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
});

interface HeaderProps extends WithStyles<typeof styles> {
  rootStore?: Root;
}

interface HeaderState {
  anchorEl: HTMLElement | null;
  name: string;
  initials: string;
}

const Header = inject("rootStore")(
  observer((props: HeaderProps) => {
    const { classes } = props;
    const auth = useAuth();

    const [state, setState] = useState<HeaderState>({
      anchorEl: null,
      name: "",
      initials: "",
    });

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      const anchorEl = event.currentTarget;
      setState((prevState) => ({ ...prevState, anchorEl }));
    };

    const handleClose = () => {
      const anchorEl = null;
      setState((prevState) => ({ ...prevState, anchorEl }));
    };

    const redirectToHub = () => {
      window.location.href = Constants.environment().hubUrl;
      return null;
    };

    const handleLogout = () => {
      if (!!auth) {
        auth.signOutRedirect();
      }
    };

    useEffect(() => {
      async function setUserDetails() {
        const { rootStore } = props;
        setState((prevState) => ({
          ...prevState,
          name: !!rootStore?.userStore.userDetails ? rootStore.userStore.getUserNameFormatted : "",
          initials: !!rootStore?.userStore.userDetails ? rootStore.userStore.getUserInitials : "",
        }));
      }
      setUserDetails();
    }, []);

    return (
      <div style={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <IconButton edge="start" className={classes.menuButton}>
              <img src={PefaLogo} height="40" alt="Logo" />
            </IconButton>
            <Typography className={classes.title}>&nbsp;</Typography>

            <Button aria-label="Account of current user" aria-controls="menu-appbar" aria-haspopup="true" color="primary" disableRipple={true} onClick={handleClick}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginRight: "20px" }}>
                  <ExpandMoreIcon style={{ color: "#ffffff" }} />
                </div>
                <div style={{ display: "inline-block", marginRight: "30px" }}>
                  <div>
                    <Typography
                      variant="subtitle1"
                      style={{
                        fontSize: "16px",
                        color: "#ffffff",
                        lineHeight: "1",
                        fontWeight: "bold",
                      }}
                    >
                      {state.name}
                    </Typography>
                  </div>
                  <div style={{ textAlign: "left" }}>
                    <Typography variant="caption" className="settingsText">
                      Settings
                    </Typography>
                  </div>
                </div>
              </div>
              <span className="MenuIconName">{state.initials}</span>
            </Button>
            <Menu
              id="menu-appbar"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              className="MenuDropdown"
              anchorEl={state.anchorEl}
              open={Boolean(state.anchorEl)}
              onClose={handleClose}
            >
              <Typography className="settingsHeaderText" variant="caption">
                Settings
              </Typography>
              <MenuItem onClick={redirectToHub}>JobFit System HUB</MenuItem>
              <MenuItem onClick={handleLogout}>Log Out</MenuItem>
              <ListItem
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <Typography className="PoweredBy" variant="caption">
                  Powered By
                </Typography>
                <img src={JFLogo} width="80" alt="Logo" />
              </ListItem>
            </Menu>
          </Toolbar>
        </AppBar>
      </div>
    );
  })
);

export default withStyles(styles, { withTheme: true })(Header);

import * as yup from "yup";
import { eMedicalClearanceRequired, eMedicalClearanceType } from "./MedicalDetails";

const medicalConsentSchema = yup.object().shape({
  highBloodPressure: yup.string().test("highBloodPressure", "Input is required", function () {
    const { highBloodPressure, highBloodPressureMCType, highBloodPressureViewedMC, highBloodPressureNote } = this.parent;
    if (
      !highBloodPressure ||
      (highBloodPressure === "yesMC" && highBloodPressureNote === "") ||
      (highBloodPressure === "yesMC" && highBloodPressureMCType === eMedicalClearanceType.SELECT) ||
      (highBloodPressure === "yesMC" && highBloodPressureMCType !== eMedicalClearanceType.NOTPROVIDED && highBloodPressureViewedMC === "")
    ) {
      return false;
    }
    return true;
  }),
  lowBloodPressure: yup.string().test("lowBloodPressure", "Input is required", function () {
    const { lowBloodPressure } = this.parent;
    if (!lowBloodPressure) {
      return false;
    }
    return true;
  }),
  // lowBloodPressureNote: yup.string().test("lowBloodPressureNote", "Input is required", function () {
  //   const { lowBloodPressureNote, lowBloodPressure } = this.parent;
  //   if (lowBloodPressureNote === "" && lowBloodPressure === "yesMCNotRequired") {
  //     return false;
  //   }
  //   return true;
  // }),
  pacemaker: yup.string().test("pacemaker", "Input is required", function () {
    const { pacemaker } = this.parent;
    if (!pacemaker) {
      return false;
    }
    return true;
  }),
  otherHeartCondition: yup.string().test("otherHeartCondition", "Input is required", function () {
    const { otherHeartCondition } = this.parent;
    if (!otherHeartCondition) {
      return false;
    }
    return true;
  }),
  boneSurgeries: yup.string().test("boneSurgeries", "Input is required", function () {
    const { boneSurgeries } = this.parent;
    if (!boneSurgeries) {
      return false;
    }
    return true;
  }),
  fractures: yup.string().test("fractures", "Input is required", function () {
    const { fractures } = this.parent;
    if (!fractures) {
      return false;
    }
    return true;
  }),
  dislocations: yup.string().test("dislocations", "Input is required", function () {
    const { dislocations } = this.parent;
    if (!dislocations) {
      return false;
    }
    return true;
  }),
  hernia: yup.string().test("hernia", "Input is required", function () {
    const { hernia } = this.parent;
    if (!hernia) {
      return false;
    }
    return true;
  }),
  otherSurgeries: yup.string().test("otherSurgeries", "Input is required", function () {
    const { otherSurgeries } = this.parent;
    if (!otherSurgeries) {
      return false;
    }
    return true;
  }),
  arthritis: yup.string().test("arthritis", "Input is required", function () {
    const { arthritis } = this.parent;
    if (!arthritis) {
      return false;
    }
    return true;
  }),
  arthritisNote: yup.string().test("arthritisNote", "Input is required", function () {
    const { arthritisNote, arthritis } = this.parent;
    if (arthritisNote === "" && arthritis === "yesMCNotRequired") {
      return false;
    }
    return true;
  }),
  asthma: yup.string().test("asthma", "Input is required", function () {
    const { asthma } = this.parent;
    if (!asthma) {
      return false;
    }
    return true;
  }),
  otherLungConditionNote: yup.string().test("otherLungConditionNote", "Input is required", function () {
    const { otherLungConditionNote, asthma } = this.parent;
    if (otherLungConditionNote === "" && asthma === "yesMCNotRequired") {
      return false;
    }
    return true;
  }),
  cancer: yup.string().test("cancer", "Input is required", function () {
    const { cancer } = this.parent;
    if (!cancer) {
      return false;
    }
    return true;
  }),
  cancerNote: yup.string().test("cancerNote", "Input is required", function () {
    const { cancerNote, cancer } = this.parent;
    if (cancerNote === "" && cancer === "yesMCNotRequired") {
      return false;
    }
    return true;
  }),
  epilepsy: yup.string().test("epilepsy", "Input is required", function () {
    const { epilepsy } = this.parent;
    if (!epilepsy) {
      return false;
    }
    return true;
  }),
  epilepsyNote: yup.string().test("epilepsyNote", "Input is required", function () {
    const { epilepsyNote, epilepsy } = this.parent;
    if (epilepsyNote === "" && (epilepsy === "yesControlled" || epilepsy === "yesNotControlled")) {
      return false;
    }
    return true;
  }),
  epilepsyMCAssessor: yup.string().test("epilepsyMCAssessor", "Selection is required", function () {
    const { epilepsyMCAssessor, epilepsy } = this.parent;
    if ((epilepsyMCAssessor === "" || epilepsyMCAssessor === eMedicalClearanceRequired.SELECT) && epilepsy === "yesNotControlled") {
      return false;
    }
    return true;
  }),
  neurological: yup.string().test("neurological", "Input is required", function () {
    const { neurological } = this.parent;
    if (!neurological) {
      return false;
    }
    return true;
  }),
  neurologicalNote: yup.string().test("neurologicalNote", "Input is required", function () {
    const { neurologicalNote, neurological } = this.parent;
    if (neurologicalNote === "" && neurological === "yesMCNotRequired") {
      return false;
    }
    return true;
  }),
  diabetes: yup.string().test("diabetes", "Input is required", function () {
    const { diabetes } = this.parent;
    if (!diabetes) {
      return false;
    }
    return true;
  }),
  diabetesNote: yup.string().test("diabetesNote", "Input is required", function () {
    const { diabetesNote, diabetes } = this.parent;
    if (diabetesNote === "" && (diabetes === "yesControlled" || diabetes === "yesNotControlled")) {
      return false;
    }
    return true;
  }),
  diabetesMCAssessor: yup.string().test("diabetesMCAssessor", "Selection is required", function () {
    const { diabetesMCAssessor, diabetes } = this.parent;
    if ((diabetesMCAssessor === "" || diabetesMCAssessor === eMedicalClearanceRequired.SELECT) && diabetes === "yesNotControlled") {
      return false;
    }
    return true;
  }),
  infectious: yup.string().test("infectious", "Input is required", function () {
    const { infectious } = this.parent;
    if (!infectious) {
      return false;
    }
    return true;
  }),
  infectiousConditionNote: yup.string().test("infectiousConditionNote", "Input is required", function () {
    const { infectiousConditionNote, infectious } = this.parent;
    if (infectiousConditionNote === "" && infectious === "yesMCNotRequired") {
      return false;
    }
    return true;
  }),
  other: yup.string().test("other", "Input is required", function () {
    const { other } = this.parent;
    if (!other) {
      return false;
    }
    return true;
  }),
  otherNote: yup.string().test("otherNote", "Input is required", function () {
    const { otherNote, other } = this.parent;
    if (otherNote === "" && other === "yesMCNotRequired") {
      return false;
    }
    return true;
  }),
  anyInjuries6wks: yup.string().test("anyInjuries6wks", "Input is required", function () {
    const { anyInjuries6wks } = this.parent;
    if (!anyInjuries6wks) {
      return false;
    }
    return true;
  }),
  medCertOrWrkRstrctns: yup.string().test("medCertOrWrkRstrctns", "Input is required", function () {
    const { medCertOrWrkRstrctns } = this.parent;
    if (!medCertOrWrkRstrctns) {
      return false;
    }
    return true;
  }),
  pregnancy: yup.string().test("pregnancy", "Input is required", function () {
    const { pregnancy } = this.parent;
    if (pregnancy !== null && pregnancy === "") {
      return false;
    }
    return true;
  }),
  participantName: yup.string().required("A participant name is required."),
  participantSignature: yup.string().required("A participant signature is required."),
});

export default medicalConsentSchema;

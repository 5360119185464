import React, { Component, ErrorInfo, Fragment } from "react";
import { ReactPlugin, withAITracking } from "@microsoft/applicationinsights-react-js";
import { ai, getAppInsights } from "./telemetryService";
import { withRouter } from "react-router-dom";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import ErrorView from "../../views/Error";
import { inject, observer } from "mobx-react";

/*

The following is a guide to the props and state in use. We can't use the normal method because the app insights handlers crack a sad if we do.

errorProviderProps {
  rootStore?: any;
  history: any;
  instrumentationKey: string;

  children: any;
}

errorProviderState {
  initialized: boolean;
  hasError: boolean;
}
*/

/**
 * This Component provides telemetry with Azure App Insights
 *
 * We are essentially replicating the appInsightsErrorBoundary here. But it allows us to add our own bits to the exception handling before we post it up.
 * Without replicating it, we would have no way of adding the booking id to the exception details.
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */
@inject("rootStore")
@observer
class TelemetryProvider extends Component<any> {
  state = {
    initialized: false,
    hasError: false,
  };

  appInsights: any;

  componentWillMount() {
    const { history, instrumentationKey } = this.props;
    const { initialized } = this.state;
    if (!Boolean(initialized) && Boolean(instrumentationKey) && Boolean(history)) {
      ai.initialize(instrumentationKey, history);
      this.setState((prevState) => ({ ...prevState, initialized: true }));
    }

    this.appInsights = getAppInsights();
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    console.error(error);
    this.setState((prevState) => ({ ...prevState, hasError: true }));

    this.appInsights.trackException({
      error: error,
      exception: error,
      severityLevel: SeverityLevel.Error,
      properties: { ...info, bookingId: this.props.rootStore?.pefaStore.selectedPefa[0].id || -1, page: this.props.history.location || "" },
    });
  }

  render() {
    const { children } = this.props;
    if (!!this.appInsights) {
      if (this.state.hasError) {
        return <ErrorView />;
      }
      return <Fragment>{children}</Fragment>;
    } else {
      return <></>;
    }
  }
}

export default withRouter(withAITracking(ai.reactPlugin as ReactPlugin, TelemetryProvider));

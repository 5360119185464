import { Button, Drawer, Grid, Typography } from "@material-ui/core";
import React, { Component, ReactNode } from "react";
import { TimerIcon } from "../../assets/images";

interface DrawerTimerProps {
  isTimerOpen: boolean;
  countUp: boolean;
  timeTarget: string;

  timerCompletedHandler?: any;
  timerStopHandler?: any;
  remoteResumeHandler?: any;
}

interface DrawerTimerState {
  seconds: number;
  totalSeconds: number;
  currentSeconds: number;
  formattedTime: string;
  isTimerRunning: boolean;
}

export default class DrawerTimer extends Component<DrawerTimerProps, DrawerTimerState> {
  DrawerTimer: NodeJS.Timeout | undefined;

  constructor(props: DrawerTimerProps) {
    super(props);

    const timeParts = this.props.timeTarget.split(":");

    const calculatedSeconds = this.calculateSeconds(parseInt(timeParts[0]), parseInt(timeParts[1]));

    this.state = {
      seconds: 0,
      totalSeconds: calculatedSeconds,
      currentSeconds: !this.props.countUp ? calculatedSeconds : 0,
      formattedTime: "",
      isTimerRunning: false,
    };
  }

  calculateSeconds = (minutes: number, seconds: number): number => {
    return minutes * 60 + seconds;
  };

  startTimer = (): void => {
    this.DrawerTimer = setInterval(this.iterateTimer, 1000);
  };

  stopTimer = (): void => {
    if (this.DrawerTimer) {
      clearInterval(this.DrawerTimer);
    }
  };

  resetTimer = (): void => {
    this.setState((prevState) => ({
      ...prevState,
      isTimerRunning: false,
      formattedTime: "",
      currentSeconds: 0,
    }));
  };

  handleTimerStopClicked = (): void => {
    this.stopTimer();
    this.resetTimer();
    if (!!this.props.timerStopHandler) {
      this.props.timerStopHandler(this.state.formattedTime);
    }
  };

  handleTimerResumeClicked = (): void => {
    this.stopTimer();
    this.resetTimer();
    if (!!this.props.remoteResumeHandler) {
      this.props.remoteResumeHandler();
    }
  };

  iterateTimer = (): void => {
    let { currentSeconds, isTimerRunning } = this.state;
    if (
      (!this.props.countUp && currentSeconds === this.state.totalSeconds) || //if we are counting down and no time has elapsed, continue.
      (this.props.countUp && currentSeconds === 0) || //if we are counting up and no time has elapsed, continue.
      isTimerRunning //if the timer is running, continue.
    ) {
      if (this.props.countUp) {
        currentSeconds++;
        isTimerRunning = !(currentSeconds === this.state.totalSeconds);
      } else {
        if (currentSeconds === 0) {
          currentSeconds = this.state.totalSeconds;
        }
        currentSeconds--;
        isTimerRunning = !(currentSeconds === 0);
      }

      let formattedTime = "";
      if (currentSeconds <= 60) {
        formattedTime = `${currentSeconds}sec`;
      } else {
        const minutes = Math.floor(currentSeconds / 60);
        const seconds = currentSeconds % 60;
        const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
        formattedTime = `${minutes}:${formattedSeconds}`;
      }

      this.setState((prevState) => ({
        ...prevState,
        currentSeconds,
        seconds: currentSeconds,
        isTimerRunning,
        formattedTime,
      }));
    } else {
      this.stopTimer();
      if (!!this.props.timerCompletedHandler) {
        this.props.timerCompletedHandler(this.state.formattedTime);
      }
      this.resetTimer();
    }
  };

  shouldComponentUpdate(nextProps: DrawerTimerProps, nextState: DrawerTimerState): boolean {
    if (nextProps.isTimerOpen === !this.props.isTimerOpen && nextProps.isTimerOpen && !nextState.isTimerRunning && !this.DrawerTimer) {
      this.startTimer();
      return true;
    }

    if (nextState.currentSeconds !== this.state.currentSeconds) {
      return true;
    }

    return false;
  }

  componentDidUpdate(): void {
    if (!this.state.isTimerRunning && this.state.formattedTime === "" && this.state.currentSeconds === 0 && this.state.seconds > 0 && this.DrawerTimer) {
      this.DrawerTimer = undefined;
      this.setState((prevState) => ({ ...prevState, seconds: 0 }));
    }
  }

  render(): ReactNode {
    const { formattedTime } = this.state;
    return (
      <Drawer open={this.props.isTimerOpen} variant="persistent" anchor="top">
        {/* <div className="recoveryModal"> */}
        <Grid container direction="row" justify="space-around" alignItems="center">
          <Grid item xs={2}></Grid>
          <Grid item xs={6}>
            <div className="recoveryModal">
              <img src={TimerIcon} height="35" alt="Recovery Timer" />
              <Typography variant="h2">
                Recovery Time <span className="recoveryTimer">{this.DrawerTimer ? <span>{formattedTime}</span> : <span>&nbsp;</span>}</span>
              </Typography>
            </div>
          </Grid>
          <Grid item xs={2}>
            {this.props.timerStopHandler ? (
              <Button color="primary" variant="outlined" onClick={this.handleTimerStopClicked} className="stopTimerButton">
                Stop
              </Button>
            ) : (
              <></>
            )}
            {this.props.remoteResumeHandler ? (
              <Button color="primary" variant="outlined" onClick={this.handleTimerResumeClicked} className="stopTimerButton">
                Resume
              </Button>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
        {/* </div> */}
      </Drawer>
    );
  }
}

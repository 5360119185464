import React, { Component, ReactNode } from "react";
import { Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import { Container, Grid } from "@material-ui/core";
import { Stepper, Step, StepLabel } from "@material-ui/core";
import { inject, observer } from "mobx-react";

const styles = (theme: Theme) => ({
  root: {
    width: "100%",
    flexGrow: 1,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

interface StepperProps extends WithStyles<typeof styles> {
  targetStep?: string;
}

interface StepperState {
  activeStep: number;
  skipped: Set<number>;
}

@inject("rootStore")
@observer
class LinearStepper extends Component<StepperProps, StepperState> {
  steps = ["Assessment Details", "Observations & Musculosketal", "Balance Test", "Fitness Test", "Postural & Dynamic Tolerances", "Manual Handling", "Preview & Recomendations"];

  constructor(props: StepperProps) {
    super(props);

    this.state = {
      activeStep: this.steps.indexOf(this.props.targetStep || ""),
      skipped: new Set<number>(),
    };
  }

  render(): ReactNode {
    const { classes } = this.props;
    const { activeStep } = this.state;

    return (
      <div className={classes.root}>
        <Grid item container direction="row" className="stepperContainer" xs={12}>
          <Container maxWidth={"lg"}>
            {this.props.targetStep ? (
              <Stepper activeStep={activeStep}>
                {this.steps.map((label, index) => {
                  const stepProps: { completed?: boolean } = {};
                  const labelProps: { optional?: ReactNode } = {};
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel StepIconComponent="noindex">{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            ) : (
              <Stepper>
                <></>
              </Stepper>
            )}
          </Container>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(LinearStepper);

import * as yup from "yup";

const generalObservationsValidation = yup.object().shape({
  posture: yup
    .bool()
    .test("posture", "Please provide feedback on participant posture.", function () {
      const {
        postureNilSignificant,
        postureForwardHeadedPosture,
        postureFlatBackPosture,
        postureSwayBackPosture,
        postureScapularWinging,
        postureRoundedShoulders,
        postureGenusVarusBow,
        postureAssymetry,
      } = this.parent;

      if (
        !postureNilSignificant &&
        !postureForwardHeadedPosture &&
        !postureFlatBackPosture &&
        !postureSwayBackPosture &&
        !postureScapularWinging &&
        !postureRoundedShoulders &&
        !postureGenusVarusBow &&
        !postureAssymetry
      ) {
        return false;
      }
      return true;
    })
    .test("assymetry", "Please provide additional notes on postural assymetry", function () {
      const { postureAssymetry, postureNotes } = this.parent;

      if (postureAssymetry && !postureNotes) {
        return false;
      }

      return true;
    })
    .test("postureHierarchy", "Please provide feedback on participant posture or select Nil NilSignificant, not both.", function () {
      const {
        postureNilSignificant,
        postureForwardHeadedPosture,
        postureFlatBackPosture,
        postureSwayBackPosture,
        postureScapularWinging,
        postureRoundedShoulders,
        postureGenusVarusBow,
        postureAssymetry,
      } = this.parent;
      if (
        postureNilSignificant &&
        (postureForwardHeadedPosture ||
          postureFlatBackPosture ||
          postureSwayBackPosture ||
          postureScapularWinging ||
          postureRoundedShoulders ||
          postureGenusVarusBow ||
          postureAssymetry)
      ) {
        return false;
      }
      return true;
    }),
  build: yup
    .bool()
    .test("build", "Please provide feedback on participant build.", function () {
      const { buildNilSignificant, buildGeneralReducedMuscleBulk, buildIncreasedAdiposeTissue, buildMuscularAtrophy } = this.parent;

      if (!buildNilSignificant && !buildGeneralReducedMuscleBulk && !buildIncreasedAdiposeTissue && !buildMuscularAtrophy) {
        return false;
      }
      return true;
    })
    .test("atrophy", "Please provide additional notes on muscular atrophy", function () {
      const { buildMuscularAtrophy, buildNotes } = this.parent;

      if (buildMuscularAtrophy && !buildNotes) {
        return false;
      }

      return true;
    })
    .test("buildHierarchy", "Please provide feedback on participant build or select Nil Feedback, not both.", function () {
      const { buildNilSignificant, buildGeneralReducedMuscleBulk, buildIncreasedAdiposeTissue, buildMuscularAtrophy } = this.parent;

      if (buildNilSignificant && (buildGeneralReducedMuscleBulk || buildIncreasedAdiposeTissue || buildMuscularAtrophy)) {
        return false;
      }
      return true;
    }),
  oedemaAndinflammation: yup
    .bool()
    .test("oedemaAndinflammation", "Please provide feedback on participant oedema and inflammation.", function () {
      const { oedemainflammationNilSignificant, oedemaLocation, oedemaSeverityMild, oedemaSeverityModerate, oedemaSeveritySevere, oedemainflammationNotes } = this.parent;

      if (!oedemainflammationNilSignificant && !oedemaLocation && !oedemaSeverityMild && !oedemaSeverityModerate && !oedemaSeveritySevere && !oedemainflammationNotes) {
        return false;
      }
      return true;
    })
    .test("oedema", "Please provide additional notes on the location of participant oedema", function () {
      const { oedemaLocation, oedemaSeverityMild, oedemaSeverityModerate, oedemaSeveritySevere } = this.parent;

      //If the location has been entered, severity is required. Similarly,
      //if severity has been provided, the location must be provided.
      if (
        (oedemaLocation && !oedemaSeverityMild && !oedemaSeverityModerate && !oedemaSeveritySevere) ||
        (!oedemaLocation && (oedemaSeverityMild || oedemaSeverityModerate || oedemaSeveritySevere))
      ) {
        return false;
      }

      return true;
    })
    .test("oedemaAndinflammationHierarchy", "Please provide feedback on any participant oedema or inflammation or select Nil NilSignificant, not both.", function () {
      const { oedemainflammationNilSignificant, oedemaLocation, oedemaSeverityMild, oedemaSeverityModerate, oedemaSeveritySevere, oedemainflammationNotes } = this.parent;

      if (oedemainflammationNilSignificant && (oedemaLocation || oedemaSeverityMild || oedemaSeverityModerate || oedemaSeveritySevere || oedemainflammationNotes)) {
        return false;
      }
      return true;
    }),
  scars: yup
    .bool()
    .test("scars", "Please provide feedback on participant scars.", function () {
      const { scarsNilSignificant, scarNotes } = this.parent;

      if (!scarsNilSignificant && !scarNotes) {
        return false;
      }
      return true;
    })
    .test("scarsHierarchy", "Please provide feedback on participant scars or select Nil NilSignificant, not both.", function () {
      const { scarsNilSignificant, scarNotes } = this.parent;

      if (scarsNilSignificant && !!scarNotes) {
        return false;
      }
      return true;
    }),
  gait: yup
    .bool()
    .test("gait", "Please provide feedback on participant gait.", function () {
      const { gaitNilSignificant, gaitReducedWeightBearingLeft, gaitReducedWeightBearingRight, gaitTrendelenburg, gaitNotes } = this.parent;
      if (!gaitNilSignificant && !gaitReducedWeightBearingLeft && !gaitReducedWeightBearingRight && !gaitTrendelenburg && !gaitNotes) {
        return false;
      }
      return true;
    })
    .test("gaitHierarchy", "Please provide feedback on participant Gait or select Nil NilSignificant, not both.", function () {
      const { gaitNilSignificant, gaitReducedWeightBearingLeft, gaitReducedWeightBearingRight, gaitTrendelenburg, gaitNotes } = this.parent;
      if (gaitNilSignificant && (gaitReducedWeightBearingLeft || gaitReducedWeightBearingRight || gaitTrendelenburg || gaitNotes)) {
        return false;
      }
      return true;
    }),
  braceProsthesis: yup
    .bool()
    .test("braceProsthesis", "Please provide feedback on participant braces, prosthesis, etc.", function () {
      const { bracesProsthesisNilSignificant, bracesProsthesisProsthesis, gaitReducedWeightBearingRight, bracesProsthesisOtherSupportDevice, bracesProsthesisNotes } = this.parent;
      if (!bracesProsthesisNilSignificant && !bracesProsthesisProsthesis && !gaitReducedWeightBearingRight && !bracesProsthesisOtherSupportDevice && !bracesProsthesisNotes) {
        return false;
      }
      return true;
    })
    .test("braceProsthesisHierarchy", "Please provide feedback on participant braces, prosthesis, etc. or select Nil NilSignificant, not both.", function () {
      const { bracesProsthesisNilSignificant, bracesProsthesisProsthesis, gaitReducedWeightBearingRight, bracesProsthesisOtherSupportDevice, bracesProsthesisNotes } = this.parent;
      if (bracesProsthesisNilSignificant && (bracesProsthesisProsthesis || gaitReducedWeightBearingRight || bracesProsthesisOtherSupportDevice || bracesProsthesisNotes)) {
        return false;
      }
      return true;
    }),
});

export default generalObservationsValidation;

import { Constants } from "../../helpers/Constants";
import { AxiosInstance } from "axios";
import { AuthContextProps, User } from "oidc-react";

export function tokenInterceptor(axios: AxiosInstance, user?: User | null | undefined, auth?: AuthContextProps | null | undefined): any {
  /**
   * Intercept outgoing requests, adding an authorisation bearer token header to calls to the PEFA api.
   */
  axios.interceptors.request.use((config) => {
    //Get the request's target URL, build an array of allowed origins.
    const { origin } = new URL(config.url || "");
    const environment = Constants.environment();
    const allowedOrigins = [environment.apiUrl];

    //if the request's target url is the PEFA api server, add the token to the header.
    if (allowedOrigins.includes(origin) && (user || auth?.userData?.access_token)) {
      config.headers.authorization = `Bearer ${auth ? auth.userData?.access_token : user?.access_token}`;
    }

    return config;
  });

  /**
   * Intercept incoming requests, checking for any 401 responses.
   */
  axios.interceptors.response.use(
    function (response) {
      //If there is no issues, let it go through without any changes.
      //NOTE: this is a nice place to put a console.log if you want to see what is coming back.
      return response;
    },
    function (error) {
      //If the request is an error response, get the server's URL and original request.
      const originalRequest = error.config;

      //If we got 4xx response and the requst was to the refresh token URL, their session has expired, force them to log in again.
      if (error.response.status >= 400 && error.response.status < 500) {
        auth?.userManager.signinRedirect();
      }

      //If there was a 4xx from any other endpoint, assume that the user's token has just expired and needs renewing.
      return Promise.reject(error);
    }
  );
}

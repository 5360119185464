import React, { useState, useEffect } from "react";
import { ProfileCard, FormNavigator, Stepper, NotesModal, PerformanceSummary, ParticipantReports, InTestMedicalClearanceModal } from "../../../components/";
import { Root } from "../../../stores";
import programRecommendationsSchema from "./validation";
import { dataStorePageKeys } from "../../../stores/dataStore";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { differenceInYears } from "date-fns";
import { inject, observer } from "mobx-react";
import {
  Grid,
  Typography,
  FormControl,
  Card,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Container,
  FormHelperText,
  Radio,
  RadioGroup,
  OutlinedInput,
  Accordion as StyledAccordion,
  AccordionSummary,
  AccordionDetails,
  FormLabel,
} from "@material-ui/core";
import { Success } from "../../../assets/images";
import { useHistory } from "react-router";
import { DASHBOARD, PEFAREVIEW } from "../../../routes/paths";
import { getAppInsights } from "../../../components/telemetry";
import { ExpandMore as ExpandMoreIcon, ErrorOutline } from "@material-ui/icons";

interface ProgramRecommendationsProps {
  rootStore?: Root;
}

const ProgramRecommendations = inject("rootStore")(
  observer((props: ProgramRecommendationsProps) => {
    let pefaAssessment: any;
    let prevData: any = null;
    let referralDetails: any = null;
    let participantAgeYears = 0;
    let medicalHistoryData: any;
    const history = useHistory();
    const appInsights = getAppInsights();

    if (props.rootStore?.pefaStore.selectedPefa) {
      pefaAssessment = props.rootStore?.pefaStore.selectedPefa[0];
      if (props.rootStore?.dataStore.pefaData.hasOwnProperty(pefaAssessment.id)) {
        prevData = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.PROGRAM_RECOMMENDATIONS);
        referralDetails = props.rootStore?.dataStore.getCheckInData(pefaAssessment.id, dataStorePageKeys.REFERRAL_DETAILS);
        medicalHistoryData = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.MEDICAL_DETAILS);
        participantAgeYears = differenceInYears(new Date(), new Date(referralDetails?.dob ?? pefaAssessment.pefArequest.worker.dateOfBirth));
      } else {
        participantAgeYears = differenceInYears(new Date(), new Date(pefaAssessment.pefArequest.worker.dateOfBirth));
      }
    } else {
      history.push(DASHBOARD);
      return <></>;
    }

    const { register, handleSubmit, errors, setValue } = useForm({
      resolver: yupResolver(programRecommendationsSchema),
    });

    const [state, setState] = useState({
      isLoading: true,
      programRecommendationAerobicConditioning: prevData ? prevData.programRecommendationAerobicConditioning : false,
      programRecommendationBalanceRetraining: prevData ? prevData.programRecommendationBalanceRetraining : false,
      programRecommendationFlexibilityProgram: prevData ? prevData.programRecommendationFlexibilityProgram : false,
      programRecommendationManualHandlingTraining: prevData ? prevData.programRecommendationManualHandlingTraining : false,
      programRecommendationShoulderStabilityProgram: prevData ? prevData.programRecommendationShoulderStabilityProgram : false,
      programRecommendationTrunkStabilityProgram: prevData ? prevData.programRecommendationTrunkStabilityProgram : false,
      programRecommendationTrunkStrenghthening: prevData ? prevData.programRecommendationTrunkStrenghthening : false,
      programRecommendationGeneralStrengthening: prevData ? prevData.programRecommendationGeneralStrengthening : false,
      programRecommendationLowerBodyStrengthening: prevData ? prevData.programRecommendationLowerBodyStrengthening : false,
      programRecommendationUpperBodyStrengthening: prevData ? prevData.programRecommendationUpperBodyStrengthening : false,
      programRecommendationMedicalReview: prevData ? prevData.programRecommendationMedicalReview : false,
      programRecommendationPhysiotherapyReview: prevData ? prevData.programRecommendationPhysiotherapyReview : false,
      programRecommendationOther: prevData ? prevData.programRecommendationOther : false,
      discomfortComment: prevData ? prevData.discomfortComment : "",
      discomfortCommentsOther: prevData ? prevData.discomfortCommentsOther : "",
      programRecommendationNotes: prevData ? prevData.programRecommendationNotes : "",
      programRecommendationAerobicConditioningNote: prevData ? prevData.programRecommendationAerobicConditioningNote : "",
      programRecommendationBalanceRetrainingNote: prevData ? prevData.programRecommendationBalanceRetrainingNote : "",
      programRecommendationFlexibilityProgramNote: prevData ? prevData.programRecommendationFlexibilityProgramNote : "",
      programRecommendationManualHandlingTrainingNote: prevData ? prevData.programRecommendationManualHandlingTrainingNote : "",
      programRecommendationShoulderStabilityProgramNote: prevData ? prevData.programRecommendationShoulderStabilityProgramNote : "",
      programRecommendationTrunkStabilityProgramNote: prevData ? prevData.programRecommendationTrunkStabilityProgramNote : "",
      programRecommendationTrunkStrenghtheningNote: prevData ? prevData.programRecommendationTrunkStrenghtheningNote : "",
      programRecommendationGeneralStrengtheningNote: prevData ? prevData.programRecommendationGeneralStrengtheningNote : "",
      programRecommendationLowerBodyStrengtheningNote: prevData ? prevData.programRecommendationLowerBodyStrengtheningNote : "",
      programRecommendationUpperBodyStrengtheningNote: prevData ? prevData.programRecommendationUpperBodyStrengtheningNote : "",
      programRecommendationMedicalReviewNote: prevData ? prevData.programRecommendationMedicalReviewNote : "",
      programRecommendationPhysiotherapyReviewNote: prevData ? prevData.programRecommendationPhysiotherapyReviewNote : "",
      programRecommendationOtherNote: prevData ? prevData.programRecommendationOtherNote : "",
      gaitChangesNoneChecked: prevData ? prevData.gaitChangesNoneChecked : false,
      gaitChangesObservedChecked: prevData ? prevData.gaitChangesObservedChecked : false,
      gaitChangesObservedNote: prevData ? prevData.gaitChangesObservedNote : "",
      inflammationChangesNoneChecked: prevData ? prevData.inflammationChangesNoneChecked : false,
      inflammationChangesObservedChecked: prevData ? prevData.inflammationChangesObservedChecked : false,
      inflammationChangesObservedNote: prevData ? prevData.inflammationChangesObservedNote : "",
      otherChangesNoneChecked: prevData ? prevData.otherChangesNoneChecked : false,
      otherChangesObservedChecked: prevData ? prevData.otherChangesObservedChecked : false,
      otherChangesObservedNote: prevData ? prevData.otherChangesObservedNote : "",
      ppeAnticipatedConcernsNoneChecked: prevData ? prevData.ppeAnticipatedConcernsNoneChecked : false,
      ppeAnticipatedConcernsObservedChecked: prevData ? prevData.ppeAnticipatedConcernsObservedChecked : false,
      ppeAnticipatedConcernsNote: prevData ? prevData.ppeAnticipatedConcernsNote : "",
      participantDeniedDiscomfortChecked: prevData ? prevData.participantDeniedDiscomfortChecked : false,
      participantReportedDiscomfortChecked: prevData ? prevData.participantReportedDiscomfortChecked : false,
    });

    const handleCheckboxChange = (event: any): void => {
      event.persist();
      setState((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.checked,
      }));
    };

    const handleValueChange = (event: any): void => {
      event.persist();
      setState((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
      }));
    };

    const handleGaitChange = (event: any): void => {
      event.persist();

      setState((prevState) => ({
        ...prevState,
        gaitChangesNoneChecked: false,
        gaitChangesObservedChecked: false,
        [event.target.name]: event.target.checked,
      }));
    };

    const handleInflammationChange = (event: any): void => {
      event.persist();

      setState((prevState) => ({
        ...prevState,
        inflammationChangesNoneChecked: false,
        inflammationChangesObservedChecked: false,
        [event.target.name]: event.target.checked,
      }));
    };

    const handleOtherChange = (event: any): void => {
      event.persist();

      setState((prevState) => ({
        ...prevState,
        otherChangesNoneChecked: false,
        otherChangesObservedChecked: false,
        [event.target.name]: event.target.checked,
      }));
    };

    const handlePpeChange = (event: any): void => {
      event.persist();

      setState((prevState) => ({
        ...prevState,
        ppeAnticipatedConcernsNoneChecked: false,
        ppeAnticipatedConcernsObservedChecked: false,
        [event.target.name]: event.target.checked,
      }));
    };

    const handlePostPefaDiscomfortChange = (event: any): void => {
      event.persist();

      setState((prevState) => ({
        ...prevState,
        participantDeniedDiscomfortChecked: false,
        participantReportedDiscomfortChecked: false,
        [event.target.name]: event.target.checked,
      }));
    };

    useEffect(() => {
      const timeout = setTimeout(() => {
        props.rootStore?.dataStore.savePartialData(state, pefaAssessment.id, dataStorePageKeys.PROGRAM_RECOMMENDATIONS);
      }, 500);

      return () => clearTimeout(timeout);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      state.programRecommendationAerobicConditioning,
      state.programRecommendationBalanceRetraining,
      state.programRecommendationFlexibilityProgram,
      state.programRecommendationManualHandlingTraining,
      state.programRecommendationShoulderStabilityProgram,
      state.programRecommendationTrunkStabilityProgram,
      state.programRecommendationTrunkStrenghthening,
      state.programRecommendationGeneralStrengthening,
      state.programRecommendationLowerBodyStrengthening,
      state.programRecommendationUpperBodyStrengthening,
      state.programRecommendationMedicalReview,
      state.programRecommendationPhysiotherapyReview,
      state.programRecommendationOther,
      state.discomfortComment,
      state.discomfortCommentsOther,
      state.programRecommendationNotes,
      state.programRecommendationAerobicConditioningNote,
      state.programRecommendationBalanceRetrainingNote,
      state.programRecommendationFlexibilityProgramNote,
      state.programRecommendationManualHandlingTrainingNote,
      state.programRecommendationShoulderStabilityProgramNote,
      state.programRecommendationTrunkStabilityProgramNote,
      state.programRecommendationTrunkStrenghtheningNote,
      state.programRecommendationGeneralStrengtheningNote,
      state.programRecommendationLowerBodyStrengtheningNote,
      state.programRecommendationUpperBodyStrengtheningNote,
      state.programRecommendationMedicalReviewNote,
      state.programRecommendationPhysiotherapyReviewNote,
      state.programRecommendationOtherNote,
      state.gaitChangesNoneChecked,
      state.gaitChangesObservedChecked,
      state.gaitChangesObservedNote,
      state.inflammationChangesNoneChecked,
      state.inflammationChangesObservedChecked,
      state.inflammationChangesObservedNote,
      state.otherChangesNoneChecked,
      state.otherChangesObservedChecked,
      state.otherChangesObservedNote,
      state.ppeAnticipatedConcernsNoneChecked,
      state.ppeAnticipatedConcernsObservedChecked,
      state.ppeAnticipatedConcernsNote,
      state.participantDeniedDiscomfortChecked,
      state.participantReportedDiscomfortChecked,
    ]);

    useEffect(() => {
      //Replicate the onUnmount event handler from class components.
      return () => {
        appInsights.trackEvent({ name: "intermediateSubmission", properties: { bookingId: pefaAssessment.id, dataKey: dataStorePageKeys.PROGRAM_RECOMMENDATIONS, data: state } });
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submitForm = (data: any, forwardTarget: any) => {
      props.rootStore?.dataStore.saveData(data, pefaAssessment.id, dataStorePageKeys.PROGRAM_RECOMMENDATIONS);
      const pefaData = { ...props.rootStore?.dataStore.pefaData[pefaAssessment.id] };
      // take snapshot of assessment and store against booking
      props.rootStore?.pefaStore.nextSubmit(pefaData, pefaAssessment.id);
      history.push(forwardTarget);
    };

    return (
      <div className="FormStyler">
        <div className="AssessmentDetails">
          <Grid item container direction="column" xs={12}>
            <Stepper targetStep={"Preview & Recomendations"} />
            <Container maxWidth={"lg"}>
              <Grid item container xs={12} direction="row">
                <Grid item container xs={9}>
                  <form>
                    <Card className="uCard" variant="outlined">
                      <Typography variant="h2" className="heroHeading">
                        <img src={Success} alt="Success" /> PEFA Score &amp; Recommendations
                      </Typography>
                    </Card>
                    <PerformanceSummary assessmentId={pefaAssessment.id} />
                    <Card className="uCard" variant="outlined">
                      <FormHelperText error>{errors.termsCheck ? errors.termsCheck.message : " "}</FormHelperText>
                      <Typography variant="h2" className="heroHeading">
                        Program Recommendations
                      </Typography>
                      <Typography className="introPefaText" variant="body1">
                        Do you have any program recommendations for{" "}
                        <span>
                          {pefaAssessment.pefArequest.worker.firstName} {pefaAssessment.pefArequest.worker.lastName}.
                        </span>
                      </Typography>
                      <FormControl component="fieldset" style={{ width: "100%" }}>
                        <FormGroup className="formGroupSmMargin">
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={<span className="checkboxIcon"></span>}
                                checkedIcon={<span className="checkboxIconChecked"></span>}
                                checked={state.programRecommendationAerobicConditioning}
                                onChange={handleCheckboxChange}
                                color="primary"
                                inputRef={register}
                                name="programRecommendationAerobicConditioning"
                              />
                            }
                            label="Aerobic Conditioning"
                          />
                          {state.programRecommendationAerobicConditioning ? (
                            <OutlinedInput
                              color="primary"
                              placeholder="Describe here"
                              name="programRecommendationAerobicConditioningNote"
                              className="textInputFull"
                              autoComplete="off"
                              inputRef={register}
                              onChange={handleValueChange}
                              value={state.programRecommendationAerobicConditioningNote}
                            />
                          ) : (
                            <></>
                          )}
                        </FormGroup>
                        <FormGroup className="formGroupSmMargin">
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={<span className="checkboxIcon"></span>}
                                checkedIcon={<span className="checkboxIconChecked"></span>}
                                checked={state.programRecommendationBalanceRetraining}
                                onChange={handleCheckboxChange}
                                color="primary"
                                inputRef={register}
                                name="programRecommendationBalanceRetraining"
                              />
                            }
                            label="Balance retraining"
                          />
                          {state.programRecommendationBalanceRetraining ? (
                            <OutlinedInput
                              color="primary"
                              placeholder="Describe here"
                              name="programRecommendationBalanceRetrainingNote"
                              className="textInputFull"
                              autoComplete="off"
                              inputRef={register}
                              onChange={handleValueChange}
                              value={state.programRecommendationBalanceRetrainingNote}
                            />
                          ) : (
                            <></>
                          )}
                        </FormGroup>
                        <FormGroup className="formGroupSmMargin">
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={<span className="checkboxIcon"></span>}
                                checkedIcon={<span className="checkboxIconChecked"></span>}
                                checked={state.programRecommendationFlexibilityProgram}
                                onChange={handleCheckboxChange}
                                color="primary"
                                inputRef={register}
                                name="programRecommendationFlexibilityProgram"
                              />
                            }
                            label="Flexibility program"
                          />
                          {state.programRecommendationFlexibilityProgram ? (
                            <OutlinedInput
                              color="primary"
                              placeholder="Describe here"
                              name="programRecommendationFlexibilityProgramNote"
                              className="textInputFull"
                              autoComplete="off"
                              inputRef={register}
                              onChange={handleValueChange}
                              value={state.programRecommendationFlexibilityProgramNote}
                            />
                          ) : (
                            <></>
                          )}
                        </FormGroup>
                        <FormGroup className="formGroupSmMargin">
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={<span className="checkboxIcon"></span>}
                                checkedIcon={<span className="checkboxIconChecked"></span>}
                                checked={state.programRecommendationManualHandlingTraining}
                                onChange={handleCheckboxChange}
                                color="primary"
                                inputRef={register}
                                name="programRecommendationManualHandlingTraining"
                              />
                            }
                            label="Manual handling training"
                          />
                          {state.programRecommendationManualHandlingTraining ? (
                            <OutlinedInput
                              color="primary"
                              placeholder="Describe here"
                              name="programRecommendationManualHandlingTrainingNote"
                              className="textInputFull"
                              autoComplete="off"
                              inputRef={register}
                              onChange={handleValueChange}
                              value={state.programRecommendationManualHandlingTrainingNote}
                            />
                          ) : (
                            <></>
                          )}
                        </FormGroup>
                        <FormGroup className="formGroupSmMargin">
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={<span className="checkboxIcon"></span>}
                                checkedIcon={<span className="checkboxIconChecked"></span>}
                                checked={state.programRecommendationShoulderStabilityProgram}
                                onChange={handleCheckboxChange}
                                color="primary"
                                inputRef={register}
                                name="programRecommendationShoulderStabilityProgram"
                              />
                            }
                            label="Shoulder stability program"
                          />
                          {state.programRecommendationShoulderStabilityProgram ? (
                            <OutlinedInput
                              color="primary"
                              placeholder="Describe here"
                              name="programRecommendationShoulderStabilityProgramNote"
                              className="textInputFull"
                              autoComplete="off"
                              inputRef={register}
                              onChange={handleValueChange}
                              value={state.programRecommendationShoulderStabilityProgramNote}
                            />
                          ) : (
                            <></>
                          )}
                        </FormGroup>
                        <FormGroup className="formGroupSmMargin">
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={<span className="checkboxIcon"></span>}
                                checkedIcon={<span className="checkboxIconChecked"></span>}
                                checked={state.programRecommendationTrunkStabilityProgram}
                                onChange={handleCheckboxChange}
                                color="primary"
                                inputRef={register}
                                name="programRecommendationTrunkStabilityProgram"
                              />
                            }
                            label="Trunk stability program"
                          />
                          {state.programRecommendationTrunkStabilityProgram ? (
                            <OutlinedInput
                              color="primary"
                              placeholder="Describe here"
                              name="programRecommendationTrunkStabilityProgramNote"
                              className="textInputFull"
                              autoComplete="off"
                              inputRef={register}
                              onChange={handleValueChange}
                              value={state.programRecommendationTrunkStabilityProgramNote}
                            />
                          ) : (
                            <></>
                          )}
                        </FormGroup>
                        <FormGroup className="formGroupSmMargin">
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={<span className="checkboxIcon"></span>}
                                checkedIcon={<span className="checkboxIconChecked"></span>}
                                checked={state.programRecommendationTrunkStrenghthening}
                                onChange={handleCheckboxChange}
                                color="primary"
                                inputRef={register}
                                name="programRecommendationTrunkStrenghthening"
                              />
                            }
                            label="Trunk strenghthening"
                          />
                          {state.programRecommendationTrunkStrenghthening ? (
                            <OutlinedInput
                              color="primary"
                              placeholder="Describe here"
                              name="programRecommendationTrunkStrenghtheningNote"
                              className="textInputFull"
                              autoComplete="off"
                              inputRef={register}
                              onChange={handleValueChange}
                              value={state.programRecommendationTrunkStrenghtheningNote}
                            />
                          ) : (
                            <></>
                          )}
                        </FormGroup>
                        <FormGroup className="formGroupSmMargin">
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={<span className="checkboxIcon"></span>}
                                checkedIcon={<span className="checkboxIconChecked"></span>}
                                checked={state.programRecommendationGeneralStrengthening}
                                onChange={handleCheckboxChange}
                                color="primary"
                                inputRef={register}
                                name="programRecommendationGeneralStrengthening"
                              />
                            }
                            label="General strengthening"
                          />
                          {state.programRecommendationGeneralStrengthening ? (
                            <OutlinedInput
                              color="primary"
                              placeholder="Describe here"
                              name="programRecommendationGeneralStrengtheningNote"
                              className="textInputFull"
                              autoComplete="off"
                              inputRef={register}
                              onChange={handleValueChange}
                              value={state.programRecommendationGeneralStrengtheningNote}
                            />
                          ) : (
                            <></>
                          )}
                        </FormGroup>
                        <FormGroup className="formGroupSmMargin">
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={<span className="checkboxIcon"></span>}
                                checkedIcon={<span className="checkboxIconChecked"></span>}
                                checked={state.programRecommendationLowerBodyStrengthening}
                                onChange={handleCheckboxChange}
                                color="primary"
                                inputRef={register}
                                name="programRecommendationLowerBodyStrengthening"
                              />
                            }
                            label="Lower Body Strengthening"
                          />
                          {state.programRecommendationLowerBodyStrengthening ? (
                            <OutlinedInput
                              color="primary"
                              placeholder="Describe here"
                              name="programRecommendationLowerBodyStrengtheningNote"
                              className="textInputFull"
                              autoComplete="off"
                              inputRef={register}
                              onChange={handleValueChange}
                              value={state.programRecommendationLowerBodyStrengtheningNote}
                            />
                          ) : (
                            <></>
                          )}
                        </FormGroup>
                        <FormGroup className="formGroupSmMargin">
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={<span className="checkboxIcon"></span>}
                                checkedIcon={<span className="checkboxIconChecked"></span>}
                                checked={state.programRecommendationUpperBodyStrengthening}
                                onChange={handleCheckboxChange}
                                color="primary"
                                inputRef={register}
                                name="programRecommendationUpperBodyStrengthening"
                              />
                            }
                            label="Upper body strengthening"
                          />
                          {state.programRecommendationUpperBodyStrengthening ? (
                            <OutlinedInput
                              color="primary"
                              placeholder="Describe here"
                              name="programRecommendationUpperBodyStrengtheningNote"
                              className="textInputFull"
                              autoComplete="off"
                              inputRef={register}
                              onChange={handleValueChange}
                              value={state.programRecommendationUpperBodyStrengtheningNote}
                            />
                          ) : (
                            <></>
                          )}
                        </FormGroup>
                        <FormGroup className="formGroupSmMargin">
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={<span className="checkboxIcon"></span>}
                                checkedIcon={<span className="checkboxIconChecked"></span>}
                                checked={state.programRecommendationMedicalReview}
                                onChange={handleCheckboxChange}
                                color="primary"
                                inputRef={register}
                                name="programRecommendationMedicalReview"
                              />
                            }
                            label="Medical review"
                          />
                          {state.programRecommendationMedicalReview ? (
                            <OutlinedInput
                              color="primary"
                              placeholder="Describe here"
                              name="programRecommendationMedicalReviewNote"
                              className="textInputFull"
                              autoComplete="off"
                              inputRef={register}
                              onChange={handleValueChange}
                              value={state.programRecommendationMedicalReviewNote}
                            />
                          ) : (
                            <></>
                          )}
                        </FormGroup>
                        <FormGroup className="formGroupSmMargin">
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={<span className="checkboxIcon"></span>}
                                checkedIcon={<span className="checkboxIconChecked"></span>}
                                checked={state.programRecommendationPhysiotherapyReview}
                                onChange={handleCheckboxChange}
                                color="primary"
                                inputRef={register}
                                name="programRecommendationPhysiotherapyReview"
                              />
                            }
                            label="Physiotherapy review"
                          />
                          {state.programRecommendationPhysiotherapyReview ? (
                            <OutlinedInput
                              color="primary"
                              placeholder="Describe here"
                              name="programRecommendationPhysiotherapyReviewNote"
                              className="textInputFull"
                              autoComplete="off"
                              inputRef={register}
                              onChange={handleValueChange}
                              value={state.programRecommendationPhysiotherapyReviewNote}
                            />
                          ) : (
                            <></>
                          )}
                        </FormGroup>
                        <FormGroup className="formGroupSmMargin">
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={<span className="checkboxIcon"></span>}
                                checkedIcon={<span className="checkboxIconChecked"></span>}
                                checked={state.programRecommendationOther}
                                onChange={handleCheckboxChange}
                                color="primary"
                                inputRef={register}
                                name="programRecommendationOther"
                              />
                            }
                            label="Other"
                          />
                          {state.programRecommendationOther ? (
                            <OutlinedInput
                              color="primary"
                              placeholder="Describe here"
                              name="programRecommendationOtherNote"
                              className="textInputFull"
                              autoComplete="off"
                              inputRef={register}
                              onChange={handleValueChange}
                              value={state.programRecommendationOtherNote}
                            />
                          ) : (
                            <></>
                          )}
                        </FormGroup>
                      </FormControl>
                    </Card>
                    <Card className="uCard" variant="outlined">
                      <Typography variant="h2" className="heroHeading">
                        Other Comments
                      </Typography>
                      <Typography className="introPefaText" variant="body1">
                        Please advise the following:
                      </Typography>
                      <StyledAccordion variant="outlined" className="innerOutlinedBox">
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography variant="body1">Observational Changes</Typography>
                          {errors.gait?.message ||
                          errors.gaitHasChanged?.message ||
                          errors.inflammation?.message ||
                          errors.inflammationHasChanged?.message ||
                          errors.other?.message ||
                          errors.otherHasChanged?.message ? (
                            <ErrorOutline className="invalid-feedback" />
                          ) : (
                            <></>
                          )}
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="flexer">
                            <FormGroup>
                              <FormLabel component="legend">Gait</FormLabel>
                              <div className="flexer">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="gaitChangesNoneChecked"
                                      inputRef={register}
                                      checked={state.gaitChangesNoneChecked}
                                      icon={<span className="checkboxIcon"></span>}
                                      checkedIcon={<span className="checkboxIconChecked"></span>}
                                      color="primary"
                                      onChange={(event) => handleGaitChange(event)}
                                    />
                                  }
                                  label="No changes in gait pattern observed immediately after the PEFA"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="gaitChangesObservedChecked"
                                      inputRef={register}
                                      checked={state.gaitChangesObservedChecked}
                                      icon={<span className="checkboxIcon"></span>}
                                      checkedIcon={<span className="checkboxIconChecked"></span>}
                                      color="primary"
                                      onChange={(event) => handleGaitChange(event)}
                                    />
                                  }
                                  label="Changes in gait pattern observed post PEFA (describe)"
                                />
                              </div>
                              {state.gaitChangesObservedChecked ? (
                                <OutlinedInput
                                  color="primary"
                                  placeholder="Describe here"
                                  name="gaitChangesObservedNote"
                                  inputRef={register}
                                  value={state.gaitChangesObservedNote}
                                  className="textInputFull"
                                  autoComplete="off"
                                  onChange={handleValueChange}
                                />
                              ) : (
                                <></>
                              )}
                              <div style={{ marginLeft: "0px" }} className="invalidFeedback">
                                {errors.gait?.message}
                                {errors.gaitHasChanged?.message} <br />
                              </div>
                            </FormGroup>
                          </div>
                          <div className="flexer">
                            <FormGroup>
                              <FormLabel component="legend">Inflammation</FormLabel>
                              <div className="flexer">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="inflammationChangesNoneChecked"
                                      inputRef={register}
                                      checked={state.inflammationChangesNoneChecked}
                                      icon={<span className="checkboxIcon"></span>}
                                      checkedIcon={<span className="checkboxIconChecked"></span>}
                                      color="primary"
                                      onChange={(event) => handleInflammationChange(event)}
                                    />
                                  }
                                  label="No visible signs of inflammation immediately after the PEFA"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="inflammationChangesObservedChecked"
                                      inputRef={register}
                                      checked={state.inflammationChangesObservedChecked}
                                      icon={<span className="checkboxIcon"></span>}
                                      checkedIcon={<span className="checkboxIconChecked"></span>}
                                      color="primary"
                                      onChange={(event) => handleInflammationChange(event)}
                                    />
                                  }
                                  label="Signs of possible inflammation observed post PEFA (describe)"
                                />
                              </div>
                              {state.inflammationChangesObservedChecked ? (
                                <OutlinedInput
                                  color="primary"
                                  placeholder="Describe here"
                                  name="inflammationChangesObservedNote"
                                  inputRef={register}
                                  value={state.inflammationChangesObservedNote}
                                  className="textInputFull"
                                  autoComplete="off"
                                  onChange={handleValueChange}
                                />
                              ) : (
                                <></>
                              )}
                              <div style={{ marginLeft: "0px" }} className="invalidFeedback">
                                {errors.inflammation?.message}
                                {errors.inflammationHasChanged?.message} <br />
                              </div>
                            </FormGroup>
                          </div>
                          <div className="flexer">
                            <FormGroup>
                              <FormLabel component="legend">Other</FormLabel>
                              <div className="flexer">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="otherChangesNoneChecked"
                                      inputRef={register}
                                      checked={state.otherChangesNoneChecked}
                                      icon={<span className="checkboxIcon"></span>}
                                      checkedIcon={<span className="checkboxIconChecked"></span>}
                                      color="primary"
                                      onChange={(event) => handleOtherChange(event)}
                                    />
                                  }
                                  label="No other visible signs of discomfort immediately after the PEFA"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="otherChangesObservedChecked"
                                      inputRef={register}
                                      checked={state.otherChangesObservedChecked}
                                      icon={<span className="checkboxIcon"></span>}
                                      checkedIcon={<span className="checkboxIconChecked"></span>}
                                      color="primary"
                                      onChange={(event) => handleOtherChange(event)}
                                    />
                                  }
                                  label="Assessor noted visible signs of discomfort immediately after the PEFA (describe)"
                                />
                              </div>
                              {state.otherChangesObservedChecked ? (
                                <OutlinedInput
                                  color="primary"
                                  placeholder="Describe here"
                                  name="otherChangesObservedNote"
                                  inputRef={register}
                                  value={state.otherChangesObservedNote}
                                  className="textInputFull"
                                  autoComplete="off"
                                  onChange={handleValueChange}
                                />
                              ) : (
                                <></>
                              )}
                              <div style={{ marginLeft: "0px" }} className="invalidFeedback">
                                {errors.other?.message}
                                {errors.otherHasChanged?.message} <br />
                              </div>
                            </FormGroup>
                          </div>
                        </AccordionDetails>
                      </StyledAccordion>
                      <StyledAccordion variant="outlined" className="innerOutlinedBox">
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography variant="body1">Wearing PPE</Typography>
                          {errors.ppe?.message || errors.ppeHasChanged?.message ? <ErrorOutline className="invalid-feedback" /> : <></>}
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="flexer">
                            <FormGroup>
                              <div className="flexer">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="ppeAnticipatedConcernsNoneChecked"
                                      inputRef={register}
                                      checked={state.ppeAnticipatedConcernsNoneChecked}
                                      icon={<span className="checkboxIcon"></span>}
                                      checkedIcon={<span className="checkboxIconChecked"></span>}
                                      color="primary"
                                      onChange={(event) => handlePpeChange(event)}
                                    />
                                  }
                                  label="In the assessor's opinion, there are no anticipated concerns with donning, doffing or wearing PPE"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="ppeAnticipatedConcernsObservedChecked"
                                      inputRef={register}
                                      checked={state.ppeAnticipatedConcernsObservedChecked}
                                      icon={<span className="checkboxIcon"></span>}
                                      checkedIcon={<span className="checkboxIconChecked"></span>}
                                      color="primary"
                                      onChange={(event) => handlePpeChange(event)}
                                    />
                                  }
                                  label="Assessor has concerns with donning, doffing or wearing PPE (describe)"
                                />
                              </div>
                              {state.ppeAnticipatedConcernsObservedChecked ? (
                                <OutlinedInput
                                  color="primary"
                                  placeholder="Describe here"
                                  name="ppeAnticipatedConcernsNote"
                                  inputRef={register}
                                  value={state.ppeAnticipatedConcernsNote}
                                  className="textInputFull"
                                  autoComplete="off"
                                  onChange={handleValueChange}
                                />
                              ) : (
                                <></>
                              )}
                              <div style={{ marginLeft: "0px" }} className="invalidFeedback">
                                {errors.ppe?.message}
                                {errors.ppeHasChanged?.message} <br />
                              </div>
                            </FormGroup>
                          </div>
                        </AccordionDetails>
                      </StyledAccordion>
                      <StyledAccordion variant="outlined" className="innerOutlinedBox">
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography variant="body1">Post PEFA Discomfort</Typography>
                          {errors.postDiscomfort?.message ? <ErrorOutline className="invalid-feedback" /> : <></>}
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="flexer">
                            <FormGroup>
                              <div className="flexer">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="participantDeniedDiscomfortChecked"
                                      inputRef={register}
                                      checked={state.participantDeniedDiscomfortChecked}
                                      icon={<span className="checkboxIcon"></span>}
                                      checkedIcon={<span className="checkboxIconChecked"></span>}
                                      color="primary"
                                      onChange={(event) => handlePostPefaDiscomfortChange(event)}
                                    />
                                  }
                                  label="Participant denied any discomfort immediately after the PEFA"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="participantReportedDiscomfortChecked"
                                      inputRef={register}
                                      checked={state.participantReportedDiscomfortChecked}
                                      icon={<span className="checkboxIcon"></span>}
                                      checkedIcon={<span className="checkboxIconChecked"></span>}
                                      color="primary"
                                      onChange={(event) => handlePostPefaDiscomfortChange(event)}
                                    />
                                  }
                                  label="Participant reported discomfort at the end of the PEFA"
                                />
                              </div>
                              {state.participantReportedDiscomfortChecked ? (
                                <ParticipantReports
                                  datasetName="programRecommendations"
                                  register={register}
                                  data={prevData}
                                  errors={errors}
                                  setValue={setValue}
                                  assessmentID={pefaAssessment.id}
                                  dataKey={dataStorePageKeys.PROGRAM_RECOMMENDATIONS}
                                  showParticipantsReport={false}
                                />
                              ) : (
                                <></>
                              )}
                              <div style={{ marginLeft: "0px" }} className="invalidFeedback">
                                {errors.postDiscomfort?.message}
                                <br />
                              </div>
                            </FormGroup>
                          </div>
                        </AccordionDetails>
                      </StyledAccordion>
                    </Card>
                    <Card className="uCard" variant="outlined">
                      <Typography variant="h2" className="heroHeading">
                        Discomfort Comments
                      </Typography>
                      <Typography className="introPefaText" variant="body1">
                        Suggested comments:
                      </Typography>
                      <RadioGroup aria-label="hand-dominance" name="discomfortComment" className="flexer" onChange={handleValueChange} value={state.discomfortComment} row>
                        <FormControlLabel
                          control={
                            <Radio
                              color="primary"
                              name="discomfortComment"
                              value="No abnormal discomfort was reported by the participant or noted by the assessor before, during or immediately after the PEFA."
                            />
                          }
                          inputRef={register}
                          label="No abnormal discomfort was reported by the participant or noted by the assessor before, during or immediately after the PEFA."
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              color="primary"
                              name="discomfortComment"
                              value="Other than that reported above, no abnormal discomfort was reported by the participant or noted by the assessor before, during or immediately after the PEFA."
                            />
                          }
                          inputRef={register}
                          label="Other than that reported elsewhere, no abnormal discomfort was reported by the participant or noted by the assessor before, during or immediately after the PEFA."
                        />
                      </RadioGroup>
                      <div className="invalid-feedback">{errors.discomfortComment?.message}</div>
                    </Card>
                  </form>
                </Grid>
                <Grid item container xs={3} className="RightCol">
                  <ProfileCard
                    firstName={pefaAssessment.pefArequest.worker.firstName}
                    lastName={pefaAssessment.pefArequest.worker.lastName}
                    participantAgeYears={participantAgeYears}
                  />
                  <NotesModal pageKey={dataStorePageKeys.PROGRAM_RECOMMENDATIONS} pefaID={pefaAssessment.id} />
                  <InTestMedicalClearanceModal
                    firstName={referralDetails.firstName}
                    lastName={referralDetails.lastName}
                    pageKey={dataStorePageKeys.PROGRAM_RECOMMENDATIONS}
                    pefaID={pefaAssessment.id}
                    medicalPractice={medicalHistoryData.medicalPractitioner}
                    medicalPractitioner={medicalHistoryData.medicalPractice}
                  />
                </Grid>
              </Grid>
            </Container>
            <FormNavigator forwardTarget={PEFAREVIEW} handleSubmit={handleSubmit(submitForm)} showEndButton={false} selectedKey="" />
          </Grid>
        </div>
      </div>
    );
  })
);

export default ProgramRecommendations;

import { Button, FormControlLabel, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import React, { ChangeEvent, useEffect, useState } from "react";
import { EditIconBlue } from "../../assets/images";
import { Root } from "../../stores";
import { dataStorePageKeys } from "../../stores/dataStore";

interface ManualHandlingTestProps {
  rootStore?: Root;
  index: number;
  lastAttemptIndex: number;
  demandsTarget: number;
  recoveryModalOpen: boolean;
  isBreakTaken: boolean;
  isLift: boolean;

  maxStartWeight?: number;
  testWeight?: number;

  breakTimeFormatted?: string;

  stoppedReason?: string;
  stoppedMessage?: string;
  stoppedEditHandler?: any;

  testHandler?: any;

  handleHeartRateChange?: any;

  data?: any;
  register?: any;
  errors?: any;
  setValue?: any;

  assessmentID?: string;
  dataKey?: dataStorePageKeys;
}

export const ManualHandlingDecisions: {
  [key: string]: { key: string; value: string };
} = {
  MET_DEMANDS: { key: "MET_DEMANDS", value: "Met demands" },
  SAFE_TO_CONTINUE: { key: "SAFE_TO_CONTINUE", value: "Safe to continue" },
  REST_TO_LOWER_HR: { key: "REST_TO_LOWER_HR", value: "Rest to lower HR" },
  PARTICIPANT_STOPPED: {
    key: "PARTICIPANT_STOPPED",
    value: "Participant stopped",
  },
  ASSESSOR_STOPPED: { key: "ASSESSOR_STOPPED", value: "Assessor stopped" },
};

const ManualHandlingTest = inject("rootStore")(
  observer((props: ManualHandlingTestProps) => {
    //Apparently adding to a property number type is not possible, even if you dump it into a variable first? It just appends to the end of the value like a string.
    //e.g. instead of 39 + 2 = 41, it was giving 392.
    //So don't touch the Number cast, it looks stupid because it is!
    let demandsTarget = Number(props.demandsTarget);
    demandsTarget += 2;

    const [state, setState] = useState({
      safeWeightLimit: demandsTarget,
      weightExceedsStartLimit: false,
      weightExceedsSafeLimit: false,
      weightExceedsIncrementLimit: false,
      weightNotValid: false,

      weight: props.data && !!props.data[`weight_${props.index}`] ? props.data[`weight_${props.index}`] : props.testWeight ? props.testWeight.toString() : -1,

      heartRate: props.data ? props.data[`bpm_${props.index}`] : 0,
      safeHandlingResponse: props.data ? (!!props.data[`safeRepeats_${props.index}`] ? props.data[`safeRepeats_${props.index}`] : "SELECT") : "SELECT",
      decisionResponse: props.data ? (!!props.data[`decision_${props.index}`] ? props.data[`decision_${props.index}`] : "SELECT") : "SELECT",
      comment: props.data ? props.data[`comment_${props.index}`] : "",

      decisionOptions: [] as string[],
      decisionFinalised: false,
      decisionSelectDisabled: true,
    });

    const populateDecisionOptions = (): void => {
      const manualHandlingDecisionKeys = Object.keys(ManualHandlingDecisions);
      const availableDecisions: any[] = [];

      availableDecisions.push(
        <MenuItem value="SELECT" selected={true}>
          Options
        </MenuItem>
      );

      let addDecision: boolean;
      manualHandlingDecisionKeys.forEach((decisionKey) => {
        addDecision = true;

        //If the participant has already taken a break for this test, do not show the option to take a break.
        if (props.isBreakTaken && props.index === props.lastAttemptIndex && decisionKey === ManualHandlingDecisions.REST_TO_LOWER_HR.key) {
          addDecision = false;
        }

        if (Number(state.weight) < props.demandsTarget && decisionKey === ManualHandlingDecisions.MET_DEMANDS.key) {
          addDecision = false;
        }

        //If the weight target has been met, safe to continue should no longer be an option.
        //NOTE: We are doing a blanket greater or equal here. A user cannot select met demands
        //      when the weight is greater than 2kg over. Validation will not allow it. However
        //      under these circumstances, the select control should already be disabled.

        //We are also only interested in changing the dropdown options of the current trial not
        //previous trials. Hence the comparison between the current and final index.
        if (props.index === props.lastAttemptIndex && Number(state.weight) >= props.demandsTarget && decisionKey === ManualHandlingDecisions.SAFE_TO_CONTINUE.key) {
          addDecision = false;
        }

        if (addDecision) {
          availableDecisions.push(<MenuItem value={decisionKey}>{ManualHandlingDecisions[decisionKey].value}</MenuItem>);
        }
      });

      setState((prevState) => ({
        ...prevState,
        decisionOptions: availableDecisions,
      }));
    };

    const handleWeightChange = (weight: string): void => {
      if (!isNaN(Number(weight))) {
        //if the weight is greater than safe weight limit (2KG greater than the target), it is unsafe.
        const weightExceedsSafeLimit = Number(weight) > state.safeWeightLimit && props.index > 1;

        //if the weight is greater than the provided maximum starting value on the first attempt, it is unsafe.
        let weightExceedsStartLimit = false;
        if (props.index === 1) {
          weightExceedsStartLimit = props.maxStartWeight ? Number(weight) > props.maxStartWeight : false;
        }

        //if we have a valid previous test weight, confirm we don't increase the weight by more than 10kg at a time.
        let weightExceedsIncrementLimit = false;
        if (props.testWeight && props.testWeight > 0) {
          weightExceedsIncrementLimit = Number(weight) - props.testWeight > 10;
        }

        let decisionResponse = state.decisionResponse;
        if (decisionResponse === ManualHandlingDecisions.MET_DEMANDS.key && Number(weight) < props.demandsTarget) {
          decisionResponse = "SELECT";
          props.testHandler("SELECT", weight);
        }

        setState((prevState) => ({
          ...prevState,
          weightExceedsSafeLimit,
          weightExceedsStartLimit,
          weightExceedsIncrementLimit,
          weightNotValid: false,
          decisionResponse,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          weightNotValid: true,
        }));
      }
    };

    const handleHeartRateChange = (event: any) => {
      event.persist();

      setState((prevState) => ({
        ...prevState,
        heartRate: event.target.value,
      }));

      if (!!props.handleHeartRateChange) {
        props.handleHeartRateChange(event.target.value);
      }
    };

    const handleCommentChanged = (event: any) => {
      event.persist();

      setState((prevState) => ({
        ...prevState,
        comment: event.target.value,
      }));
    };

    const handleSafeChange = (event: ChangeEvent<any>): void => {
      event.persist();
      setState((prevState) => ({
        ...prevState,
        safeHandlingResponse: event.target.value,
      }));
    };

    const handleTestDecisionChanged = (event: ChangeEvent<any>): void => {
      event.persist();
      const { weight, heartRate } = state;

      if (!!props.testHandler) {
        props.testHandler(event.target.value, weight, heartRate);
      }

      setState((prevState) => ({
        ...prevState,
        decisionResponse: event.target.value,
      }));
    };

    const finaliseDecision = (): void => {
      let decisionSelectDisabled = false;
      let decisionFinalised = state.decisionFinalised;

      decisionSelectDisabled = props.recoveryModalOpen;

      if (!decisionSelectDisabled) {
        //If the weight is less than or equal to 0, disable the select list.
        decisionSelectDisabled = Number(state.weight) <= 0 || !state.weight;
      }

      if (!decisionFinalised) {
        //If the heart rate has not been provided.
        decisionSelectDisabled = state.heartRate <= 0 || !state.heartRate;
      }

      if (!decisionSelectDisabled) {
        //if the safe handling response has not yet been changed, disable the select list.
        decisionSelectDisabled = state.safeHandlingResponse === "SELECT";
      }

      if (!decisionSelectDisabled) {
        decisionSelectDisabled = state.weightExceedsStartLimit || state.weightExceedsSafeLimit || state.weightExceedsIncrementLimit;
      }

      if (props.index !== props.lastAttemptIndex) {
        //Override the disabled state if the attempt is not the most recent.
        //This ensures that a user cannot change a previous attempt's decision.

        //We also want to block changes
        decisionSelectDisabled = true;
        decisionFinalised = true;
      }

      setState((prevState) => ({
        ...prevState,
        decisionSelectDisabled,
        decisionFinalised,
      }));
    };

    useEffect(() => {
      handleWeightChange(state.weight);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //updates the decision options based on the test weight entered.
    useEffect(() => {
      handleWeightChange(state.weight);
      populateDecisionOptions();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.weight]);

    //If the user changes the attempt weight, safe handling response, or the number of attempts changes, trigger the finaliseDecision function.
    useEffect(() => {
      finaliseDecision();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      props.lastAttemptIndex,
      state.weight,
      state.heartRate,
      state.safeHandlingResponse,
      state.weightExceedsStartLimit,
      state.weightExceedsIncrementLimit,
      state.weightExceedsSafeLimit,
      state.decisionResponse,
    ]);

    useEffect(() => {
      const timeout = setTimeout(() => {
        if (!!props.dataKey && !!props.assessmentID) {
          props.rootStore?.dataStore.savePartialData(
            {
              [`weight_${props.index}`]: state.weight,
              [`bpm_${props.index}`]: state.heartRate,
              [`safeRepeats_${props.index}`]: state.safeHandlingResponse,
              [`decision_${props.index}`]: state.decisionResponse,
              [`comment_${props.index}`]: state.comment,
            },
            props.assessmentID,
            props.dataKey
          );
        }
      }, 500);

      return () => clearTimeout(timeout);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.weight, state.heartRate, state.safeHandlingResponse, state.decisionResponse, state.comment]);

    return (
      <div key={props.index} className="singleTrial">
        <div className="trialWrapper">
          <Typography variant="caption" className="trialText">
            {`#${props.index}`}
          </Typography>
          <FormControlLabel
            control={
              <TextField
                placeholder="Weight"
                className="mhInput"
                name={`weight_${props.index}`}
                variant="outlined"
                InputLabelProps={{ shrink: false }}
                defaultValue={Number(state.weight) > 0 ? state.weight : ""}
                onChange={(event) => {
                  event.persist();
                  setState((prevState) => ({
                    ...prevState,
                    weight: event.target.value,
                  }));
                }}
                inputRef={props.register}
                disabled={state.decisionFinalised}
              />
            }
            label="Weight"
            labelPlacement="top"
          />
          <FormControlLabel
            control={
              <TextField
                placeholder="BPM"
                className="mhInput"
                name={`bpm_${props.index}`}
                variant="outlined"
                InputLabelProps={{ shrink: false }}
                disabled={state.weightExceedsSafeLimit || state.weightExceedsStartLimit || state.weightExceedsIncrementLimit || state.weightNotValid || state.decisionFinalised}
                inputRef={props.register}
                value={state.heartRate > 0 ? state.heartRate : ""}
                onChange={handleHeartRateChange}
              />
            }
            label="Heart Rate"
            labelPlacement="top"
          />
          <FormControlLabel
            control={
              <Select
                color="primary"
                value={state.safeHandlingResponse}
                className="mhInput"
                variant="outlined"
                name={`safeRepeats_${props.index}`}
                onChange={handleSafeChange}
                disabled={state.weightExceedsSafeLimit || state.weightExceedsStartLimit || state.weightExceedsIncrementLimit || state.weightNotValid || state.decisionFinalised}
              >
                <MenuItem value="SELECT">Select</MenuItem>
                <MenuItem value="YES">Yes</MenuItem>
                <MenuItem value="NO">No</MenuItem>
              </Select>
            }
            label={props.isLift ? "Safe x3" : "Safe 30m"}
            labelPlacement="top"
          />
          <input type="text" style={{ visibility: "hidden", display: "none" }} name={`safeRepeats_${props.index}`} value={state.safeHandlingResponse} ref={props.register} />
          <FormControlLabel
            control={
              <Select
                color="primary"
                value={state.decisionResponse}
                className="mhLongInput"
                variant="outlined"
                name={`decision_${props.index}`}
                disabled={state.decisionFinalised || state.decisionSelectDisabled}
                readOnly={state.decisionFinalised || state.decisionSelectDisabled}
                onChange={handleTestDecisionChanged}
              >
                {state.decisionOptions}
              </Select>
            }
            label="Decision"
            labelPlacement="top"
          />
          <input type="text" style={{ visibility: "hidden", display: "none" }} name={`decision_${props.index}`} value={state.decisionResponse} ref={props.register} />
        </div>
        {state.weightExceedsSafeLimit ? (
          <div className="invalid-feedback textInputMarginLeft">
            <span>The weight value entered exceeds the target weight by more than 2kg. Please reduce the weight before testing.</span>
          </div>
        ) : (
          <></>
        )}
        {state.weightExceedsStartLimit ? (
          <div className="invalid-feedback textInputMarginLeft">
            <span>
              {`The weight value entered exceeds the maximum starting weight of ${props.maxStartWeight}kg.
              Please reduce the weight before testing.`}
            </span>
          </div>
        ) : (
          <></>
        )}
        {state.weightExceedsIncrementLimit ? (
          <div className="invalid-feedback textInputMarginLeft">
            <span>The weight value entered exceeds the maximum weight increase of 10KG between trials. Please reduce the weight before testing.</span>
          </div>
        ) : (
          <></>
        )}
        {state.weightNotValid ? (
          <div className="invalid-feedback textInputMarginLeft">
            <span>The weight value entered is not a valid number.</span>
          </div>
        ) : (
          <></>
        )}
        <TextField
          placeholder="Comments here"
          className="textInputMarginLeft"
          name={`comment_${props.index}`}
          variant="outlined"
          defaultValue={state.comment}
          InputLabelProps={{ shrink: false }}
          disabled={state.weightExceedsSafeLimit || state.weightExceedsStartLimit || state.weightExceedsIncrementLimit || state.weightNotValid}
          inputRef={props.register}
          onChange={handleCommentChanged}
        />
        {props.breakTimeFormatted && props.isBreakTaken ? <div className="restRecorded">{props.breakTimeFormatted} rest time recorded</div> : <></>}

        {props.stoppedReason &&
        props.stoppedMessage &&
        (state.decisionResponse === ManualHandlingDecisions.PARTICIPANT_STOPPED.key || state.decisionResponse === ManualHandlingDecisions.ASSESSOR_STOPPED.key) ? (
          <div className="restRecorded notesContainer">
            <div className="notesLeft">
              <span>{`${props.stoppedReason} - ${props.stoppedMessage}`}</span>
            </div>
            <div className="notesRight">
              <Button className="roundButton" variant="outlined" onClick={() => props.stoppedEditHandler(state.decisionResponse)}>
                <img src={EditIconBlue} alt="Edit" />
              </Button>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  })
);

export default ManualHandlingTest;

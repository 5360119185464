import React, { ChangeEvent, Component, ReactNode } from "react";
import { Modal, Backdrop, Button, Slide, Typography, FormControlLabel, InputAdornment, OutlinedInput } from "@material-ui/core";

interface ModalProps {
  isOpen: boolean;
  handlePostTestCloseModal: any;
}

interface ModalState {
  postTestHeartRate: string;
}

export default class postTestHeartRateModal extends Component<ModalProps, ModalState> {
  constructor(props: ModalProps) {
    super(props);

    this.state = {
      postTestHeartRate: "",
    };
    this.handlePostTestHRChanged = this.handlePostTestHRChanged.bind(this);
  }

  handlePostTestHRChanged(event: ChangeEvent<any>): void {
    event.persist();
    this.setState((prevState) => ({
      ...prevState,
      postTestHeartRate: event.target.value,
    }));
  }

  render(): ReactNode {
    return (
      <Modal
        aria-labelledby="modal-title"
        open={this.props.isOpen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Slide direction="up" in={this.props.isOpen} mountOnEnter unmountOnExit>
          <div className="modalPop">
            <div className="hbpModalText">
              <Typography variant="h2" className="modalHeader">
                1 min recovery heart rate
              </Typography>
              <Typography variant="body2" className="modalText">
                Please record the 1 min recovery heart rate
              </Typography>
              <div style={{ marginBottom: "15px" }}>
                <FormControlLabel
                  control={
                    <OutlinedInput
                      color="primary"
                      name="reachForwardpostureAsymmetricalNote"
                      className="textInputFull"
                      autoComplete="off"
                      value={this.state.postTestHeartRate}
                      endAdornment={<InputAdornment position="end">bpm</InputAdornment>}
                      onChange={(event) => this.handlePostTestHRChanged(event)}
                    />
                  }
                  label=" Post rest HR"
                  labelPlacement="start"
                  className="inputNoMargin"
                />
              </div>
              <div className="spacer">
                <Button color="primary" variant="contained" fullWidth onClick={() => this.props.handlePostTestCloseModal(this.state.postTestHeartRate)}>
                  Enter result
                </Button>
              </div>
            </div>
          </div>
        </Slide>
      </Modal>
    );
  }
}

import React, { ChangeEvent, ReactNode, useEffect, useState } from "react";
import { Card, Typography, FormControl, FormControlLabel, Checkbox, FormGroup, Grid, OutlinedInput, FormLabel, TextField } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import { Baseline } from "../../assets/images";
import { Root } from "../../stores";
import { dataStorePageKeys } from "../../stores/dataStore";

interface ParticipantReportsProps {
  rootStore?: Root;

  datasetName: string;
  showParticipantsReport?: boolean;

  data?: any;
  register?: any;
  errors?: any;
  setValue?: any;

  handleDiscomfortReported?: any;

  assessmentID?: string;
  dataKey?: dataStorePageKeys;
}

const ParticipantReports = inject("rootStore")(
  observer((props: ParticipantReportsProps) => {
    const { datasetName } = props;
    const showParticipantsReport = props.showParticipantsReport ? props.showParticipantsReport : true;

    const [state, setState] = useState({
      intensityCheckboxesEnabled: true,
      intensityVASEnabled: true,
    });

    const [formData, setFormData] = useState({
      [`${datasetName}_deniedDiscomfort`]: props.data ? props.data[`${datasetName}_deniedDiscomfort`] : false,
      [`${datasetName}_reportedDiscomfort`]: props.data ? props.data[`${datasetName}_reportedDiscomfort`] : false,

      [`${datasetName}_location`]: props.data ? props.data[`${datasetName}_location`] : "",

      [`${datasetName}_intensityVas`]: props.data ? props.data[`${datasetName}_intensityVas`] : "",
      [`${datasetName}_intensityMild`]: props.data ? props.data[`${datasetName}_intensityMild`] : false,
      [`${datasetName}_intensityMod`]: props.data ? props.data[`${datasetName}_intensityMod`] : false,
      [`${datasetName}_intensitySevere`]: props.data ? props.data[`${datasetName}_intensitySevere`] : false,

      [`${datasetName}_discomfortTypeAche`]: props.data ? props.data[`${datasetName}_discomfortTypeAche`] : false,
      [`${datasetName}_discomfortTypeSharp`]: props.data ? props.data[`${datasetName}_discomfortTypeSharp`] : false,
      [`${datasetName}_discomfortTypeBurning`]: props.data ? props.data[`${datasetName}_discomfortTypeBurning`] : false,
      [`${datasetName}_discomfortTypeTightness`]: props.data ? props.data[`${datasetName}_discomfortTypeTightness`] : false,
      [`${datasetName}_discomfortTypePulling`]: props.data ? props.data[`${datasetName}_discomfortTypePulling`] : false,
      [`${datasetName}_discomfortTypeOther`]: props.data ? props.data[`${datasetName}_discomfortTypeOther`] : false,
      [`${datasetName}_typeOther`]: props.data ? props.data[`${datasetName}_typeOther`] : "",

      [`${datasetName}_discomfortResolvedImmediately`]: props.data ? props.data[`${datasetName}_discomfortResolvedImmediately`] : false,
      [`${datasetName}_discomfortPersisting`]: props.data ? props.data[`${datasetName}_discomfortPersisting`] : false,
      [`${datasetName}_discomfortResolvedWithRest`]: props.data ? props.data[`${datasetName}_discomfortResolvedWithRest`] : false,
      [`${datasetName}_discomfortIncreasing`]: props.data ? props.data[`${datasetName}_discomfortIncreasing`] : false,
    });

    const handleChange = (event: any): void => {
      event.persist();

      setFormData((prevState) => ({
        ...prevState,
        [`${datasetName}_deniedDiscomfort`]: false,
        [`${datasetName}_reportedDiscomfort`]: false,
        [event.target.name]: event.target.checked,
      }));
    };

    const handleCheckboxChange = (event: any): void => {
      event.persist();
      setFormData((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.checked,
      }));
    };

    const handleValueChange = (event: any): void => {
      event.persist();
      setFormData((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
      }));
    };

    const handleStatusChanged = (event: ChangeEvent<any>): void => {
      event.persist();
      const { datasetName } = props;

      let discomfortResolvedImmediately = formData[`${datasetName}_discomfortResolvedImmediately`];
      let discomfortPersisting = formData[`${datasetName}_discomfortPersisting`];
      let discomfortResolvedWithRest = formData[`${datasetName}_discomfortResolvedWithRest`];
      let discomfortIncreasing = formData[`${datasetName}_discomfortIncreasing`];

      if (
        event.target.name === `${datasetName}_discomfortResolvedImmediately` ||
        event.target.name === `${datasetName}_discomfortPersisting` ||
        event.target.name === `${datasetName}_discomfortResolvedWithRest` ||
        event.target.name === `${datasetName}_discomfortIncreasing`
      ) {
        //Set all the values to false, the event target will be overwritten with the actual value when the formData is set.
        //This gives us pseudo radio-button functionality.
        discomfortResolvedImmediately = false;
        discomfortPersisting = false;
        discomfortResolvedWithRest = false;
        discomfortIncreasing = false;
      }

      setFormData((prevState) => ({
        ...prevState,
        [`${datasetName}_discomfortResolvedImmediately`]: discomfortResolvedImmediately,
        [`${datasetName}_discomfortPersisting`]: discomfortPersisting,
        [`${datasetName}_discomfortResolvedWithRest`]: discomfortResolvedWithRest,
        [`${datasetName}_discomfortIncreasing`]: discomfortIncreasing,
        [event.target.name]: event.target.checked,
      }));
    };

    const handleIntensityChanged = (event: ChangeEvent<any>): void => {
      event.persist();

      const { datasetName } = props;

      let { intensityCheckboxesEnabled, intensityVASEnabled } = state;

      let intensityMild = formData[`${datasetName}_intensityMild`];
      let intensityMod = formData[`${datasetName}_intensityMod`];
      let intensitySevere = formData[`${datasetName}_intensitySevere`];

      let value = "";
      switch (event.target.name) {
        case `${datasetName}_intensityVas`: {
          intensityCheckboxesEnabled = !event.target.value;

          //Set the value here, we know that inside this case, it will always be a "value".
          value = event.target.value;
          break;
        }
        case `${datasetName}_intensityMild`:
        case `${datasetName}_intensityMod`:
        case `${datasetName}_intensitySevere`: {
          intensityVASEnabled = !event.target.checked;

          //Set all the values to false, the event target will be overwritten with the actual value when the formData is set.
          //This gives us pseudo radio-button functionality.
          intensityMild = false;
          intensityMod = false;
          intensitySevere = false;

          //Set the value here, we know that inside these cases, it will always be a "checked" value.
          value = event.target.checked;
          break;
        }
      }

      setFormData((prevState) => ({
        ...prevState,
        [`${datasetName}_intensityMild`]: intensityMild,
        [`${datasetName}_intensityMod`]: intensityMod,
        [`${datasetName}_intensitySevere`]: intensitySevere,
        [event.target.name]: value,
      }));

      setState((prevState) => ({
        ...prevState,
        intensityCheckboxesEnabled,
        intensityVASEnabled,
      }));
    };

    useEffect(() => {
      const timeout = setTimeout(() => {
        if (!!props.dataKey && !!props.assessmentID) {
          props.rootStore?.dataStore.savePartialData(formData, props.assessmentID, props.dataKey);
        }
      }, 500);

      return () => clearTimeout(timeout);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      formData[`${datasetName}_deniedDiscomfort`],
      formData[`${datasetName}_reportedDiscomfort`],
      formData[`${datasetName}_location`],
      formData[`${datasetName}_intensityVas`],
      formData[`${datasetName}_intensityMild`],
      formData[`${datasetName}_intensityMod`],
      formData[`${datasetName}_intensitySevere`],
      formData[`${datasetName}_discomfortTypeAche`],
      formData[`${datasetName}_discomfortTypeSharp`],
      formData[`${datasetName}_discomfortTypeBurning`],
      formData[`${datasetName}_discomfortTypeTightness`],
      formData[`${datasetName}_discomfortTypePulling`],
      formData[`${datasetName}_discomfortTypeOther`],
      formData[`${datasetName}_typeOther`],
      formData[`${datasetName}_discomfortResolvedImmediately`],
      formData[`${datasetName}_discomfortPersisting`],
      formData[`${datasetName}_discomfortIncreasing`],
    ]);

    useEffect(() => {
      if (!!props.handleDiscomfortReported && showParticipantsReport) {
        props.handleDiscomfortReported(formData[`${datasetName}_reportedDiscomfort`]);
      }
    }, [formData[`${datasetName}_deniedDiscomfort`], formData[`${datasetName}_reportedDiscomfort`]]);

    const buildDiscomfortDetails = (): ReactNode => {
      const { datasetName } = props;

      const { intensityVASEnabled, intensityCheckboxesEnabled } = state;

      const discomfortDetails = (
        <>
          {/* Location */}
          <FormGroup className="formGroupOveride">
            <Grid xs={12} item container className="StrengthTestContainer">
              <FormControl component="fieldset" className="fieldsetStrength">
                <FormGroup className="formGroupSmMargin">
                  <div className="flexer">
                    <div className="labelLeftSmall">
                      <FormLabel component="legend">Location</FormLabel>
                    </div>
                    <OutlinedInput
                      color="primary"
                      value={formData[`${datasetName}_location`]}
                      name={`${datasetName}_location`}
                      autoComplete="off"
                      inputRef={props.register}
                      onChange={handleValueChange}
                    />
                  </div>
                </FormGroup>
                <div style={{ marginLeft: "0px" }} className="invalidFeedback">
                  {props.errors?.location?.message}
                  {props.errors?.[`${datasetName}_location`]?.message} <br />
                </div>
              </FormControl>
            </Grid>
          </FormGroup>
          {/* Intensity */}
          <FormGroup className="formGroupOveride">
            <Grid xs={12} item container className="StrengthTestContainer">
              <FormControl component="fieldset" className="fieldsetStrength">
                <FormGroup className="formGroupSmMargin">
                  <div className="flexer">
                    <div className="labelLeftSmall">
                      <FormLabel component="legend">Intensity</FormLabel>
                    </div>
                    <OutlinedInput
                      color="primary"
                      name={`${datasetName}_intensityVas`}
                      autoComplete="off"
                      placeholder="VAS"
                      className="vasInput"
                      onChange={handleIntensityChanged}
                      value={formData[`${datasetName}_intensityVas`]}
                      disabled={!intensityVASEnabled}
                      inputRef={props.register}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<span className="checkboxIcon"></span>}
                          checkedIcon={<span className="checkboxIconChecked"></span>}
                          color="primary"
                          checked={formData[`${datasetName}_intensityMild`] as boolean}
                          disabled={!intensityCheckboxesEnabled}
                          onChange={handleIntensityChanged}
                        />
                      }
                      label="Mild"
                      labelPlacement="end"
                      name={`${datasetName}_intensityMild`}
                      inputRef={props.register}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<span className="checkboxIcon"></span>}
                          checkedIcon={<span className="checkboxIconChecked"></span>}
                          color="primary"
                          checked={formData[`${datasetName}_intensityMod`] as boolean}
                          disabled={!intensityCheckboxesEnabled}
                          onChange={handleIntensityChanged}
                        />
                      }
                      label="Mod"
                      labelPlacement="end"
                      name={`${datasetName}_intensityMod`}
                      inputRef={props.register}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<span className="checkboxIcon"></span>}
                          checkedIcon={<span className="checkboxIconChecked"></span>}
                          color="primary"
                          checked={formData[`${datasetName}_intensitySevere`] as boolean}
                          disabled={!intensityCheckboxesEnabled}
                          onChange={handleIntensityChanged}
                        />
                      }
                      label="Severe"
                      labelPlacement="end"
                      name={`${datasetName}_intensitySevere`}
                      inputRef={props.register}
                    />
                  </div>
                  <div style={{ marginLeft: "0px" }} className="invalidFeedback">
                    {props.errors?.intensity?.message}
                    {props.errors?.[`${datasetName}_intensity`]?.message} <br />
                  </div>
                </FormGroup>
              </FormControl>
            </Grid>
          </FormGroup>
          {/* Type */}
          <FormGroup className="formGroupOveride">
            <Grid xs={12} item container className="StrengthTestContainer">
              <FormControl component="fieldset" className="fieldsetStrength">
                <FormGroup className="formGroupSmMargin">
                  <div className="flexer">
                    <div className="labelLeftSmall">
                      <FormLabel component="legend">Type</FormLabel>
                    </div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<span className="checkboxIcon"></span>}
                          checkedIcon={<span className="checkboxIconChecked"></span>}
                          color="primary"
                          checked={formData[`${datasetName}_discomfortTypeAche`] as boolean}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="Ache"
                      labelPlacement="end"
                      name={`${datasetName}_discomfortTypeAche`}
                      inputRef={props.register}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<span className="checkboxIcon"></span>}
                          checkedIcon={<span className="checkboxIconChecked"></span>}
                          color="primary"
                          checked={formData[`${datasetName}_discomfortTypeSharp`] as boolean}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="Sharp"
                      labelPlacement="end"
                      name={`${datasetName}_discomfortTypeSharp`}
                      inputRef={props.register}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<span className="checkboxIcon"></span>}
                          checkedIcon={<span className="checkboxIconChecked"></span>}
                          color="primary"
                          checked={formData[`${datasetName}_discomfortTypeBurning`] as boolean}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="Burning"
                      labelPlacement="end"
                      name={`${datasetName}_discomfortTypeBurning`}
                      inputRef={props.register}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<span className="checkboxIcon"></span>}
                          checkedIcon={<span className="checkboxIconChecked"></span>}
                          color="primary"
                          checked={formData[`${datasetName}_discomfortTypeTightness`] as boolean}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="Tightness"
                      labelPlacement="end"
                      name={`${datasetName}_discomfortTypeTightness`}
                      inputRef={props.register}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<span className="checkboxIcon"></span>}
                          checkedIcon={<span className="checkboxIconChecked"></span>}
                          color="primary"
                          checked={formData[`${datasetName}_discomfortTypePulling`] as boolean}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="Pulling"
                      labelPlacement="end"
                      name={`${datasetName}_discomfortTypePulling`}
                      inputRef={props.register}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<span className="checkboxIcon"></span>}
                          checkedIcon={<span className="checkboxIconChecked"></span>}
                          color="primary"
                          checked={formData[`${datasetName}_discomfortTypeOther`] as boolean}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="Other"
                      labelPlacement="end"
                      name={`${datasetName}_discomfortTypeOther`}
                      inputRef={props.register}
                    />
                    {(formData[`${datasetName}_discomfortTypeOther`] as boolean) ? (
                      <TextField
                        label="Write something"
                        name={`${datasetName}_typeOther`}
                        multiline
                        fullWidth
                        variant="outlined"
                        className="outlineTextArea"
                        value={formData[`${datasetName}_typeOther`]}
                        onChange={handleValueChange}
                        inputRef={props.register}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </FormGroup>
                <div style={{ marginLeft: "0px" }} className="invalidFeedback">
                  {props.errors?.type?.message}
                  {props.errors?.[`${datasetName}_type`]?.message}
                  {props.errors?.typeOther?.message} <br />
                  {props.errors?.[`${datasetName}_typeOther`]?.message} <br />
                </div>
              </FormControl>
            </Grid>
          </FormGroup>
          {/* Status */}
          <FormGroup className="formGroupOveride">
            <Grid xs={12} item container className="StrengthTestContainer">
              <FormControl component="fieldset" className="fieldsetStrength">
                <FormGroup className="formGroupSmMargin">
                  <div className="flexer">
                    <div className="labelLeftSmall">
                      <FormLabel component="legend">Status</FormLabel>
                    </div>
                    <div className="statusWrapper">
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<span className="checkboxIcon"></span>}
                            checkedIcon={<span className="checkboxIconChecked"></span>}
                            color="primary"
                            checked={formData[`${datasetName}_discomfortResolvedImmediately`] as boolean}
                            onChange={handleStatusChanged}
                          />
                        }
                        label="Discomfort resolved immediately"
                        labelPlacement="end"
                        className="statusHalf"
                        name={`${datasetName}_discomfortResolvedImmediately`}
                        inputRef={props.register}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<span className="checkboxIcon"></span>}
                            checkedIcon={<span className="checkboxIconChecked"></span>}
                            color="primary"
                            checked={formData[`${datasetName}_discomfortPersisting`] as boolean}
                            onChange={handleStatusChanged}
                          />
                        }
                        label="Discomfort persisting"
                        labelPlacement="end"
                        className="statusHalf"
                        name={`${datasetName}_discomfortPersisting`}
                        inputRef={props.register}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<span className="checkboxIcon"></span>}
                            checkedIcon={<span className="checkboxIconChecked"></span>}
                            color="primary"
                            checked={formData[`${datasetName}_discomfortResolvedWithRest`] as boolean}
                            onChange={handleStatusChanged}
                          />
                        }
                        label="Discomfort resolved with rest"
                        labelPlacement="end"
                        className="statusHalf"
                        name={`${datasetName}_discomfortResolvedWithRest`}
                        inputRef={props.register}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<span className="checkboxIcon"></span>}
                            checkedIcon={<span className="checkboxIconChecked"></span>}
                            color="primary"
                            checked={formData[`${datasetName}_discomfortIncreasing`] as boolean}
                            onChange={handleStatusChanged}
                          />
                        }
                        label="Discomfort increasing"
                        labelPlacement="end"
                        className="statusHalf"
                        name={`${datasetName}_discomfortIncreasing`}
                        inputRef={props.register}
                      />
                    </div>
                  </div>
                </FormGroup>
                <div style={{ marginLeft: "0px" }} className="invalidFeedback">
                  {props.errors?.status?.message}
                  {props.errors?.[`${datasetName}_status`]?.message}
                </div>
              </FormControl>
            </Grid>
          </FormGroup>
        </>
      );
      return discomfortDetails;
    };

    if (showParticipantsReport) {
      return (
        <>
          {showParticipantsReport ? (
            <Card className="participantReportsCard" variant="outlined">
              <Typography variant="h2" className="cardHeroHeading">
                <img src={Baseline} alt="Success" />
                Participant reports
              </Typography>
              <Typography variant="subtitle2" className="subtitleHeading">
                "How did you go with that?"
              </Typography>
              <div className="participantReports">
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<span className="checkboxIcon"></span>}
                      checkedIcon={<span className="checkboxIconChecked"></span>}
                      checked={formData[`${datasetName}_deniedDiscomfort`]}
                      onChange={handleChange}
                      color="primary"
                    />
                  }
                  label="Denied discomfort"
                  name={`${datasetName}_deniedDiscomfort`}
                  inputRef={props.register}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<span className="checkboxIcon"></span>}
                      checkedIcon={<span className="checkboxIconChecked"></span>}
                      checked={formData[`${datasetName}_reportedDiscomfort`]}
                      onChange={handleChange}
                      color="primary"
                    />
                  }
                  style={{ marginLeft: "20px" }}
                  label="Reported discomfort"
                  name={`${datasetName}_reportedDiscomfort`}
                  inputRef={props.register}
                />
              </div>
              <div className="invalidFeedback">
                {props.errors?.participantReports?.message} <br />
              </div>
            </Card>
          ) : (
            <></>
          )}
          {formData[`${datasetName}_reportedDiscomfort`] ? (
            <Card variant="outlined">
              <Typography variant="h2" style={{ marginBottom: "40px" }}>
                Discomfort reported
              </Typography>
              {buildDiscomfortDetails()}
            </Card>
          ) : (
            <></>
          )}
        </>
      );
    } else {
      /*
        This will return the discomfort details form WITHOUT any wrapping card or the "parent" participant reports card.
        This is intended for use where embedding the discomfort details form is required over a separate card. e.g. MSK
      */
      return <>{buildDiscomfortDetails()}</>;
    }
  })
);

export default ParticipantReports;

import * as yup from "yup";

const baselineDetailsValidation = yup.object({
  //bodyWeight: yup.string().required("Required"),
  bodyWeight: yup.string().test("bodyWeight", "Required", function () {
    const { bodyWeight } = this.parent;
    return !!bodyWeight;
  }),
  height: yup.string().required("Required"),
  bloodPressureSystolic: yup.string().test("bloodPressureSystolic", "Please enter the patient's blood pressure.", function () {
    const { bloodPressureSystolic } = this.parent;
    return !!bloodPressureSystolic;
  }),
  bloodPressureDiastolic: yup.string().test("bloodPressureDiastolic", "Please enter the patient's blood pressure.", function () {
    const { bloodPressureDiastolic } = this.parent;
    return !!bloodPressureDiastolic;
  }),
  bloodPressureSystolic2: yup.string().test("bloodPressureSystolic2", "Please enter the patient's 2nd blood pressure reading.", function () {
    const { BPDia, BPSys, bloodPressureSystolic2, isHighBPFlowTested, bloodPressureDiastolic, bloodPressureSystolic } = this.parent;
    if (bloodPressureSystolic2 === undefined) {
      return true;
    }
    return (
      ((Number(bloodPressureDiastolic) > Number(BPDia) && Number(bloodPressureSystolic) > 0) ||
        (Number(bloodPressureSystolic) > Number(BPSys) && Number(bloodPressureDiastolic) > 0)) &&
      isHighBPFlowTested &&
      isHighBPFlowTested === "true" &&
      bloodPressureSystolic2 !== null &&
      bloodPressureSystolic2 !== ""
    );
  }),
  bloodPressureDiastolic2: yup.string().test("bloodPressureDiastolic2", "Please enter the patient's 2nd blood pressure reading.", function () {
    const { BPDia, BPSys, bloodPressureDiastolic2, isHighBPFlowTested, bloodPressureDiastolic, bloodPressureSystolic } = this.parent;
    if (bloodPressureDiastolic2 === undefined) {
      return true;
    }
    return (
      ((Number(bloodPressureDiastolic) > Number(BPDia) && Number(bloodPressureSystolic) > 0) ||
        (Number(bloodPressureSystolic) > Number(BPSys) && Number(bloodPressureDiastolic) > 0)) &&
      isHighBPFlowTested &&
      isHighBPFlowTested === "true" &&
      bloodPressureDiastolic2 !== null &&
      bloodPressureDiastolic2 !== ""
    );
  }),
  heartRate: yup.string().required("Please enter the patient's heart rate."),
  handDominance: yup.string().required("Please record the patient's hand dominance."),
  physicalSelected: yup.bool().test("Physical", "Please select at least one physical readiness option", function (yesAllPhysical) {
    const { wellRested, noCaffeine, noNicotine, noSuspectedInfluence } = this.parent;

    if (!yesAllPhysical && !wellRested && !noCaffeine && !noNicotine && !noSuspectedInfluence) {
      return false;
    }
    return true;
  }),
  emotionalState: yup.string().required("Please record the patient's emotional state."),
  communication: yup.string().required("Please record the patient's communication level."),
  attireSelected: yup.bool().test("Attire", "Please select at least one attire option", function (yesAllAttire) {
    const { closedShoes, shorts, shoulderAndBackVisible } = this.parent;

    if (!yesAllAttire && !closedShoes && !shorts && !shoulderAndBackVisible) {
      return false;
    }
    return true;
  }),
});

export default baselineDetailsValidation;

import React, { ChangeEvent, useEffect } from "react";
import { ProfileCard, FormNavigator, Stepper, Accordion, IOSSwitch, MobilityTest, NotesModal, InTestMedicalClearanceModal } from "../../../components/";
import { MskStanding } from "../../../assets/images/";
import musculoskeletalValidation from "./mskStandingValidation";

import { Grid, Typography, Container, FormLabel, FormControlLabel, OutlinedInput, Card, FormGroup, Checkbox } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import { Root } from "../../../stores";
import { differenceInYears } from "date-fns";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { dataStorePageKeys } from "../../../stores/dataStore";
import { mskStandingMobilityDataKeys } from "./mskDataKeys";
import { useHistory } from "react-router-dom";
import { DASHBOARD, MSKSITTING } from "../../../routes/paths";
import { getAppInsights } from "../../../components/telemetry";
import withAppInsights from "../AppInsights";

interface MusckuloskeletalProps {
  rootStore?: Root;
}

const mskStanding = inject("rootStore")(
  observer((props: MusckuloskeletalProps) => {
    let pefaAssessment: any;
    let prevData: any;
    let referralDetails: any;
    let medicalHistoryData: any;
    let endAssessment: any;
    let participantAgeYears = 0;
    const history = useHistory();
    const appInsights = getAppInsights();

    if (props.rootStore?.pefaStore.selectedPefa) {
      pefaAssessment = props.rootStore?.pefaStore.selectedPefa[0];
      prevData = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.MSK_STANDING);
      referralDetails = props.rootStore?.dataStore.getCheckInData(pefaAssessment.id, dataStorePageKeys.REFERRAL_DETAILS);
      participantAgeYears = differenceInYears(new Date(), new Date(referralDetails?.dob ?? pefaAssessment.pefArequest.worker.dateOfBirth));
      medicalHistoryData = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.MEDICAL_DETAILS);
      endAssessment = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.END_ASSESSMENT);
    } else {
      history.push(DASHBOARD);
      return <></>;
    }

    const { register, handleSubmit, errors, setValue } = useForm({
      resolver: yupResolver(musculoskeletalValidation),
    });

    const [state, setState] = useState({
      isLoading: true,
      yesAllNilSignificant: false,
      viewAll: false,

      trunkMobilityPainFree: prevData ? prevData.trunkMobilityPainFree : false,
      trunkMobilityEdited: false,
      trunkMobilityExpanded: false,

      bendForward: false,
      bendBackward: false,
      rotateLeft: false,
      rotateRight: false,

      squatFullPainFree: prevData ? prevData.squatFullPainFree : false,
      squatFullEdited: false,
      squatFullPainFreeExpanded: false,

      squatFull: false,

      squatFullWeightBearingRatioSymmetrical: prevData ? (prevData.squatFullWeightBearingRatioSymmetrical ? prevData.squatFullWeightBearingRatioSymmetrical : false) : false,
      squatFullWeightBearingRatioAsymmetrical: prevData ? (prevData.squatFullWeightBearingRatioAsymmetrical ? prevData.squatFullWeightBearingRatioAsymmetrical : false) : false,
      squatFullWeightBearingRatioL: prevData ? prevData.squatFullWeightBearingRatioL || (prevData.squatsquatFullWeightBearingRatioSymmetrical && "50") || "" : "",
      squatFullWeightBearingRatioR: prevData ? prevData.squatFullWeightBearingRatioR || (prevData.squatsquatFullWeightBearingRatioSymmetrical && "50") || "" : "",

      squatFullNoSupportRequired: prevData ? (prevData.squatFullNoSupportRequired ? prevData.squatFullNoSupportRequired : false) : false,
      squatFullSupportToLower: prevData ? (prevData.squatFullSupportToLower ? prevData.squatFullSupportToLower : false) : false,
      squatFullSupportToRise: prevData ? (prevData.squatFullSupportToRise ? prevData.squatFullSupportToRise : false) : false,
    });

    const handleViewAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();
      setState((prevState) => ({
        ...prevState,
        viewAll: event.target.checked,
        trunkMobilityExpanded: event.target.checked,
        squatFullPainFreeExpanded: event.target.checked,
      }));
    };

    const toggleAccordion = async (eventValue: React.ChangeEvent<HTMLDivElement>, componentName: string) => {
      await setState((prevState) => ({
        ...prevState,
        [componentName]: eventValue,
      }));

      if (!eventValue && state.viewAll) {
        await setState((prevState) => ({
          ...prevState,
          viewAll: false,
        }));
      }
    };

    const handleCheckboxChange = async (event: any, logic: any = null): Promise<void> => {
      const control = event.currentTarget;
      const formComponent = control.getAttribute("name") as string;

      //Store the current checkbox's value to the state using the checkbox name attribute as the key.
      //This is set as an await to make sure that it has saved before moving on. This may not be necessary,
      //but I'll leave it here for now. It's handy for testing
      await setState((prevState) => ({
        ...prevState,
        [formComponent]: control.checked,
      }));

      //If a reference to a function has been passed, execute it, passing in the control checked state.
      if (!!logic) {
        logic(control.checked);
      }
    };

    const changeAllChildCheckboxes = (checked: boolean, targetCheckboxes: string[]): void => {
      targetCheckboxes.forEach((child) => {
        //Set the checkbox values in the form, flagging them as dirty.
        setValue(child, checked, { shouldDirty: true });

        //Update the mapped state values. This updates the field visually.
        setState((prevState) => ({
          ...prevState,
          [child]: checked,
        }));
      });
    };

    const changeParentCheckbox = async (childChecked: boolean, parentCheckbox: string) => {
      if (!childChecked) {
        setValue(parentCheckbox, false, { shouldDirty: true });
        await setState((prevState) => ({
          ...prevState,
          [parentCheckbox]: false,
        }));
      }
    };

    const handleWeightBearingChange = (event: ChangeEvent<any>) => {
      event.persist();

      let { squatFullWeightBearingRatioSymmetrical, squatFullWeightBearingRatioAsymmetrical, squatFullWeightBearingRatioL, squatFullWeightBearingRatioR } = state;

      let value = "";

      switch (event.target.name) {
        case "squatFullWeightBearingRatioSymmetrical":
        case "squatFullWeightBearingRatioAsymmetrical": {
          value = event.target.checked;

          squatFullWeightBearingRatioSymmetrical = false;
          squatFullWeightBearingRatioAsymmetrical = false;
          squatFullWeightBearingRatioL = event.target.checked ? "50" : "";
          squatFullWeightBearingRatioR = event.target.checked ? "50" : "";
          break;
        }
        case "squatFullWeightBearingRatioL": {
          value = event.target.value;
          squatFullWeightBearingRatioR = (100 - Number(event.target.value)).toString();
          break;
        }
        case "squatFullWeightBearingRatioR": {
          value = event.target.value;
          squatFullWeightBearingRatioL = (100 - Number(event.target.value)).toString();
          break;
        }
      }

      setState((prevState) => ({
        ...prevState,
        squatFullWeightBearingRatioSymmetrical,
        squatFullWeightBearingRatioAsymmetrical,
        squatFullWeightBearingRatioL,
        squatFullWeightBearingRatioR,
        [event.target.name]: value,
      }));
    };

    const handleSupportChange = (event: ChangeEvent<any>) => {
      event.persist();

      setState((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.checked,
      }));
    };

    const handleDataChanged = (datasetName: string, isDirty: boolean) => {
      setState((prevState) => ({
        ...prevState,
        [datasetName]: isDirty,
      }));
    };

    useEffect(() => {
      setState((prevState) => ({
        ...prevState,
        trunkMobilityEdited: state.bendForward || state.bendBackward || state.rotateLeft || state.rotateRight,
        squatFullEdited:
          state.squatFull ||
          state.squatFullNoSupportRequired ||
          state.squatFullSupportToLower ||
          state.squatFullSupportToRise ||
          state.squatFullWeightBearingRatioSymmetrical ||
          state.squatFullWeightBearingRatioAsymmetrical,
      }));
    }, [
      state.bendForward,
      state.bendBackward,
      state.rotateLeft,
      state.rotateRight,
      state.squatFull,
      state.squatFullNoSupportRequired,
      state.squatFullSupportToLower,
      state.squatFullSupportToRise,
      state.squatFullWeightBearingRatioSymmetrical,
      state.squatFullWeightBearingRatioAsymmetrical,
    ]);

    useEffect(() => {
      const timeout = setTimeout(() => {
        props.rootStore?.dataStore.savePartialData(state, pefaAssessment.id, dataStorePageKeys.MSK_STANDING);
      }, 500);

      return () => clearTimeout(timeout);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      state.trunkMobilityPainFree,
      state.squatFullPainFree,
      state.squatFullWeightBearingRatioSymmetrical,
      state.squatFullWeightBearingRatioAsymmetrical,
      state.squatFullWeightBearingRatioL,
      state.squatFullWeightBearingRatioR,
      state.squatFullNoSupportRequired,
      state.squatFullSupportToLower,
      state.squatFullSupportToRise,
    ]);

    useEffect(() => {
      //Replicate the onUnmount event handler from class components.
      return () => {
        appInsights.trackEvent({ name: "intermediateSubmission", properties: { bookingId: pefaAssessment.id, dataKey: dataStorePageKeys.MSK_STANDING, data: state } });
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submitForm = (data: any, forwardTarget: any) => {
      props.rootStore?.dataStore.saveData(data, pefaAssessment.id, dataStorePageKeys.MSK_STANDING);
      const pefaData = { ...props.rootStore?.dataStore.pefaData[pefaAssessment.id] };
      // take snapshot of assessment and store against booking
      props.rootStore?.pefaStore.nextSubmit(pefaData, pefaAssessment.id);
      history.push(forwardTarget);
    };

    const physicalChildren = ["trunkMobilityPainFree", "squatFullPainFree"];

    return (
      <div className="FormStyler">
        <div className="AssessmentDetails">
          <Grid item container direction="column" xs={12}>
            <Stepper targetStep={"Observations & Musculosketal"} />
            <Container maxWidth={"lg"}>
              <Grid item container direction="row" xs={12}>
                <Grid item container xs={9}>
                  <Card className="titleCard" variant="outlined">
                    <Typography variant="h2" className="cardHeroHeading">
                      <img src={MskStanding} alt="Success" /> Standing: Mobility
                    </Typography>
                    <Typography variant="subtitle2" className="subtitleHeading">
                      List of Observations
                    </Typography>
                  </Card>
                  <Grid item container xs={12}>
                    <div className="formControllerContainer">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="yesAllNilSignificant"
                            name="yesAllNilSignificant"
                            icon={<span className="checkboxIcon"></span>}
                            checkedIcon={<span className="checkboxIconChecked"></span>}
                            color="primary"
                          />
                        }
                        label="All within functional limits pain free"
                        inputRef={register}
                        checked={state.yesAllNilSignificant}
                        onChange={(event) => {
                          handleCheckboxChange(event, (checked: boolean) => changeAllChildCheckboxes(checked, physicalChildren));
                        }}
                      />
                      <FormControlLabel control={<IOSSwitch checked={state.viewAll} onChange={handleViewAllChange} name="checkedB" />} label="View All" />
                    </div>
                    {/* 
                    |
                      Trunk Mobility within limits pain free
                    |
                    */}
                    <form className="PefaForm">
                      <Accordion
                        inputName="trunkMobilityPainFree"
                        label="Trunk mobility within limits pain free"
                        checked={state.trunkMobilityPainFree}
                        expanded={state.trunkMobilityExpanded}
                        edited={state.trunkMobilityEdited}
                        innerRef={register}
                        innerValidationError={
                          !!errors.trunkMobility?.message ||
                          !!errors.bendForward?.message ||
                          !!errors.bendBackward?.message ||
                          !!errors.rotateLeft?.message ||
                          !!errors.rotateRight?.message
                        }
                        checkedHandler={(event: any) => handleCheckboxChange(event, (checked: boolean) => changeParentCheckbox(checked, "yesAllNilSignificant"))}
                        expandedHandler={(event: any) => {
                          toggleAccordion(event, "trunkMobilityExpanded");
                        }}
                      >
                        <div className="invalid-feedback">{errors.trunkMobility?.message}</div>
                        <MobilityTest
                          label="Bend Forward"
                          datasetName={mskStandingMobilityDataKeys.BENDFORWARD}
                          register={register}
                          errors={errors}
                          data={prevData}
                          setValue={setValue}
                          handleDataChanged={handleDataChanged}
                          assessmentID={pefaAssessment.id}
                          dataKey={dataStorePageKeys.MSK_STANDING}
                        />
                        <MobilityTest
                          label="Bend Backward"
                          datasetName={mskStandingMobilityDataKeys.BENDBACKWARD}
                          register={register}
                          errors={errors}
                          data={prevData}
                          setValue={setValue}
                          handleDataChanged={handleDataChanged}
                          assessmentID={pefaAssessment.id}
                          dataKey={dataStorePageKeys.MSK_STANDING}
                        />
                        <MobilityTest
                          label="Rotate Left"
                          datasetName={mskStandingMobilityDataKeys.ROTATELEFT}
                          register={register}
                          errors={errors}
                          data={prevData}
                          setValue={setValue}
                          handleDataChanged={handleDataChanged}
                          assessmentID={pefaAssessment.id}
                          dataKey={dataStorePageKeys.MSK_STANDING}
                        />
                        <MobilityTest
                          label="Rotate Right"
                          datasetName={mskStandingMobilityDataKeys.ROTATERIGHT}
                          register={register}
                          errors={errors}
                          data={prevData}
                          setValue={setValue}
                          handleDataChanged={handleDataChanged}
                          assessmentID={pefaAssessment.id}
                          dataKey={dataStorePageKeys.MSK_STANDING}
                        />
                      </Accordion>
                      {/* 
                    |
                      Squat full, even, painfree and no support required
                    |
                    */}
                      <Accordion
                        inputName="squatFullPainFree"
                        checked={state.squatFullPainFree}
                        expanded={state.squatFullPainFreeExpanded}
                        edited={state.squatFullEdited}
                        innerValidationError={!!errors.squatFullPainFree?.message || !!errors.squatFull?.message}
                        innerRef={register}
                        label="Squat full, even, painfree and no support required"
                        checkedHandler={(event: any) => handleCheckboxChange(event, (checked: boolean) => changeParentCheckbox(checked, "yesAllNilSignificant"))}
                        expandedHandler={(event: any) => {
                          toggleAccordion(event, "squatFullPainFreeExpanded");
                        }}
                      >
                        <div className="invalid-feedback">{errors.squatFullPainFree?.message}</div>
                        <MobilityTest
                          label="Squat"
                          datasetName={mskStandingMobilityDataKeys.SQUATFULL}
                          register={register}
                          errors={errors}
                          data={prevData}
                          setValue={setValue}
                          handleDataChanged={handleDataChanged}
                          assessmentID={pefaAssessment.id}
                          dataKey={dataStorePageKeys.MSK_STANDING}
                        />
                        <FormLabel component="legend">Weight Bearing Ratio</FormLabel>
                        <FormGroup className="formGroupOveride">
                          <div className="flexer">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  icon={<span className="checkboxIcon"></span>}
                                  checkedIcon={<span className="checkboxIconChecked"></span>}
                                  color="primary"
                                  checked={state.squatFullWeightBearingRatioSymmetrical}
                                  onChange={handleWeightBearingChange}
                                />
                              }
                              inputRef={register}
                              label="Symmetrical"
                              name="squatFullWeightBearingRatioSymmetrical"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  icon={<span className="checkboxIcon"></span>}
                                  checkedIcon={<span className="checkboxIconChecked"></span>}
                                  color="primary"
                                  checked={state.squatFullWeightBearingRatioAsymmetrical}
                                  onChange={handleWeightBearingChange}
                                />
                              }
                              inputRef={register}
                              label="Asymmetrical"
                              name="squatFullWeightBearingRatioAsymmetrical"
                            />
                            <FormLabel component="legend" className="wbRatio">
                              WB Ratio
                            </FormLabel>
                            <FormControlLabel
                              control={
                                <OutlinedInput
                                  color="primary"
                                  className="textInputTiny"
                                  autoComplete="off"
                                  value={state.squatFullWeightBearingRatioL}
                                  onChange={handleWeightBearingChange}
                                  disabled={!state.squatFullWeightBearingRatioAsymmetrical}
                                />
                              }
                              inputRef={register}
                              label="L"
                              labelPlacement="start"
                              name="squatFullWeightBearingRatioL"
                            />
                            <FormControlLabel
                              control={
                                <OutlinedInput
                                  color="primary"
                                  className="textInputTiny"
                                  autoComplete="off"
                                  value={state.squatFullWeightBearingRatioR}
                                  onChange={handleWeightBearingChange}
                                  disabled={!state.squatFullWeightBearingRatioAsymmetrical}
                                />
                              }
                              inputRef={register}
                              label="R"
                              labelPlacement="start"
                              name="squatFullWeightBearingRatioR"
                            />
                          </div>
                        </FormGroup>

                        <FormLabel component="legend">Support</FormLabel>
                        <FormGroup className="formGroupOveride">
                          <div className="flexer">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  icon={<span className="checkboxIcon"></span>}
                                  checkedIcon={<span className="checkboxIconChecked"></span>}
                                  color="primary"
                                  checked={state.squatFullNoSupportRequired}
                                  disabled={state.squatFullSupportToLower || state.squatFullSupportToRise}
                                  onChange={handleSupportChange}
                                />
                              }
                              inputRef={register}
                              label="No Support Required"
                              name="squatFullNoSupportRequired"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  icon={<span className="checkboxIcon"></span>}
                                  checkedIcon={<span className="checkboxIconChecked"></span>}
                                  color="primary"
                                  checked={state.squatFullSupportToLower}
                                  disabled={state.squatFullNoSupportRequired}
                                  onChange={handleSupportChange}
                                />
                              }
                              inputRef={register}
                              label="Support to lower"
                              name="squatFullSupportToLower"
                            />

                            <FormControlLabel
                              control={
                                <Checkbox
                                  icon={<span className="checkboxIcon"></span>}
                                  checkedIcon={<span className="checkboxIconChecked"></span>}
                                  color="primary"
                                  checked={state.squatFullSupportToRise}
                                  disabled={state.squatFullNoSupportRequired}
                                  onChange={handleSupportChange}
                                />
                              }
                              inputRef={register}
                              label="Support to rise"
                              name="squatFullSupportToRise"
                            />
                          </div>
                        </FormGroup>
                      </Accordion>
                    </form>
                  </Grid>
                </Grid>
                <Grid item container xs={3} className="RightCol">
                  <ProfileCard
                    firstName={pefaAssessment.pefArequest.worker.firstName}
                    lastName={pefaAssessment.pefArequest.worker.lastName}
                    participantAgeYears={participantAgeYears}
                  />
                  <NotesModal pageKey={dataStorePageKeys.MSK_STANDING} pefaID={pefaAssessment.id} />
                  <InTestMedicalClearanceModal
                    firstName={referralDetails.firstName}
                    lastName={referralDetails.lastName}
                    pageKey={dataStorePageKeys.MSK_STANDING}
                    pefaID={pefaAssessment.id}
                    medicalPractice={medicalHistoryData.medicalPractitioner}
                    medicalPractitioner={medicalHistoryData.medicalPractice}
                  />
                </Grid>
              </Grid>
            </Container>
            <FormNavigator
              forwardTarget={MSKSITTING}
              handleSubmit={handleSubmit(submitForm)}
              warning="If you suspect a current injury, please follow protocol for obtaining a JobFit System Medical Clearance"
              disabledNextButton={
                endAssessment &&
                endAssessment.endAssessmentReason !== "" &&
                endAssessment.endAssessmentNotes !== "" &&
                endAssessment.endAssessmentPage === dataStorePageKeys.MSK_STANDING
              }
              selectedKey={dataStorePageKeys.MSK_STANDING}
            />
          </Grid>
        </div>
      </div>
    );
  })
);

export default withAppInsights(mskStanding);

import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { useAuth } from "oidc-react";
import { useHistory } from "react-router-dom";
import { FormNavigator, PerformanceSummary, ProfileCard, Stepper } from "../../components";
import { DASHBOARD } from "../../routes/paths";
import { Root } from "../../stores";
import { Grid, Container, Card, Typography, Link } from "@material-ui/core";
import { Success } from "../../assets/images";
import { differenceInYears } from "date-fns";
import ApiService from "../../services/ApiService";

interface AssessmentPreviewProps {
  rootStore?: Root;
}

const AssessmentPreview = inject("rootStore")(
  observer((props: AssessmentPreviewProps) => {
    const auth = useAuth();
    const history = useHistory();
    const [medicalClearances] = useState(props.rootStore?.pefaStore.getSelectedPefaMedicalClearances());

    let pefaAssessment: any;
    let participantAgeYears = 0;
    if (props.rootStore?.pefaStore.selectedPefa) {
      pefaAssessment = props.rootStore?.pefaStore.selectedPefa[0];
      if (pefaAssessment.pefArequest.worker.dateOfBirth) {
        participantAgeYears = differenceInYears(new Date(), new Date(pefaAssessment.pefArequest.worker.dateOfBirth));
      } else {
        participantAgeYears = 0;
      }
    } else {
      history.push(DASHBOARD);
      return <></>;
    }
    const handleCertificateDownload = async (id: number, fileName: string) => {
      await ApiService.getMedicalCertificate(id, fileName);
    };
    const [state, setState] = useState({
      isLoading: true,
    });

    return (
      <div className="FormStyler">
        <div className="AssessmentDetails">
          <Grid item container direction="column" xs={12}>
            <Stepper targetStep={""} />
            <Container maxWidth={"lg"}>
              <Grid item container xs={12} direction="row">
                <Grid item container xs={9}>
                  <Card className="uCard" variant="outlined">
                    <Typography variant="h2" className="heroHeading">
                      <img src={Success} alt="Success" />
                      &nbsp;
                      <span>Activities in this PEFA</span>
                    </Typography>
                  </Card>
                  <PerformanceSummary assessmentId={pefaAssessment.id} isPreview={true} assessmentType={pefaAssessment.bookingType.displayName} />
                  <Card className="uCard" variant="outlined">
                    <Typography className={"agreementText"} variant="body1">
                      If this activity list does not seem to be complete, please contact your referrer to confirm before starting.
                    </Typography>
                  </Card>
                  <Card className="uCard" variant="outlined">
                    <Typography variant="h2" className="heroHeading">
                      <img src={Success} alt="Success" />
                      &nbsp;
                      <span>Pre Test Clearances</span>
                    </Typography>
                  </Card>
                  <Card className="uCard" variant="outlined">
                    <Typography variant="h2" className="heroHeading">
                      <span>
                        {medicalClearances
                          .filter((x) => x.medicalCertificate !== null && x.otherInfo?.indexOf("Paper") < 0)
                          .map((medicalClearance: any, index: number) => (
                            <>
                              <div className="viewMedicalConditionFile" key={index}>
                                <div className="viewMedicalConditionFile" key={index}>
                                  {medicalClearance.medicalCertificate ? (
                                    <>
                                      <Link
                                        className="viewMC"
                                        underline="always"
                                        onClick={() => handleCertificateDownload(medicalClearance.medicalCertificate.id, medicalClearance.medicalCertificate.filename)}
                                      >
                                        {medicalClearance.condition}
                                      </Link>
                                      <div className="overflowText">
                                        {medicalClearance.outcome ? ` - ${medicalClearance.outcome.toUpperCase()} ` : ""}{" "}
                                        {medicalClearance.otherInfo ? ` - ${medicalClearance.otherInfo} ` : ""}
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </>
                          ))}
                      </span>
                    </Typography>
                  </Card>
                </Grid>
                <Grid item container xs={3} className="RightCol">
                  <ProfileCard
                    firstName={pefaAssessment.pefArequest.worker.firstName}
                    lastName={pefaAssessment.pefArequest.worker.lastName}
                    participantAgeYears={participantAgeYears}
                  />
                </Grid>
              </Grid>
            </Container>
            <FormNavigator homeText={"Return to Dashboard"} pushProgressOnEnd={false} showBackButton={false} selectedKey="" />
          </Grid>
        </div>
      </div>
    );
  })
);

export default AssessmentPreview;

import { keyPairData } from ".";

export default class balanceResults {
  public static formatForSubmission(balanceResults: { [key: string]: any }): keyPairData[] {
    const formattedData: keyPairData[] = [];

    const dataKeys = ["leftLegEyesOpen", "rightLegEyesOpen", "leftLegEyesClosed", "rightLegEyesClosed"];

    dataKeys.forEach((dataKey) => {
      const attempts: string[] = [];
      let tested = false;

      if (!!balanceResults[`${dataKey}Attempt1`]) {
        attempts.push(balanceResults[`${dataKey}Attempt1`]);
        tested = true;
      }
      if (!!balanceResults[`${dataKey}Attempt2`]) {
        attempts.push(balanceResults[`${dataKey}Attempt2`]);
      }
      if (!!balanceResults[`${dataKey}Attempt3`]) {
        attempts.push(balanceResults[`${dataKey}Attempt3`]);
      }

      const balanceTest = {
        name: dataKey,
        attempts,
        comments: balanceResults[`${dataKey}Comments`],
        tested,
        pillowUsed: balanceResults[`standardPillowUsed${dataKeys.indexOf(dataKey) + 1}`],
      };

      //TODO ratingResult?.

      formattedData.push(balanceTest);
    });

    return formattedData;
  }
}

import { keyPairData } from ".";
import { programRecommendationDataKeys } from "../../views/app/ProgramRecommendations/ProgramRecommendationsDataKeys";

export default class recommendations {
  public static formatForSubmission(recommendations: { [key: string]: any }): keyPairData {
    let formattedData: keyPairData = {};

    programRecommendationDataKeys.forEach((dataKey) => {
      if (recommendations[dataKey.flag]) {
        formattedData = {
          ...formattedData,
          [dataKey.friendlyName]: recommendations[dataKey.note],
        };
      }
    });

    return formattedData;
  }
}

import * as yup from "yup";

const assessmentDetailsSchema = yup.object().shape({
  assessmentType: yup.bool().oneOf([true], "The participant must confirm the Assessment Type."),
  photoId: yup.bool().oneOf([true], "The participant must provide Photo ID."),
  informedConsent: yup.bool().oneOf([true], "The participant must have signed the informed consent form."),
  medicalHistory: yup.bool().oneOf([true], "The participant must confirm their medical history."),
  jobTitleSite: yup.bool().oneOf([true], "The participant must confirm the job title and site."),
  company: yup.bool().oneOf([true], "The participant must confirm the company."),
  minorConsent: yup.bool().when(["confirmedConsent", "noConsent", "age"], {
    is: (confirmedConsent, noConsent, age) => (age < 18 && !confirmedConsent && !noConsent) || (age < 18 && confirmedConsent && noConsent),
    then: yup.bool().required("Please confirm the participant has appropriate consent."),
  }),
});

export default assessmentDetailsSchema;

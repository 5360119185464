import React, { ChangeEvent, useEffect, useState } from "react";
import { FormControlLabel, FormControl, FormLabel, FormGroup, Checkbox, Select, MenuItem, OutlinedInput, Grid, TextField } from "@material-ui/core";
import { ParticipantReports } from "../";
import { inject, observer } from "mobx-react";
import { limitingFactors, limitingFactorsFriendly } from "./MobilityTestDataKeys";
import { dataStorePageKeys } from "../../stores/dataStore";
import { Root } from "../../stores";

interface MobilityProps {
  rootStore?: Root;
  label: string;
  datasetName: string;

  handleDataChanged?: any;

  data?: any;
  register?: any;
  errors?: any;
  setValue?: any;

  assessmentID?: any;
  dataKey?: dataStorePageKeys;
}

const MobilityTestComponent = inject("rootStore")(
  observer((props: MobilityProps) => {
    const { label, datasetName } = props;

    const [state, setState] = useState({
      dataChanged: props.data
        ? props.data[`${datasetName}_unable`] ||
          props.data[`${datasetName}_lessThanOneThird`] ||
          props.data[`${datasetName}_lessThanTwoThirds`] ||
          props.data[`${datasetName}_greaterThanTwoThirds`] ||
          !!props.data[`${datasetName}_objectiveRange`] ||
          props.data[`${datasetName}_limitingFactor`] !== limitingFactors.SELECT
        : false,

      [`${datasetName}_unable`]: props.data ? (props.data[`${datasetName}_unable`] ? props.data[`${datasetName}_unable`] : false) : false,
      [`${datasetName}_lessThanOneThird`]: props.data ? (props.data[`${datasetName}_lessThanOneThird`] ? props.data[`${datasetName}_lessThanOneThird`] : false) : false,
      [`${datasetName}_lessThanTwoThirds`]: props.data ? (props.data[`${datasetName}_lessThanTwoThirds`] ? props.data[`${datasetName}_lessThanTwoThirds`] : false) : false,
      [`${datasetName}_greaterThanTwoThirds`]: props.data ? (props.data[`${datasetName}_greaterThanTwoThirds`] ? props.data[`${datasetName}_greaterThanTwoThirds`] : false) : false,
      [`${datasetName}_objectiveRange`]: props.data ? props.data[`${datasetName}_objectiveRange`] : "",
      [`${datasetName}_limitingFactor`]: props.data
        ? !!props.data[`${datasetName}_limitingFactor`]
          ? (props.data[`${datasetName}_limitingFactor`] as limitingFactors)
          : limitingFactors.SELECT
        : limitingFactors.SELECT,
      [`${datasetName}_limitingFactorNotes`]: props.data ? props.data[`${datasetName}_limitingFactorNotes`] : "",
      isLoaded: false,
    });

    const handleLimitingFactorChange = (event: ChangeEvent<any>): void => {
      event.persist();

      setState((prevState) => ({
        ...prevState,
        dataChanged: true,
        [event.target.name]: event.target.value,
      }));

      props.setValue(event.target.name, event.target.value);
    };

    const handleRangeChange = (event: ChangeEvent<any>): void => {
      event.persist();
      const { datasetName } = props;

      setState((prevState) => ({
        ...prevState,
        dataChanged: true,
        [`${datasetName}_unable`]: false,
        [`${datasetName}_lessThanOneThird`]: false,
        [`${datasetName}_lessThanTwoThirds`]: false,
        [`${datasetName}_greaterThanTwoThirds`]: false,
        [event.target.name]: event.target.checked,
      }));
    };

    useEffect(() => {
      if (props.handleDataChanged && state.dataChanged) {
        setState((prevState) => ({ ...prevState, dataChanged: false }));
        props.handleDataChanged(
          datasetName,
          state[`${datasetName}_unable`] ||
            state[`${datasetName}_lessThanOneThird`] ||
            state[`${datasetName}_lessThanTwoThirds`] ||
            state[`${datasetName}_greaterThanTwoThirds`] ||
            !!state[`${datasetName}_objectiveRange`] ||
            state[`${datasetName}_limitingFactor`] !== limitingFactors.SELECT
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    useEffect(() => {
      const timeout = setTimeout(() => {
        if (!!props.assessmentID && !!props.dataKey && state.isLoaded) {
          props.rootStore?.dataStore.savePartialData(state, props.assessmentID, props.dataKey);
        }
      }, 500);

      return () => clearTimeout(timeout);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      state[`${datasetName}_unable`],
      state[`${datasetName}_lessThanTwoThirds`],
      state[`${datasetName}_greaterThanTwoThirds`],
      state[`${datasetName}_objectiveRange`],
      state[`${datasetName}_limitingFactor`],
      state[`${datasetName}_limitingFactorNotes`],
      state[`${datasetName}_lessThanOneThird`],
    ]);

    useEffect(() => {
      setState((prevState) => ({ ...prevState, isLoaded: true }));
    }, []);

    return (
      <Grid xs={12} item container className="mobilityTestContainer full-width">
        <div className="fieldsetGroup full-width">
          <FormLabel component="legend">{label}</FormLabel>
          <FormGroup className="formGroupOveride">
            <div className="flexer">
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<span className="checkboxIcon"></span>}
                    checkedIcon={<span className="checkboxIconChecked"></span>}
                    color="primary"
                    checked={state[`${datasetName}_unable`] as boolean}
                    onChange={handleRangeChange}
                  />
                }
                label="Unable"
                labelPlacement="start"
                name={datasetName + "_unable"}
                className="firstCheckbox"
                inputRef={props.register}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<span className="checkboxIcon"></span>}
                    checkedIcon={<span className="checkboxIconChecked"></span>}
                    color="primary"
                    checked={state[`${datasetName}_lessThanOneThird`] as boolean}
                    onChange={handleRangeChange}
                  />
                }
                label="< 1/3"
                labelPlacement="start"
                className="formLabelSmall"
                name={datasetName + "_lessThanOneThird"}
                inputRef={props.register}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<span className="checkboxIcon"></span>}
                    checkedIcon={<span className="checkboxIconChecked"></span>}
                    color="primary"
                    checked={state[`${datasetName}_lessThanTwoThirds`] as boolean}
                    onChange={handleRangeChange}
                  />
                }
                label="< 2/3"
                labelPlacement="start"
                className="formLabelSmall"
                name={datasetName + "_lessThanTwoThirds"}
                inputRef={props.register}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<span className="checkboxIcon"></span>}
                    checkedIcon={<span className="checkboxIconChecked"></span>}
                    color="primary"
                    checked={state[`${datasetName}_greaterThanTwoThirds`] as boolean}
                    onChange={handleRangeChange}
                  />
                }
                label="> 2/3"
                className="formLabelSmall"
                labelPlacement="start"
                name={datasetName + "_greaterThanTwoThirds"}
                inputRef={props.register}
              />
            </div>
            <div className="mobilityTestSecondLine">
              <FormGroup>
                <FormLabel component="legend" className="textLabelSmall">
                  Objective range
                </FormLabel>
                <OutlinedInput
                  color="primary"
                  value={state[`${datasetName}_objectiveRange`]}
                  onChange={handleLimitingFactorChange}
                  name={datasetName + "_objectiveRange"}
                  className="textInputLarge"
                  autoComplete="off"
                  inputRef={props.register}
                />
              </FormGroup>
              <FormControl variant="outlined">
                <FormLabel component="legend" className="textLabelSmall">
                  Limiting factor
                </FormLabel>
                <Select color="primary" onChange={handleLimitingFactorChange} value={state[`${datasetName}_limitingFactor`]} name={datasetName + "_limitingFactor"}>
                  <MenuItem value={limitingFactors.SELECT}>{limitingFactorsFriendly.SELECT}</MenuItem>
                  <MenuItem value={limitingFactors.MUSCLETIGHTNESS}>{limitingFactorsFriendly.MUSCLETIGHTNESS}</MenuItem>
                  <MenuItem value={limitingFactors.JOINTSTIFFNESS}>{limitingFactorsFriendly.JOINTSTIFFNESS}</MenuItem>
                  <MenuItem value={limitingFactors.PAIN}>{limitingFactorsFriendly.PAIN}</MenuItem>
                </Select>
                {/* This is about as disgusting as it gets. However this is still better than
                    replicating the contents of this component many times inside the MSK forms.
                    Think of the children! */}
                <input type="text" style={{ visibility: "hidden" }} name={datasetName + "_limitingFactor"} value={state[`${datasetName}_limitingFactor`]} ref={props.register} />
              </FormControl>
            </div>
            {state[`${datasetName}_limitingFactor`] === limitingFactors.MUSCLETIGHTNESS || state[`${datasetName}_limitingFactor`] === limitingFactors.JOINTSTIFFNESS ? (
              <>
                <div>
                  <FormLabel component="legend">Limiting Factor Notes</FormLabel>
                  <TextField
                    label="Write something"
                    value={state[`${datasetName}_limitingFactorNotes`]}
                    name={datasetName + "_limitingFactorNotes"}
                    multiline
                    fullWidth
                    variant="outlined"
                    className="outlineTextArea"
                    inputRef={props.register}
                    onChange={(event) => {
                      event.persist();
                      setState((prevState) => ({
                        ...prevState,
                        [event.target.name]: event.target.value,
                      }));
                    }}
                  />
                </div>
              </>
            ) : (
              <></>
            )}
            {state[`${datasetName}_limitingFactor`] === limitingFactors.PAIN ? (
              <ParticipantReports
                datasetName={datasetName}
                showParticipantsReport={false}
                data={props.data}
                register={props.register}
                errors={props.errors}
                setValue={props.setValue}
                assessmentID={props.assessmentID}
                dataKey={props.dataKey}
              />
            ) : (
              <></>
            )}
            <div className="invalid-feedback">{props.errors[datasetName]?.message}</div>
          </FormGroup>
        </div>
      </Grid>
    );
  })
);

export default MobilityTestComponent;

import React, { Component, ReactNode } from "react";
import { TimerIcon } from "../../assets/images";

import { Button } from "@material-ui/core";

interface TimerProps {
  countUp: boolean;
  timeTarget: string;
  timerHandler?: any;
  timerStoppedHandler?: any;
  timerCompletedHandler?: any;
  timerRemoteTrigger: boolean;
  timerRemoteResumeTrigger?: boolean;
  isMetronomeRunning?: boolean;
  isRecoveryTimerRunning?: boolean;
}

interface TimerState {
  minutes: number;
  seconds: number;
  totalSeconds: number;
  currentSeconds: number;
  finalSeconds: number;
  formattedTime: string;
  isTimerRunning: boolean;
  resumingTimer: boolean;
}

class Timer extends Component<TimerProps, TimerState> {
  timer: NodeJS.Timeout | undefined;
  timerButton: React.RefObject<any>;

  public static defaultProps = {
    isMetronomeRunning: true,
  };

  constructor(props: TimerProps) {
    super(props);

    const timeParts = this.props.timeTarget.split(":");

    const calculatedSeconds = this.calculateSeconds(parseInt(timeParts[0]), parseInt(timeParts[1]));

    this.state = {
      minutes: 0,
      seconds: 0,
      totalSeconds: calculatedSeconds,
      currentSeconds: !this.props.countUp ? calculatedSeconds : 0,
      finalSeconds: 0,
      formattedTime: "",
      isTimerRunning: false,
      resumingTimer: false,
    };

    this.timerButton = React.createRef();
    this.timer = undefined;

    this.handleTimerClick = this.handleTimerClick.bind(this);
    this.handleResumeClick = this.handleResumeClick.bind(this);
    this.resumeTimer = this.resumeTimer.bind(this);
  }

  calculateSeconds = (minutes: number, seconds: number): number => {
    return minutes * 60 + seconds;
  };

  startTimer = (): void => {
    if (!this.props.isRecoveryTimerRunning) {
      this.timer = setInterval(this.iterateTimer, 1000);
    }
  };

  resumeTimer = (): void => {
    //If for some reason there is a timer here. Kill it.
    //For some reason there can be a double timer scenario when trying to resume from the existing time.
    if (this.timer) {
      clearInterval(this.timer);
    }

    this.timer = setInterval(this.iterateTimer, 1000);
  };

  stopTimer = (): void => {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = undefined;
    }
  };

  resetTimer = (): void => {
    this.setState((prevState) => ({
      ...prevState,
      finalSeconds: this.state.currentSeconds,
      isTimerRunning: false,
      currentSeconds: !this.props.countUp ? this.state.totalSeconds : 0,
    }));
  };

  shouldComponentUpdate(nextProps: Readonly<TimerProps>, nextState: Readonly<TimerState>): boolean {
    if (nextProps.timeTarget !== this.props.timeTarget) {
      const timeParts = nextProps.timeTarget.split(":");

      const calculatedSeconds = this.calculateSeconds(parseInt(timeParts[0]), parseInt(timeParts[1]));

      this.setState((prevState) => ({
        ...prevState,
        totalSeconds: calculatedSeconds,
        currentSeconds: !this.props.countUp ? calculatedSeconds : 0,
      }));
      return true;
    }

    if (nextState.resumingTimer) {
      this.resumeTimer();
      return true;
    }

    if (nextState.currentSeconds !== this.state.currentSeconds) {
      return true;
    }
    return false;
  }

  iterateTimer = (): void => {
    let { currentSeconds, isTimerRunning } = this.state;
    if (
      (!this.props.countUp && currentSeconds === this.state.totalSeconds) || //if we are counting down and no time has elapsed, continue.
      (this.props.countUp && currentSeconds === 0) || //if we are counting up and no time has elapsed, continue.
      (this.state.resumingTimer && currentSeconds === this.state.finalSeconds) || //if the resumingTimer Flag is true.
      isTimerRunning //if the timer is running, continue.
    ) {
      if (this.props.countUp) {
        currentSeconds++;
        isTimerRunning = !(currentSeconds === this.state.totalSeconds);
      } else {
        if (currentSeconds === 0) {
          currentSeconds = this.state.totalSeconds;
        }
        currentSeconds--;
        isTimerRunning = !(currentSeconds === 0);
      }

      const minutes = Math.floor(currentSeconds / 60);
      const seconds = currentSeconds % 60;

      const formattedSeconds = seconds < 10 && minutes ? `0${seconds}` : seconds.toString();
      const formattedMinutes = minutes > 0 ? `${minutes}:` : "";
      const formattedTime = `${formattedMinutes}${formattedSeconds}`;

      this.setState((prevState) => ({
        ...prevState,
        resumingTimer: false,
        currentSeconds,
        minutes,
        seconds,
        isTimerRunning,
        formattedTime,
      }));

      if (!!this.props.timerHandler) {
        this.props.timerHandler(currentSeconds, formattedTime);
      }
    } else {
      this.stopTimer();
      if (!!this.props.timerCompletedHandler) {
        this.props.timerCompletedHandler();
      }
      this.resetTimer();
    }
  };

  handleTimerClick = (): void => {
    if (this.props.isMetronomeRunning && !this.state.isTimerRunning && !this.timer) {
      this.startTimer();
      return;
    } else if (this.state.isTimerRunning && !!this.timer) {
      this.stopTimer();
      if (!!this.props.timerStoppedHandler) {
        this.props.timerStoppedHandler();
      }
      this.resetTimer();
    }
  };

  handleResumeClick = (): void => {
    this.setState((prevState) => ({
      ...prevState,
      resumingTimer: true,
      currentSeconds: prevState.finalSeconds,
    }));
  };

  componentWillReceiveProps(nextProps: TimerProps): void {
    if (nextProps.timerRemoteTrigger !== this.props.timerRemoteTrigger) {
      if (nextProps.timerRemoteTrigger) {
        this.handleTimerClick();
      }
    }

    if (nextProps.timerRemoteResumeTrigger !== this.props.timerRemoteResumeTrigger) {
      if (nextProps.timerRemoteResumeTrigger) {
        this.handleResumeClick();
      }
    }
  }

  render(): ReactNode {
    const { formattedTime, finalSeconds, currentSeconds } = this.state;
    return (
      <div className={this.timer ? "timerContainer redBackground" : "timerContainer"}>
        <Button className="timerBtn" onClick={this.handleTimerClick} onDoubleClick={this.handleTimerClick}>
          {(!this.timer && !formattedTime) || !!this.timer ? (
            <>
              <img src={TimerIcon} width="24" alt="Timer Icon" />
              <div>
                {this.timer ? (
                  <span
                    style={{
                      color: "#FFFFFF",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    {currentSeconds < 60 ? `${formattedTime} sec` : formattedTime}
                  </span>
                ) : (
                  <span>&nbsp;</span>
                )}
              </div>
              <div style={{ marginTop: "29px" }}>{!!this.timer ? <span className="metronomeStop">STOP</span> : "Tap to start timer"}</div>
            </>
          ) : (
            <></>
          )}

          <div>
            {!this.timer && formattedTime ? (
              <>
                <div style={{ color: "#222E50", fontWeight: "normal" }}>Timer Stopped</div>
                <div>
                  <span
                    style={{
                      color: "#222E50",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    {finalSeconds < 60 ? `${formattedTime}sec` : formattedTime}
                  </span>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </Button>
      </div>
    );
  }
}
export default Timer;

import { types, Instance, SnapshotOut, applySnapshot } from "mobx-state-tree";
import { Constants } from "../helpers/Constants";
import localStoreService from "../services/LocalStoreService";
import { PefaStore } from "./pefaStore";
import { UserStore } from "./userStore";
import { DataStore } from "./dataStore";

const RootStore = types
  .model("Root", {
    userStore: UserStore,
    pefaStore: PefaStore,
    dataStore: DataStore,
  })
  .actions((self) => ({
    async afterCreate() {
      const data = await localStoreService.getData(Constants.rootStoreKey);
      if (data) {
        applySnapshot(self, data);
      }
    },
  }));

export { RootStore };

export type Root = Instance<typeof RootStore> | null;
export type RootSnapshot = SnapshotOut<typeof RootStore>;

export type User = Instance<typeof UserStore>;
export type Pefa = Instance<typeof PefaStore>;

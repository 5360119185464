import * as yup from "yup";
import { limitingFactors } from "../../../components/groupedComponents/MobilityTestDataKeys";

const musculoskeletalValidation = yup.object().shape({
  neckMobility: yup.bool().test("neckMobility", "Please provide feedback on neck mobility.", function () {
    let valid = false;
    if (
      !valid &&
      !this.parent.neckMobilityPainFree &&
      (!this.parent.headDown_unable || !this.parent.headDown_lessThanOneThird || !this.parent.headDown_lessThanTwoThirds || !this.parent.headDown_greaterThanTwoThirds) &&
      !this.parent.headDown_objectiveRange &&
      this.parent.headDown_limitingFactor === "SELECT" &&
      !this.parent.headDown_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.neckMobilityPainFree &&
      (!this.parent.headUp_unable || !this.parent.headUp_lessThanOneThird || !this.parent.headUp_lessThanTwoThirds || !this.parent.headUp_greaterThanTwoThirds) &&
      !this.parent.headUp_objectiveRange &&
      this.parent.headUp_limitingFactor === "SELECT" &&
      !this.parent.headUp_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.neckMobilityPainFree &&
      (!this.parent.rotateLeft_unable || !this.parent.rotateLeft_lessThanOneThird || !this.parent.rotateLeft_lessThanTwoThirds || !this.parent.rotateLeft_greaterThanTwoThirds) &&
      !this.parent.rotateLeft_objectiveRange &&
      this.parent.rotateLeft_limitingFactor === "SELECT" &&
      !this.parent.rotateLeft_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.neckMobilityPainFree &&
      (!this.parent.rotateRight_unable ||
        !this.parent.rotateRight_lessThanOneThird ||
        !this.parent.rotateRight_lessThanTwoThirds ||
        !this.parent.rotateRight_greaterThanTwoThirds) &&
      !this.parent.rotateRight_objectiveRange &&
      this.parent.rotateRight_limitingFactor === "SELECT" &&
      !this.parent.rotateRight_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    return valid;
  }),
  upperLimbMobility: yup.bool().test("upperLimbMobility", "Please provide feedback on upper limb mobility.", function () {
    let valid = false;
    if (
      !valid &&
      !this.parent.upperLimbMobilityPainFree &&
      (!this.parent.leftShoulderFlexion_unable ||
        !this.parent.leftShoulderFlexion_lessThanOneThird ||
        !this.parent.leftShoulderFlexion_lessThanTwoThirds ||
        !this.parent.leftShoulderFlexion_greaterThanTwoThirds) &&
      !this.parent.leftShoulderFlexion_objectiveRange &&
      this.parent.leftShoulderFlexion_limitingFactor === "SELECT" &&
      !this.parent.leftShoulderFlexion_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.upperLimbMobilityPainFree &&
      (!this.parent.leftShoulderAbduction_unable ||
        !this.parent.leftShoulderAbduction_lessThanOneThird ||
        !this.parent.leftShoulderAbduction_lessThanTwoThirds ||
        !this.parent.leftShoulderAbduction_greaterThanTwoThirds) &&
      !this.parent.leftShoulderAbduction_objectiveRange &&
      this.parent.leftShoulderAbduction_limitingFactor === "SELECT" &&
      !this.parent.leftShoulderAbduction_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.upperLimbMobilityPainFree &&
      (!this.parent.leftShoulderHandBehindHead_unable ||
        !this.parent.leftShoulderHandBehindHead_lessThanOneThird ||
        !this.parent.leftShoulderHandBehindHead_lessThanTwoThirds ||
        !this.parent.leftShoulderHandBehindHead_greaterThanTwoThirds) &&
      !this.parent.leftShoulderHandBehindHead_objectiveRange &&
      this.parent.leftShoulderHandBehindHead_limitingFactor === "SELECT" &&
      !this.parent.leftShoulderHandBehindHead_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.upperLimbMobilityPainFree &&
      (!this.parent.leftShoulderHandBehindBack_unable ||
        !this.parent.leftShoulderHandBehindBack_lessThanOneThird ||
        !this.parent.leftShoulderHandBehindBack_lessThanTwoThirds ||
        !this.parent.leftShoulderHandBehindBack_greaterThanTwoThirds) &&
      !this.parent.leftShoulderHandBehindBack_objectiveRange &&
      this.parent.leftShoulderHandBehindBack_limitingFactor === "SELECT" &&
      !this.parent.leftShoulderHandBehindBack_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.upperLimbMobilityPainFree &&
      (!this.parent.leftElbowForearmExtension_unable ||
        !this.parent.leftElbowForearmExtension_lessThanOneThird ||
        !this.parent.leftElbowForearmExtension_lessThanTwoThirds ||
        !this.parent.leftElbowForearmExtension_greaterThanTwoThirds) &&
      !this.parent.leftElbowForearmExtension_objectiveRange &&
      this.parent.leftElbowForearmExtension_limitingFactor === "SELECT" &&
      !this.parent.leftElbowForearmExtension_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.upperLimbMobilityPainFree &&
      (!this.parent.leftElbowForearmFlexion_unable ||
        !this.parent.leftElbowForearmFlexion_lessThanOneThird ||
        !this.parent.leftElbowForearmFlexion_lessThanTwoThirds ||
        !this.parent.leftElbowForearmFlexion_greaterThanTwoThirds) &&
      !this.parent.leftElbowForearmFlexion_objectiveRange &&
      this.parent.leftElbowForearmFlexion_limitingFactor === "SELECT" &&
      !this.parent.leftElbowForearmFlexion_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.upperLimbMobilityPainFree &&
      (!this.parent.leftElbowForearmSupination_unable ||
        !this.parent.leftElbowForearmSupination_lessThanOneThird ||
        !this.parent.leftElbowForearmSupination_lessThanTwoThirds ||
        !this.parent.leftElbowForearmSupination_greaterThanTwoThirds) &&
      !this.parent.leftElbowForearmSupination_objectiveRange &&
      this.parent.leftElbowForearmSupination_limitingFactor === "SELECT" &&
      !this.parent.leftElbowForearmSupination_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.upperLimbMobilityPainFree &&
      (!this.parent.leftWristHandExtension_unable ||
        !this.parent.leftWristHandExtension_lessThanOneThird ||
        !this.parent.leftWristHandExtension_lessThanTwoThirds ||
        !this.parent.leftWristHandExtension_greaterThanTwoThirds) &&
      !this.parent.leftWristHandExtension_objectiveRange &&
      this.parent.leftWristHandExtension_limitingFactor === "SELECT" &&
      !this.parent.leftWristHandExtension_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.upperLimbMobilityPainFree &&
      (!this.parent.leftWristHandFlexion_unable ||
        !this.parent.leftWristHandFlexion_lessThanOneThird ||
        !this.parent.leftWristHandFlexion_lessThanTwoThirds ||
        !this.parent.leftWristHandFlexion_greaterThanTwoThirds) &&
      !this.parent.leftWristHandFlexion_objectiveRange &&
      this.parent.leftWristHandFlexion_limitingFactor === "SELECT" &&
      !this.parent.leftWristHandFlexion_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.upperLimbMobilityPainFree &&
      (!this.parent.leftWristHandRadialDeviation_unable ||
        !this.parent.leftWristHandRadialDeviation_lessThanOneThird ||
        !this.parent.leftWristHandRadialDeviation_lessThanTwoThirds ||
        !this.parent.leftWristHandRadialDeviation_greaterThanTwoThirds) &&
      !this.parent.leftWristHandRadialDeviation_objectiveRange &&
      this.parent.leftWristHandRadialDeviation_limitingFactor === "SELECT" &&
      !this.parent.leftWristHandRadialDeviation_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.upperLimbMobilityPainFree &&
      (!this.parent.leftWristHandUlnarDeviation_unable ||
        !this.parent.leftWristHandUlnarDeviation_lessThanOneThird ||
        !this.parent.leftWristHandUlnarDeviation_lessThanTwoThirds ||
        !this.parent.leftWristHandUlnarDeviation_greaterThanTwoThirds) &&
      !this.parent.leftWristHandUlnarDeviation_objectiveRange &&
      this.parent.leftWristHandUlnarDeviation_limitingFactor === "SELECT" &&
      !this.parent.leftWristHandUlnarDeviation_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.upperLimbMobilityPainFree &&
      (!this.parent.leftHandFingerOppositionToThumb_unable ||
        !this.parent.leftHandFingerOppositionToThumb_lessThanOneThird ||
        !this.parent.leftHandFingerOppositionToThumb_lessThanTwoThirds ||
        !this.parent.leftHandFingerOppositionToThumb_greaterThanTwoThirds) &&
      !this.parent.leftHandFingerOppositionToThumb_objectiveRange &&
      this.parent.leftHandFingerOppositionToThumb_limitingFactor === "SELECT" &&
      !this.parent.leftHandFingerOppositionToThumb_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.upperLimbMobilityPainFree &&
      (!this.parent.leftHandFingerFullFist_unable ||
        !this.parent.leftHandFingerFullFist_lessThanOneThird ||
        !this.parent.leftHandFingerFullFist_lessThanTwoThirds ||
        !this.parent.leftHandFingerFullFist_greaterThanTwoThirds) &&
      !this.parent.leftHandFingerFullFist_objectiveRange &&
      this.parent.leftHandFingerFullFist_limitingFactor === "SELECT" &&
      !this.parent.leftHandFingerFullFist_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.upperLimbMobilityPainFree &&
      (!this.parent.rightShoulderFlexion_unable ||
        !this.parent.rightShoulderFlexion_lessThanOneThird ||
        !this.parent.rightShoulderFlexion_lessThanTwoThirds ||
        !this.parent.rightShoulderFlexion_greaterThanTwoThirds) &&
      !this.parent.rightShoulderFlexion_objectiveRange &&
      this.parent.rightShoulderFlexion_limitingFactor === "SELECT" &&
      !this.parent.rightShoulderFlexion_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.upperLimbMobilityPainFree &&
      (!this.parent.rightShoulderAbduction_unable ||
        !this.parent.rightShoulderAbduction_lessThanOneThird ||
        !this.parent.rightShoulderAbduction_lessThanTwoThirds ||
        !this.parent.rightShoulderAbduction_greaterThanTwoThirds) &&
      !this.parent.rightShoulderAbduction_objectiveRange &&
      this.parent.rightShoulderAbduction_limitingFactor === "SELECT" &&
      !this.parent.rightShoulderAbduction_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.upperLimbMobilityPainFree &&
      (!this.parent.rightShoulderHandBehindHead_unable ||
        !this.parent.rightShoulderHandBehindHead_lessThanOneThird ||
        !this.parent.rightShoulderHandBehindHead_lessThanTwoThirds ||
        !this.parent.rightShoulderHandBehindHead_greaterThanTwoThirds) &&
      !this.parent.rightShoulderHandBehindHead_objectiveRange &&
      this.parent.rightShoulderHandBehindHead_limitingFactor === "SELECT" &&
      !this.parent.rightShoulderHandBehindHead_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.upperLimbMobilityPainFree &&
      (!this.parent.rightShoulderHandBehindBack_unable ||
        !this.parent.rightShoulderHandBehindBack_lessThanOneThird ||
        !this.parent.rightShoulderHandBehindBack_lessThanTwoThirds ||
        !this.parent.rightShoulderHandBehindBack_greaterThanTwoThirds) &&
      !this.parent.rightShoulderHandBehindBack_objectiveRange &&
      this.parent.rightShoulderHandBehindBack_limitingFactor === "SELECT" &&
      !this.parent.rightShoulderHandBehindBack_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.upperLimbMobilityPainFree &&
      (!this.parent.rightElbowForearmExtension_unable ||
        !this.parent.rightElbowForearmExtension_lessThanOneThird ||
        !this.parent.rightElbowForearmExtension_lessThanTwoThirds ||
        !this.parent.rightElbowForearmExtension_greaterThanTwoThirds) &&
      !this.parent.rightElbowForearmExtension_objectiveRange &&
      this.parent.rightElbowForearmExtension_limitingFactor === "SELECT" &&
      !this.parent.rightElbowForearmExtension_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.upperLimbMobilityPainFree &&
      (!this.parent.rightElbowForearmFlexion_unable ||
        !this.parent.rightElbowForearmFlexion_lessThanOneThird ||
        !this.parent.rightElbowForearmFlexion_lessThanTwoThirds ||
        !this.parent.rightElbowForearmFlexion_greaterThanTwoThirds) &&
      !this.parent.rightElbowForearmFlexion_objectiveRange &&
      this.parent.rightElbowForearmFlexion_limitingFactor === "SELECT" &&
      !this.parent.rightElbowForearmFlexion_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.upperLimbMobilityPainFree &&
      (!this.parent.rightElbowForearmSupination_unable ||
        !this.parent.rightElbowForearmSupination_lessThanOneThird ||
        !this.parent.rightElbowForearmSupination_lessThanTwoThirds ||
        !this.parent.rightElbowForearmSupination_greaterThanTwoThirds) &&
      !this.parent.rightElbowForearmSupination_objectiveRange &&
      this.parent.rightElbowForearmSupination_limitingFactor === "SELECT" &&
      !this.parent.rightElbowForearmSupination_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.upperLimbMobilityPainFree &&
      (!this.parent.rightWristHandExtension_unable ||
        !this.parent.rightWristHandExtension_lessThanOneThird ||
        !this.parent.rightWristHandExtension_lessThanTwoThirds ||
        !this.parent.rightWristHandExtension_greaterThanTwoThirds) &&
      !this.parent.rightWristHandExtension_objectiveRange &&
      this.parent.rightWristHandExtension_limitingFactor === "SELECT" &&
      !this.parent.rightWristHandExtension_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.upperLimbMobilityPainFree &&
      (!this.parent.rightWristHandFlexion_unable ||
        !this.parent.rightWristHandFlexion_lessThanOneThird ||
        !this.parent.rightWristHandFlexion_lessThanTwoThirds ||
        !this.parent.rightWristHandFlexion_greaterThanTwoThirds) &&
      !this.parent.rightWristHandFlexion_objectiveRange &&
      this.parent.rightWristHandFlexion_limitingFactor === "SELECT" &&
      !this.parent.rightWristHandFlexion_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.upperLimbMobilityPainFree &&
      (!this.parent.rightWristHandRadialDeviation_unable ||
        !this.parent.rightWristHandRadialDeviation_lessThanOneThird ||
        !this.parent.rightWristHandRadialDeviation_lessThanTwoThirds ||
        !this.parent.rightWristHandRadialDeviation_greaterThanTwoThirds) &&
      !this.parent.rightWristHandRadialDeviation_objectiveRange &&
      this.parent.rightWristHandRadialDeviation_limitingFactor === "SELECT" &&
      !this.parent.rightWristHandRadialDeviation_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.upperLimbMobilityPainFree &&
      (!this.parent.rightWristHandUlnarDeviation_unable ||
        !this.parent.rightWristHandUlnarDeviation_lessThanOneThird ||
        !this.parent.rightWristHandUlnarDeviation_lessThanTwoThirds ||
        !this.parent.rightWristHandUlnarDeviation_greaterThanTwoThirds) &&
      !this.parent.rightWristHandUlnarDeviation_objectiveRange &&
      this.parent.rightWristHandUlnarDeviation_limitingFactor === "SELECT" &&
      !this.parent.rightWristHandUlnarDeviation_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.upperLimbMobilityPainFree &&
      (!this.parent.rightHandFingerOppositionToThumb_unable ||
        !this.parent.rightHandFingerOppositionToThumb_lessThanOneThird ||
        !this.parent.rightHandFingerOppositionToThumb_lessThanTwoThirds ||
        !this.parent.rightHandFingerOppositionToThumb_greaterThanTwoThirds) &&
      !this.parent.rightHandFingerOppositionToThumb_objectiveRange &&
      this.parent.rightHandFingerOppositionToThumb_limitingFactor === "SELECT" &&
      !this.parent.rightHandFingerOppositionToThumb_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.upperLimbMobilityPainFree &&
      (!this.parent.rightHandFingerFullFist_unable ||
        !this.parent.rightHandFingerFullFist_lessThanOneThird ||
        !this.parent.rightHandFingerFullFist_lessThanTwoThirds ||
        !this.parent.rightHandFingerFullFist_greaterThanTwoThirds) &&
      !this.parent.rightHandFingerFullFist_objectiveRange &&
      this.parent.rightHandFingerFullFist_limitingFactor === "SELECT" &&
      !this.parent.rightHandFingerFullFist_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    return valid;
  }),
  upperLimbStrength: yup.bool().test("upperLimbStrength", "Please provide feedback on upper limb strength.", function () {
    //By default, we want to assume the fields are not valid for this test.
    //Once a value is found under the upper limb strength group, this test will consider the data valid.

    let valid = false;

    if (!valid && !this.parent.upperLimbStrengthPainFree && this.parent.leftShoulderStrengthFlexion_discomfort === "SELECT") {
      valid = false;
    } else valid = true;

    if (!valid && !this.parent.upperLimbStrengthPainFree && this.parent.leftShoulderStrengthAbduction_discomfort === "SELECT") {
      valid = false;
    } else valid = true;

    if (!valid && !this.parent.upperLimbStrengthPainFree && this.parent.leftShoulderStrengthInternalRotation_discomfort === "SELECT") {
      valid = false;
    } else valid = true;

    if (!valid && !this.parent.upperLimbStrengthPainFree && this.parent.leftShoulderStrengthExternalRotation_discomfort === "SELECT") {
      valid = false;
    } else valid = true;

    if (!valid && !this.parent.upperLimbStrengthPainFree && this.parent.leftElbowStrengthFlexion_discomfort === "SELECT") {
      valid = false;
    } else valid = true;

    if (!valid && !this.parent.upperLimbStrengthPainFree && this.parent.leftElbowStrengthExtension_discomfort === "SELECT") {
      valid = false;
    } else valid = true;

    if (!valid && !this.parent.upperLimbStrengthPainFree && this.parent.leftElbowStrengthSupination_discomfort === "SELECT") {
      valid = false;
    } else valid = true;

    if (!valid && !this.parent.upperLimbStrengthPainFree && this.parent.leftThumbStrengthExtensions_discomfort === "SELECT") {
      valid = false;
    } else valid = true;

    if (!valid && !this.parent.upperLimbStrengthPainFree && this.parent.leftThumbStrengthLumbricals_discomfort === "SELECT") {
      valid = false;
    } else valid = true;

    if (!valid && !this.parent.upperLimbStrengthPainFree && this.parent.rightShoulderStrengthFlexion_discomfort === "SELECT") {
      valid = false;
    } else valid = true;

    if (!valid && !this.parent.upperLimbStrengthPainFree && this.parent.rightShoulderStrengthAbduction_discomfort === "SELECT") {
      valid = false;
    } else valid = true;

    if (!valid && !this.parent.upperLimbStrengthPainFree && this.parent.rightShoulderStrengthInternalRotation_discomfort === "SELECT") {
      valid = false;
    } else valid = true;

    if (!valid && !this.parent.upperLimbStrengthPainFree && this.parent.rightShoulderStrengthExternalRotation_discomfort === "SELECT") {
      valid = false;
    } else valid = true;

    if (!valid && !this.parent.upperLimbStrengthPainFree && this.parent.rightElbowStrengthFlexion_discomfort === "SELECT") {
      valid = false;
    } else valid = true;

    if (!valid && !this.parent.upperLimbStrengthPainFree && this.parent.rightElbowStrengthExtension_discomfort === "SELECT") {
      valid = false;
    } else valid = true;

    if (!valid && !this.parent.upperLimbStrengthPainFree && this.parent.rightElbowStrengthExtension_discomfort === "SELECT") {
      valid = false;
    } else valid = true;

    if (!valid && !this.parent.upperLimbStrengthPainFree && this.parent.rightThumbStrengthExtensions_discomfort === "SELECT") {
      valid = false;
    } else valid = true;

    if (!valid && !this.parent.upperLimbStrengthPainFree && this.parent.rightThumbStrengthLumbricals_discomfort === "SELECT") {
      valid = false;
    } else valid = true;

    return valid;
  }),
  headDown: yup.bool().test("headDown", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      neckMobilityPainFree,
      headDown_unable,
      headDown_lessThanOneThird,
      headDown_lessThanTwoThirds,
      headDown_greaterThanTwoThirds,
      headDown_objectiveRange,
      headDown_limitingFactor,
      headDown_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (neckMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (headDown_unable || headDown_lessThanOneThird || headDown_lessThanTwoThirds || headDown_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!headDown_objectiveRange ||
        //or the limiting factor has not been selected,
        headDown_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((headDown_limitingFactor === "MUSCLETIGHTNESS" || headDown_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !headDown_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are unset
      (!!headDown_objectiveRange || headDown_limitingFactor !== "SELECT" || !!headDown_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !headDown_unable &&
      !headDown_lessThanOneThird &&
      !headDown_lessThanTwoThirds &&
      !headDown_greaterThanTwoThirds
    ) {
      //then the headDown fields are not valid.
      return false;
    }

    return true;
  }),
  headDown_location: yup.string().test("headDown_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.headDown_limitingFactor === limitingFactors.PAIN && this.parent.headDown_location === "") {
      return false;
    }

    return true;
  }),

  headDown_intensity: yup.bool().test("headDown_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.headDown_limitingFactor === limitingFactors.PAIN &&
      this.parent.headDown_intensityVas === "" &&
      this.parent.headDown_intensityMild === false &&
      this.parent.headDown_intensityMod === false &&
      this.parent.headDown_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  headDown_type: yup.bool().test("headDown_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.headDown_limitingFactor === limitingFactors.PAIN &&
      this.parent.headDown_discomfortTypeAche === false &&
      this.parent.headDown_discomfortTypeSharp === false &&
      this.parent.headDown_discomfortTypeBurning === false &&
      this.parent.headDown_discomfortTypeTightness === false &&
      this.parent.headDown_discomfortTypePulling === false &&
      this.parent.headDown_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  headDown_typeOther: yup.string().test("headDown_typeOther", "Please describe the discomfort further.", function () {
    if (this.parent.headDown_limitingFactor === limitingFactors.PAIN && this.parent.headDown_discomfortTypeOther === true && this.parent.headDown_typeOther === "") {
      return false;
    }

    return true;
  }),

  headDown_status: yup.bool().test("headDown_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.headDown_limitingFactor === limitingFactors.PAIN &&
      this.parent.headDown_discomfortResolvedImmediately === false &&
      this.parent.headDown_discomfortPersisting === false &&
      this.parent.headDown_discomfortResolvedWithRest === false &&
      this.parent.headDown_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  headUp: yup.bool().test("headUp", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      neckMobilityPainFree,
      headUp_unable,
      headUp_lessThanOneThird,
      headUp_lessThanTwoThirds,
      headUp_greaterThanTwoThirds,
      headUp_objectiveRange,
      headUp_limitingFactor,
      headUp_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (neckMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (headUp_unable || headUp_lessThanOneThird || headUp_lessThanTwoThirds || headUp_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!headUp_objectiveRange ||
        //or the limiting factor has not been selected,
        headUp_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((headUp_limitingFactor === "MUSCLETIGHTNESS" || headUp_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !headUp_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are unset
      (!!headUp_objectiveRange || headUp_limitingFactor !== "SELECT" || !!headUp_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !headUp_unable &&
      !headUp_lessThanOneThird &&
      !headUp_lessThanTwoThirds &&
      !headUp_greaterThanTwoThirds
    ) {
      //then the headUp fields are not valid.
      return false;
    }

    return true;
  }),
  headUp_location: yup.string().test("headUp_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.headUp_limitingFactor === limitingFactors.PAIN && this.parent.headUp_location === "") {
      return false;
    }

    return true;
  }),

  headUp_intensity: yup.bool().test("headUp_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.headUp_limitingFactor === limitingFactors.PAIN &&
      this.parent.headUp_intensityVas === "" &&
      this.parent.headUp_intensityMild === false &&
      this.parent.headUp_intensityMod === false &&
      this.parent.headUp_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  headUp_type: yup.bool().test("headUp_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.headUp_limitingFactor === limitingFactors.PAIN &&
      this.parent.headUp_discomfortTypeAche === false &&
      this.parent.headUp_discomfortTypeSharp === false &&
      this.parent.headUp_discomfortTypeBurning === false &&
      this.parent.headUp_discomfortTypeTightness === false &&
      this.parent.headUp_discomfortTypePulling === false &&
      this.parent.headUp_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  headUp_typeOther: yup.string().test("headUp_typeOther", "Please describe the discomfort further.", function () {
    if (this.parent.headUp_limitingFactor === limitingFactors.PAIN && this.parent.headUp_discomfortTypeOther === true && this.parent.headUp_typeOther === "") {
      return false;
    }

    return true;
  }),

  headUp_status: yup.bool().test("headUp_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.headUp_limitingFactor === limitingFactors.PAIN &&
      this.parent.headUp_discomfortResolvedImmediately === false &&
      this.parent.headUp_discomfortPersisting === false &&
      this.parent.headUp_discomfortResolvedWithRest === false &&
      this.parent.headUp_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  rotateLeft: yup.bool().test("rotateLeft", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      neckMobilityPainFree,
      rotateLeft_unable,
      rotateLeft_lessThanOneThird,
      rotateLeft_lessThanTwoThirds,
      rotateLeft_greaterThanTwoThirds,
      rotateLeft_objectiveRange,
      rotateLeft_limitingFactor,
      rotateLeft_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (neckMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (rotateLeft_unable || rotateLeft_lessThanOneThird || rotateLeft_lessThanTwoThirds || rotateLeft_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!rotateLeft_objectiveRange ||
        //or the limiting factor has not been selected,
        rotateLeft_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((rotateLeft_limitingFactor === "MUSCLETIGHTNESS" || rotateLeft_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !rotateLeft_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are unset
      (!!rotateLeft_objectiveRange || rotateLeft_limitingFactor !== "SELECT" || !!rotateLeft_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !rotateLeft_unable &&
      !rotateLeft_lessThanOneThird &&
      !rotateLeft_lessThanTwoThirds &&
      !rotateLeft_greaterThanTwoThirds
    ) {
      //then the rotateLeft fields are not valid.
      return false;
    }

    return true;
  }),
  rotateLeft_location: yup.string().test("rotateLeft_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.rotateLeft_limitingFactor === limitingFactors.PAIN && this.parent.rotateLeft_location === "") {
      return false;
    }

    return true;
  }),

  rotateLeft_intensity: yup.bool().test("rotateLeft_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.rotateLeft_limitingFactor === limitingFactors.PAIN &&
      this.parent.rotateLeft_intensityVas === "" &&
      this.parent.rotateLeft_intensityMild === false &&
      this.parent.rotateLeft_intensityMod === false &&
      this.parent.rotateLeft_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  rotateLeft_type: yup.bool().test("rotateLeft_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.rotateLeft_limitingFactor === limitingFactors.PAIN &&
      this.parent.rotateLeft_discomfortTypeAche === false &&
      this.parent.rotateLeft_discomfortTypeSharp === false &&
      this.parent.rotateLeft_discomfortTypeBurning === false &&
      this.parent.rotateLeft_discomfortTypeTightness === false &&
      this.parent.rotateLeft_discomfortTypePulling === false &&
      this.parent.rotateLeft_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  rotateLeft_typeOther: yup.string().test("rotateLeft_typeOther", "Please describe the discomfort further.", function () {
    if (this.parent.rotateLeft_limitingFactor === limitingFactors.PAIN && this.parent.rotateLeft_discomfortTypeOther === true && this.parent.rotateLeft_typeOther === "") {
      return false;
    }

    return true;
  }),

  rotateLeft_status: yup.bool().test("rotateLeft_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.rotateLeft_limitingFactor === limitingFactors.PAIN &&
      this.parent.rotateLeft_discomfortResolvedImmediately === false &&
      this.parent.rotateLeft_discomfortPersisting === false &&
      this.parent.rotateLeft_discomfortResolvedWithRest === false &&
      this.parent.rotateLeft_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  rotateRight: yup.bool().test("rotateRight", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      neckMobilityPainFree,
      rotateRight_unable,
      rotateRight_lessThanOneThird,
      rotateRight_lessThanTwoThirds,
      rotateRight_greaterThanTwoThirds,
      rotateRight_objectiveRange,
      rotateRight_limitingFactor,
      rotateRight_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (neckMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (rotateRight_unable || rotateRight_lessThanOneThird || rotateRight_lessThanTwoThirds || rotateRight_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!rotateRight_objectiveRange ||
        //or the limiting factor has not been selected,
        rotateRight_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((rotateRight_limitingFactor === "MUSCLETIGHTNESS" || rotateRight_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !rotateRight_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are unset
      (!!rotateRight_objectiveRange || rotateRight_limitingFactor !== "SELECT" || !!rotateRight_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !rotateRight_unable &&
      !rotateRight_lessThanOneThird &&
      !rotateRight_lessThanTwoThirds &&
      !rotateRight_greaterThanTwoThirds
    ) {
      //then the rotateRight fields are not valid.
      return false;
    }

    return true;
  }),
  rotateRight_location: yup.string().test("rotateRight_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.rotateRight_limitingFactor === limitingFactors.PAIN && this.parent.rotateRight_location === "") {
      return false;
    }

    return true;
  }),

  rotateRight_intensity: yup.bool().test("rotateRight_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.rotateRight_limitingFactor === limitingFactors.PAIN &&
      this.parent.rotateRight_intensityVas === "" &&
      this.parent.rotateRight_intensityMild === false &&
      this.parent.rotateRight_intensityMod === false &&
      this.parent.rotateRight_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  rotateRight_type: yup.bool().test("rotateRight_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.rotateRight_limitingFactor === limitingFactors.PAIN &&
      this.parent.rotateRight_discomfortTypeAche === false &&
      this.parent.rotateRight_discomfortTypeSharp === false &&
      this.parent.rotateRight_discomfortTypeBurning === false &&
      this.parent.rotateRight_discomfortTypeTightness === false &&
      this.parent.rotateRight_discomfortTypePulling === false &&
      this.parent.rotateRight_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  rotateRight_typeOther: yup.string().test("rotateRight_typeOther", "Please describe the discomfort further.", function () {
    if (this.parent.rotateRight_limitingFactor === limitingFactors.PAIN && this.parent.rotateRight_discomfortTypeOther === true && this.parent.rotateRight_typeOther === "") {
      return false;
    }

    return true;
  }),

  rotateRight_status: yup.bool().test("rotateRight_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.rotateRight_limitingFactor === limitingFactors.PAIN &&
      this.parent.rotateRight_discomfortResolvedImmediately === false &&
      this.parent.rotateRight_discomfortPersisting === false &&
      this.parent.rotateRight_discomfortResolvedWithRest === false &&
      this.parent.rotateRight_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  leftShoulderFlexion: yup.bool().test("leftShoulderFlexion", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      upperLimbMobilityPainFree,
      leftShoulderFlexion_unable,
      leftShoulderFlexion_lessThanOneThird,
      leftShoulderFlexion_lessThanTwoThirds,
      leftShoulderFlexion_greaterThanTwoThirds,
      leftShoulderFlexion_objectiveRange,
      leftShoulderFlexion_limitingFactor,
      leftShoulderFlexion_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (upperLimbMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (leftShoulderFlexion_unable || leftShoulderFlexion_lessThanOneThird || leftShoulderFlexion_lessThanTwoThirds || leftShoulderFlexion_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!leftShoulderFlexion_objectiveRange ||
        //or the limiting factor has not been selected,
        leftShoulderFlexion_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((leftShoulderFlexion_limitingFactor === "MUSCLETIGHTNESS" || leftShoulderFlexion_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !leftShoulderFlexion_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are unset
      (!!leftShoulderFlexion_objectiveRange || leftShoulderFlexion_limitingFactor !== "SELECT" || !!leftShoulderFlexion_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !leftShoulderFlexion_unable &&
      !leftShoulderFlexion_lessThanOneThird &&
      !leftShoulderFlexion_lessThanTwoThirds &&
      !leftShoulderFlexion_greaterThanTwoThirds
    ) {
      //then the leftShoulderFlexion fields are not valid.
      return false;
    }

    return true;
  }),
  leftShoulderFlexion_location: yup.string().test("leftShoulderFlexion_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.leftShoulderFlexion_limitingFactor === limitingFactors.PAIN && this.parent.leftShoulderFlexion_location === "") {
      return false;
    }

    return true;
  }),

  leftShoulderFlexion_intensity: yup.bool().test("leftShoulderFlexion_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.leftShoulderFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftShoulderFlexion_intensityVas === "" &&
      this.parent.leftShoulderFlexion_intensityMild === false &&
      this.parent.leftShoulderFlexion_intensityMod === false &&
      this.parent.leftShoulderFlexion_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  leftShoulderFlexion_type: yup.bool().test("leftShoulderFlexion_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.leftShoulderFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftShoulderFlexion_discomfortTypeAche === false &&
      this.parent.leftShoulderFlexion_discomfortTypeSharp === false &&
      this.parent.leftShoulderFlexion_discomfortTypeBurning === false &&
      this.parent.leftShoulderFlexion_discomfortTypeTightness === false &&
      this.parent.leftShoulderFlexion_discomfortTypePulling === false &&
      this.parent.leftShoulderFlexion_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  leftShoulderFlexion_typeOther: yup.string().test("leftShoulderFlexion_typeOther", "Please describe the discomfort further.", function () {
    if (
      this.parent.leftShoulderFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftShoulderFlexion_discomfortTypeOther === true &&
      this.parent.leftShoulderFlexion_typeOther === ""
    ) {
      return false;
    }

    return true;
  }),

  leftShoulderFlexion_status: yup.bool().test("leftShoulderFlexion_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.leftShoulderFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftShoulderFlexion_discomfortResolvedImmediately === false &&
      this.parent.leftShoulderFlexion_discomfortPersisting === false &&
      this.parent.leftShoulderFlexion_discomfortResolvedWithRest === false &&
      this.parent.leftShoulderFlexion_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  leftShoulderAbduction: yup.bool().test("leftShoulderAbduction", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      upperLimbMobilityPainFree,
      leftShoulderAbduction_unable,
      leftShoulderAbduction_lessThanOneThird,
      leftShoulderAbduction_lessThanTwoThirds,
      leftShoulderAbduction_greaterThanTwoThirds,
      leftShoulderAbduction_objectiveRange,
      leftShoulderAbduction_limitingFactor,
      leftShoulderAbduction_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (upperLimbMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (leftShoulderAbduction_unable || leftShoulderAbduction_lessThanOneThird || leftShoulderAbduction_lessThanTwoThirds || leftShoulderAbduction_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!leftShoulderAbduction_objectiveRange ||
        //or the limiting factor has not been selected,
        leftShoulderAbduction_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((leftShoulderAbduction_limitingFactor === "MUSCLETIGHTNESS" || leftShoulderAbduction_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !leftShoulderAbduction_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are unset
      (!!leftShoulderAbduction_objectiveRange || leftShoulderAbduction_limitingFactor !== "SELECT" || !!leftShoulderAbduction_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !leftShoulderAbduction_unable &&
      !leftShoulderAbduction_lessThanOneThird &&
      !leftShoulderAbduction_lessThanTwoThirds &&
      !leftShoulderAbduction_greaterThanTwoThirds
    ) {
      //then the leftShoulderAbduction fields are not valid.
      return false;
    }

    return true;
  }),
  leftShoulderAbduction_location: yup.string().test("leftShoulderAbduction_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.leftShoulderAbduction_limitingFactor === limitingFactors.PAIN && this.parent.leftShoulderAbduction_location === "") {
      return false;
    }

    return true;
  }),

  leftShoulderAbduction_intensity: yup.bool().test("leftShoulderAbduction_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.leftShoulderAbduction_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftShoulderAbduction_intensityVas === "" &&
      this.parent.leftShoulderAbduction_intensityMild === false &&
      this.parent.leftShoulderAbduction_intensityMod === false &&
      this.parent.leftShoulderAbduction_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  leftShoulderAbduction_type: yup.bool().test("leftShoulderAbduction_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.leftShoulderAbduction_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftShoulderAbduction_discomfortTypeAche === false &&
      this.parent.leftShoulderAbduction_discomfortTypeSharp === false &&
      this.parent.leftShoulderAbduction_discomfortTypeBurning === false &&
      this.parent.leftShoulderAbduction_discomfortTypeTightness === false &&
      this.parent.leftShoulderAbduction_discomfortTypePulling === false &&
      this.parent.leftShoulderAbduction_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  leftShoulderAbduction_typeOther: yup.string().test("leftShoulderAbduction_typeOther", "Please describe the discomfort further.", function () {
    if (
      this.parent.leftShoulderAbduction_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftShoulderAbduction_discomfortTypeOther === true &&
      this.parent.leftShoulderAbduction_typeOther === ""
    ) {
      return false;
    }

    return true;
  }),

  leftShoulderAbduction_status: yup.bool().test("leftShoulderAbduction_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.leftShoulderAbduction_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftShoulderAbduction_discomfortResolvedImmediately === false &&
      this.parent.leftShoulderAbduction_discomfortPersisting === false &&
      this.parent.leftShoulderAbduction_discomfortResolvedWithRest === false &&
      this.parent.leftShoulderAbduction_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  leftShoulderHandBehindHead: yup.bool().test("leftShoulderHandBehindHead", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      upperLimbMobilityPainFree,
      leftShoulderHandBehindHead_unable,
      leftShoulderHandBehindHead_lessThanOneThird,
      leftShoulderHandBehindHead_lessThanTwoThirds,
      leftShoulderHandBehindHead_greaterThanTwoThirds,
      leftShoulderHandBehindHead_objectiveRange,
      leftShoulderHandBehindHead_limitingFactor,
      leftShoulderHandBehindHead_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (upperLimbMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (leftShoulderHandBehindHead_unable ||
        leftShoulderHandBehindHead_lessThanOneThird ||
        leftShoulderHandBehindHead_lessThanTwoThirds ||
        leftShoulderHandBehindHead_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!leftShoulderHandBehindHead_objectiveRange ||
        //or the limiting factor has not been selected,
        leftShoulderHandBehindHead_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((leftShoulderHandBehindHead_limitingFactor === "MUSCLETIGHTNESS" || leftShoulderHandBehindHead_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !leftShoulderHandBehindHead_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are unset
      (!!leftShoulderHandBehindHead_objectiveRange || leftShoulderHandBehindHead_limitingFactor !== "SELECT" || !!leftShoulderHandBehindHead_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !leftShoulderHandBehindHead_unable &&
      !leftShoulderHandBehindHead_lessThanOneThird &&
      !leftShoulderHandBehindHead_lessThanTwoThirds &&
      !leftShoulderHandBehindHead_greaterThanTwoThirds
    ) {
      //then the leftShoulderHandBehindHead fields are not valid.
      return false;
    }

    return true;
  }),
  leftShoulderHandBehindHead_location: yup.string().test("leftShoulderHandBehindHead_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.leftShoulderHandBehindHead_limitingFactor === limitingFactors.PAIN && this.parent.leftShoulderHandBehindHead_location === "") {
      return false;
    }

    return true;
  }),

  leftShoulderHandBehindHead_intensity: yup.bool().test("leftShoulderHandBehindHead_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.leftShoulderHandBehindHead_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftShoulderHandBehindHead_intensityVas === "" &&
      this.parent.leftShoulderHandBehindHead_intensityMild === false &&
      this.parent.leftShoulderHandBehindHead_intensityMod === false &&
      this.parent.leftShoulderHandBehindHead_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  leftShoulderHandBehindHead_type: yup.bool().test("leftShoulderHandBehindHead_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.leftShoulderHandBehindHead_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftShoulderHandBehindHead_discomfortTypeAche === false &&
      this.parent.leftShoulderHandBehindHead_discomfortTypeSharp === false &&
      this.parent.leftShoulderHandBehindHead_discomfortTypeBurning === false &&
      this.parent.leftShoulderHandBehindHead_discomfortTypeTightness === false &&
      this.parent.leftShoulderHandBehindHead_discomfortTypePulling === false &&
      this.parent.leftShoulderHandBehindHead_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  leftShoulderHandBehindHead_typeOther: yup.string().test("leftShoulderHandBehindHead_typeOther", "Please describe the discomfort further.", function () {
    if (
      this.parent.leftShoulderHandBehindHead_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftShoulderHandBehindHead_discomfortTypeOther === true &&
      this.parent.leftShoulderHandBehindHead_typeOther === ""
    ) {
      return false;
    }

    return true;
  }),

  leftShoulderHandBehindHead_status: yup.bool().test("leftShoulderHandBehindHead_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.leftShoulderHandBehindHead_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftShoulderHandBehindHead_discomfortResolvedImmediately === false &&
      this.parent.leftShoulderHandBehindHead_discomfortPersisting === false &&
      this.parent.leftShoulderHandBehindHead_discomfortResolvedWithRest === false &&
      this.parent.leftShoulderHandBehindHead_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  leftShoulderHandBehindBack: yup.bool().test("leftShoulderHandBehindBack", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      upperLimbMobilityPainFree,
      leftShoulderHandBehindBack_unable,
      leftShoulderHandBehindBack_lessThanOneThird,
      leftShoulderHandBehindBack_lessThanTwoThirds,
      leftShoulderHandBehindBack_greaterThanTwoThirds,
      leftShoulderHandBehindBack_objectiveRange,
      leftShoulderHandBehindBack_limitingFactor,
      leftShoulderHandBehindBack_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (upperLimbMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (leftShoulderHandBehindBack_unable ||
        leftShoulderHandBehindBack_lessThanOneThird ||
        leftShoulderHandBehindBack_lessThanTwoThirds ||
        leftShoulderHandBehindBack_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!leftShoulderHandBehindBack_objectiveRange ||
        //or the limiting factor has not been selected,
        leftShoulderHandBehindBack_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((leftShoulderHandBehindBack_limitingFactor === "MUSCLETIGHTNESS" || leftShoulderHandBehindBack_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !leftShoulderHandBehindBack_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are unset
      (!!leftShoulderHandBehindBack_objectiveRange || leftShoulderHandBehindBack_limitingFactor !== "SELECT" || !!leftShoulderHandBehindBack_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !leftShoulderHandBehindBack_unable &&
      !leftShoulderHandBehindBack_lessThanOneThird &&
      !leftShoulderHandBehindBack_lessThanTwoThirds &&
      !leftShoulderHandBehindBack_greaterThanTwoThirds
    ) {
      //then the leftShoulderHandBehindBack fields are not valid.
      return false;
    }

    return true;
  }),
  leftShoulderHandBehindBack_location: yup.string().test("leftShoulderHandBehindBack_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.leftShoulderHandBehindBack_limitingFactor === limitingFactors.PAIN && this.parent.leftShoulderHandBehindBack_location === "") {
      return false;
    }

    return true;
  }),

  leftShoulderHandBehindBack_intensity: yup.bool().test("leftShoulderHandBehindBack_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.leftShoulderHandBehindBack_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftShoulderHandBehindBack_intensityVas === "" &&
      this.parent.leftShoulderHandBehindBack_intensityMild === false &&
      this.parent.leftShoulderHandBehindBack_intensityMod === false &&
      this.parent.leftShoulderHandBehindBack_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  leftShoulderHandBehindBack_type: yup.bool().test("leftShoulderHandBehindBack_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.leftShoulderHandBehindBack_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftShoulderHandBehindBack_discomfortTypeAche === false &&
      this.parent.leftShoulderHandBehindBack_discomfortTypeSharp === false &&
      this.parent.leftShoulderHandBehindBack_discomfortTypeBurning === false &&
      this.parent.leftShoulderHandBehindBack_discomfortTypeTightness === false &&
      this.parent.leftShoulderHandBehindBack_discomfortTypePulling === false &&
      this.parent.leftShoulderHandBehindBack_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  leftShoulderHandBehindBack_typeOther: yup.string().test("leftShoulderHandBehindBack_typeOther", "Please describe the discomfort further.", function () {
    if (
      this.parent.leftShoulderHandBehindBack_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftShoulderHandBehindBack_discomfortTypeOther === true &&
      this.parent.leftShoulderHandBehindBack_typeOther === ""
    ) {
      return false;
    }

    return true;
  }),

  leftShoulderHandBehindBack_status: yup.bool().test("leftShoulderHandBehindBack_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.leftShoulderHandBehindBack_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftShoulderHandBehindBack_discomfortResolvedImmediately === false &&
      this.parent.leftShoulderHandBehindBack_discomfortPersisting === false &&
      this.parent.leftShoulderHandBehindBack_discomfortResolvedWithRest === false &&
      this.parent.leftShoulderHandBehindBack_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  leftElbowForearmExtension: yup.bool().test("leftElbowForearmExtension", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      upperLimbMobilityPainFree,
      leftElbowForearmExtension_unable,
      leftElbowForearmExtension_lessThanOneThird,
      leftElbowForearmExtension_lessThanTwoThirds,
      leftElbowForearmExtension_greaterThanTwoThirds,
      leftElbowForearmExtension_objectiveRange,
      leftElbowForearmExtension_limitingFactor,
      leftElbowForearmExtension_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (upperLimbMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (leftElbowForearmExtension_unable ||
        leftElbowForearmExtension_lessThanOneThird ||
        leftElbowForearmExtension_lessThanTwoThirds ||
        leftElbowForearmExtension_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!leftElbowForearmExtension_objectiveRange ||
        //or the limiting factor has not been selected,
        leftElbowForearmExtension_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((leftElbowForearmExtension_limitingFactor === "MUSCLETIGHTNESS" || leftElbowForearmExtension_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !leftElbowForearmExtension_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are unset
      (!!leftElbowForearmExtension_objectiveRange || leftElbowForearmExtension_limitingFactor !== "SELECT" || !!leftElbowForearmExtension_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !leftElbowForearmExtension_unable &&
      !leftElbowForearmExtension_lessThanOneThird &&
      !leftElbowForearmExtension_lessThanTwoThirds &&
      !leftElbowForearmExtension_greaterThanTwoThirds
    ) {
      //then the leftElbowForearmExtension fields are not valid.
      return false;
    }

    return true;
  }),
  leftElbowForearmExtension_location: yup.string().test("leftElbowForearmExtension_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.leftElbowForearmExtension_limitingFactor === limitingFactors.PAIN && this.parent.leftElbowForearmExtension_location === "") {
      return false;
    }

    return true;
  }),

  leftElbowForearmExtension_intensity: yup.bool().test("leftElbowForearmExtension_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.leftElbowForearmExtension_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftElbowForearmExtension_intensityVas === "" &&
      this.parent.leftElbowForearmExtension_intensityMild === false &&
      this.parent.leftElbowForearmExtension_intensityMod === false &&
      this.parent.leftElbowForearmExtension_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  leftElbowForearmExtension_type: yup.bool().test("leftElbowForearmExtension_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.leftElbowForearmExtension_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftElbowForearmExtension_discomfortTypeAche === false &&
      this.parent.leftElbowForearmExtension_discomfortTypeSharp === false &&
      this.parent.leftElbowForearmExtension_discomfortTypeBurning === false &&
      this.parent.leftElbowForearmExtension_discomfortTypeTightness === false &&
      this.parent.leftElbowForearmExtension_discomfortTypePulling === false &&
      this.parent.leftElbowForearmExtension_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  leftElbowForearmExtension_typeOther: yup.string().test("leftElbowForearmExtension_typeOther", "Please describe the discomfort further.", function () {
    if (
      this.parent.leftElbowForearmExtension_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftElbowForearmExtension_discomfortTypeOther === true &&
      this.parent.leftElbowForearmExtension_typeOther === ""
    ) {
      return false;
    }

    return true;
  }),

  leftElbowForearmExtension_status: yup.bool().test("leftElbowForearmExtension_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.leftElbowForearmExtension_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftElbowForearmExtension_discomfortResolvedImmediately === false &&
      this.parent.leftElbowForearmExtension_discomfortPersisting === false &&
      this.parent.leftElbowForearmExtension_discomfortResolvedWithRest === false &&
      this.parent.leftElbowForearmExtension_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  leftElbowForearmFlexion: yup.bool().test("leftElbowForearmFlexion", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      upperLimbMobilityPainFree,
      leftElbowForearmFlexion_unable,
      leftElbowForearmFlexion_lessThanOneThird,
      leftElbowForearmFlexion_lessThanTwoThirds,
      leftElbowForearmFlexion_greaterThanTwoThirds,
      leftElbowForearmFlexion_objectiveRange,
      leftElbowForearmFlexion_limitingFactor,
      leftElbowForearmFlexion_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (upperLimbMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (leftElbowForearmFlexion_unable || leftElbowForearmFlexion_lessThanOneThird || leftElbowForearmFlexion_lessThanTwoThirds || leftElbowForearmFlexion_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!leftElbowForearmFlexion_objectiveRange ||
        //or the limiting factor has not been selected,
        leftElbowForearmFlexion_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((leftElbowForearmFlexion_limitingFactor === "MUSCLETIGHTNESS" || leftElbowForearmFlexion_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !leftElbowForearmFlexion_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are unset
      (!!leftElbowForearmFlexion_objectiveRange || leftElbowForearmFlexion_limitingFactor !== "SELECT" || !!leftElbowForearmFlexion_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !leftElbowForearmFlexion_unable &&
      !leftElbowForearmFlexion_lessThanOneThird &&
      !leftElbowForearmFlexion_lessThanTwoThirds &&
      !leftElbowForearmFlexion_greaterThanTwoThirds
    ) {
      //then the leftElbowForearmFlexion fields are not valid.
      return false;
    }

    return true;
  }),
  leftElbowForearmFlexion_location: yup.string().test("leftElbowForearmFlexion_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.leftElbowForearmFlexion_limitingFactor === limitingFactors.PAIN && this.parent.leftElbowForearmFlexion_location === "") {
      return false;
    }

    return true;
  }),

  leftElbowForearmFlexion_intensity: yup.bool().test("leftElbowForearmFlexion_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.leftElbowForearmFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftElbowForearmFlexion_intensityVas === "" &&
      this.parent.leftElbowForearmFlexion_intensityMild === false &&
      this.parent.leftElbowForearmFlexion_intensityMod === false &&
      this.parent.leftElbowForearmFlexion_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  leftElbowForearmFlexion_type: yup.bool().test("leftElbowForearmFlexion_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.leftElbowForearmFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftElbowForearmFlexion_discomfortTypeAche === false &&
      this.parent.leftElbowForearmFlexion_discomfortTypeSharp === false &&
      this.parent.leftElbowForearmFlexion_discomfortTypeBurning === false &&
      this.parent.leftElbowForearmFlexion_discomfortTypeTightness === false &&
      this.parent.leftElbowForearmFlexion_discomfortTypePulling === false &&
      this.parent.leftElbowForearmFlexion_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  leftElbowForearmFlexion_typeOther: yup.string().test("leftElbowForearmFlexion_typeOther", "Please describe the discomfort further.", function () {
    if (
      this.parent.leftElbowForearmFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftElbowForearmFlexion_discomfortTypeOther === true &&
      this.parent.leftElbowForearmFlexion_typeOther === ""
    ) {
      return false;
    }

    return true;
  }),

  leftElbowForearmFlexion_status: yup.bool().test("leftElbowForearmFlexion_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.leftElbowForearmFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftElbowForearmFlexion_discomfortResolvedImmediately === false &&
      this.parent.leftElbowForearmFlexion_discomfortPersisting === false &&
      this.parent.leftElbowForearmFlexion_discomfortResolvedWithRest === false &&
      this.parent.leftElbowForearmFlexion_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  leftElbowForearmSupination: yup.bool().test("leftElbowForearmSupination", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      upperLimbMobilityPainFree,
      leftElbowForearmSupination_unable,
      leftElbowForearmSupination_lessThanOneThird,
      leftElbowForearmSupination_lessThanTwoThirds,
      leftElbowForearmSupination_greaterThanTwoThirds,
      leftElbowForearmSupination_objectiveRange,
      leftElbowForearmSupination_limitingFactor,
      leftElbowForearmSupination_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (upperLimbMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (leftElbowForearmSupination_unable ||
        leftElbowForearmSupination_lessThanOneThird ||
        leftElbowForearmSupination_lessThanTwoThirds ||
        leftElbowForearmSupination_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!leftElbowForearmSupination_objectiveRange ||
        //or the limiting factor has not been selected,
        leftElbowForearmSupination_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((leftElbowForearmSupination_limitingFactor === "MUSCLETIGHTNESS" || leftElbowForearmSupination_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !leftElbowForearmSupination_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are unset
      (!!leftElbowForearmSupination_objectiveRange || leftElbowForearmSupination_limitingFactor !== "SELECT" || !!leftElbowForearmSupination_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !leftElbowForearmSupination_unable &&
      !leftElbowForearmSupination_lessThanOneThird &&
      !leftElbowForearmSupination_lessThanTwoThirds &&
      !leftElbowForearmSupination_greaterThanTwoThirds
    ) {
      //then the leftElbowForearmSupination fields are not valid.
      return false;
    }

    return true;
  }),
  leftElbowForearmSupination_location: yup.string().test("leftElbowForearmSupination_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.leftElbowForearmSupination_limitingFactor === limitingFactors.PAIN && this.parent.leftElbowForearmSupination_location === "") {
      return false;
    }

    return true;
  }),

  leftElbowForearmSupination_intensity: yup.bool().test("leftElbowForearmSupination_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.leftElbowForearmSupination_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftElbowForearmSupination_intensityVas === "" &&
      this.parent.leftElbowForearmSupination_intensityMild === false &&
      this.parent.leftElbowForearmSupination_intensityMod === false &&
      this.parent.leftElbowForearmSupination_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  leftElbowForearmSupination_type: yup.bool().test("leftElbowForearmSupination_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.leftElbowForearmSupination_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftElbowForearmSupination_discomfortTypeAche === false &&
      this.parent.leftElbowForearmSupination_discomfortTypeSharp === false &&
      this.parent.leftElbowForearmSupination_discomfortTypeBurning === false &&
      this.parent.leftElbowForearmSupination_discomfortTypeTightness === false &&
      this.parent.leftElbowForearmSupination_discomfortTypePulling === false &&
      this.parent.leftElbowForearmSupination_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  leftElbowForearmSupination_typeOther: yup.string().test("leftElbowForearmSupination_typeOther", "Please describe the discomfort further.", function () {
    if (
      this.parent.leftElbowForearmSupination_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftElbowForearmSupination_discomfortTypeOther === true &&
      this.parent.leftElbowForearmSupination_typeOther === ""
    ) {
      return false;
    }

    return true;
  }),

  leftElbowForearmSupination_status: yup.bool().test("leftElbowForearmSupination_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.leftElbowForearmSupination_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftElbowForearmSupination_discomfortResolvedImmediately === false &&
      this.parent.leftElbowForearmSupination_discomfortPersisting === false &&
      this.parent.leftElbowForearmSupination_discomfortResolvedWithRest === false &&
      this.parent.leftElbowForearmSupination_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  leftWristHandExtension: yup.bool().test("leftWristHandExtension", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      upperLimbMobilityPainFree,
      leftWristHandExtension_unable,
      leftWristHandExtension_lessThanOneThird,
      leftWristHandExtension_lessThanTwoThirds,
      leftWristHandExtension_greaterThanTwoThirds,
      leftWristHandExtension_objectiveRange,
      leftWristHandExtension_limitingFactor,
      leftWristHandExtension_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (upperLimbMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (leftWristHandExtension_unable || leftWristHandExtension_lessThanOneThird || leftWristHandExtension_lessThanTwoThirds || leftWristHandExtension_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!leftWristHandExtension_objectiveRange ||
        //or the limiting factor has not been selected,
        leftWristHandExtension_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((leftWristHandExtension_limitingFactor === "MUSCLETIGHTNESS" || leftWristHandExtension_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !leftWristHandExtension_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are unset
      (!!leftWristHandExtension_objectiveRange || leftWristHandExtension_limitingFactor !== "SELECT" || !!leftWristHandExtension_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !leftWristHandExtension_unable &&
      !leftWristHandExtension_lessThanOneThird &&
      !leftWristHandExtension_lessThanTwoThirds &&
      !leftWristHandExtension_greaterThanTwoThirds
    ) {
      //then the leftWristHandExtension fields are not valid.
      return false;
    }

    return true;
  }),
  leftWristHandExtension_location: yup.string().test("leftWristHandExtension_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.leftWristHandExtension_limitingFactor === limitingFactors.PAIN && this.parent.leftWristHandExtension_location === "") {
      return false;
    }

    return true;
  }),

  leftWristHandExtension_intensity: yup.bool().test("leftWristHandExtension_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.leftWristHandExtension_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftWristHandExtension_intensityVas === "" &&
      this.parent.leftWristHandExtension_intensityMild === false &&
      this.parent.leftWristHandExtension_intensityMod === false &&
      this.parent.leftWristHandExtension_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  leftWristHandExtension_type: yup.bool().test("leftWristHandExtension_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.leftWristHandExtension_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftWristHandExtension_discomfortTypeAche === false &&
      this.parent.leftWristHandExtension_discomfortTypeSharp === false &&
      this.parent.leftWristHandExtension_discomfortTypeBurning === false &&
      this.parent.leftWristHandExtension_discomfortTypeTightness === false &&
      this.parent.leftWristHandExtension_discomfortTypePulling === false &&
      this.parent.leftWristHandExtension_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  leftWristHandExtension_typeOther: yup.string().test("leftWristHandExtension_typeOther", "Please describe the discomfort further.", function () {
    if (
      this.parent.leftWristHandExtension_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftWristHandExtension_discomfortTypeOther === true &&
      this.parent.leftWristHandExtension_typeOther === ""
    ) {
      return false;
    }

    return true;
  }),

  leftWristHandExtension_status: yup.bool().test("leftWristHandExtension_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.leftWristHandExtension_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftWristHandExtension_discomfortResolvedImmediately === false &&
      this.parent.leftWristHandExtension_discomfortPersisting === false &&
      this.parent.leftWristHandExtension_discomfortResolvedWithRest === false &&
      this.parent.leftWristHandExtension_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  leftWristHandFlexion: yup.bool().test("leftWristHandFlexion", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      upperLimbMobilityPainFree,
      leftWristHandFlexion_unable,
      leftWristHandFlexion_lessThanOneThird,
      leftWristHandFlexion_lessThanTwoThirds,
      leftWristHandFlexion_greaterThanTwoThirds,
      leftWristHandFlexion_objectiveRange,
      leftWristHandFlexion_limitingFactor,
      leftWristHandFlexion_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (upperLimbMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (leftWristHandFlexion_unable || leftWristHandFlexion_lessThanOneThird || leftWristHandFlexion_lessThanTwoThirds || leftWristHandFlexion_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!leftWristHandFlexion_objectiveRange ||
        //or the limiting factor has not been selected,
        leftWristHandFlexion_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((leftWristHandFlexion_limitingFactor === "MUSCLETIGHTNESS" || leftWristHandFlexion_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !leftWristHandFlexion_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are unset
      (!!leftWristHandFlexion_objectiveRange || leftWristHandFlexion_limitingFactor !== "SELECT" || !!leftWristHandFlexion_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !leftWristHandFlexion_unable &&
      !leftWristHandFlexion_lessThanOneThird &&
      !leftWristHandFlexion_lessThanTwoThirds &&
      !leftWristHandFlexion_greaterThanTwoThirds
    ) {
      //then the leftWristHandFlexion fields are not valid.
      return false;
    }

    return true;
  }),
  leftWristHandFlexion_location: yup.string().test("leftWristHandFlexion_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.leftWristHandFlexion_limitingFactor === limitingFactors.PAIN && this.parent.leftWristHandFlexion_location === "") {
      return false;
    }

    return true;
  }),

  leftWristHandFlexion_intensity: yup.bool().test("leftWristHandFlexion_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.leftWristHandFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftWristHandFlexion_intensityVas === "" &&
      this.parent.leftWristHandFlexion_intensityMild === false &&
      this.parent.leftWristHandFlexion_intensityMod === false &&
      this.parent.leftWristHandFlexion_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  leftWristHandFlexion_type: yup.bool().test("leftWristHandFlexion_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.leftWristHandFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftWristHandFlexion_discomfortTypeAche === false &&
      this.parent.leftWristHandFlexion_discomfortTypeSharp === false &&
      this.parent.leftWristHandFlexion_discomfortTypeBurning === false &&
      this.parent.leftWristHandFlexion_discomfortTypeTightness === false &&
      this.parent.leftWristHandFlexion_discomfortTypePulling === false &&
      this.parent.leftWristHandFlexion_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  leftWristHandFlexion_typeOther: yup.string().test("leftWristHandFlexion_typeOther", "Please describe the discomfort further.", function () {
    if (
      this.parent.leftWristHandFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftWristHandFlexion_discomfortTypeOther === true &&
      this.parent.leftWristHandFlexion_typeOther === ""
    ) {
      return false;
    }

    return true;
  }),

  leftWristHandFlexion_status: yup.bool().test("leftWristHandFlexion_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.leftWristHandFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftWristHandFlexion_discomfortResolvedImmediately === false &&
      this.parent.leftWristHandFlexion_discomfortPersisting === false &&
      this.parent.leftWristHandFlexion_discomfortResolvedWithRest === false &&
      this.parent.leftWristHandFlexion_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  leftWristHandRadialDeviation: yup.bool().test("leftWristHandRadialDeviation", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      upperLimbMobilityPainFree,
      leftWristHandRadialDeviation_unable,
      leftWristHandRadialDeviation_lessThanOneThird,
      leftWristHandRadialDeviation_lessThanTwoThirds,
      leftWristHandRadialDeviation_greaterThanTwoThirds,
      leftWristHandRadialDeviation_objectiveRange,
      leftWristHandRadialDeviation_limitingFactor,
      leftWristHandRadialDeviation_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (upperLimbMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (leftWristHandRadialDeviation_unable ||
        leftWristHandRadialDeviation_lessThanOneThird ||
        leftWristHandRadialDeviation_lessThanTwoThirds ||
        leftWristHandRadialDeviation_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!leftWristHandRadialDeviation_objectiveRange ||
        //or the limiting factor has not been selected,
        leftWristHandRadialDeviation_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((leftWristHandRadialDeviation_limitingFactor === "MUSCLETIGHTNESS" || leftWristHandRadialDeviation_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !leftWristHandRadialDeviation_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are unset
      (!!leftWristHandRadialDeviation_objectiveRange || leftWristHandRadialDeviation_limitingFactor !== "SELECT" || !!leftWristHandRadialDeviation_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !leftWristHandRadialDeviation_unable &&
      !leftWristHandRadialDeviation_lessThanOneThird &&
      !leftWristHandRadialDeviation_lessThanTwoThirds &&
      !leftWristHandRadialDeviation_greaterThanTwoThirds
    ) {
      //then the leftWristHandRadialDeviation fields are not valid.
      return false;
    }

    return true;
  }),
  leftWristHandRadialDeviation_location: yup.string().test("leftWristHandRadialDeviation_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.leftWristHandRadialDeviation_limitingFactor === limitingFactors.PAIN && this.parent.leftWristHandRadialDeviation_location === "") {
      return false;
    }

    return true;
  }),

  leftWristHandRadialDeviation_intensity: yup.bool().test("leftWristHandRadialDeviation_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.leftWristHandRadialDeviation_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftWristHandRadialDeviation_intensityVas === "" &&
      this.parent.leftWristHandRadialDeviation_intensityMild === false &&
      this.parent.leftWristHandRadialDeviation_intensityMod === false &&
      this.parent.leftWristHandRadialDeviation_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  leftWristHandRadialDeviation_type: yup.bool().test("leftWristHandRadialDeviation_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.leftWristHandRadialDeviation_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftWristHandRadialDeviation_discomfortTypeAche === false &&
      this.parent.leftWristHandRadialDeviation_discomfortTypeSharp === false &&
      this.parent.leftWristHandRadialDeviation_discomfortTypeBurning === false &&
      this.parent.leftWristHandRadialDeviation_discomfortTypeTightness === false &&
      this.parent.leftWristHandRadialDeviation_discomfortTypePulling === false &&
      this.parent.leftWristHandRadialDeviation_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  leftWristHandRadialDeviation_typeOther: yup.string().test("leftWristHandRadialDeviation_typeOther", "Please describe the discomfort further.", function () {
    if (
      this.parent.leftWristHandRadialDeviation_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftWristHandRadialDeviation_discomfortTypeOther === true &&
      this.parent.leftWristHandRadialDeviation_typeOther === ""
    ) {
      return false;
    }

    return true;
  }),

  leftWristHandRadialDeviation_status: yup.bool().test("leftWristHandRadialDeviation_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.leftWristHandRadialDeviation_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftWristHandRadialDeviation_discomfortResolvedImmediately === false &&
      this.parent.leftWristHandRadialDeviation_discomfortPersisting === false &&
      this.parent.leftWristHandRadialDeviation_discomfortResolvedWithRest === false &&
      this.parent.leftWristHandRadialDeviation_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  leftWristHandUlnarDeviation: yup.bool().test("leftWristHandUlnarDeviation", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      upperLimbMobilityPainFree,
      leftWristHandUlnarDeviation_unable,
      leftWristHandUlnarDeviation_lessThanOneThird,
      leftWristHandUlnarDeviation_lessThanTwoThirds,
      leftWristHandUlnarDeviation_greaterThanTwoThirds,
      leftWristHandUlnarDeviation_objectiveRange,
      leftWristHandUlnarDeviation_limitingFactor,
      leftWristHandUlnarDeviation_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (upperLimbMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (leftWristHandUlnarDeviation_unable ||
        leftWristHandUlnarDeviation_lessThanOneThird ||
        leftWristHandUlnarDeviation_lessThanTwoThirds ||
        leftWristHandUlnarDeviation_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!leftWristHandUlnarDeviation_objectiveRange ||
        //or the limiting factor has not been selected,
        leftWristHandUlnarDeviation_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((leftWristHandUlnarDeviation_limitingFactor === "MUSCLETIGHTNESS" || leftWristHandUlnarDeviation_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !leftWristHandUlnarDeviation_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are unset
      (!!leftWristHandUlnarDeviation_objectiveRange || leftWristHandUlnarDeviation_limitingFactor !== "SELECT" || !!leftWristHandUlnarDeviation_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !leftWristHandUlnarDeviation_unable &&
      !leftWristHandUlnarDeviation_lessThanOneThird &&
      !leftWristHandUlnarDeviation_lessThanTwoThirds &&
      !leftWristHandUlnarDeviation_greaterThanTwoThirds
    ) {
      //then the leftWristHandUlnarDeviation fields are not valid.
      return false;
    }

    return true;
  }),
  leftWristHandUlnarDeviation_location: yup.string().test("leftWristHandUlnarDeviation_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.leftWristHandUlnarDeviation_limitingFactor === limitingFactors.PAIN && this.parent.leftWristHandUlnarDeviation_location === "") {
      return false;
    }

    return true;
  }),

  leftWristHandUlnarDeviation_intensity: yup.bool().test("leftWristHandUlnarDeviation_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.leftWristHandUlnarDeviation_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftWristHandUlnarDeviation_intensityVas === "" &&
      this.parent.leftWristHandUlnarDeviation_intensityMild === false &&
      this.parent.leftWristHandUlnarDeviation_intensityMod === false &&
      this.parent.leftWristHandUlnarDeviation_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  leftWristHandUlnarDeviation_type: yup.bool().test("leftWristHandUlnarDeviation_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.leftWristHandUlnarDeviation_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftWristHandUlnarDeviation_discomfortTypeAche === false &&
      this.parent.leftWristHandUlnarDeviation_discomfortTypeSharp === false &&
      this.parent.leftWristHandUlnarDeviation_discomfortTypeBurning === false &&
      this.parent.leftWristHandUlnarDeviation_discomfortTypeTightness === false &&
      this.parent.leftWristHandUlnarDeviation_discomfortTypePulling === false &&
      this.parent.leftWristHandUlnarDeviation_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  leftWristHandUlnarDeviation_typeOther: yup.string().test("leftWristHandUlnarDeviation_typeOther", "Please describe the discomfort further.", function () {
    if (
      this.parent.leftWristHandUlnarDeviation_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftWristHandUlnarDeviation_discomfortTypeOther === true &&
      this.parent.leftWristHandUlnarDeviation_typeOther === ""
    ) {
      return false;
    }

    return true;
  }),

  leftWristHandUlnarDeviation_status: yup.bool().test("leftWristHandUlnarDeviation_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.leftWristHandUlnarDeviation_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftWristHandUlnarDeviation_discomfortResolvedImmediately === false &&
      this.parent.leftWristHandUlnarDeviation_discomfortPersisting === false &&
      this.parent.leftWristHandUlnarDeviation_discomfortResolvedWithRest === false &&
      this.parent.leftWristHandUlnarDeviation_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  leftHandFingerOppositionToThumb: yup.bool().test("leftHandFingerOppositionToThumb", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      upperLimbMobilityPainFree,
      leftHandFingerOppositionToThumb_unable,
      leftHandFingerOppositionToThumb_lessThanOneThird,
      leftHandFingerOppositionToThumb_lessThanTwoThirds,
      leftHandFingerOppositionToThumb_greaterThanTwoThirds,
      leftHandFingerOppositionToThumb_objectiveRange,
      leftHandFingerOppositionToThumb_limitingFactor,
      leftHandFingerOppositionToThumb_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (upperLimbMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (leftHandFingerOppositionToThumb_unable ||
        leftHandFingerOppositionToThumb_lessThanOneThird ||
        leftHandFingerOppositionToThumb_lessThanTwoThirds ||
        leftHandFingerOppositionToThumb_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!leftHandFingerOppositionToThumb_objectiveRange ||
        //or the limiting factor has not been selected,
        leftHandFingerOppositionToThumb_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((leftHandFingerOppositionToThumb_limitingFactor === "MUSCLETIGHTNESS" || leftHandFingerOppositionToThumb_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !leftHandFingerOppositionToThumb_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are unset
      (!!leftHandFingerOppositionToThumb_objectiveRange || leftHandFingerOppositionToThumb_limitingFactor !== "SELECT" || !!leftHandFingerOppositionToThumb_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !leftHandFingerOppositionToThumb_unable &&
      !leftHandFingerOppositionToThumb_lessThanOneThird &&
      !leftHandFingerOppositionToThumb_lessThanTwoThirds &&
      !leftHandFingerOppositionToThumb_greaterThanTwoThirds
    ) {
      //then the leftHandFingerOppositionToThumb fields are not valid.
      return false;
    }

    return true;
  }),
  leftHandFingerOppositionToThumb_location: yup.string().test("leftHandFingerOppositionToThumb_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.leftHandFingerOppositionToThumb_limitingFactor === limitingFactors.PAIN && this.parent.leftHandFingerOppositionToThumb_location === "") {
      return false;
    }

    return true;
  }),

  leftHandFingerOppositionToThumb_intensity: yup.bool().test("leftHandFingerOppositionToThumb_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.leftHandFingerOppositionToThumb_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftHandFingerOppositionToThumb_intensityVas === "" &&
      this.parent.leftHandFingerOppositionToThumb_intensityMild === false &&
      this.parent.leftHandFingerOppositionToThumb_intensityMod === false &&
      this.parent.leftHandFingerOppositionToThumb_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  leftHandFingerOppositionToThumb_type: yup.bool().test("leftHandFingerOppositionToThumb_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.leftHandFingerOppositionToThumb_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftHandFingerOppositionToThumb_discomfortTypeAche === false &&
      this.parent.leftHandFingerOppositionToThumb_discomfortTypeSharp === false &&
      this.parent.leftHandFingerOppositionToThumb_discomfortTypeBurning === false &&
      this.parent.leftHandFingerOppositionToThumb_discomfortTypeTightness === false &&
      this.parent.leftHandFingerOppositionToThumb_discomfortTypePulling === false &&
      this.parent.leftHandFingerOppositionToThumb_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  leftHandFingerOppositionToThumb_typeOther: yup.string().test("leftHandFingerOppositionToThumb_typeOther", "Please describe the discomfort further.", function () {
    if (
      this.parent.leftHandFingerOppositionToThumb_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftHandFingerOppositionToThumb_discomfortTypeOther === true &&
      this.parent.leftHandFingerOppositionToThumb_typeOther === ""
    ) {
      return false;
    }

    return true;
  }),

  leftHandFingerOppositionToThumb_status: yup.bool().test("leftHandFingerOppositionToThumb_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.leftHandFingerOppositionToThumb_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftHandFingerOppositionToThumb_discomfortResolvedImmediately === false &&
      this.parent.leftHandFingerOppositionToThumb_discomfortPersisting === false &&
      this.parent.leftHandFingerOppositionToThumb_discomfortResolvedWithRest === false &&
      this.parent.leftHandFingerOppositionToThumb_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  leftHandFingerFullFist: yup.bool().test("leftHandFingerFullFist", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      upperLimbMobilityPainFree,
      leftHandFingerFullFist_unable,
      leftHandFingerFullFist_lessThanOneThird,
      leftHandFingerFullFist_lessThanTwoThirds,
      leftHandFingerFullFist_greaterThanTwoThirds,
      leftHandFingerFullFist_objectiveRange,
      leftHandFingerFullFist_limitingFactor,
      leftHandFingerFullFist_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (upperLimbMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (leftHandFingerFullFist_unable || leftHandFingerFullFist_lessThanOneThird || leftHandFingerFullFist_lessThanTwoThirds || leftHandFingerFullFist_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!leftHandFingerFullFist_objectiveRange ||
        //or the limiting factor has not been selected,
        leftHandFingerFullFist_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((leftHandFingerFullFist_limitingFactor === "MUSCLETIGHTNESS" || leftHandFingerFullFist_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !leftHandFingerFullFist_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are unset
      (!!leftHandFingerFullFist_objectiveRange || leftHandFingerFullFist_limitingFactor !== "SELECT" || !!leftHandFingerFullFist_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !leftHandFingerFullFist_unable &&
      !leftHandFingerFullFist_lessThanOneThird &&
      !leftHandFingerFullFist_lessThanTwoThirds &&
      !leftHandFingerFullFist_greaterThanTwoThirds
    ) {
      //then the leftHandFingerFullFist fields are not valid.
      return false;
    }

    return true;
  }),
  leftHandFingerFullFist_location: yup.string().test("leftHandFingerFullFist_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.leftHandFingerFullFist_limitingFactor === limitingFactors.PAIN && this.parent.leftHandFingerFullFist_location === "") {
      return false;
    }

    return true;
  }),

  leftHandFingerFullFist_intensity: yup.bool().test("leftHandFingerFullFist_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.leftHandFingerFullFist_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftHandFingerFullFist_intensityVas === "" &&
      this.parent.leftHandFingerFullFist_intensityMild === false &&
      this.parent.leftHandFingerFullFist_intensityMod === false &&
      this.parent.leftHandFingerFullFist_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  leftHandFingerFullFist_type: yup.bool().test("leftHandFingerFullFist_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.leftHandFingerFullFist_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftHandFingerFullFist_discomfortTypeAche === false &&
      this.parent.leftHandFingerFullFist_discomfortTypeSharp === false &&
      this.parent.leftHandFingerFullFist_discomfortTypeBurning === false &&
      this.parent.leftHandFingerFullFist_discomfortTypeTightness === false &&
      this.parent.leftHandFingerFullFist_discomfortTypePulling === false &&
      this.parent.leftHandFingerFullFist_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  leftHandFingerFullFist_typeOther: yup.string().test("leftHandFingerFullFist_typeOther", "Please describe the discomfort further.", function () {
    if (
      this.parent.leftHandFingerFullFist_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftHandFingerFullFist_discomfortTypeOther === true &&
      this.parent.leftHandFingerFullFist_typeOther === ""
    ) {
      return false;
    }

    return true;
  }),

  leftHandFingerFullFist_status: yup.bool().test("leftHandFingerFullFist_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.leftHandFingerFullFist_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftHandFingerFullFist_discomfortResolvedImmediately === false &&
      this.parent.leftHandFingerFullFist_discomfortPersisting === false &&
      this.parent.leftHandFingerFullFist_discomfortResolvedWithRest === false &&
      this.parent.leftHandFingerFullFist_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  rightShoulderFlexion: yup.bool().test("rightShoulderFlexion", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      upperLimbMobilityPainFree,
      rightShoulderFlexion_unable,
      rightShoulderFlexion_lessThanOneThird,
      rightShoulderFlexion_lessThanTwoThirds,
      rightShoulderFlexion_greaterThanTwoThirds,
      rightShoulderFlexion_objectiveRange,
      rightShoulderFlexion_limitingFactor,
      rightShoulderFlexion_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (upperLimbMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (rightShoulderFlexion_unable || rightShoulderFlexion_lessThanOneThird || rightShoulderFlexion_lessThanTwoThirds || rightShoulderFlexion_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!rightShoulderFlexion_objectiveRange ||
        //or the limiting factor has not been selected,
        rightShoulderFlexion_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((rightShoulderFlexion_limitingFactor === "MUSCLETIGHTNESS" || rightShoulderFlexion_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !rightShoulderFlexion_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are unset
      (!!rightShoulderFlexion_objectiveRange || rightShoulderFlexion_limitingFactor !== "SELECT" || !!rightShoulderFlexion_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !rightShoulderFlexion_unable &&
      !rightShoulderFlexion_lessThanOneThird &&
      !rightShoulderFlexion_lessThanTwoThirds &&
      !rightShoulderFlexion_greaterThanTwoThirds
    ) {
      //then the rightShoulderFlexion fields are not valid.
      return false;
    }

    return true;
  }),
  rightShoulderFlexion_location: yup.string().test("rightShoulderFlexion_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.rightShoulderFlexion_limitingFactor === limitingFactors.PAIN && this.parent.rightShoulderFlexion_location === "") {
      return false;
    }

    return true;
  }),

  rightShoulderFlexion_intensity: yup.bool().test("rightShoulderFlexion_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.rightShoulderFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightShoulderFlexion_intensityVas === "" &&
      this.parent.rightShoulderFlexion_intensityMild === false &&
      this.parent.rightShoulderFlexion_intensityMod === false &&
      this.parent.rightShoulderFlexion_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  rightShoulderFlexion_type: yup.bool().test("rightShoulderFlexion_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.rightShoulderFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightShoulderFlexion_discomfortTypeAche === false &&
      this.parent.rightShoulderFlexion_discomfortTypeSharp === false &&
      this.parent.rightShoulderFlexion_discomfortTypeBurning === false &&
      this.parent.rightShoulderFlexion_discomfortTypeTightness === false &&
      this.parent.rightShoulderFlexion_discomfortTypePulling === false &&
      this.parent.rightShoulderFlexion_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  rightShoulderFlexion_typeOther: yup.string().test("rightShoulderFlexion_typeOther", "Please describe the discomfort further.", function () {
    if (
      this.parent.rightShoulderFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightShoulderFlexion_discomfortTypeOther === true &&
      this.parent.rightShoulderFlexion_typeOther === ""
    ) {
      return false;
    }

    return true;
  }),

  rightShoulderFlexion_status: yup.bool().test("rightShoulderFlexion_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.rightShoulderFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightShoulderFlexion_discomfortResolvedImmediately === false &&
      this.parent.rightShoulderFlexion_discomfortPersisting === false &&
      this.parent.rightShoulderFlexion_discomfortResolvedWithRest === false &&
      this.parent.rightShoulderFlexion_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  rightShoulderAbduction: yup.bool().test("rightShoulderAbduction", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      upperLimbMobilityPainFree,
      rightShoulderAbduction_unable,
      rightShoulderAbduction_lessThanOneThird,
      rightShoulderAbduction_lessThanTwoThirds,
      rightShoulderAbduction_greaterThanTwoThirds,
      rightShoulderAbduction_objectiveRange,
      rightShoulderAbduction_limitingFactor,
      rightShoulderAbduction_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (upperLimbMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (rightShoulderAbduction_unable || rightShoulderAbduction_lessThanOneThird || rightShoulderAbduction_lessThanTwoThirds || rightShoulderAbduction_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!rightShoulderAbduction_objectiveRange ||
        //or the limiting factor has not been selected,
        rightShoulderAbduction_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((rightShoulderAbduction_limitingFactor === "MUSCLETIGHTNESS" || rightShoulderAbduction_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !rightShoulderAbduction_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are unset
      (!!rightShoulderAbduction_objectiveRange || rightShoulderAbduction_limitingFactor !== "SELECT" || !!rightShoulderAbduction_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !rightShoulderAbduction_unable &&
      !rightShoulderAbduction_lessThanOneThird &&
      !rightShoulderAbduction_lessThanTwoThirds &&
      !rightShoulderAbduction_greaterThanTwoThirds
    ) {
      //then the rightShoulderAbduction fields are not valid.
      return false;
    }

    return true;
  }),
  rightShoulderAbduction_location: yup.string().test("rightShoulderAbduction_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.rightShoulderAbduction_limitingFactor === limitingFactors.PAIN && this.parent.rightShoulderAbduction_location === "") {
      return false;
    }

    return true;
  }),

  rightShoulderAbduction_intensity: yup.bool().test("rightShoulderAbduction_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.rightShoulderAbduction_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightShoulderAbduction_intensityVas === "" &&
      this.parent.rightShoulderAbduction_intensityMild === false &&
      this.parent.rightShoulderAbduction_intensityMod === false &&
      this.parent.rightShoulderAbduction_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  rightShoulderAbduction_type: yup.bool().test("rightShoulderAbduction_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.rightShoulderAbduction_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightShoulderAbduction_discomfortTypeAche === false &&
      this.parent.rightShoulderAbduction_discomfortTypeSharp === false &&
      this.parent.rightShoulderAbduction_discomfortTypeBurning === false &&
      this.parent.rightShoulderAbduction_discomfortTypeTightness === false &&
      this.parent.rightShoulderAbduction_discomfortTypePulling === false &&
      this.parent.rightShoulderAbduction_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  rightShoulderAbduction_typeOther: yup.string().test("rightShoulderAbduction_typeOther", "Please describe the discomfort further.", function () {
    if (
      this.parent.rightShoulderAbduction_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightShoulderAbduction_discomfortTypeOther === true &&
      this.parent.rightShoulderAbduction_typeOther === ""
    ) {
      return false;
    }

    return true;
  }),

  rightShoulderAbduction_status: yup.bool().test("rightShoulderAbduction_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.rightShoulderAbduction_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightShoulderAbduction_discomfortResolvedImmediately === false &&
      this.parent.rightShoulderAbduction_discomfortPersisting === false &&
      this.parent.rightShoulderAbduction_discomfortResolvedWithRest === false &&
      this.parent.rightShoulderAbduction_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  rightShoulderHandBehindHead: yup.bool().test("rightShoulderHandBehindHead", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      upperLimbMobilityPainFree,
      rightShoulderHandBehindHead_unable,
      rightShoulderHandBehindHead_lessThanOneThird,
      rightShoulderHandBehindHead_lessThanTwoThirds,
      rightShoulderHandBehindHead_greaterThanTwoThirds,
      rightShoulderHandBehindHead_objectiveRange,
      rightShoulderHandBehindHead_limitingFactor,
      rightShoulderHandBehindHead_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (upperLimbMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (rightShoulderHandBehindHead_unable ||
        rightShoulderHandBehindHead_lessThanOneThird ||
        rightShoulderHandBehindHead_lessThanTwoThirds ||
        rightShoulderHandBehindHead_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!rightShoulderHandBehindHead_objectiveRange ||
        //or the limiting factor has not been selected,
        rightShoulderHandBehindHead_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((rightShoulderHandBehindHead_limitingFactor === "MUSCLETIGHTNESS" || rightShoulderHandBehindHead_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !rightShoulderHandBehindHead_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are unset
      (!!rightShoulderHandBehindHead_objectiveRange || rightShoulderHandBehindHead_limitingFactor !== "SELECT" || !!rightShoulderHandBehindHead_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !rightShoulderHandBehindHead_unable &&
      !rightShoulderHandBehindHead_lessThanOneThird &&
      !rightShoulderHandBehindHead_lessThanTwoThirds &&
      !rightShoulderHandBehindHead_greaterThanTwoThirds
    ) {
      //then the rightShoulderHandBehindHead fields are not valid.
      return false;
    }

    return true;
  }),
  rightShoulderHandBehindHead_location: yup.string().test("rightShoulderHandBehindHead_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.rightShoulderHandBehindHead_limitingFactor === limitingFactors.PAIN && this.parent.rightShoulderHandBehindHead_location === "") {
      return false;
    }

    return true;
  }),

  rightShoulderHandBehindHead_intensity: yup.bool().test("rightShoulderHandBehindHead_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.rightShoulderHandBehindHead_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightShoulderHandBehindHead_intensityVas === "" &&
      this.parent.rightShoulderHandBehindHead_intensityMild === false &&
      this.parent.rightShoulderHandBehindHead_intensityMod === false &&
      this.parent.rightShoulderHandBehindHead_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  rightShoulderHandBehindHead_type: yup.bool().test("rightShoulderHandBehindHead_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.rightShoulderHandBehindHead_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightShoulderHandBehindHead_discomfortTypeAche === false &&
      this.parent.rightShoulderHandBehindHead_discomfortTypeSharp === false &&
      this.parent.rightShoulderHandBehindHead_discomfortTypeBurning === false &&
      this.parent.rightShoulderHandBehindHead_discomfortTypeTightness === false &&
      this.parent.rightShoulderHandBehindHead_discomfortTypePulling === false &&
      this.parent.rightShoulderHandBehindHead_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  rightShoulderHandBehindHead_typeOther: yup.string().test("rightShoulderHandBehindHead_typeOther", "Please describe the discomfort further.", function () {
    if (
      this.parent.rightShoulderHandBehindHead_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightShoulderHandBehindHead_discomfortTypeOther === true &&
      this.parent.rightShoulderHandBehindHead_typeOther === ""
    ) {
      return false;
    }

    return true;
  }),

  rightShoulderHandBehindHead_status: yup.bool().test("rightShoulderHandBehindHead_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.rightShoulderHandBehindHead_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightShoulderHandBehindHead_discomfortResolvedImmediately === false &&
      this.parent.rightShoulderHandBehindHead_discomfortPersisting === false &&
      this.parent.rightShoulderHandBehindHead_discomfortResolvedWithRest === false &&
      this.parent.rightShoulderHandBehindHead_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  rightShoulderHandBehindBack: yup.bool().test("rightShoulderHandBehindBack", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      upperLimbMobilityPainFree,
      rightShoulderHandBehindBack_unable,
      rightShoulderHandBehindBack_lessThanOneThird,
      rightShoulderHandBehindBack_lessThanTwoThirds,
      rightShoulderHandBehindBack_greaterThanTwoThirds,
      rightShoulderHandBehindBack_objectiveRange,
      rightShoulderHandBehindBack_limitingFactor,
      rightShoulderHandBehindBack_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (upperLimbMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (rightShoulderHandBehindBack_unable ||
        rightShoulderHandBehindBack_lessThanOneThird ||
        rightShoulderHandBehindBack_lessThanTwoThirds ||
        rightShoulderHandBehindBack_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!rightShoulderHandBehindBack_objectiveRange ||
        //or the limiting factor has not been selected,
        rightShoulderHandBehindBack_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((rightShoulderHandBehindBack_limitingFactor === "MUSCLETIGHTNESS" || rightShoulderHandBehindBack_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !rightShoulderHandBehindBack_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are unset
      (!!rightShoulderHandBehindBack_objectiveRange || rightShoulderHandBehindBack_limitingFactor !== "SELECT" || !!rightShoulderHandBehindBack_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !rightShoulderHandBehindBack_unable &&
      !rightShoulderHandBehindBack_lessThanOneThird &&
      !rightShoulderHandBehindBack_lessThanTwoThirds &&
      !rightShoulderHandBehindBack_greaterThanTwoThirds
    ) {
      //then the rightShoulderHandBehindBack fields are not valid.
      return false;
    }

    return true;
  }),
  rightShoulderHandBehindBack_location: yup.string().test("rightShoulderHandBehindBack_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.rightShoulderHandBehindBack_limitingFactor === limitingFactors.PAIN && this.parent.rightShoulderHandBehindBack_location === "") {
      return false;
    }

    return true;
  }),

  rightShoulderHandBehindBack_intensity: yup.bool().test("rightShoulderHandBehindBack_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.rightShoulderHandBehindBack_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightShoulderHandBehindBack_intensityVas === "" &&
      this.parent.rightShoulderHandBehindBack_intensityMild === false &&
      this.parent.rightShoulderHandBehindBack_intensityMod === false &&
      this.parent.rightShoulderHandBehindBack_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  rightShoulderHandBehindBack_type: yup.bool().test("rightShoulderHandBehindBack_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.rightShoulderHandBehindBack_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightShoulderHandBehindBack_discomfortTypeAche === false &&
      this.parent.rightShoulderHandBehindBack_discomfortTypeSharp === false &&
      this.parent.rightShoulderHandBehindBack_discomfortTypeBurning === false &&
      this.parent.rightShoulderHandBehindBack_discomfortTypeTightness === false &&
      this.parent.rightShoulderHandBehindBack_discomfortTypePulling === false &&
      this.parent.rightShoulderHandBehindBack_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  rightShoulderHandBehindBack_typeOther: yup.string().test("rightShoulderHandBehindBack_typeOther", "Please describe the discomfort further.", function () {
    if (
      this.parent.rightShoulderHandBehindBack_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightShoulderHandBehindBack_discomfortTypeOther === true &&
      this.parent.rightShoulderHandBehindBack_typeOther === ""
    ) {
      return false;
    }

    return true;
  }),

  rightShoulderHandBehindBack_status: yup.bool().test("rightShoulderHandBehindBack_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.rightShoulderHandBehindBack_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightShoulderHandBehindBack_discomfortResolvedImmediately === false &&
      this.parent.rightShoulderHandBehindBack_discomfortPersisting === false &&
      this.parent.rightShoulderHandBehindBack_discomfortResolvedWithRest === false &&
      this.parent.rightShoulderHandBehindBack_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  rightElbowForearmExtension: yup.bool().test("rightElbowForearmExtension", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      upperLimbMobilityPainFree,
      rightElbowForearmExtension_unable,
      rightElbowForearmExtension_lessThanOneThird,
      rightElbowForearmExtension_lessThanTwoThirds,
      rightElbowForearmExtension_greaterThanTwoThirds,
      rightElbowForearmExtension_objectiveRange,
      rightElbowForearmExtension_limitingFactor,
      rightElbowForearmExtension_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (upperLimbMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (rightElbowForearmExtension_unable ||
        rightElbowForearmExtension_lessThanOneThird ||
        rightElbowForearmExtension_lessThanTwoThirds ||
        rightElbowForearmExtension_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!rightElbowForearmExtension_objectiveRange ||
        //or the limiting factor has not been selected,
        rightElbowForearmExtension_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((rightElbowForearmExtension_limitingFactor === "MUSCLETIGHTNESS" || rightElbowForearmExtension_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !rightElbowForearmExtension_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are unset
      (!!rightElbowForearmExtension_objectiveRange || rightElbowForearmExtension_limitingFactor !== "SELECT" || !!rightElbowForearmExtension_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !rightElbowForearmExtension_unable &&
      !rightElbowForearmExtension_lessThanOneThird &&
      !rightElbowForearmExtension_lessThanTwoThirds &&
      !rightElbowForearmExtension_greaterThanTwoThirds
    ) {
      //then the rightElbowForearmExtension fields are not valid.
      return false;
    }

    return true;
  }),
  rightElbowForearmExtension_location: yup.string().test("rightElbowForearmExtension_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.rightElbowForearmExtension_limitingFactor === limitingFactors.PAIN && this.parent.rightElbowForearmExtension_location === "") {
      return false;
    }

    return true;
  }),

  rightElbowForearmExtension_intensity: yup.bool().test("rightElbowForearmExtension_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.rightElbowForearmExtension_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightElbowForearmExtension_intensityVas === "" &&
      this.parent.rightElbowForearmExtension_intensityMild === false &&
      this.parent.rightElbowForearmExtension_intensityMod === false &&
      this.parent.rightElbowForearmExtension_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  rightElbowForearmExtension_type: yup.bool().test("rightElbowForearmExtension_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.rightElbowForearmExtension_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightElbowForearmExtension_discomfortTypeAche === false &&
      this.parent.rightElbowForearmExtension_discomfortTypeSharp === false &&
      this.parent.rightElbowForearmExtension_discomfortTypeBurning === false &&
      this.parent.rightElbowForearmExtension_discomfortTypeTightness === false &&
      this.parent.rightElbowForearmExtension_discomfortTypePulling === false &&
      this.parent.rightElbowForearmExtension_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  rightElbowForearmExtension_typeOther: yup.string().test("rightElbowForearmExtension_typeOther", "Please describe the discomfort further.", function () {
    if (
      this.parent.rightElbowForearmExtension_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightElbowForearmExtension_discomfortTypeOther === true &&
      this.parent.rightElbowForearmExtension_typeOther === ""
    ) {
      return false;
    }

    return true;
  }),

  rightElbowForearmExtension_status: yup.bool().test("rightElbowForearmExtension_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.rightElbowForearmExtension_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightElbowForearmExtension_discomfortResolvedImmediately === false &&
      this.parent.rightElbowForearmExtension_discomfortPersisting === false &&
      this.parent.rightElbowForearmExtension_discomfortResolvedWithRest === false &&
      this.parent.rightElbowForearmExtension_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  rightElbowForearmFlexion: yup.bool().test("rightElbowForearmFlexion", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      upperLimbMobilityPainFree,
      rightElbowForearmFlexion_unable,
      rightElbowForearmFlexion_lessThanOneThird,
      rightElbowForearmFlexion_lessThanTwoThirds,
      rightElbowForearmFlexion_greaterThanTwoThirds,
      rightElbowForearmFlexion_objectiveRange,
      rightElbowForearmFlexion_limitingFactor,
      rightElbowForearmFlexion_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (upperLimbMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (rightElbowForearmFlexion_unable ||
        rightElbowForearmFlexion_lessThanOneThird ||
        rightElbowForearmFlexion_lessThanTwoThirds ||
        rightElbowForearmFlexion_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!rightElbowForearmFlexion_objectiveRange ||
        //or the limiting factor has not been selected,
        rightElbowForearmFlexion_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((rightElbowForearmFlexion_limitingFactor === "MUSCLETIGHTNESS" || rightElbowForearmFlexion_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !rightElbowForearmFlexion_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are unset
      (!!rightElbowForearmFlexion_objectiveRange || rightElbowForearmFlexion_limitingFactor !== "SELECT" || !!rightElbowForearmFlexion_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !rightElbowForearmFlexion_unable &&
      !rightElbowForearmFlexion_lessThanOneThird &&
      !rightElbowForearmFlexion_lessThanTwoThirds &&
      !rightElbowForearmFlexion_greaterThanTwoThirds
    ) {
      //then the rightElbowForearmFlexion fields are not valid.
      return false;
    }

    return true;
  }),
  rightElbowForearmFlexion_location: yup.string().test("rightElbowForearmFlexion_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.rightElbowForearmFlexion_limitingFactor === limitingFactors.PAIN && this.parent.rightElbowForearmFlexion_location === "") {
      return false;
    }

    return true;
  }),

  rightElbowForearmFlexion_intensity: yup.bool().test("rightElbowForearmFlexion_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.rightElbowForearmFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightElbowForearmFlexion_intensityVas === "" &&
      this.parent.rightElbowForearmFlexion_intensityMild === false &&
      this.parent.rightElbowForearmFlexion_intensityMod === false &&
      this.parent.rightElbowForearmFlexion_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  rightElbowForearmFlexion_type: yup.bool().test("rightElbowForearmFlexion_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.rightElbowForearmFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightElbowForearmFlexion_discomfortTypeAche === false &&
      this.parent.rightElbowForearmFlexion_discomfortTypeSharp === false &&
      this.parent.rightElbowForearmFlexion_discomfortTypeBurning === false &&
      this.parent.rightElbowForearmFlexion_discomfortTypeTightness === false &&
      this.parent.rightElbowForearmFlexion_discomfortTypePulling === false &&
      this.parent.rightElbowForearmFlexion_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  rightElbowForearmFlexion_typeOther: yup.string().test("rightElbowForearmFlexion_typeOther", "Please describe the discomfort further.", function () {
    if (
      this.parent.rightElbowForearmFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightElbowForearmFlexion_discomfortTypeOther === true &&
      this.parent.rightElbowForearmFlexion_typeOther === ""
    ) {
      return false;
    }

    return true;
  }),

  rightElbowForearmFlexion_status: yup.bool().test("rightElbowForearmFlexion_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.rightElbowForearmFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightElbowForearmFlexion_discomfortResolvedImmediately === false &&
      this.parent.rightElbowForearmFlexion_discomfortPersisting === false &&
      this.parent.rightElbowForearmFlexion_discomfortResolvedWithRest === false &&
      this.parent.rightElbowForearmFlexion_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  rightElbowForearmSupination: yup.bool().test("rightElbowForearmSupination", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      upperLimbMobilityPainFree,
      rightElbowForearmSupination_unable,
      rightElbowForearmSupination_lessThanOneThird,
      rightElbowForearmSupination_lessThanTwoThirds,
      rightElbowForearmSupination_greaterThanTwoThirds,
      rightElbowForearmSupination_objectiveRange,
      rightElbowForearmSupination_limitingFactor,
      rightElbowForearmSupination_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (upperLimbMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (rightElbowForearmSupination_unable ||
        rightElbowForearmSupination_lessThanOneThird ||
        rightElbowForearmSupination_lessThanTwoThirds ||
        rightElbowForearmSupination_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!rightElbowForearmSupination_objectiveRange ||
        //or the limiting factor has not been selected,
        rightElbowForearmSupination_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((rightElbowForearmSupination_limitingFactor === "MUSCLETIGHTNESS" || rightElbowForearmSupination_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !rightElbowForearmSupination_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are unset
      (!!rightElbowForearmSupination_objectiveRange || rightElbowForearmSupination_limitingFactor !== "SELECT" || !!rightElbowForearmSupination_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !rightElbowForearmSupination_unable &&
      !rightElbowForearmSupination_lessThanOneThird &&
      !rightElbowForearmSupination_lessThanTwoThirds &&
      !rightElbowForearmSupination_greaterThanTwoThirds
    ) {
      //then the rightElbowForearmSupination fields are not valid.
      return false;
    }

    return true;
  }),
  rightElbowForearmSupination_location: yup.string().test("rightElbowForearmSupination_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.rightElbowForearmSupination_limitingFactor === limitingFactors.PAIN && this.parent.rightElbowForearmSupination_location === "") {
      return false;
    }

    return true;
  }),

  rightElbowForearmSupination_intensity: yup.bool().test("rightElbowForearmSupination_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.rightElbowForearmSupination_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightElbowForearmSupination_intensityVas === "" &&
      this.parent.rightElbowForearmSupination_intensityMild === false &&
      this.parent.rightElbowForearmSupination_intensityMod === false &&
      this.parent.rightElbowForearmSupination_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  rightElbowForearmSupination_type: yup.bool().test("rightElbowForearmSupination_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.rightElbowForearmSupination_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightElbowForearmSupination_discomfortTypeAche === false &&
      this.parent.rightElbowForearmSupination_discomfortTypeSharp === false &&
      this.parent.rightElbowForearmSupination_discomfortTypeBurning === false &&
      this.parent.rightElbowForearmSupination_discomfortTypeTightness === false &&
      this.parent.rightElbowForearmSupination_discomfortTypePulling === false &&
      this.parent.rightElbowForearmSupination_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  rightElbowForearmSupination_typeOther: yup.string().test("rightElbowForearmSupination_typeOther", "Please describe the discomfort further.", function () {
    if (
      this.parent.rightElbowForearmSupination_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightElbowForearmSupination_discomfortTypeOther === true &&
      this.parent.rightElbowForearmSupination_typeOther === ""
    ) {
      return false;
    }

    return true;
  }),

  rightElbowForearmSupination_status: yup.bool().test("rightElbowForearmSupination_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.rightElbowForearmSupination_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightElbowForearmSupination_discomfortResolvedImmediately === false &&
      this.parent.rightElbowForearmSupination_discomfortPersisting === false &&
      this.parent.rightElbowForearmSupination_discomfortResolvedWithRest === false &&
      this.parent.rightElbowForearmSupination_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  rightWristHandExtension: yup.bool().test("rightWristHandExtension", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      upperLimbMobilityPainFree,
      rightWristHandExtension_unable,
      rightWristHandExtension_lessThanOneThird,
      rightWristHandExtension_lessThanTwoThirds,
      rightWristHandExtension_greaterThanTwoThirds,
      rightWristHandExtension_objectiveRange,
      rightWristHandExtension_limitingFactor,
      rightWristHandExtension_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (upperLimbMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (rightWristHandExtension_unable || rightWristHandExtension_lessThanOneThird || rightWristHandExtension_lessThanTwoThirds || rightWristHandExtension_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!rightWristHandExtension_objectiveRange ||
        //or the limiting factor has not been selected,
        rightWristHandExtension_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((rightWristHandExtension_limitingFactor === "MUSCLETIGHTNESS" || rightWristHandExtension_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !rightWristHandExtension_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are unset
      (!!rightWristHandExtension_objectiveRange || rightWristHandExtension_limitingFactor !== "SELECT" || !!rightWristHandExtension_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !rightWristHandExtension_unable &&
      !rightWristHandExtension_lessThanOneThird &&
      !rightWristHandExtension_lessThanTwoThirds &&
      !rightWristHandExtension_greaterThanTwoThirds
    ) {
      //then the rightWristHandExtension fields are not valid.
      return false;
    }

    return true;
  }),
  rightWristHandExtension_location: yup.string().test("rightWristHandExtension_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.rightWristHandExtension_limitingFactor === limitingFactors.PAIN && this.parent.rightWristHandExtension_location === "") {
      return false;
    }

    return true;
  }),

  rightWristHandExtension_intensity: yup.bool().test("rightWristHandExtension_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.rightWristHandExtension_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightWristHandExtension_intensityVas === "" &&
      this.parent.rightWristHandExtension_intensityMild === false &&
      this.parent.rightWristHandExtension_intensityMod === false &&
      this.parent.rightWristHandExtension_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  rightWristHandExtension_type: yup.bool().test("rightWristHandExtension_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.rightWristHandExtension_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightWristHandExtension_discomfortTypeAche === false &&
      this.parent.rightWristHandExtension_discomfortTypeSharp === false &&
      this.parent.rightWristHandExtension_discomfortTypeBurning === false &&
      this.parent.rightWristHandExtension_discomfortTypeTightness === false &&
      this.parent.rightWristHandExtension_discomfortTypePulling === false &&
      this.parent.rightWristHandExtension_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  rightWristHandExtension_typeOther: yup.string().test("rightWristHandExtension_typeOther", "Please describe the discomfort further.", function () {
    if (
      this.parent.rightWristHandExtension_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightWristHandExtension_discomfortTypeOther === true &&
      this.parent.rightWristHandExtension_typeOther === ""
    ) {
      return false;
    }

    return true;
  }),

  rightWristHandExtension_status: yup.bool().test("rightWristHandExtension_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.rightWristHandExtension_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightWristHandExtension_discomfortResolvedImmediately === false &&
      this.parent.rightWristHandExtension_discomfortPersisting === false &&
      this.parent.rightWristHandExtension_discomfortResolvedWithRest === false &&
      this.parent.rightWristHandExtension_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  rightWristHandFlexion: yup.bool().test("rightWristHandFlexion", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      upperLimbMobilityPainFree,
      rightWristHandFlexion_unable,
      rightWristHandFlexion_lessThanOneThird,
      rightWristHandFlexion_lessThanTwoThirds,
      rightWristHandFlexion_greaterThanTwoThirds,
      rightWristHandFlexion_objectiveRange,
      rightWristHandFlexion_limitingFactor,
      rightWristHandFlexion_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (upperLimbMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (rightWristHandFlexion_unable || rightWristHandFlexion_lessThanOneThird || rightWristHandFlexion_lessThanTwoThirds || rightWristHandFlexion_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!rightWristHandFlexion_objectiveRange ||
        //or the limiting factor has not been selected,
        rightWristHandFlexion_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((rightWristHandFlexion_limitingFactor === "MUSCLETIGHTNESS" || rightWristHandFlexion_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !rightWristHandFlexion_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are unset
      (!!rightWristHandFlexion_objectiveRange || rightWristHandFlexion_limitingFactor !== "SELECT" || !!rightWristHandFlexion_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !rightWristHandFlexion_unable &&
      !rightWristHandFlexion_lessThanOneThird &&
      !rightWristHandFlexion_lessThanTwoThirds &&
      !rightWristHandFlexion_greaterThanTwoThirds
    ) {
      //then the rightWristHandFlexion fields are not valid.
      return false;
    }

    return true;
  }),
  rightWristHandFlexion_location: yup.string().test("rightWristHandFlexion_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.rightWristHandFlexion_limitingFactor === limitingFactors.PAIN && this.parent.rightWristHandFlexion_location === "") {
      return false;
    }

    return true;
  }),

  rightWristHandFlexion_intensity: yup.bool().test("rightWristHandFlexion_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.rightWristHandFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightWristHandFlexion_intensityVas === "" &&
      this.parent.rightWristHandFlexion_intensityMild === false &&
      this.parent.rightWristHandFlexion_intensityMod === false &&
      this.parent.rightWristHandFlexion_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  rightWristHandFlexion_type: yup.bool().test("rightWristHandFlexion_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.rightWristHandFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightWristHandFlexion_discomfortTypeAche === false &&
      this.parent.rightWristHandFlexion_discomfortTypeSharp === false &&
      this.parent.rightWristHandFlexion_discomfortTypeBurning === false &&
      this.parent.rightWristHandFlexion_discomfortTypeTightness === false &&
      this.parent.rightWristHandFlexion_discomfortTypePulling === false &&
      this.parent.rightWristHandFlexion_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  rightWristHandFlexion_typeOther: yup.string().test("rightWristHandFlexion_typeOther", "Please describe the discomfort further.", function () {
    if (
      this.parent.rightWristHandFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightWristHandFlexion_discomfortTypeOther === true &&
      this.parent.rightWristHandFlexion_typeOther === ""
    ) {
      return false;
    }

    return true;
  }),

  rightWristHandFlexion_status: yup.bool().test("rightWristHandFlexion_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.rightWristHandFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightWristHandFlexion_discomfortResolvedImmediately === false &&
      this.parent.rightWristHandFlexion_discomfortPersisting === false &&
      this.parent.rightWristHandFlexion_discomfortResolvedWithRest === false &&
      this.parent.rightWristHandFlexion_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  rightWristHandRadialDeviation: yup.bool().test("rightWristHandRadialDeviation", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      upperLimbMobilityPainFree,
      rightWristHandRadialDeviation_unable,
      rightWristHandRadialDeviation_lessThanOneThird,
      rightWristHandRadialDeviation_lessThanTwoThirds,
      rightWristHandRadialDeviation_greaterThanTwoThirds,
      rightWristHandRadialDeviation_objectiveRange,
      rightWristHandRadialDeviation_limitingFactor,
      rightWristHandRadialDeviation_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (upperLimbMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (rightWristHandRadialDeviation_unable ||
        rightWristHandRadialDeviation_lessThanOneThird ||
        rightWristHandRadialDeviation_lessThanTwoThirds ||
        rightWristHandRadialDeviation_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!rightWristHandRadialDeviation_objectiveRange ||
        //or the limiting factor has not been selected,
        rightWristHandRadialDeviation_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((rightWristHandRadialDeviation_limitingFactor === "MUSCLETIGHTNESS" || rightWristHandRadialDeviation_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !rightWristHandRadialDeviation_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are unset
      (!!rightWristHandRadialDeviation_objectiveRange || rightWristHandRadialDeviation_limitingFactor !== "SELECT" || !!rightWristHandRadialDeviation_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !rightWristHandRadialDeviation_unable &&
      !rightWristHandRadialDeviation_lessThanOneThird &&
      !rightWristHandRadialDeviation_lessThanTwoThirds &&
      !rightWristHandRadialDeviation_greaterThanTwoThirds
    ) {
      //then the rightWristHandRadialDeviation fields are not valid.
      return false;
    }

    return true;
  }),
  rightWristHandRadialDeviation_location: yup.string().test("rightWristHandRadialDeviation_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.rightWristHandRadialDeviation_limitingFactor === limitingFactors.PAIN && this.parent.rightWristHandRadialDeviation_location === "") {
      return false;
    }

    return true;
  }),

  rightWristHandRadialDeviation_intensity: yup.bool().test("rightWristHandRadialDeviation_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.rightWristHandRadialDeviation_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightWristHandRadialDeviation_intensityVas === "" &&
      this.parent.rightWristHandRadialDeviation_intensityMild === false &&
      this.parent.rightWristHandRadialDeviation_intensityMod === false &&
      this.parent.rightWristHandRadialDeviation_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  rightWristHandRadialDeviation_type: yup.bool().test("rightWristHandRadialDeviation_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.rightWristHandRadialDeviation_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightWristHandRadialDeviation_discomfortTypeAche === false &&
      this.parent.rightWristHandRadialDeviation_discomfortTypeSharp === false &&
      this.parent.rightWristHandRadialDeviation_discomfortTypeBurning === false &&
      this.parent.rightWristHandRadialDeviation_discomfortTypeTightness === false &&
      this.parent.rightWristHandRadialDeviation_discomfortTypePulling === false &&
      this.parent.rightWristHandRadialDeviation_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  rightWristHandRadialDeviation_typeOther: yup.string().test("rightWristHandRadialDeviation_typeOther", "Please describe the discomfort further.", function () {
    if (
      this.parent.rightWristHandRadialDeviation_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightWristHandRadialDeviation_discomfortTypeOther === true &&
      this.parent.rightWristHandRadialDeviation_typeOther === ""
    ) {
      return false;
    }

    return true;
  }),

  rightWristHandRadialDeviation_status: yup.bool().test("rightWristHandRadialDeviation_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.rightWristHandRadialDeviation_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightWristHandRadialDeviation_discomfortResolvedImmediately === false &&
      this.parent.rightWristHandRadialDeviation_discomfortPersisting === false &&
      this.parent.rightWristHandRadialDeviation_discomfortResolvedWithRest === false &&
      this.parent.rightWristHandRadialDeviation_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  rightWristHandUlnarDeviation: yup.bool().test("rightWristHandUlnarDeviation", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      upperLimbMobilityPainFree,
      rightWristHandUlnarDeviation_unable,
      rightWristHandUlnarDeviation_lessThanOneThird,
      rightWristHandUlnarDeviation_lessThanTwoThirds,
      rightWristHandUlnarDeviation_greaterThanTwoThirds,
      rightWristHandUlnarDeviation_objectiveRange,
      rightWristHandUlnarDeviation_limitingFactor,
      rightWristHandUlnarDeviation_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (upperLimbMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (rightWristHandUlnarDeviation_unable ||
        rightWristHandUlnarDeviation_lessThanOneThird ||
        rightWristHandUlnarDeviation_lessThanTwoThirds ||
        rightWristHandUlnarDeviation_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!rightWristHandUlnarDeviation_objectiveRange ||
        //or the limiting factor has not been selected,
        rightWristHandUlnarDeviation_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((rightWristHandUlnarDeviation_limitingFactor === "MUSCLETIGHTNESS" || rightWristHandUlnarDeviation_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !rightWristHandUlnarDeviation_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are unset
      (!!rightWristHandUlnarDeviation_objectiveRange || rightWristHandUlnarDeviation_limitingFactor !== "SELECT" || !!rightWristHandUlnarDeviation_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !rightWristHandUlnarDeviation_unable &&
      !rightWristHandUlnarDeviation_lessThanOneThird &&
      !rightWristHandUlnarDeviation_lessThanTwoThirds &&
      !rightWristHandUlnarDeviation_greaterThanTwoThirds
    ) {
      //then the rightWristHandUlnarDeviation fields are not valid.
      return false;
    }

    return true;
  }),
  rightWristHandUlnarDeviation_location: yup.string().test("rightWristHandUlnarDeviation_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.rightWristHandUlnarDeviation_limitingFactor === limitingFactors.PAIN && this.parent.rightWristHandUlnarDeviation_location === "") {
      return false;
    }

    return true;
  }),

  rightWristHandUlnarDeviation_intensity: yup.bool().test("rightWristHandUlnarDeviation_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.rightWristHandUlnarDeviation_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightWristHandUlnarDeviation_intensityVas === "" &&
      this.parent.rightWristHandUlnarDeviation_intensityMild === false &&
      this.parent.rightWristHandUlnarDeviation_intensityMod === false &&
      this.parent.rightWristHandUlnarDeviation_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  rightWristHandUlnarDeviation_type: yup.bool().test("rightWristHandUlnarDeviation_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.rightWristHandUlnarDeviation_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightWristHandUlnarDeviation_discomfortTypeAche === false &&
      this.parent.rightWristHandUlnarDeviation_discomfortTypeSharp === false &&
      this.parent.rightWristHandUlnarDeviation_discomfortTypeBurning === false &&
      this.parent.rightWristHandUlnarDeviation_discomfortTypeTightness === false &&
      this.parent.rightWristHandUlnarDeviation_discomfortTypePulling === false &&
      this.parent.rightWristHandUlnarDeviation_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  rightWristHandUlnarDeviation_typeOther: yup.string().test("rightWristHandUlnarDeviation_typeOther", "Please describe the discomfort further.", function () {
    if (
      this.parent.rightWristHandUlnarDeviation_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightWristHandUlnarDeviation_discomfortTypeOther === true &&
      this.parent.rightWristHandUlnarDeviation_typeOther === ""
    ) {
      return false;
    }

    return true;
  }),

  rightWristHandUlnarDeviation_status: yup.bool().test("rightWristHandUlnarDeviation_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.rightWristHandUlnarDeviation_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightWristHandUlnarDeviation_discomfortResolvedImmediately === false &&
      this.parent.rightWristHandUlnarDeviation_discomfortPersisting === false &&
      this.parent.rightWristHandUlnarDeviation_discomfortResolvedWithRest === false &&
      this.parent.rightWristHandUlnarDeviation_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  rightHandFingerOppositionToThumb: yup
    .bool()
    .test("rightHandFingerOppositionToThumb", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
      const {
        upperLimbMobilityPainFree,
        rightHandFingerOppositionToThumb_unable,
        rightHandFingerOppositionToThumb_lessThanOneThird,
        rightHandFingerOppositionToThumb_lessThanTwoThirds,
        rightHandFingerOppositionToThumb_greaterThanTwoThirds,
        rightHandFingerOppositionToThumb_objectiveRange,
        rightHandFingerOppositionToThumb_limitingFactor,
        rightHandFingerOppositionToThumb_limitingFactorNotes,
      } = this.parent;

      //If the accordion checkbox is ticked, this is "valid".
      if (upperLimbMobilityPainFree) return true;

      if (
        //If a checkbox has been ticked and
        (rightHandFingerOppositionToThumb_unable ||
          rightHandFingerOppositionToThumb_lessThanOneThird ||
          rightHandFingerOppositionToThumb_lessThanTwoThirds ||
          rightHandFingerOppositionToThumb_greaterThanTwoThirds) &&
        //the objective range is empty,
        (!rightHandFingerOppositionToThumb_objectiveRange ||
          //or the limiting factor has not been selected,
          rightHandFingerOppositionToThumb_limitingFactor === "SELECT" ||
          //or a limiting factor has been selected and
          ((rightHandFingerOppositionToThumb_limitingFactor === "MUSCLETIGHTNESS" || rightHandFingerOppositionToThumb_limitingFactor === "JOINTSTIFFNESS") &&
            //no notes have been provided,
            !rightHandFingerOppositionToThumb_limitingFactorNotes))
      ) {
        //then consider the bend forward data invalid.
        return false;
      }

      if (
        //If the objective range, limiting factor or limiting factor notes are unset
        (!!rightHandFingerOppositionToThumb_objectiveRange ||
          rightHandFingerOppositionToThumb_limitingFactor !== "SELECT" ||
          !!rightHandFingerOppositionToThumb_limitingFactorNotes) &&
        //and the checkboxes are not set,
        !rightHandFingerOppositionToThumb_unable &&
        !rightHandFingerOppositionToThumb_lessThanOneThird &&
        !rightHandFingerOppositionToThumb_lessThanTwoThirds &&
        !rightHandFingerOppositionToThumb_greaterThanTwoThirds
      ) {
        //then the rightHandFingerOppositionToThumb fields are not valid.
        return false;
      }

      return true;
    }),
  rightHandFingerOppositionToThumb_location: yup.string().test("rightHandFingerOppositionToThumb_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.rightHandFingerOppositionToThumb_limitingFactor === limitingFactors.PAIN && this.parent.rightHandFingerOppositionToThumb_location === "") {
      return false;
    }

    return true;
  }),

  rightHandFingerOppositionToThumb_intensity: yup.bool().test("rightHandFingerOppositionToThumb_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.rightHandFingerOppositionToThumb_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightHandFingerOppositionToThumb_intensityVas === "" &&
      this.parent.rightHandFingerOppositionToThumb_intensityMild === false &&
      this.parent.rightHandFingerOppositionToThumb_intensityMod === false &&
      this.parent.rightHandFingerOppositionToThumb_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  rightHandFingerOppositionToThumb_type: yup.bool().test("rightHandFingerOppositionToThumb_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.rightHandFingerOppositionToThumb_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightHandFingerOppositionToThumb_discomfortTypeAche === false &&
      this.parent.rightHandFingerOppositionToThumb_discomfortTypeSharp === false &&
      this.parent.rightHandFingerOppositionToThumb_discomfortTypeBurning === false &&
      this.parent.rightHandFingerOppositionToThumb_discomfortTypeTightness === false &&
      this.parent.rightHandFingerOppositionToThumb_discomfortTypePulling === false &&
      this.parent.rightHandFingerOppositionToThumb_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  rightHandFingerOppositionToThumb_typeOther: yup.string().test("rightHandFingerOppositionToThumb_typeOther", "Please describe the discomfort further.", function () {
    if (
      this.parent.rightHandFingerOppositionToThumb_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightHandFingerOppositionToThumb_discomfortTypeOther === true &&
      this.parent.rightHandFingerOppositionToThumb_typeOther === ""
    ) {
      return false;
    }

    return true;
  }),

  rightHandFingerOppositionToThumb_status: yup.bool().test("rightHandFingerOppositionToThumb_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.rightHandFingerOppositionToThumb_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightHandFingerOppositionToThumb_discomfortResolvedImmediately === false &&
      this.parent.rightHandFingerOppositionToThumb_discomfortPersisting === false &&
      this.parent.rightHandFingerOppositionToThumb_discomfortResolvedWithRest === false &&
      this.parent.rightHandFingerOppositionToThumb_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  rightHandFingerFullFist: yup.bool().test("rightHandFingerFullFist", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      upperLimbMobilityPainFree,
      rightHandFingerFullFist_unable,
      rightHandFingerFullFist_lessThanOneThird,
      rightHandFingerFullFist_lessThanTwoThirds,
      rightHandFingerFullFist_greaterThanTwoThirds,
      rightHandFingerFullFist_objectiveRange,
      rightHandFingerFullFist_limitingFactor,
      rightHandFingerFullFist_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (upperLimbMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (rightHandFingerFullFist_unable || rightHandFingerFullFist_lessThanOneThird || rightHandFingerFullFist_lessThanTwoThirds || rightHandFingerFullFist_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!rightHandFingerFullFist_objectiveRange ||
        //or the limiting factor has not been selected,
        rightHandFingerFullFist_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((rightHandFingerFullFist_limitingFactor === "MUSCLETIGHTNESS" || rightHandFingerFullFist_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !rightHandFingerFullFist_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are unset
      (!!rightHandFingerFullFist_objectiveRange || rightHandFingerFullFist_limitingFactor !== "SELECT" || !!rightHandFingerFullFist_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !rightHandFingerFullFist_unable &&
      !rightHandFingerFullFist_lessThanOneThird &&
      !rightHandFingerFullFist_lessThanTwoThirds &&
      !rightHandFingerFullFist_greaterThanTwoThirds
    ) {
      //then the rightHandFingerFullFist fields are not valid.
      return false;
    }

    return true;
  }),
  rightHandFingerFullFist_location: yup.string().test("rightHandFingerFullFist_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.rightHandFingerFullFist_limitingFactor === limitingFactors.PAIN && this.parent.rightHandFingerFullFist_location === "") {
      return false;
    }

    return true;
  }),

  rightHandFingerFullFist_intensity: yup.bool().test("rightHandFingerFullFist_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.rightHandFingerFullFist_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightHandFingerFullFist_intensityVas === "" &&
      this.parent.rightHandFingerFullFist_intensityMild === false &&
      this.parent.rightHandFingerFullFist_intensityMod === false &&
      this.parent.rightHandFingerFullFist_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  rightHandFingerFullFist_type: yup.bool().test("rightHandFingerFullFist_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.rightHandFingerFullFist_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightHandFingerFullFist_discomfortTypeAche === false &&
      this.parent.rightHandFingerFullFist_discomfortTypeSharp === false &&
      this.parent.rightHandFingerFullFist_discomfortTypeBurning === false &&
      this.parent.rightHandFingerFullFist_discomfortTypeTightness === false &&
      this.parent.rightHandFingerFullFist_discomfortTypePulling === false &&
      this.parent.rightHandFingerFullFist_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  rightHandFingerFullFist_typeOther: yup.string().test("rightHandFingerFullFist_typeOther", "Please describe the discomfort further.", function () {
    if (
      this.parent.rightHandFingerFullFist_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightHandFingerFullFist_discomfortTypeOther === true &&
      this.parent.rightHandFingerFullFist_typeOther === ""
    ) {
      return false;
    }

    return true;
  }),

  rightHandFingerFullFist_status: yup.bool().test("rightHandFingerFullFist_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.rightHandFingerFullFist_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightHandFingerFullFist_discomfortResolvedImmediately === false &&
      this.parent.rightHandFingerFullFist_discomfortPersisting === false &&
      this.parent.rightHandFingerFullFist_discomfortResolvedWithRest === false &&
      this.parent.rightHandFingerFullFist_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),

  leftShoulderStrengthFlexion: yup.bool().test("leftShoulderStrengthFlexion", "Please provide a description of the discomfort.", function () {
    const { leftShoulderStrengthFlexion_discomfort, leftShoulderStrengthFlexion_description } = this.parent;

    if (leftShoulderStrengthFlexion_discomfort === "YES" && leftShoulderStrengthFlexion_description === "") {
      return false;
    }
    return true;
  }),
  leftShoulderStrengthAbduction: yup.bool().test("leftShoulderStrengthAbduction", "Please provide a description of the discomfort.", function () {
    const { leftShoulderStrengthAbduction_discomfort, leftShoulderStrengthAbduction_description } = this.parent;

    if (leftShoulderStrengthAbduction_discomfort === "YES" && leftShoulderStrengthAbduction_description === "") {
      return false;
    }

    return true;
  }),
  leftShoulderStrengthInternalRotation: yup.bool().test("leftShoulderStrengthInternalRotation", "Please provide a description of the discomfort.", function () {
    const { leftShoulderStrengthInternalRotation_discomfort, leftShoulderStrengthInternalRotation_description } = this.parent;

    if (leftShoulderStrengthInternalRotation_discomfort === "YES" && leftShoulderStrengthInternalRotation_description === "") {
      return false;
    }
    return true;
  }),
  leftShoulderStrengthExternalRotation: yup.bool().test("leftShoulderStrengthExternalRotation", "Please provide a description of the discomfort.", function () {
    const { leftShoulderStrengthExternalRotation_discomfort, leftShoulderStrengthExternalRotation_description } = this.parent;

    if (leftShoulderStrengthExternalRotation_discomfort === "YES" && leftShoulderStrengthExternalRotation_description === "") {
      return false;
    }
    return true;
  }),
  leftElbowStrengthFlexion: yup.bool().test("leftElbowStrengthFlexion", "Please provide a description of the discomfort.", function () {
    const { leftElbowStrengthFlexion_discomfort, leftElbowStrengthFlexion_description } = this.parent;

    if (leftElbowStrengthFlexion_discomfort === "YES" && leftElbowStrengthFlexion_description === "") {
      return false;
    }
    return true;
  }),
  leftElbowStrengthExtension: yup.bool().test("leftElbowStrengthExtension", "Please provide a description of the discomfort.", function () {
    const { leftElbowStrengthExtension_discomfort, leftElbowStrengthExtension_description } = this.parent;

    if (leftElbowStrengthExtension_discomfort === "YES" && leftElbowStrengthExtension_description === "") {
      return false;
    }
    return true;
  }),
  leftElbowStrengthSupination: yup.bool().test("leftElbowStrengthSupination", "Please provide a description of the discomfort.", function () {
    const { leftElbowStrengthSupination_discomfort, leftElbowStrengthSupination_description } = this.parent;

    if (leftElbowStrengthSupination_discomfort === "YES" && leftElbowStrengthSupination_description === "") {
      return false;
    }
    return true;
  }),
  leftThumbStrengthExtensions: yup.bool().test("leftThumbStrengthExtensions", "Please provide a description of the discomfort.", function () {
    const { leftThumbStrengthExtensions_discomfort, leftThumbStrengthExtensions_description } = this.parent;

    if (leftThumbStrengthExtensions_discomfort === "YES" && leftThumbStrengthExtensions_description === "") {
      return false;
    }
    return true;
  }),
  leftThumbStrengthLumbricals: yup.bool().test("leftThumbStrengthLumbricals", "Please provide a description of the discomfort.", function () {
    const { leftThumbStrengthLumbricals_discomfort, leftThumbStrengthLumbricals_description } = this.parent;

    if (leftThumbStrengthLumbricals_discomfort === "YES" && leftThumbStrengthLumbricals_description === "") {
      return false;
    }
    return true;
  }),
  rightShoulderStrengthFlexion: yup.bool().test("rightShoulderStrengthFlexion", "Please provide a description of the discomfort.", function () {
    const { rightShoulderStrengthFlexion_discomfort, rightShoulderStrengthFlexion_description } = this.parent;

    if (rightShoulderStrengthFlexion_discomfort === "YES" && rightShoulderStrengthFlexion_description === "") {
      return false;
    }
    return true;
  }),
  rightShoulderStrengthAbduction: yup.bool().test("rightShoulderStrengthAbduction", "Please provide a description of the discomfort.", function () {
    const { rightShoulderStrengthAbduction_discomfort, rightShoulderStrengthAbduction_description } = this.parent;

    if (rightShoulderStrengthAbduction_discomfort === "YES" && rightShoulderStrengthAbduction_description === "") {
      return false;
    }
    return true;
  }),
  rightShoulderStrengthInternalRotation: yup.bool().test("rightShoulderStrengthInternalRotation", "Please provide a description of the discomfort.", function () {
    const { rightShoulderStrengthInternalRotation_discomfort, rightShoulderStrengthInternalRotation_description } = this.parent;

    if (rightShoulderStrengthInternalRotation_discomfort === "YES" && rightShoulderStrengthInternalRotation_description === "") {
      return false;
    }
    return true;
  }),
  rightShoulderStrengthExternalRotation: yup.bool().test("rightShoulderStrengthExternalRotation", "Please provide a description of the discomfort.", function () {
    const { rightShoulderStrengthExternalRotation_discomfort, rightShoulderStrengthExternalRotation_description } = this.parent;

    if (rightShoulderStrengthExternalRotation_discomfort === "YES" && rightShoulderStrengthExternalRotation_description === "") {
      return false;
    }
    return true;
  }),
  rightElbowStrengthFlexion: yup.bool().test("rightElbowStrengthFlexion", "Please provide a description of the discomfort.", function () {
    const { rightElbowStrengthFlexion_discomfort, rightElbowStrengthFlexion_description } = this.parent;

    if (rightElbowStrengthFlexion_discomfort === "YES" && rightElbowStrengthFlexion_description === "") {
      return false;
    }
    return true;
  }),
  rightElbowStrengthExtension: yup.bool().test("rightElbowStrengthExtension", "Please provide a description of the discomfort.", function () {
    const { rightElbowStrengthExtension_discomfort, rightElbowStrengthExtension_description } = this.parent;

    if (rightElbowStrengthExtension_discomfort === "YES" && rightElbowStrengthExtension_description === "") {
      return false;
    }
    return true;
  }),
  rightElbowStrengthSupination: yup.bool().test("rightElbowStrengthSupination", "Please provide a description of the discomfort.", function () {
    const { rightElbowStrengthSupination_discomfort, rightElbowStrengthSupination_description } = this.parent;

    if (rightElbowStrengthSupination_discomfort === "YES" && rightElbowStrengthSupination_description === "") {
      return false;
    }
    return true;
  }),
  rightThumbStrengthExtensions: yup.bool().test("rightThumbStrengthExtensions", "Please provide a description of the discomfort.", function () {
    const { rightThumbStrengthExtensions_discomfort, rightThumbStrengthExtensions_description } = this.parent;

    if (rightThumbStrengthExtensions_discomfort === "YES" && rightThumbStrengthExtensions_description === "") {
      return false;
    }
    return true;
  }),
  rightThumbStrengthLumbricals: yup.bool().test("rightThumbStrengthLumbricals", "Please provide a description of the discomfort.", function () {
    const { rightThumbStrengthLumbricals_discomfort, rightThumbStrengthLumbricals_description } = this.parent;

    if (rightThumbStrengthLumbricals_discomfort === "YES" && rightThumbStrengthLumbricals_description === "") {
      return false;
    }
    return true;
  }),
  leftJamarGripTest: yup.bool().test("leftJamarGripTest", "Please perform all three grip strength tests.", function () {
    const { sittingSpecialTests, leftJamarGripTrial1, leftJamarGripTrial2, leftJamarGripTrial3 } = this.parent;

    //If the nil-significant special tests checkbox is ticked, assume true.
    if (sittingSpecialTests) {
      return true;
    }

    //If the test has been started but not all attempts completed, prompt for the remaining attempts.
    if (!sittingSpecialTests && !!leftJamarGripTrial1 && (!leftJamarGripTrial2 || !leftJamarGripTrial3)) {
      return false;
    }

    return true;
  }),
  rightJamarGripTest: yup.bool().test("rightJamarGripTest", "Please perform all three grip strength tests.", function () {
    const { sittingSpecialTests, rightJamarGripTrial1, rightJamarGripTrial2, rightJamarGripTrial3 } = this.parent;

    //If the nil-significant special tests checkbox is ticked, assume true.
    if (sittingSpecialTests) {
      return true;
    }

    //If the test has been started but not all attempts completed, prompt for the remaining attempts.
    if (!sittingSpecialTests && !!rightJamarGripTrial1 && (!rightJamarGripTrial2 || !rightJamarGripTrial3)) {
      return false;
    }

    return true;
  }),
});

export default musculoskeletalValidation;

import { keyPairData } from ".";

export default class baselineMeasurements {
  public static formatForSubmission(baselineMeasures: { [key: string]: any }): keyPairData[] {
    const formattedData: keyPairData[] = [];

    Object.keys(baselineMeasures).forEach((key: string) => {
      formattedData.push({
        name: key,
        value: baselineMeasures[key]?.toString(),
      });
    });

    return formattedData;
  }
}

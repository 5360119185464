import React, { Component, ReactNode } from "react";
import { Card } from "@material-ui/core";
import { UserIcon } from "../../assets/images";
import { inject, observer } from "mobx-react";

interface ProfileCardProps {
  participantAgeYears: number;
  firstName: string;
  lastName: string;
  currentHeartRate?: string;
}

interface ProfileCardState {
  percentageBand: HeartRateBand;
  participantAgeYears: number;
  MHR: number;
  MHR85: number;
}

enum HeartRateBand {
  "UserProfile" = "UserProfile",
  "UserProfileGreen" = "UserProfileGreen",
  "UserProfileYellow" = "UserProfileYellow",
  "UserProfileOrange" = "UserProfileOrange",
  "UserProfileRed" = "UserProfileRed",
}

@inject("rootStore")
@observer
class ProfileCard extends Component<ProfileCardProps, ProfileCardState> {
  constructor(props: ProfileCardProps) {
    super(props);
    this.state = {
      percentageBand: HeartRateBand.UserProfile,
      participantAgeYears: this.props.participantAgeYears,
      MHR: 220 - this.props.participantAgeYears,
      MHR85: Math.floor((220 - this.props.participantAgeYears) * 0.85),
    };

    this.manageHeartRateVisualStyle = this.manageHeartRateVisualStyle.bind(this);
  }

  manageHeartRateVisualStyle(): void {
    if (!this.props.currentHeartRate) {
      return;
    }

    //calculate percentage heartrate of maximum
    const percentage = (parseInt(this.props.currentHeartRate) / this.state.MHR) * 100;

    let percentageBand = HeartRateBand.UserProfileGreen;
    if (percentage >= 75 && percentage < 80) {
      percentageBand = HeartRateBand.UserProfileYellow;
    } else if (percentage >= 80 && percentage < 85) {
      percentageBand = HeartRateBand.UserProfileOrange;
    } else if (percentage >= 85) {
      percentageBand = HeartRateBand.UserProfileRed;
    }

    this.setState((prevState) => ({ ...prevState, percentageBand }));
  }

  componentDidUpdate(prevProps: ProfileCardProps, prevState: ProfileCardState): void {
    if (this.props.currentHeartRate !== prevProps.currentHeartRate) {
      this.manageHeartRateVisualStyle();
    }
    if (this.props.participantAgeYears !== prevProps.participantAgeYears) {
      this.setState((prevState) => ({
        ...prevState,
        participantAgeYears: this.props.participantAgeYears,
        MHR: 220 - this.props.participantAgeYears,
        MHR85: Math.floor((220 - this.props.participantAgeYears) * 0.85),
      }));
    }
  }

  render(): ReactNode {
    return (
      <Card className={this.state.percentageBand} variant="outlined">
        {this.state.participantAgeYears > 0 ? <div className="MHR">85% MHR: {this.state.MHR85}bpm</div> : <div className="MHR">DOB is required</div>}
        <div
          style={{
            alignSelf: "center",
            textAlign: "center",
            marginTop: "20px",
          }}
        >
          <img alt="User" src={UserIcon} width="50px" />
          <div className="firstName">{this.props.firstName}</div>
          <div className="lastName">{this.props.lastName}</div>
        </div>
      </Card>
    );
  }
}

export default ProfileCard;

import React, { useEffect, useState } from "react";
import { ProfileCard, FormNavigator, Stepper, NotesModal, InTestMedicalClearanceModal } from "../../../components/";
import { Baseline } from "../../../assets/images/";
import ptValidation from "./validation";

import { Grid, Typography, Container, FormControl, FormControlLabel, Card, Checkbox, FormGroup } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import { Root } from "../../../stores";
import { differenceInYears } from "date-fns";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { dataStorePageKeys } from "../../../stores/dataStore";
import { useHistory } from "react-router-dom";
import { DASHBOARD, PROGRAMRECOMMENDATIONS } from "../../../routes/paths";
import { useAuth } from "oidc-react";
import withAppInsights from "../AppInsights";

interface fitnessTestProps {
  rootStore?: Root;
}

const mhOptionalComments = inject("rootStore")(
  observer((props: fitnessTestProps) => {
    let pefaAssessment: any;
    let prevData: any;
    let medicalHistoryData: any;
    let referralDetails: any;
    let endAssessment: any;
    let participantAgeYears = 0;
    const history = useHistory();
    const auth = useAuth();

    if (props.rootStore?.pefaStore.selectedPefa) {
      pefaAssessment = props.rootStore?.pefaStore.selectedPefa[0];
      prevData = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.MH_OPTIONAL_COMMENTS);
      referralDetails = props.rootStore?.dataStore.getCheckInData(pefaAssessment.id, dataStorePageKeys.REFERRAL_DETAILS);
      participantAgeYears = differenceInYears(new Date(), new Date(referralDetails?.dob ?? pefaAssessment.pefArequest.worker.dateOfBirth));
      medicalHistoryData = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.MEDICAL_DETAILS);
      endAssessment = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.END_ASSESSMENT);
    } else {
      history.push(DASHBOARD);
      return <></>;
    }

    const { register, handleSubmit, errors, setValue } = useForm({
      resolver: yupResolver(ptValidation),
    });

    const [state, setState] = useState({
      isLoading: true,
      timeElapsedFormatted: "",
      isTimerStoppedModalOpen: false,

      notTestedModalOpen: false,
      notTestedReason: prevData ? prevData.notTestedReason : "",
      notTestedNotes: prevData ? prevData.notTestedNotes : "",
      recoveryModalOpen: false,
      yesAllNilSignificant: false,
      viewAll: false,
      preTestHeartRateModalOpen: false,

      preTestHeartRateModalLocked: prevData ? !!prevData.preTestHeartRate : false,
      preTestHeartRate: prevData ? prevData.preTestHeartRate : "",
      attempts: [{ attempt: 1, result: "" }],
      lastAttempt: prevData ? prevData.lastAttempt : 1,
      isBreakTaken: false,
      recoveryTimeFormatted: "",
      isTestComplete: false,
      testWeight: -1,
      decision: "",

      stoppedReason: "",
      stoppedMessage: "",
      assessorStoppedKey: "",
      assessorStoppedModalOpen: false,
      assessorStoppedOptions: [] as any[],

      participantStoppedModalOpen: false,
      benchToAboveShoulderpostureAsymmetricalChecked: false,
      benchToAboveShoulderLossOfLoadControlChecked: false,
      benchToAboveShoulderLossOfTrunkControlChecked: false,
      benchToAboveShoulderInsufficientLLStrengthChecked: false,
      benchToAboveShoulderInsufficientULStrengthChecked: false,
      benchToAboveShoulderCVMaxChecked: false,
      benchToAboveShoulderDiscomfortReportedChecked: false,
      benchToAboveShoulderMaxLiftOtherChecked: false,
    });

    useEffect(() => {
      const timeOutId = setTimeout(() => {
        if (state.preTestHeartRate > 110) {
          setState((prevState) => ({
            ...prevState,
            preTestHeartRateModalOpen: true,
          }));
        }

        // TODO: Fix this so that if HR over 110, display modal. (Set at above 1100 to ignore unless over HR over 1100)
        // If user selects recovery timer display timer, if selects no rest time needed, carry on.
        if (!state.preTestHeartRateModalLocked && state.preTestHeartRate > 110) {
          setState((prevState) => ({
            ...prevState,
            preTestHeartRateModalOpen: true,
          }));
        }

        if (state.preTestHeartRateModalOpen && state.preTestHeartRate) {
          setState((prevState) => ({
            ...prevState,
            preTestHeartRateModalLocked: true,
          }));
        }
      }, 500);
      return () => clearTimeout(timeOutId);
    }, [state.preTestHeartRateModalLocked, state.preTestHeartRateModalOpen, state.preTestHeartRate, setValue]);

    const submitForm = (data: any, forwardTarget: any) => {
      const pefaData = { ...props.rootStore?.dataStore.pefaData[pefaAssessment.id] };
      // take snapshot of assessment and store against booking
      props.rootStore?.pefaStore.nextSubmit(pefaData, pefaAssessment.id);
      history.push(forwardTarget);
    };

    return (
      <>
        <div className="FormStyler">
          <div className="AssessmentDetails">
            <Grid item container direction="column" xs={12}>
              <Stepper targetStep={"Manual Handling"} />
              <Container maxWidth={"lg"}>
                <Grid item container direction="row" xs={12}>
                  <Grid item container xs={9}>
                    <Card className="uCard" variant="outlined">
                      <Typography variant="h2" className="cardHeroHeading">
                        <img src={Baseline} alt="Baseline" /> Optional Comments
                      </Typography>
                      <FormControl component="fieldset" style={{ width: "100%", marginTop: "50px" }}>
                        <FormGroup className="formGroupSmMargin">
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={<span className="checkboxIcon"></span>}
                                checkedIcon={<span className="checkboxIconChecked"></span>}
                                color="primary"
                                name="participantRequiredFrequentRestBreaks"
                              />
                            }
                            label="Participant required frequent / prolonged rest breaks during manual handling due to elevated heart rate >85% MHR. Caution with sustained or strenuous physical activity, particularly in hot conditions."
                          />
                        </FormGroup>
                        <FormGroup className="formGroupSmMargin">
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={<span className="checkboxIcon"></span>}
                                checkedIcon={<span className="checkboxIconChecked"></span>}
                                color="primary"
                                name="participantHRRemainedOver85Percent"
                              />
                            }
                            label="Participant's heart rate remained < 85% max HR without requiring rest breaks."
                          />
                        </FormGroup>
                        <FormGroup className="formGroupSmMargin">
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={<span className="checkboxIcon"></span>}
                                checkedIcon={<span className="checkboxIconChecked"></span>}
                                color="primary"
                                name="participantRequiredFrequentCues"
                              />
                            }
                            label="Paticipant’s required frequent cues for safe manual handling."
                          />
                        </FormGroup>
                        <FormGroup className="formGroupSmMargin">
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={<span className="checkboxIcon"></span>}
                                checkedIcon={<span className="checkboxIconChecked"></span>}
                                color="primary"
                                name="safeManualHandlingTechniquesObserved"
                              />
                            }
                            label="Safe manual handling techniques observed."
                          />
                        </FormGroup>
                      </FormControl>
                    </Card>
                  </Grid>
                  <Grid item container xs={3} className="RightCol">
                    <ProfileCard
                      firstName={pefaAssessment.pefArequest.worker.firstName}
                      lastName={pefaAssessment.pefArequest.worker.lastName}
                      participantAgeYears={participantAgeYears}
                    />
                    <NotesModal pageKey={dataStorePageKeys.MH_OPTIONAL_COMMENTS} pefaID={pefaAssessment.id} />
                    <InTestMedicalClearanceModal
                      firstName={referralDetails.firstName}
                      lastName={referralDetails.lastName}
                      pageKey={dataStorePageKeys.MH_OPTIONAL_COMMENTS}
                      pefaID={pefaAssessment.id}
                      medicalPractice={medicalHistoryData.medicalPractitioner}
                      medicalPractitioner={medicalHistoryData.medicalPractice}
                    />
                  </Grid>
                </Grid>
              </Container>
              <FormNavigator
                forwardTarget={PROGRAMRECOMMENDATIONS}
                handleSubmit={handleSubmit(submitForm)}
                warning="Please remember to gradually increase weights as per protocol"
                disabledNextButton={
                  endAssessment &&
                  endAssessment.endAssessmentReason !== "" &&
                  endAssessment.endAssessmentNotes !== "" &&
                  endAssessment.endAssessmentPage === dataStorePageKeys.MH_OPTIONAL_COMMENTS
                }
                selectedKey={dataStorePageKeys.MH_OPTIONAL_COMMENTS}
              />
            </Grid>
          </div>
        </div>
      </>
    );
  })
);

export default withAppInsights(mhOptionalComments);

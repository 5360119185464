import * as yup from "yup";

const pefaFinalisationSchema = yup.object().shape({
  jobFitSystemScore: yup.string().required("Please provide a PEFA score."),
  minimalRestrictionsDescription: yup.string().test("minimalRestrictionsDescription", "Please provide further details for the restrictions.", function () {
    if (this.parent.jobFitSystemScore === "2" && this.parent.minimalRestrictionsDescription === "") {
      return false;
    }
    return true;
  }),
  moderateRestrictionsDescription: yup.string().test("moderateRestrictionsDescription", "Please provide further details for the restrictions.", function () {
    if (this.parent.jobFitSystemScore === "3" && this.parent.moderateRestrictionsDescription === "") {
      return false;
    }
    return true;
  }),
});

export default pefaFinalisationSchema;

import { Constants } from "../../helpers/Constants";

export class AuthService {
  public async getUserManagerDetails(): Promise<string> {
    const environmentConstants = Constants.environment();

    const response = await fetch(environmentConstants?.configUrl);
    if (!response.ok) {
      throw new Error(`Could not load settings for '${Constants.applicationName}'`);
    }

    const settings = await response.json();
    return settings;
  }
}

const authService = new AuthService();

export default authService;

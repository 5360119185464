import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin, withAITracking } from "@microsoft/applicationinsights-react-js";
import { globalHistory } from "@reach/router";
import { Constants } from "../../helpers/Constants";

const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
  config: {
    instrumentationKey: Constants.applicationInsightsKey,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: globalHistory },
    },
  },
});

ai.loadAppInsights();

export default (Component) => withAITracking(reactPlugin, Component);
export const appInsights = ai.appInsights;

export enum limitingFactors {
  "MUSCLETIGHTNESS" = "MUSCLETIGHTNESS",
  "JOINTSTIFFNESS" = "JOINTSTIFFNESS",
  "PAIN" = "PAIN",
  "SELECT" = "SELECT",
}

export enum limitingFactorsFriendly {
  "MUSCLETIGHTNESS" = "Muscle Tightness",
  "JOINTSTIFFNESS" = "Joint Stiffness",
  "PAIN" = "Pain",
  "SELECT" = "Select reason",
}

import React, { Component, ReactNode } from "react";
import { Button, Card, FormGroup, Grid, Typography } from "@material-ui/core";
import { MetronomeIcon, TimerIcon } from "../../assets/images";

interface remoteStartConfigItem {
  img: string;
  alt: string;
  text: string;
}

export const RemoteStartConfig: { [key: string]: remoteStartConfigItem } = {
  TIMER: { img: TimerIcon, alt: "Timer Icon", text: "Start the Timer" },
  METRONOME: {
    img: MetronomeIcon,
    alt: "Metronome Icon",
    text: "Start the Metronome",
  },
};

interface RemoteStartProps {
  configKey: string;
  disabled: boolean;
  visible: boolean;

  isTimerStarted?: boolean;

  handleRemoteStart: any;
  handleRemoteResume?: any;
}

class RemoteStart extends Component<RemoteStartProps> {
  private configItem: remoteStartConfigItem;

  constructor(props: RemoteStartProps) {
    super(props);
    this.configItem = RemoteStartConfig[props.configKey.toUpperCase()];
  }

  render(): ReactNode {
    return (
      <>
        {this.props.visible ? (
          <Card variant="outlined" className={this.props.disabled ? "disabledCard" : "nDisabled"}>
            <FormGroup row>
              <div className="flexerSpaceBetween">
                <img src={this.configItem.img} width="36" alt={this.configItem.alt} />
                <Typography variant="h3" className="McardHeroHeading">
                  {this.configItem.text}
                </Typography>
                <Button color="primary" variant="contained" onClick={this.props.handleRemoteStart}>
                  <span>
                    <strong>Start</strong>
                  </span>
                </Button>
                {this.props.configKey === "TIMER" && this.props.isTimerStarted && this.props.handleRemoteResume ? (
                  <Button color="primary" variant="outlined" onClick={this.props.handleRemoteResume} className="remoteStartResumeBtn">
                    <span>
                      <strong>Resume</strong>
                    </span>
                  </Button>
                ) : (
                  <></>
                )}
              </div>
            </FormGroup>
          </Card>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default RemoteStart;

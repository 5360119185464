import * as yup from "yup";

const balanceValidation = yup.object().shape({
  eyesOpenAttemptL: yup.boolean().test("eyesOpenAttemptL", "Please continue until target time is reached or all attempts are made", function () {
    const { notTestedReason, leftLegEyesOpenAttempt1, leftLegEyesOpenAttempt2, leftLegEyesOpenAttempt3 } = this.parent;

    if (
      !notTestedReason &&
      (!leftLegEyesOpenAttempt1 ||
        (!leftLegEyesOpenAttempt2 && leftLegEyesOpenAttempt1 < 30) ||
        (!leftLegEyesOpenAttempt3 && leftLegEyesOpenAttempt2 < 30 && leftLegEyesOpenAttempt1 < 30))
    ) {
      return false;
    }

    return true;
  }),
  eyesOpenAttemptR: yup.boolean().test("eyesOpenAttemptR", "Please continue until target time is reached or all attempts are made", function () {
    const { notTestedReason, rightLegEyesOpenAttempt1, rightLegEyesOpenAttempt2, rightLegEyesOpenAttempt3 } = this.parent;

    if (
      !notTestedReason &&
      (!rightLegEyesOpenAttempt1 ||
        (!rightLegEyesOpenAttempt2 && rightLegEyesOpenAttempt1 < 30) ||
        (!rightLegEyesOpenAttempt3 && rightLegEyesOpenAttempt2 < 30 && rightLegEyesOpenAttempt1 < 30))
    ) {
      return false;
    }

    return true;
  }),
  eyesClosedAttemptL: yup.boolean().test("eyesClosedAttemptL", "Please continue until target time is reached or all attempts are made", function () {
    const {
      notTestedReason,
      leftLegEyesOpenAttempt1,
      leftLegEyesOpenAttempt2,
      leftLegEyesOpenAttempt3,
      leftLegEyesClosedAttempt1,
      leftLegEyesClosedAttempt2,
      leftLegEyesClosedAttempt3,
    } = this.parent;

    if (leftLegEyesOpenAttempt1 >= 30 || leftLegEyesOpenAttempt2 >= 30 || leftLegEyesOpenAttempt3 >= 30) {
      if (
        !notTestedReason &&
        (!leftLegEyesClosedAttempt1 ||
          (!leftLegEyesClosedAttempt2 && leftLegEyesClosedAttempt1 < 10) ||
          (!leftLegEyesClosedAttempt3 && leftLegEyesClosedAttempt2 < 10 && leftLegEyesClosedAttempt1 < 10))
      ) {
        return false;
      }
    }

    return true;
  }),
  eyesClosedAttemptR: yup.boolean().test("eyesClosedAttemptR", "Please continue until target time is reached or all attempts are made", function () {
    const {
      notTestedReason,
      rightLegEyesOpenAttempt1,
      rightLegEyesOpenAttempt2,
      rightLegEyesOpenAttempt3,
      rightLegEyesClosedAttempt1,
      rightLegEyesClosedAttempt2,
      rightLegEyesClosedAttempt3,
    } = this.parent;

    //If the eye open attempts are greater or equal to fifteen seconds, only then will we consider validating the eyes closed attempts.
    if (rightLegEyesOpenAttempt1 >= 30 || rightLegEyesOpenAttempt2 >= 30 || rightLegEyesOpenAttempt3 >= 30) {
      if (
        !notTestedReason &&
        (!rightLegEyesClosedAttempt1 ||
          (!rightLegEyesClosedAttempt2 && rightLegEyesClosedAttempt1 < 10) ||
          (!rightLegEyesClosedAttempt3 && rightLegEyesClosedAttempt2 < 10 && rightLegEyesClosedAttempt1 < 10))
      ) {
        return false;
      }
    }

    return true;
  }),
});

export default balanceValidation;

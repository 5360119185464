import React, { useEffect, useState } from "react";
import { Stepper, ProfileCard, NotesModal, InTestMedicalClearanceModal } from "../../../components";
import { Baseline, WarningIcon } from "../../../assets/images";
import FormNavigator from "../../../components/navigation/FormNavigator";
import { Root } from "../../../stores";
import baselineDetailsValidation from "./validation";
import {
  Grid,
  Typography,
  FormControl,
  Card,
  FormControlLabel,
  FormLabel,
  OutlinedInput,
  InputAdornment,
  Checkbox,
  Container,
  Radio,
  RadioGroup,
  FormGroup,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { inject, observer } from "mobx-react";
import { differenceInYears } from "date-fns";
import { dataStorePageKeys } from "../../../stores/dataStore";
import { useHistory } from "react-router-dom";
import { ASSESSMENTDETAILS, DASHBOARD, GENERALOBSERVATIONS } from "../../../routes/paths";
import { getAppInsights } from "../../../components/telemetry";
import withAppInsights from "../AppInsights";

interface BaselineMeasurementsProps {
  rootStore?: Root;
}

const BaselineMeasurements = inject("rootStore")(
  observer((props: BaselineMeasurementsProps) => {
    let pefaAssessment: any;
    let prevData: any;
    let referralDetails: any;
    let medicalHistoryData: any;
    let endAssessment: any;
    let participantAgeYears = 0;
    let isHighBPFlowTested = false;
    const history = useHistory();
    const appInsights = getAppInsights();

    if (props.rootStore?.pefaStore.selectedPefa) {
      pefaAssessment = props.rootStore?.pefaStore.selectedPefa[0];
      prevData = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.BASELINE_MEASUREMENTS);
      medicalHistoryData = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.MEDICAL_DETAILS);
      referralDetails = props.rootStore?.dataStore.getCheckInData(pefaAssessment.id, dataStorePageKeys.REFERRAL_DETAILS);
      endAssessment = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.END_ASSESSMENT);
      participantAgeYears = differenceInYears(new Date(), new Date(referralDetails?.dob ?? pefaAssessment.pefArequest.worker.dateOfBirth));
      isHighBPFlowTested = medicalHistoryData.highBloodPressureViewedMC === "ViewedAccepted" ? false : true;
    } else {
      history.push(DASHBOARD);
      return <></>;
    }

    const email = pefaAssessment.pefArequest.company.clientConsentTxtName;
    // correct this for the way it comes from Console (currently being stored in wrong fields in Console)
    const BPSys = pefaAssessment.pefArequest.company.bpDia || 145;
    const BPDia = pefaAssessment.pefArequest.company.bpSys || 95;

    const { register, handleSubmit, errors, setValue } = useForm({
      resolver: yupResolver(baselineDetailsValidation),
    });

    const [state, setState] = useState({
      isLoading: true,
      BPDia: prevData ? prevData.BPDia : BPDia,
      BPSys: prevData ? prevData.BPSys : BPSys,
      bodyWeight: prevData ? prevData.bodyWeight : "",
      height: prevData ? prevData.height : "",
      showSecondReading: prevData
        ? (isHighBPFlowTested &&
            ((Number(prevData.bloodPressureDiastolic) > Number(prevData.BPDia) && Number(prevData.bloodPressureSystolic) > 0) ||
              (Number(prevData.bloodPressureSystolic) > Number(prevData.BPSys) && Number(prevData.bloodPressureDiastolic) > 0))) ||
          prevData.showSecondReading
        : false,
      bloodPressureSystolic: prevData ? prevData.bloodPressureSystolic : "",
      bloodPressureDiastolic: prevData ? prevData.bloodPressureDiastolic : "",
      bloodPressureSystolic2: prevData ? prevData.bloodPressureSystolic2 : "",
      bloodPressureDiastolic2: prevData ? prevData.bloodPressureDiastolic2 : "",
      bloodPressureSystolic3: prevData ? prevData.bloodPressureSystolic3 : "",
      bloodPressureDiastolic3: prevData ? prevData.bloodPressureDiastolic3 : "",
      bloodPressureSystolic4: prevData ? prevData.bloodPressureSystolic4 : "",
      bloodPressureDiastolic4: prevData ? prevData.bloodPressureDiastolic4 : "",
      heartRate: prevData ? prevData.heartRate : "",
      handDominance: prevData ? prevData.handDominance : "",
      yesAllPhysical: prevData ? prevData.yesAllPhysical : false,
      wellRested: prevData ? prevData.wellRested : false,
      noCaffeine: prevData ? prevData.noCaffeine : false,
      noNicotine: prevData ? prevData.noNicotine : false,
      noSuspectedInfluence: prevData ? prevData.noSuspectedInfluence : false,

      communication: prevData ? prevData.communication : "",
      emotionalState: prevData ? prevData.emotionalState : "",

      yesAllAttire: prevData ? prevData.yesAllAttire : false,
      closedShoes: prevData ? prevData.closedShoes : false,
      shorts: prevData ? prevData.shorts : false,
      shoulderAndBackVisible: prevData ? prevData.shoulderAndBackVisible : false,
      baselineSubmitted: prevData ? prevData.baselineSubmitted : false,
    });

    const handleValueChange = (event: any): void => {
      event.persist();
      setState((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
      }));
    };
    const handleFirstBPReading = (event: any): void => {
      event.persist();
      let { bloodPressureSystolic, bloodPressureDiastolic, showSecondReading } = state;
      if (event.target.name === "bloodPressureSystolic") {
        bloodPressureSystolic = event.target.value;
      } else {
        bloodPressureDiastolic = event.target.value;
      }

      if (
        isHighBPFlowTested &&
        ((Number(bloodPressureDiastolic) > Number(BPDia) && Number(bloodPressureSystolic) > 0) ||
          (Number(bloodPressureSystolic) > Number(BPSys) && Number(bloodPressureDiastolic) > 0))
      ) {
        showSecondReading = true;
      } else {
        showSecondReading = false;
      }
      setState((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
        bloodPressureSystolic2: "",
        bloodPressureDiastolic2: "",
        showSecondReading,
      }));
    };
    const handleCheckboxChange = async (event: any, logic: any = null): Promise<void> => {
      const control = event.currentTarget;
      const formComponent = control.getAttribute("name") as string;

      //Store the current checkbox's value to the state using the checkbox name attribute as the key.
      //This is set as an await to make sure that it has saved before moving on. This may not be necessary,
      //but I'll leave it here for now. It's handy for testing
      await setState((prevState) => ({
        ...prevState,
        [formComponent]: control.checked,
      }));

      //If a reference to a function has been passed, execute it, passing in the control checked state.
      if (!!logic) {
        logic(control.checked);
      }
    };

    const changeAllChildCheckboxes = (checked: boolean, targetCheckboxes: string[]): void => {
      targetCheckboxes.forEach((child) => {
        //Set the checkbox values in the form, flagging them as dirty.
        setValue(child, checked, { shouldDirty: true });

        //Update the mapped state values. This updates the field visually
        setState((prevState) => ({
          ...prevState,
          [child]: checked,
        }));
      });
    };

    const changeParentCheckbox = (childChecked: boolean, parentCheckbox: string) => {
      if (!childChecked) {
        setValue(parentCheckbox, false, { shouldDirty: true });
        setState((prevState) => ({
          ...prevState,
          [parentCheckbox]: false,
        }));
      }
    };

    useEffect(() => {
      const timeout = setTimeout(() => {
        props.rootStore?.dataStore.savePartialData(state, pefaAssessment.id, dataStorePageKeys.BASELINE_MEASUREMENTS);
      }, 500);

      return () => clearTimeout(timeout);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      state.bodyWeight,
      state.height,
      state.bloodPressureSystolic,
      state.bloodPressureDiastolic,
      state.bloodPressureSystolic2,
      state.bloodPressureDiastolic2,
      state.bloodPressureSystolic3,
      state.bloodPressureDiastolic3,
      state.bloodPressureSystolic4,
      state.bloodPressureDiastolic4,
      state.showSecondReading,
      state.heartRate,
      state.handDominance,
      state.yesAllPhysical,
      state.wellRested,
      state.noCaffeine,
      state.noNicotine,
      state.noSuspectedInfluence,
      state.communication,
      state.emotionalState,
      state.yesAllAttire,
      state.closedShoes,
      state.shorts,
      state.shoulderAndBackVisible,
      state.BPDia,
      state.BPSys,
      state.baselineSubmitted,
    ]);

    useEffect(() => {
      //Replicate the onUnmount event handler from class components.
      return () => {
        appInsights.trackEvent({ name: "intermediateSubmission", properties: { bookingId: pefaAssessment.id, dataKey: dataStorePageKeys.BASELINE_MEASUREMENTS, data: state } });
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submitForm = (data: any, forwardTarget: any) => {
      const {
        BPDia,
        BPSys,
        bodyWeight,
        height,
        bloodPressureSystolic,
        bloodPressureDiastolic,
        bloodPressureSystolic2,
        bloodPressureDiastolic2,
        bloodPressureSystolic3,
        bloodPressureDiastolic3,
        bloodPressureSystolic4,
        bloodPressureDiastolic4,
        showSecondReading,
        heartRate,
        handDominance,
        yesAllPhysical,
        wellRested,
        noCaffeine,
        noNicotine,
        noSuspectedInfluence,
        communication,
        emotionalState,
        yesAllAttire,
        closedShoes,
        shorts,
        shoulderAndBackVisible,
      } = state;
      const baselineSubmitted = true;
      props.rootStore?.dataStore.saveData(
        {
          ...data,
          BPDia,
          BPSys,
          bodyWeight,
          height,
          bloodPressureSystolic,
          bloodPressureDiastolic,
          bloodPressureSystolic2,
          bloodPressureDiastolic2,
          bloodPressureSystolic3,
          bloodPressureDiastolic3,
          bloodPressureSystolic4,
          bloodPressureDiastolic4,
          showSecondReading,
          heartRate,
          handDominance,
          yesAllPhysical,
          wellRested,
          noCaffeine,
          noNicotine,
          noSuspectedInfluence,
          communication,
          emotionalState,
          yesAllAttire,
          closedShoes,
          shorts,
          shoulderAndBackVisible,
          baselineSubmitted,
        },
        pefaAssessment.id,
        dataStorePageKeys.BASELINE_MEASUREMENTS
      );
      const pefaData = { ...props.rootStore?.dataStore.pefaData[pefaAssessment.id] };
      // take snapshot of assessment and store against booking
      props.rootStore?.pefaStore.nextSubmit(pefaData, pefaAssessment.id);
      history.push(forwardTarget);
    };

    const physicalChildren = ["wellRested", "noCaffeine", "noNicotine", "noSuspectedInfluence"];

    const attireChildren = ["closedShoes", "shorts", "shoulderAndBackVisible"];

    return (
      <div className="FormStyler">
        <div className="BaselineMeasurements">
          <Grid item container direction="column" xs={12}>
            <Stepper targetStep={"Assessment Details"} />
            <Container maxWidth={"lg"}>
              <Grid item container xs={12} direction="row">
                <Grid item container xs={9}>
                  <form>
                    <Card className="participantReportsCard" variant="outlined">
                      <Typography variant="h2" className="heroHeading">
                        <img src={Baseline} alt="Success" /> Baseline Measurements
                      </Typography>

                      <Grid item container xs={11} style={{ flexGrow: 1 }}>
                        <Grid item container xs={12} style={{ flexGrow: 1 }}>
                          <FormControl className="formControl">
                            <Grid container xs={12}>
                              <Grid item xs={4}>
                                <FormLabel className="blueText height-adjusted textbox" component="legend">
                                  Body Weight
                                </FormLabel>
                              </Grid>
                              <Grid item xs={8}>
                                <FormControl>
                                  <OutlinedInput
                                    color="primary"
                                    className="textInputMedium"
                                    endAdornment={<InputAdornment position="end">Kg</InputAdornment>}
                                    name="bodyWeight"
                                    inputRef={register}
                                    defaultValue={state.bodyWeight}
                                    autoComplete="off"
                                    error={!!errors.bodyWeight?.message}
                                    onChange={handleValueChange}
                                  />
                                  <Grid item xs={12}>
                                    {state.bodyWeight >= 100 ? (
                                      <span style={{ marginTop: "5px" }} className="inline-warningMsg">
                                        <img src={WarningIcon} alt="Warning Icon" />
                                        This person's body weight is 100kg or more. Please check the SWL of your ladder.{" "}
                                      </span>
                                    ) : (
                                      <></>
                                    )}
                                  </Grid>
                                </FormControl>
                              </Grid>
                            </Grid>
                          </FormControl>
                        </Grid>

                        <Grid item container xs={12} style={{ flexGrow: 1 }}>
                          <FormControl className="formControl">
                            <Grid container xs={12}>
                              <Grid item xs={4}>
                                <FormLabel className="blueText height-adjusted textbox" component="legend">
                                  Height
                                </FormLabel>
                              </Grid>
                              <Grid item xs={8}>
                                <FormControl>
                                  <OutlinedInput
                                    color="primary"
                                    className="textInputMedium"
                                    endAdornment={<InputAdornment position="end">cm</InputAdornment>}
                                    name="height"
                                    aria-describedby="standard-weight-helper-text"
                                    inputRef={register}
                                    autoComplete="off"
                                    error={!!errors.height?.message}
                                    defaultValue={state.height}
                                    onChange={handleValueChange}
                                  />
                                </FormControl>
                              </Grid>
                            </Grid>
                          </FormControl>
                        </Grid>
                        <Grid item container xs={12} style={{ flexGrow: 1 }}>
                          <Grid container xs={12}>
                            <Grid item xs={4}>
                              <FormLabel className="blueText height-adjusted textbox" component="legend">
                                Blood Pressure (sitting)
                              </FormLabel>
                            </Grid>
                            <Grid item xs={8}>
                              <FormControl className="formControl">
                                <OutlinedInput
                                  color="primary"
                                  className={state.baselineSubmitted ? "disabled-bp" : "bp"}
                                  endAdornment={<InputAdornment position="end">mmhg</InputAdornment>}
                                  name="bloodPressureSystolic"
                                  aria-describedby="standard-weight-helper-text"
                                  inputRef={register}
                                  autoComplete="off"
                                  defaultValue={state.bloodPressureSystolic}
                                  error={!!errors.bloodPressureSystolic?.message}
                                  inputProps={{
                                    helpertext: errors.bloodPressureSystolic?.message,
                                  }}
                                  readOnly={state.baselineSubmitted}
                                  onChange={handleFirstBPReading}
                                />
                              </FormControl>
                              <FormControl className="formControl">
                                <OutlinedInput
                                  color="primary"
                                  className={state.baselineSubmitted ? "disabled-bp" : "bp"}
                                  endAdornment={<InputAdornment position="end">mmhg</InputAdornment>}
                                  name="bloodPressureDiastolic"
                                  aria-describedby="standard-weight-helper-text"
                                  inputRef={register}
                                  autoComplete="off"
                                  error={!!errors.bloodPressureDiastolic?.message}
                                  inputProps={{
                                    helpertext: errors.bloodPressureDiastolic?.message,
                                  }}
                                  defaultValue={state.bloodPressureDiastolic}
                                  onChange={handleFirstBPReading}
                                  readOnly={state.baselineSubmitted}
                                />
                              </FormControl>
                              <FormControl className="formControl">
                                <div className="bpDetails">
                                  Limit {BPSys} / {BPDia}mmHg
                                </div>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                        <input type="text" style={{ display: "none" }} name={`BPSys`} defaultValue={BPSys?.toString()} ref={register} />
                        <input type="text" style={{ display: "none" }} name={`BPDia`} defaultValue={BPDia?.toString()} ref={register} />
                        <input type="text" style={{ display: "none" }} name={`isHighBPFlowTested`} defaultValue={isHighBPFlowTested?.toString()} ref={register} />
                        <input type="text" style={{ display: "none" }} name={`showSecondReading`} defaultValue={state.showSecondReading?.toString()} ref={register} />
                        {isHighBPFlowTested && state.showSecondReading ? (
                          <Grid item container xs={12} style={{ flexGrow: 1 }}>
                            <Grid container xs={12}>
                              <Grid item xs={4}>
                                <FormLabel className="blueText height-adjusted textbox" component="legend">
                                  2nd Reading
                                </FormLabel>
                              </Grid>
                              <Grid item xs={8}>
                                <FormControl className="formControl">
                                  <OutlinedInput
                                    color="primary"
                                    className={state.baselineSubmitted ? "disabled-bp" : "bp"}
                                    endAdornment={<InputAdornment position="end">mmhg</InputAdornment>}
                                    name="bloodPressureSystolic2"
                                    aria-describedby="standard-weight-helper-text"
                                    inputRef={register}
                                    autoComplete="off"
                                    defaultValue={state.bloodPressureSystolic2}
                                    error={!!errors.bloodPressureSystolic2?.message}
                                    inputProps={{
                                      helpertext: errors.bloodPressureSystolic2?.message,
                                    }}
                                    readOnly={state.baselineSubmitted}
                                    onChange={handleValueChange}
                                  />
                                </FormControl>
                                <FormControl className="formControl">
                                  <OutlinedInput
                                    color="primary"
                                    className={state.baselineSubmitted ? "disabled-bp" : "bp"}
                                    endAdornment={<InputAdornment position="end">mmhg</InputAdornment>}
                                    name="bloodPressureDiastolic2"
                                    inputRef={register}
                                    aria-describedby="standard-weight-helper-text"
                                    autoComplete="off"
                                    defaultValue={state.bloodPressureDiastolic2}
                                    onChange={handleValueChange}
                                    readOnly={state.baselineSubmitted}
                                    error={!!errors.bloodPressureDiastolic2?.message}
                                    inputProps={{
                                      helpertext: errors.bloodPressureDiastolic2?.message,
                                    }}
                                  />
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Grid>
                        ) : (
                          <></>
                        )}
                        <Grid item container xs={12} style={{ flexGrow: 1 }}>
                          <FormControl className="formControl">
                            <Grid container xs={12}>
                              <Grid item xs={4}>
                                <FormLabel className="blueText height-adjusted textbox" component="legend">
                                  Heart Rate
                                </FormLabel>
                              </Grid>
                              <Grid item xs={8}>
                                <FormControl>
                                  <OutlinedInput
                                    type="number"
                                    color="primary"
                                    endAdornment={<InputAdornment position="end">bpm</InputAdornment>}
                                    name="heartRate"
                                    className="textInputMedium"
                                    aria-describedby="standard-weight-helper-text"
                                    inputRef={register}
                                    autoComplete="off"
                                    defaultValue={state.heartRate}
                                    onChange={handleValueChange}
                                    onInput={(e) => {
                                      (e.target as HTMLInputElement).value = Math.max(0, parseInt((e.target as HTMLInputElement).value))
                                        .toString()
                                        .slice(0, 3);
                                    }}
                                  />
                                </FormControl>
                              </Grid>
                              {errors.heartRate?.message ? (
                                <div className="invalid-feedback">
                                  {errors.heartRate?.message} <br />
                                </div>
                              ) : (
                                <></>
                              )}
                            </Grid>
                          </FormControl>
                        </Grid>
                        <Grid item container xs={12} style={{ flexGrow: 1 }}>
                          <FormControl className="formControl">
                            <Grid container xs={12}>
                              <Grid item xs={4}>
                                <FormLabel className="blueText height-adjusted" component="legend">
                                  Hand Dominance
                                </FormLabel>
                              </Grid>
                              <Grid item xs={8}>
                                <RadioGroup aria-label="hand-dominance" name="handDominance" className="flexer" row value={state.handDominance} onChange={handleValueChange}>
                                  <FormControlLabel
                                    control={<Radio color="primary" name="handDominance" id="handDominanceLeft" value="left" />}
                                    value={state.handDominance}
                                    inputRef={register}
                                    label="Left"
                                  />
                                  <FormControlLabel
                                    control={<Radio color="primary" name="handDominance" id="handDominanceRight" value="right" />}
                                    inputRef={register}
                                    label="Right"
                                  />
                                </RadioGroup>
                                <div className="invalid-feedback">
                                  {errors.handDominance?.message} <br />
                                </div>
                              </Grid>
                            </Grid>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Card>
                    <Card className="participantReportsCard" variant="outlined">
                      <Typography variant="h2" className="heroHeading">
                        <img src={Baseline} alt="Success" /> Readiness
                      </Typography>

                      <Grid item container direction="column">
                        <div className="full-width">
                          <FormLabel component="legend">Physical</FormLabel>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  name="yesAllPhysical"
                                  icon={<span className="checkboxIcon"></span>}
                                  checkedIcon={<span className="checkboxIconChecked"></span>}
                                />
                              }
                              label="Yes to All"
                              name="yesAllPhysical"
                              inputRef={register}
                              checked={state.yesAllPhysical}
                              onChange={(event) => {
                                handleCheckboxChange(event, (checked: boolean) => changeAllChildCheckboxes(checked, physicalChildren));
                              }}
                            />
                            <div className="flexer">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    icon={<span className="checkboxIcon"></span>}
                                    checkedIcon={<span className="checkboxIconChecked"></span>}
                                    color="primary"
                                    name="wellRested"
                                  />
                                }
                                label="Well rested"
                                checked={state.wellRested}
                                name="wellRested"
                                inputRef={register}
                                onChange={(event) => {
                                  handleCheckboxChange(event, (checked: boolean) => changeParentCheckbox(checked, "yesAllPhysical"));
                                }}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    icon={<span className="checkboxIcon"></span>}
                                    checkedIcon={<span className="checkboxIconChecked"></span>}
                                    color="primary"
                                    name="noCaffeine"
                                  />
                                }
                                label="No Caffeine in past 1 hour"
                                checked={state.noCaffeine}
                                name="noCaffeine"
                                inputRef={register}
                                onChange={(event) => {
                                  handleCheckboxChange(event, (checked: boolean) => changeParentCheckbox(checked, "yesAllPhysical"));
                                }}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    icon={<span className="checkboxIcon"></span>}
                                    checkedIcon={<span className="checkboxIconChecked"></span>}
                                    color="primary"
                                    name="noNicotine"
                                  />
                                }
                                label="No Nicotine in past 1 hour"
                                checked={state.noNicotine}
                                name="noNicotine"
                                inputRef={register}
                                onChange={(event) => {
                                  handleCheckboxChange(event, (checked: boolean) => changeParentCheckbox(checked, "yesAllPhysical"));
                                }}
                              />
                            </div>
                            <div className="flexer">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    name="noSuspectedInfluence"
                                    icon={<span className="checkboxIcon"></span>}
                                    checkedIcon={<span className="checkboxIconChecked"></span>}
                                  />
                                }
                                label="No suspected influence of drugs or alcohol"
                                checked={state.noSuspectedInfluence}
                                name="noSuspectedInfluence"
                                inputRef={register}
                                onChange={(event) => {
                                  handleCheckboxChange(event, (checked: boolean) => changeParentCheckbox(checked, "yesAllPhysical"));
                                }}
                              />
                            </div>
                          </FormGroup>
                          <div className="invalid-feedback">
                            {errors.physicalSelected?.message} <br />
                          </div>
                        </div>
                        <div className="full-width">
                          <FormLabel component="legend">Emotional State</FormLabel>
                          <RadioGroup aria-label="emotionalState" name="emotionalState" className="flexer" row value={state.emotionalState} onChange={handleValueChange}>
                            <FormControlLabel control={<Radio color="primary" name="emotionalState" id="emotionalStateCalm" value="calm" />} inputRef={register} label="Calm" />
                            <FormControlLabel
                              control={<Radio color="primary" name="emotionalState" id="emotionalStateFlustered" value="flustered" />}
                              inputRef={register}
                              label="Flustered"
                            />
                            <FormControlLabel
                              control={<Radio color="primary" name="emotionalState" id="emotionalStateNervous" value="nervous" />}
                              inputRef={register}
                              label="Nervous"
                            />
                            <FormControlLabel
                              control={<Radio color="primary" name="emotionalState" id="emotionalStateAgitated" value="agitated" />}
                              inputRef={register}
                              label="Agitated"
                            />
                            <FormControlLabel control={<Radio color="primary" name="emotionalState" id="emotionalStateOther" value="other" />} inputRef={register} label="Other" />
                          </RadioGroup>
                          <div className="invalid-feedback">
                            {errors.emotionalState?.message} <br />
                          </div>
                        </div>
                        <div className="full-width">
                          <FormLabel component="legend">Communication</FormLabel>
                          <RadioGroup aria-label="communication" name="communication" className="flexer" row value={state.communication} onChange={handleValueChange}>
                            <FormControlLabel
                              control={<Radio color="primary" name="communication" id="communicationNone" value="no-anticipated-dificulties" />}
                              inputRef={register}
                              label="No Anticipated Dificulties"
                            />
                            <FormControlLabel
                              control={<Radio color="primary" name="communication" id="communicationHearing" value="apparent-difficulty-hearing" />}
                              inputRef={register}
                              label="Apparent difficulty hearing"
                            />
                            <FormControlLabel
                              control={<Radio color="primary" name="communication" id="communicationEnglish" value="apparent-difficulty-english" />}
                              inputRef={register}
                              label="Apparent difficulty with English"
                            />
                          </RadioGroup>
                          <div className="invalid-feedback">
                            {errors.communication?.message} <br />
                          </div>
                        </div>
                        <div className="full-width">
                          <FormLabel component="legend">Attire</FormLabel>
                          <FormControlLabel
                            control={<Checkbox icon={<span className="checkboxIcon"></span>} checkedIcon={<span className="checkboxIconChecked"></span>} color="primary" />}
                            label="Yes to All"
                            name="yesAllAttire"
                            inputRef={register}
                            checked={state.yesAllAttire}
                            onChange={(event) => {
                              handleCheckboxChange(event, (checked: boolean) => changeAllChildCheckboxes(checked, attireChildren));
                            }}
                          />
                          <div className="flexer">
                            <FormControlLabel
                              control={<Checkbox icon={<span className="checkboxIcon"></span>} checkedIcon={<span className="checkboxIconChecked"></span>} color="primary" />}
                              inputRef={register}
                              label="Closed Shoes"
                              checked={state.closedShoes}
                              name="closedShoes"
                              onChange={(event) => {
                                handleCheckboxChange(event, (checked: boolean) => changeParentCheckbox(checked, "yesAllAttire"));
                              }}
                            />
                            <FormControlLabel
                              control={<Checkbox icon={<span className="checkboxIcon"></span>} checkedIcon={<span className="checkboxIconChecked"></span>} color="primary" />}
                              inputRef={register}
                              label="Shorts"
                              checked={state.shorts}
                              name="shorts"
                              onChange={(event) => {
                                handleCheckboxChange(event, (checked: boolean) => changeParentCheckbox(checked, "yesAllAttire"));
                              }}
                            />
                            <FormControlLabel
                              control={<Checkbox color="primary" icon={<span className="checkboxIcon"></span>} checkedIcon={<span className="checkboxIconChecked"></span>} />}
                              inputRef={register}
                              label="Shoulder and back visible"
                              checked={state.shoulderAndBackVisible}
                              name="shoulderAndBackVisible"
                              onChange={(event) => {
                                handleCheckboxChange(event, (checked: boolean) => changeParentCheckbox(checked, "yesAllAttire"));
                              }}
                            />
                          </div>
                          <div className="invalid-feedback">
                            {errors.attireSelected?.message} <br />
                          </div>
                        </div>
                      </Grid>
                    </Card>
                  </form>
                </Grid>
                <Grid item container xs={3} className="RightCol">
                  <ProfileCard
                    firstName={pefaAssessment.pefArequest.worker.firstName}
                    lastName={pefaAssessment.pefArequest.worker.lastName}
                    participantAgeYears={participantAgeYears}
                    currentHeartRate={state.heartRate}
                  />
                  <NotesModal pageKey={dataStorePageKeys.BASELINE_MEASUREMENTS} pefaID={pefaAssessment.id} />
                  <InTestMedicalClearanceModal
                    firstName={referralDetails.firstName}
                    lastName={referralDetails.lastName}
                    pageKey={dataStorePageKeys.BASELINE_MEASUREMENTS}
                    pefaID={pefaAssessment.id}
                    medicalPractice={medicalHistoryData.medicalPractitioner}
                    medicalPractitioner={medicalHistoryData.medicalPractice}
                  />
                </Grid>
              </Grid>
            </Container>
            <FormNavigator
              forwardTarget={GENERALOBSERVATIONS}
              backTarget={ASSESSMENTDETAILS}
              disabledNextButton={
                endAssessment &&
                endAssessment.endAssessmentReason !== "" &&
                endAssessment.endAssessmentNotes !== "" &&
                endAssessment.endAssessmentPage === dataStorePageKeys.BASELINE_MEASUREMENTS
              }
              handleSubmit={handleSubmit(submitForm)}
              selectedKey={dataStorePageKeys.BASELINE_MEASUREMENTS}
            />
          </Grid>
        </div>
      </div>
    );
  })
);

export default withAppInsights(BaselineMeasurements);

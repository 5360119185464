import React, { useState } from "react";
import { Grid, Button, Container } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import { Root } from "../../stores";
import { WarningIcon } from "../../assets/images";
import { EndAssessmentModal } from "..";
import { useHistory } from "react-router-dom";
import { DASHBOARD, LOGIN } from "../../routes/paths";

interface FormNavigatorProps {
  rootStore?: Root;
  forwardText?: string;
  forwardTarget?: any;
  showBackButton?: boolean;
  backTarget?: any;
  handleSubmit?: any;
  warning?: string;
  homeText?: string;
  pushProgressOnEnd?: boolean;
  showEndButton?: boolean;
  disabledNextButton?: boolean;
  disabledEndButton?: boolean;
  handleEnd?: any;
  selectedKey: string;
}

enum navTarget {
  "FORWARD" = "FORWARD",
  "BACK" = "BACK",
  "HOME" = "HOME",
}

const FormNavigator = inject("rootStore")(
  observer((props: FormNavigatorProps) => {
    const history = useHistory();

    //Because of the nature of these options, we want it to default to true if a value is not set.
    //This way we won't unintentionally lose data.
    const pushProgressOnEnd = props.pushProgressOnEnd || props.pushProgressOnEnd === undefined;
    const showBackButton = props.showBackButton || props.showBackButton === undefined;
    const showEndButton = props.showEndButton || props.showEndButton === undefined;

    const [state, setState] = useState({
      triggerShowEndAssessmentModal: false,
    });

    const handleNavigation = async (target: any, navKey: string): Promise<void> => {
      switch (navKey) {
        case navTarget.HOME: {
          if (pushProgressOnEnd) {
            if (props.handleEnd) {
              props.handleEnd();
            }
            setState((prevState) => ({ ...prevState, triggerShowEndAssessmentModal: true }));
          } else {
            history.push(DASHBOARD);
          }

          break;
        }
        case navTarget.FORWARD: {
          //If a reference to a submit function has been provided, call the reference, passing the target view.
          if (props.handleSubmit) {
            props.handleSubmit(target);
            break;
          }

          //Otherwise, just set the view to the target.
          history.push(target);
          break;
        }
        case navTarget.BACK: {
          //If a backTarget has been provided, set the view to that target.
          if (props.backTarget) {
            history.push(target);
            break;
          }
          //Otherwise trigger the "back" function of the router.
          history.goBack();
          break;
        }
      }
    };

    const handleModalOpened = (): void => {
      setState((prevState) => ({ ...prevState, triggerShowEndAssessmentModal: false }));
    };

    return (
      <>
        <EndAssessmentModal history={history} openModalTrigger={state.triggerShowEndAssessmentModal} handleModalOpened={handleModalOpened} selectedKey={props.selectedKey} />
        <Grid item container className="formNavigator" xs={12}>
          <Container maxWidth={"lg"} style={{ position: "relative", textAlign: "end" }}>
            {props.warning ? (
              <div className="warningMsg">
                <img src={WarningIcon} alt="Warning Icon" />
                {props.warning}
              </div>
            ) : (
              <></>
            )}
            {showBackButton ? (
              <Button className="backButton" color="primary" variant="outlined" onClick={() => handleNavigation(props.backTarget, navTarget.BACK)}>
                Back
              </Button>
            ) : (
              <></>
            )}
            {props.forwardTarget ? (
              <Button
                color="primary"
                variant="contained"
                disabled={props.disabledNextButton != null ? props.disabledNextButton : false}
                onClick={() => handleNavigation(props.forwardTarget, navTarget.FORWARD)}
              >
                {props.forwardText ? props.forwardText : "Next"}
              </Button>
            ) : (
              <></>
            )}
            {showEndButton ? (
              <Button
                className={props.disabledNextButton ? "greenEnd" : "buttonEnd"}
                disabled={props.disabledEndButton != null ? props.disabledEndButton : false}
                variant="contained"
                onClick={() => handleNavigation(DASHBOARD, navTarget.HOME)}
              >
                {props.homeText ? props.homeText : "End PEFA"}
              </Button>
            ) : (
              <></>
            )}
          </Container>
        </Grid>
      </>
    );
  })
);

export default FormNavigator;

export enum strengthDiscomfortFactors {
  "SELECT" = "SELECT",
  "YES" = "YES",
  "NO" = "NO",
}

export enum strengthDiscomfortFactorsFriendly {
  "SELECT" = "Select",
  "YES" = "Yes",
  "NO" = "No",
}

import React, { useEffect, useState } from "react";
import { Typography, FormControl, Button, FormGroup, Grid, Backdrop, Slide, Modal } from "@material-ui/core";

import { inject, observer } from "mobx-react";
import apiService from "../../services/ApiService";
import EndAssessment from "./EndAssessmentConfirmationModal";
import { EndAssessmentModal } from "..";
import { useHistory } from "react-router-dom";
import { DASHBOARD, LOGIN } from "../../routes/paths";
//prepare medical clearance
interface ClearanceDocumentProps {
  medicalClearanceModalTrigger: boolean;
  bookingId: number;
  medicalClearanceKeys?: string[];
  medicalPractitioner: string;
  medicalPractice: string;
  participantName: string;
  clearanceGenerated: boolean;
  forwardText?: string;
  forwardTarget?: any;
  showBackButton?: boolean;
  backTarget?: any;
  handleSubmit?: any;
  warning?: string;
  homeText?: string;
  pushProgressOnEnd?: boolean;
  showEndButton?: boolean;
}
enum navTarget {
  "FORWARD" = "FORWARD",
  "BACK" = "BACK",
  "HOME" = "HOME",
}

const clearanceKeysFriendly: { [key: string]: string } = {
  highBloodPressure: "High Blood Pressure",
  pacemaker: "pacemaker",
  otherHeartCondition: "Heart Condition",
  surgeries: "Surgeries",
  hernia: "Hernias",
  fractures: "Fractures",
  dislocations: "Dislocations",
  anyInjuries6wksYes: "Injury in Past 6 Weeks",
  medCertOrWrkRstrctnsYes: "Current Medical Certificate or Medical Restrictions",
  pregnantYes: "Possible Pregnancy",
};

const ClearanceDocument = inject("rootStore")(
  observer((props: ClearanceDocumentProps) => {
    const history = useHistory();
    const pushProgressOnEnd = props.pushProgressOnEnd || props.pushProgressOnEnd === undefined;

    const [state, setState] = useState({
      clearanceDocumentModalOpen: false,
      triggerShowEndAssessmentModal: false,
      clearanceGenerated: true,
    });
    const handleCloseModal = (): void => {
      setState((prevState) => ({
        ...prevState,
        clearanceDocumentModalOpen: false,
      }));
    };
    const handleModalOpened = (): void => {
      setState((prevState) => ({ ...prevState, triggerShowEndAssessmentModal: false }));
    };

    const handleClearanceEndAssessmentClicked = (): void => {
      setState((prevState) => ({ ...prevState, clearanceDocumentModalOpen: false }));
    };

    const handleDownloadDocumentClicked = async (): Promise<void> => {
      let requestData = {};
      if (props.medicalClearanceKeys && props.medicalClearanceKeys.length > 0) {
        props.medicalClearanceKeys.forEach((key: string) => {
          requestData = { ...requestData, [clearanceKeysFriendly[key]]: "Not Provided" };
        });
        //console.log(requestData);
      }
      //console.log(requestData);
      await apiService.getMedicalClearanceDocument(
        props.bookingId,
        `MedicalClearance - ${props.participantName}.docx`,
        requestData,
        props.medicalPractitioner,
        props.medicalPractice
      );
      setState((prevState) => ({ ...prevState, clearanceGenerated: false }));
    };
    const handleNavigation = async (target: any, navKey: string): Promise<void> => {
      switch (navKey) {
        case navTarget.HOME: {
          if (pushProgressOnEnd) {
            setState((prevState) => ({ ...prevState, triggerShowEndAssessmentModal: true }));
          } else {
            history.push(DASHBOARD);
          }

          break;
        }
        case navTarget.FORWARD: {
          //If a reference to a submit function has been provided, call the reference, passing the target view.
          if (props.handleSubmit) {
            props.handleSubmit(target);
            break;
          }

          //Otherwise, just set the view to the target.
          history.push(target);

          break;
        }
        case navTarget.BACK: {
          //If a backTarget has been provided, set the view to that target.
          if (props.backTarget) {
            history.push(target);
            break;
          }
          //Otherwise trigger the "back" function of the router.
          history.goBack();
          break;
        }
      }
    };
    useEffect(() => {
      if (props.medicalClearanceModalTrigger) {
        setState((prevState) => ({
          ...prevState,
          clearanceDocumentModalOpen: true,
        }));
      }
    }, [props.medicalClearanceModalTrigger]);

    return (
      <Modal
        aria-labelledby="modal-title"
        open={state.clearanceDocumentModalOpen}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Slide direction="up" in={state.clearanceDocumentModalOpen} mountOnEnter unmountOnExit>
          <div className="modalPopSmall">
            <form>
              <div className="notesModalText">
                <Typography variant="h2" className="helpHeader">
                  Medical Clearance
                </Typography>
                <Typography variant="caption" className="helpCaption">
                  {/* TODO: update caption */}
                  Please download the medical clearance
                </Typography>
                <FormGroup className="formGroupOveride">
                  <Grid xs={12} item container className="StrengthTestContainer">
                    <FormControl component="fieldset" className="fieldsetStrength">
                      <div className="flexerCenter">
                        <Button color="primary" variant="contained" onClick={handleDownloadDocumentClicked}>
                          Prepare Medical Clearance Letter
                        </Button>
                      </div>
                      <br />
                      <div className="endLabel">If you need to change the Letter click Cancel</div>
                      <div className="flexerEnd">
                        <Button color="primary" variant="outlined" onClick={handleCloseModal}>
                          Cancel
                        </Button>
                        <EndAssessmentModal history={history} openModalTrigger={state.triggerShowEndAssessmentModal} handleModalOpened={handleModalOpened} selectedKey="" />
                        <Button disabled={state.clearanceGenerated} className="buttonEnd" variant="contained" onClick={() => handleNavigation(DASHBOARD, navTarget.HOME)}>
                          {props.homeText ? props.homeText : "End PEFA"}
                        </Button>
                      </div>
                    </FormControl>
                  </Grid>
                </FormGroup>
              </div>
            </form>
          </div>
        </Slide>
      </Modal>
    );
  })
);

export default ClearanceDocument;

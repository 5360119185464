import React, { ReactNode } from "react";
import { Provider } from "mobx-react";
import { setupRootStore } from "./stores/setup";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "./theme";
import { Root } from "./stores";
import { AuthProvider } from "oidc-react";
import authService from "./services/auth/AuthService";
import { BrowserRouter as Router, Switch, useLocation } from "react-router-dom";
import routes from "./routes/web";
import { ScrollToTop } from "./components";
import { TelemetryProvider } from "./components";
import AppRoute from "./components/router/appRoute";
import { Constants } from "./helpers/Constants";
import axios from "axios";
import { tokenInterceptor } from "./services/auth/tokenInterceptor";

interface State {
  rootStore: Root;
  settings: any;
}

class App extends React.Component<unknown, State> {
  appInsights: any;
  constructor(props: unknown) {
    super(props);
    this.state = {
      rootStore: null,
      settings: {},
    };
  }

  componentDidMount = async (): Promise<void> => {
    if (!this.state.rootStore) {
      const settings = await authService.getUserManagerDetails();
      const { rootStore } = await setupRootStore();
      this.setState((prevState) => ({ ...prevState, rootStore, settings }));
    }
  };
  render(): ReactNode {
    const { rootStore } = this.state;
    if (!rootStore) return <span></span>;

    return (
      <Provider rootStore={rootStore}>
        <AuthProvider
          onBeforeSignIn={() => {
            // eslint-disable-next-line no-restricted-globals
            rootStore.userStore.setCurrentUserPath(window.location.pathname);
          }}
          onSignIn={(userData) => {
            if (userData) {
              tokenInterceptor(axios, userData);
              rootStore?.userStore.fetchUserDetails();

              rootStore?.userStore.setUserRole(userData.profile.role);
              rootStore?.pefaStore.fetchPefas();
            }
          }}
          authority={this.state.settings.authority}
          clientId={this.state.settings.client_id}
          responseType={this.state.settings.response_type}
          scope={this.state.settings.scope}
          redirectUri={this.state.settings.redirect_uri}
          postLogoutRedirectUri={this.state.settings.post_logout_redirect_uri}
          automaticSilentRenew={false}
          onSignOut={() => {
            //rootStore?.pefaStore.clearPefaData();
          }}
        >
          <Router>
            <TelemetryProvider instrumentationKey={Constants.environment().applicationInsightKey}>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <Switch>
                  {routes.map((route) => {
                    return <AppRoute key={route.path} {...route} />;
                  })}
                </Switch>
                <ScrollToTop />
              </ThemeProvider>
            </TelemetryProvider>
          </Router>
        </AuthProvider>
      </Provider>
    );
  }
}

export default App;

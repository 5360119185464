import React, { useEffect, useState } from "react";
import { Typography, FormControl, FormControlLabel, Button, FormGroup, Grid, MenuItem, Select, TextField, Backdrop, Slide, Modal } from "@material-ui/core";
import { HelpIcon, WarningIcon } from "../../assets/images";

import { inject, observer } from "mobx-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useAuth } from "oidc-react";
import { PROGRAMRECOMMENDATIONS } from "../../routes/paths";
import { LoadingIndicator } from "..";
import axios from "axios";
import { tokenInterceptor } from "../../services/auth/tokenInterceptor";
import { dataStorePageKeys } from "../../stores/dataStore";

interface endAssessmentProps {
  rootStore?: any;
  history: any;
  openModalTrigger: boolean;
  handleModalOpened: any;
  selectedKey: string;
}

export enum endAssessmentSelectKeys {
  "SELECT" = "SELECT",
  "PARTICIPANT_DECLINED" = "PARTICIPANT_DECLINED",
  "ASSESSOR_DECISION" = "ASSESSOR_DECISION",
  "MEDICAL_RESTRICTION" = "MEDICAL_RESTRICTION",
  "EQUIPMENT_FAILURE" = "EQUIPMENT_FAILURE",
}

export enum endAssessmentSelectKeysFriendly {
  "SELECT" = "Select option from list",
  "PARTICIPANT_DECLINED" = "Participant declined",
  "ASSESSOR_DECISION" = "Assessor decision",
  "MEDICAL_RESTRICTION" = "Medical restriction",
  "EQUIPMENT_FAILURE" = "Equipment failure or limitation",
}

const endAssessmentValidation = yup.object().shape({
  endAssessmentReason: yup.string().test("endAssessmentReason", "Please choose a valid reason for ending the assessment early", function () {
    return this.parent.endAssessmentReason && this.parent.endAssessmentReason !== endAssessmentSelectKeys.SELECT;
  }),
  endAssessmentNotes: yup.string().required("Please provide extra notes on why the assessment is being ended early"),
});

const EndAssessment = inject("rootStore")(
  observer((props: endAssessmentProps) => {
    const { register, handleSubmit, errors } = useForm({
      resolver: yupResolver(endAssessmentValidation),
    });

    const auth = useAuth();
    tokenInterceptor(axios, null, auth);

    let pefaAssessment: any;
    let prevData: any;
    if (props.rootStore?.pefaStore.selectedPefa) {
      pefaAssessment = props.rootStore?.pefaStore.selectedPefa[0];
      prevData = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.END_ASSESSMENT);
    }

    const [state, setState] = useState({
      isSubmitting: false,
      endAssessmentModalOpen: false,
      // Check that there is incoming data, if there is actual data, use it.if the incoming data is an empty string, default to SELECT. If there is nothing, default to SELECT.
      endAssessmentReason: prevData ? prevData.endAssessmentReason : endAssessmentSelectKeys.SELECT,
      endAssessmentNotes: prevData ? prevData.endAssessmentNotes : "",
      endAssessmentPage: prevData ? prevData.endAssessmentPage : "",
    });

    const handleCloseModal = (): void => {
      setState((prevState) => ({
        ...prevState,
        endAssessmentModalOpen: false,
      }));
    };

    const submitForm = (): void => {
      if (!!auth) {
        if (!!auth.userData) {
          setState((prevState) => ({ ...prevState, isSubmitting: true, endAssessmentModalOpen: false }));
        }
      }
    };

    const handleResumeAssessmentClicked = (): void => {
      setState((prevState) => ({
        ...prevState,
        endAssessmentReason: endAssessmentSelectKeys.SELECT,
        endAssessmentNotes: "",
        endAssessmentPage: "",
        endAssessmentModalOpen: false,
      }));
      const endAssessmentReason = endAssessmentSelectKeys.SELECT;
      const endAssessmentNotes = "";
      const endAssessmentPage = "";
      const submissionData = { endAssessmentReason, endAssessmentNotes, endAssessmentPage };
      props.rootStore?.dataStore.saveData(submissionData, pefaAssessment.id, dataStorePageKeys.END_ASSESSMENT);
    };

    useEffect(() => {
      const handleSubmissionAsync = () => {
        const { endAssessmentReason, endAssessmentNotes } = state;
        const endAssessmentPage =
          (prevData && (prevData.endAssessmentReason !== endAssessmentReason || prevData.endAssessmentNotes !== endAssessmentNotes)) || state.endAssessmentPage === ""
            ? props.selectedKey
            : state.endAssessmentPage;
        const submissionData = { endAssessmentReason, endAssessmentNotes, endAssessmentPage };

        props.rootStore?.dataStore.saveData(submissionData, pefaAssessment.id, dataStorePageKeys.END_ASSESSMENT);
        props.history.push(PROGRAMRECOMMENDATIONS);
      };

      if (state.isSubmitting && !state.endAssessmentModalOpen) {
        handleSubmissionAsync();
      }

      if (props.openModalTrigger && !state.endAssessmentModalOpen) {
        props.handleModalOpened();
        setState((prevState) => ({
          ...prevState,
          endAssessmentModalOpen: true,
        }));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.isSubmitting, state.endAssessmentModalOpen, props.openModalTrigger]);

    return (
      <>
        <LoadingIndicator visible={state.isSubmitting} />
        <Modal
          aria-labelledby="modal-title"
          open={state.endAssessmentModalOpen}
          onClose={handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Slide direction="up" in={state.endAssessmentModalOpen} mountOnEnter unmountOnExit>
            <div className="modalPopFull">
              <Button className="hideBtn-end" onClick={handleCloseModal}>
                Close
              </Button>
              <form>
                <div className="notesModalText">
                  <div className="end-pefa-warningMsg">
                    <img src={WarningIcon} alt="Warning Icon" />
                    {"Warning: If you end PEFA you can't go back"}
                  </div>
                  <Typography variant="h2" className="helpHeader">
                    <img src={HelpIcon} alt="Notes Icon" />
                    End PEFA
                  </Typography>
                  <Typography variant="caption" className="helpCaption">
                    Please advise why this assessment is being ended
                  </Typography>
                  <FormGroup className="formGroupOveride">
                    <Grid xs={12} item container className="EndAssessmentContainer">
                      <FormControl component="fieldset" className="fieldsetStrength">
                        <FormControl variant="outlined">
                          <Select
                            color="primary"
                            className="notTestedSelect"
                            value={state.endAssessmentReason}
                            onChange={(event) => {
                              setState((prevState) => ({
                                ...prevState,
                                endAssessmentReason: event.target.value as endAssessmentSelectKeys,
                              }));
                            }}
                          >
                            <MenuItem value={endAssessmentSelectKeys.SELECT}>{endAssessmentSelectKeysFriendly.SELECT}</MenuItem>
                            <MenuItem value={endAssessmentSelectKeys.PARTICIPANT_DECLINED}>{endAssessmentSelectKeysFriendly.PARTICIPANT_DECLINED}</MenuItem>
                            <MenuItem value={endAssessmentSelectKeys.ASSESSOR_DECISION}>{endAssessmentSelectKeysFriendly.ASSESSOR_DECISION}</MenuItem>
                            <MenuItem value={endAssessmentSelectKeys.MEDICAL_RESTRICTION}>{endAssessmentSelectKeysFriendly.MEDICAL_RESTRICTION}</MenuItem>
                            <MenuItem value={endAssessmentSelectKeys.EQUIPMENT_FAILURE}>{endAssessmentSelectKeysFriendly.EQUIPMENT_FAILURE}</MenuItem>
                          </Select>
                          <input type="text" style={{ visibility: "hidden" }} name={"endAssessmentReason"} value={state.endAssessmentReason} ref={register} />
                          <div className="invalid-feedback">{errors.endAssessmentReason?.message}</div>
                        </FormControl>
                        <FormGroup className="formGroupOveride">
                          <FormControlLabel
                            control={
                              <TextField
                                name="endAssessmentNotes"
                                multiline
                                fullWidth
                                variant="outlined"
                                className="outlineTextArea"
                                label="Enter notes here"
                                defaultValue={state.endAssessmentNotes}
                                onChange={(event) => {
                                  setState((prevState) => ({ ...prevState, endAssessmentNotes: event.target.value }));
                                }}
                              />
                            }
                            inputRef={register}
                            label=""
                          />
                          <div className="invalid-feedback">{errors.endAssessmentNotes?.message}</div>
                        </FormGroup>
                        <div className="flexerEnd">
                          <Button color="primary" variant="outlined" onClick={handleResumeAssessmentClicked}>
                            Cancel End PEFA
                          </Button>
                          <Button color="primary" variant="contained" onClick={handleSubmit(submitForm)}>
                            End PEFA
                          </Button>
                        </div>
                      </FormControl>
                    </Grid>
                  </FormGroup>
                </div>
              </form>
            </div>
          </Slide>
        </Modal>
      </>
    );
  })
);

export default EndAssessment;

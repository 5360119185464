import React, { useEffect, useState } from "react";
import { Grid, Typography, Button, MenuItem, FormControl, Select, Card } from "@material-ui/core";
import { Header, PefaCard, LoadingIndicator } from "../../components";
import { RefreshIcon, Wave } from "../../assets/images/";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { inject, observer } from "mobx-react";
import { Root } from "../../stores";
import { filteredPeriodKeys } from "../../stores/pefaStore";
import { useAuth } from "oidc-react";
import { useHistory } from "react-router-dom";
import { ASSESSMENTPREVIEW, INFORMEDCONSENT, REFERRALDETAILS, MEDICALDETAILS } from "../../routes/paths";
import axios from "axios";
import { tokenInterceptor } from "../../services/auth/tokenInterceptor";
import { dataStorePageKeys } from "../../stores/dataStore";

interface DashboardProps {
  rootStore?: Root;
}

interface DashboardState {
  isInitialised: boolean;
  isLoading: boolean;
  periodKey: filteredPeriodKeys;
  pefaCount: number;
  name: string;
  pefaDateKeys: string[];
  pefaListingCurrent: boolean;
  groupedPefas: any;
}

const Dashboard = inject("rootStore")(
  observer((props: DashboardProps) => {
    const auth = useAuth();
    const history = useHistory();
    tokenInterceptor(axios, null, auth);

    const [state, setState] = useState<DashboardState>({
      isInitialised: false,
      isLoading: true,
      periodKey: filteredPeriodKeys.TODAY,
      pefaCount: props.rootStore ? props.rootStore?.pefaStore.pefaCount : 0,
      name: props.rootStore ? props.rootStore.userStore.getUserNameFormatted : "",
      pefaDateKeys: [],
      pefaListingCurrent: true,
      groupedPefas: {},
    });

    const isChrome = () => {
      const agent = window.navigator.userAgent.toLowerCase();
      if (agent.indexOf("edg") > -1) {
        return false;
      }
      if (agent.indexOf("opr") > -1) {
        return false;
      }
      return agent.indexOf("chrome") > -1 && !!window["chrome"];
    };

    const [totalPefas, setTotalPefas] = useState<number>(0);

    const handlePeriodChange = async (event: React.ChangeEvent<{ value: unknown }>): Promise<void> => {
      const periodKey = event.target.value as filteredPeriodKeys;
      const groupedPefas = filterPefas(periodKey);

      await setState((prevState) => ({
        ...prevState,
        periodKey,
        pefaDateKeys: Object.keys(groupedPefas),
        groupedPefas,
      }));
    };

    const handleRefreshPefas = async (): Promise<void> => {
      const { rootStore } = props;

      if (rootStore) {
        setState((prevState) => ({
          ...prevState,
          isLoading: true,
          pefaDateKeys: [],
        }));

        await fetchPefaHandler(true);
      }
    };

    const filterPefas = (periodKey: filteredPeriodKeys): any => {
      const { rootStore } = props;

      if (rootStore) {
        const groupedPefas = rootStore.pefaStore.filteredPefas(periodKey, state.pefaListingCurrent);

        let pefaCount = 0;
        const pefaKeys = Object.keys(groupedPefas);
        pefaKeys.forEach((pefaKey) => {
          pefaCount += groupedPefas[pefaKey].length;
        });

        setTotalPefas(pefaCount);

        return groupedPefas;
      }
      return {};
    };

    /**
     * Handles the onclick event generated when a pefa card is clicked.
     * @param event the onClick event generated when the pefa is clicked.
     */
    const handlePefaClicked = async (event: React.MouseEvent<HTMLDivElement>, pefaID: string) => {
      //Shortcut the rootstore from props. This saves us calling props.rootstore every time.
      //We can just call rootstore directly instead.
      const { rootStore } = props;
      const pefaIDInt = parseInt(event.currentTarget.id);

      //Select and pre-load the PEFA from the state.
      await rootStore?.pefaStore.selectPefa(pefaIDInt);

      //If the PEFA is checked in and the current user is the assigned assessor,
      if (
        rootStore?.pefaStore.isPefaCheckedIn(pefaIDInt) &&
        rootStore?.pefaStore.isAssignedAssessor(pefaIDInt, rootStore.userStore.getUserAssessorNumber) &&
        rootStore.userStore.isUserAssessor
      ) {
        //send them to the medical details page if the checkin includes informed consent.
        if (rootStore?.pefaStore.isCheckInIncludingInformedConsent(pefaIDInt)) {
          history.push(MEDICALDETAILS);
        } else {
          //Otherwise, send them to the informed consent page instead.
          history.push(INFORMEDCONSENT);
        }
      } else {
        //If they are not the an assessor, and not the assigned assessor and the pefa is not checked in, take them to the beginning.
        history.push(REFERRALDETAILS);
      }
    };

    const handlePefaPreviewClicked = async (event: React.MouseEvent<HTMLDivElement>, pefaID: string) => {
      event.stopPropagation();

      await props.rootStore?.pefaStore.selectPefa(parseInt(pefaID));
      history.push(ASSESSMENTPREVIEW);
    };

    const handlePefaListingChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      let { pefaListingCurrent } = state;

      //If there has been no change, escape the function and do nothing.
      if ((pefaListingCurrent && event.currentTarget.name === "currentPefas") || (!pefaListingCurrent && event.currentTarget.name === "completedPefas")) {
        return;
      }

      if (event.currentTarget.name === "currentPefas") {
        pefaListingCurrent = true;
      } else {
        pefaListingCurrent = false;
      }

      setState((prevState) => ({
        ...prevState,
        isLoading: true,
        pefaListingCurrent,
      }));
    };

    const fetchPefaHandler = async (refreshPefas: boolean) => {
      if (auth) {
        const userInfo = auth.isLoading ? await auth.userManager.getUser() : auth.userData;

        if (userInfo?.access_token && refreshPefas) {
          await props.rootStore?.pefaStore.fetchPefas();
          const groupedPefas = filterPefas(state.periodKey);

          // update caches of checkin and informed consent
          const pefaKeys = Object.keys(groupedPefas);
          pefaKeys.forEach((pefaKey) => {
            const pefa = groupedPefas[pefaKey];
            if (pefa.length > 0) {
              if (pefa[0].checkinInformation != null) {
                props.rootStore?.dataStore.saveCheckInData(JSON.parse(pefa[0].checkinInformation.content), pefa[0].id, dataStorePageKeys.REFERRAL_DETAILS);
              }
              if (pefa[0].informedConsent != null) {
                props.rootStore?.dataStore.saveCheckInData(JSON.parse(pefa[0].informedConsent), pefa[0].id, dataStorePageKeys.INFORMED_CONSENT);
              }
            }
          });

          setState((prevState) => ({
            ...prevState,
            isLoading: false,
            pefaDateKeys: Object.keys(groupedPefas),
            groupedPefas,
          }));
        }
      }
    };

    /**
     * Create a listener that will run only the first time the dashboard is loaded.
     */
    useEffect(() => {
      async function initDashboard() {
        const { rootStore } = props;

        if (!rootStore) return;

        if (!auth.isLoading) {
          await fetchPefaHandler(true);
        }

        setState((prevState) => ({
          ...prevState,
          isInitialised: true,
          isLoading: false,
          pefaCount: rootStore.pefaStore.pefaCount,
          name: !!rootStore.userStore.userDetails ? rootStore.userStore.getUserNameFormatted : "",
        }));
      }
      if (!state.isInitialised) {
        initDashboard();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.isLoading]);

    /**
     * Create a listener for the periodKey value stored in the state.
     * If the value changes, call the filterPefas function and update the pefas that are displayed.
     */
    useEffect(() => {
      const groupedPefas = filterPefas(state.periodKey);

      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        pefaDateKeys: Object.keys(groupedPefas),
        groupedPefas,
      }));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.periodKey]);

    return (
      <div className="Dashboard">
        <Header />
        <Grid container>
          <Grid item xs={12}></Grid>
          <Grid item container>
            <Grid item xs={1} sm={1} />

            <Grid item container direction="column" xs={10} sm={10}>
              <Grid item container style={{ marginTop: "30px" }} direction="column" xs={12}>
                <Grid item xs={9}>
                  <Typography variant="h1" style={{ color: "#FFFFFF" }}>
                    <img alt="wave" src={Wave} style={{ marginRight: "10px" }} />
                    Welcome to your <br /> schedule, <span style={{ color: "#1CBEAF" }}>{state.name}</span>
                  </Typography>
                </Grid>
                <Grid item xs={3} />
              </Grid>
              <Grid item container spacing={2} style={{ marginTop: "0px" }} direction="row" xs={12}>
                <Grid item xs={8}>
                  <Typography variant="subtitle1" className="introText">
                    <Button className="refreshButton" onClick={handleRefreshPefas}>
                      <img alt="refresh" src={RefreshIcon} style={{ padding: "0px 0px" }} width="45" />{" "}
                    </Button>{" "}
                    You have <span style={{ color: "#1CBEAF", fontWeight: "bold" }}>{props.rootStore?.pefaStore.getTotalNumberOfAppointments()}</span> PEFA's lined up.
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  {state.pefaListingCurrent ? (
                    <FormControl fullWidth={true} variant="outlined">
                      <Select color="primary" fullWidth={true} id="dayselector-select-outlined" onChange={handlePeriodChange} value={state.periodKey} className="DayChanger">
                        <MenuItem value={filteredPeriodKeys.TODAY}>Today</MenuItem>
                        <MenuItem value={filteredPeriodKeys.TOMORROW}>Tomorrow</MenuItem>
                        <MenuItem value={filteredPeriodKeys.THREEDAYS}>Next 3 days</MenuItem>
                        <MenuItem value={filteredPeriodKeys.SEVENDAYS}>Next 7 Days</MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>

              {!isChrome() && (
                <div
                  style={{
                    fontSize: "0.9em",
                    position: "relative",
                    padding: ".75rem 1.25rem",
                    border: "1px solid transparent",
                    borderRadius: ".25rem",
                    color: "#856404",
                    backgroundColor: "#fff3cd",
                    borderColor: "#ffeeba",
                    marginTop: "40px",
                  }}
                >
                  We recommend <strong>Google Chrome</strong> browser for best performance.
                </div>
              )}

              <Grid item container spacing={2} style={{ marginBottom: "10px" }} direction="row" xs={12}>
                <Grid item xs={5}>
                  <Typography className="pefaAppointments">
                    <span>{totalPefas}</span> PEFA Appointments
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  xs={7}
                  direction="row"
                  justify="center"
                  alignItems="center"
                  style={{
                    textAlign: "right",
                    justifyContent: "flex-end",
                    verticalAlign: "middle",
                    paddingTop: "60px",
                    paddingBottom: "24px",
                  }}
                >
                  <Typography style={{ color: "#222E50" }}>
                    <FiberManualRecordIcon style={{ color: "#dde2e5", marginBottom: "-7px" }} /> Pending
                  </Typography>
                  <Typography style={{ color: "#222E50", marginLeft: "25px" }}>
                    <FiberManualRecordIcon style={{ color: "#FEC93D", marginBottom: "-7px" }} /> In Progress
                  </Typography>
                  <Typography style={{ color: "#222E50", marginLeft: "25px" }}>
                    <FiberManualRecordIcon style={{ color: "#20D347", marginBottom: "-7px" }} /> Checked In
                  </Typography>
                  <Typography style={{ color: "#222E50", marginLeft: "25px" }}>
                    <FiberManualRecordIcon style={{ color: "#FF0000", marginBottom: "-7px" }} /> Past
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container direction="column">
                {state.pefaDateKeys.length > 0 ? (
                  <>
                    {state.pefaDateKeys.map((pefaDataKey: any) => {
                      if (state.groupedPefas[pefaDataKey].length > 0) {
                        return (
                          <>
                            <Typography className="daterK">{pefaDataKey}</Typography>
                            {state.groupedPefas[pefaDataKey].map((pefa: any) => (
                              <PefaCard key={pefa.id} pefaAssessment={pefa} handlePefaCardClick={handlePefaClicked} handlePefaPreviewClick={handlePefaPreviewClicked} />
                            ))}
                          </>
                        );
                      }
                    })}
                  </>
                ) : (
                  [
                    !state.isLoading ? (
                      <Card key="none" className="PefaCard" variant="outlined">
                        <Typography
                          style={{
                            margin: "25px",
                          }}
                        >
                          There are no PEFA's to display.
                        </Typography>
                      </Card>
                    ) : (
                      ""
                    ),
                  ]
                )}
              </Grid>
            </Grid>

            <Grid item xs={1} sm={1} />
          </Grid>
        </Grid>
        <LoadingIndicator visible={state.isLoading} />
      </div>
    );
  })
);

export default Dashboard;

import * as yup from "yup";

const finalBPValidation = yup.object().shape({
  bloodPressureSystolic4: yup.string().test("bloodPressureSystolic4", "Please enter the participant's final blood pressure reading.", function () {
    const { bloodPressureSystolic4 } = this.parent;
    return bloodPressureSystolic4 !== null && bloodPressureSystolic4 !== "";
  }),
  bloodPressureDiastolic4: yup.string().test("bloodPressureDiastolic4", "Please enter the participant's final blood pressure reading.", function () {
    const { bloodPressureDiastolic4 } = this.parent;
    return bloodPressureDiastolic4 !== null && bloodPressureDiastolic4 !== "";
  }),
});

export default finalBPValidation;

import { createMuiTheme } from "@material-ui/core/styles";
import BgImg from "./assets/images/bluebar.png";
import HelpIcon from "./assets/images/helpIcon.svg";
import CheckboxIcon from "./assets/images/checkbox.svg";
import CheckboxIconChecked from "./assets/images/checked.svg";
import BentonSans from "./Fonts/BentonSans.woff";
import BentonSansBold from "./Fonts/BentonSans-Bold.woff";

const bentonSans: any = {
  fontFamily: "BentonSans",
  fontStyle: "regular",
  fontDisplay: "swap",
  fontWeight: "400",
  src: `
    local('BentonSans'),
    url(${BentonSans}) format('woff')
  `,
  unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const bentonSansBold: any = {
  fontFamily: "BentonSans",
  fontStyle: "bold",
  fontDisplay: "swap",
  fontWeight: "700",
  src: `
    local('BentonSans-Bold'),
    url(${BentonSansBold}) format('woff')
  `,
  unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#1CBEAF",
      dark: "#189589",
      light: "",
    },

    action: {
      disabledBackground: "#BDBDBD",
    },

    background: { default: "#F8F8FF" },
  },
  typography: {
    fontFamily: ["BentonSans", '"Open Sans"', "Roboto"].join(","),
    fontWeightRegular: "normal",
    fontSize: 16,
    h1: {
      fontSize: 49,
      fontFamily: '"BentonSans", Open Sans, Roboto',
      fontWeight: 700,
    },
    h2: {
      fontSize: 31,
      fontFamily: '"BentonSans", Open Sans, Roboto',
      fontWeight: 600,
      color: "#222E50",
    },
    h3: {
      fontSize: 39,
      fontFamily: '"BentonSans", Open Sans, Roboto',
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: 16,
      fontFamily: '"BentonSans", Open Sans, Roboto',
      fontWeight: 400,
    },
    caption: {
      fontSize: 14,
      fontFamily: '"BentonSans", Open Sans, Roboto',
      fontWeight: 700,
    },
    button: {
      textTransform: "none",
      fontWeight: "bold",
    },
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
  },
  overrides: {
    MuiGrid: {
      root: {
        //height: '100vh',
      },
    },
    MuiButton: {
      containedPrimary: {
        "&:hover:not($disabled)": {
          backgroundColor: "#189589",
        },
      },
      outlinedPrimary: {
        "&:hover:not($disabled)": {
          backgroundColor: "rgba(28, 190, 175, 0.15)",
        },
      },
    },
    MuiCssBaseline: {
      "@global": {
        "@font-face": [bentonSans, bentonSansBold],
        ".MuiButton-containedPrimary": {
          fontSize: "16px",
          padding: "15px 60px",
          color: "#ffffff",
        },
        ".MuiButton-outlinedPrimary": {
          fontSize: "16px",
          padding: "15px 40px",
          marginRight: "15px",
        },
        ".MuiAppBar-colorPrimary": {
          backgroundColor: "#222E50 !important",
          padding: "10px",
        },
        ".percentage": {
          color: "#222E50",
          fontSize: "12px !important",
          display: "block",
          marginTop: "-5px",
        },
        ".percent": {
          color: "#7A8296",
        },
        ".targetWeight": {
          position: "absolute",
          right: "170px",
          bottom: "30px",
        },
        ".targetWeightText": {
          color: "#1CBEAF",
          display: "block",
          fontSize: "39px",
          fontWeight: "bold",
          right: "150px",
        },
        ".MuiTypography-body1": {
          fontSize: "16px !important",
        },
        ".MuiButton-label span.bubble": {
          width: "32px",
          height: "32px",
          borderRadius: "50%",
          backgroundColor: "#1CBEAF",
          padding: "4px",
          color: "#ffffff",
        },
        ".MuiFormControlLabel-root": {
          color: "#495057",
        },
        ".Dashboard": {
          minHeight: "100vh",
          backgroundImage: `url(${BgImg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top right",
          backgroundSize: "auto 335px",
          //backgroundColor: '#FF0000'
        },
        ".pefaAppointments": {
          color: "#101b3c",
          marginTop: "60px",
          fontSize: "16px !important",
          fontWeight: "bold",
        },
        ".pefaAppointments span": {
          color: "#1cbeaf",
          fontSize: "16px",
          fontWeight: "bold",
        },
        ".PefaSent": {
          minHeight: "100vh",
          backgroundColor: "#F2F3F5",
          overflow: "hidden !important",
        },
        ".helpIcon": {
          height: "25px",
          width: "25px",
          backgroundImage: `url(${HelpIcon})`,
          overflow: "hidden !important",
          textIndent: "-999px",
          display: "block",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          position: "absolute",
          top: "10px",
          right: "10px",
        },
        ".Dashboard .MuiGrid-item": {
          alignItems: "initial !important",
        },
        ".PefaSentHeading": {
          color: "#222E50",
          fontSize: "39px",
          fontWeight: "bold",
          marginTop: "25px",
        },
        ".upload": {
          height: "20px",
        },
        ".PefaSentCopy": {
          color: "#222E50",
          fontSize: "16px",
          fontWeight: "normal",
          width: "630px",
        },
        ".PefaSentCopy span": {
          color: "#1CBEAF",
          fontWeight: "bold",
        },
        ".PefaSentButton": {
          position: "relative",
          zIndex: 10,
          marginTop: "50px",
        },
        ".introText": {
          color: "#ffffff",
          fontSize: "18px",
        },
        ".MuiSelect-outlined": {
          backgroundColor: "#FFFFFF",
        },
        ".MuiPopover-paper": {
          marginTop: "65px",
          width: "250px",
        },
        ".MuiListItem-button": {
          margin: "8px 0 2px 0",
          fontSize: "14px !important",
        },
        ".errorTitle": {
          margin: "20px 0 20px 0",
          fontSize: "39px !important",
          color: "#222E50",
          fontWeight: "bold",
        },
        ".errorMessage": {
          margin: "20px auto 20px auto",
          width: "420px",
          fontSize: "16px !important",
          color: "#222E50",
        },
        ".settingsHeaderText": {
          color: "#A9A9A9",
          fontWeight: "normal",
          paddingLeft: "16px",
          marginBottom: "25px",
        },
        ".MenuIconName": {
          backgroundColor: "#1CBEAF",
          border: "2px solid",
          borderRadius: "50%",
          padding: "11px 8px 8px 8px",
          width: "45px",
          height: "45px",
          color: "#FFFFFF",
          fontSize: "13px",
          fontWeight: "600",
        },
        ".refreshButton": {
          margin: "0",
          borderRadius: "25px",
          padding: "10px 0",
        },
        ".settingsText": {
          color: "#C9CED6",
          fontWeight: "normal",
          lineHeight: "1",
        },
        ".DayChanger": {
          backgroundColor: "#FFFFFF",
        },
        ".selectOveride": {
          maxWidth: "180px",
          marginRight: "15px !important",
        },
        ".selectMC": {
          maxWidth: "88px !important",
          marginRight: "10px !important",
        },
        ".MuiSelect-iconOutlined": {
          color: "#1CBEAF",
        },
        ".PefaBtn": {
          color: "#ffffff",
          lineHeight: "40px",
          padding: "4px 10px 10px 10px",
        },
        ".PefaBtnSelected": {
          color: "#ffffff",
          lineHeight: "40px",
          borderRadius: "0",
          padding: "5px 15px",
          borderBottomStyle: "solid",
          borderBottomWidth: "5px",
          borderBottomColor: "#1CBEAF",
        },
        ".PefaCard": {
          marginBottom: "20px !important",
          padding: "0px !important",
          maxWidth: "100%",
          boxShaddow: "0 4px 20px 0 rgb(0 0 0 / 14%)",
        },
        ".PefaCaption": {
          color: "#222E50",
          textAlign: "left",
          fontSize: "10px",
        },
        ".pefaCardContainer": {
          display: "flex",
          flexGrow: 1,
          alignItems: "center",
          justifyContent: "space-between",
        },
        ".pefaCardData": {
          textAlign: "left",
        },
        ".dob-label": {
          width: "250px !important",
        },
        ".referral-dob .MuiInputBase-input": {
          width: "170px !important",
        },
        ".referral-biological .MuiInputBase-input": {
          width: "95px !important",
        },
        ".MuiAccordion-root.Mui-disabled": {
          opacity: "0.5",
          backgroundColor: "#ffffff",
        },
        ".pefaCardDataWorker": {
          "@media (max-width: 1080px)": {
            minWidth: "auto",
            padding: "0 4px 0 4px",
          },
          textAlign: "left",
          minWidth: "130px",
        },
        ".pefaCardDataReport": {
          "@media (max-width: 1080px)": {
            minWidth: "auto",
            padding: "0 4px 0 4px",
          },
          textAlign: "left",
          minWidth: "16px",
        },
        ".pefaCardDataDue": {
          "@media (max-width: 1080px)": {
            minWidth: "auto",
            padding: "0 4px 0 4px",
          },
          textAlign: "left",
          minWidth: "65px",
        },
        ".pefaCardDataTime": {
          "@media (max-width: 1080px)": {
            minWidth: "auto",
            padding: "0 4px 0 4px",
          },
          textAlign: "left",
          minWidth: "80px",
        },
        ".pefaCardDataGender": {
          "@media (max-width: 1080px)": {
            width: "15px",
          },
          textAlign: "left",
          width: "80px",
        },
        ".pefaCardDataGender img": {
          "@media (max-width: 1080px)": {
            display: "none",
          },
        },
        ".pefaCardDataAssessment": {
          "@media (max-width: 1080px)": {
            width: "auto",
            padding: "0 4px 0 4px",
          },
          textAlign: "left",
          width: "180px",
        },
        ".pefaCardPreview": {
          "@media (max-width: 1080px)": {
            width: "auto",
            padding: "0 4px 0 4px",
          },
          width: "64px",
        },
        ".statusIndicator": {
          width: "5px",
          height: "70px",
          marginLeft: "5px",
          backgroundColor: "#CBF0ED",
          borderRadius: "25%",
        },
        ".statusIndicatorCheckedIn": {
          width: "5px",
          height: "70px",
          marginLeft: "5px",
          backgroundColor: "#20D347",
          borderRadius: "25%",
        },
        ".statusIndicatorPastDue": {
          width: "5px",
          height: "70px",
          marginLeft: "5px",
          backgroundColor: "#FF0000",
          borderRadius: "25%",
        },
        ".statusIndicatorInProgress": {
          width: "5px",
          height: "70px",
          marginLeft: "5px",
          backgroundColor: "#FEC93D",
          borderRadius: "25%",
        },
        ".PoweredBy": {
          fontWeight: "400",
          fontSize: "12px",
          textAlign: "left",
          color: "#DFDFDF",
        },
        ".MuiMenuItem-root": {
          fontWeight: "400",
          fontSize: "12px",
          textAlign: "left",
          color: "#222E50",
        },
        ".MuiListItem-button:hover": {
          backgroundColor: "initial !important",
        },
        ".MuiMenuItem-root:hover": {
          backgroundColor: "initial !important",
          color: "#1CBEB0",
        },
        ".MuiGrid-item": {
          alignItems: "flex-start",
          textAlign: "left",
        },
        ".Stepper": {
          width: "100%",
        },
        ".MuiStepLabel-completed": {
          opacity: "1 !important",
        },
        ".MedicalConsent": {
          width: "100%",
          flexGrow: 1,
        },
        ".medicalQuestion": {
          width: "670px",
          alignSelf: "center",
        },
        ".ellipsis": {
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          width: "500px",
        },
        ".flexerEnd": {
          flexGrow: 1,
          textAlign: "end",
        },
        ".flexerCenter": {
          flexGrow: 1,
          textAlign: "center",
        },
        ".flexerEnd .flexerCheckbox": {
          width: "80px",
        },
        ".amberNote": {
          width: "100%",
          flexGrow: 1,
          background: "rgba(248, 140, 36, 0.1)",
          borderRadius: "5px",
          padding: "24px",
          marginTop: "40px",
        },
        ".amberNoteDetails": {
          color: "#f88c24",
          fontSize: "16px",
          lineHeight: "24px",
          marginTop: "5px",
        },
        ".AssessmentDetails": {
          width: "100%",
          flexGrow: 1,
        },
        ".disabledCard": {
          opacity: "0.5 !important",
        },
        ".uCard": {
          width: "100%",
          padding: "20px",
          flexGrow: 1,
          position: "relative",
        },
        ".consentCard": {
          width: "100%",
          padding: "20px",
          flexGrow: 1,
          position: "relative",
          paddingBottom: "15px !important",
          marginBottom: "20px !important",
        },
        ".medicalCard": {
          width: "100%",
          padding: "20px",
          flexGrow: 1,
          position: "relative",
          paddingBottom: "15px !important",
          marginBottom: "20px !important",
        },
        ".consentCard .MuiTypography-body2": {
          width: "90%",
          padding: "10px 0",
          color: "#495057",
        },
        ".medicalCard .flexer": {
          margin: "20px 0",
        },
        ".medicalHistory": {
          width: "100%",
          padding: "20px",
          flexGrow: 1,
          position: "relative",
          paddingBottom: "15px !important",
          marginBottom: "20px !important",
        },
        ".medicalHistory .MuiFormGroup-root": {
          marginBottom: "5px",
        },
        ".medicalHistory .med-note": {
          marginBottom: "15px",
          width: "100%",
        },
        ".medicalHistory .small-note": {
          width: "50%",
          marginRight: "15px !important",
        },
        ".medicalHistory .addButton": {
          height: "48px",
          fontSize: "25px !important",
          marginBottom: "9px",
          fontWeight: "normal",
          border: "none",
          cursor: "pointer",
        },
        ".medicalHistory .MuiOutlinedInput-input": {
          padding: "13.5px 14px",
        },
        ".medicalHistory .selectOveride": {
          maxWidth: "250px",
          marginRight: "15px !important",
        },
        ".med-fullWidth": {
          width: "100%",
        },
        ".sigBlock": {
          width: "100%",
          flexGrow: 1,
          padding: "10px 0",
          backgroundColor: "#f2f2f2",
          borderRadius: "5px",
          textAlign: "center",
        },
        ".sigBlock canvas": {
          backgroundImage:
            "linear-gradient(45deg, #EDEDED 25%, transparent 25%), linear-gradient(-45deg, #EDEDED 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #EDEDED 75%), linear-gradient(-45deg, transparent 75%, #EDEDED 75%)",
          backgroundSize: "20px 20px",
          backgroundColor: "#FFFFFF",
          backgroundPosition: "0 0, 0 10px, 10px -10px, -10px 0px",
        },
        ".yCard": {
          width: "100%",
          padding: "20px !important",
          flexGrow: 1,
        },
        ".MuiStepper-horizontal": {
          width: "100%",
          flexGrow: 1,
          background: "none",
        },
        ".stepperContainer": {
          backgroundColor: "#222E50",
          marginBottom: "40px !important",
          color: "#222E50",
        },
        ".introPefaText span": {
          color: "#1CBEAF",
          fontSize: "16px",
        },
        ".PefaText": {
          "@media (max-width: 1025px)": {
            fontSize: "12px !important",
          },
          fontSize: "12px !important",
        },
        ".MuiAccordion-root": {
          width: "100%",
        },
        ".UserProfile": {
          display: "flex",
          flexGrow: 1,
          justifyContent: "center",
          position: "relative",
          height: "300px",
        },
        ".UserProfileGreen": {
          display: "flex",
          flexGrow: 1,
          justifyContent: "center",
          position: "relative",
          height: "300px",
          background: "#20D347",
        },
        ".UserProfileGreen .MHR": {
          background: "#FFFFFF",
          color: "#222E50 !important",
        },
        ".UserProfileYellow": {
          display: "flex",
          flexGrow: 1,
          justifyContent: "center",
          position: "relative",
          height: "300px",
          background: "#FFEE33",
        },
        ".UserProfileYellow .MHR": {
          background: "#FFFFFF",
          color: "#222E50 !important",
        },
        ".UserProfileOrange": {
          display: "flex",
          flexGrow: 1,
          justifyContent: "center",
          position: "relative",
          height: "300px",
          background: "#FFA733",
        },
        ".UserProfileOrange .MHR": {
          background: "#FFFFFF",
          color: "#222E50 !important",
        },
        ".UserProfileRed": {
          display: "flex",
          flexGrow: 1,
          justifyContent: "center",
          position: "relative",
          height: "300px",
          background: "#FF0000",
        },
        ".UserProfileRed .MHR": {
          background: "#FFFFFF",
          color: "#222E50 !important",
        },
        ".MHR": {
          position: "absolute",
          left: "10px",
          right: "10px",
          top: "10px",
          textAlign: "center",
          backgroundColor: "#F2F3F5",
          borderRadius: "4px",
          padding: "10px",
          color: "#222E50",
        },
        ".MuiCard-root": {
          borderRadius: "8px",
          flexGrow: 1,
          padding: "30px 30px 0px 30px",
          marginBottom: "8px",
        },
        ".formControl": {
          flexGrow: 1,
          marginBottom: "14px !important",
        },
        ".MuiOutlinedInput-root": {
          borderRadius: "8px !important",
        },
        ".introPefaText": {
          margin: "30px 0",
          width: "70%",
          color: "#222E50",
        },
        ".formNavigator": {
          position: "fixed",
          bottom: "0px",
          padding: "20px 0",
          backgroundColor: "#FFF",
          borderRadius: "8px 8px 0 0",
          boxShadow: "-1px 1px 9px -7px rgba(0,0,0, 0.8)",
          zIndex: 100,
        },
        ".FormStyler": {
          paddingBottom: "150px",
        },
        ".RightCol": {
          paddingLeft: "40px",
          position: "sticky",
          top: "10px",
          alignContent: "baseline",
        },
        ".buttonsRight": {
          display: "flex",
          alignSelf: "flex-end",
        },
        ".extraNotesBtn": {
          width: "100%",
          border: "1px solid #1CBEAF",
          borderRadius: "8px",
          color: "#1CBEAF",
          padding: "15px",
          marginTop: "20px",
          fontWeight: "bold",
        },
        ".intest-mc": {
          alignSelf: "flex-end",
          marginTop: "20px",
        },
        ".saveNoteBtn": {
          border: "1px solid #1CBEAF",
          borderRadius: "8px",
          color: "#FFFFFF",
          backgroundColor: "#1CBEAF",
          padding: "15px 50px",
          marginTop: "20px",
          alignSelf: "flex-end",
          fontWeight: "bold",
        },
        ".retestBtn": {
          border: "1px solid #1CBEAF",
          borderRadius: "8px",
          color: "#1CBEAF",
          padding: "15px 50px",
          marginTop: "20px",
          marginRight: "20px",
          alignSelf: "flex-end",
          fontWeight: "bold",
        },
        ".SpaceBetween": {
          justifyContent: "space-between",
          width: "100%",
          display: "flex",
        },
        ".notesText": {
          color: "#FFFFFF",
        },
        ".typeTag": {
          backgroundColor: "#101B3C",
          color: "#FFFFFF",
          fontWeight: "bold",
          padding: "10px 20px",
          borderRadius: "32px",
          marginBottom: "20px",
          display: "inline-block",
        },
        ".pastNotesHeader": {
          fontWeight: "normal",
          fontSize: "16px",
          marginBottom: "15px",
          lineHeight: "16px",
          color: "#7A8296",
        },
        ".prevResult": {
          fontWeight: "normal",
          fontSize: "16px",
          color: "#A9A9A9",
          marginRight: "20px",
        },
        ".actualResult": {
          fontWeight: "bold",
          fontSize: "16px",
          color: "#222E50",
        },
        ".notesContainer": {
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#2B375A",
          borderRadius: "20px",
          marginBottom: "8px",
          padding: "30px",
        },
        ".roundButton": {
          background: "#FFFFFF",
          border: "1px solid #FFFFFF",
          boxSizing: "border-box",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          borderRadius: "50%",
          width: "60px",
          height: "60px",
          marginRight: "10px",
        },
        ".modalPopNotes": {
          height: "90vh !important",
          outline: "none",
          position: "absolute",
          bottom: "0",
          right: "0",
          left: "0",
          background: "#222E50",
          borderTopRightRadius: "20px",
          borderTopLeftRadius: "20px",
        },
        ".modalPopFull": {
          height: "90vh !important",
          outline: "none",
          position: "absolute",
          bottom: "0",
          right: "0",
          left: "0",
          background: "#FFFFFF",
          borderTopRightRadius: "20px",
          borderTopLeftRadius: "20px",
        },
        ".modalPopSmall": {
          height: "530px !important",
          outline: "none",
          position: "absolute",
          bottom: "0",
          right: "0",
          left: "0",
          background: "#FFFFFF",
          borderTopRightRadius: "20px",
          borderTopLeftRadius: "20px",
        },
        ".ModalBox": {
          width: "600px",
          margin: "0px auto",
          background: "#FFFFFF",
          height: "fit-content",
          padding: "20px",
          borderRadius: "8px",
          alignSelf: "center",
          outline: "none",
          boxShadow: "0 0 12px -7px black",
          display: "flex",
          flexWrap: "nowrap",
          flexDirection: "column",
        },
        ".ModalBox.Error": {
          width: "900px",
        },
        ".ModalSuper": {
          height: "100%",
          outline: "none",
          display: "grid",
        },
        ".ErrorModalTextArea": {
          width: "100%",
          minHeight: "600px",
          resize: "none",
        },
        ".editingTag": {
          color: "#1CBEAF !important",
          marginTop: "-10px",
        },
        ".editingText": {
          color: "#FFFFFF !important",
        },
        ".MuiStepLabel-label": {
          color: "#FFFFFF !important",
          opacity: "0.3",
          textAlign: "left",
          fontWeight: "700 !important",
          fontSize: "12px",
        },
        ".MuiStepLabel-label.MuiStepLabel-active": {
          color: "#1CBEAF !important",
          opacity: "1 !important",
        },
        ".MuiStepConnector-line": {
          borderColor: "#222e50 !important",
        },
        ".backButton": {
          marginRight: "10px",
          color: "#1CBEAF",
          padding: "15px 60px",
          fontSize: "16px",
          borderColor: "#1CBEAF",
        },
        ".outlinedButton": {
          marginRight: "10px",
          color: "#1CBEAF",
          padding: "15px 20px",
          fontSize: "16px",
          borderColor: "#1CBEAF",
        },
        ".buttonEnd": {
          marginLeft: "10px",
          color: "#1C253F",
          padding: "15px 20px",
          fontSize: "16px",
          backgroundColor: "#F2F2F2",
        },
        ".greenEnd": {
          marginLeft: "10px",
          color: "#ffffff",
          padding: "15px 20px",
          fontSize: "16px",
          backgroundColor: "#1CBEAF",
        },
        ".firstName": {
          fontWeight: "bold !important",
          fontSize: "16px",
        },
        ".lastName": {
          fontSize: "14px",
        },
        ".summaryGroup": {
          display: "flex",
        },
        ".summaryContent": {
          display: "flex",
          width: "50%",
        },
        ".summaryActivity": {
          width: "150px",
        },
        ".summaryActivityTitle": {
          color: "#495057",
          fontSize: "16px",
          fontWeight: "bold",
          marginBottom: "15px",
          width: "220px",
        },
        ".summaryActivityTitle.heading": {
          color: "#A9A9A9",
          fontSize: "16px",
          marginBottom: "15px",
          fontWeight: "normal",
          width: "220px",
        },
        ".agreementCaption": {
          color: "#101B3C",
          fontSize: "14px",
          fontWeight: "normal",
          marginBottom: "0px",
        },
        ".summaryDemandTitle": {
          color: "#495057",
          fontSize: "16px",
          textAlign: "center",
          marginBottom: "15px",
          width: "60px",
        },
        ".summaryDemandMatch.tick": {
          fontSize: "16px",
          textAlign: "center",
          marginBottom: "-10px",
          width: "60px",
        },
        ".summaryDemandMatch.cross": {
          fontSize: "16px",
          textAlign: "center",
          marginBottom: "15px",
          width: "60px",
        },
        ".summaryDemandTitle.heading": {
          color: "#A9A9A9",
          fontSize: "16px",
          textAlign: "center",
          marginBottom: "15px",
          width: "60px",
        },
        ".summaryDemand": {
          minWidth: "130px",
          textAlign: "center",
          width: "80px",
        },
        ".MuiSwitch-input": {
          zIndex: 1000,
        },
        ".outlineTextAreaFinal": {
          marginRight: "10px !important",
          marginBottom: "10px !important",
        },
        ".outlineTextAreaSm": {
          marginRight: "10px !important",
          marginBottom: "10px !important",
          maxWidth: "90px",
        },
        ".outlineTextAreaFinalFloor": {
          marginRight: "10px !important",
          flexGrow: 1,
          marginBottom: "10px !important",
        },
        ".noGrowField": {
          flexGrow: 0,
          display: "block !important",
        },
        ".summaryPerformance": {
          minWidth: "130px",
          textAlign: "center",
        },
        ".heroHeading": {
          marginBottom: "20px",
          color: "#222E50",
        },
        ".previewSection": {
          marginBottom: "20px",
          paddingBottom: "20px",
        },
        ".stepTestSection": {
          margin: "20px 0",
          padding: "20px 0",
          display: "flex",
          borderBottom: "1px solid #E5E7EC",
        },
        ".stepTestSection span": {
          color: "#1CBEAF",
          fontWeight: "bold",
          marginRight: "10px",
          paddingTop: "15px",
          display: "flex",
        },
        ".mhTestSection": {
          margin: "20px 0",
          padding: "20px 0",
          display: "flex",
          flexFlow: "wrap",
          borderBottom: "1px solid #E5E7EC",
        },
        ".mhTestSection span": {
          color: "#1CBEAF",
          fontWeight: "bold",
          marginRight: "10px",
          paddingTop: "15px",
          display: "flex",
        },
        ".previewSection::after": {
          display: "block",
          marginTop: "30px",
          content: "no-close-quote",
          width: "66px",
          height: "2px",
          backgroundColor: "#E5E7EC",
        },
        ".sectionHeading": {
          marginBottom: "20px",
          color: "#222E50",
          fontSize: "25px",
          fontWeight: "bold",
        },
        ".typeHeading": {
          marginBottom: "10px",
          marginTop: "35px",
          color: "#222E50",
          fontSize: "14px",
          fontWeight: "bold",
        },
        ".typeSectionHeading": {
          marginBottom: "10px",
          marginTop: "35px",
          color: "#222E50",
          fontSize: "16px",
          fontWeight: "bold",
        },
        ".greySectionHeading": {
          marginBottom: "10px",
          marginTop: "35px",
          color: "#A9A9A9",
          fontSize: "13px",
          fontWeight: "bold",
        },
        ".agreementText": {
          marginBottom: "20px",
          color: "#222E50",
        },
        ".RadioAgreementTolerance": {
          marginBottom: "20px",
          color: "#495057",
        },
        ".RadioAgreement .MuiFormControlLabel-label::first-letter": {
          fontWeight: "bold !important",
        },
        ".RadioAgreementTolerance .MuiFormControlLabel-label": {
          fontWeight: "normmal !important",
          fontSize: "18px !important",
          color: "#495057",
        },
        ".greyHeading": {
          marginBottom: "15px",
          color: "#A9A9A9",
          fontSize: "16px",
        },
        ".toleranceRatingGroup": {
          marginLeft: "70px",
          marginBottom: "0px !important",
          fontSize: "16px",
        },
        ".greyPillHeading": {
          marginBottom: "15px",
          marginRight: "10px",
          color: "#495057",
          fontSize: "16px",
          display: "inline-block",
          background: "rgba(221, 226, 229, 0.4)",
          borderRadius: "8px",
          padding: "15px 20px",
        },
        ".minorHeading span": {
          color: "#1CBEAF",
        },
        ".minorHeading": {
          marginBottom: "20px",
          color: "#222E50",
          fontSize: "16px",
          fontWeight: "bold",
        },
        ".minorSubHeading": {
          marginBottom: "20px",
          fontSize: "16px",
          fontWeight: "500",
        },
        ".cardHeroHeading": {
          marginBottom: "0px",
          color: "#222E50",
        },
        ".cardHeroHeading span": {
          position: "absolute",
          left: "99px",
        },
        "H3.cardHeroHeading": {
          fontSize: "25px",
          fontWeight: "bold",
          marginBottom: "30px",
          color: "#222E50",
        },
        "H3.bpCardHeroHeading": {
          fontSize: "25px",
          fontWeight: "bold",
          marginBottom: "3px",
          marginLeft: "12px",
          color: "#222E50",
        },
        ".McardHeroHeading": {
          fontSize: "26px",
          fontWeight: "bold",
          marginLeft: "40px",
          flexGrow: 1,
          color: "#222E50",
          alignSelf: "center",
          textAlign: "left",
        },
        ".medicalHistoryTitle": {
          color: "#101b3c !important",
          fontSisze: "16px !important",
          fontWeight: "bold !important",
          lineHeight: "24px",
        },
        ".subtitleHeading": {
          color: "#101B3C !important",
          fontSisze: "12px !important",
          display: "block",
          marginLeft: "70px",
          fontWeight: "normal !important",
        },
        ".callJobFit": {
          color: "#222e50 !important",
          fontSize: "24px !important",
          padding: "10px 14px 4px 14px",
          lineHeight: "34px",
          fontWeight: "normal !important",
          background: "rgba(27,190,175,0.1)",
          display: "inline-block",
        },
        ".callJobFitText": {
          color: "#222e50 !important",
          marginTop: "25px",
          marginBottom: "15px",
          fontSize: "16px !important",
          lineHeight: "24px",
          fontWeight: "normal !important",
        },
        ".helpHeaderTextr": {
          color: "#222e50 !important",
          marginTop: "10px",
          marginBottom: "15px",
          fontSize: "39px !important",
          lineHeight: "46.7px",
          fontWeight: "bold !important",
        },
        ".flexer": {
          display: "flex",
          flexWrap: "wrap",
        },
        ".flexerGrow": {
          flexGrow: 1,
        },
        ".detailsFlexer": {
          flexGrow: 1,
        },
        ".detailsFlexer .MuiFormControlLabel-label": {
          width: "180px",
        },
        ".detailsFlexerLastName": {
          flexGrow: 1,
        },
        ".errorTimmy": {
          position: "absolute",
          bottom: "0px",
          left: "30px",
        },
        ".errorPage": {
          width: "100vw",
          height: "100vh",
          display: "flex",
          position: "relative",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        },
        ".medicalCheckbox .MuiFormControlLabel-label": {
          width: "190px",
        },
        ".enableEndPEFA .MuiFormControlLabel-label": {
          width: "490px",
        },
        ".in-testmc-chk .MuiFormControlLabel-label": {
          width: "260px",
        },
        ".agreeDownload": {
          marginTop: "10px",
        },
        ".detailsFlexerLastName .MuiFormControlLabel-label": {
          width: "95px",
          marginLeft: "10px",
        },
        ".detailsFlexerDOB .MuiFormControlLabel-label": {
          width: "180px",
        },
        ".detailsFlexerBiological .MuiFormControlLabel-label": {
          width: "120px",
          marginLeft: "10px",
        },
        ".flexerCheckbox": {
          width: "240px",
        },
        ".flexerSpaceBetween": {
          display: "flex",
          flexGrow: 1,
          flexWrap: "wrap",
          justifyContent: "space-between",
        },
        ".mobilityTestContainer": {
          flexGrow: 1,
        },
        ".inputBoldBlue": {
          marginLeft: "70px !important",
        },
        ".inputBoldBlueSolo": {
          marginLeft: "70px !important",
          marginBottom: "20px !important",
        },
        ".statusHalf": {
          width: "320px",
        },
        ".inputNoMargin": {
          marginLeft: "0px !important",
        },
        ".sobInput": {
          marginRight: "10px !important",
          color: "#7A8296",
        },
        ".sobPlaceHolder": {
          marginRight: "10px !important",
          color: "#c8c8c8 !important",
          backgroundColor: "#FFFFFF",
        },
        ".inputBoldBlue .MuiFormControlLabel-label": {
          fontSize: "16px",
          fontWeight: "bold",
          color: "#101B3C",
          whiteSpace: "nowrap",
          marginRight: "20px",
        },
        ".inputBoldBlueSolo .MuiFormControlLabel-label": {
          fontSize: "16px",
          fontWeight: "bold",
          color: "#101B3C",
          whiteSpace: "nowrap",
          marginRight: "20px",
        },
        ".inputNoMargin .MuiFormControlLabel-label": {
          fontSize: "16px",
          fontWeight: "bold",
          color: "#101B3C",
          whiteSpace: "nowrap",
          marginRight: "20px",
        },
        ".hr-pt": {
          marginLeft: "0px",
          marginRight: "10px",
          marginTop: "10px",
          fontSize: "15px",
        },
        ".hr-pt-radio .MuiFormControlLabel-label": {
          fontSize: "15px !important",
        },
        ".heart-rate-pt": {
          marginBottom: "0px !important",
        },
        ".downloadhighbp": {
          marginBottom: "10px !important",
        },
        ".strengthTestContainer": {
          flexGrow: 1,
        },
        ".hbpModalText": {
          width: "435px",
          margin: "0px auto",
        },
        ".hideBtn": {
          position: "absolute",
          top: "30px",
          right: "20px",
          color: "#FFFFFF",
          fontWeight: "bold",
          fontSize: "14px",
        },
        ".hideBtn-end": {
          position: "absolute",
          top: "30px",
          right: "20px",
          color: "black",
          fontWeight: "bold",
          fontSize: "14px",
        },
        ".notesHeader img": {
          marginRight: "15px",
        },
        ".notesHeader": {
          color: "#FFFFFF",
          fontSize: "25px",
          margin: "50px 0",
        },
        ".helpCaption": {
          color: "#222E50",
          fontWeight: "normal",
          fontSize: "16px",
          display: "block",
          marginBottom: "30px !important",
        },
        ".stopCaption": {
          color: "#A9A9A9",
          fontWeight: "normal",
          fontSize: "16px",
          display: "block",
          marginBottom: "30px !important",
        },
        ".stoppedTime": {
          color: "#1CBEAF",
        },
        ".helpHeader": {
          color: "#222E50",
          fontSize: "39px",
          margin: "50px 0 0 0",
        },
        ".helpHeader img": {
          display: "block",
          marginBottom: "15px",
        },
        ".notTestedSelect": {
          maxWidth: "250px",
          marginBottom: "20px !important",
        },
        ".medicalClearanceSelect": {
          maxWidth: "350px",
          marginBottom: "20px !important",
        },
        ".dropDown": {
          overflow: "scroll",
          menuPlacement: "top",
          maxHeight: "200px",
          paddingBottom: "20px",
        },
        ".notesModalText": {
          maxWidth: "994px",
          padding: "40px 0",
          margin: "0px auto",
          flexGrow: 1,
        },
        ".modalHeader": {
          fontSize: "39px !important",
          color: "#222E50 !important",
          marginBottom: "15px",
        },
        ".noWrap": {
          whiteSpace: "nowrap",
        },
        ".modalText": {
          fontSize: "16px !important",
          color: "#222E50 !important",
          marginBottom: "15px",
        },
        ".MuiFormLabel-root.bpLeft": {
          fontSize: "16px !important",
          color: "#101B3C !important",
          marginBottom: "0px",
          marginTop: "28px",
          alignSelf: "center",
          marginRight: "20px",
        },
        ".bpLimit": {
          fontWeight: "bold",
        },
        "img.bpSitting": {
          height: "35px !important",
          marginBottom: "-8px !important",
          marginRight: "20px !important",
        },
        ".MuiOutlinedInput-notchedOutline": {
          border: "1px solid #dde2e5 !important",
        },
        ".MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline": {
          border: "1px solid #1CBEAF !important",
        },
        ".MuiInputAdornment-root .MuiTypography-colorTextSecondary": {
          color: "#dde2e5",
        },
        ".MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
          borderColor: "#f44336 !important",
        },
        ".MuiPaper-outlined": {
          border: "0px !important",
        },
        ".heroHeading img": {
          marginBottom: "-7px",
          height: "35px",
          marginRight: "10px",
        },
        ".cardHeroHeading img": {
          marginBottom: "-27px",
          height: "50px",
          maxWidth: "41px",
          marginRight: "20px",
        },
        ".modalPop": {
          width: "100%",
          position: "absolute",
          outline: "none",
          backgroundColor: "#FFFFFF",
          bottom: "0",
          minHeight: "400px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.25)",
        },
        ".MuiFormLabel-root": {
          fontWeight: "normal !important",
          fontStyle: "normal !important",
          fontSize: "16px !important",
          lineHeight: "16px !important",
          letterSpacing: "0.08em",
          color: "#7A8296 !important",
        },
        ".fieldsetGroup .MuiFormLabel-root": {
          color: "#222E50 !important",
        },
        "legend.MuiFormLabel-root": {
          marginBottom: "21px",
        },
        ".titleCard": {
          border: "none !important",
          marginBottom: "24px",
          width: "100%",
          paddingBottom: "30px",
          maxHeight: "122px",
          position: "sticky",
          top: "0px",
          zIndex: 1001,
        },
        ".participantReportsCard": {
          border: "none !important",
          marginBottom: "8px",
          paddingBottom: "30px",
          position: "relative",
        },
        ".notTestedHeader": {
          position: "absolute",
          right: "10px",
          bottom: "20px",
        },
        ".bpDetails": {
          textAlign: "center",
          marginTop: "20px",
        },
        ".endLabel": {
          textAlign: "center",
        },
        ".MuiFormGroup-root": {
          marginBottom: "28px",
        },
        ".formGroupSmMargin": {
          marginBottom: "8px !important",
        },
        ".formGroupOveride": {
          marginBottom: "30px !important",
        },
        ".clearanceDocList": {
          display: "flex",
          boxSizing: "border-box",
          dropDownDirection: "TOP",
        },
        ".conditionSelected": {
          marginTop: "20px",
          textAlign: "center",
          marginLeft: "5px",
        },

        ".MuiFormControl-root": {
          flexGrow: 1,
        },
        ".MuiAccordionDetails-root .MuiFormControl-root": {
          display: "flex",
        },
        "form.PefaForm": {
          minWidth: "100%",
        },
        ".warningMsg img": {
          marginRight: "15px",
        },
        ".preTestRate": {
          color: "#A9A9A9",
          display: "flex",
          alignItems: "center",
        },
        ".stopTimerButton": {
          padding: "15px 115px!important",
          fontWeight: "bold",
        },
        ".recoveryModal": {
          display: "flex",
          padding: "20px 0",
          justifyContent: "center",
          alignItems: "center",
        },
        ".recoveryModal H2": {
          fontSize: "39px",
          fontWeight: "bold",
          color: "#222E50",
        },
        ".recoveryModal img": {
          marginRight: "15px",
        },
        ".recoveryTimer": {
          fontSize: "39px",
          fontWeight: "normal",
        },
        ".warningMsg": {
          "@media (min-width: 1190px)": {
            maxWidth: "600px",
          },
          backgroundColor: "#FFC400",
          position: "absolute",
          left: "0",
          maxWidth: "450px",
          textAlign: "left",
          marginLeft: "20px",
          height: "65px",
          display: "flex",
          alignItems: "center",
          padding: "0 30px",
          color: "#222E50",
        },
        ".inline-warningMsg img": {
          marginRight: "5px",
          marginTop: "3px",
          alignSelf: "start",
        },
        ".inline-warningMsg": {
          backgroundColor: "#FFC400",
          left: "0",
          textAlign: "left",
          display: "flex",
          alignItems: "center",
          padding: "0 5px",
          color: "#222E50",
        },
        ".end-pefa-warningMsg": {
          "@media (min-width: 1190px)": {
            maxWidth: "600px",
          },
          backgroundColor: "#FFC400",
          maxWidth: "450px",
          textAlign: "left",
          height: "40px",
          display: "flex",
          alignItems: "center",
          padding: "0 30px",
          color: "#222E50",
        },
        ".formControllerContainer": {
          marginBottom: "24px",
          display: "flex",
          flexGrow: 1,
          justifyContent: "space-between",
        },
        ".textFormRight .MuiFormControlLabel-label": {
          width: "150px",
          textAlign: "right",
          marginRight: "20px",
        },
        ".invalid-medical": {
          color: "#FF0001",
          marginLeft: "10px",
          display: "flex",
          alignSelf: "center",
          width: "735px",
          marginBottom: "10px",
        },
        ".invalid-intest": {
          color: "#FF0001",
          display: "flex",
          alignSelf: "center",
          width: "289px",
        },
        ".addedList": {
          fontSize: "14px",
        },
        ".addedList img": {
          height: "14px",
          marginRight: "5px",
        },
        ".prepare-link": {
          cursor: "pointer",
        },
        ".note-item": {
          marginTop: "10px",
        },
        ".invalid-feedback": {
          color: "#FF0001",
          marginLeft: "10px",
          display: "flex",
          alignSelf: "center",
        },
        ".invalidFeedback": {
          color: "#FF0001",
          marginLeft: "30px",
          width: "100%",
        },
        ".invalid-bpReading": {
          color: "#FF0001",
          marginLeft: "10px",
          alignSelf: "center",
        },
        ".invalid-condition": {
          color: "#FF0001",
          alignSelf: "center",
        },
        ".invalidFeedbackInForm": {
          color: "#FF0001",
          width: "100%",
          marginLeft: "0px",
          marginTop: "15px",
        },
        ".trialWrapper": {
          width: "100%",
          display: "flex",
          marginBottom: "15px",
        },
        ".longWrapper": {
          width: "100%",
          display: "flex",
          marginBottom: "15px",
        },
        ".fullWidth": {
          width: "100%",
        },
        ".fullWidthGrey": {
          width: "100%",
        },
        ".halfWidth": {
          width: "50%",
        },
        ".oneThird": {
          width: "30%",
        },
        ".oneThirdGrey": {
          width: "30%",
        },
        ".oneThirdGrey .MuiInputBase-input": {
          backgroundColor: "#dde2e5",
          color: "#a9a9a9 !important",
          borderRadius: "8px",
        },
        ".fullWidthGrey .MuiInputBase-input": {
          backgroundColor: "#dde2e5",
          color: "#a9a9a9 !important",
          borderRadius: "8px",
        },
        ".twoThirds": {
          width: "70%",
        },
        ".photoId": {
          color: "#495057 !important",
          marginLeft: "15px",
        },
        ".photoId .MuiFormLabel-root": {
          color: "#495057 !important",
        },
        ".fullWidth span": {
          margin: "20px 0 20px 0 !important",
          marginLeft: "0px !important",
          color: "#101b3c !important",
        },
        ".oneThird span": {
          margin: "20px 0 20px 0 !important",
          marginLeft: "0px !important",
          color: "#101b3c !important",
        },
        ".oneThirdGrey span": {
          margin: "20px 0 20px 0 !important",
          marginLeft: "0px !important",
          color: "#101b3c !important",
        },
        ".fullWidthGrey span": {
          margin: "20px 0 20px 0 !important",
          marginLeft: "0px !important",
          color: "#101b3c !important",
        },
        ".twoThirds span": {
          margin: "20px 0 20px 0 !important",
          marginLeft: "0px !important",
          color: "#101b3c !important",
        },
        ".mc-checkbox": {
          paddingTop: "0px !important",
          paddingBottom: "0px !important",
        },
        ".medicalConditionName": {
          color: "#1cbeaf !important",
          marginTop: "1px",
          marginRight: "15px",
        },
        ".reviewConditionName": {
          marginTop: "1px",
          marginRight: "15px",
          marginLeft: "5px",
        },
        ".review": {
          fontWeight: "bold",
          marginTop: "20px",
        },
        ".bold": {
          fontWeight: "bold !important",
        },
        ".separator": {
          borderTop: "1px solid #dde2e5",
          marginTop: "15px",
          marginBottom: "15px",
        },
        ".uploadLink": {
          paddingTop: "15px",
        },
        ".note-required": {
          marginLeft: "20px",
        },
        ".medicalConditionCancel": {
          color: "#1cbeaf !important",
          cursor: "pointer",
        },
        ".require-enableEndPEFA": {
          border: "solid red 1px",
        },
        ".prepare-mc": {
          marginTop: "20px",
        },
        ".editMC": {
          fontWeight: "bold",
          marginRight: "10px",
          marginTop: "1px",
          minWidth: "188px",
          cursor: "pointer",
          color: "#1CBEAF",
        },
        ".viewMC": {
          fontWeight: "bold",
          marginRight: "15px",
          marginTop: "1px",
          minWidth: "188px",
          cursor: "pointer",
          color: "#1CBEAF",
        },
        ".downloadMC": {
          border: "1px solid #1CBEAF",
          fontWeight: "bold",
          marginRight: "15px",
          marginTop: "1px",
          minWidth: "188px",
          cursor: "pointer",
          color: "#1CBEAF",
        },
        ".cancelPaperMC": {
          color: "red",
          marginRight: "15px",
          marginTop: "1px",
          minWidth: "188px",
          cursor: "pointer",
        },
        ".disable-viewMC": {
          fontWeight: "bold",
          marginRight: "15px",
          marginTop: "1px",
          minWidth: "188px",
          color: "gray",
        },
        ".medicalCondition": {
          color: "#495057 !important",
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: "normal",
          margin: "5px 0",
        },
        ".mcFileName": {
          color: "#101b3c !important",
          fontSize: "16px",
          flexGrow: 1,
          lineHeight: "24px",
          fontWeight: "normal",
        },
        ".medicalConditionFile": {
          margin: "20px 0",
          flexGrow: 1,
          display: "flex",
          alignItems: "center",
          width: "100%",
          border: "1px solid #dde2e5",
          borderRadius: "5px",
          padding: "14px 30px",
        },
        ".viewMedicalConditionFile": {
          fontSize: "16px",
          flexGrow: 1,
          display: "flex",
          width: "100%",
          color: "#1cbeaf",
        },
        ".medicalConditionFile img": {
          marginRight: "20px",
        },
        ".medicalConditionFile button": {
          color: "#1cbeaf",
          border: "1px solid #1cbeaf",
          padding: "10px 40px",
        },
        ".conditions": {
          marginTop: "40px",
        },
        ".condition": {
          marginBottom: "30px",
        },
        ".medicalNote": {
          color: "#a9a9a9 !important",
          fontSize: "16px",
          fontWeight: "normal",
        },
        ".sigButtons": {
          width: "100%",
          margin: "20px 0",
          textAlign: "right",
        },
        ".bp": {
          width: "124px",
          marginRight: "4px",
        },
        ".disabled-bp": {
          color: "rgba(0, 0, 0, 0.38) !important",
          width: "124px",
          marginRight: "4px",
        },
        ".textInputMedium": {
          width: "330px",
        },
        ".safeWeightTextInputFull": {
          "@media (max-width: 1200px)": {
            maxWidth: "80px",
          },
          width: "100%",
          margin: "10px 0",
        },
        ".textInputFull": {
          width: "100%",
          margin: "10px 0",
        },
        ".full-width": {
          width: "100%",
        },
        ".metronomeBpmStopped": {
          fontWeight: "normal !important",
          fontSize: "12px",
          color: "#101B3C !important",
        },
        ".metronomeBpmStarted": {
          fontWeight: "bold !important",
          fontSize: "12px",
          color: "#101B3C !important",
        },
        ".metronomeStop": {
          fontWeight: "bold !important",
          color: "#FFFFFF !important",
          fontSize: "21px",
          padding: "7px 30px",
          marginTop: "15px",
        },
        ".metronomeStart": {
          fontWeight: "bold !important",
          fontSize: "14px",
        },
        ".mhtextInputFull": {
          width: "100%",
          marginBottom: "20px !important",
        },
        ".textInputMarginLeft": {
          width: "100%",
          paddingLeft: "50px !important",
        },
        ".textInputLarge": {
          width: "470px",
          marginRight: "15px",
        },
        ".textInputTiny": {
          width: "60px",
          margin: "0 5px",
          backgroundColor: "#FAFAFA",
          border: "1px solid #F2F2F2",
        },
        ".textInputJamar": {
          width: "120px",
          margin: "0 5px",
          backgroundColor: "#FFFFFF",
          border: "1px solid #F2F2F2",
        },
        ".textInputJamarResult": {
          width: "160px",
          margin: "0 5px",
          backgroundColor: "#FAFAFA",
          border: "1px solid #F2F2F2",
        },
        ".MuiFormControlLabel-labelPlacementTop": {
          marginLeft: "0px !important",
          alignItems: "start !important",
        },
        ".MuiFormControlLabel-labelPlacementTop .MuiFormControlLabel-label": {
          marginLeft: "10px !important",
        },
        ".MuiAccordion-rounded": {
          borderRadius: "8px !important",
          marginBottom: "8px !important",
        },
        ".MuiAccordionSummary-content.Mui-expanded": {
          margin: "12px 0px !important",
        },
        ".MuiAccordion-root:before": {
          backgroundColor: "initial !important",
        },
        ".accordianLabelPadding": {
          paddingLeft: "20px !important",
          lineHeight: "46px",
        },
        ".blueText": {
          color: "#101B3C !important",
          letterSpacing: "normal",
        },
        ".blueText.height-adjusted": {
          lineHeight: "48px !important",
          marginBottom: "0px",
        },
        ".blueText.height-adjusted.textbox": {
          marginTop: "5px",
        },
        ".checkboxIcon": {
          width: "28px",
          height: "28px",
          backgroundImage: `url(${CheckboxIcon})`,
        },
        ".editedTag": {
          position: "absolute",
          right: "60px",
          top: "16px",
          backgroundColor: "rgba(28, 190, 175, 0.21)",
          padding: "8px 14px",
          borderRadius: "8px",
        },
        ".checkboxIconChecked": {
          width: "28px",
          height: "28px",
          backgroundImage: `url(${CheckboxIconChecked})`,
        },
        ".MuiAccordionSummary-root.Mui-expanded": {
          borderBottom: "1px solid #E5E7EC",
        },
        ".MuiInputBase-multiline": {
          backgroundColor: "#FAFAFA",
          border: "1px solid #F2F2F2",
          fontWeight: "400 !important",
          minHeight: "140px",
        },
        ".clockTime": {
          padding: "16px 13px",
          marginLeft: "35px",
          backgroundColor: "rgba(28, 190, 175, 0.15)",
          minHeight: "140px",
          color: "#1CBEAF",
          fontSize: "16px",
          fontWeight: "normal",
          borderRadius: "32px",
        },
        ".MuiAccordionDetails-root": {
          padding: "28px 22px 16px !important",
          flexWrap: "wrap",
        },
        ".errorOutlinedBox": {
          border: "1px solid red !important",
        },
        ".innerOutlinedBox": {
          border: "none !important",
        },
        ".mhinnerOutlinedBox": {
          border: "none !important",
          padding: "30px 50px",
        },
        ".toleranceOutlinedBox": {
          border: "none !important",
          padding: "30px 50px 5px 50px",
        },
        ".weightAchieved img": {
          marginRight: "30px",
        },
        ".toleranceHeading img": {
          marginRight: "30px",
        },
        ".weightAchieved": {
          marginBottom: "30px",
        },
        ".toleranceHeading": {
          marginBottom: "20px",
        },
        ".mhFormRight": {
          marginLeft: "50px",
        },
        ".mhFormRight .MuiFormControlLabel-label": {
          marginRight: "10px !important",
          color: "#101B3C !important",
        },
        ".mhFormRight .MuiOutlinedInput-root": {
          marginRight: "20px !important",
        },
        ".MuiSelect-outlined.MuiSelect-outlined": {
          fontSize: "16px !important",
        },
        ".innerOutlinedBox .Mui-expanded .MuiFormControlLabel-root": {
          color: "#1CBEAF !important",
        },
        ".greyBox": {
          padding: "13px 26px!important",
          backgroundColor: "#F2F3F5",
          border: "1px solid #E5E7EC",
          color: "#7A8296",
          borderRadius: "4px",
          marginBottom: "28px",
        },
        ".mobilityTestSecondLine": {
          display: "flex",
          marginTop: "25px",
          marginBottom: "0px",
        },
        ".firstCheckbox": {
          marginLeft: "0px !important",
          color: "#101B3C",
          fontSize: "12px !important",
        },
        ".firstCheckbox .MuiTypography-body1": {
          color: "#101B3C",
          fontSize: "12px !important",
        },
        ".formLabelSmall .MuiTypography-body1": {
          color: "#101B3C",
          fontSize: "12px !important",
        },
        ".overflowText": {
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          maxWidth: "400px",
        },
        ".textLabelSmall": {
          color: "#101B3C !important",
          marginBottom: "5px !important",
          fontSize: "12px !important",
        },
        ".wbRatio": {
          color: "#212429 !important",
          alignSelf: "center",
          marginLeft: "25px !important",
          marginBottom: "0px !important",
          fontSize: "14px !important",
        },
        ".fieldsetGroup": {
          marginBottom: "40px !important",
          paddingBottom: "12px !important",
        },
        ".labelLeftOveride": {
          width: "220px !important",
          display: "flex",
          alignItems: "center",
        },
        ".labelLeftOverideSpacer": {
          paddingLeft: "220px",
        },
        ".labelLeftSmall": {
          width: "150px !important",
          display: "flex",
          alignItems: "center",
        },
        ".vasInput": {
          width: "100px !important",
          marginRight: "20px",
        },
        ".statusWrapper": {
          display: "flex",
          flexFlow: "wrap",
          maxWidth: "650px",
        },
        ".greyPill": {
          display: "inline",
          alignSelf: "center",
          padding: "12px !important",
          backgroundColor: "rgba(242, 243, 245, 0.46)",
          borderRadius: "8px",
          marginBottom: "0px !important",
          color: "#495057 !important",
        },
        ".fieldsetStrength": {
          marginBottom: "0px !important",
          paddingBottom: "12px !important",
        },
        ".fieldsetBalance": {
          marginBottom: "0px !important",
          paddingBottom: "0px !important",
        },
        ".commentsField": {
          margin: "0 25px 0 0 !important",
          paddingBottom: "0px !important",
          flexGrow: 1,
          width: "100%",
        },
        ".Mui-expanded .MuiTypography-subtitle1": {
          color: "#101B3C",
          fontWeight: "bold",
          fontSize: "12px",
        },
        ".fieldsetMainGroup": {
          marginBottom: "40px !important",
          paddingBottom: "12px !important",
        },
        ".fieldsetMainGroup::after": {
          display: "block",
          marginTop: "30px",
          content: "no-close-quote",
          width: "66px",
          height: "2px",
          backgroundColor: "#E5E7EC",
        },
        ".fieldsetMainGroupLast": {
          marginBottom: "10px !important",
          paddingBottom: "12px !important",
        },
        ".fieldsetGroup::after": {
          display: "block",
          content: "no-close-quote",
          width: "66px",
          height: "2px",
          backgroundColor: "#E5E7EC",
        },
        ".hrWrapper": {
          flexGrow: 1,
          maxWidth: "90%",
          minWidth: "90%",
          margin: "5px auto",
        },
        ".hrWrapper div": {
          display: "flex",
        },
        ".participantReports": {
          marginLeft: "60px",
          marginTop: "20px",
        },
        ".timerContainer": {
          padding: "6px",
          borderRadius: "4px",
          backgroundColor: "#FFFFFF",
          width: "100%",
        },
        ".redBackground": {
          backgroundColor: "#FF0001",
        },
        ".metronomeContainer": {
          padding: "6px",
          borderRadius: "4px",
          marginBottom: "8px",
          backgroundColor: "#FFFFFF",
          width: "100%",
        },
        ".timerBtn": {
          padding: "100px 0",
          textAlign: "center",
          borderRadius: "4px",
          fontWeight: "bold",
          color: "#1CBEAF",
          display: "block",
          width: "100%",
          backgroundColor: "rgba(242, 243, 245, 0.46)",
        },
        ".metronomeBtn": {
          padding: "50px 0",
          textAlign: "center",
          borderRadius: "4px",
          fontWeight: "bold",
          color: "#1CBEAF",
          display: "block",
          width: "100%",
          backgroundColor: "rgba(242, 243, 245, 0.46)",
        },
        ".timerBtn div": {
          width: "120px",
          margin: "0px auto",
        },
        ".remoteStartResumeBtn": {
          marginLeft: "10px",
        },
        ".LR .MuiSwitch-track:before": {
          content: "'L'",
          position: "absolute",
          left: "10px",
          top: "5px",
          color: "#FFFFFF",
        },
        ".LR .MuiSwitch-track": {
          backgroundColor: "#1CBEAF !important",
        },
        ".LR": {
          alignItems: "center",
        },
        ".LR .MuiSwitch-track:after": {
          content: "'R'",
          position: "absolute",
          right: "12px",
          top: "5px",
          color: "#FFFFFF",
        },
        ".YN .MuiSwitch-track:before": {
          content: "'Y'",
          position: "absolute",
          left: "10px",
          top: "5px",
          color: "#FFFFFF",
        },
        ".YN .MuiSwitch-track": {
          backgroundColor: "#1CBEAF !important",
        },
        ".YN": {
          alignItems: "center",
        },
        ".YN .MuiSwitch-track:after": {
          content: "'N'",
          position: "absolute",
          right: "12px",
          top: "5px",
          color: "#FFFFFF",
        },
        ".bpmInput": {
          maxWidth: "100px",
          marginRight: "12px !important",
        },
        ".mhInput": {
          maxWidth: "100px",
          marginRight: "0px !important",
        },
        ".mhLongInput": {
          width: "200px",
          marginRight: "0px !important",
        },
        ".mhLongSelect": {
          width: "300px",
          marginRight: "0px !important",
          marginBottom: "20px",
        },
        ".hrCurrent .hrCountText": {
          color: "#1CBEAF !important",
        },
        ".hrCurrent .MuiOutlinedInput-root": {
          border: "1px solid #1CBEAF !important",
        },
        ".hrCountText": {
          minWidth: "60px",
          fontSize: "16px",
          alignSelf: "center",
          color: "#495057",
        },
        ".trialText": {
          minWidth: "50px",
          fontSize: "16px",
          fontWeight: "normal",
          alignSelf: "center",
          color: "#101B3C",
          marginTop: "10px",
        },
        ".daterK": {
          fontSize: "12px !important",
          fontWeight: "normal !important",
          color: "rgba(0, 0, 0, 0.81) !important",
          marginTop: "10px !important",
          marginBottom: "10px !important",
        },
        ".fitnessRating": {
          color: "#1CBEAF",
          display: "block",
          fontSize: "39px",
          textAlign: "center",
          fontWeight: "bold",
        },
        ".Hdr": {
          color: "#101b3c",
          display: "block",
          fontSize: "16px",
          fontWeight: "bold",
          marginBottom: "20px",
        },
        ".lister": {
          color: "#101b3c",
          display: "block",
          fontSize: "16px",
          marginBottom: "20px",
        },
        ".lister span": {
          color: "#101b3c",
          display: "block",
          float: "left",
          height: "40px",
          fontSize: "16px",
          fontWeight: "bold",
          marginRight: "20px",
        },
        ".fitnessResult": {
          color: "#1CBEAF",
          display: "block",
          fontSize: "15px",
          textAlign: "center",
          fontWeight: "bold",
        },
        ".fitnessRatingText": {
          color: "#222E50",
          display: "block",
          fontSize: "12px !important",
          marginTop: "-5px",
        },
        ".fitnessRatingCalculation": {
          marginRight: "25px",
        },
        ".singleTrial": {
          borderBottom: "1px solid #DBDBDB",
          flexGrow: 1,
          padding: "20px 0",
          margin: "10px 0",
        },
        ".restRecorded": {
          backgroundColor: "rgba(28, 190, 175, 0.15)",
          color: "#1CBEAF",
          marginTop: "15px !important",
          borderRadius: "6px",
          padding: "20px",
          textAlign: "center",
          flexGrow: 1,
        },
        ".MuiContainer-maxWidthLg": {},
        ".MuiGrid-grid-xs-12": {
          flexBasis: "auto !important",
        },
        ".MuiBackdrop-root": {
          backgroundColor: "rgba(255,255,255,0.3)",
        },
        ".loadingIndicatorText": {
          marginTop: "35px",
          textAlign: "center",
          fontWeight: "bold",
          color: "#1cbeaf",
          fontSize: "16px",
        },
        ".visibilityHidden": {
          display: "none",
          visibility: "hidden",
        },
      },
    },
  },
});
export default theme;

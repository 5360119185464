import React, { useEffect, useState } from "react";
import { ProfileCard, FormNavigator, Stepper } from "../../../components/";
import { Success } from "../../../assets/images/";
import { Root } from "../../../stores";
import informedConsentSchema from "./InformedConsentValidation";
import { dataStorePageKeys } from "../../../stores/dataStore";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { differenceInYears, format } from "date-fns";
import { inject, observer } from "mobx-react";
import SignatureCanvas from "react-signature-canvas";
import { Grid, Typography, Card, FormControlLabel, Container, OutlinedInput, Button } from "@material-ui/core";
import { useAuth } from "oidc-react";
import { useHistory } from "react-router";
import { DASHBOARD, MEDICALDETAILS, REFERRALDETAILS } from "../../../routes/paths";
import axios from "axios";
import { tokenInterceptor } from "../../../services/auth/tokenInterceptor";
import { getAppInsights } from "../../../components/telemetry";
import withAppInsights from "../AppInsights";

interface InformedConsentProps {
  rootStore?: Root;
}

const InformedConsent = inject("rootStore")(
  observer((props: InformedConsentProps) => {
    let pefaAssessment: any;
    let prevData: any = null;
    let referralDetails: any = null;
    let participantAgeYears = 0;
    const history = useHistory();
    const auth = useAuth();
    const appInsights = getAppInsights();
    tokenInterceptor(axios, null, auth);

    let participantSignature: SignatureCanvas | null = null;
    let witnessSignature: SignatureCanvas | null = null;
    let personResponsibleSignature: SignatureCanvas | null = null;

    if (props.rootStore?.pefaStore.selectedPefa) {
      pefaAssessment = props.rootStore?.pefaStore.selectedPefa[0];
      if (props.rootStore?.dataStore.checkinData.hasOwnProperty(pefaAssessment.id)) {
        prevData = props.rootStore?.dataStore.getCheckInData(pefaAssessment.id, dataStorePageKeys.INFORMED_CONSENT);
        referralDetails = props.rootStore?.dataStore.getCheckInData(pefaAssessment.id, dataStorePageKeys.REFERRAL_DETAILS);
        participantAgeYears = differenceInYears(new Date(), new Date(referralDetails?.dob ?? pefaAssessment.pefArequest.worker.dateOfBirth));
      } else {
        participantAgeYears = differenceInYears(new Date(), new Date(pefaAssessment.pefArequest.worker.dateOfBirth));
      }
    } else {
      history.push(DASHBOARD);
      return <></>;
    }

    const submitCheckinAndGoHome =
      props.rootStore?.pefaStore.isCheckInIncludingInformedConsent(pefaAssessment.id) &&
      !props.rootStore?.pefaStore.isAssignedAssessor(pefaAssessment.id, props.rootStore?.userStore.getUserAssessorNumber);
    const { register, handleSubmit, errors } = useForm({
      resolver: yupResolver(informedConsentSchema),
    });

    const [state, setState] = useState({
      isLoading: true,
      participantName: pefaAssessment.pefArequest.worker.firstName + " " + pefaAssessment.pefArequest.worker.lastName,
      witnessName: prevData ? prevData.witnessName : "",
      personResponsibleName: prevData ? prevData.personResponsibleName : "",
      address: prevData ? prevData.address : "",
      phoneNumber: prevData ? prevData.phoneNumber : "",

      dateToday: format(new Date(), "dd-LLL-y"),

      participantSignature: prevData ? prevData.participantSignature : "",
      witnessSignature: prevData ? prevData.witnessSignature : "",
      personResponsibleSignature: prevData ? prevData.personResponsibleSignature : "",
    });

    useEffect(() => {
      if (state.isLoading) {
        //Populate the signature pads if there is any existing signatures.
        participantSignature?.fromDataURL(state.participantSignature);
        witnessSignature?.fromDataURL(state.witnessSignature);
        personResponsibleSignature?.fromDataURL(state.personResponsibleSignature);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [participantSignature]);

    useEffect(() => {
      if (!state.isLoading) {
        const timeout = setTimeout(() => {
          props.rootStore?.dataStore.savePartialCheckInData(state, pefaAssessment.id, dataStorePageKeys.INFORMED_CONSENT);
        }, 500);

        return () => clearTimeout(timeout);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      state.participantName,
      state.participantSignature,
      state.witnessName,
      state.witnessSignature,
      state.personResponsibleName,
      state.address,
      state.phoneNumber,
      state.personResponsibleSignature,
    ]);

    const handleValueChange = (event: any) => {
      event.persist();
      setState((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
      }));
    };

    const clearParticipantSignature = () => {
      participantSignature?.clear();
      setState((prevState) => ({
        ...prevState,
        participantSignature: "",
      }));
    };

    const clearWitnessSignature = () => {
      witnessSignature?.clear();
      setState((prevState) => ({
        ...prevState,
        witnessSignature: "",
      }));
    };

    const clearResponsiblePersonSignature = () => {
      personResponsibleSignature?.clear();
      setState((prevState) => ({
        ...prevState,
        personResponsibleSignature: "",
      }));
    };

    const handleParticipantSignatureChanged = () => {
      setState((prevState) => ({
        ...prevState,
        participantSignature: participantSignature?.getCanvas().toDataURL("image/png"),
      }));
    };

    const handleWitnessSignatureChanged = () => {
      setState((prevState) => ({
        ...prevState,
        witnessSignature: witnessSignature?.getCanvas().toDataURL("image/png"),
      }));
    };

    const handleResponsiblePersonSignatureChanged = () => {
      setState((prevState) => ({
        ...prevState,
        personResponsibleSignature: personResponsibleSignature?.getCanvas().toDataURL("image/png"),
      }));
    };

    useEffect(() => {
      //Replicate the onUnmount event handler from class components.
      return () => {
        appInsights.trackEvent({ name: "intermediateSubmission", properties: { bookingId: pefaAssessment.id, dataKey: dataStorePageKeys.INFORMED_CONSENT, data: state } });
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submitForm = (data: any, forwardTarget: any) => {
      props.rootStore?.dataStore.saveCheckInData(data, pefaAssessment.id, dataStorePageKeys.INFORMED_CONSENT);
      props.rootStore?.dataStore.saveData(data, pefaAssessment.id, dataStorePageKeys.INFORMED_CONSENT);
      props.rootStore?.pefaStore.submitConsent(data, pefaAssessment.id).then(() => {
        props.rootStore?.dataStore.saveCheckInData(data, pefaAssessment.id, dataStorePageKeys.INFORMED_CONSENT);
        const pefaData = { ...props.rootStore?.dataStore.pefaData[pefaAssessment.id] };
        // take snapshot of assessment and store against booking
        props.rootStore?.pefaStore.nextSubmit(pefaData, pefaAssessment.id);
      });

      //Regardless of whether the user is an admin or an assessor, set this local flag here.
      props.rootStore?.dataStore.setLocalCheckInCompleteFlag(pefaAssessment.id);

      if (submitCheckinAndGoHome) {
        history.push(DASHBOARD);
      } else {
        history.push(forwardTarget);
      }
    };

    return (
      <div className="FormStyler">
        <div className="MedicalConsent">
          <Grid item container direction="column" xs={12}>
            <Stepper targetStep={"Assessment Details"} />
            <Container maxWidth={"lg"}>
              <Grid item container xs={12} direction="row">
                <Grid item container sm={12} md={9}>
                  <form>
                    <Card className="consentCard" variant="outlined">
                      <Typography variant="h2" className="heroHeading">
                        <img src={Success} alt="Success" /> Informed Consent
                      </Typography>
                      <Typography variant="body1">
                        You are invited to participate in a Pre- / Periodic Employment Functional Assessment (PEFA) as part of the required processes of{" "}
                        {pefaAssessment.pefArequest.company.clientConsentTxtName}.
                      </Typography>
                      <Typography variant="body2">
                        This PEFA is a series of physical tests, which may include tests of strength, flexibility, fitness, endurance, coordination, static posturing, repetitive
                        movements, lifting and carrying, which will help determine your safe ability to do the required tasks.
                        <strong>All of the activities are voluntary, and you may refuse any activity if you feel you are not capable of safely performing it.</strong> All
                        activities will be explained to you before you are invited to perform them.
                      </Typography>
                      <Typography variant="body2">
                        There is a risk that you may experience an injury during the PEFA, but this risk is small and you will be able to control the activity by stopping if you
                        feel uncomfortable or unwell or for any reason. <strong>You should immediately stop the activity if you feel unsafe.</strong> We will take all reasonable
                        precautions to keep you safe during the PEFA and if we see you doing anything that we think is unsafe for you, we will stop the activity. You may experience
                        some post-assessment muscle soreness typically associated with exercise.
                      </Typography>
                      <Typography variant="body2">
                        We will only collect information that is required for identification purposes or related to your performance in the assessment. Your information will be
                        stored in a secure location. Your information will only be disclosed to those involved in the scheduling, conducting and reporting of this assessment. Your
                        report will be sent to {pefaAssessment.pefArequest.company.clientConsentTxtName} and the assessing Medical Officer. Your de-identified information may be
                        used for research purposes as per the Australian Privacy Principles.
                      </Typography>
                      <Typography variant="body2">
                        After reading these instructions, please ask any questions you wish. When your questions are satisfied, please sign and date the statement below.
                      </Typography>
                    </Card>

                    <Card className="consentCard" variant="outlined">
                      <Typography variant="h2" className="heroHeading">
                        Participant Confirmation
                      </Typography>
                      <Typography variant="body1">
                        I understand the above instructions and agree to participate in this PEFA and give consent to release the required information from the PEFA to{" "}
                        {pefaAssessment.pefArequest.company.clientConsentTxtName} and the assessing Medical Officer.
                      </Typography>
                      <div className="longWrapper">
                        <FormControlLabel
                          control={<OutlinedInput color="primary" name="participantName" autoComplete="off" className="fullWidth" />}
                          label="Participant Name"
                          labelPlacement="top"
                          value={state.participantName}
                          inputRef={register}
                          onChange={handleValueChange}
                          className="twoThirds"
                        />
                        <FormControlLabel
                          control={<OutlinedInput color="primary" name="participantDateToday" autoComplete="off" className="fullWidth" disabled={true} value={state.dateToday} />}
                          label="Date"
                          labelPlacement="top"
                          inputRef={register}
                          className="oneThirdGrey"
                        />
                        <input type="hidden" name="participantDateToday" value={state.dateToday} ref={register} />
                      </div>
                      <div className="invalidFeedbackInForm">{errors.participantName?.message}</div>
                      <FormControlLabel
                        label="Signature Block"
                        control={
                          <div className="sigBlock">
                            <SignatureCanvas
                              penColor="black"
                              canvasProps={{
                                width: 500,
                                height: 200,
                                className: "sigCanvas",
                              }}
                              ref={(ref) => {
                                participantSignature = ref;
                              }}
                              onEnd={handleParticipantSignatureChanged}
                            />
                          </div>
                        }
                        labelPlacement="top"
                        className="fullWidth"
                      />
                      <div className="invalidFeedbackInForm">{errors.participantSignature?.message}</div>
                      <input type="hidden" name="participantSignature" ref={register} value={state.participantSignature} />
                      <div className="sigButtons">
                        <Button variant="outlined" className="outlinedButton" color="primary" onClick={clearParticipantSignature}>
                          Clear Signature
                        </Button>
                      </div>
                    </Card>
                    <Card className="consentCard" variant="outlined">
                      <Typography variant="h2" className="heroHeading">
                        Witness Confirmation
                      </Typography>
                      <div className="longWrapper">
                        <FormControlLabel
                          control={<OutlinedInput color="primary" name="witnessName" autoComplete="off" className="fullWidth" defaultValue={state.witnessName} />}
                          label="Witness Name"
                          labelPlacement="top"
                          inputRef={register}
                          onChange={handleValueChange}
                          className="twoThirds"
                        />
                        <FormControlLabel
                          control={<OutlinedInput color="primary" name="witnessDateToday" autoComplete="off" className="fullWidth" disabled={true} value={state.dateToday} />}
                          label="Date"
                          labelPlacement="top"
                          inputRef={register}
                          className="oneThirdGrey"
                        />
                        <input type="hidden" name="witnessDateToday" value={state.dateToday} ref={register} />
                      </div>
                      <div className="invalidFeedbackInForm">{errors.witnessName?.message}</div>
                      <FormControlLabel
                        label="Signature Block"
                        control={
                          <div className="sigBlock">
                            <SignatureCanvas
                              penColor="black"
                              canvasProps={{
                                width: 500,
                                height: 200,
                                className: "sigCanvas",
                              }}
                              ref={(ref) => {
                                witnessSignature = ref;
                              }}
                              onEnd={handleWitnessSignatureChanged}
                            />
                          </div>
                        }
                        labelPlacement="top"
                        className="fullWidth"
                      />
                      <input type="hidden" name="witnessSignature" ref={register} value={state.witnessSignature} />
                      <div className="invalidFeedbackInForm">{errors.witnessSignature?.message}</div>
                      <div className="sigButtons">
                        <Button variant="outlined" className="outlinedButton" color="primary" onClick={clearWitnessSignature}>
                          Clear Signature
                        </Button>
                      </div>
                    </Card>
                    {/* 
                  Other Party Consent - if user age < 18 
                  */}
                    {participantAgeYears < 18 ? (
                      <Card className="consentCard" variant="outlined">
                        <Typography variant="h2" className="heroHeading">
                          Person Responsible Confirmation
                        </Typography>
                        <Typography variant="body1">If Participant is less than 18 years of age, or unable to sign on, a ‘Person Responsible’ must sign.</Typography>
                        <div className="longWrapper">
                          <FormControlLabel
                            control={
                              <OutlinedInput color="primary" name="personResponsibleName" autoComplete="off" className="fullWidth" defaultValue={state.personResponsibleName} />
                            }
                            label="Person Responsible Name"
                            labelPlacement="top"
                            inputRef={register}
                            onChange={handleValueChange}
                            className="twoThirds"
                          />
                          <FormControlLabel
                            control={
                              <OutlinedInput color="primary" name="personResponsibleDate" autoComplete="off" className="fullWidth" disabled={true} defaultValue={state.dateToday} />
                            }
                            label="Date"
                            labelPlacement="top"
                            inputRef={register}
                            className="oneThirdGrey"
                          />
                          <input type="hidden" name="personResponsibleDate" value={state.dateToday} ref={register} />
                          <input type="hidden" name="age" ref={register} value={participantAgeYears} />
                        </div>
                        <div className="invalidFeedbackInForm">{errors.personResponsibleName?.message}</div>

                        <FormControlLabel
                          control={<OutlinedInput color="primary" name="address" autoComplete="off" className="fullWidth" defaultValue={state.address} />}
                          label="Address"
                          labelPlacement="top"
                          inputRef={register}
                          onChange={handleValueChange}
                          className="fullWidth"
                        />
                        <div className="invalidFeedbackInForm">{errors.address?.message}</div>
                        <FormControlLabel
                          control={<OutlinedInput color="primary" name="phoneNumber" autoComplete="off" className="fullWidth" defaultValue={state.phoneNumber} />}
                          label="Phone Number"
                          labelPlacement="top"
                          inputRef={register}
                          onChange={handleValueChange}
                          className="fullWidth"
                        />
                        <div className="invalidFeedbackInForm">{errors.phoneNumber?.message}</div>
                        <FormControlLabel
                          label="Signature Block"
                          control={
                            <div className="sigBlock">
                              <SignatureCanvas
                                penColor="black"
                                canvasProps={{
                                  width: 500,
                                  height: 200,
                                  className: "sigCanvas",
                                }}
                                ref={(ref) => {
                                  personResponsibleSignature = ref;
                                }}
                                onEnd={handleResponsiblePersonSignatureChanged}
                              />
                            </div>
                          }
                          labelPlacement="top"
                          className="fullWidth"
                        />
                        <div className="invalidFeedbackInForm">{errors.personResponsibleSignature?.message}</div>
                        <input type="hidden" name="personResponsibleSignature" ref={register} value={state.personResponsibleSignature} />
                        <div className="sigButtons">
                          <Button variant="outlined" className="outlinedButton" color="primary" onClick={clearResponsiblePersonSignature}>
                            Clear Signature
                          </Button>
                        </div>
                      </Card>
                    ) : (
                      <></>
                    )}
                  </form>
                </Grid>
                <Grid item container md={3} sm={12} className="RightCol">
                  <ProfileCard
                    firstName={pefaAssessment.pefArequest.worker.firstName}
                    lastName={pefaAssessment.pefArequest.worker.lastName}
                    participantAgeYears={participantAgeYears}
                  />
                </Grid>
              </Grid>
            </Container>
            <FormNavigator
              backTarget={REFERRALDETAILS}
              forwardTarget={MEDICALDETAILS}
              handleSubmit={handleSubmit(submitForm)}
              forwardText={submitCheckinAndGoHome ? "Check-In" : "Next"}
              homeText={submitCheckinAndGoHome ? "Cancel Check-In" : "End PEFA"}
              pushProgressOnEnd={false}
              selectedKey=""
            />
          </Grid>
        </div>
      </div>
    );
  })
);

export default withAppInsights(InformedConsent);

import React, { useEffect, useState } from "react";
import { ProfileCard, FormNavigator, Stepper, NotesModal } from "../../../components/";
import { Success, ClearanceBlueIcon } from "../../../assets/images/";
import { Root } from "../../../stores";
import assessmentDetailsSchema from "./validation";
import { dataStorePageKeys } from "../../../stores/dataStore";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { differenceInYears } from "date-fns";
import { inject, observer } from "mobx-react";
import { Grid, Typography, FormControl, Card, FormGroup, FormControlLabel, Checkbox, Container, FormHelperText } from "@material-ui/core";
import { BASELINEMEASUREMENTS, DASHBOARD, MEDICALDETAILS } from "../../../routes/paths";
import { useHistory } from "react-router-dom";
import { getAppInsights } from "../../../components/telemetry";
import withAppInsights from "../AppInsights";

interface AssessmentDetailsProps {
  rootStore?: Root;
}

const AssessmentDetails = inject("rootStore")(
  observer((props: AssessmentDetailsProps) => {
    let pefaAssessment: any;
    let prevData: any = null;
    let referralDetails: any = null;
    let endAssessment: any = null;
    let participantAgeYears = 0;
    let participantGender = "";
    const history = useHistory();
    const appInsights = getAppInsights();

    if (props.rootStore?.pefaStore.selectedPefa) {
      pefaAssessment = props.rootStore?.pefaStore.selectedPefa[0];
      if (props.rootStore?.dataStore.pefaData.hasOwnProperty(pefaAssessment.id)) {
        prevData = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.ASSESSMENT_DATA);
        referralDetails = props.rootStore?.dataStore.getCheckInData(pefaAssessment.id, dataStorePageKeys.REFERRAL_DETAILS);
        endAssessment = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.END_ASSESSMENT);
        participantAgeYears = differenceInYears(new Date(), new Date(referralDetails?.dob ?? pefaAssessment.pefArequest.worker.dateOfBirth));
      } else {
        participantAgeYears = differenceInYears(new Date(), new Date(pefaAssessment.pefArequest.worker.dateOfBirth));
      }
      participantGender = referralDetails?.biologicalSex ?? pefaAssessment.pefArequest.worker.gender?.toUpperCase();
    } else {
      history.push(DASHBOARD);
      return <></>;
    }

    const { register, handleSubmit, errors } = useForm({
      resolver: yupResolver(assessmentDetailsSchema),
    });

    const [state, setState] = useState({
      isLoading: true,
      assessmentType: prevData ? prevData.assessmentType : true,
      photoId: prevData ? prevData.photoId : true,
      informedConsent: prevData ? prevData.informedConsent : true,
      medicalHistory: prevData ? prevData.medicalHistory : true,
      jobTitleSite: prevData ? prevData.jobTitleSite : true,
      company: prevData ? prevData.company : true,
      confirmedConsent: prevData ? prevData.confirmedConsent : true,
      noConsent: prevData ? prevData.noConsent : false,
    });

    useEffect(() => {
      const timeout = setTimeout(() => {
        if (!state.isLoading) {
          props.rootStore?.dataStore.savePartialData(state, pefaAssessment.id, dataStorePageKeys.ASSESSMENT_DATA);
        }
      }, 500);

      return () => clearTimeout(timeout);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.assessmentType, state.photoId, state.informedConsent, state.medicalHistory, state.jobTitleSite, state.company, state.confirmedConsent, state.noConsent]);

    function formatAMPM(dater: any) {
      let hours = dater.getHours();
      let minutes = dater.getMinutes();
      const ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      const strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    }

    useEffect(() => {
      //Replicate the onUnmount event handler from class components.
      return () => {
        appInsights.trackEvent({ name: "intermediateSubmission", properties: { bookingId: pefaAssessment.id, dataKey: dataStorePageKeys.ASSESSMENT_DATA, data: state } });
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submitForm = (data: any, forwardTarget: any) => {
      props.rootStore?.dataStore.saveData(data, pefaAssessment.id, dataStorePageKeys.ASSESSMENT_DATA);
      history.push(forwardTarget);
    };

    return (
      <div className="FormStyler">
        <div className="AssessmentDetails">
          <Grid item container direction="column" xs={12}>
            <Stepper targetStep={"Assessment Details"} />
            <Container maxWidth={"lg"}>
              <Grid item container xs={12} direction="row">
                <Grid item container sm={12} md={9}>
                  <form>
                    <Card className="uCard" variant="outlined">
                      <a href="/docs/PEFA Instructions_Golden Safety Rules.pdf" className="helpIcon" target="_blank">
                        Help
                      </a>
                      <FormHelperText error>{errors.termsCheck ? errors.termsCheck.message : " "}</FormHelperText>
                      <Typography variant="h2" className="heroHeading">
                        <img src={Success} alt="Success" /> Assessment Details
                        <span className="clockTime">{formatAMPM(new Date())}</span>
                      </Typography>
                      <Typography className="introPefaText" variant="body1">
                        <span>{pefaAssessment.pefArequest.company.name.split("-")[0]}</span> has requested{" "}
                        <span>
                          {pefaAssessment.pefArequest.worker.firstName} {pefaAssessment.pefArequest.worker.lastName}
                        </span>
                        , a <span>{participantAgeYears}</span> year old <span>{participantGender}</span> be assessed for the role of{" "}
                        <span>{pefaAssessment.pefArequest.pefaJob.name.split("_")[0]}</span>.
                      </Typography>
                      <FormControl component="fieldset">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={<span className="checkboxIcon"></span>}
                                checkedIcon={<span className="checkboxIconChecked"></span>}
                                color="primary"
                                checked={state.assessmentType}
                                name="assessmentType"
                                onChange={(event) => {
                                  event.persist();
                                  setState((prevState) => ({
                                    ...prevState,
                                    [event.target.name]: event.target.checked,
                                  }));
                                }}
                              />
                            }
                            inputRef={register}
                            label="Assessment Type"
                          />
                          <div className="invalid-feedback">{errors.assessmentType?.message}</div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={<span className="checkboxIcon"></span>}
                                checkedIcon={<span className="checkboxIconChecked"></span>}
                                color="primary"
                                checked={state.photoId}
                                name="photoId"
                                onChange={(event) => {
                                  event.persist();
                                  setState((prevState) => ({
                                    ...prevState,
                                    [event.target.name]: event.target.checked,
                                  }));
                                }}
                              />
                            }
                            inputRef={register}
                            label="Confirm Photo ID"
                          />
                          <div className="invalid-feedback">{errors.photoId?.message}</div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={<span className="checkboxIcon"></span>}
                                checkedIcon={<span className="checkboxIconChecked"></span>}
                                color="primary"
                                checked={state.informedConsent}
                                name="informedConsent"
                                onChange={(event) => {
                                  event.persist();
                                  setState((prevState) => ({
                                    ...prevState,
                                    [event.target.name]: event.target.checked,
                                  }));
                                }}
                              />
                            }
                            inputRef={register}
                            label="Participant has signed informed consent"
                          />
                          <div className="invalid-feedback">{errors.informedConsent?.message}</div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                icon={<span className="checkboxIcon"></span>}
                                checkedIcon={<span className="checkboxIconChecked"></span>}
                                checked={state.medicalHistory}
                                name="medicalHistory"
                                onChange={(event) => {
                                  event.persist();
                                  setState((prevState) => ({
                                    ...prevState,
                                    [event.target.name]: event.target.checked,
                                  }));
                                }}
                              />
                            }
                            inputRef={register}
                            label="Medical history confirmed"
                          />
                          <div className="invalid-feedback">{errors.medicalHistory?.message}</div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={<span className="checkboxIcon"></span>}
                                checkedIcon={<span className="checkboxIconChecked"></span>}
                                color="primary"
                                checked={state.jobTitleSite}
                                name="jobTitleSite"
                                onChange={(event) => {
                                  event.persist();
                                  setState((prevState) => ({
                                    ...prevState,
                                    [event.target.name]: event.target.checked,
                                  }));
                                }}
                              />
                            }
                            inputRef={register}
                            label="Job title and site"
                          />
                          <div className="invalid-feedback">{errors.jobTitleSite?.message}</div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={<span className="checkboxIcon"></span>}
                                checkedIcon={<span className="checkboxIconChecked"></span>}
                                color="primary"
                                checked={state.company}
                                name="company"
                                onChange={(event) => {
                                  event.persist();
                                  setState((prevState) => ({
                                    ...prevState,
                                    [event.target.name]: event.target.checked,
                                  }));
                                }}
                              />
                            }
                            inputRef={register}
                            label="Company confirmed"
                          />
                          <div className="invalid-feedback">{errors.company?.message}</div>
                        </FormGroup>
                      </FormControl>
                    </Card>
                    {/* 
                  Other Party Consent - if user age < 18 
                  */}
                    {participantAgeYears < 18 ? (
                      <Card className="uCard" variant="outlined">
                        <Typography variant="h2" className="heroHeading">
                          <img src={ClearanceBlueIcon} alt="Success" /> Other Party Consent
                        </Typography>
                        <Typography className="introPefaText" variant="body1">
                          Because{" "}
                          <span>
                            {pefaAssessment.pefArequest.worker.firstName} {pefaAssessment.pefArequest.worker.lastName}
                          </span>
                          , is under 18, they will need signed consent. Does the participant have this?
                        </Typography>
                        <input type="hidden" name="age" ref={register} value={participantAgeYears} />
                        <FormControl component="fieldset">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  name="confirmedConsent"
                                  icon={<span className="checkboxIcon"></span>}
                                  checkedIcon={<span className="checkboxIconChecked"></span>}
                                  checked={state.confirmedConsent}
                                  onChange={(event) => {
                                    event.persist();
                                    setState((prevState) => ({
                                      ...prevState,
                                      [event.target.name]: event.target.checked,
                                    }));
                                  }}
                                />
                              }
                              inputRef={register}
                              label="Confirmed consent?"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  icon={<span className="checkboxIcon"></span>}
                                  checkedIcon={<span className="checkboxIconChecked"></span>}
                                  color="primary"
                                  checked={state.noConsent}
                                  name="noConsent"
                                  onChange={(event) => {
                                    event.persist();
                                    setState((prevState) => ({
                                      ...prevState,
                                      [event.target.name]: event.target.checked,
                                    }));
                                  }}
                                />
                              }
                              inputRef={register}
                              label="No? Place note on file."
                            />
                            <div className="invalid-feedback">{errors.minorConsent?.message}</div>
                          </FormGroup>
                        </FormControl>
                      </Card>
                    ) : (
                      <></>
                    )}
                    <Card className="uCard" variant="outlined">
                      <Typography className="Hdr">
                        By accepting this referral, you confirm that you will provide the services in accordance with the following conditions:
                      </Typography>
                      <Typography className="lister">
                        <span>A</span>
                        Your business and assessors used in the provision of the service are covered by current Professional Indemnity, Public Liability and Workers Compensation
                        insurance;
                      </Typography>
                      <Typography className="lister">
                        <span>B</span>
                        Your assessors do not have any conditions on their registration/accreditation with the relevant body;
                      </Typography>
                      <Typography className="lister">
                        <span>C</span>
                        You will follow the JobFit System PEFA testing procedures and only use JobFit System trained assessors for the provision of this service;
                      </Typography>
                      <Typography className="lister">
                        <span>D</span>
                        You will immediately report any incidents that occur during the provision of this service to JobFit Systems International;
                      </Typography>
                      <Typography className="lister">
                        <span>E</span>
                        You will not copy, reproduce or otherwise use our intellectual property for any purpose other than the purpose of this referral;
                      </Typography>
                      <Typography className="lister">
                        <span>F</span>
                        You will ensure that your testing equipment used in the provision of this service is in good working order, fit for the purpose and safe for use and you
                        have provided a safe place of work.
                      </Typography>
                    </Card>
                  </form>
                </Grid>
                <Grid item container md={3} sm={12} className="RightCol">
                  <ProfileCard
                    firstName={pefaAssessment.pefArequest.worker.firstName}
                    lastName={pefaAssessment.pefArequest.worker.lastName}
                    participantAgeYears={participantAgeYears}
                  />
                  <NotesModal pageKey={dataStorePageKeys.ASSESSMENT_DATA} pefaID={pefaAssessment.id} />
                </Grid>
              </Grid>
            </Container>
            <FormNavigator
              backTarget={MEDICALDETAILS}
              forwardTarget={BASELINEMEASUREMENTS}
              handleSubmit={handleSubmit(submitForm)}
              disabledNextButton={
                endAssessment &&
                endAssessment.endAssessmentReason !== "" &&
                endAssessment.endAssessmentNotes !== "" &&
                endAssessment.endAssessmentPage === dataStorePageKeys.ASSESSMENT_DATA
              }
              selectedKey={dataStorePageKeys.ASSESSMENT_DATA}
            />
          </Grid>
        </div>
      </div>
    );
  })
);

export default withAppInsights(AssessmentDetails);

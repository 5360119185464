import React, { ChangeEvent, useEffect } from "react";
import { ProfileCard, FormNavigator, Stepper, Accordion, IOSSwitch, MobilityTest, StrengthTest, NotesModal, InTestMedicalClearanceModal } from "../../../components/";
import { MskSitting } from "../../../assets/images/";
import musculoskeletalValidation from "./mskSittingValidation";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { mskSittingMobilityDataKeys, mskSittingStrengthDataKeys } from "./mskDataKeys";

import {
  Grid,
  Typography,
  Container,
  FormControl,
  FormLabel,
  FormControlLabel,
  OutlinedInput,
  Card,
  FormGroup,
  Checkbox,
  AccordionSummary,
  InputAdornment,
  AccordionDetails,
} from "@material-ui/core";
import { Accordion as StyledAccordion } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import { Root } from "../../../stores";
import { differenceInYears } from "date-fns";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { dataStorePageKeys } from "../../../stores/dataStore";
import { useHistory } from "react-router-dom";
import { DASHBOARD, MSKLYING } from "../../../routes/paths";
import { getAppInsights } from "../../../components/telemetry";
import withAppInsights from "../AppInsights";

interface MusckuloskeletalProps {
  rootStore?: Root;
}

const mskSitting = inject("rootStore")(
  observer((props: MusckuloskeletalProps) => {
    let pefaAssessment: any;
    let prevData: any;
    let referralDetails: any;
    let medicalHistoryData: any;
    let endAssessment: any;
    let participantAgeYears = 0;
    const history = useHistory();
    const appInsights = getAppInsights();

    if (props.rootStore?.pefaStore.selectedPefa) {
      pefaAssessment = props.rootStore?.pefaStore.selectedPefa[0];
      prevData = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.MSK_SITTING);
      referralDetails = props.rootStore?.dataStore.getCheckInData(pefaAssessment.id, dataStorePageKeys.REFERRAL_DETAILS);
      participantAgeYears = differenceInYears(new Date(), new Date(referralDetails?.dob ?? pefaAssessment.pefArequest.worker.dateOfBirth));
      medicalHistoryData = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.MEDICAL_DETAILS);
      endAssessment = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.END_ASSESSMENT);
    } else {
      history.push(DASHBOARD);
      return <></>;
    }

    const { register, handleSubmit, errors, setValue } = useForm({
      resolver: yupResolver(musculoskeletalValidation),
    });

    const [state, setState] = useState({
      isLoading: true,
      yesAllNilSignificant: false,
      viewAll: false,

      neckMobilityPainFree: prevData ? prevData.neckMobilityPainFree : false,
      neckMobilityPainFreeExpanded: false,
      neckMobilityPainFreeEdited: false,

      upperLimbMobilityPainFree: prevData ? prevData.upperLimbMobilityPainFree : false,
      upperLimbMobilityPainFreeExpanded: false,
      upperLimbMobilityPainFreeEdited: false,

      upperLimbStrengthPainFree: prevData ? prevData.upperLimbStrengthPainFree : false,
      upperLimbStrengthPainFreeExpanded: false,
      upperLimbStrengthPainFreeEdited: false,

      sittingSpecialTests: prevData ? prevData.sittingSpecialTests : false,
      sittingSpecialTestsExpanded: false,
      sittingSpecialTestsEdited: false,

      ankleStrengthPainFree: prevData ? prevData.ankleStrengthPainFree : false,
      ankleStrengthPainFreeExpanded: false,
      ankleStrengthPainFreeEdited: false,

      leftEmptyCanTestNegative: prevData ? (prevData.leftEmptyCanTestNegative ? prevData.leftEmptyCanTestNegative : false) : false,
      leftEmptyCanTestPositiveWeakness: prevData ? (prevData.leftEmptyCanTestPositiveWeakness ? prevData.leftEmptyCanTestPositiveWeakness : false) : false,
      leftEmptyCanTestPositiveDiscomfort: prevData ? (prevData.leftEmptyCanTestPositiveDiscomfort ? prevData.leftEmptyCanTestPositiveDiscomfort : false) : false,
      rightEmptyCanTestNegative: prevData ? (prevData.rightEmptyCanTestNegative ? prevData.rightEmptyCanTestNegative : false) : false,
      rightEmptyCanTestPositiveWeakness: prevData ? (prevData.rightEmptyCanTestPositiveWeakness ? prevData.rightEmptyCanTestPositiveWeakness : false) : false,
      rightEmptyCanTestPositiveDiscomfort: prevData ? (prevData.rightEmptyCanTestPositiveDiscomfort ? prevData.rightEmptyCanTestPositiveDiscomfort : false) : false,

      leftJamarGripTrial1: prevData ? prevData.leftJamarGripTrial1 : "",
      leftJamarGripTrial2: prevData ? prevData.leftJamarGripTrial2 : "",
      leftJamarGripTrial3: prevData ? prevData.leftJamarGripTrial3 : "",
      leftJamarGripTrialResult: prevData ? prevData.leftJamarGripTrialResult : "",
      rightJamarGripTrial1: prevData ? prevData.rightJamarGripTrial1 : "",
      rightJamarGripTrial2: prevData ? prevData.rightJamarGripTrial2 : "",
      rightJamarGripTrial3: prevData ? prevData.rightJamarGripTrial3 : "",
      rightJamarGripTrialResult: prevData ? prevData.rightJamarGripTrialResult : "",
      jamarGripLoaded: false,

      headDown: false,
      headUp: false,
      rotateLeft: false,
      rotateRight: false,
      leftShoulderFlexion: false,
      leftShoulderAbduction: false,
      leftShoulderHandBehindHead: false,
      leftShoulderHandBehindBack: false,
      leftElbowForearmExtension: false,
      leftElbowForearmFlexion: false,
      leftElbowForearmSupination: false,
      leftWristHandExtension: false,
      leftWristHandFlexion: false,
      leftWristHandRadialDeviation: false,
      leftWristHandUlnarDeviation: false,
      leftHandFingerOppositionToThumb: false,
      leftHandFingerFullFist: false,
      rightShoulderFlexion: false,
      rightShoulderAbduction: false,
      rightShoulderHandBehindHead: false,
      rightShoulderHandBehindBack: false,
      rightElbowForearmExtension: false,
      rightElbowForearmFlexion: false,
      rightElbowForearmSupination: false,
      rightWristHandExtension: false,
      rightWristHandFlexion: false,
      rightWristHandRadialDeviation: false,
      rightWristHandUlnarDeviation: false,
      rightHandFingerOppositionToThumb: false,
      rightHandFingerFullFist: false,
      leftShoulderStrengthFlexion: false,
      leftShoulderStrengthAbduction: false,
      leftShoulderStrengthInternalRotation: false,
      leftShoulderStrengthExternalRotation: false,
      leftElbowStrengthFlexion: false,
      leftElbowStrengthExtension: false,
      leftElbowStrengthSupination: false,
      leftThumbStrengthExtensions: false,
      leftThumbStrengthLumbricals: false,
      rightShoulderStrengthFlexion: false,
      rightShoulderStrengthAbduction: false,
      rightShoulderStrengthInternalRotation: false,
      rightShoulderStrengthExternalRotation: false,
      rightElbowStrengthFlexion: false,
      rightElbowStrengthExtension: false,
      rightElbowStrengthSupination: false,
      rightThumbStrengthExtensions: false,
      rightThumbStrengthLumbricals: false,
    });

    const handleViewAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setState((prevState) => ({
        ...prevState,
        viewAll: event.target.checked,
        neckMobilityPainFreeExpanded: event.target.checked,
        upperLimbMobilityPainFreeExpanded: event.target.checked,
        upperLimbStrengthPainFreeExpanded: event.target.checked,
        sittingSpecialTestsExpanded: event.target.checked,
        ankleStrengthPainFreeExpanded: event.target.checked,
      }));
    };

    const toggleAccordion = async (eventValue: React.ChangeEvent<HTMLDivElement>, componentName: string) => {
      await setState((prevState) => ({
        ...prevState,
        [componentName]: eventValue,
      }));

      if (!eventValue && state.viewAll) {
        await setState((prevState) => ({
          ...prevState,
          viewAll: false,
        }));
      }
    };

    const submitForm = (data: any, forwardTarget: any) => {
      const { leftJamarGripTrialResult, rightJamarGripTrialResult } = state;
      const pefaData = { ...props.rootStore?.dataStore.pefaData[pefaAssessment.id] };
      props.rootStore?.dataStore.saveData({ ...data, leftJamarGripTrialResult, rightJamarGripTrialResult }, pefaAssessment.id, dataStorePageKeys.MSK_SITTING);
      // take snapshot of assessment and store against booking
      props.rootStore?.pefaStore.nextSubmit(pefaData, pefaAssessment.id);
      history.push(forwardTarget);
    };

    const handleCheckboxChange = async (event: any, logic: any = null): Promise<void> => {
      const control = event.currentTarget;
      const formComponent = control.getAttribute("name") as string;

      //Store the current checkbox's value to the state using the checkbox name attribute as the key.
      //This is set as an await to make sure that it has saved before moving on. This may not be necessary,
      //but I'll leave it here for now. It's handy for testing
      await setState((prevState) => ({
        ...prevState,
        [formComponent]: control.checked,
      }));

      //If a reference to a function has been passed, execute it, passing in the control checked state.
      if (!!logic) {
        logic(control.checked);
      }
    };

    const changeAllChildCheckboxes = (checked: boolean, targetCheckboxes: string[]): void => {
      targetCheckboxes.forEach((child) => {
        //Set the checkbox values in the form, flagging them as dirty.
        setValue(child, checked, { shouldDirty: true });

        //Update the mapped state values. This updates the field visually.
        setState((prevState) => ({
          ...prevState,
          [child]: checked,
        }));
      });
    };

    const changeParentCheckbox = async (childChecked: boolean, parentCheckbox: string) => {
      if (!childChecked) {
        setValue(parentCheckbox, false, { shouldDirty: true });
        await setState((prevState) => ({
          ...prevState,
          [parentCheckbox]: false,
        }));
      }
    };

    const handleCanTestChanged = (event: ChangeEvent<any>) => {
      event.persist();

      setState((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.checked,
      }));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleJamarGripChange = (event: any) => {
      let {
        leftJamarGripTrial1,
        leftJamarGripTrial2,
        leftJamarGripTrial3,
        leftJamarGripTrialResult,
        rightJamarGripTrial1,
        rightJamarGripTrial2,
        rightJamarGripTrial3,
        rightJamarGripTrialResult,
      } = state;

      if (event) {
        event.persist();
        switch (event.target.name) {
          case "leftJamarGripTrial1": {
            leftJamarGripTrial1 = event.target.value;
            break;
          }
          case "leftJamarGripTrial2": {
            leftJamarGripTrial2 = event.target.value;
            break;
          }
          case "leftJamarGripTrial3": {
            leftJamarGripTrial3 = event.target.value;
            break;
          }
          case "rightJamarGripTrial1": {
            rightJamarGripTrial1 = event.target.value;
            break;
          }
          case "rightJamarGripTrial2": {
            rightJamarGripTrial2 = event.target.value;
            break;
          }
          case "rightJamarGripTrial3": {
            rightJamarGripTrial3 = event.target.value;
            break;
          }
        }
      }

      let leftTrialCount = 0;
      let leftTotalWeight = 0;
      let rightTrialCount = 0;
      let rightTotalWeight = 0;

      //Based on the values entered, calculate the total weight and number of trials completed.

      if (leftJamarGripTrial1) {
        leftTotalWeight += Number(leftJamarGripTrial1);
        leftTrialCount++;
      }

      if (leftJamarGripTrial2) {
        leftTotalWeight += Number(leftJamarGripTrial2);
        leftTrialCount++;
      }

      if (leftJamarGripTrial3) {
        leftTotalWeight += Number(leftJamarGripTrial3);
        leftTrialCount++;
      }

      //Based on the values entered, calculate the total weight and number of trials completed.

      if (rightJamarGripTrial1) {
        rightTotalWeight += Number(rightJamarGripTrial1);
        rightTrialCount++;
      }

      if (rightJamarGripTrial2) {
        rightTotalWeight += Number(rightJamarGripTrial2);
        rightTrialCount++;
      }

      if (rightJamarGripTrial3) {
        rightTotalWeight += Number(rightJamarGripTrial3);
        rightTrialCount++;
      }

      //Calculate the averages for the Jamar grip tests.

      if (leftTrialCount > 0) {
        leftJamarGripTrialResult = (leftTotalWeight / leftTrialCount).toFixed(2);

        setValue("leftJamarGripTrialResult", leftJamarGripTrialResult, {
          shouldDirty: true,
        });
      }

      if (rightTrialCount > 0) {
        rightJamarGripTrialResult = (rightTotalWeight / rightTrialCount).toFixed(2);

        setValue("rightJamarGripTrialResult", rightJamarGripTrialResult, {
          shouldDirty: true,
        });
      }

      setState((prevState) => ({
        ...prevState,
        leftJamarGripTrial1,
        leftJamarGripTrial2,
        leftJamarGripTrial3,
        leftJamarGripTrialResult,
        rightJamarGripTrial1,
        rightJamarGripTrial2,
        rightJamarGripTrial3,
        rightJamarGripTrialResult,
      }));
    };

    const physicalChildren = ["neckMobilityPainFree", "upperLimbMobilityPainFree", "upperLimbStrengthPainFree", "sittingSpecialTests", "ankleStrengthPainFree"];

    const handleDataChanged = (datasetName: string, isDirty: boolean) => {
      setState((prevState) => ({
        ...prevState,
        [datasetName]: isDirty,
      }));
    };

    //leave this separate to the other useEffect otherwise it will break the page.
    useEffect(() => {
      return () => {
        if (!state.jamarGripLoaded) {
          setState((prevState) => ({ ...prevState, jamarGripLoaded: true }));
          handleJamarGripChange(null);
        }
      };
    }, [handleJamarGripChange, state.jamarGripLoaded]);

    useEffect(() => {
      setState((prevState) => ({
        ...prevState,
        neckMobilityPainFreeEdited: state.headDown || state.headUp || state.rotateLeft || state.rotateRight,
        upperLimbMobilityPainFreeEdited:
          state.leftShoulderFlexion ||
          state.leftShoulderAbduction ||
          state.leftShoulderHandBehindHead ||
          state.leftShoulderHandBehindBack ||
          state.leftElbowForearmExtension ||
          state.leftElbowForearmFlexion ||
          state.leftElbowForearmSupination ||
          state.leftWristHandExtension ||
          state.leftWristHandFlexion ||
          state.leftWristHandRadialDeviation ||
          state.leftWristHandUlnarDeviation ||
          state.leftHandFingerOppositionToThumb ||
          state.leftHandFingerFullFist ||
          state.rightShoulderFlexion ||
          state.rightShoulderAbduction ||
          state.rightShoulderHandBehindHead ||
          state.rightShoulderHandBehindBack ||
          state.rightElbowForearmExtension ||
          state.rightElbowForearmFlexion ||
          state.rightElbowForearmSupination ||
          state.rightWristHandExtension ||
          state.rightWristHandFlexion ||
          state.rightWristHandRadialDeviation ||
          state.rightWristHandUlnarDeviation ||
          state.rightHandFingerOppositionToThumb ||
          state.rightHandFingerFullFist,
        upperLimbStrengthPainFreeEdited:
          state.leftShoulderStrengthFlexion ||
          state.leftShoulderStrengthAbduction ||
          state.leftShoulderStrengthInternalRotation ||
          state.leftShoulderStrengthExternalRotation ||
          state.leftElbowStrengthFlexion ||
          state.leftElbowStrengthExtension ||
          state.leftElbowStrengthSupination ||
          state.leftThumbStrengthExtensions ||
          state.leftThumbStrengthLumbricals ||
          state.rightShoulderStrengthFlexion ||
          state.rightShoulderStrengthAbduction ||
          state.rightShoulderStrengthInternalRotation ||
          state.rightShoulderStrengthExternalRotation ||
          state.rightElbowStrengthFlexion ||
          state.rightElbowStrengthExtension ||
          state.rightElbowStrengthSupination ||
          state.rightThumbStrengthExtensions ||
          state.rightThumbStrengthLumbricals,
        sittingSpecialTestsEdited:
          state.leftEmptyCanTestNegative ||
          state.leftEmptyCanTestPositiveWeakness ||
          state.leftEmptyCanTestPositiveDiscomfort ||
          state.rightEmptyCanTestNegative ||
          state.rightEmptyCanTestPositiveWeakness ||
          state.rightEmptyCanTestPositiveDiscomfort ||
          state.leftJamarGripTrial1 ||
          state.rightJamarGripTrial1,
      }));
    }, [
      state.headDown,
      state.headUp,
      state.rotateLeft,
      state.rotateRight,
      state.leftShoulderFlexion,
      state.leftShoulderAbduction,
      state.leftShoulderHandBehindHead,
      state.leftShoulderHandBehindBack,
      state.leftElbowForearmExtension,
      state.leftElbowForearmFlexion,
      state.leftElbowForearmSupination,
      state.leftWristHandExtension,
      state.leftWristHandFlexion,
      state.leftWristHandRadialDeviation,
      state.leftWristHandUlnarDeviation,
      state.leftHandFingerOppositionToThumb,
      state.leftHandFingerFullFist,
      state.rightShoulderFlexion,
      state.rightShoulderAbduction,
      state.rightShoulderHandBehindHead,
      state.rightShoulderHandBehindBack,
      state.rightElbowForearmExtension,
      state.rightElbowForearmFlexion,
      state.rightElbowForearmSupination,
      state.rightWristHandExtension,
      state.rightWristHandFlexion,
      state.rightWristHandRadialDeviation,
      state.rightWristHandUlnarDeviation,
      state.rightHandFingerOppositionToThumb,
      state.rightHandFingerFullFist,
      state.leftShoulderStrengthFlexion,
      state.leftShoulderStrengthAbduction,
      state.leftShoulderStrengthInternalRotation,
      state.leftShoulderStrengthExternalRotation,
      state.leftElbowStrengthFlexion,
      state.leftElbowStrengthExtension,
      state.leftElbowStrengthSupination,
      state.leftThumbStrengthExtensions,
      state.leftThumbStrengthLumbricals,
      state.rightShoulderStrengthFlexion,
      state.rightShoulderStrengthAbduction,
      state.rightShoulderStrengthInternalRotation,
      state.rightShoulderStrengthExternalRotation,
      state.rightElbowStrengthFlexion,
      state.rightElbowStrengthExtension,
      state.rightElbowStrengthSupination,
      state.rightThumbStrengthExtensions,
      state.rightThumbStrengthLumbricals,
      state.leftEmptyCanTestNegative,
      state.leftEmptyCanTestPositiveWeakness,
      state.leftEmptyCanTestPositiveDiscomfort,
      state.rightEmptyCanTestNegative,
      state.rightEmptyCanTestPositiveWeakness,
      state.rightEmptyCanTestPositiveDiscomfort,
      state.leftJamarGripTrial1,
      state.rightJamarGripTrial1,
    ]);

    useEffect(() => {
      const timeout = setTimeout(() => {
        props.rootStore?.dataStore.savePartialData(state, pefaAssessment.id, dataStorePageKeys.MSK_SITTING);
      }, 500);

      return () => clearTimeout(timeout);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      state.neckMobilityPainFree,
      state.upperLimbMobilityPainFree,
      state.upperLimbStrengthPainFree,
      state.sittingSpecialTests,
      state.ankleStrengthPainFree,
      state.leftEmptyCanTestNegative,
      state.leftEmptyCanTestPositiveWeakness,
      state.leftEmptyCanTestPositiveDiscomfort,
      state.rightEmptyCanTestNegative,
      state.rightEmptyCanTestPositiveWeakness,
      state.rightEmptyCanTestPositiveDiscomfort,
      state.leftJamarGripTrial1,
      state.leftJamarGripTrial2,
      state.leftJamarGripTrial3,
      state.leftJamarGripTrialResult,
      state.rightJamarGripTrial1,
      state.rightJamarGripTrial2,
      state.rightJamarGripTrial3,
      state.rightJamarGripTrialResult,
    ]);

    useEffect(() => {
      //Replicate the onUnmount event handler from class components.
      return () => {
        appInsights.trackEvent({ name: "intermediateSubmission", properties: { bookingId: pefaAssessment.id, dataKey: dataStorePageKeys.MSK_SITTING, data: state } });
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <div className="FormStyler">
        <div className="AssessmentDetails">
          <Grid item container direction="column" xs={12}>
            <Stepper targetStep={"Observations & Musculosketal"} />
            <Container maxWidth={"lg"}>
              <Grid item container direction="row" xs={12}>
                <Grid item container xs={9}>
                  <Card className="titleCard" variant="outlined">
                    <Typography variant="h2" className="cardHeroHeading">
                      <img src={MskSitting} alt="Success" /> Sitting: Mobility &amp; Strength
                    </Typography>
                    <Typography variant="subtitle2" className="subtitleHeading">
                      List of Observations
                    </Typography>
                  </Card>
                  <Grid item container xs={12}>
                    <div className="formControllerContainer">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="yesAllNilSignificant"
                            name="yesAllNilSignificant"
                            icon={<span className="checkboxIcon"></span>}
                            checkedIcon={<span className="checkboxIconChecked"></span>}
                            color="primary"
                          />
                        }
                        label="All within functional limits pain free"
                        inputRef={register}
                        checked={state.yesAllNilSignificant}
                        onChange={(event) => {
                          handleCheckboxChange(event, (checked: boolean) => changeAllChildCheckboxes(checked, physicalChildren));
                        }}
                      />
                      <FormControlLabel control={<IOSSwitch checked={state.viewAll} onChange={handleViewAllChange} name="checkedB" />} label="View All" />
                    </div>
                    {/* 
                    |
                      Neck Mobility within limits pain free
                    |
                    */}
                    <form className="PefaForm">
                      {/* 
                    |
                      Neck mobility within functional limits pain free
                    |
                    */}
                      <Accordion
                        inputName="neckMobilityPainFree"
                        label="Neck mobility within functional limits pain free"
                        checked={state.neckMobilityPainFree}
                        expanded={state.neckMobilityPainFreeExpanded}
                        edited={state.neckMobilityPainFreeEdited}
                        innerRef={register}
                        innerValidationError={
                          errors.neckMobility?.message || errors.headDown?.message || errors.headUp?.message || errors.rotateLeft?.message || errors.rotateRight?.message
                        }
                        checkedHandler={(event: any) => handleCheckboxChange(event, (checked: boolean) => changeParentCheckbox(checked, "yesAllNilSignificant"))}
                        expandedHandler={(event: any) => {
                          toggleAccordion(event, "neckMobilityPainFreeExpanded");
                        }}
                      >
                        <div className="invalid-feedback">{errors.neckMobility?.message}</div>
                        <MobilityTest
                          label="Head down"
                          datasetName={mskSittingMobilityDataKeys.HEADDOWN}
                          register={register}
                          errors={errors}
                          data={prevData}
                          setValue={setValue}
                          handleDataChanged={handleDataChanged}
                          assessmentID={pefaAssessment.id}
                          dataKey={dataStorePageKeys.MSK_SITTING}
                        />
                        <MobilityTest
                          label="Head up"
                          datasetName={mskSittingMobilityDataKeys.HEADUP}
                          register={register}
                          errors={errors}
                          data={prevData}
                          setValue={setValue}
                          handleDataChanged={handleDataChanged}
                          assessmentID={pefaAssessment.id}
                          dataKey={dataStorePageKeys.MSK_SITTING}
                        />
                        <MobilityTest
                          label="Rotate Left"
                          datasetName={mskSittingMobilityDataKeys.ROTATELEFT}
                          register={register}
                          errors={errors}
                          data={prevData}
                          setValue={setValue}
                          handleDataChanged={handleDataChanged}
                          assessmentID={pefaAssessment.id}
                          dataKey={dataStorePageKeys.MSK_SITTING}
                        />
                        <MobilityTest
                          label="Rotate Right"
                          datasetName={mskSittingMobilityDataKeys.ROTATERIGHT}
                          register={register}
                          errors={errors}
                          data={prevData}
                          setValue={setValue}
                          handleDataChanged={handleDataChanged}
                          assessmentID={pefaAssessment.id}
                          dataKey={dataStorePageKeys.MSK_SITTING}
                        />
                      </Accordion>
                      {/* 
                    |
                      Upper limb mobility within functional limits painfree on both sides
                    |
                    */}
                      <Accordion
                        inputName="upperLimbMobilityPainFree"
                        checked={state.upperLimbMobilityPainFree}
                        expanded={state.upperLimbMobilityPainFreeExpanded}
                        edited={state.upperLimbMobilityPainFreeEdited}
                        innerRef={register}
                        innerValidationError={
                          !!errors.upperLimbMobility?.message ||
                          !!errors.leftShoulderFlexion?.message ||
                          !!errors.leftShoulderAbduction?.message ||
                          !!errors.leftShoulderHandBehindHead?.message ||
                          !!errors.leftShoulderHandBehindBack?.message ||
                          !!errors.leftElbowForearmExtension?.message ||
                          !!errors.leftElbowForearmFlexion?.message ||
                          !!errors.leftElbowForearmSupination?.message ||
                          !!errors.leftWristHandExtension?.message ||
                          !!errors.leftWristHandFlexion?.message ||
                          !!errors.leftWristHandRadialDeviation?.message ||
                          !!errors.leftWristHandUlnarDeviation?.message ||
                          !!errors.leftHandFingerOppositionToThumb?.message ||
                          !!errors.leftHandFingerFullFist?.message ||
                          !!errors.rightShoulderFlexion?.message ||
                          !!errors.rightShoulderAbduction?.message ||
                          !!errors.rightShoulderHandBehindHead?.message ||
                          !!errors.rightShoulderHandBehindBack?.message ||
                          !!errors.rightElbowForearmExtension?.message ||
                          !!errors.rightElbowForearmFlexion?.message ||
                          !!errors.rightElbowForearmSupination?.message ||
                          !!errors.rightWristHandExtension?.message ||
                          !!errors.rightWristHandFlexion?.message ||
                          !!errors.rightWristHandRadialDeviation?.message ||
                          !!errors.rightWristHandUlnarDeviation?.message ||
                          !!errors.rightHandFingerOppositionToThumb?.message ||
                          !!errors.rightHandFingerFullFist?.message
                        }
                        label="Upper limb mobility within functional limits painfree on both sides"
                        checkedHandler={(event: any) => handleCheckboxChange(event, (checked: boolean) => changeParentCheckbox(checked, "yesAllNilSignificant"))}
                        expandedHandler={(event: any) => {
                          toggleAccordion(event, "upperLimbMobilityPainFreeExpanded");
                        }}
                      >
                        <div className="invalid-feedback">{errors.upperLimbMobility?.message}</div>
                        <StyledAccordion variant="outlined" className="innerOutlinedBox">
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <FormControlLabel
                              aria-label="Acknowledge"
                              control={
                                <Checkbox
                                  icon={<span className="checkboxIcon"></span>}
                                  checkedIcon={<span className="checkboxIconChecked"></span>}
                                  color="primary"
                                  name="upperLimbMobilityLimitedL"
                                />
                              }
                              label="Upper limb mobility limited or discomfort reported on Left"
                              color="primary"
                              inputRef={register}
                            />
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="greyBox">
                              <Typography variant="body1">
                                Please indicate which movements are limited or uncomfortable. All others will be marked as within functional limits painfree.
                              </Typography>
                            </div>
                            <FormLabel component="legend">Left shoulder mobility</FormLabel>
                            <MobilityTest
                              label="Flexion"
                              datasetName={mskSittingMobilityDataKeys.LEFTSHOULDERFLEXION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                            <MobilityTest
                              label="Abduction"
                              datasetName={mskSittingMobilityDataKeys.LEFTSHOULDERABDUCTION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                            <MobilityTest
                              label="Hand behind head"
                              datasetName={mskSittingMobilityDataKeys.LEFTSHOULDERHANDBEHINDHEAD}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                            <MobilityTest
                              label="Hand behind back"
                              datasetName={mskSittingMobilityDataKeys.LEFTSHOULDERHANDBEHINDBACK}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                            <FormLabel component="legend">Left elbow/forearm mobility</FormLabel>
                            <MobilityTest
                              label="Extension"
                              datasetName={mskSittingMobilityDataKeys.LEFTELBOWFOREARMEXTENSION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                            <MobilityTest
                              label="Flexion"
                              datasetName={mskSittingMobilityDataKeys.LEFTELBOWFOREARMFLEXION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                            <MobilityTest
                              label="Pronation"
                              datasetName={mskSittingMobilityDataKeys.LEFTELBOWFOREARMSUPINATION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                            <FormLabel component="legend">Left wrist/hand mobility</FormLabel>
                            <MobilityTest
                              label="Extension"
                              datasetName={mskSittingMobilityDataKeys.LEFTWRISTHANDEXTENSION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                            <MobilityTest
                              label="Flexion"
                              datasetName={mskSittingMobilityDataKeys.LEFTWRISTHANDFLEXION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                            <MobilityTest
                              label="Radial deviation"
                              datasetName={mskSittingMobilityDataKeys.LEFTWRISTHANDRADIALDEVIATION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                            <MobilityTest
                              label="Ulnar deviation"
                              datasetName={mskSittingMobilityDataKeys.LEFTWRISTHANDULNARDEVIATION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                            <FormLabel component="legend">Left hand/finger mobility</FormLabel>
                            <MobilityTest
                              label="Opposition to thumb"
                              datasetName={mskSittingMobilityDataKeys.LEFTHANDFINGEROPPOSITIONTOTHUMB}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                            <MobilityTest
                              label="Full fist"
                              datasetName={mskSittingMobilityDataKeys.LEFTHANDFINGERFULLFIST}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                          </AccordionDetails>
                        </StyledAccordion>
                        <StyledAccordion variant="outlined" className="innerOutlinedBox">
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <FormControlLabel
                              aria-label="Acknowledge"
                              control={
                                <Checkbox
                                  icon={<span className="checkboxIcon"></span>}
                                  checkedIcon={<span className="checkboxIconChecked"></span>}
                                  color="primary"
                                  name="upperLimbMobilityLimitedR"
                                />
                              }
                              label="Upper limb mobility limited or discomfort reported on Right"
                              color="primary"
                              inputRef={register}
                            />
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="greyBox">
                              <Typography variant="body1">
                                Please indicate which movements are limited or uncomfortable. All others will be marked as within functional limits painfree.
                              </Typography>
                            </div>
                            <FormLabel component="legend">Right shoulder mobility</FormLabel>
                            <MobilityTest
                              label="Flexion"
                              datasetName={mskSittingMobilityDataKeys.RIGHTSHOULDERFLEXION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                            <MobilityTest
                              label="Abduction"
                              datasetName={mskSittingMobilityDataKeys.RIGHTSHOULDERABDUCTION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                            <MobilityTest
                              label="Hand behind head"
                              datasetName={mskSittingMobilityDataKeys.RIGHTSHOULDERHANDBEHINDHEAD}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                            <MobilityTest
                              label="Hand behind back"
                              datasetName={mskSittingMobilityDataKeys.RIGHTSHOULDERHANDBEHINDBACK}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                            <FormLabel component="legend">Right elbow/forearm mobility</FormLabel>
                            <MobilityTest
                              label="Extension"
                              datasetName={mskSittingMobilityDataKeys.RIGHTELBOWFOREARMEXTENSION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                            <MobilityTest
                              label="Flexion"
                              datasetName={mskSittingMobilityDataKeys.RIGHTELBOWFOREARMFLEXION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                            <MobilityTest
                              label="Pronation"
                              datasetName={mskSittingMobilityDataKeys.RIGHTELBOWFOREARMSUPINATION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                            <FormLabel component="legend">Right wrist/hand mobility</FormLabel>
                            <MobilityTest
                              label="Extension"
                              datasetName={mskSittingMobilityDataKeys.RIGHTWRISTHANDEXTENSION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                            <MobilityTest
                              label="Flexion"
                              datasetName={mskSittingMobilityDataKeys.RIGHTWRISTHANDFLEXION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                            <MobilityTest
                              label="Radial deviation"
                              datasetName={mskSittingMobilityDataKeys.RIGHTWRISTHANDRADIALDEVIATION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                            <MobilityTest
                              label="Ulnar deviation"
                              datasetName={mskSittingMobilityDataKeys.RIGHTWRISTHANDULNARDEVIATION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                            <FormLabel component="legend">Right hand/finger mobility</FormLabel>
                            <MobilityTest
                              label="Opposition to thumb"
                              datasetName={mskSittingMobilityDataKeys.RIGHTHANDFINGEROPPOSITIONTOTHUMB}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                            <MobilityTest
                              label="Full fist"
                              datasetName={mskSittingMobilityDataKeys.RIGHTHANDFINGERFULLFIST}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                          </AccordionDetails>
                        </StyledAccordion>
                      </Accordion>
                      {/* 
                    |
                      Upper limb strength within normal limits pain free on both sides
                    |
                    */}
                      <Accordion
                        inputName="upperLimbStrengthPainFree"
                        checked={state.upperLimbStrengthPainFree}
                        expanded={state.upperLimbStrengthPainFreeExpanded}
                        edited={state.upperLimbStrengthPainFreeEdited}
                        innerRef={register}
                        innerValidationError={
                          !!errors.upperLimbStrength?.message ||
                          !!errors.leftShoulderStrengthFlexion?.message ||
                          !!errors.leftShoulderStrengthAbduction?.message ||
                          !!errors.leftShoulderStrengthInternalRotation?.message ||
                          !!errors.leftShoulderStrengthExternalRotation?.message ||
                          !!errors.leftElbowStrengthFlexion?.message ||
                          !!errors.leftElbowStrengthExtension?.message ||
                          !!errors.leftElbowStrengthSupination?.message ||
                          !!errors.leftThumbStrengthExtensions?.message ||
                          !!errors.leftThumbStrengthLumbricals?.message ||
                          !!errors.rightShoulderStrengthFlexion?.message ||
                          !!errors.rightShoulderStrengthAbduction?.message ||
                          !!errors.rightShoulderStrengthInternalRotation?.message ||
                          !!errors.rightShoulderStrengthExternalRotation?.message ||
                          !!errors.rightElbowStrengthFlexion?.message ||
                          !!errors.rightElbowStrengthExtension?.message ||
                          !!errors.rightElbowStrengthSupination?.message ||
                          !!errors.rightThumbStrengthExtensions?.message ||
                          !!errors.rightThumbStrengthLumbricals?.message
                        }
                        label="Upper limb strength within normal limits pain free on both sides"
                        checkedHandler={(event: any) => handleCheckboxChange(event, (checked: boolean) => changeParentCheckbox(checked, "yesAllNilSignificant"))}
                        expandedHandler={(event: any) => {
                          toggleAccordion(event, "upperLimbStrengthPainFreeExpanded");
                        }}
                      >
                        <div className="invalid-feedback">{errors.upperLimbStrength?.message}</div>
                        <StyledAccordion variant="outlined" className="innerOutlinedBox">
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <FormControlLabel
                              aria-label="Acknowledge"
                              control={
                                <Checkbox
                                  icon={<span className="checkboxIcon"></span>}
                                  checkedIcon={<span className="checkboxIconChecked"></span>}
                                  color="primary"
                                  name="upperLimbStrengthLimitedL"
                                />
                              }
                              label="Upper limb strength reduced or discomfort reported on left"
                              color="primary"
                              inputRef={register}
                            />
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="greyBox">
                              <Typography variant="body1">
                                Please indicate which movements are limited or uncomfortable. All others will be marked as within functional limits painfree.
                              </Typography>
                            </div>
                            <FormLabel component="legend">Left shoulder strength</FormLabel>
                            <StrengthTest
                              label="Flexion"
                              datasetName={mskSittingStrengthDataKeys.LEFTSHOULDERSTRENGTHFLEXION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                            <StrengthTest
                              label="Abduction"
                              datasetName={mskSittingStrengthDataKeys.LEFTSHOULDERSTRENGTHABDUCTION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                            <StrengthTest
                              label="Internal Rotation"
                              datasetName={mskSittingStrengthDataKeys.LEFTSHOULDERSTRENGTHINTERNALROTATION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                            <StrengthTest
                              label="External Rotation"
                              datasetName={mskSittingStrengthDataKeys.LEFTSHOULDERSTRENGTHEXTERNALROTATION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                            <FormLabel component="legend">Left elbow strength</FormLabel>
                            <StrengthTest
                              label="Flexion"
                              datasetName={mskSittingStrengthDataKeys.LEFTELBOWSTRENGTHFLEXION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                            <StrengthTest
                              label="Extension"
                              datasetName={mskSittingStrengthDataKeys.LEFTELBOWSTRENGTHEXTENSION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                            <StrengthTest
                              label="Supination"
                              datasetName={mskSittingStrengthDataKeys.LEFTELBOWSTRENGTHSUPINATION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                            <FormLabel component="legend">Left hand strength</FormLabel>
                            <StrengthTest
                              label="Thumb Extensions"
                              datasetName={mskSittingStrengthDataKeys.LEFTTHUMBSTRENGTHEXTENSIONS}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                            <StrengthTest
                              label="Lumbricals"
                              datasetName={mskSittingStrengthDataKeys.LEFTTHUMBSTRENGTHLUMBRICALS}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                          </AccordionDetails>
                        </StyledAccordion>
                        <StyledAccordion variant="outlined" className="innerOutlinedBox">
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  icon={<span className="checkboxIcon"></span>}
                                  checkedIcon={<span className="checkboxIconChecked"></span>}
                                  color="primary"
                                  name="upperLimbStrengthLimitedR"
                                />
                              }
                              label="Upper limb strength reduced or discomfort reported on right"
                              color="primary"
                              inputRef={register}
                            />
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="greyBox">
                              <Typography variant="body1">
                                Please indicate which movements are limited or uncomfortable. All others will be marked as within functional limits painfree.
                              </Typography>
                            </div>
                            <FormLabel component="legend">Right shoulder strength</FormLabel>
                            <StrengthTest
                              label="Flexion"
                              datasetName={mskSittingStrengthDataKeys.RIGHTSHOULDERSTRENGTHFLEXION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                            <StrengthTest
                              label="Abduction"
                              datasetName={mskSittingStrengthDataKeys.RIGHTSHOULDERSTRENGTHABDUCTION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                            <StrengthTest
                              label="Internal Rotation"
                              datasetName={mskSittingStrengthDataKeys.RIGHTSHOULDERSTRENGTHINTERNALROTATION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                            <StrengthTest
                              label="External Rotation"
                              datasetName={mskSittingStrengthDataKeys.RIGHTSHOULDERSTRENGTHEXTERNALROTATION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                            <FormLabel component="legend">Right elbow strength</FormLabel>
                            <StrengthTest
                              label="Flexion"
                              datasetName={mskSittingStrengthDataKeys.RIGHTELBOWSTRENGTHFLEXION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                            <StrengthTest
                              label="Extension"
                              datasetName={mskSittingStrengthDataKeys.RIGHTELBOWSTRENGTHEXTENSION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                            <StrengthTest
                              label="Supination"
                              datasetName={mskSittingStrengthDataKeys.RIGHTELBOWSTRENGTHSUPINATION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                            <FormLabel component="legend">Right hand strength</FormLabel>
                            <StrengthTest
                              label="Thumb Extensions"
                              datasetName={mskSittingStrengthDataKeys.RIGHTTHUMBSTRENGTHEXTENSIONS}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                            <StrengthTest
                              label="Lumbricals"
                              datasetName={mskSittingStrengthDataKeys.RIGHTTHUMBSTRENGTHLUMBRICALS}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_SITTING}
                            />
                          </AccordionDetails>
                        </StyledAccordion>
                      </Accordion>
                      {/* 
                    |
                      Special Tests
                    |
                    */}
                      <Accordion
                        inputName="sittingSpecialTests"
                        label="Special tests"
                        checked={state.sittingSpecialTests}
                        expanded={state.sittingSpecialTestsExpanded}
                        edited={state.sittingSpecialTestsEdited}
                        innerRef={register}
                        checkedHandler={(event: any) => handleCheckboxChange(event, (checked: boolean) => changeParentCheckbox(checked, "yesAllNilSignificant"))}
                        expandedHandler={(event: any) => {
                          toggleAccordion(event, "sittingSpecialTestsExpanded");
                        }}
                      >
                        <FormControl component="fieldset" className="fieldsetStrength">
                          <FormLabel component="legend">Empty can test</FormLabel>
                          <FormGroup className="formGroupOveride">
                            <Grid xs={12} item container className="StrengthTestContainer">
                              <FormControl component="fieldset" className="fieldsetStrength">
                                <FormGroup className="formGroupOveride">
                                  <div className="flexer">
                                    <div className="labelLeftOveride">
                                      <FormLabel className="greyPill" component="legend">
                                        Left
                                      </FormLabel>
                                    </div>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          icon={<span className="checkboxIcon"></span>}
                                          checkedIcon={<span className="checkboxIconChecked"></span>}
                                          color="primary"
                                          checked={state.leftEmptyCanTestNegative}
                                          disabled={!!state.leftEmptyCanTestPositiveWeakness || !!state.leftEmptyCanTestPositiveDiscomfort}
                                          onChange={handleCanTestChanged}
                                        />
                                      }
                                      label="Negative"
                                      name="leftEmptyCanTestNegative"
                                      inputRef={register}
                                    />
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          icon={<span className="checkboxIcon"></span>}
                                          checkedIcon={<span className="checkboxIconChecked"></span>}
                                          color="primary"
                                          checked={state.leftEmptyCanTestPositiveWeakness}
                                          disabled={state.leftEmptyCanTestNegative}
                                          onChange={handleCanTestChanged}
                                        />
                                      }
                                      label="Positive Weakness"
                                      name="leftEmptyCanTestPositiveWeakness"
                                      inputRef={register}
                                    />
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          icon={<span className="checkboxIcon"></span>}
                                          checkedIcon={<span className="checkboxIconChecked"></span>}
                                          color="primary"
                                          checked={state.leftEmptyCanTestPositiveDiscomfort}
                                          disabled={state.leftEmptyCanTestNegative}
                                          onChange={handleCanTestChanged}
                                        />
                                      }
                                      label="Positive discomfort"
                                      name="leftEmptyCanTestPositiveDiscomfort"
                                      inputRef={register}
                                    />
                                  </div>
                                </FormGroup>
                              </FormControl>
                            </Grid>
                          </FormGroup>
                          <FormGroup className="formGroupOveride">
                            <div className="flexer">
                              <div className="labelLeftOveride">
                                <FormLabel className="greyPill" component="legend">
                                  Right
                                </FormLabel>
                              </div>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    icon={<span className="checkboxIcon"></span>}
                                    checkedIcon={<span className="checkboxIconChecked"></span>}
                                    color="primary"
                                    disabled={!!state.rightEmptyCanTestPositiveDiscomfort || !!state.rightEmptyCanTestPositiveWeakness}
                                    checked={state.rightEmptyCanTestNegative}
                                    onChange={handleCanTestChanged}
                                  />
                                }
                                label="Negative"
                                name="rightEmptyCanTestNegative"
                                inputRef={register}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    icon={<span className="checkboxIcon"></span>}
                                    checkedIcon={<span className="checkboxIconChecked"></span>}
                                    color="primary"
                                    disabled={!!state.rightEmptyCanTestNegative}
                                    checked={state.rightEmptyCanTestPositiveWeakness}
                                    onChange={handleCanTestChanged}
                                  />
                                }
                                label="Positive Weakness"
                                name="rightEmptyCanTestPositiveWeakness"
                                inputRef={register}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    icon={<span className="checkboxIcon"></span>}
                                    checkedIcon={<span className="checkboxIconChecked"></span>}
                                    color="primary"
                                    disabled={!!state.rightEmptyCanTestNegative}
                                    checked={state.rightEmptyCanTestPositiveDiscomfort}
                                    onChange={handleCanTestChanged}
                                  />
                                }
                                label="Positive discomfort"
                                name="rightEmptyCanTestPositiveDiscomfort"
                                inputRef={register}
                              />
                            </div>
                          </FormGroup>
                        </FormControl>
                        <FormControl component="fieldset" className="fieldsetStrength">
                          <FormLabel component="legend">Jamar grip strength (optional ) “test only if required”</FormLabel>
                          <FormGroup className="formGroupOveride">
                            <Grid xs={12} item container className="StrengthTestContainer">
                              <FormControl component="fieldset" className="fieldsetStrength">
                                <FormGroup className="formGroupOveride">
                                  <div className="flexer">
                                    <div className="labelLeftOveride">
                                      <FormLabel className="greyPill" component="legend">
                                        Left
                                      </FormLabel>
                                    </div>
                                    <FormControlLabel
                                      control={
                                        <OutlinedInput
                                          color="primary"
                                          className="textInputJamar"
                                          autoComplete="off"
                                          endAdornment={<InputAdornment position="end">Kg</InputAdornment>}
                                          value={state.leftJamarGripTrial1}
                                          onChange={handleJamarGripChange}
                                          inputRef={register}
                                        />
                                      }
                                      label="Trial 1"
                                      labelPlacement="top"
                                      name="leftJamarGripTrial1"
                                    />
                                    <FormControlLabel
                                      control={
                                        <OutlinedInput
                                          color="primary"
                                          className="textInputJamar"
                                          autoComplete="off"
                                          endAdornment={<InputAdornment position="end">Kg</InputAdornment>}
                                          disabled={!state.leftJamarGripTrial1}
                                          value={state.leftJamarGripTrial2}
                                          onChange={handleJamarGripChange}
                                          inputRef={register}
                                        />
                                      }
                                      label="Trial 2"
                                      labelPlacement="top"
                                      name="leftJamarGripTrial2"
                                    />
                                    <FormControlLabel
                                      control={
                                        <OutlinedInput
                                          color="primary"
                                          className="textInputJamar"
                                          autoComplete="off"
                                          endAdornment={<InputAdornment position="end">Kg</InputAdornment>}
                                          disabled={!state.leftJamarGripTrial2}
                                          value={state.leftJamarGripTrial3}
                                          onChange={handleJamarGripChange}
                                          inputRef={register}
                                        />
                                      }
                                      label="Trial 3"
                                      labelPlacement="top"
                                      name="leftJamarGripTrial3"
                                    />
                                    <FormControlLabel
                                      control={
                                        <OutlinedInput
                                          color="primary"
                                          className="textInputJamarResult"
                                          autoComplete="off"
                                          endAdornment={<InputAdornment position="end">Kg</InputAdornment>}
                                          disabled
                                          value={state.leftJamarGripTrialResult}
                                          inputRef={register}
                                        />
                                      }
                                      label="Average kg"
                                      labelPlacement="top"
                                      name="leftJamarGripTrialResult"
                                    />
                                  </div>
                                  <div className="invalid-feedback">{errors.leftJamarGripTest?.message}</div>
                                </FormGroup>
                              </FormControl>
                            </Grid>
                          </FormGroup>
                          <FormGroup className="formGroupOveride">
                            <Grid xs={12} item container className="StrengthTestContainer">
                              <FormControl component="fieldset" className="fieldsetStrength">
                                <FormGroup className="formGroupOveride">
                                  <div className="flexer">
                                    <div className="labelLeftOveride">
                                      <FormLabel className="greyPill" component="legend">
                                        Right
                                      </FormLabel>
                                    </div>
                                    <FormControlLabel
                                      control={
                                        <OutlinedInput
                                          color="primary"
                                          className="textInputJamar"
                                          autoComplete="off"
                                          endAdornment={<InputAdornment position="end">Kg</InputAdornment>}
                                          value={state.rightJamarGripTrial1}
                                          onChange={handleJamarGripChange}
                                          inputRef={register}
                                        />
                                      }
                                      label="Trial 1"
                                      labelPlacement="top"
                                      name="rightJamarGripTrial1"
                                    />
                                    <FormControlLabel
                                      control={
                                        <OutlinedInput
                                          color="primary"
                                          className="textInputJamar"
                                          autoComplete="off"
                                          endAdornment={<InputAdornment position="end">Kg</InputAdornment>}
                                          disabled={!state.rightJamarGripTrial1}
                                          value={state.rightJamarGripTrial2}
                                          onChange={handleJamarGripChange}
                                          inputRef={register}
                                        />
                                      }
                                      label="Trial 2"
                                      labelPlacement="top"
                                      name="rightJamarGripTrial2"
                                    />
                                    <FormControlLabel
                                      control={
                                        <OutlinedInput
                                          color="primary"
                                          className="textInputJamar"
                                          autoComplete="off"
                                          endAdornment={<InputAdornment position="end">Kg</InputAdornment>}
                                          disabled={!state.rightJamarGripTrial2}
                                          value={state.rightJamarGripTrial3}
                                          onChange={handleJamarGripChange}
                                          inputRef={register}
                                        />
                                      }
                                      label="Trial 3"
                                      labelPlacement="top"
                                      name="rightJamarGripTrial3"
                                    />
                                    <FormControlLabel
                                      control={
                                        <OutlinedInput
                                          color="primary"
                                          className="textInputJamarResult"
                                          autoComplete="off"
                                          endAdornment={<InputAdornment position="end">Kg</InputAdornment>}
                                          value={state.rightJamarGripTrialResult}
                                          disabled
                                          inputRef={register}
                                        />
                                      }
                                      label="Average kg"
                                      labelPlacement="top"
                                      name="rightJamarGripTrialResult"
                                    />
                                  </div>
                                  <div className="invalid-feedback">{errors.rightJamarGripTest?.message}</div>
                                </FormGroup>
                              </FormControl>
                            </Grid>
                          </FormGroup>
                        </FormControl>
                      </Accordion>
                    </form>
                  </Grid>
                </Grid>
                <Grid item container xs={3} className="RightCol">
                  <ProfileCard
                    firstName={pefaAssessment.pefArequest.worker.firstName}
                    lastName={pefaAssessment.pefArequest.worker.lastName}
                    participantAgeYears={participantAgeYears}
                  />
                  <NotesModal pageKey={dataStorePageKeys.MSK_SITTING} pefaID={pefaAssessment.id} />
                  <InTestMedicalClearanceModal
                    firstName={referralDetails.firstName}
                    lastName={referralDetails.lastName}
                    pageKey={dataStorePageKeys.MSK_SITTING}
                    pefaID={pefaAssessment.id}
                    medicalPractice={medicalHistoryData.medicalPractitioner}
                    medicalPractitioner={medicalHistoryData.medicalPractice}
                  />
                </Grid>
              </Grid>
            </Container>
            <FormNavigator
              forwardTarget={MSKLYING}
              handleSubmit={handleSubmit(submitForm)}
              disabledNextButton={
                endAssessment &&
                endAssessment.endAssessmentReason !== "" &&
                endAssessment.endAssessmentNotes !== "" &&
                endAssessment.endAssessmentPage === dataStorePageKeys.MSK_SITTING
              }
              selectedKey={dataStorePageKeys.MSK_SITTING}
            />
          </Grid>
        </div>
      </div>
    );
  })
);

export default withAppInsights(mskSitting);

import React, { FocusEvent, useEffect, useState } from "react";
import {
  ProfileCard,
  FormNavigator,
  Stepper,
  Modal,
  IOSSwitch,
  Timer,
  TimerStopped,
  NotTested,
  ParticipantReports,
  DrawerTimer,
  Metronome,
  PostTestHeartRateModal,
  NotesModal,
  Peeper,
  RemoteStart,
  HighBloodPressureModal,
  InTestMedicalClearanceModal,
} from "../../../components/";
import { FitnessIcon, Baseline, StarIcon } from "../../../assets/images/";
import fitnessTestValidation from "./validation";
import { ExpandMore, ErrorOutline } from "@material-ui/icons";

import {
  Grid,
  Typography,
  Container,
  FormControl,
  FormLabel,
  FormControlLabel,
  OutlinedInput,
  Card,
  FormGroup,
  Button,
  Checkbox,
  TextField,
  AccordionSummary,
  InputAdornment,
  AccordionDetails,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { Accordion as StyledAccordion } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import { Root } from "../../../stores";
import { differenceInYears } from "date-fns";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { modalTransitions } from "../../../components/modals/modalBase";
import { dataStorePageKeys } from "../../../stores/dataStore";
import { demand } from "../../../stores/pefaStore";
import { SOB, SOBFriendly } from "./FitnessTestDataKeys";
import { notTestedSelectKeys } from "../../../components/modals/NotTestedModal";
import { useHistory } from "react-router-dom";
import { DASHBOARD } from "../../../routes/paths";
import { getAppInsights } from "../../../components/telemetry";
import withAppInsights from "../AppInsights";

interface fitnessTestProps {
  rootStore?: Root;
}

const fitnessTest = inject("rootStore")(
  observer((props: fitnessTestProps) => {
    let pefaAssessment: any;
    let pA: any;
    let currentDemands: demand;
    let nextDemandPage: demand;
    let prevData: any;
    let referralDetails: any;
    let participantAgeYears = 0;
    let participantGender = "";
    let extraPressureTestRequired = false;
    let baselineMeasurements: any;
    let medicalHistoryData: any;
    let endAssessment: any;
    let isHighBPFlowTested = false;
    let is4thBPPopulated = false;
    const history = useHistory();
    const appInsights = getAppInsights();

    if (props.rootStore?.pefaStore.selectedPefa) {
      pefaAssessment = props.rootStore?.pefaStore.selectedPefa[0];
      pA = props.rootStore?.pefaStore.selectedPefa;
      currentDemands = props.rootStore?.pefaStore.selectedPefa.demands[dataStorePageKeys.FITNESS];
      baselineMeasurements = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.BASELINE_MEASUREMENTS);
      medicalHistoryData = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.MEDICAL_DETAILS);
      endAssessment = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.END_ASSESSMENT);
      isHighBPFlowTested = medicalHistoryData.highBloodPressureViewedMC === "ViewedAccepted" ? false : true;
      is4thBPPopulated = isHighBPFlowTested && Number(baselineMeasurements.bloodPressureDiastolic4) > 0 && Number(baselineMeasurements.bloodPressureSystolic4) > 0;
      // if 4th bp is populated and its low, skip PT as we already done it (from High BP flow)
      if (is4thBPPopulated) {
        nextDemandPage = props.rootStore?.pefaStore.getNextStepAfterFitnessHighBPFlow();
      } else {
        nextDemandPage = props.rootStore?.pefaStore.getNextPefaStep(currentDemands.functionalOrder);
      }

      prevData = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.FITNESS);
      extraPressureTestRequired = props.rootStore?.dataStore.isExtraPressureTestRequired(pefaAssessment.id);
      referralDetails = props.rootStore?.dataStore.getCheckInData(pefaAssessment.id, dataStorePageKeys.REFERRAL_DETAILS);
      participantAgeYears = differenceInYears(new Date(), new Date(referralDetails?.dob ?? pefaAssessment.pefArequest.worker.dateOfBirth));
      participantGender = referralDetails?.biologicalSex ?? pefaAssessment.pefArequest.worker.gender?.toUpperCase();
    } else {
      history.push(DASHBOARD);
      return <></>;
    }

    const bpDia = pA.bpDia || 145;
    const bpSys = pA.bpSys || 95;
    const { register, handleSubmit, errors, setValue } = useForm({
      resolver: yupResolver(fitnessTestValidation),
    });

    const [state, setState] = useState({
      isLoading: true,
      isMetronomeRunning: false,
      timeElapsedFormatted: "",
      timeElapsedRaw: prevData ? parseInt(prevData.timeElapsedRaw) : -1,
      timeRange: -1,
      isTimerStoppedModalOpen: false,
      timerStoppedReason: prevData ? prevData.timerStoppedReason : "",
      timerStoppedNotes: prevData ? prevData.timerStoppedNotes : "",
      recoveryModalOpen: false,
      recoveryTimeFormatted: "",
      yesAllNilSignificant: false,
      viewAll: false,
      preTestHeartRateModalOpen: false,
      currentHeartRate: "",
      fRating: prevData ? prevData.fRating : "",
      aRating: prevData ? prevData.aRating : "",
      toleranceRating: prevData ? prevData.toleranceRating : "",
      discomfortReported: false,

      bloodPressureModalOpen: prevData ? prevData.bloodPressureModalOpen : extraPressureTestRequired,
      bloodPressureModalLocked: prevData ? !!prevData.bloodPressureSystolic && !!prevData.bloodPressureDiastolic : false,
      bloodPressureSystolic: prevData ? prevData.bloodPressureSystolic : "",
      bloodPressureDiastolic: prevData ? prevData.bloodPressureDiastolic : "",
      furtherTestRequired: prevData ? prevData.furtherTestRequired : false,

      peeperPlaying: false,
      peeperEnabled: false,
      peeperInterval: 100,
      peeperRepeats: 2,

      remoteMetronomeVisible: prevData ? prevData.remoteMetronomeVisible : true,
      remoteTimerVisible: prevData ? prevData.remoteTimerVisible : true,
      isTimerStarted: false,
      timerRemoteTrigger: false,
      timerRemoteResumeTrigger: false,
      metronomeRemoteTrigger: false,

      preTestHeartRateModalLocked: prevData ? !!prevData.preTestHeartRate : false,

      postTestHeartRateModalOpen: prevData ? parseInt(prevData.timeElapsedRaw) >= 180 : false,
      showPostTestHeartRate: prevData ? !!prevData.postTestHeartRate : false,

      startingLeadLeg: prevData ? prevData.startingLeadLeg : true,
      leadLeg030: prevData ? prevData.leadLeg030 : true,
      leadLeg100: prevData ? prevData.leadLeg100 : true,
      leadLeg130: prevData ? prevData.leadLeg130 : true,
      leadLeg200: prevData ? prevData.leadLeg200 : true,
      leadLeg230: prevData ? prevData.leadLeg230 : true,
      leadLeg300: prevData ? prevData.leadLeg300 : true,

      fitnessTestSweatingNil: prevData ? prevData.fitnessTestSweatingNil : false,
      fitnessTestSweatingMild: prevData ? prevData.fitnessTestSweatingMild : false,
      fitnessTestSweatingMod: prevData ? prevData.fitnessTestSweatingMod : false,
      fitnessTestSweatingSignificant: prevData ? prevData.fitnessTestSweatingSignificant : false,
      fitnessHrRateRecoveringWell: prevData ? prevData.fitnessHrRateRecoveringWell : false,
      fitnessHrRateRecoveringSlowly: prevData ? prevData.fitnessHrRateRecoveringSlowly : false,
      fitnessHrRateNotRecovering: prevData ? prevData.fitnessHrRateNotRecovering : false,
      fitnessTestSkinColourNormal: prevData ? prevData.fitnessTestSkinColourNormal : false,
      fitnessTestSkinColourMildRedness: prevData ? prevData.fitnessTestSkinColourMildRedness : false,
      fitnessTestSkinColourSignificantRedness: prevData ? prevData.fitnessTestSkinColourSignificantRedness : false,

      fitnessBpm030: prevData ? prevData.fitnessBpm030 : "",
      fitnessSob030: prevData ? prevData.fitnessSob030 : SOB.SELECT,
      fitnessComment030: prevData ? prevData.fitnessComment030 : "",
      fitnessBpm100: prevData ? prevData.fitnessBpm100 : "",
      fitnessSob100: prevData ? prevData.fitnessSob100 : SOB.SELECT,
      fitnessComment100: prevData ? prevData.fitnessComment100 : "",
      fitnessBpm130: prevData ? prevData.fitnessBpm130 : "",
      fitnessSob130: prevData ? prevData.fitnessSob130 : SOB.SELECT,
      fitnessComment130: prevData ? prevData.fitnessComment130 : "",
      fitnessBpm200: prevData ? prevData.fitnessBpm200 : "",
      fitnessSob200: prevData ? prevData.fitnessSob200 : SOB.SELECT,
      fitnessComment200: prevData ? prevData.fitnessComment200 : "",
      fitnessBpm230: prevData ? prevData.fitnessBpm230 : "",
      fitnessSob230: prevData ? prevData.fitnessSob100 : SOB.SELECT,
      fitnessComment230: prevData ? prevData.fitnessComment230 : "",
      fitnessBpm300: prevData ? prevData.fitnessBpm300 : "",
      fitnessSob300: prevData ? prevData.fitnessSob300 : SOB.SELECT,
      fitnessComment300: prevData ? prevData.fitnessComment300 : "",

      preTestHeartRate: prevData ? prevData.preTestHeartRate : "",
      postRestHeartRate: prevData ? prevData.postRestHeartRate : "",
      postTestHeartRate: prevData ? prevData.postTestHeartRate : "",
      notTestedReason: prevData ? prevData.notTestedReason : "",
      notTestedNotes: prevData ? prevData.notTestedNotes : "",
    });

    const handleCheckboxChange = (event: any): void => {
      event.persist();
      setState((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.checked,
      }));
    };

    const handleValueChange = (event: any): void => {
      event.persist();
      setState((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
      }));
    };

    const handleLegChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();

      let { leadLeg030, leadLeg100, leadLeg130, leadLeg200, leadLeg230, leadLeg300 } = state;

      switch (event.target.name) {
        case "startingLeadLeg": {
          //Check if the test has begun. If not, set all the toggles to match the leading leg.
          if (state.timeRange === -1) {
            leadLeg030 = event.target.checked;
            leadLeg100 = event.target.checked;
            leadLeg130 = event.target.checked;
            leadLeg200 = event.target.checked;
            leadLeg230 = event.target.checked;
            leadLeg300 = event.target.checked;
          }
          break;
        }
        case "leadLeg030": {
          leadLeg030 = event.target.checked;
          leadLeg100 = event.target.checked;
          leadLeg130 = event.target.checked;
          leadLeg200 = event.target.checked;
          leadLeg230 = event.target.checked;
          leadLeg300 = event.target.checked;
          break;
        }
        case "leadLeg100": {
          leadLeg100 = event.target.checked;
          leadLeg130 = event.target.checked;
          leadLeg200 = event.target.checked;
          leadLeg230 = event.target.checked;
          leadLeg300 = event.target.checked;
          break;
        }
        case "leadLeg130": {
          leadLeg130 = event.target.checked;
          leadLeg200 = event.target.checked;
          leadLeg230 = event.target.checked;
          leadLeg300 = event.target.checked;
          break;
        }
        case "leadLeg200": {
          leadLeg200 = event.target.checked;
          leadLeg230 = event.target.checked;
          leadLeg300 = event.target.checked;
          break;
        }
        case "leadLeg230": {
          leadLeg230 = event.target.checked;
          leadLeg300 = event.target.checked;
          break;
        }
        case "leadLeg300": {
          leadLeg300 = event.target.checked;
          break;
        }
      }

      //Otherwise, we are simply changing the state of the control related to the event.

      setState((prevState) => ({
        ...prevState,
        leadLeg030,
        leadLeg100,
        leadLeg130,
        leadLeg200,
        leadLeg230,
        leadLeg300,
        [event.target.name]: event.target.checked,
      }));
    };

    useEffect(() => {
      const timeOutId = setTimeout(() => {
        // If user selects recovery timer display timer, if selects no rest time needed, carry on.
        if (!state.preTestHeartRateModalLocked && state.preTestHeartRate > 110) {
          setState((prevState) => ({
            ...prevState,
            preTestHeartRateModalOpen: true,
          }));
        }

        if (state.preTestHeartRateModalOpen && state.preTestHeartRate) {
          setState((prevState) => ({
            ...prevState,
            preTestHeartRateModalLocked: true,
          }));
        }
      }, 500);
      return () => clearTimeout(timeOutId);
    }, [state.preTestHeartRateModalOpen, state.preTestHeartRate, setValue, state.preTestHeartRateModalLocked]);

    const handleHeartRateChanged = (event: FocusEvent<HTMLInputElement>): void => {
      event.persist();
      setState((prevState) => ({
        ...prevState,
        currentHeartRate: event.target.value,
        [event.target.name]: event.target.value,
      }));
    };

    const handleRecoveryStopped = (recoveryTimeFormatted: string) => {
      if (state.timeRange === -1 && !state.timeElapsedFormatted) {
        setState((prevState) => ({
          ...prevState,
          recoveryModalOpen: false,
          recoveryTimeFormatted,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          recoveryModalOpen: false,
          postTestTimeFormatted: recoveryTimeFormatted,
          postTestHeartRateModalOpen: state.timeElapsedRaw >= 180,
          isTimerStoppedModalOpen: state.timeElapsedRaw < 180,
        }));
      }
    };

    const handlePostTestCloseModal = (postTestHeartRate: string): void => {
      setState((prevState) => ({
        ...prevState,
        postTestHeartRate,
        postTestHeartRateModalOpen: false,
        showPostTestHeartRate: true,
      }));
      if (state.aRating !== "I") {
        if (participantGender === "MALE") {
          if (parseInt(postTestHeartRate) < 71) {
            setState((prevState) => ({
              ...prevState,
              fRating: "Excellent",
              aRating: "X",
            }));
          } else if (parseInt(postTestHeartRate) >= 71 && parseInt(postTestHeartRate) <= 102) {
            setState((prevState) => ({
              ...prevState,
              fRating: "Good",
              aRating: "X",
            }));
          } else if (parseInt(postTestHeartRate) >= 103 && parseInt(postTestHeartRate) <= 117) {
            setState((prevState) => ({
              ...prevState,
              fRating: "Average",
              aRating: "F",
            }));
          } else if (parseInt(postTestHeartRate) >= 118 && parseInt(postTestHeartRate) <= 147) {
            setState((prevState) => ({
              ...prevState,
              fRating: "Fair",
              aRating: "F",
            }));
          } else {
            setState((prevState) => ({
              ...prevState,
              fRating: "Poor",
              aRating: "O",
            }));
          }
        } else {
          if (parseInt(postTestHeartRate) < 97) {
            setState((prevState) => ({
              ...prevState,
              fRating: "Excellent",
              aRating: "X",
            }));
          } else if (parseInt(postTestHeartRate) >= 97 && parseInt(postTestHeartRate) <= 127) {
            setState((prevState) => ({
              ...prevState,
              fRating: "Good",
              aRating: "X",
            }));
          } else if (parseInt(postTestHeartRate) >= 128 && parseInt(postTestHeartRate) <= 142) {
            setState((prevState) => ({
              ...prevState,
              fRating: "Average",
              aRating: "F",
            }));
          } else if (parseInt(postTestHeartRate) >= 143 && parseInt(postTestHeartRate) <= 171) {
            setState((prevState) => ({
              ...prevState,
              fRating: "Fair",
              aRating: "F",
            }));
          } else if (parseInt(postTestHeartRate) >= 172) {
            setState((prevState) => ({
              ...prevState,
              fRating: "Poor",
              aRating: "O",
            }));
          }
        }
      }
    };

    const handlePreTestCloseModal = (restRequired: boolean) => {
      setState((prevState) => ({
        ...prevState,
        preTestHeartRateModalOpen: false,
        recoveryModalOpen: restRequired,
      }));
    };

    const handleStoppedModal = (timeElapsed: number) => {
      setState((prevState) => ({
        ...prevState,
        isTimerRunning: false,
        recoveryModalOpen: true,
      }));
    };

    const handleTimerEvents = (timeElapsedRaw: number, timeElapsedFormatted: string): void => {
      let timeRange = -1;
      let peeperInterval = 0;
      let peeperRepeats = 0;
      let peeperEnabled = false;

      switch (Math.floor(timeElapsedRaw / 30)) {
        case 0:
          timeRange = 0;
          break;
        case 1:
          timeRange = 1;
          break;
        case 2:
          timeRange = 2;
          break;
        case 3:
          timeRange = 3;
          break;
        case 4:
          timeRange = 4;
          break;
        case 5:
          timeRange = 5;
          break;
      }

      if (timeRange > state.timeRange && timeRange > 0) {
        peeperInterval = 200;
        peeperRepeats = 2;
        peeperEnabled = true;
      } else if (timeElapsedRaw === 180) {
        peeperInterval = 100;
        peeperRepeats = 5;
        peeperEnabled = true;
      }

      setState((prevState) => ({
        ...prevState,
        timeRange,
        timeElapsedRaw,
        timeElapsedFormatted,
        timerRemoteTrigger: false,
        timerRemoteResumeTrigger: false,
        remoteTimerVisible: false,
        isTimerStarted: true,
        peeperInterval,
        peeperRepeats,
        peeperEnabled,
      }));
    };

    const handlePeeperCompleted = (): void => {
      setState((prevState) => ({ ...prevState, peeperPlaying: false }));
    };

    const handleTimerRemoteStart = (): void => {
      setState((prevState) => ({ ...prevState, timerRemoteTrigger: true }));
    };

    const handleTimerRemoteResume = (): void => {
      setState((prevState) => ({ ...prevState, timerRemoteResumeTrigger: true, recoveryModalOpen: false, metronomeRemoteTrigger: !prevState.metronomeRemoteTrigger }));
    };

    const handleMetronomeRemoteStart = (): void => {
      setState((prevState) => ({ ...prevState, metronomeRemoteTrigger: true }));
    };

    const handleTimerCompleted = (): void => {
      handleStoppedModal(state.timeElapsedRaw);
    };

    const handleTimerStopped = (): void => {
      setState((prevState) => ({
        ...prevState,
        metronomeRemoteTrigger: !prevState.metronomeRemoteTrigger,
      }));
      handleStoppedModal(state.timeElapsedRaw);
    };

    const handleStoppedRetestClicked = (): void => {
      setState((prevState) => ({
        ...prevState,
        metronomeRemoteTrigger: !prevState.metronomeRemoteTrigger,
        timerStoppedReason: "",
        timerStoppedNotes: "",
        isTimerStoppedModalOpen: false,
        timerRemoteTrigger: true,
        timeRange: -1,
      }));
    };

    const handleStoppedContinueClicked = (stoppedReason: string, stoppedNotes: string): void => {
      setState((prevState) => ({
        ...prevState,
        isTimerStoppedModalOpen: false,
        timerStoppedReason: stoppedReason,
        timerStoppedNotes: stoppedNotes,
        postTestHeartRateModalOpen: true,
        fRating: "Incomplete",
        aRating: "I",
      }));
    };

    const handleMetronomeStateChanged = (isRunning: boolean): void => {
      setState((prevState) => ({
        ...prevState,
        isMetronomeRunning: isRunning,
        remoteMetronomeVisible: false,
      }));
    };

    const discomfortReportedChange = (discomfortReported: boolean): void => {
      let { toleranceRating } = state;

      if (discomfortReported && toleranceRating === "X") {
        toleranceRating = "";
      }
      setState((prevState) => ({
        ...prevState,
        toleranceRating,
        discomfortReported,
      }));
    };

    const handleCloseBloodPressureModalPressure = (systolic: string, diastolic: string, furtherTestRequired: boolean) => {
      let notTestedReason = "";
      let notTestedNotes = "";

      if (furtherTestRequired) {
        notTestedReason = notTestedSelectKeys.MEDICAL_RESTRICTION;
        notTestedNotes = "Participant blood pressure above safe limit.";
      }

      setState((prevState) => ({
        ...prevState,
        bloodPressureModalOpen: false,
        bloodPressureSystolic: systolic,
        bloodPressureDiastolic: diastolic,
        furtherTestRequired,
        notTestedReason,
        notTestedNotes,
      }));
    };

    useEffect(() => {
      if (!state.peeperPlaying && state.peeperEnabled) {
        setState((prevState) => ({
          ...prevState,
          peeperPlaying: true,
        }));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.peeperInterval, state.peeperRepeats]);

    useEffect(() => {
      const timeout = setTimeout(() => {
        props.rootStore?.dataStore.savePartialData(state, pefaAssessment.id, dataStorePageKeys.FITNESS);
      }, 500);

      return () => clearTimeout(timeout);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      state.startingLeadLeg,
      state.leadLeg030,
      state.leadLeg100,
      state.leadLeg130,
      state.leadLeg200,
      state.leadLeg230,
      state.leadLeg300,
      state.fitnessTestSweatingNil,
      state.fitnessTestSweatingMild,
      state.fitnessTestSweatingMod,
      state.fitnessTestSweatingSignificant,
      state.fitnessHrRateRecoveringWell,
      state.fitnessHrRateRecoveringSlowly,
      state.fitnessHrRateNotRecovering,
      state.fitnessTestSkinColourNormal,
      state.fitnessTestSkinColourMildRedness,
      state.fitnessTestSkinColourSignificantRedness,
      state.fitnessBpm030,
      state.fitnessSob030,
      state.fitnessComment030,
      state.fitnessBpm100,
      state.fitnessSob100,
      state.fitnessComment100,
      state.fitnessBpm130,
      state.fitnessSob130,
      state.fitnessComment130,
      state.fitnessBpm200,
      state.fitnessSob200,
      state.fitnessComment200,
      state.fitnessBpm230,
      state.fitnessSob230,
      state.fitnessComment230,
      state.fitnessBpm300,
      state.fitnessSob300,
      state.fitnessComment300,
      state.preTestHeartRate,
      state.postRestHeartRate,
      state.postTestHeartRate,
      state.notTestedReason,
      state.notTestedNotes,
    ]);

    useEffect(() => {
      //If the participant's extra blood pressure reading has been taken, and the value is above the threshold still,
      //we want to mark the test as not-tested. We also don't want to mess with the form submission, so bloodPressureModalLocked
      //is acting as a blocker once the systolic and diastolic blood pressure values are set.
      if (!state.bloodPressureModalLocked && !!state.notTestedReason && (!!state.bloodPressureDiastolic || !!state.bloodPressureSystolic)) {
        submitForm(prevData, nextDemandPage.pageKey);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.notTestedReason, state.bloodPressureModalLocked, state.bloodPressureDiastolic, state.bloodPressureSystolic]);

    useEffect(() => {
      //Replicate the onUnmount event handler from class components.
      return () => {
        appInsights.trackEvent({ name: "intermediateSubmission", properties: { bookingId: pefaAssessment.id, dataKey: dataStorePageKeys.FITNESS, data: state } });
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submitForm = (data: any, forwardTarget: any) => {
      const {
        startingLeadLeg,
        leadLeg030,
        leadLeg100,
        leadLeg130,
        leadLeg200,
        leadLeg230,
        leadLeg300,
        notTestedNotes,
        notTestedReason,
        furtherTestRequired,
        bloodPressureDiastolic,
        bloodPressureSystolic,
      } = state;

      props.rootStore?.dataStore.saveData(
        {
          ...data,
          startingLeadLeg,
          leadLeg030,
          leadLeg100,
          leadLeg130,
          leadLeg200,
          leadLeg230,
          leadLeg300,
          notTestedNotes: data.notTestedNotes || notTestedNotes || "",
          notTestedReason: data.notTestedReason || notTestedReason || "",
          bloodPressureModalOpen: false,
          furtherTestRequired,
          bloodPressureDiastolic,
          bloodPressureSystolic,
        },
        pefaAssessment.id,
        dataStorePageKeys.FITNESS
      );
      const pefaData = { ...props.rootStore?.dataStore.pefaData[pefaAssessment.id] };
      // take snapshot of assessment and store against booking
      props.rootStore?.pefaStore.nextSubmit(pefaData, pefaAssessment.id);
      history.push(forwardTarget);
    };

    return (
      <div className="FormStyler">
        <TimerStopped
          currentTime={state.timeElapsedFormatted}
          isOpen={state.isTimerStoppedModalOpen}
          retestHandler={handleStoppedRetestClicked}
          continueHandler={handleStoppedContinueClicked}
        />
        <div className="AssessmentDetails">
          <Grid item container direction="column" xs={12}>
            <Stepper targetStep={"Fitness Test"} />
            <Container maxWidth={"lg"}>
              <Grid item container direction="row" xs={12}>
                <Grid item container xs={9}>
                  <Card className="titleCard" variant="outlined">
                    <a href="/docs/PEFA Instructions_MSK_Step Test.pdf" className="helpIcon" target="_blank">
                      Help
                    </a>
                    <Typography variant="h2" className="cardHeroHeading">
                      <img src={FitnessIcon} alt="Success" />
                      <span>3 min step test</span>
                    </Typography>
                    <Typography variant="subtitle2" className="subtitleHeading">
                      Aerobic Fitness
                    </Typography>
                    <div className="targetWeight">
                      <span className="targetWeightText">{currentDemands.frequency}</span>
                      <span className="percentage">Requirement</span>
                    </div>
                    <NotTested data={prevData} forwardTarget={nextDemandPage.pageKey} notTestedSaveHandler={submitForm} />
                    <input type="hidden" name="notTestedReason" ref={register} value={state.notTestedReason} />
                    <input type="hidden" name="notTestedNotes" ref={register} value={state.notTestedNotes} />
                    <input type="hidden" name="timerStoppedReason" ref={register} value={state.timerStoppedReason} />
                    <input type="hidden" name="timerStoppedNotes" ref={register} value={state.timerStoppedNotes} />
                    <input type="hidden" name="timeElapsedRaw" ref={register} value={state.timeElapsedRaw} />
                  </Card>
                  <Card className="fitnessTitleCard" variant="outlined">
                    <Typography variant="h3" className="cardHeroHeading">
                      <img src={Baseline} alt="Success" /> Pre-test information
                    </Typography>
                    <FormGroup aria-label="position" row>
                      <div className="flexerSpaceBetween">
                        <FormControlLabel
                          control={
                            <OutlinedInput
                              color="primary"
                              name="preTestHeartRate"
                              className="textInputFull"
                              autoComplete="off"
                              endAdornment={<InputAdornment position="end">bpm</InputAdornment>}
                              inputRef={register}
                              value={state.preTestHeartRate}
                              onChange={handleValueChange}
                              onBlur={handleHeartRateChanged}
                            />
                          }
                          label="Heart rate"
                          labelPlacement="start"
                          className="inputBoldBlue"
                        />
                        <FormControlLabel
                          control={<IOSSwitch checked={state.startingLeadLeg} onChange={handleLegChange} name="startingLeadLeg" />}
                          className="LR"
                          label="Starting lead leg"
                          labelPlacement="start"
                        />
                      </div>
                      <div style={{ marginLeft: "170px" }} className="invalidFeedback">
                        {errors.preTestHr?.message} <br />
                        {errors.postTestHr?.message} <br />
                      </div>
                    </FormGroup>

                    {state.recoveryTimeFormatted ? (
                      <FormGroup aria-label="position" row>
                        <div className="flexerSpaceBetween">
                          <FormControlLabel
                            control={
                              <OutlinedInput
                                color="primary"
                                name="postRestHeartRate"
                                inputRef={register}
                                value={state.postRestHeartRate}
                                onChange={handleValueChange}
                                className="textInputFull"
                                autoComplete="off"
                                endAdornment={<InputAdornment position="end">bpm</InputAdornment>}
                                onBlur={handleHeartRateChanged}
                              />
                            }
                            label=" Post rest HR"
                            labelPlacement="start"
                            className="inputBoldBlue"
                          />
                          <span className="preTestRate">Pre-Test rest time: {state.recoveryTimeFormatted}</span>
                        </div>
                        <div style={{ marginLeft: "170px" }} className="invalidFeedback">
                          {errors.postRestHr?.message} <br />
                        </div>
                      </FormGroup>
                    ) : (
                      <></>
                    )}
                  </Card>
                  <Grid item container xs={12}>
                    <form className="PefaForm">
                      <Peeper
                        intervalMs={state.peeperInterval}
                        isPlaying={state.peeperPlaying}
                        isEnabled={state.peeperEnabled}
                        repeats={state.peeperRepeats}
                        soundKey={3}
                        handlePeeperComplete={handlePeeperCompleted}
                      />
                      <RemoteStart configKey="METRONOME" disabled={!state.preTestHeartRate} visible={state.remoteMetronomeVisible} handleRemoteStart={handleMetronomeRemoteStart} />
                      <RemoteStart
                        configKey="TIMER"
                        disabled={!state.isMetronomeRunning}
                        visible={state.remoteTimerVisible}
                        isTimerStarted={state.isTimerStarted}
                        handleRemoteStart={handleTimerRemoteStart}
                        handleRemoteResume={handleTimerRemoteResume}
                      />
                      <StyledAccordion variant="outlined" className="innerOutlinedBox" defaultExpanded={true}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                          <Typography variant="subtitle1">Test recordings</Typography>
                          {!!errors.sec30?.message ||
                          !!errors.sec100?.message ||
                          !!errors.sec130?.message ||
                          !!errors.sec200?.message ||
                          !!errors.sec230?.message ||
                          !!errors.sec300?.message ? (
                            <ErrorOutline className="invalid-feedback" />
                          ) : (
                            <></>
                          )}
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="hrWrapper">
                            <div className={state.timeRange === 0 ? "hrCurrent" : ""}>
                              <Typography variant="caption" className="hrCountText">
                                0:30
                              </Typography>
                              <TextField
                                placeholder="BPM"
                                className="bpmInput"
                                name="fitnessBpm030"
                                variant="outlined"
                                InputLabelProps={{ shrink: false }}
                                onBlur={handleHeartRateChanged}
                                inputRef={register}
                                value={state.fitnessBpm030}
                                onChange={handleValueChange}
                              />
                              <Select
                                color="primary"
                                className={state.fitnessSob030 === SOB.SELECT ? "sobPlaceHolder" : "sobInput"}
                                variant="outlined"
                                value={state.fitnessSob030}
                                name="fitnessSob030"
                                onChange={(event) => {
                                  setState((prevState) => ({
                                    ...prevState,
                                    fitnessSob030: event.target.value,
                                  }));
                                }}
                              >
                                <MenuItem value={SOB.SELECT} disabled>
                                  {SOBFriendly.SELECT}
                                </MenuItem>
                                <MenuItem value={SOB.WNL}>{SOBFriendly.WNL}</MenuItem>
                                <MenuItem value={SOB.mild}>{SOBFriendly.mild}</MenuItem>
                                <MenuItem value={SOB.mod}>{SOBFriendly.mod}</MenuItem>
                                <MenuItem value={SOB.severe}>{SOBFriendly.severe}</MenuItem>
                              </Select>
                              <input
                                type="text"
                                style={{
                                  visibility: "hidden",
                                  display: "none",
                                }}
                                name="fitnessSob030"
                                value={state.fitnessSob030}
                                ref={register}
                              />
                              <TextField
                                placeholder="Enter comments here"
                                className="bpmComment"
                                name="fitnessComment030"
                                inputRef={register}
                                value={state.fitnessComment030}
                                variant="outlined"
                                InputLabelProps={{ shrink: false }}
                                onChange={handleValueChange}
                              />
                              <div className="LR">
                                <IOSSwitch checked={state.leadLeg030} onChange={handleLegChange} name="leadLeg030" />
                              </div>
                            </div>
                            <div style={{ marginLeft: "60px" }} className="invalidFeedback">
                              {errors.sec30?.message} <br />
                            </div>
                          </div>
                          <div className="hrWrapper">
                            <div className={state.timeRange === 1 ? "hrCurrent" : ""}>
                              <Typography variant="caption" className="hrCountText">
                                1:00
                              </Typography>
                              <TextField
                                placeholder="BPM"
                                className="bpmInput"
                                name="fitnessBpm100"
                                inputRef={register}
                                value={state.fitnessBpm100}
                                variant="outlined"
                                InputLabelProps={{ shrink: false }}
                                onChange={handleValueChange}
                                onBlur={handleHeartRateChanged}
                              />
                              <Select
                                color="primary"
                                value={state.fitnessSob100}
                                name="fitnessSob100"
                                className={state.fitnessSob100 === SOB.SELECT ? "sobPlaceHolder" : "sobInput"}
                                variant="outlined"
                                onChange={(event) => {
                                  setState((prevState) => ({
                                    ...prevState,
                                    fitnessSob100: event.target.value,
                                  }));
                                }}
                              >
                                <MenuItem value={SOB.SELECT} disabled>
                                  {SOBFriendly.SELECT}
                                </MenuItem>
                                <MenuItem value={SOB.WNL}>{SOBFriendly.WNL}</MenuItem>
                                <MenuItem value={SOB.mild}>{SOBFriendly.mild}</MenuItem>
                                <MenuItem value={SOB.mod}>{SOBFriendly.mod}</MenuItem>
                                <MenuItem value={SOB.severe}>{SOBFriendly.severe}</MenuItem>
                              </Select>
                              <input
                                type="text"
                                style={{
                                  visibility: "hidden",
                                  display: "none",
                                }}
                                name="fitnessSob100"
                                value={state.fitnessSob100}
                                ref={register}
                              />
                              <TextField
                                placeholder="Enter comments here"
                                className="bpmComment"
                                name="fitnessComment100"
                                inputRef={register}
                                value={state.fitnessComment100}
                                onChange={handleValueChange}
                                variant="outlined"
                                InputLabelProps={{ shrink: false }}
                              />
                              <div className="LR">
                                <IOSSwitch checked={state.leadLeg100} onChange={handleLegChange} name="leadLeg100" />
                              </div>
                            </div>
                            <div style={{ marginLeft: "60px" }} className="invalidFeedback">
                              {errors.sec100?.message} <br />
                            </div>
                          </div>
                          <div className="hrWrapper">
                            <div className={state.timeRange === 2 ? "hrCurrent" : ""}>
                              <Typography variant="caption" className="hrCountText">
                                1:30
                              </Typography>
                              <TextField
                                placeholder="BPM"
                                className="bpmInput"
                                name="fitnessBpm130"
                                inputRef={register}
                                value={state.fitnessBpm130}
                                variant="outlined"
                                InputLabelProps={{ shrink: false }}
                                onBlur={handleHeartRateChanged}
                                onChange={handleValueChange}
                              />
                              <Select
                                color="primary"
                                value={state.fitnessSob130}
                                name="fitnessSob130"
                                className={state.fitnessSob130 === SOB.SELECT ? "sobPlaceHolder" : "sobInput"}
                                variant="outlined"
                                onChange={(event) => {
                                  setState((prevState) => ({
                                    ...prevState,
                                    fitnessSob130: event.target.value,
                                  }));
                                }}
                              >
                                <MenuItem value={SOB.SELECT} disabled>
                                  {SOBFriendly.SELECT}
                                </MenuItem>
                                <MenuItem value={SOB.WNL}>{SOBFriendly.WNL}</MenuItem>
                                <MenuItem value={SOB.mild}>{SOBFriendly.mild}</MenuItem>
                                <MenuItem value={SOB.mod}>{SOBFriendly.mod}</MenuItem>
                                <MenuItem value={SOB.severe}>{SOBFriendly.severe}</MenuItem>
                              </Select>
                              <input
                                type="text"
                                style={{
                                  visibility: "hidden",
                                  display: "none",
                                }}
                                name="fitnessSob130"
                                value={state.fitnessSob130}
                                ref={register}
                              />
                              <TextField
                                placeholder="Enter comments here"
                                className="bpmComment"
                                name="fitnessComment130"
                                inputRef={register}
                                value={state.fitnessComment130}
                                variant="outlined"
                                InputLabelProps={{ shrink: false }}
                                onChange={handleValueChange}
                              />
                              <div className="LR">
                                <IOSSwitch checked={state.leadLeg130} onChange={handleLegChange} name="leadLeg130" />
                              </div>
                            </div>
                            <div style={{ marginLeft: "60px" }} className="invalidFeedback">
                              {errors.sec130?.message} <br />
                            </div>
                          </div>
                          <div
                            className="greyBox"
                            style={{
                              width: "90%",
                              textAlign: "center",
                              margin: "20px auto 20px auto",
                            }}
                          >
                            <Typography variant="body1">Please attempt to change Leading Leg if not already</Typography>
                          </div>
                          <div className="hrWrapper">
                            <div className={state.timeRange === 3 ? "hrCurrent" : ""}>
                              <Typography variant="caption" className="hrCountText">
                                2:00
                              </Typography>
                              <TextField
                                placeholder="BPM"
                                className="bpmInput"
                                name="fitnessBpm200"
                                inputRef={register}
                                value={state.fitnessBpm200}
                                variant="outlined"
                                InputLabelProps={{ shrink: false }}
                                onBlur={handleHeartRateChanged}
                                onChange={handleValueChange}
                              />
                              <Select
                                color="primary"
                                value={state.fitnessSob200}
                                name="fitnessSob200"
                                className={state.fitnessSob200 === SOB.SELECT ? "sobPlaceHolder" : "sobInput"}
                                variant="outlined"
                                onChange={(event) => {
                                  setState((prevState) => ({
                                    ...prevState,
                                    fitnessSob200: event.target.value,
                                  }));
                                }}
                              >
                                <MenuItem value={SOB.SELECT} disabled>
                                  {SOBFriendly.SELECT}
                                </MenuItem>
                                <MenuItem value={SOB.WNL}>{SOBFriendly.WNL}</MenuItem>
                                <MenuItem value={SOB.mild}>{SOBFriendly.mild}</MenuItem>
                                <MenuItem value={SOB.mod}>{SOBFriendly.mod}</MenuItem>
                                <MenuItem value={SOB.severe}>{SOBFriendly.severe}</MenuItem>
                              </Select>
                              <input
                                type="text"
                                style={{
                                  visibility: "hidden",
                                  display: "none",
                                }}
                                name="fitnessSob200"
                                value={state.fitnessSob200}
                                ref={register}
                              />
                              <TextField
                                placeholder="Enter comments here"
                                className="bpmComment"
                                name="fitnessComment200"
                                inputRef={register}
                                value={state.fitnessComment200}
                                variant="outlined"
                                InputLabelProps={{ shrink: false }}
                                onChange={handleValueChange}
                              />
                              <div className="LR">
                                <IOSSwitch checked={state.leadLeg200} onChange={handleLegChange} name="leadLeg200" />
                              </div>
                            </div>
                            <div style={{ marginLeft: "60px" }} className="invalidFeedback">
                              {errors.sec200?.message} <br />
                            </div>
                          </div>
                          <div className="hrWrapper">
                            <div className={state.timeRange === 4 ? "hrCurrent" : ""}>
                              <Typography variant="caption" className="hrCountText">
                                2:30
                              </Typography>
                              <TextField
                                placeholder="BPM"
                                className="bpmInput"
                                name="fitnessBpm230"
                                inputRef={register}
                                value={state.fitnessBpm230}
                                variant="outlined"
                                InputLabelProps={{ shrink: false }}
                                onBlur={handleHeartRateChanged}
                                onChange={handleValueChange}
                              />
                              <Select
                                color="primary"
                                value={state.fitnessSob230}
                                className={state.fitnessSob230 === SOB.SELECT ? "sobPlaceHolder" : "sobInput"}
                                variant="outlined"
                                onChange={(event) => {
                                  setState((prevState) => ({
                                    ...prevState,
                                    fitnessSob230: event.target.value,
                                  }));
                                }}
                              >
                                <MenuItem value={SOB.SELECT} disabled>
                                  {SOBFriendly.SELECT}
                                </MenuItem>
                                <MenuItem value={SOB.WNL}>{SOBFriendly.WNL}</MenuItem>
                                <MenuItem value={SOB.mild}>{SOBFriendly.mild}</MenuItem>
                                <MenuItem value={SOB.mod}>{SOBFriendly.mod}</MenuItem>
                                <MenuItem value={SOB.severe}>{SOBFriendly.severe}</MenuItem>
                              </Select>
                              <input
                                type="text"
                                style={{
                                  visibility: "hidden",
                                  display: "none",
                                }}
                                name="fitnessSob230"
                                value={state.fitnessSob230}
                                ref={register}
                              />
                              <TextField
                                placeholder="Enter comments here"
                                className="bpmComment"
                                name="fitnessComment230"
                                inputRef={register}
                                value={state.fitnessComment230}
                                variant="outlined"
                                InputLabelProps={{ shrink: false }}
                                onChange={handleValueChange}
                              />
                              <div className="LR">
                                <IOSSwitch checked={state.leadLeg230} onChange={handleLegChange} name="leadLeg230" />
                              </div>
                            </div>
                            <div style={{ marginLeft: "60px" }} className="invalidFeedback">
                              {errors.sec230?.message} <br />
                            </div>
                          </div>
                          <div className="hrWrapper">
                            <div className={state.timeRange === 5 ? "hrCurrent" : ""}>
                              <Typography variant="caption" className="hrCountText">
                                3:00
                              </Typography>
                              <TextField
                                placeholder="BPM"
                                className="bpmInput"
                                name="fitnessBpm300"
                                inputRef={register}
                                value={state.fitnessBpm300}
                                variant="outlined"
                                InputLabelProps={{ shrink: false }}
                                onBlur={handleHeartRateChanged}
                                onChange={handleValueChange}
                              />
                              <Select
                                color="primary"
                                value={state.fitnessSob300}
                                name="fitnessSob300"
                                className={state.fitnessSob300 === SOB.SELECT ? "sobPlaceHolder" : "sobInput"}
                                variant="outlined"
                                onChange={(event) => {
                                  setState((prevState) => ({
                                    ...prevState,
                                    fitnessSob300: event.target.value,
                                  }));
                                }}
                              >
                                <MenuItem value={SOB.SELECT} disabled>
                                  {SOBFriendly.SELECT}
                                </MenuItem>
                                <MenuItem value={SOB.WNL}>{SOBFriendly.WNL}</MenuItem>
                                <MenuItem value={SOB.mild}>{SOBFriendly.mild}</MenuItem>
                                <MenuItem value={SOB.mod}>{SOBFriendly.mod}</MenuItem>
                                <MenuItem value={SOB.severe}>{SOBFriendly.severe}</MenuItem>
                              </Select>
                              <input
                                type="text"
                                style={{
                                  visibility: "hidden",
                                  display: "none",
                                }}
                                name="fitnessSob300"
                                value={state.fitnessSob300}
                                ref={register}
                              />
                              <TextField
                                placeholder="Enter comments here"
                                className="bpmComment"
                                name="fitnessComment300"
                                inputRef={register}
                                value={state.fitnessComment300}
                                variant="outlined"
                                InputLabelProps={{ shrink: false }}
                                onChange={handleValueChange}
                              />
                              <div className="LR">
                                <IOSSwitch checked={state.leadLeg300} onChange={handleLegChange} name="leadLeg300" />
                              </div>
                            </div>
                            <div style={{ marginLeft: "60px" }} className="invalidFeedback">
                              {errors.sec300?.message} <br />
                            </div>
                          </div>
                        </AccordionDetails>
                      </StyledAccordion>

                      {!!state.showPostTestHeartRate ? (
                        <Card className="uCard" variant="outlined">
                          <Typography variant="h2" className="toleranceHeading">
                            <img src={StarIcon} alt="Success" />
                            Aerobic Fitness Rating
                          </Typography>
                          <FormGroup aria-label="position" row>
                            <div className="flexerSpaceBetween">
                              <FormControlLabel
                                control={
                                  <OutlinedInput
                                    color="primary"
                                    name="postTestHeartRate"
                                    className="textInputFull"
                                    autoComplete="off"
                                    defaultValue={state.postTestHeartRate}
                                    inputRef={register}
                                    endAdornment={<InputAdornment position="end">bpm</InputAdornment>}
                                    onChange={(event) => {
                                      event.persist();
                                      setState((prevState) => ({
                                        ...prevState,
                                        postTestHeartRate: event.target.value,
                                      }));
                                      handlePostTestCloseModal(event.target.value);
                                    }}
                                  />
                                }
                                label=" Post test HR"
                                labelPlacement="start"
                                className="inputBoldBlue"
                              />
                              <div className="fitnessRatingCalculation">
                                <span className="fitnessRating">
                                  {state.aRating}
                                  <input
                                    type="text"
                                    ref={register}
                                    name="aRating"
                                    value={state.aRating}
                                    style={{
                                      visibility: "hidden",
                                      display: "none",
                                    }}
                                  />
                                </span>
                                <span className="fitnessResult">
                                  {state.fRating}
                                  <input
                                    type="text"
                                    ref={register}
                                    name="fRating"
                                    value={state.fRating}
                                    style={{
                                      visibility: "hidden",
                                      display: "none",
                                    }}
                                  />
                                </span>
                                <span className="fitnessRatingText">Fitness Rating</span>
                              </div>
                            </div>
                            <div style={{ marginLeft: "170px" }} className="invalidFeedback">
                              {errors.postTestHr?.message} <br />
                            </div>
                          </FormGroup>
                        </Card>
                      ) : (
                        <></>
                      )}
                      <Card variant="outlined" className="toleranceOutlinedBox">
                        <Typography variant="h2" className="toleranceHeading">
                          <img src={StarIcon} alt="Success" />
                          Tolerance Rating
                        </Typography>
                        <RadioGroup className="toleranceRatingGroup" name="toleranceRating" value={state.toleranceRating} onChange={handleValueChange}>
                          <div className="flexer">
                            <FormControlLabel
                              control={<Radio color="primary" name="toleranceRating" value="N" />}
                              className="RadioAgreementTolerance"
                              inputRef={register}
                              label="N"
                            />
                            <FormControlLabel
                              control={<Radio color="primary" name="toleranceRating" value="O" />}
                              className="RadioAgreementTolerance"
                              inputRef={register}
                              label="O"
                              disabled={state.timeElapsedRaw <= 30}
                            />
                            <FormControlLabel
                              control={<Radio color="primary" name="toleranceRating" value="F" />}
                              className="RadioAgreementTolerance"
                              inputRef={register}
                              label="F"
                              disabled={state.timeElapsedRaw < 180}
                            />
                            <FormControlLabel
                              control={<Radio color="primary" name="toleranceRating" value="X" />}
                              className="RadioAgreementTolerance"
                              inputRef={register}
                              label="X"
                              disabled={state.timeElapsedRaw < 180 || !!state.discomfortReported}
                            />
                          </div>
                          <div style={{ marginLeft: "10px", marginBottom: "10px" }} className="invalidFeedback">
                            {errors.testNotPerformed?.message}
                            {errors.toleranceRating?.message} <br />
                          </div>
                        </RadioGroup>
                      </Card>
                      <StyledAccordion variant="outlined" className="innerOutlinedBox">
                        <AccordionSummary expandIcon={<ExpandMore />}>
                          <Typography variant="subtitle1">Assessor Observations</Typography>
                          {!!errors.aoHR?.message ? <ErrorOutline className="invalid-feedback" /> : <></>}
                        </AccordionSummary>
                        <AccordionDetails>
                          <FormControl component="fieldset">
                            <FormGroup>
                              <FormLabel component="legend">Heart Rate</FormLabel>
                              <div className="flexer">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="fitnessHrRateRecoveringWell"
                                      icon={<span className="checkboxIcon"></span>}
                                      checkedIcon={<span className="checkboxIconChecked"></span>}
                                      color="primary"
                                      inputRef={register}
                                      disabled={!!state.fitnessHrRateNotRecovering || !!state.fitnessHrRateRecoveringSlowly}
                                      onChange={(event) => handleCheckboxChange(event)}
                                      checked={state.fitnessHrRateRecoveringWell}
                                    />
                                  }
                                  label="Recovering well"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="fitnessHrRateRecoveringSlowly"
                                      icon={<span className="checkboxIcon"></span>}
                                      checkedIcon={<span className="checkboxIconChecked"></span>}
                                      color="primary"
                                      inputRef={register}
                                      disabled={!!state.fitnessHrRateNotRecovering || !!state.fitnessHrRateRecoveringWell}
                                      onChange={(event) => handleCheckboxChange(event)}
                                      checked={state.fitnessHrRateRecoveringSlowly}
                                    />
                                  }
                                  label="Recovering slowly"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="fitnessHrRateNotRecovering"
                                      id="oedemaSeveritySevere"
                                      icon={<span className="checkboxIcon"></span>}
                                      checkedIcon={<span className="checkboxIconChecked"></span>}
                                      color="primary"
                                      inputRef={register}
                                      disabled={!!state.fitnessHrRateRecoveringWell || !!state.fitnessHrRateRecoveringSlowly}
                                      onChange={(event) => handleCheckboxChange(event)}
                                      checked={state.fitnessHrRateNotRecovering}
                                    />
                                  }
                                  label="Not recovering (SEEK MEDICAL ATTENTION)"
                                />
                              </div>
                              <div style={{ marginLeft: "0px" }} className="invalidFeedback">
                                {errors.aoHR?.message} <br />
                              </div>
                            </FormGroup>
                            <FormGroup>
                              <FormLabel component="legend">Skin colour</FormLabel>
                              <div className="flexer">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="fitnessTestSkinColourNormal"
                                      icon={<span className="checkboxIcon"></span>}
                                      checkedIcon={<span className="checkboxIconChecked"></span>}
                                      color="primary"
                                      inputRef={register}
                                      onChange={(event) => handleCheckboxChange(event)}
                                      checked={state.fitnessTestSkinColourNormal}
                                    />
                                  }
                                  label="Normal"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="fitnessTestSkinColourMildRedness"
                                      icon={<span className="checkboxIcon"></span>}
                                      checkedIcon={<span className="checkboxIconChecked"></span>}
                                      color="primary"
                                      inputRef={register}
                                      onChange={(event) => handleCheckboxChange(event)}
                                      checked={state.fitnessTestSkinColourMildRedness}
                                    />
                                  }
                                  label="Mild redness"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="fitnessTestSkinColourSignificantRedness"
                                      icon={<span className="checkboxIcon"></span>}
                                      checkedIcon={<span className="checkboxIconChecked"></span>}
                                      color="primary"
                                      inputRef={register}
                                      onChange={(event) => handleCheckboxChange(event)}
                                      checked={state.fitnessTestSkinColourSignificantRedness}
                                    />
                                  }
                                  label="Significant redness"
                                />
                              </div>
                            </FormGroup>
                            <FormGroup>
                              <FormLabel component="legend">Sweating</FormLabel>
                              <div className="flexer">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="fitnessTestSweatingNil"
                                      icon={<span className="checkboxIcon"></span>}
                                      checkedIcon={<span className="checkboxIconChecked"></span>}
                                      color="primary"
                                      inputRef={register}
                                      onChange={(event) => handleCheckboxChange(event)}
                                      checked={state.fitnessTestSweatingNil}
                                    />
                                  }
                                  label="Nil"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="fitnessTestSweatingMild"
                                      icon={<span className="checkboxIcon"></span>}
                                      checkedIcon={<span className="checkboxIconChecked"></span>}
                                      color="primary"
                                      inputRef={register}
                                      onChange={(event) => handleCheckboxChange(event)}
                                      checked={state.fitnessTestSweatingMild}
                                    />
                                  }
                                  label="Mild"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="fitnessTestSweatingMod"
                                      icon={<span className="checkboxIcon"></span>}
                                      checkedIcon={<span className="checkboxIconChecked"></span>}
                                      color="primary"
                                      inputRef={register}
                                      onChange={(event) => handleCheckboxChange(event)}
                                      checked={state.fitnessTestSweatingMod}
                                    />
                                  }
                                  label="Mod"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="fitnessTestSweatingSignificant"
                                      icon={<span className="checkboxIcon"></span>}
                                      checkedIcon={<span className="checkboxIconChecked"></span>}
                                      color="primary"
                                      inputRef={register}
                                      onChange={(event) => handleCheckboxChange(event)}
                                      checked={state.fitnessTestSweatingSignificant}
                                    />
                                  }
                                  label="Significant"
                                />
                              </div>
                            </FormGroup>
                          </FormControl>
                        </AccordionDetails>
                      </StyledAccordion>
                      <ParticipantReports
                        datasetName="fitness"
                        register={register}
                        data={prevData}
                        errors={errors}
                        setValue={setValue}
                        assessmentID={pefaAssessment.id}
                        dataKey={dataStorePageKeys.FITNESS}
                        handleDiscomfortReported={discomfortReportedChange}
                      />
                      <Modal isOpen={state.preTestHeartRateModalOpen} transitionKey={modalTransitions.SLIDE_UP} handleClose={handlePreTestCloseModal}>
                        <div className="hbpModalText">
                          <Typography variant="h2" className="modalHeader">
                            High pre-test heart rate
                          </Typography>
                          <Typography variant="body2" className="modalText">
                            Does the participant need to rest before starting?
                          </Typography>
                          <div className="spacer">
                            <Button color="primary" variant="contained" fullWidth onClick={() => handlePreTestCloseModal(true)}>
                              Brief rest to lower heart rate
                            </Button>
                            <Button color="primary" variant="outlined" fullWidth className="retestBtn" onClick={() => handlePreTestCloseModal(false)}>
                              No rest time required
                            </Button>
                          </div>
                        </div>
                      </Modal>
                      <PostTestHeartRateModal isOpen={state.postTestHeartRateModalOpen} handlePostTestCloseModal={handlePostTestCloseModal} />
                      <HighBloodPressureModal
                        isOpen={state.bloodPressureModalOpen}
                        handleCloseModalPressure={handleCloseBloodPressureModalPressure}
                        bpDia={bpDia}
                        bpSys={bpSys}
                        pefaId={pefaAssessment.id}
                      />
                    </form>
                  </Grid>
                </Grid>
                <Grid item container xs={3} className="RightCol">
                  <ProfileCard
                    firstName={pefaAssessment.pefArequest.worker.firstName}
                    lastName={pefaAssessment.pefArequest.worker.lastName}
                    participantAgeYears={participantAgeYears}
                    currentHeartRate={state.currentHeartRate}
                  />
                  <Timer
                    countUp={true}
                    timeTarget={"10:00"}
                    timerHandler={handleTimerEvents}
                    timerStoppedHandler={handleTimerStopped}
                    timerCompletedHandler={handleTimerCompleted}
                    timerRemoteResumeTrigger={state.timerRemoteResumeTrigger}
                    timerRemoteTrigger={state.timerRemoteTrigger}
                    isMetronomeRunning={state.isMetronomeRunning}
                    isRecoveryTimerRunning={state.recoveryModalOpen}
                  />
                  <Metronome bpm={96} isPlayingHandler={handleMetronomeStateChanged} metronomeRemoteTrigger={state.metronomeRemoteTrigger} />
                  <NotesModal pageKey={dataStorePageKeys.FITNESS} pefaID={pefaAssessment.id} />
                  <InTestMedicalClearanceModal
                    firstName={referralDetails.firstName}
                    lastName={referralDetails.lastName}
                    pageKey={dataStorePageKeys.FITNESS}
                    pefaID={pefaAssessment.id}
                    medicalPractice={medicalHistoryData.medicalPractitioner}
                    medicalPractitioner={medicalHistoryData.medicalPractice}
                  />
                </Grid>
              </Grid>
            </Container>
            <FormNavigator
              forwardTarget={nextDemandPage.pageKey}
              handleSubmit={handleSubmit(submitForm)}
              warning="Stop the test if HR reaches 85% MHR, they have pain or other signs of cardiac distress"
              disabledNextButton={
                endAssessment &&
                endAssessment.endAssessmentReason !== "" &&
                endAssessment.endAssessmentNotes !== "" &&
                endAssessment.endAssessmentPage === dataStorePageKeys.FITNESS
              }
              selectedKey={dataStorePageKeys.FITNESS}
            />
          </Grid>
        </div>
        <DrawerTimer
          isTimerOpen={state.recoveryModalOpen}
          countUp={true}
          timeTarget={"1:00"}
          timerCompletedHandler={handleRecoveryStopped}
          timerStopHandler={state.timeRange === -1 && !state.timeElapsedFormatted ? handleRecoveryStopped : null}
          remoteResumeHandler={handleTimerRemoteResume}
        />
      </div>
    );
  })
);

export default withAppInsights(fitnessTest);

import React, { Component, ReactElement, ReactNode } from "react";
import { Modal, Backdrop, Fade, Button, Slide, Select, TextField, Typography, MenuItem } from "@material-ui/core";
import { ChangeEvent } from "react";

interface ModalProps {
  isOpen: boolean;
  handleStoppedCloseModal: any;
  stoppedKey?: string;
  stoppedText?: string;
}

interface ModalState {
  noteText: string;
  stoppedPlaceholder: string;
  stoppedReasonKey: string;
  stoppedReason: string;
  stoppedOptions: any[];
}

const assessorStoppedOptions: {
  [key: string]: { key: string; value: string; placeholder: string };
} = {
  PARTICIPANT_DECLINED: {
    key: "PARTICIPANT_DECLINED",
    value: "Participant declined",
    placeholder: "Please list the participant response",
  },
  ASSESSOR_DECISION: {
    key: "  ASSESSOR_DECISION",
    value: "Assessor decision",
    placeholder: "Please describe what you saw and heard",
  },
  MEDICAL_RESTRICTIONS: {
    key: "  MEDICAL_RESTRICTIONS",
    value: "Medical restrictions",
    placeholder: "Please list",
  },
  EQUIPMENT_FAILURE: {
    key: "  EQUIPMENT_FAILURE",
    value: "Equipment Failure",
    placeholder: "Please describe what happened",
  },
};

class assessorStoppedModal extends Component<ModalProps, ModalState> {
  constructor(props: ModalProps) {
    super(props);

    this.state = {
      noteText: this.props.stoppedText ? this.props.stoppedText : "",
      stoppedPlaceholder: "",
      stoppedReason: this.props.stoppedKey ? assessorStoppedOptions[this.props.stoppedKey].value : assessorStoppedOptions.PARTICIPANT_DECLINED.value,
      stoppedOptions: [] as any[],
      stoppedReasonKey: this.props.stoppedKey ? this.props.stoppedKey : "",
    };

    this.populateAssessorStoppedOptions = this.populateAssessorStoppedOptions.bind(this);
    this.handleAssessorStoppedChanged = this.handleAssessorStoppedChanged.bind(this);
    this.handleReasonChanged = this.handleReasonChanged.bind(this);
  }

  componentDidMount(): void {
    this.populateAssessorStoppedOptions();
  }

  handleAssessorStoppedChanged(event: ChangeEvent<any>): void {
    event.persist();
    const friendlyOption = assessorStoppedOptions[event.target.value].value;
    const placeholderText = assessorStoppedOptions[event.target.value].placeholder;
    this.setState((prevState) => ({
      ...prevState,
      stoppedReasonKey: event.target.value,
      stoppedReason: friendlyOption,
      stoppedPlaceholder: placeholderText,
    }));
  }

  populateAssessorStoppedOptions(): void {
    const assessorStoppedOptionKeys = Object.keys(assessorStoppedOptions);
    const stoppedOptions: any[] = [];

    assessorStoppedOptionKeys.forEach((optionKey) => {
      stoppedOptions.push(<MenuItem value={optionKey}>{assessorStoppedOptions[optionKey].value}</MenuItem>);
    });

    if (this.state.stoppedOptions.length !== stoppedOptions.length) {
      this.setState((prevState) => ({ ...prevState, stoppedOptions }));
    }
  }

  handleReasonChanged(event: ChangeEvent<any>): void {
    event.persist();
    this.setState((prevState) => ({
      ...prevState,
      noteText: event.target.value,
    }));
  }

  render(): ReactNode {
    return (
      <div className="notTestedHeader">
        <Modal
          aria-labelledby="modal-title"
          open={this.props.isOpen}
          onClose={this.props.handleStoppedCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Slide direction="up" in={this.props.isOpen} mountOnEnter unmountOnExit>
            <div className="modalPop">
              {" "}
              <div className="notesModalText">
                <Typography variant="h2" className="modalHeader">
                  Assessor Stopped
                </Typography>
                <Typography variant="body2" className="modalText">
                  Please advise why this activity was stopped
                </Typography>
                <div className="spacer">
                  <Select
                    color="primary"
                    className="mhLongSelect"
                    variant="outlined"
                    name="assessorStopped"
                    value={this.state.stoppedReasonKey}
                    onChange={(event) => this.handleAssessorStoppedChanged(event)}
                  >
                    {this.state.stoppedOptions}
                  </Select>
                </div>
                <div className="spacer">
                  <TextField
                    id="outlined-multiline-static"
                    multiline
                    rows={4}
                    placeholder={this.state.stoppedPlaceholder}
                    className="mhtextInputFull"
                    name="stoppedMessage"
                    variant="outlined"
                    InputLabelProps={{ shrink: false }}
                    value={this.state.noteText}
                    onChange={(event) => this.handleReasonChanged(event)}
                  />
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    this.props.handleStoppedCloseModal(this.state.noteText, this.state.stoppedReasonKey, this.state.stoppedReason);
                  }}
                >
                  Save and next
                </Button>
              </div>
            </div>
          </Slide>
        </Modal>
      </div>
    );
  }
}

export default assessorStoppedModal;

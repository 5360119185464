import React, { useEffect, useState } from "react";
import { ProfileCard, FormNavigator, Stepper, LoadingIndicator, PerformanceSummary } from "../../../components/";
import { Success } from "../../../assets/images/";
import { Root } from "../../../stores";
import pefaFinalisationSchema from "./validation";
import { dataStorePageKeys } from "../../../stores/dataStore";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { differenceInYears } from "date-fns";
import { inject, observer } from "mobx-react";
import { Grid, Typography, FormControl, Card, FormGroup, FormControlLabel, Container, RadioGroup, Radio, TextField } from "@material-ui/core";
import { useAuth } from "oidc-react";
import { APPRATING, DASHBOARD, LOGIN, PEFASENT } from "../../../routes/paths";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { tokenInterceptor } from "../../../services/auth/tokenInterceptor";
import { getAppInsights } from "../../../components/telemetry";
import withAppInsights from "../AppInsights";

interface PefaFinalisationProps {
  rootStore?: Root;
}

const PefaFinalisation = inject("rootStore")(
  observer((props: PefaFinalisationProps) => {
    const auth = useAuth();
    const history = useHistory();
    const appInsights = getAppInsights();
    tokenInterceptor(axios, null, auth);

    let pefaAssessment: any;
    let prevData: any = null;
    let referralDetails: any = null;
    let participantAgeYears = 0;
    let demands: any;
    let userName: any;
    let userProfession: any;
    let userAssessorNumber: any;
    let endAssessment: any;

    if (props.rootStore?.pefaStore.selectedPefa) {
      userName = props.rootStore?.userStore.getUserNameFormatted;
      userProfession = props.rootStore?.userStore.getUserProfession;
      userAssessorNumber = props.rootStore?.userStore.getUserAssessorNumber;
      pefaAssessment = props.rootStore?.pefaStore.selectedPefa[0];
      demands = props.rootStore?.pefaStore.selectedPefa.demands;
      if (props.rootStore?.dataStore.pefaData.hasOwnProperty(pefaAssessment.id)) {
        prevData = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.FINALISATION);
        referralDetails = props.rootStore?.dataStore.getCheckInData(pefaAssessment.id, dataStorePageKeys.REFERRAL_DETAILS);
        participantAgeYears = differenceInYears(new Date(), new Date(referralDetails?.dob ?? pefaAssessment.pefArequest.worker.dateOfBirth));
        endAssessment = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.END_ASSESSMENT);
      } else {
        participantAgeYears = differenceInYears(new Date(), new Date(pefaAssessment.pefArequest.worker.dateOfBirth));
      }
    } else {
      history.push(DASHBOARD);
      return <></>;
    }

    const { register, handleSubmit, errors } = useForm({
      resolver: yupResolver(pefaFinalisationSchema),
    });

    const [state, setState] = useState({
      isLoading: true,
      jobFitSystemScore: endAssessment && endAssessment.endAssessmentReason !== "" && endAssessment.endAssessmentNotes !== "" ? "4" : prevData ? prevData.jobFitSystemScore : "",
      minimalRestrictionsDescription: prevData ? prevData.minimalRestrictionsDescription : "",
      moderateRestrictionsDescription: prevData ? prevData.moderateRestrictionsDescription : "",

      userProfession: prevData ? (!!prevData.userProfession ? prevData.userProfession : userProfession) : userProfession,
      userAssessorNumber: prevData ? (!!prevData.userAssessorNumber ? prevData.userAssessorNumber : userAssessorNumber) : userAssessorNumber,
      userName: prevData ? (!!prevData.userName ? prevData.userName : userName) : userName,
      month: new Date().toLocaleString("default", { month: "short" }),
      day: new Date().getDate(),
      year: new Date().getFullYear(),
      bookingID: pefaAssessment.bookingID,
      isSubmitting: false,
    });

    const handleValueChange = (event: any): void => {
      event.persist();
      setState((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
      }));
    };

    useEffect(() => {
      const timeout = setTimeout(() => {
        props.rootStore?.dataStore.savePartialData(state, pefaAssessment.id, dataStorePageKeys.FINALISATION);
      }, 500);

      return () => clearTimeout(timeout);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      state.jobFitSystemScore,
      state.minimalRestrictionsDescription,
      state.moderateRestrictionsDescription,
      state.userProfession,
      state.userAssessorNumber,
      state.userName,
      state.month,
      state.day,
      state.year,
    ]);

    useEffect(() => {
      //Replicate the onUnmount event handler from class components.
      return () => {
        appInsights.trackEvent({ name: "intermediateSubmission", properties: { bookingId: pefaAssessment.id, dataKey: dataStorePageKeys.FINALISATION, data: state } });
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submitForm = async (data: any, forwardTarget: any) => {
      props.rootStore?.dataStore.saveData(data, pefaAssessment.id, dataStorePageKeys.FINALISATION);
      // take snapshot of assessment and store against booking
      const pefaData = { ...props.rootStore?.dataStore.pefaData[pefaAssessment.id] };
      props.rootStore?.pefaStore.nextSubmit(pefaData, pefaAssessment.id);

      if (auth) {
        if (!!auth.userData) {
          setState((prevState) => ({ ...prevState, isSubmitting: true }));

          const isIncomplete = endAssessment ? (endAssessment.endAssessmentReason !== "" && endAssessment.endAssessmentNotes !== "" ? true : false) : false;
          props.rootStore?.pefaStore
            .submitPefa(props.rootStore?.dataStore.pefaData[pefaAssessment.id], props.rootStore?.dataStore.pefaNotes[pefaAssessment.id], pefaAssessment.id, demands, !isIncomplete)
            .then((response) => {
              if (response.toString() === "200") {
                setState((prevState) => ({
                  ...prevState,
                  isSubmitting: false,
                }));
                history.push(PEFASENT);
              } else {
                console.error("error submitting PEFA: ", response);
                setState((prevState) => ({
                  ...prevState,
                  isSubmitting: false,
                }));
              }
            });
        }
      } else {
        history.push(LOGIN);
      }
    };

    return (
      <div className="FormStyler">
        <div className="AssessmentDetails">
          <Grid item container direction="column" xs={12}>
            <Stepper targetStep={"Preview & Recomendations"} />
            <Container maxWidth={"lg"}>
              <Grid item container xs={12} direction="row">
                <Grid item container xs={9}>
                  <form>
                    <Card className="uCard" variant="outlined">
                      <Typography variant="h2" className="heroHeading">
                        <img src={Success} alt="Success" /> PEFA Score &amp; Confirmation
                      </Typography>
                    </Card>
                    <PerformanceSummary assessmentId={pefaAssessment.id} />
                    <Card className="uCard" variant="outlined">
                      <Typography variant="h3" className="minorHeading">
                        In my professional opinion, and based on the job demands provided, the participant has demonstrated a JobFit System PEFA Score of:
                      </Typography>
                      <RadioGroup name="jobFitSystemScore" value={state.jobFitSystemScore} onChange={handleValueChange}>
                        <FormControlLabel
                          control={<Radio color="primary" name="jobFitSystemScore" id="jobfitSystemScore1" value="1" />}
                          inputRef={register}
                          className="RadioAgreement"
                          label="1. Has demonstrated the functional capacity to perform the proposed position as described with no restrictions"
                          disabled={endAssessment && endAssessment.endAssessmentReason !== "" && endAssessment.endAssessmentNotes}
                        />
                        <FormControlLabel
                          control={<Radio color="primary" name="jobFitSystemScore" id="jobfitSystemScore2" value="2" />}
                          inputRef={register}
                          className="RadioAgreement"
                          label="2. Has demonstrated the functional capacity to perform the proposed position as described with minimal restrictions (describe)"
                          disabled={endAssessment && endAssessment.endAssessmentReason !== "" && endAssessment.endAssessmentNotes}
                        />
                        {state.jobFitSystemScore === "2" ? (
                          <TextField
                            name="minimalRestrictionsDescription"
                            variant="outlined"
                            className="outlineTextAreaFinal"
                            placeholder="Reason for score"
                            inputRef={register}
                            defaultValue={state.minimalRestrictionsDescription}
                            onChange={handleValueChange}
                          />
                        ) : (
                          <></>
                        )}
                        <FormControlLabel
                          control={<Radio color="primary" name="jobFitSystemScore" id="jobfitSystemScore3" value="3" />}
                          inputRef={register}
                          className="RadioAgreement"
                          label="3. Has demonstrated the functional capacity to perform the proposed position as described with moderate restrictions (describe)"
                          disabled={endAssessment && endAssessment.endAssessmentReason !== "" && endAssessment.endAssessmentNotes}
                        />
                        {state.jobFitSystemScore === "3" ? (
                          <TextField
                            name="moderateRestrictionsDescription"
                            variant="outlined"
                            className="outlineTextAreaFinal"
                            placeholder="Reason for score"
                            inputRef={register}
                            defaultValue={state.moderateRestrictionsDescription}
                            onChange={handleValueChange}
                          />
                        ) : (
                          <></>
                        )}
                        <FormControlLabel
                          control={<Radio color="primary" name="jobFitSystemScore" id="jobfitSystemScore4" value="4" />}
                          inputRef={register}
                          className="RadioAgreement"
                          label="4. Has not demonstrated the functional capacity to meet the inherent requirements of the proposed position as described."
                          disabled={endAssessment && endAssessment.endAssessmentReason !== "" && endAssessment.endAssessmentNotes}
                        />
                      </RadioGroup>
                      <div className="invalid-feedback">
                        {errors.jobFitSystemScore?.message}
                        {errors.minimalRestrictionsDescription?.message}
                        {errors.moderateRestrictionsDescription?.message}
                      </div>
                      <Typography variant="body1" style={{ marginBottom: "20px", marginTop: "0px" }}>
                        I understand that these results will go through a Quality Review process with JobFit Systems and that the final PEFA score may be adjusted following that
                        review.
                      </Typography>
                    </Card>
                    <Card className="uCard" variant="outlined">
                      <Typography className="minorHeading" variant="h3">
                        I <span>{state.userName}.</span>
                        {", "}
                        confirm that:
                      </Typography>
                      <Typography className="agreementText" variant="body1">
                        a) I have conducted all components of this JobFit System PEFA
                      </Typography>
                      <Typography className="agreementText" variant="body1">
                        b) Unless otherwise documented, I have conducted the JobFit System PEFA to protocol
                      </Typography>
                      <Typography className="agreementText" variant="body1">
                        c) I have documented, and advised JobFit Systems International of any adverse events that occurred during the conduct of this PEFA
                      </Typography>
                      <Typography className="agreementText" variant="body1">
                        d) All information recorded on these JobFit System PEFA results are true and accurate to the best of my knowledge.
                      </Typography>
                      <Typography className="agreementCaption" variant="caption">
                        Assessor Details
                      </Typography>
                      <FormControl component="fieldset" style={{ width: "100%" }}>
                        <FormGroup className="commentsField">
                          <div className="flexer">
                            <TextField
                              label="Full legal name"
                              name="userName"
                              variant="outlined"
                              className="outlineTextAreaFinal"
                              value={state.userName}
                              onChange={handleValueChange}
                              inputRef={register}
                            />
                            <TextField
                              label="Profession"
                              name="userProfession"
                              variant="outlined"
                              className="outlineTextAreaFinal"
                              value={state.userProfession}
                              inputRef={register}
                              onChange={handleValueChange}
                            />
                            <TextField
                              label="Assessor Number"
                              name="userAssessorNumber"
                              variant="outlined"
                              className="outlineTextAreaFinal"
                              value={state.userAssessorNumber}
                              inputRef={register}
                              onChange={handleValueChange}
                            />
                          </div>
                        </FormGroup>
                        <FormGroup className="noGrowField">
                          <TextField label="Day" name="day" variant="outlined" className="outlineTextAreaSm" value={state.day} inputRef={register} onChange={handleValueChange} />
                          <TextField
                            label="Month"
                            name="month"
                            variant="outlined"
                            className="outlineTextAreaSm"
                            value={state.month}
                            inputRef={register}
                            onChange={handleValueChange}
                          />
                          <TextField
                            label="Year"
                            name="year"
                            variant="outlined"
                            className="outlineTextAreaSm"
                            value={state.year}
                            inputRef={register}
                            onChange={handleValueChange}
                          />
                        </FormGroup>
                      </FormControl>
                    </Card>
                  </form>
                </Grid>
                <Grid item container xs={3} className="RightCol">
                  <ProfileCard
                    firstName={pefaAssessment.pefArequest.worker.firstName}
                    lastName={pefaAssessment.pefArequest.worker.lastName}
                    participantAgeYears={participantAgeYears}
                  />
                </Grid>
              </Grid>
            </Container>
            <FormNavigator forwardTarget={PEFASENT} handleSubmit={handleSubmit(submitForm)} forwardText="Submit &amp; Finish" showEndButton={false} selectedKey="" />
          </Grid>
          <LoadingIndicator visible={state.isSubmitting} />
        </div>
      </div>
    );
  })
);

export default withAppInsights(PefaFinalisation);

import { keyPairData, resultTimeKeys } from ".";
import { SOBFriendly, SOB } from "../../views/app/FitnessTest/FitnessTestDataKeys";

export default class fitnessResult {
  public static formatForSubmission(fitnessResult: { [key: string]: any }): keyPairData {
    const recordings: keyPairData[] = [];
    const observations: keyPairData[] = [];

    let discomfort: keyPairData = {};

    // Recordings
    resultTimeKeys.forEach((timeKey: keyPairData) => {
      const recording = {
        time: timeKey.value,
        heartRate: fitnessResult[`fitnessBpm${timeKey.key}`],
        sob: SOBFriendly[fitnessResult[`fitnessSob${timeKey.key}`] as SOB],
        comment: fitnessResult[`fitnessComment${timeKey.key}`],
        leadingLeg: fitnessResult[`leadLeg${timeKey.key}`] ? "Left" : "Right",
      };
      recordings.push(recording);
    });

    // Observations
    let heartRate = "";
    let skinColour = "";
    let sweating = "";

    if (fitnessResult["fitnessHrRateNotRecovering"]) {
      heartRate = "Not recovering";
    } else if (fitnessResult["fitnessHrRateRecoveringSlowly"]) {
      heartRate = "Recovering slowly";
    } else if (fitnessResult["fitnessHrRateRecoveringWell"]) {
      heartRate = "Recovering well";
    }

    if (fitnessResult["fitnessTestSkinColourSignificantRedness"]) {
      skinColour = "Significant redness";
    } else if (fitnessResult["fitnessTestSkinColourMildRedness"]) {
      skinColour = "Mild redness";
    } else if (fitnessResult["fitnessTestSkinColourNormal"]) {
      skinColour = "Normal";
    }

    if (fitnessResult["fitnessTestSweatingSignificant"]) {
      sweating = "Significant";
    } else if (fitnessResult["fitnessTestSweatingMod"]) {
      sweating = "Moderate";
    } else if (fitnessResult["fitnessTestSweatingMild"]) {
      sweating = "Mild";
    } else if (fitnessResult["fitnessTestSweatingNil"]) {
      sweating = "Nil";
    }

    observations.push({ name: "heartRate", value: heartRate });
    observations.push({ name: "skinColour", value: skinColour });
    observations.push({ name: "sweating", value: sweating });

    // Discomfort
    if (fitnessResult["fitness_reportedDiscomfort"]) {
      let intensity = "";
      let status = "";
      const type: string[] = [];

      //prepare the intensity
      if (!!fitnessResult["fitness_intensityVas"]) {
        intensity = fitnessResult["fitness_intensityVas"];
      } else if (fitnessResult["fitness_intensityMild"]) {
        intensity = "Mild";
      } else if (fitnessResult["fitness_intensityMod"]) {
        intensity = "Moderate";
      } else if (fitnessResult["fitness_intensitySevere"]) {
        intensity = "Severe";
      }

      //prepare the status
      if (fitnessResult["fitness_discomfortResolvedImmediately"]) {
        status = "Resolved immediately";
      } else if (fitnessResult["fitness_discomfortPersisting"]) {
        status = "Persisting";
      } else if (fitnessResult["fitness_discomfortResolvedWithRest"]) {
        status = "Resolved with rest";
      } else if (fitnessResult["fitness_discomfortIncreasing"]) {
        status = "Increasing";
      }

      //prepare the type
      if (fitnessResult["fitness_discomfortTypeAche"]) {
        type.push("Ache");
      } else if (fitnessResult["fitness_discomfortTypeSharp"]) {
        type.push("Sharp");
      } else if (fitnessResult["fitness_discomfortTypeBurning"]) {
        type.push("Burning");
      } else if (fitnessResult["fitness_discomfortTypeTightness"]) {
        type.push("Tightness");
      } else if (fitnessResult["fitness_discomfortTypePulling"]) {
        type.push("Pulling");
      } else if (fitnessResult["fitness_discomfortTypeOther"]) {
        type.push("Other");
      }

      discomfort = {
        location: fitnessResult["fitness_location"],
        intensity,
        type,
        status,
      };
    }

    let formattedData: keyPairData = {
      leadingLeg: fitnessResult["startingLeadLeg"] ? "Left" : "Right",
      startingHeartRate: fitnessResult["preTestHeartRate"],
      endHeartRate: fitnessResult["postTestHeartRate"],
      ratingResult: fitnessResult["toleranceRating"],
      recordings,
      observations,
      discomfort,
    };

    if (!!fitnessResult["postRestHeartRate"]) {
      formattedData = {
        ...formattedData,
        recoveryHeartRate: fitnessResult["postRestHeartRate"],
      };
    }

    return formattedData;
  }
}

import cryptojs from "crypto-js";
import { Constants } from "../helpers/Constants";

/**
 * @description an interface that allows us to inject new properties into a data object within typescript.
 */
interface LooseObject {
  [key: string]: any;
}

const LocalStoreService = () => {
  /**
   * @description Retrieves the state values from local storage using the provided key. \
   * NOTE: either the pefaID and/or storeKey value must be provided. Neither value being provided will return an
   * empty object.
   *
   * @param storeKey (Optional) The key used to retrieve the state from local storage.
   * @param pefaID (Optional) The pefa specific identifier.
   *
   * @example "Retrieving the MST object: Provide only the storeKey with the MST key constant."
   * @example "Retrieving form data for a given PEFA: Provide the storeKey for the page and the unique PEFA ID."
   */
  const getData = async (storeKey?: string, pefaID?: string): Promise<any | null> => {
    let data = "";

    try {
      let dataKey: string;

      //If a PEFA id has been provided, use it to retrieve the "parent" PEFA store.
      //Otherwise, use the store key to retrieve an object.
      if (pefaID) {
        dataKey = pefaID;
      } else if (storeKey) {
        dataKey = storeKey;
      } else {
        return data;
      }

      //Get the data from local storage using the given data key, then parse the JSON string.
      const rawState = await getSecureLocalData(dataKey);
      if (rawState) {
        data = JSON.parse(rawState);
      }

      //Confirming if both a store key and a PEFA id were provided,
      //get the data from the "parent" PEFA store object that matches the given key.
      if (pefaID && storeKey) {
        data = data[storeKey];
      }
    } catch (error) {
      console.error("LocalStoreError: ", error);
    }

    return data;
  };

  /**
   * @description Stores the provided state object in local storage using the provided key.
   * @param storeKey The key used to store the state to local storage.
   * @param state the state object to be stored in local storage.
   * @param pefaID (Optional) The pefa specific identifier.
   *
   * @example "Storing an MST snapshot: Provide the MST key constant and the snapshot object as the state."
   * @example "Storing form data: Provide the storeKey as the form identifier, the unique pefaID and the data as the state."
   */
  const setData = async (storeKey: string, state: any, pefaID?: string): Promise<void> => {
    let data: LooseObject;
    let jsonData: string;
    if (pefaID) {
      data = getData(pefaID);
      data[storeKey] = state;
      jsonData = JSON.stringify(data);
    } else {
      jsonData = JSON.stringify(state);
    }

    setSecureLocalData(storeKey, jsonData);
  };

  const getSecureLocalData = async (storeKey: string): Promise<string | undefined> => {
    const encodedData = localStorage.getItem(storeKey);
    if (!!encodedData) {
      const decodedBytes = cryptojs.AES.decrypt(encodedData, Constants.cryptoStoreKey);
      const decodedData = JSON.parse(decodedBytes.toString(cryptojs.enc.Utf8));
      return decodedData;
    }
    return undefined;
  };

  const setSecureLocalData = async (storeKey: string, data: any): Promise<void> => {
    const cypherText = cryptojs.AES.encrypt(JSON.stringify(data), Constants.cryptoStoreKey).toString();
    localStorage.setItem(storeKey, cypherText);
  };

  return { getData, setData };
};

const localStoreService = LocalStoreService();

export default localStoreService;

import React, { Component, ReactNode } from "react";
import { Modal, Backdrop, Button, Slide, TextField, Typography } from "@material-ui/core";
import { ChangeEvent } from "react";

interface ModalProps {
  isOpen: boolean;
  handleStoppedCloseModal: any;
  stoppedText?: string;
}

interface ModalState {
  noteText: string;
  stoppedReason: string;
}

class participantStoppedModal extends Component<ModalProps, ModalState> {
  constructor(props: ModalProps) {
    super(props);

    this.state = {
      noteText: this.props.stoppedText ? this.props.stoppedText : "",
      stoppedReason: "Participant Stopped",
    };

    this.handleParticipantStoppedChanged = this.handleParticipantStoppedChanged.bind(this);
  }

  handleParticipantStoppedChanged(event: ChangeEvent<any>): void {
    event.persist();
    this.setState((prevState) => ({
      ...prevState,
      noteText: event.target.value,
    }));
  }

  render(): ReactNode {
    return (
      <div className="notTestedHeader">
        <Modal
          aria-labelledby="modal-title"
          open={this.props.isOpen}
          onClose={(event) => this.props.handleStoppedCloseModal(null, null)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Slide direction="up" in={this.props.isOpen} mountOnEnter unmountOnExit>
            <div className="modalPop">
              {" "}
              <div className="notesModalText">
                <Typography variant="h2" className="modalHeader">
                  Participant Stopped
                </Typography>
                <Typography variant="body2" className="modalText">
                  Please advise why this activity was stopped
                </Typography>
                <div className="spacer">
                  <TextField
                    id="outlined-multiline-static"
                    multiline
                    rows={4}
                    placeholder="Enter note here"
                    className="textInputFull"
                    name="stoppedMessage"
                    variant="outlined"
                    InputLabelProps={{ shrink: false }}
                    value={this.state.noteText}
                    onChange={(event) => this.handleParticipantStoppedChanged(event)}
                  />
                </div>
                <Button variant="contained" color="primary" onClick={() => this.props.handleStoppedCloseModal(this.state.noteText, this.state.stoppedReason)}>
                  Save and next
                </Button>
              </div>
            </div>
          </Slide>
        </Modal>
      </div>
    );
  }
}

export default participantStoppedModal;

import React, { useEffect } from "react";
import { ProfileCard, FormNavigator, Stepper, Accordion, IOSSwitch, MobilityTest, StrengthTest, NotesModal, InTestMedicalClearanceModal } from "../../../components/";
import { MskLying, MskSitting } from "../../../assets/images/";
import musculoskeletalValidation from "./mskLyingValidation";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import {
  Grid,
  Typography,
  Container,
  FormControl,
  FormLabel,
  FormControlLabel,
  OutlinedInput,
  Card,
  FormGroup,
  Checkbox,
  AccordionSummary,
  AccordionDetails,
  Select,
  MenuItem,
  InputAdornment,
} from "@material-ui/core";
import { Accordion as StyledAccordion } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import { Root } from "../../../stores";
import { differenceInYears } from "date-fns";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { dataStorePageKeys } from "../../../stores/dataStore";
import { mskLyingMobilityDataKeys, mskLyingStrengthDataKeys } from "./mskDataKeys";
import { useHistory } from "react-router-dom";
import { BALANCETEST, DASHBOARD } from "../../../routes/paths";
import { getAppInsights } from "../../../components/telemetry";
import withAppInsights from "../AppInsights";
import { ExpandMore } from "@material-ui/icons";

interface MusckuloskeletalProps {
  rootStore?: Root;
}

const mskLying = inject("rootStore")(
  observer((props: MusckuloskeletalProps) => {
    let pefaAssessment: any;
    let prevData: any;
    let referralDetails: any;
    let baselineMeasurements: any;
    let medicalHistoryData: any;
    let endAssessment: any;
    let participantAgeYears = 0;
    let BPSys: any;
    let BPDia: any;
    let isHighBPFlowTested = false;
    let showHighBloodPressure = false;
    const history = useHistory();
    const appInsights = getAppInsights();

    if (props.rootStore?.pefaStore.selectedPefa) {
      pefaAssessment = props.rootStore?.pefaStore.selectedPefa[0];
      prevData = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.MSK_LYING);
      referralDetails = props.rootStore?.dataStore.getCheckInData(pefaAssessment.id, dataStorePageKeys.REFERRAL_DETAILS);
      baselineMeasurements = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.BASELINE_MEASUREMENTS);
      medicalHistoryData = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.MEDICAL_DETAILS);
      endAssessment = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.END_ASSESSMENT);
      isHighBPFlowTested = medicalHistoryData.highBloodPressureViewedMC === "ViewedAccepted" ? false : true;
      participantAgeYears = differenceInYears(new Date(), new Date(referralDetails?.dob ?? pefaAssessment.pefArequest.worker.dateOfBirth));
      BPSys = pefaAssessment.pefArequest.company.bpDia || 145;
      BPDia = pefaAssessment.pefArequest.company.bpSys || 95;
      showHighBloodPressure =
        isHighBPFlowTested &&
        ((Number(baselineMeasurements.bloodPressureDiastolic2) > Number(BPDia) && Number(baselineMeasurements.bloodPressureSystolic2) > 0) ||
          (Number(baselineMeasurements.bloodPressureSystolic2) > Number(BPSys) && Number(baselineMeasurements.bloodPressureDiastolic2) > 0));
    } else {
      history.push(DASHBOARD);
      return <></>;
    }

    const { register, handleSubmit, errors, setValue } = useForm({
      resolver: yupResolver(musculoskeletalValidation),
    });

    const [state, setState] = useState({
      isLoading: true,
      yesAllNilSignificant: false,
      viewAll: false,

      hipMobilityPainFree: prevData ? prevData.hipMobilityPainFree : false,
      hipMobilityPainFreeExpanded: false,
      hipMobilityPainFreeEdited: false,

      ankleMobilityPainFree: prevData ? prevData.ankleMobilityPainFree : false,
      ankleMobilityPainFreeExpanded: false,
      ankleMobilityPainFreeEdited: false,

      ankleStrengthPainFree: prevData ? prevData.ankleStrengthPainFree : false,
      ankleStrengthPainFreeExpanded: false,
      ankleStrengthPainFreeEdited: false,

      trunkStrengthPainFree: prevData ? prevData.trunkStrengthPainFree : false,
      trunkStrengthPainFreeExpanded: false,
      trunkStrengthPainFreeEdited: false,

      lyingSpecialTests: prevData ? prevData.lyingSpecialTests : false,
      lyingSpecialTestsExpanded: false,
      lyingSpecialTestsEdited: false,

      mskLyingSubmitted: prevData ? prevData.mskLyingSubmitted : false,
      bloodPressureSystolic3: prevData ? prevData.bloodPressureSystolic3 : "",
      bloodPressureDiastolic3: prevData ? prevData.bloodPressureDiastolic3 : "",

      hamstringLeftSLR: prevData ? prevData.hamstringLeftSLR : "",
      hamstringRightSLR: prevData ? prevData.hamstringRightSLR : "",

      trunkStabilityGrade: prevData ? (prevData.trunkStabilityGrade ? prevData.trunkStabilityGrade : "") : "",
      leftShoulderStabilityGrade: prevData ? (prevData.leftShoulderStabilityGrade ? prevData.leftShoulderStabilityGrade : "") : "",
      rightShoulderStabilityGrade: prevData ? (prevData.rightShoulderStabilityGrade ? prevData.rightShoulderStabilityGrade : "") : "",

      leftAnkleDorsiflexion: false,
      leftAnklePlantarFlexion: false,
      leftAnkleInversion: false,
      leftAnkleEversion: false,
      rightAnkleDorsiflexion: false,
      rightAnklePlantarFlexion: false,
      rightAnkleInversion: false,
      rightAnkleEversion: false,
      leftHipFlexion: false,
      leftHipExternalRotation: false,
      leftKneeFlexion: false,
      leftKneeExtension: false,
      rightHipFlexion: false,
      rightHipExternalRotation: false,
      rightKneeFlexion: false,
      rightKneeExtension: false,
      leftAnkleStrengthDorsiflexion: false,
      leftAnkleStrengthPlantarflexion: false,
      leftAnkleStrengthInversion: false,
      leftAnkleStrengthEversion: false,
      leftToeExtension: false,
      rightAnkleStrengthDorsiflexion: false,
      rightAnkleStrengthPlantarflexion: false,
      rightAnkleStrengthInversion: false,
      rightAnkleStrengthEversion: false,
      rightToeExtension: false,
      trunkStrengthExtensors: false,
      trunkStrengthAbdominals: false,
    });

    const handleViewAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setState((prevState) => ({
        ...prevState,
        viewAll: event.target.checked,
        hipMobilityPainFreeExpanded: event.target.checked,
        ankleMobilityPainFreeExpanded: event.target.checked,
        ankleStrengthPainFreeExpanded: event.target.checked,
        trunkStrengthPainFreeExpanded: event.target.checked,
        lyingSpecialTestsExpanded: event.target.checked,
      }));
    };

    const toggleAccordion = async (eventValue: React.ChangeEvent<HTMLDivElement>, componentName: string) => {
      await setState((prevState) => ({
        ...prevState,
        [componentName]: eventValue,
      }));

      if (!eventValue && state.viewAll) {
        await setState((prevState) => ({
          ...prevState,
          viewAll: false,
        }));
      }
    };

    const submitForm = (data: any, forwardTarget: any) => {
      const pefaData = { ...props.rootStore?.dataStore.pefaData[pefaAssessment.id] };
      // take snapshot of assessment and store against booking
      props.rootStore?.pefaStore.nextSubmit(pefaData, pefaAssessment.id);
      const mskLyingSubmitted = true;
      const bloodPressureSystolic3 = state.bloodPressureSystolic3;
      const bloodPressureDiastolic3 = state.bloodPressureDiastolic3;
      props.rootStore?.dataStore.saveData(
        {
          ...baselineMeasurements,
          bloodPressureSystolic3,
          bloodPressureDiastolic3,
        },
        pefaAssessment.id,
        dataStorePageKeys.BASELINE_MEASUREMENTS
      );
      props.rootStore?.dataStore.saveData({ ...data, mskLyingSubmitted }, pefaAssessment.id, dataStorePageKeys.MSK_LYING);
      history.push(forwardTarget);
    };

    const handleCheckboxChange = async (event: any, logic: any = null): Promise<void> => {
      const control = event.currentTarget;
      const formComponent = control.getAttribute("name") as string;

      //Store the current checkbox's value to the state using the checkbox name attribute as the key.
      //This is set as an await to make sure that it has saved before moving on. This may not be necessary,
      //but I'll leave it here for now. It's handy for testing
      await setState((prevState) => ({
        ...prevState,
        [formComponent]: control.checked,
      }));

      //If a reference to a function has been passed, execute it, passing in the control checked state.
      if (!!logic) {
        logic(control.checked);
      }
    };

    const changeAllChildCheckboxes = (checked: boolean, targetCheckboxes: string[]): void => {
      targetCheckboxes.forEach((child) => {
        //Set the checkbox values in the form, flagging them as dirty.
        setValue(child, checked, { shouldDirty: true });

        //Update the mapped state values. This updates the field visually.
        setState((prevState) => ({
          ...prevState,
          [child]: checked,
        }));
      });
    };

    const changeParentCheckbox = async (childChecked: boolean, parentCheckbox: string) => {
      if (!childChecked) {
        setValue(parentCheckbox, false, { shouldDirty: true });
        await setState((prevState) => ({
          ...prevState,
          [parentCheckbox]: false,
        }));
      }
    };

    useEffect(() => {
      setState((prevState) => ({
        ...prevState,
        ankleMobilityPainFreeEdited:
          state.leftAnkleDorsiflexion ||
          state.leftAnklePlantarFlexion ||
          state.leftAnkleInversion ||
          state.leftAnkleEversion ||
          state.rightAnkleDorsiflexion ||
          state.rightAnklePlantarFlexion ||
          state.rightAnkleInversion ||
          state.rightAnkleEversion,
        hipMobilityPainFreeEdited:
          state.leftHipFlexion ||
          state.leftHipExternalRotation ||
          state.leftKneeFlexion ||
          state.leftKneeExtension ||
          state.rightHipFlexion ||
          state.rightHipExternalRotation ||
          state.rightKneeFlexion ||
          state.rightKneeExtension,
        ankleStrengthPainFreeEdited:
          state.leftAnkleStrengthDorsiflexion ||
          state.leftAnkleStrengthPlantarflexion ||
          state.leftAnkleStrengthInversion ||
          state.leftAnkleStrengthEversion ||
          state.leftToeExtension ||
          state.rightAnkleStrengthDorsiflexion ||
          state.rightAnkleStrengthPlantarflexion ||
          state.rightAnkleStrengthInversion ||
          state.rightAnkleStrengthEversion ||
          state.rightToeExtension,
        trunkStrengthPainFreeEdited: state.trunkStrengthExtensors || state.trunkStrengthAbdominals,
        lyingSpecialTestsEdited:
          !!state.hamstringLeftSLR ||
          !!state.hamstringRightSLR ||
          state.trunkStabilityGrade !== "" ||
          state.leftShoulderStabilityGrade !== "" ||
          state.rightShoulderStabilityGrade !== "",
      }));
    }, [
      state.leftAnkleDorsiflexion,
      state.leftAnklePlantarFlexion,
      state.leftAnkleInversion,
      state.leftAnkleEversion,
      state.rightAnkleDorsiflexion,
      state.rightAnklePlantarFlexion,
      state.rightAnkleInversion,
      state.rightAnkleEversion,
      state.leftHipFlexion,
      state.leftHipExternalRotation,
      state.leftKneeFlexion,
      state.leftKneeExtension,
      state.rightHipFlexion,
      state.rightHipExternalRotation,
      state.rightKneeFlexion,
      state.rightKneeExtension,
      state.leftAnkleStrengthDorsiflexion,
      state.leftAnkleStrengthPlantarflexion,
      state.leftAnkleStrengthInversion,
      state.leftAnkleStrengthEversion,
      state.leftToeExtension,
      state.rightAnkleStrengthDorsiflexion,
      state.rightAnkleStrengthPlantarflexion,
      state.rightAnkleStrengthInversion,
      state.rightAnkleStrengthEversion,
      state.rightToeExtension,
      state.trunkStrengthExtensors,
      state.trunkStrengthAbdominals,
      state.hamstringLeftSLR,
      state.hamstringRightSLR,
      state.trunkStabilityGrade,
      state.leftShoulderStabilityGrade,
      state.rightShoulderStabilityGrade,
    ]);

    useEffect(() => {
      const timeout = setTimeout(() => {
        props.rootStore?.dataStore.savePartialData(state, pefaAssessment.id, dataStorePageKeys.MSK_LYING);
      }, 500);

      return () => clearTimeout(timeout);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      state.hipMobilityPainFree,
      state.ankleMobilityPainFree,
      state.ankleStrengthPainFree,
      state.trunkStrengthPainFree,
      state.lyingSpecialTests,
      state.hamstringLeftSLR,
      state.hamstringRightSLR,
      state.trunkStabilityGrade,
      state.leftShoulderStabilityGrade,
      state.rightShoulderStabilityGrade,
      state.mskLyingSubmitted,
      state.bloodPressureSystolic3,
      state.bloodPressureDiastolic3,
    ]);

    useEffect(() => {
      //Replicate the onUnmount event handler from class components.
      return () => {
        appInsights.trackEvent({ name: "intermediateSubmission", properties: { bookingId: pefaAssessment.id, dataKey: dataStorePageKeys.MSK_LYING, data: state } });
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDataChanged = (datasetName: string, isDirty: boolean) => {
      setState((prevState) => ({
        ...prevState,
        [datasetName]: isDirty,
      }));
    };
    const handleValueChange = (event: any): void => {
      event.persist();
      setState((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
      }));
    };
    const physicalChildren = ["hipMobilityPainFree", "ankleMobilityPainFree", "ankleStrengthPainFree", "trunkStrengthPainFree", "lyingSpecialTests"];

    return (
      <div className="FormStyler">
        <div className="AssessmentDetails">
          <Grid item container direction="column" xs={12}>
            <Stepper targetStep={"Observations & Musculosketal"} />
            <Container maxWidth={"lg"}>
              <Grid item container direction="row" xs={12}>
                <Grid item container xs={9}>
                  <Card className="titleCard" variant="outlined">
                    <Typography variant="h2" className="cardHeroHeading">
                      <img src={MskLying} alt="Success" /> Lying: Mobility &amp; Strength
                    </Typography>
                    <Typography variant="subtitle2" className="subtitleHeading">
                      List of Observations
                    </Typography>
                  </Card>
                  <Grid item container xs={12}>
                    <div className="formControllerContainer">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="yesAllNilSignificant"
                            name="yesAllNilSignificant"
                            icon={<span className="checkboxIcon"></span>}
                            checkedIcon={<span className="checkboxIconChecked"></span>}
                            color="primary"
                          />
                        }
                        label="All within functional limits pain free"
                        inputRef={register}
                        checked={state.yesAllNilSignificant}
                        onChange={(event) => {
                          handleCheckboxChange(event, (checked: boolean) => changeAllChildCheckboxes(checked, physicalChildren));
                        }}
                      />
                      <FormControlLabel control={<IOSSwitch checked={state.viewAll} onChange={handleViewAllChange} name="checkedB" />} label="View All" />
                    </div>
                    <form className="PefaForm">
                      {/* 
                  |
                    Ankle mobility within functional limits painfree on both sides
                  |
                  */}
                      <Accordion
                        inputName="ankleMobilityPainFree"
                        checked={state.ankleMobilityPainFree}
                        expanded={state.ankleMobilityPainFreeExpanded}
                        edited={state.ankleMobilityPainFreeEdited}
                        label="Ankle mobility within functional limits painfree on both sides"
                        innerRef={register}
                        innerValidationError={
                          !!errors.ankleMobility?.message ||
                          !!errors.leftAnkleDorsiflexion?.message ||
                          !!errors.leftAnklePlantarFlexion?.message ||
                          !!errors.leftAnkleInversion?.message ||
                          !!errors.leftAnkleEversion?.message ||
                          !!errors.rightAnkleDorsiflexion?.message ||
                          !!errors.rightAnklePlantarFlexion?.message ||
                          !!errors.rightAnkleInversion?.message ||
                          !!errors.rightAnkleEversion?.message
                        }
                        checkedHandler={(event: any) => handleCheckboxChange(event, (checked: boolean) => changeParentCheckbox(checked, "yesAllNilSignificant"))}
                        expandedHandler={(event: any) => {
                          toggleAccordion(event, "ankleMobilityPainFreeExpanded");
                        }}
                      >
                        <div className="invalid-feedback">{errors.ankleMobility?.message}</div>
                        <StyledAccordion variant="outlined" className="innerOutlinedBox">
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <FormControlLabel
                              aria-label="Acknowledge"
                              control={
                                <Checkbox
                                  icon={<span className="checkboxIcon"></span>}
                                  checkedIcon={<span className="checkboxIconChecked"></span>}
                                  color="primary"
                                  name="ankleStrengthLimitedL"
                                />
                              }
                              label="Ankle or foot strength reduced or discomfort reported on left"
                              color="primary"
                            />
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="greyBox">
                              <Typography variant="body1">
                                Please indicate which movements are limited or uncomfortable. All others will be marked as within functional limits painfree.
                              </Typography>
                            </div>
                            <MobilityTest
                              label="Dorsiflexion"
                              datasetName={mskLyingMobilityDataKeys.LEFTANKLEDORSIFLEXION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_LYING}
                            />
                            <MobilityTest
                              label="Plantar Flexion"
                              datasetName={mskLyingMobilityDataKeys.LEFTANKLEPLANTARFLEXION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_LYING}
                            />
                            <MobilityTest
                              label="Inversion"
                              datasetName={mskLyingMobilityDataKeys.LEFTANKLEINVERSION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_LYING}
                            />
                            <MobilityTest
                              label="Eversion"
                              datasetName={mskLyingMobilityDataKeys.LEFTANKLEEVERSION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_LYING}
                            />
                          </AccordionDetails>
                        </StyledAccordion>
                        <StyledAccordion variant="outlined" className="innerOutlinedBox">
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <FormControlLabel
                              aria-label="Acknowledge"
                              control={
                                <Checkbox
                                  icon={<span className="checkboxIcon"></span>}
                                  checkedIcon={<span className="checkboxIconChecked"></span>}
                                  color="primary"
                                  name="ankleStrengthLimitedR"
                                />
                              }
                              label="Ankle or foot strength reduced or discomfort reported on right"
                              color="primary"
                            />
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="greyBox">
                              <Typography variant="body1">
                                Please indicate which movements are limited or uncomfortable. All others will be marked as within functional limits painfree.
                              </Typography>
                            </div>
                            <MobilityTest
                              label="Dorsiflexion"
                              datasetName={mskLyingMobilityDataKeys.RIGHTANKLEDORSIFLEXION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_LYING}
                            />
                            <MobilityTest
                              label="Plantar Flexion"
                              datasetName={mskLyingMobilityDataKeys.RIGHTANKLEPLANTARFLEXION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_LYING}
                            />
                            <MobilityTest
                              label="Inversion"
                              datasetName={mskLyingMobilityDataKeys.RIGHTANKLEINVERSION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_LYING}
                            />
                            <MobilityTest
                              label="Eversion"
                              datasetName={mskLyingMobilityDataKeys.RIGHTANKLEEVERSION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_LYING}
                            />
                          </AccordionDetails>
                        </StyledAccordion>
                      </Accordion>
                      {/* 
                    |
                      Hip and knee mobility within functional limits painfree on both sides
                    |
                    */}
                      <Accordion
                        inputName="hipMobilityPainFree"
                        label="Hip and knee mobility within functional limits painfree on both sides"
                        checked={state.hipMobilityPainFree}
                        expanded={state.hipMobilityPainFreeExpanded}
                        edited={state.hipMobilityPainFreeEdited}
                        innerRef={register}
                        innerValidationError={
                          !!errors.hipMobility?.message ||
                          !!errors.leftHipFlexion?.message ||
                          !!errors.leftHipExternalRotation?.message ||
                          !!errors.leftKneeFlexion?.message ||
                          !!errors.leftKneeExtension?.message ||
                          !!errors.rightHipFlexion?.message ||
                          !!errors.rightHipExternalRotation?.message ||
                          !!errors.rightKneeFlexion?.message ||
                          !!errors.rightKneeExtension?.message
                        }
                        checkedHandler={(event: any) => handleCheckboxChange(event, (checked: boolean) => changeParentCheckbox(checked, "yesAllNilSignificant"))}
                        expandedHandler={(event: any) => {
                          toggleAccordion(event, "hipMobilityPainFreeExpanded");
                        }}
                      >
                        <div className="invalid-feedback">{errors.hipMobility?.message}</div>
                        <StyledAccordion variant="outlined" className="innerOutlinedBox">
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <FormControlLabel
                              aria-label="Acknowledge"
                              control={
                                <Checkbox
                                  icon={<span className="checkboxIcon"></span>}
                                  checkedIcon={<span className="checkboxIconChecked"></span>}
                                  color="primary"
                                  name="hipAndKneeMobilityLimitedL"
                                />
                              }
                              label="Hip and knee mobility reported on the left"
                              color="primary"
                            />
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="greyBox">
                              <Typography variant="body1">
                                Please indicate which movements are limited or uncomfortable. All others will be marked as within functional limits painfree.
                              </Typography>
                            </div>
                            <FormLabel component="legend">Left hip mobility</FormLabel>
                            <MobilityTest
                              label="Flexion"
                              datasetName={mskLyingMobilityDataKeys.LEFTHIPFLEXION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_LYING}
                            />
                            <MobilityTest
                              label="External Rotation"
                              datasetName={mskLyingMobilityDataKeys.LEFTHIPEXTERNALROTATION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_LYING}
                            />
                            <FormLabel component="legend">Left knee mobility</FormLabel>
                            <MobilityTest
                              label="Flexion"
                              datasetName={mskLyingMobilityDataKeys.LEFTKNEEFLEXION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_LYING}
                            />
                            <MobilityTest
                              label="Extension"
                              datasetName={mskLyingMobilityDataKeys.LEFTKNEEEXTENSION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_LYING}
                            />
                          </AccordionDetails>
                        </StyledAccordion>
                        <StyledAccordion variant="outlined" className="innerOutlinedBox">
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <FormControlLabel
                              aria-label="Acknowledge"
                              control={
                                <Checkbox
                                  icon={<span className="checkboxIcon"></span>}
                                  checkedIcon={<span className="checkboxIconChecked"></span>}
                                  color="primary"
                                  name="hipAndKneeMobilityLimitedR"
                                />
                              }
                              label="Hip and knee mobility reported on the right"
                              color="primary"
                            />
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="greyBox">
                              <Typography variant="body1">
                                Please indicate which movements are limited or uncomfortable. All others will be marked as within functional limits painfree.
                              </Typography>
                            </div>
                            <FormLabel component="legend">Right hip mobility</FormLabel>
                            <MobilityTest
                              label="Flexion"
                              datasetName={mskLyingMobilityDataKeys.RIGHTHIPFLEXION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_LYING}
                            />
                            <MobilityTest
                              label="External Rotation"
                              datasetName={mskLyingMobilityDataKeys.RIGHTHIPEXTERNALROTATION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_LYING}
                            />
                            <FormLabel component="legend">Right knee mobility</FormLabel>
                            <MobilityTest
                              label="Flexion"
                              datasetName={mskLyingMobilityDataKeys.RIGHTKNEEFLEXION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_LYING}
                            />
                            <MobilityTest
                              label="Extension"
                              datasetName={mskLyingMobilityDataKeys.RIGHTKNEEEXTENSION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_LYING}
                            />
                          </AccordionDetails>
                        </StyledAccordion>
                      </Accordion>
                      {/* 
                    |
                      Ankle strength within normal limits painfree on both sides 
                    |
                    */}
                      <Accordion
                        inputName="ankleStrengthPainFree"
                        checked={state.ankleStrengthPainFree}
                        expanded={state.ankleStrengthPainFreeExpanded}
                        edited={state.ankleStrengthPainFreeEdited}
                        innerRef={register}
                        innerValidationError={
                          !!errors.ankleStrength?.message ||
                          !!errors.leftAnkleStrengthDorsiflexion?.message ||
                          !!errors.leftAnkleStrengthPlantarflexion?.message ||
                          !!errors.leftAnkleStrengthInversion?.message ||
                          !!errors.leftAnkleStrengthEversion?.message ||
                          !!errors.leftToeExtension?.message ||
                          !!errors.rightAnkleStrengthDorsiflexion?.message ||
                          !!errors.rightAnkleStrengthPlantarflexion?.message ||
                          !!errors.rightAnkleStrengthInversion?.message ||
                          !!errors.rightAnkleStrengthEversion?.message ||
                          !!errors.rightToeExtension?.message
                        }
                        label="Ankle strength within normal limits painfree on both sides "
                        checkedHandler={(event: any) => handleCheckboxChange(event, (checked: boolean) => changeParentCheckbox(checked, "yesAllNilSignificant"))}
                        expandedHandler={(event: any) => {
                          toggleAccordion(event, "ankleStrengthPainFreeExpanded");
                        }}
                      >
                        <div className="invalid-feedback">{errors.ankleStrength?.message}</div>
                        <StyledAccordion variant="outlined" className="innerOutlinedBox">
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <FormControlLabel
                              aria-label="Acknowledge"
                              control={
                                <Checkbox
                                  icon={<span className="checkboxIcon"></span>}
                                  checkedIcon={<span className="checkboxIconChecked"></span>}
                                  color="primary"
                                  name="ankleStrengthLimitedL"
                                />
                              }
                              label="Ankle or foot strength reduced or discomfort reported on Left"
                              color="primary"
                            />
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="greyBox">
                              <Typography variant="body1">
                                Please indicate which movements are limited or uncomfortable. All others will be marked as within functional limits painfree.
                              </Typography>
                            </div>
                            <FormLabel component="legend">Left ankle strength</FormLabel>
                            <StrengthTest
                              label="Dorsiflexion"
                              datasetName={mskLyingStrengthDataKeys.LEFTANKLESTRENGTHDORSIFLEXION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_LYING}
                            />
                            <StrengthTest
                              label="Plantar Flexion"
                              datasetName={mskLyingStrengthDataKeys.LEFTANKLESTRENGTHPLANTARFLEXION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_LYING}
                            />
                            <StrengthTest
                              label="Inversion"
                              datasetName={mskLyingStrengthDataKeys.LEFTANKLESTRENGTHINVERSION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_LYING}
                            />
                            <StrengthTest
                              label="Eversion"
                              datasetName={mskLyingStrengthDataKeys.LEFTANKLESTRENGTHEVERSION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_LYING}
                            />
                            <FormLabel component="legend">Left toe</FormLabel>
                            <StrengthTest
                              label="Extension"
                              datasetName={mskLyingStrengthDataKeys.LEFTTOEEXTENSION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_LYING}
                            />
                          </AccordionDetails>
                        </StyledAccordion>
                        <StyledAccordion variant="outlined" className="innerOutlinedBox">
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <FormControlLabel
                              aria-label="Acknowledge"
                              control={
                                <Checkbox
                                  icon={<span className="checkboxIcon"></span>}
                                  checkedIcon={<span className="checkboxIconChecked"></span>}
                                  color="primary"
                                  name="ankleStrengthLimitedR"
                                />
                              }
                              label="Ankle or foot strength reduced or discomfort reported on right"
                              color="primary"
                            />
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="greyBox">
                              <Typography variant="body1">
                                Please indicate which movements are limited or uncomfortable. All others will be marked as within functional limits painfree.
                              </Typography>
                            </div>
                            <FormLabel component="legend">Right ankle strength</FormLabel>
                            <StrengthTest
                              label="Dorsiflexion"
                              datasetName={mskLyingStrengthDataKeys.RIGHTANKLESTRENGTHDORSIFLEXION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_LYING}
                            />
                            <StrengthTest
                              label="Plantar Flexion"
                              datasetName={mskLyingStrengthDataKeys.RIGHTANKLESTRENGTHPLANTARFLEXION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_LYING}
                            />
                            <StrengthTest
                              label="Inversion"
                              datasetName={mskLyingStrengthDataKeys.RIGHTANKLESTRENGTHINVERSION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_LYING}
                            />
                            <StrengthTest
                              label="Eversion"
                              datasetName={mskLyingStrengthDataKeys.RIGHTANKLESTRENGTHEVERSION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_LYING}
                            />
                            <FormLabel component="legend">Right toe</FormLabel>
                            <StrengthTest
                              label="Extension"
                              datasetName={mskLyingStrengthDataKeys.RIGHTTOEEXTENSION}
                              register={register}
                              errors={errors}
                              data={prevData}
                              setValue={setValue}
                              handleDataChanged={handleDataChanged}
                              assessmentID={pefaAssessment.id}
                              dataKey={dataStorePageKeys.MSK_LYING}
                            />
                          </AccordionDetails>
                        </StyledAccordion>
                      </Accordion>
                      {/* 
                    |
                      Trunk strength within normal limits painfree
                    |
                    */}
                      <Accordion
                        inputName="trunkStrengthPainFree"
                        checked={state.trunkStrengthPainFree}
                        expanded={state.trunkStrengthPainFreeExpanded}
                        edited={state.trunkStrengthPainFreeEdited}
                        innerRef={register}
                        innerValidationError={!!errors.trunkStrength?.message || !!errors.trunkStrengthExtensors?.message || !!errors.trunkStrengthAbdominals?.message}
                        label="Trunk strength within normal limits painfree"
                        checkedHandler={(event: any) => handleCheckboxChange(event, (checked: boolean) => changeParentCheckbox(checked, "yesAllNilSignificant"))}
                        expandedHandler={(event: any) => {
                          toggleAccordion(event, "trunkStrengthPainFreeExpanded");
                        }}
                      >
                        <div className="invalid-feedback">{errors.trunkStrength?.message}</div>
                        <FormLabel component="legend">Trunk strength</FormLabel>
                        <StrengthTest
                          label="Trunk extensor"
                          datasetName={mskLyingStrengthDataKeys.TRUNKSTRENGTHEXTENSORS}
                          register={register}
                          errors={errors}
                          data={prevData}
                          setValue={setValue}
                          handleDataChanged={handleDataChanged}
                          assessmentID={pefaAssessment.id}
                          dataKey={dataStorePageKeys.MSK_LYING}
                        />
                        <StrengthTest
                          label="Abdominals"
                          datasetName={mskLyingStrengthDataKeys.TRUNKSTRENGTHABDOMINALS}
                          register={register}
                          errors={errors}
                          data={prevData}
                          setValue={setValue}
                          handleDataChanged={handleDataChanged}
                          assessmentID={pefaAssessment.id}
                          dataKey={dataStorePageKeys.MSK_LYING}
                        />
                      </Accordion>
                      {/* 
                    |
                      Special Tests
                    |
                    */}
                      <Accordion
                        inputName="lyingSpecialTests"
                        label="Special tests"
                        expanded={true}
                        edited={state.lyingSpecialTestsEdited}
                        innerRef={register}
                        innerValidationError={
                          !!errors.hamstringFlexibility?.message ||
                          !!errors.trunkStability?.message ||
                          !!errors.rightShoulderStability?.message ||
                          !!errors.leftShoulderStability?.message
                        }
                        expandedHandler={(event: any) => {
                          toggleAccordion(event, "lyingSpecialTestsExpanded");
                        }}
                      >
                        <FormControl component="fieldset" className="fieldsetStrength">
                          <FormLabel component="legend">Hamstring flexibility</FormLabel>
                          <FormGroup className="formGroupOveride">
                            <Grid xs={12} item container className="StrengthTestContainer">
                              <FormControl component="fieldset" className="fieldsetStrength">
                                <FormGroup className="formGroupOveride">
                                  <div className="flexer">
                                    <div className="labelLeftOveride">
                                      <FormLabel className="greyPill" component="legend">
                                        Left SLR
                                      </FormLabel>
                                    </div>
                                    <FormControlLabel
                                      control={
                                        <OutlinedInput
                                          color="primary"
                                          className="textInputMedium"
                                          autoComplete="off"
                                          value={state.hamstringLeftSLR}
                                          onChange={(event) => {
                                            event.persist();
                                            setState((prevState) => ({
                                              ...prevState,
                                              [event.target.name]: event?.target.value,
                                            }));
                                          }}
                                        />
                                      }
                                      label="Degrees"
                                      labelPlacement="top"
                                      name="hamstringLeftSLR"
                                      inputRef={register}
                                    />
                                    {!state.hamstringLeftSLR ? (
                                      <>
                                        <div className="invalid-feedback labelLeftOverideSpacer">Please provide Left SLR value.</div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </FormGroup>
                              </FormControl>

                              <FormControl component="fieldset" className="fieldsetStrength">
                                <FormGroup className="formGroupOveride">
                                  <div className="flexer">
                                    <div className="labelLeftOveride">
                                      <FormLabel className="greyPill" component="legend">
                                        Right SLR
                                      </FormLabel>
                                    </div>
                                    <FormControlLabel
                                      control={
                                        <OutlinedInput
                                          color="primary"
                                          className="textInputMedium"
                                          autoComplete="off"
                                          value={state.hamstringRightSLR}
                                          onChange={(event) => {
                                            event.persist();
                                            setState((prevState) => ({
                                              ...prevState,
                                              [event.target.name]: event?.target.value,
                                            }));
                                          }}
                                        />
                                      }
                                      label="Degrees"
                                      labelPlacement="top"
                                      name="hamstringRightSLR"
                                      inputRef={register}
                                    />
                                    {!state.hamstringRightSLR ? (
                                      <>
                                        <div className="invalid-feedback labelLeftOverideSpacer">Please provide Right SLR value.</div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </FormGroup>
                              </FormControl>
                            </Grid>
                          </FormGroup>
                          <FormGroup className="formGroupOveride">
                            <Grid xs={12} item container className="StrengthTestContainer">
                              <FormControl component="fieldset" className="fieldsetStrength">
                                <FormLabel component="legend">Stability tests</FormLabel>
                                <FormControl component="fieldset" className="fieldsetStrength">
                                  <FormGroup className="formGroupOveride">
                                    <div className="flexer">
                                      <div className="labelLeftOveride">
                                        <FormLabel className="greyPill" component="legend">
                                          Trunk
                                        </FormLabel>
                                      </div>
                                      <FormControl variant="outlined" className="selectOveride">
                                        <FormLabel component="legend" className="textLabelSmall">
                                          Grade
                                        </FormLabel>
                                        <Select
                                          color="primary"
                                          className="DayChanger"
                                          name="trunkStabilityGrade"
                                          value={state.trunkStabilityGrade}
                                          onChange={(event) => {
                                            setState((prevState) => ({
                                              ...prevState,
                                              trunkStabilityGrade: event.target.value,
                                            }));
                                          }}
                                        >
                                          <MenuItem value="NT">NT</MenuItem>
                                          <MenuItem value="5">5</MenuItem>
                                          <MenuItem value="4">4</MenuItem>
                                          <MenuItem value="3">3</MenuItem>
                                          <MenuItem value="2">2</MenuItem>
                                          <MenuItem value="1">1</MenuItem>
                                        </Select>
                                        <input type="text" style={{ visibility: "hidden" }} name="trunkStabilityGrade" value={state.trunkStabilityGrade} ref={register} />
                                      </FormControl>
                                      <div className="invalid-feedback full-width labelLeftOverideSpacer">{errors.trunkStability?.message}</div>
                                    </div>
                                  </FormGroup>
                                </FormControl>
                                <FormControl component="fieldset" className="fieldsetStrength">
                                  <FormGroup className="formGroupOveride">
                                    <div className="flexer">
                                      <div className="labelLeftOveride">
                                        <FormLabel className="greyPill" component="legend">
                                          Left Shoulder
                                        </FormLabel>
                                      </div>
                                      <FormControl variant="outlined" className="selectOveride">
                                        <FormLabel component="legend" className="textLabelSmall">
                                          Grade
                                        </FormLabel>
                                        <Select
                                          color="primary"
                                          className="DayChanger"
                                          value={state.leftShoulderStabilityGrade}
                                          onChange={(event) => {
                                            setState((prevState) => ({
                                              ...prevState,
                                              leftShoulderStabilityGrade: event.target.value,
                                            }));
                                          }}
                                        >
                                          <MenuItem value="NT">NT</MenuItem>
                                          <MenuItem value="5">5</MenuItem>
                                          <MenuItem value="4">4</MenuItem>
                                          <MenuItem value="3">3</MenuItem>
                                          <MenuItem value="2">2</MenuItem>
                                          <MenuItem value="1">1</MenuItem>
                                        </Select>
                                        <input
                                          type="text"
                                          style={{ visibility: "hidden" }}
                                          name="leftShoulderStabilityGrade"
                                          value={state.leftShoulderStabilityGrade}
                                          ref={register}
                                        />
                                      </FormControl>
                                      <div className="invalid-feedback full-width labelLeftOverideSpacer">{errors.leftShoulderStability?.message}</div>
                                    </div>
                                  </FormGroup>
                                </FormControl>
                                <FormControl component="fieldset" className="fieldsetStrength">
                                  <FormGroup className="formGroupOveride">
                                    <div className="flexer">
                                      <div className="labelLeftOveride">
                                        <FormLabel className="greyPill" component="legend">
                                          Right Shoulder
                                        </FormLabel>
                                      </div>
                                      <FormControl variant="outlined" className="selectOveride">
                                        <FormLabel component="legend" className="textLabelSmall">
                                          Grade
                                        </FormLabel>
                                        <Select
                                          color="primary"
                                          className="DayChanger"
                                          value={state.rightShoulderStabilityGrade}
                                          onChange={(event) => {
                                            setState((prevState) => ({
                                              ...prevState,
                                              rightShoulderStabilityGrade: event.target.value,
                                            }));
                                          }}
                                        >
                                          <MenuItem value="NT">NT</MenuItem>
                                          <MenuItem value="5">5</MenuItem>
                                          <MenuItem value="4">4</MenuItem>
                                          <MenuItem value="3">3</MenuItem>
                                          <MenuItem value="2">2</MenuItem>
                                          <MenuItem value="1">1</MenuItem>
                                        </Select>
                                        <input
                                          type="text"
                                          style={{ visibility: "hidden" }}
                                          name="rightShoulderStabilityGrade"
                                          value={state.rightShoulderStabilityGrade}
                                          ref={register}
                                        />
                                      </FormControl>
                                      <div className="invalid-feedback full-width labelLeftOverideSpacer">{errors.rightShoulderStability?.message}</div>
                                    </div>
                                  </FormGroup>
                                </FormControl>
                              </FormControl>
                            </Grid>
                          </FormGroup>
                        </FormControl>
                      </Accordion>
                      {showHighBloodPressure ? (
                        <StyledAccordion variant="outlined" className="innerOutlinedBox" defaultExpanded={true}>
                          <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography variant="h3" className="bpCardHeroHeading">
                              <img src={MskSitting} className="bpSitting" alt="Success" /> Sitting: 3rd Blood Pressure Reading
                              {errors.bloodPressureDiastolic3?.message || errors.bloodPressureSystolic3?.message ? (
                                <svg className="MuiSvgIcon-root invalid-bpReading" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                  <path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
                                </svg>
                              ) : (
                                <></>
                              )}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <input type="text" style={{ visibility: "hidden" }} name={`isHighBPFlowTested`} defaultValue={isHighBPFlowTested?.toString()} ref={register} />
                            <input type="text" style={{ visibility: "hidden" }} name={`showHighBloodPressure`} defaultValue={showHighBloodPressure.toString()} ref={register} />
                            <FormControl component="fieldset" className="fieldsetStrength">
                              <FormLabel component="legend">
                                The participant's blood pressure is higher than the safety cutoff of{" "}
                                <span className="bpLimit">
                                  {BPSys}/{BPDia} mmHg
                                </span>
                                . Please check the cuff size and give them a moment to relax and retest
                              </FormLabel>
                              <div className="spacer">
                                <span className="prevResult">Previous result</span>
                                <span className="actualResult">
                                  {baselineMeasurements?.bloodPressureSystolic2}/{baselineMeasurements?.bloodPressureDiastolic2} mmHg
                                </span>
                              </div>
                              <FormGroup className="formGroupOveride">
                                <Grid xs={12} item container className="StrengthTestContainer">
                                  <div>
                                    <FormGroup className="formGroupOveride">
                                      <div className="flexer">
                                        <FormLabel className="bpLeft" component="legend">
                                          Blood pressure sitting
                                        </FormLabel>
                                        <FormControlLabel
                                          control={
                                            <OutlinedInput
                                              color="primary"
                                              className={state.mskLyingSubmitted ? "disabled-bp" : "bp"}
                                              endAdornment={<InputAdornment position="end">mmhg</InputAdornment>}
                                              name="bloodPressureSystolic3"
                                              aria-describedby="standard-weight-helper-text"
                                              inputRef={register}
                                              autoComplete="off"
                                              defaultValue={state.bloodPressureSystolic3}
                                              readOnly={state.mskLyingSubmitted}
                                              onChange={handleValueChange}
                                            />
                                          }
                                          label="&nbsp;"
                                          labelPlacement="top"
                                          name="systolicPressure"
                                        />
                                        <FormControlLabel
                                          control={
                                            <OutlinedInput
                                              color="primary"
                                              className={state.mskLyingSubmitted ? "disabled-bp" : "bp"}
                                              endAdornment={<InputAdornment position="end">mmhg</InputAdornment>}
                                              name="bloodPressureDiastolic3"
                                              aria-describedby="standard-weight-helper-text"
                                              inputRef={register}
                                              autoComplete="off"
                                              defaultValue={state.bloodPressureDiastolic3}
                                              onChange={handleValueChange}
                                              readOnly={state.mskLyingSubmitted}
                                            />
                                          }
                                          label="&nbsp;"
                                          labelPlacement="top"
                                          name="diastolicPressure"
                                        />
                                      </div>
                                    </FormGroup>
                                  </div>
                                  {errors.bloodPressureDiastolic3?.message ? (
                                    <div className="invalid-feedback full-width">{errors.bloodPressureDiastolic3?.message}</div>
                                  ) : errors.bloodPressureSystolic3?.message ? (
                                    <div className="invalid-feedback full-width">{errors.bloodPressureSystolic3?.message}</div>
                                  ) : (
                                    <></>
                                  )}
                                </Grid>
                              </FormGroup>
                            </FormControl>
                          </AccordionDetails>
                        </StyledAccordion>
                      ) : (
                        <></>
                      )}
                    </form>
                  </Grid>
                </Grid>
                <Grid item container xs={3} className="RightCol">
                  <ProfileCard
                    firstName={pefaAssessment.pefArequest.worker.firstName}
                    lastName={pefaAssessment.pefArequest.worker.lastName}
                    participantAgeYears={participantAgeYears}
                  />
                  <NotesModal pageKey={dataStorePageKeys.MSK_LYING} pefaID={pefaAssessment.id} />
                  <InTestMedicalClearanceModal
                    firstName={referralDetails.firstName}
                    lastName={referralDetails.lastName}
                    pageKey={dataStorePageKeys.MSK_LYING}
                    pefaID={pefaAssessment.id}
                    medicalPractice={medicalHistoryData.medicalPractitioner}
                    medicalPractitioner={medicalHistoryData.medicalPractice}
                  />
                </Grid>
              </Grid>
            </Container>
            <FormNavigator
              forwardTarget={BALANCETEST}
              handleSubmit={handleSubmit(submitForm)}
              warning="If you suspect a current injury, please follow protocol for obtaining a JobFit System Medical Clearance"
              disabledNextButton={
                endAssessment &&
                endAssessment.endAssessmentReason !== "" &&
                endAssessment.endAssessmentNotes !== "" &&
                endAssessment.endAssessmentPage === dataStorePageKeys.MSK_LYING
              }
              selectedKey={dataStorePageKeys.MSK_LYING}
            />
          </Grid>
        </div>
      </div>
    );
  })
);

export default withAppInsights(mskLying);

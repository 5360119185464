import Axios, { AxiosResponse } from "axios";
import fileDownload from "js-file-download";
import { Constants, environmentConfig } from "../helpers/Constants";
import * as submissionHelpers from "../helpers/submissionHelpers";
import { dataStorePageKeys } from "../stores/dataStore";

enum submissionStatus {
  "SUBMITTED" = "SUBMITTED",
  "INCOMPLETE" = "INCOMPLETE",
}

export class ApiService {
  /**
   * Retrieve a list of PEFA's between the start and end dates that are waiting to be completed.
   * @param startDate The start date of the pefa request period
   * @param endDate The end date of the pefa request period
   */

  private environmentConstants: environmentConfig;

  constructor() {
    this.environmentConstants = Constants.environment();
  }

  public async getPefas(startDate: string, endDate: string): Promise<Record<string, unknown>> {
    try {
      const data = JSON.stringify({
        fromDate: startDate,
        toDate: endDate,
      });

      const apiUrl = `${this.environmentConstants.apiUrl}/api/Assessor/Bookings`;
      const response = await Axios({
        method: "post",
        url: apiUrl,
        data: data,
        headers: {
          "Content-type": "application/json",
        },
      });

      return (response as unknown) as Record<string, unknown>;
    } catch (error) {
      console.error("An error occurred while retrieving your PEFAs", error);
    }
    return {};
  }

  public async getLatestDemands(id: number): Promise<any> {
    const latestDemandURL = `${this.environmentConstants.apiUrl}/api/PEFARequests/LatestJobDemands/${id}`;
    return Axios({
      method: "get",
      url: latestDemandURL,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return "ERROR";
      });
  }

  /**
   * Retrieve the user details from the Person Utility endpoint
   */
  public async getUserDetails(): Promise<Record<string, unknown>> {
    try {
      const apiUrl = `${this.environmentConstants.apiUrl}/api/Utility/Person`;
      const response = await Axios({
        method: "get",
        url: apiUrl,
      });

      return (response as unknown) as Record<string, unknown>;
    } catch (error) {
      console.error("An error occurred while fetching your user details", error);
    }
    return {};
  }

  public async submitPefa(pefaData: any, pefaNotes: any, assessmentId: number, demands: any, isComplete: boolean): Promise<string> {
    const apiUrl = `${this.environmentConstants.apiUrl}/api/Assessor/${assessmentId}/Results`;

    let formattedData: { [key: string]: any } = {
      status: isComplete ? submissionStatus.SUBMITTED : submissionStatus.INCOMPLETE,
      discomfortComment: "",
      comments: [],
      suggestedScore: "",
      suggestedScoreReason: "",
      rawResults: JSON.stringify(pefaData),
    };

    let response: AxiosResponse<any> | undefined = undefined;

    try {
      Object.keys(pefaData).forEach((dataKey: string) => {
        let response;
        let comments = formattedData.comments;
        let responseKey = "";
        let discomfortComment = formattedData.discomfortComment;
        let suggestedScore = formattedData.suggestedScore;
        let suggestedScoreReason = formattedData.suggestedScoreReason;

        switch (dataKey) {
          case dataStorePageKeys.MEDICAL_DETAILS: {
            responseKey = "medicalDetails";
            response = submissionHelpers.MedicalDetails.formatForSubmission(pefaData[dataKey]);
            if (!!pefaNotes && Object.keys(pefaNotes).indexOf(dataKey) !== -1) {
              comments = submissionHelpers.NoteComments.formatForSubmission(formattedData["comments"] || [], pefaNotes[dataKey], dataKey);
            }

            break;
          }
          case dataStorePageKeys.ASSESSMENT_DATA: {
            responseKey = "assessmentDetails";
            response = submissionHelpers.AssessmentDetails.formatForSubmission(pefaData[dataKey]);
            if (!!pefaNotes && Object.keys(pefaNotes).indexOf(dataKey) !== -1) {
              comments = submissionHelpers.NoteComments.formatForSubmission(formattedData["comments"] || [], pefaNotes[dataKey], dataKey);
            }

            break;
          }
          case dataStorePageKeys.BASELINE_MEASUREMENTS: {
            responseKey = "baselineMeasures";
            response = submissionHelpers.BaselineMeasurements.formatForSubmission(pefaData[dataKey]);
            if (!!pefaNotes && Object.keys(pefaNotes).indexOf(dataKey) !== -1) {
              comments = submissionHelpers.NoteComments.formatForSubmission(formattedData["comments"] || [], pefaNotes[dataKey], dataKey);
            }
            break;
          }
          case dataStorePageKeys.GENERAL_OBSERVATIONS: {
            responseKey = "generalObservations";
            response = submissionHelpers.GeneralObservations.formatForSubmission(pefaData[dataKey]);
            if (!!pefaNotes && Object.keys(pefaNotes).indexOf(dataKey) !== -1) {
              comments = submissionHelpers.NoteComments.formatForSubmission(formattedData["comments"] || [], pefaNotes[dataKey], dataKey);
            }
            break;
          }
          case dataStorePageKeys.MSK_STANDING:
          case dataStorePageKeys.MSK_SITTING:
          case dataStorePageKeys.MSK_LYING: {
            responseKey = "mskResults";
            response = submissionHelpers.Musculoskeletal.formatForSubmission(formattedData[responseKey] || [], pefaData[dataKey], dataKey);
            if (!!pefaNotes && Object.keys(pefaNotes).indexOf(dataKey) !== -1) {
              comments = submissionHelpers.NoteComments.formatForSubmission(formattedData["comments"] || [], pefaNotes[dataKey], dataKey);
            }

            break;
          }
          case dataStorePageKeys.BALANCE: {
            responseKey = "balanceResults";
            response = submissionHelpers.BalanceResults.formatForSubmission(pefaData[dataKey]);
            if (!!pefaNotes && Object.keys(pefaNotes).indexOf(dataKey) !== -1) {
              comments = submissionHelpers.NoteComments.formatForSubmission(formattedData["comments"] || [], pefaNotes[dataKey], dataKey);
            }
            break;
          }
          case dataStorePageKeys.FITNESS: {
            responseKey = "fitnessResult";
            response = submissionHelpers.FitnessResult.formatForSubmission(pefaData[dataKey]);
            if (!!pefaNotes && Object.keys(pefaNotes).indexOf(dataKey) !== -1) {
              comments = submissionHelpers.NoteComments.formatForSubmission(formattedData["comments"] || [], pefaNotes[dataKey], dataKey);
            }
            break;
          }
          case dataStorePageKeys.PT_FORWARD:
          case dataStorePageKeys.PT_ABOVE:
          case dataStorePageKeys.PT_STOOP:
          case dataStorePageKeys.PT_SQUAT:
          case dataStorePageKeys.PT_KNEEL:
          case dataStorePageKeys.DT_LADDER:
          case dataStorePageKeys.DT_SIDE:
          case dataStorePageKeys.DT_CRAWL: {
            responseKey = "toleranceResults";
            response = submissionHelpers.ToleranceResults.formatForSubmission(formattedData[responseKey] || [], pefaData[dataKey], dataKey, demands[dataKey]);
            if (!!pefaNotes && Object.keys(pefaNotes).indexOf(dataKey) !== -1) {
              comments = submissionHelpers.NoteComments.formatForSubmission(formattedData["comments"] || [], pefaNotes[dataKey], dataKey);
            }
            break;
          }
          case dataStorePageKeys.MH_BENCH_TO_FLOOR:
          case dataStorePageKeys.MH_BENCH_TO_BENCH:
          case dataStorePageKeys.MH_BENCH_TO_SHOULDER:
          case dataStorePageKeys.MH_BENCH_TO_ABOVE:
          case dataStorePageKeys.MH_BILATERAL:
          case dataStorePageKeys.MH_SINGLE_L:
          case dataStorePageKeys.MH_SINGLE_R: {
            responseKey = "handlingResults";
            response = submissionHelpers.HandlingResults.formatForSubmission(formattedData[responseKey] || [], pefaData[dataKey], dataKey, demands[dataKey]);
            if (!!pefaNotes && Object.keys(pefaNotes).indexOf(dataKey) !== -1) {
              comments = submissionHelpers.NoteComments.formatForSubmission(formattedData["comments"] || [], pefaNotes[dataKey], dataKey);
            }
            break;
          }
          case dataStorePageKeys.PROGRAM_RECOMMENDATIONS: {
            responseKey = "recommendations";
            response = submissionHelpers.Recommendations.formatForSubmission(pefaData[dataKey]);
            if (!!pefaNotes && Object.keys(pefaNotes).indexOf(dataKey) !== -1) {
              comments = submissionHelpers.NoteComments.formatForSubmission(formattedData["comments"] || [], pefaNotes[dataKey], dataKey);
            }

            discomfortComment = pefaData[dataKey].discomfortComment;
            break;
          }
          case dataStorePageKeys.FINALISATION: {
            suggestedScore = pefaData[dataKey].jobFitSystemScore;
            suggestedScoreReason = pefaData[dataKey].minimalRestrictionsDescription || pefaData[dataKey].moderateRestrictionsDescription || "";
          }
        }

        if ((!!responseKey && !!response) || !!suggestedScore) {
          formattedData = {
            ...formattedData,
            [responseKey]: response,
            discomfortComment,
            comments,
            suggestedScore,
            suggestedScoreReason,
          };
        }
      });

      await Axios({
        method: "post",
        url: apiUrl,
        data: JSON.stringify(formattedData),
        timeout: 300000, //5 minutes in milliseconds.
        headers: {
          "Content-type": "application/json",
        },
      }).then(
        (callback) => {
          response = callback;
        },
        (error) => {
          console.error("Error in submit of consent", error);
          throw error;
        }
      );
    } catch (error) {
      console.error("An error occurred while submitting the PEFA", error);
    }

    if (response) {
      return (response as AxiosResponse<unknown>).status.toString();
    }
    return "ERROR";
  }
  public nextSubmit(pefaData: any, id: number, demands: any): void {
    const apiUrl = `${this.environmentConstants.apiUrl}/api/Assessor/Bookings/${id}/NextSubmission`;
    let formattedData: { [key: string]: any } = {
      discomfortComment: "",
      comments: [],
      suggestedScore: "",
      suggestedScoreReason: "",
      rawResults: JSON.stringify(pefaData),
    };
    //console.log(pefaData);

    try {
      Object.keys(pefaData).forEach((dataKey: string) => {
        let response;
        //let comments = formattedData.comments;
        let responseKey = "";
        switch (dataKey) {
          case dataStorePageKeys.ASSESSMENT_DATA: {
            responseKey = "assessmentDetails";
            response = submissionHelpers.AssessmentDetails.formatForSubmission(pefaData[dataKey]);

            break;
          }
          case dataStorePageKeys.BASELINE_MEASUREMENTS: {
            responseKey = "baselineMeasures";
            response = submissionHelpers.BaselineMeasurements.formatForSubmission(pefaData[dataKey]);
            break;
          }
          case dataStorePageKeys.GENERAL_OBSERVATIONS: {
            responseKey = "generalObservations";
            response = submissionHelpers.GeneralObservations.formatForSubmission(pefaData[dataKey]);
            break;
          }
          case dataStorePageKeys.MSK_STANDING:
          case dataStorePageKeys.MSK_SITTING:
          case dataStorePageKeys.MSK_LYING: {
            responseKey = "mskResults";
            response = submissionHelpers.Musculoskeletal.formatForSubmission(formattedData[responseKey] || [], pefaData[dataKey], dataKey);
            break;
          }
          case dataStorePageKeys.BALANCE: {
            responseKey = "balanceResults";
            response = submissionHelpers.BalanceResults.formatForSubmission(pefaData[dataKey]);
            break;
          }
          case dataStorePageKeys.FITNESS: {
            responseKey = "fitnessResult";
            response = submissionHelpers.FitnessResult.formatForSubmission(pefaData[dataKey]);
            break;
          }
          case dataStorePageKeys.PT_FORWARD:
          case dataStorePageKeys.PT_ABOVE:
          case dataStorePageKeys.PT_STOOP:
          case dataStorePageKeys.PT_SQUAT:
          case dataStorePageKeys.PT_KNEEL:
          case dataStorePageKeys.DT_LADDER:
          case dataStorePageKeys.DT_SIDE:
          case dataStorePageKeys.DT_CRAWL: {
            responseKey = "toleranceResults";
            response = submissionHelpers.ToleranceResults.formatForSubmission(formattedData[responseKey] || [], pefaData[dataKey], dataKey, demands[dataKey]);
            break;
          }
          case dataStorePageKeys.MH_BENCH_TO_FLOOR:
          case dataStorePageKeys.MH_BENCH_TO_BENCH:
          case dataStorePageKeys.MH_BENCH_TO_SHOULDER:
          case dataStorePageKeys.MH_BENCH_TO_ABOVE:
          case dataStorePageKeys.MH_BILATERAL:
          case dataStorePageKeys.MH_SINGLE_L:
          case dataStorePageKeys.MH_SINGLE_R: {
            responseKey = "handlingResults";
            response = submissionHelpers.HandlingResults.formatForSubmission(formattedData[responseKey] || [], pefaData[dataKey], dataKey, demands[dataKey]);
            break;
          }
        }

        if (!!responseKey && !!response) {
          formattedData = {
            ...formattedData,
            [responseKey]: response,
          };
        }
      });

      // this is fire and forget, we aren't concerned with waiting for a response
      Axios({
        method: "post",
        url: apiUrl,
        data: {
          page: window.location.pathname,
          results: JSON.stringify(formattedData),
        },
        timeout: 120000,
        headers: {
          "Content-type": "application/json",
        },
      });
    } catch (error) {
      console.error("An error occurred while submitting the PEFA", error);
    }
  }
  public async submitCheckIn(data: any, assessmentId: number): Promise<string> {
    const checkinURL = `${this.environmentConstants.apiUrl}/api/Assessor/Bookings/${assessmentId}/CheckIn`;

    return Axios({
      method: "post",
      url: checkinURL,
      data: JSON.stringify({ content: JSON.stringify(data) }),
      timeout: 120000,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return (response as AxiosResponse<unknown>).status.toString();
      })
      .catch((error) => {
        throw error;
      });
  }
  public async submitRating(id: number, data: any): Promise<string> {
    const ratingURL = `${this.environmentConstants.apiUrl}/api/PEFARequests/${id}/Rating`;

    return Axios({
      method: "post",
      url: ratingURL,
      data: data,
      timeout: 120000,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return (response as AxiosResponse<unknown>).status.toString();
      })
      .catch((error) => {
        return "ERROR";
      });
  }
  public async getAssessors(id: number, assessors: any) {
    const ratingURL = `${this.environmentConstants.apiUrl}/api/PEFARequests/${id}/GetAssessorList`;

    return Axios({
      method: "get",
      url: ratingURL,
      data: assessors,
      timeout: 120000,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return (response as AxiosResponse<unknown>).status.toString();
      })
      .catch((error) => {
        return "ERROR";
      });
  }
  public async submitConsent(data: any, assessmentId: number): Promise<string> {
    const checkinURL = `${this.environmentConstants.apiUrl}/api/Assessor/Bookings/${assessmentId}/Consent`;

    return Axios({
      method: "post",
      url: checkinURL,
      data: JSON.stringify({ content: JSON.stringify(data) }),
      timeout: 120000,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return (response as AxiosResponse<unknown>).status.toString();
      })
      .catch((error) => {
        return "ERROR";
      });
  }
  //generate the MC
  public async getMedicalClearanceDocument(bookingId: number, fileName: string, clearances: { [key: string]: string }, doctorName: string, practice: string): Promise<any> {
    const clearanceDocumentUrl = `${this.environmentConstants.apiUrl}/api/Assessor/Bookings/${bookingId}/MedicalLetter`;

    return Axios({
      method: "post",
      url: clearanceDocumentUrl,
      data: JSON.stringify({ clearances: clearances, doctorName, practice }),
      timeout: 120000,
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return fileDownload(response.data, fileName);
      })
      .catch((error) => {
        console.error(error);
        return "ERROR";
      });
  }
  //generate the MC
  public async getEndPEFAMedicalClearanceDocument(bookingId: number, fileName: string, clearances: { [key: string]: string }, doctorName: string, practice: string): Promise<any> {
    const clearanceDocumentUrl = `${this.environmentConstants.apiUrl}/api/Assessor/GenerateEndPefaMedicalLetter/${bookingId}`;

    return Axios({
      method: "post",
      url: clearanceDocumentUrl,
      data: JSON.stringify({ clearances: clearances, doctorName, practice }),
      timeout: 120000,
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return fileDownload(response.data, fileName);
      })
      .catch((error) => {
        console.error(error);
        return "ERROR";
      });
  }
  public async uploadMC(id: string, fileName: string, condition: string): Promise<any> {
    const clearanceDocumentUrl = `${this.environmentConstants.apiUrl}/api/MedicalClearances/${id}/PostClearanceFromApp`;
    return Axios({
      method: "POST",
      url: clearanceDocumentUrl,
      data: JSON.stringify({ id, fileName, condition }),
      timeout: 120000,
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      return "ERROR";
    });
  }
  public async uploadPaperMC(
    condition: string,
    conditionText: string,
    otherInfo: string,
    id: string,
    doctorName: string,
    practice: string,
    file: File,
    bookingId: string
  ): Promise<any> {
    const clearanceDocumentUrl = `${this.environmentConstants.apiUrl}/api/MedicalClearances/${id}/PostPaperMCClearanceFromApp`;
    const form = new FormData();
    form.append("pefaId", id);
    form.append("condition", condition);
    form.append("conditionText", conditionText);
    form.append("otherInfo", otherInfo);
    form.append("doctor", doctorName);
    form.append("practice", practice);
    form.append("file", file, file.name);
    form.append("bookingId", bookingId);
    try {
      return Axios.post(clearanceDocumentUrl, form);
    } catch (ex: any) {
      console.log(ex);
    }
  }

  public async getMedicalCertificate(id: any, fileName: string): Promise<any> {
    const certificateURL = `${this.environmentConstants.apiUrl}/api/PEFARequests/getReport/${id}`;

    return Axios({
      method: "get",
      url: certificateURL,
      timeout: 120000,
      responseType: "blob",
    })
      .then((response) => {
        return fileDownload(response.data, fileName);
      })
      .catch((error) => {
        return "ERROR";
      });
  }
  public async cancelPaperMC(medicalCertId: string, id: string, medicalClearanceId: string): Promise<any> {
    const clearanceDocumentUrl = `${this.environmentConstants.apiUrl}/api/MedicalClearances/CancelPaperMedicalClearance/` + id + "/" + medicalCertId + "/" + medicalClearanceId;
    try {
      return Axios.post(clearanceDocumentUrl);
    } catch (ex: any) {
      console.log(ex);
    }
  }
}

const apiService = new ApiService();

export default apiService;

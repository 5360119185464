import { keyPairData } from ".";
import { limitingFactors, limitingFactorsFriendly } from "../../components/groupedComponents/MobilityTestDataKeys";
import { strengthDiscomfortFactors } from "../../components/groupedComponents/StrengthTestDataKeys";
import { dataStorePageKeys, dataStorePageKeysFriendly } from "../../stores/dataStore";
import {
  mskStandingMobilityDataKeys,
  mskSittingMobilityDataKeys,
  mskSittingStrengthDataKeys,
  mskLyingMobilityDataKeys,
  mskLyingStrengthDataKeys,
  mskStandingSpecialTestDataKeys,
  mskSittingSpecialTestDataKeys,
  mskLyingSpecialTestDataKeys,
} from "../../views/app/Musculoskeletal/mskDataKeys";

export default class musculoskeletal {
  public static formatForSubmission(
    existingData: any,
    mskData: {
      [key: string]: any;
    },
    dataKey: dataStorePageKeys
  ): keyPairData[] {
    const formattedData: keyPairData[] = existingData;
    const setName = dataStorePageKeysFriendly[dataKey as dataStorePageKeys];

    let resultsList: keyPairData = {};

    let mobilityDataKeys: typeof mskStandingMobilityDataKeys | typeof mskSittingMobilityDataKeys | typeof mskLyingMobilityDataKeys = {};
    let strengthDataKeys: typeof mskSittingStrengthDataKeys | typeof mskLyingStrengthDataKeys = {};
    let specialTestDataKeys: typeof mskStandingSpecialTestDataKeys | typeof mskSittingSpecialTestDataKeys | typeof mskLyingSpecialTestDataKeys = {};

    //Determine the dataset to use.
    switch (dataKey) {
      case dataStorePageKeys.MSK_STANDING: {
        mobilityDataKeys = mskStandingMobilityDataKeys;
        specialTestDataKeys = mskStandingSpecialTestDataKeys;
        break;
      }
      case dataStorePageKeys.MSK_SITTING: {
        mobilityDataKeys = mskSittingMobilityDataKeys;
        strengthDataKeys = mskSittingStrengthDataKeys;
        specialTestDataKeys = mskSittingSpecialTestDataKeys;
        break;
      }
      case dataStorePageKeys.MSK_LYING: {
        mobilityDataKeys = mskLyingMobilityDataKeys;
        strengthDataKeys = mskLyingStrengthDataKeys;
        specialTestDataKeys = mskLyingSpecialTestDataKeys;
        break;
      }
    }

    //---------------------------- Mobility Data ----------------------------------------------------------------------------
    if (!!mobilityDataKeys) {
      Object.keys(mobilityDataKeys).forEach((setKey: string) => {
        if (mskData[`${mobilityDataKeys[setKey]}_limitingFactor`] !== limitingFactors.SELECT) {
          let result = "";
          let discomfort: keyPairData = {};

          //Determine the string equivalent of the mobility result value based on the checkboxes.
          if (mskData[`${mobilityDataKeys[setKey]}_lessThanOneThird`]) {
            result = "< 1/3";
          } else if (mskData[`${mobilityDataKeys[setKey]}_lessThanTwoThirds`]) {
            result = "< 2/3";
          } else if (mskData[`${mobilityDataKeys[setKey]}_greaterThanTwoThirds`]) {
            result = "> 2/3";
          } else if (mskData[`${mobilityDataKeys[setKey]}_unable`]) {
            result = "unable";
          }

          if (mskData[`${mobilityDataKeys[setKey]}_limitingFactor`] === limitingFactors.PAIN) {
            let intensity = "";
            let status = "";
            const type: string[] = [];

            //prepare the intensity
            if (!!mskData[`${mobilityDataKeys[setKey]}_intensityVas`]) {
              intensity = mskData[`${mobilityDataKeys[setKey]}_intensityVas`];
            } else if (mskData[`${mobilityDataKeys[setKey]}_intensityMild`]) {
              intensity = "Mild";
            } else if (mskData[`${mobilityDataKeys[setKey]}_intensityMod`]) {
              intensity = "Moderate";
            } else if (mskData[`${mobilityDataKeys[setKey]}_intensitySevere`]) {
              intensity = "Severe";
            }

            //prepare the status
            if (mskData[`${mobilityDataKeys[setKey]}_discomfortResolvedImmediately`]) {
              status = "Resolved immediately";
            } else if (mskData[`${mobilityDataKeys[setKey]}_discomfortPersisting`]) {
              status = "Persisting";
            } else if (mskData[`${mobilityDataKeys[setKey]}_discomfortResolvedWithRest`]) {
              status = "Resolved with rest";
            } else if (mskData[`${mobilityDataKeys[setKey]}_discomfortIncreasing`]) {
              status = "Increasing";
            }

            //prepare the type
            if (mskData[`${mobilityDataKeys[setKey]}_discomfortTypeAche`]) {
              type.push("Ache");
            } else if (mskData[`${mobilityDataKeys[setKey]}_discomfortTypeSharp`]) {
              type.push("Sharp");
            } else if (mskData[`${mobilityDataKeys[setKey]}_discomfortTypeBurning`]) {
              type.push("Burning");
            } else if (mskData[`${mobilityDataKeys[setKey]}_discomfortTypeTightness`]) {
              type.push("Tightness");
            } else if (mskData[`${mobilityDataKeys[setKey]}_discomfortTypePulling`]) {
              type.push("Pulling");
            } else if (mskData[`${mobilityDataKeys[setKey]}_discomfortTypeOther`]) {
              type.push("Other");
            }

            discomfort = {
              location: mskData[`${mobilityDataKeys[setKey]}_location`],
              intensity,
              type,
              status,
            };
          }

          const mobilityData: { [key: string]: any } = {
            name: mobilityDataKeys[setKey],
            result,
            range: mskData[`${mobilityDataKeys[setKey]}_objectiveRange`],
            limitFactor: limitingFactorsFriendly[mskData[`${mobilityDataKeys[setKey]}_limitingFactor`] as limitingFactors],
            comment: mskData[`${mobilityDataKeys[setKey]}_limitingFactorNotes`],
            discomfort,
          };

          resultsList = {
            ...resultsList,
            [mobilityDataKeys[setKey]]: mobilityData,
          };
        }
      });
    }

    //---------------------------- Strength Data ----------------------------------------------------------------------------
    if (!!strengthDataKeys) {
      Object.keys(strengthDataKeys).forEach((setKey: string) => {
        if (mskData[`${strengthDataKeys[setKey]}_discomfort`] !== strengthDiscomfortFactors.SELECT) {
          let discomfort: keyPairData = {};

          if (mskData[`${strengthDataKeys[setKey]}_discomfort`] === strengthDiscomfortFactors.YES) {
            discomfort = {};
          }

          const strengthData: { [key: string]: any } = {
            name: strengthDataKeys[setKey],
            result: mskData[`${strengthDataKeys[setKey]}_grade`],
            comment: mskData[`${strengthDataKeys[setKey]}_discomfort`],
            range: mskData[`${strengthDataKeys[setKey]}_description`],
            discomfort,
          };

          resultsList = {
            ...resultsList,
            [strengthDataKeys[setKey]]: strengthData,
          };
        }
      });
    }

    //---------------------------- Special Test Data ------------------------------------------------------------------------
    if (!!specialTestDataKeys) {
      Object.keys(specialTestDataKeys).forEach((setKey: string) => {
        if (!!mskData[specialTestDataKeys[setKey]] || mskData[specialTestDataKeys[setKey]] !== "NT") {
          const result = mskData[specialTestDataKeys[setKey]] !== undefined ? mskData[specialTestDataKeys[setKey]] : "";

          const specialTestData: { [key: string]: any } = {
            name: specialTestDataKeys[setKey],
            result,
            comment: "",
            discomfort: {},
          };

          resultsList = {
            ...resultsList,
            [specialTestDataKeys[setKey]]: specialTestData,
          };
        }
      });
    }

    if (!!resultsList) {
      formattedData.push({ name: setName, results: resultsList });
    }

    return formattedData;
  }
}

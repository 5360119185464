import { keyPairData } from ".";
import { dataStorePageKeys, dataStorePageKeysFriendly } from "../../stores/dataStore";

export default class noteComments {
  public static formatForSubmission(
    existingData: keyPairData[],
    noteComments: {
      [key: string]: any;
    },
    dataKey: dataStorePageKeys
  ): keyPairData[] {
    const formattedData: keyPairData[] = existingData;

    if (!!noteComments) {
      const comment = {
        area: dataStorePageKeysFriendly[dataKey],
        content: noteComments.note,
        created: noteComments.timestamp,
      };

      formattedData.push(comment);
    }

    return formattedData;
  }
}

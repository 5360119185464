import React, { ChangeEvent, useEffect } from "react";
import { ProfileCard, FormNavigator, Stepper, Accordion, IOSSwitch, NotesModal, InTestMedicalClearanceModal } from "../../../components/";
import { ClearanceBlueIcon } from "../../../assets/images/";
import generalObservationsValidation from "./validation";

import { Grid, Typography, Container, FormControl, FormLabel, FormControlLabel, OutlinedInput, Card, FormGroup, Checkbox, TextField } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import { Root } from "../../../stores";
import { differenceInYears } from "date-fns";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { dataStorePageKeys } from "../../../stores/dataStore";
import { DASHBOARD, MSKSTANDING } from "../../../routes/paths";
import { useHistory } from "react-router-dom";
import { getAppInsights } from "../../../components/telemetry";
import withAppInsights from "../AppInsights";

interface GeneralObservationsProps {
  rootStore?: Root;
  navStore?: any;
}

const GeneralObservations = inject("rootStore")(
  observer((props: GeneralObservationsProps) => {
    let pefaAssessment: any;
    let prevData: any;
    let referralDetails: any = null;
    let medicalHistoryData: any;
    let endAssessment: any;
    let participantAgeYears = 0;
    const history = useHistory();
    const appInsights = getAppInsights();

    if (props.rootStore?.pefaStore.selectedPefa) {
      pefaAssessment = props.rootStore?.pefaStore.selectedPefa[0];
      prevData = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.GENERAL_OBSERVATIONS);
      referralDetails = props.rootStore?.dataStore.getCheckInData(pefaAssessment.id, dataStorePageKeys.REFERRAL_DETAILS);
      medicalHistoryData = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.MEDICAL_DETAILS);
      endAssessment = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.END_ASSESSMENT);
      participantAgeYears = differenceInYears(new Date(), new Date(referralDetails?.dob ?? pefaAssessment.pefArequest.worker.dateOfBirth));
    } else {
      history.push(DASHBOARD);
      return <></>;
    }

    const { register, handleSubmit, errors, setValue } = useForm({
      resolver: yupResolver(generalObservationsValidation),
    });

    const [state, setState] = useState({
      isLoading: true,
      yesAllNilSignificant: prevData ? prevData.yesAllNilSignificant : false,
      viewAll: false,

      //Posture
      postureNilSignificant: prevData ? prevData.postureNilSignificant : false,
      postureForwardHeadedPosture: prevData ? prevData.postureForwardHeadedPosture : false,
      postureFlatBackPosture: prevData ? prevData.postureFlatBackPosture : false,
      postureSwayBackPosture: prevData ? prevData.postureSwayBackPosture : false,
      postureScapularWinging: prevData ? prevData.postureScapularWinging : false,
      postureRoundedShoulders: prevData ? prevData.postureRoundedShoulders : false,
      postureGenusVarusBow: prevData ? prevData.postureGenusVarusBow : false,
      postureAssymetry: prevData ? prevData.postureAssymetry : false,
      postureNotes: prevData ? prevData.postureNotes : "",
      postureExpanded: false,
      postureEdited: false,

      //Build
      buildNilSignificant: prevData ? prevData.buildNilSignificant : false,
      buildGeneralReducedMuscleBulk: prevData ? prevData.buildGeneralReducedMuscleBulk : false,
      buildIncreasedAdiposeTissue: prevData ? prevData.buildIncreasedAdiposeTissue : false,
      buildMuscularAtrophy: prevData ? prevData.buildMuscularAtrophy : false,
      buildNotes: prevData ? prevData.buildNotes : "",
      buildExpanded: false,
      buildEdited: false,

      //oedema & inflammation
      oedemaLocation: prevData ? prevData.oedemaLocation : "",
      oedemaSeverityMild: prevData ? prevData.oedemaSeverityMild : false,
      oedemaSeverityModerate: prevData ? prevData.oedemaSeverityModerate : false,
      oedemaSeveritySevere: prevData ? prevData.oedemaSeveritySevere : false,
      oedemainflammationNotes: prevData ? prevData.oedemainflammationNotes : "",
      oedemainflammationNilSignificant: prevData ? prevData.oedemainflammationNilSignificant : false,
      oedemainflammationExpanded: false,
      oedemainflammationEdited: false,

      //Scars
      scarNotes: prevData ? prevData.scarNotes : "",
      scarsNilSignificant: prevData ? prevData.scarsNilSignificant : false,
      scarsExpanded: false,
      scarsEdited: false,

      //Gait
      gaitReducedWeightBearingLeft: prevData ? prevData.gaitReducedWeightBearingLeft : "",
      gaitReducedWeightBearingRight: prevData ? prevData.gaitReducedWeightBearingRight : "",
      gaitTrendelenburg: prevData ? prevData.gaitTrendelenburg : "",
      gaitNotes: prevData ? prevData.gaitNotes : "",
      gaitNilSignificant: prevData ? prevData.gaitNilSignificant : false,
      gaitExpanded: false,
      gaitEdited: false,

      //Braces & Prosthesis
      bracesProsthesisProsthesis: prevData ? prevData.bracesProsthesisProsthesis : "",
      bracesProsthesisBrace: prevData ? prevData.bracesProsthesisBrace : "",
      bracesProsthesisOtherSupportDevice: prevData ? prevData.bracesProsthesisOtherSupportDevice : "",
      bracesProsthesisNotes: prevData ? prevData.bracesProsthesisNotes : "",
      bracesProsthesisNilSignificant: prevData ? prevData.bracesProsthesisNilSignificant : false,
      bracesProsthesisExpanded: false,
      bracesProsthesisEdited: false,
    });

    const handleValueChange = (event: any): void => {
      event.persist();
      setState((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
      }));
    };

    const handleCheckboxChange = async (event: any, logic: any = null): Promise<void> => {
      const control = event.currentTarget;
      const formComponent = control.name as string;

      //Store the current checkbox's value to the state using the checkbox name attribute as the key.
      //This is set as an await to make sure that it has saved before moving on. This may not be necessary,
      //but I'll leave it here for now. It's handy for testing
      setState((prevState) => ({
        ...prevState,
        [formComponent]: control.checked,
      }));

      //If a reference to a function has been passed, execute it, passing in the control checked state.
      if (!!logic) {
        logic(control.checked);
      }
    };

    const changeAllChildCheckboxes = (checked: boolean, targetCheckboxes: string[]): void => {
      targetCheckboxes.forEach((child) => {
        //Set the checkbox values in the form, flagging them as dirty.
        setValue(child, checked, { shouldDirty: true });

        //Update the mapped state values. This updates the field visually.
        setState((prevState) => ({
          ...prevState,
          [child]: checked,
        }));
      });
    };

    const changeParentCheckbox = (childChecked: boolean, parentCheckbox: string) => {
      if (!childChecked) {
        setValue(parentCheckbox, false, { shouldDirty: true });
        setState((prevState) => ({
          ...prevState,
          [parentCheckbox]: false,
        }));
      }
    };

    const handleViewAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setState({
        ...state,
        viewAll: event.target.checked,
        postureExpanded: event.target.checked,
        buildExpanded: event.target.checked,
        oedemainflammationExpanded: event.target.checked,
        scarsExpanded: event.target.checked,
        gaitExpanded: event.target.checked,
        bracesProsthesisExpanded: event.target.checked,
      });
    };

    const toggleAccordion = async (eventValue: React.ChangeEvent<HTMLDivElement>, componentName: string) => {
      await setState((prevState) => ({
        ...prevState,
        [componentName]: eventValue,
      }));

      if (!eventValue && state.viewAll) {
        await setState((prevState) => ({
          ...prevState,
          viewAll: false,
        }));
      }
    };

    const handleSeverityCheckboxChange = (event: ChangeEvent<any>) => {
      event.persist();
      setState((prevState) => ({
        ...prevState,
        oedemaSeverityMild: false,
        oedemaSeverityModerate: false,
        oedemaSeveritySevere: false,
        [event.target.name]: event.target.checked,
      }));
    };

    useEffect(() => {
      let postureEdited = false;
      let buildEdited = false;
      let oedemainflammationEdited = false;
      let scarsEdited = false;
      let gaitEdited = false;
      let bracesProsthesisEdited = false;

      //Posture
      if (
        state.postureForwardHeadedPosture ||
        state.postureFlatBackPosture ||
        state.postureSwayBackPosture ||
        state.postureScapularWinging ||
        state.postureRoundedShoulders ||
        state.postureGenusVarusBow ||
        state.postureAssymetry ||
        state.postureNotes !== ""
      ) {
        postureEdited = true;
      }

      //Build
      if (state.buildGeneralReducedMuscleBulk || state.buildIncreasedAdiposeTissue || state.buildMuscularAtrophy || state.buildNotes !== "") {
        buildEdited = true;
      }

      //Oedema & inflammation
      if (state.oedemaLocation !== "" || state.oedemaSeverityMild || state.oedemaSeverityModerate || state.oedemaSeveritySevere || state.oedemainflammationNotes !== "") {
        oedemainflammationEdited = true;
      }

      //Scars
      scarsEdited = state.scarNotes !== "";

      //Gait
      if (state.gaitReducedWeightBearingLeft !== "" || state.gaitReducedWeightBearingRight !== "" || state.gaitTrendelenburg !== "" || state.gaitNotes !== "") {
        gaitEdited = true;
      }

      //Braces & Prosthesis
      if (state.bracesProsthesisProsthesis !== "" || state.bracesProsthesisBrace !== "" || state.bracesProsthesisOtherSupportDevice !== "" || state.bracesProsthesisNotes !== "") {
        bracesProsthesisEdited = true;
      }

      setState((prevState) => ({
        ...prevState,
        postureEdited,
        buildEdited,
        oedemainflammationEdited,
        scarsEdited,
        gaitEdited,
        bracesProsthesisEdited,
      }));
    }, [
      state.postureForwardHeadedPosture,
      state.postureFlatBackPosture,
      state.postureSwayBackPosture,
      state.postureScapularWinging,
      state.postureRoundedShoulders,
      state.postureGenusVarusBow,
      state.postureAssymetry,
      state.postureNotes,
      state.buildGeneralReducedMuscleBulk,
      state.buildIncreasedAdiposeTissue,
      state.buildMuscularAtrophy,
      state.buildNotes,
      state.oedemaLocation,
      state.oedemaSeverityMild,
      state.oedemaSeverityModerate,
      state.oedemaSeveritySevere,
      state.oedemainflammationNotes,
      state.scarNotes,
      state.gaitReducedWeightBearingLeft,
      state.gaitReducedWeightBearingRight,
      state.gaitTrendelenburg,
      state.gaitNotes,
      state.bracesProsthesisProsthesis,
      state.bracesProsthesisBrace,
      state.bracesProsthesisOtherSupportDevice,
      state.bracesProsthesisNotes,
    ]);

    useEffect(() => {
      const timeout = setTimeout(() => {
        props.rootStore?.dataStore.savePartialData(state, pefaAssessment.id, dataStorePageKeys.GENERAL_OBSERVATIONS);
      }, 500);

      return () => clearTimeout(timeout);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      state.yesAllNilSignificant,
      state.postureNilSignificant,
      state.postureForwardHeadedPosture,
      state.postureFlatBackPosture,
      state.postureSwayBackPosture,
      state.postureScapularWinging,
      state.postureRoundedShoulders,
      state.postureGenusVarusBow,
      state.postureAssymetry,
      state.postureNotes,
      state.buildNilSignificant,
      state.buildGeneralReducedMuscleBulk,
      state.buildIncreasedAdiposeTissue,
      state.buildMuscularAtrophy,
      state.buildNotes,
      state.oedemaLocation,
      state.oedemaSeverityMild,
      state.oedemaSeverityModerate,
      state.oedemaSeveritySevere,
      state.oedemainflammationNotes,
      state.oedemainflammationNilSignificant,
      state.gaitReducedWeightBearingLeft,
      state.gaitReducedWeightBearingRight,
      state.gaitTrendelenburg,
      state.gaitNotes,
      state.gaitNilSignificant,
      state.bracesProsthesisProsthesis,
      state.bracesProsthesisBrace,
      state.bracesProsthesisOtherSupportDevice,
      state.bracesProsthesisNotes,
      state.bracesProsthesisNilSignificant,
    ]);

    useEffect(() => {
      //Replicate the onUnmount event handler from class components.
      return () => {
        appInsights.trackEvent({ name: "intermediateSubmission", properties: { bookingId: pefaAssessment.id, dataKey: dataStorePageKeys.GENERAL_OBSERVATIONS, data: state } });
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submitForm = (data: any, forwardTarget: any) => {
      props.rootStore?.dataStore.saveData(data, pefaAssessment.id, dataStorePageKeys.GENERAL_OBSERVATIONS);
      const pefaData = { ...props.rootStore?.dataStore.pefaData[pefaAssessment.id] };
      // take snapshot of assessment and store against booking
      props.rootStore?.pefaStore.nextSubmit(pefaData, pefaAssessment.id);
      history.push(forwardTarget);
    };

    const nilSignificantChildren = [
      "postureNilSignificant",
      "buildNilSignificant",
      "oedemainflammationNilSignificant",
      "scarsNilSignificant",
      "gaitNilSignificant",
      "bracesProsthesisNilSignificant",
    ];

    return (
      <div className="FormStyler">
        <div className="AssessmentDetails">
          <Grid item container direction="column" xs={12}>
            <Stepper targetStep={"Observations & Musculosketal"} />
            <Container maxWidth={"lg"}>
              <Grid item container direction="row" xs={12}>
                <Grid item container xs={9}>
                  <Card className="titleCard" variant="outlined">
                    <Typography variant="h2" className="cardHeroHeading">
                      <img src={ClearanceBlueIcon} alt="Success" /> General Observations
                    </Typography>
                  </Card>
                  <Grid item container xs={12}>
                    <div className="formControllerContainer">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="yesAllNilSignificant"
                            name="yesAllNilSignificant"
                            icon={<span className="checkboxIcon"></span>}
                            checkedIcon={<span className="checkboxIconChecked"></span>}
                            color="primary"
                          />
                        }
                        label="All Nil Significant"
                        inputRef={register}
                        checked={state.yesAllNilSignificant}
                        onChange={(event) => {
                          handleCheckboxChange(event, (checked: boolean) => changeAllChildCheckboxes(checked, nilSignificantChildren));
                        }}
                      />
                      <FormControlLabel control={<IOSSwitch checked={state.viewAll} onChange={handleViewAllChange} name="checkedB" />} label="View All" />
                    </div>
                    {/* 
                    |
                      Posture
                    |
                    */}
                    <form className="PefaForm">
                      <Accordion
                        edited={state.postureEdited}
                        inputName="postureNilSignificant"
                        label="Posture - no abnormalities observed"
                        innerRef={register}
                        checked={state.postureNilSignificant}
                        expanded={state.postureExpanded}
                        innerValidationError={!!errors.posture?.message}
                        validationMessage={errors.posture?.message}
                        checkedHandler={(event: any) => handleCheckboxChange(event, (checked: boolean) => changeParentCheckbox(checked, "yesAllNilSignificant"))}
                        expandedHandler={(event: any) => {
                          toggleAccordion(event, "postureExpanded");
                        }}
                      >
                        <FormControl component="fieldset">
                          <FormLabel component="legend">Select Area</FormLabel>
                          <FormGroup>
                            <div className="flexer">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    id="postureForwardHeadedPosture"
                                    name="postureForwardHeadedPosture"
                                    icon={<span className="checkboxIcon"></span>}
                                    checkedIcon={<span className="checkboxIconChecked"></span>}
                                    color="primary"
                                    defaultChecked={state.postureForwardHeadedPosture}
                                    onChange={(event) => handleCheckboxChange(event)}
                                  />
                                }
                                label="Forward Headed Posture"
                                inputRef={register}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="postureFlatBackPosture"
                                    id="postureFlatBackPosture"
                                    icon={<span className="checkboxIcon"></span>}
                                    checkedIcon={<span className="checkboxIconChecked"></span>}
                                    color="primary"
                                    defaultChecked={state.postureFlatBackPosture}
                                    onChange={(event) => handleCheckboxChange(event)}
                                  />
                                }
                                label="Flat Back Posture"
                                inputRef={register}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="postureSwayBackPosture"
                                    id="postureSwayBackPosture"
                                    icon={<span className="checkboxIcon"></span>}
                                    checkedIcon={<span className="checkboxIconChecked"></span>}
                                    color="primary"
                                    defaultChecked={state.postureSwayBackPosture}
                                    onChange={(event) => handleCheckboxChange(event)}
                                  />
                                }
                                label="Sway Back Posture"
                                inputRef={register}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="postureScapularWinging"
                                    id="postureScapularWinging"
                                    icon={<span className="checkboxIcon"></span>}
                                    checkedIcon={<span className="checkboxIconChecked"></span>}
                                    color="primary"
                                    defaultChecked={state.postureScapularWinging}
                                    onChange={(event) => handleCheckboxChange(event)}
                                  />
                                }
                                label="Scapular Winging"
                                inputRef={register}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="postureRoundedShoulders"
                                    id="postureRoundedShoulders"
                                    icon={<span className="checkboxIcon"></span>}
                                    checkedIcon={<span className="checkboxIconChecked"></span>}
                                    color="primary"
                                    defaultChecked={state.postureRoundedShoulders}
                                    onChange={(event) => handleCheckboxChange(event)}
                                  />
                                }
                                label="Rounded Shoulders"
                                inputRef={register}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="postureGenusVarusBow"
                                    id="postureGenusVarusBow"
                                    icon={<span className="checkboxIcon"></span>}
                                    checkedIcon={<span className="checkboxIconChecked"></span>}
                                    color="primary"
                                    defaultChecked={state.postureGenusVarusBow}
                                    onChange={(event) => handleCheckboxChange(event)}
                                  />
                                }
                                label="Genus Varus (Bow)"
                                inputRef={register}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="postureAssymetry"
                                    id="postureAssymetry"
                                    icon={<span className="checkboxIcon"></span>}
                                    checkedIcon={<span className="checkboxIconChecked"></span>}
                                    color="primary"
                                    defaultChecked={state.postureAssymetry}
                                    onChange={(event) => handleCheckboxChange(event)}
                                  />
                                }
                                label="Assymetry (Describe Below)"
                                inputRef={register}
                              />
                            </div>
                          </FormGroup>
                          <FormGroup>
                            <FormLabel component="legend">Posture Notes</FormLabel>
                            <TextField
                              label="Write something"
                              id="postureNotes"
                              name="postureNotes"
                              multiline
                              fullWidth
                              variant="outlined"
                              className="outlineTextArea"
                              inputRef={register}
                              value={state.postureNotes}
                              onChange={handleValueChange}
                            />
                          </FormGroup>
                        </FormControl>
                      </Accordion>
                      {/* 
                    |
                      Build
                    |
                    */}
                      <Accordion
                        inputName="buildNilSignificant"
                        checked={state.buildNilSignificant}
                        expanded={state.buildExpanded}
                        edited={state.buildEdited}
                        innerRef={register}
                        label="Build - within normal limits"
                        innerValidationError={!!errors.build?.message}
                        validationMessage={errors.build?.message}
                        checkedHandler={(event: any) => handleCheckboxChange(event, (checked: boolean) => changeParentCheckbox(checked, "yesAllNilSignificant"))}
                        expandedHandler={(event: any) => {
                          toggleAccordion(event, "buildExpanded");
                        }}
                      >
                        <FormControl component="fieldset">
                          <FormGroup>
                            <div className="flexer">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="buildGeneralReducedMuscleBulk"
                                    id="buildGeneralReducedMuscleBulk"
                                    icon={<span className="checkboxIcon"></span>}
                                    checkedIcon={<span className="checkboxIconChecked"></span>}
                                    color="primary"
                                    defaultChecked={state.buildGeneralReducedMuscleBulk}
                                    onChange={(event) => handleCheckboxChange(event)}
                                  />
                                }
                                label="General reduced muscle bulk"
                                inputRef={register}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="buildIncreasedAdiposeTissue"
                                    id="buildIncreasedAdiposeTissue"
                                    icon={<span className="checkboxIcon"></span>}
                                    checkedIcon={<span className="checkboxIconChecked"></span>}
                                    color="primary"
                                    defaultChecked={state.buildIncreasedAdiposeTissue}
                                    onChange={(event) => handleCheckboxChange(event)}
                                  />
                                }
                                label="Increased adipose tissue"
                                inputRef={register}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="buildMuscularAtrophy"
                                    id="buildMuscularAtrophy"
                                    icon={<span className="checkboxIcon"></span>}
                                    checkedIcon={<span className="checkboxIconChecked"></span>}
                                    color="primary"
                                    defaultChecked={state.buildMuscularAtrophy}
                                    onChange={(event) => handleCheckboxChange(event)}
                                  />
                                }
                                label="Muscular Atrophy (Describe Below)"
                                inputRef={register}
                              />
                            </div>
                          </FormGroup>
                          <FormGroup>
                            <FormLabel component="legend">Build Notes</FormLabel>
                            <TextField
                              label="Write something"
                              id="buildNotes"
                              name="buildNotes"
                              multiline
                              fullWidth
                              variant="outlined"
                              className="outlineTextArea"
                              inputRef={register}
                              value={state.buildNotes}
                              onChange={handleValueChange}
                            />
                          </FormGroup>
                        </FormControl>
                      </Accordion>
                      {/* 
                    |
                      Oedema & inflammation
                    |
                    */}
                      <Accordion
                        inputName="oedemainflammationNilSignificant"
                        checked={state.oedemainflammationNilSignificant}
                        expanded={state.oedemainflammationExpanded}
                        edited={state.oedemainflammationEdited}
                        innerRef={register}
                        label="Oedema &amp; inflammation - nil observed"
                        innerValidationError={!!errors.oedemaAndinflammation?.message}
                        validationMessage={errors.oedemaAndinflammation?.message}
                        checkedHandler={(event: any) => handleCheckboxChange(event, (checked: boolean) => changeParentCheckbox(checked, "yesAllNilSignificant"))}
                        expandedHandler={(event: any) => {
                          toggleAccordion(event, "oedemainflammationExpanded");
                        }}
                      >
                        <FormGroup>
                          <FormLabel component="legend">Location</FormLabel>
                          <OutlinedInput
                            color="primary"
                            name="oedemaLocation"
                            className="textInputMedium"
                            inputRef={register}
                            autoComplete="off"
                            defaultValue={state.oedemaLocation}
                            onChange={handleValueChange}
                          />
                        </FormGroup>
                        <FormGroup>
                          <FormLabel component="legend">Severity</FormLabel>
                          <div className="flexer">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="oedemaSeverityMild"
                                  id="oedemaSeverityMild"
                                  icon={<span className="checkboxIcon"></span>}
                                  checkedIcon={<span className="checkboxIconChecked"></span>}
                                  color="primary"
                                  checked={state.oedemaSeverityMild}
                                  onChange={handleSeverityCheckboxChange}
                                />
                              }
                              label="Mild"
                              inputRef={register}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="oedemaSeverityModerate"
                                  id="oedemaSeverityModerate"
                                  icon={<span className="checkboxIcon"></span>}
                                  checkedIcon={<span className="checkboxIconChecked"></span>}
                                  color="primary"
                                  checked={state.oedemaSeverityModerate}
                                  onChange={handleSeverityCheckboxChange}
                                />
                              }
                              label="Moderate"
                              inputRef={register}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="oedemaSeveritySevere"
                                  id="oedemaSeveritySevere"
                                  icon={<span className="checkboxIcon"></span>}
                                  checkedIcon={<span className="checkboxIconChecked"></span>}
                                  color="primary"
                                  checked={state.oedemaSeveritySevere}
                                  onChange={handleSeverityCheckboxChange}
                                />
                              }
                              label="Severe"
                              inputRef={register}
                            />
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <FormLabel component="legend">Any signs of inflammation?</FormLabel>
                          <TextField
                            label="Write something"
                            id="oedemainflammationNotes"
                            name="oedemainflammationNotes"
                            multiline
                            fullWidth
                            variant="outlined"
                            className="outlineTextArea"
                            inputRef={register}
                            value={state.oedemainflammationNotes}
                            onChange={handleValueChange}
                          />
                        </FormGroup>
                      </Accordion>
                      {/* 
                    |
                      Scars
                    |
                    */}
                      <Accordion
                        inputName="scarsNilSignificant"
                        checked={state.scarsNilSignificant}
                        expanded={state.scarsExpanded}
                        edited={state.scarsEdited}
                        innerRef={register}
                        label="Scars - nil visible"
                        innerValidationError={!!errors.scars?.message}
                        validationMessage={errors.scars?.message}
                        checkedHandler={(event: any) => handleCheckboxChange(event)}
                        expandedHandler={(event: any) => {
                          toggleAccordion(event, "scarsExpanded");
                        }}
                      >
                        <FormControl component="fieldset">
                          <FormGroup>
                            <FormLabel component="legend">
                              Please describe location and distinguishing features (eg Keloid) and stage of healing (eg no signs of visible infection) below
                            </FormLabel>
                            <TextField
                              label="Write something"
                              id="scarNotes"
                              name="scarNotes"
                              multiline
                              fullWidth
                              variant="outlined"
                              className="outlineTextArea"
                              inputRef={register}
                              value={state.scarNotes}
                              onChange={handleValueChange}
                            />
                          </FormGroup>
                        </FormControl>
                      </Accordion>
                      {/* 
                    |
                      Gait
                    |
                    */}
                      <Accordion
                        inputName="gaitNilSignificant"
                        checked={state.gaitNilSignificant}
                        expanded={state.gaitExpanded}
                        edited={state.gaitEdited}
                        innerRef={register}
                        label="Gait - no abnormalities observed"
                        innerValidationError={!!errors.gait?.message}
                        validationMessage={errors.gait?.message}
                        checkedHandler={(event: any) => handleCheckboxChange(event, (checked: boolean) => changeParentCheckbox(checked, "yesAllNilSignificant"))}
                        expandedHandler={(event: any) => {
                          toggleAccordion(event, "gaitExpanded");
                        }}
                      >
                        <FormControl component="fieldset">
                          <FormGroup>
                            <FormLabel component="legend">
                              Reduced weight-bearing on <strong>left</strong>
                            </FormLabel>
                            <TextField
                              label="Write something"
                              id="gaitReducedWeightBearingLeft"
                              name="gaitReducedWeightBearingLeft"
                              multiline
                              fullWidth
                              variant="outlined"
                              className="outlineTextArea"
                              inputRef={register}
                              value={state.gaitReducedWeightBearingLeft}
                              onChange={handleValueChange}
                            />
                          </FormGroup>
                          <FormGroup>
                            <FormLabel component="legend">
                              Reduced weight-bearing on <strong>right</strong>
                            </FormLabel>
                            <TextField
                              label="Write something"
                              id="gaitReducedWeightBearingRight"
                              name="gaitReducedWeightBearingRight"
                              multiline
                              fullWidth
                              variant="outlined"
                              className="outlineTextArea"
                              inputRef={register}
                              value={state.gaitReducedWeightBearingRight}
                              onChange={handleValueChange}
                            />
                          </FormGroup>
                          <FormGroup>
                            <FormLabel component="legend">Trendelenburg</FormLabel>
                            <TextField
                              label="Write something"
                              id="gaitTrendelenburg"
                              name="gaitTrendelenburg"
                              multiline
                              fullWidth
                              variant="outlined"
                              className="outlineTextArea"
                              inputRef={register}
                              value={state.gaitTrendelenburg}
                              onChange={handleValueChange}
                            />
                          </FormGroup>
                          <FormGroup>
                            <FormLabel component="legend">Gait Notes</FormLabel>
                            <TextField
                              label="Write something"
                              id="gaitNotes"
                              name="gaitNotes"
                              multiline
                              fullWidth
                              variant="outlined"
                              className="outlineTextArea"
                              inputRef={register}
                              value={state.gaitNotes}
                              onChange={handleValueChange}
                            />
                          </FormGroup>
                        </FormControl>
                      </Accordion>
                      {/* 
                    |
                      Braces, Prosthesis
                    |
                    */}
                      <Accordion
                        inputName="bracesProsthesisNilSignificant"
                        checked={state.bracesProsthesisNilSignificant}
                        expanded={state.bracesProsthesisExpanded}
                        edited={state.bracesProsthesisEdited}
                        innerRef={register}
                        label="Braces, prosthesis &amp; other devices - nil worn"
                        innerValidationError={!!errors.braceProsthesis?.message}
                        validationMessage={errors.braceProsthesis?.message}
                        checkedHandler={(event: any) => handleCheckboxChange(event, (checked: boolean) => changeParentCheckbox(checked, "yesAllNilSignificant"))}
                        expandedHandler={(event: any) => {
                          toggleAccordion(event, "bracesProsthesisExpanded");
                        }}
                      >
                        <FormControl component="fieldset">
                          <FormGroup>
                            <FormLabel component="legend">Prosthesis</FormLabel>
                            <TextField
                              label="Write something"
                              id="bracesProsthesisProsthesis"
                              name="bracesProsthesisProsthesis"
                              multiline
                              fullWidth
                              variant="outlined"
                              className="outlineTextArea"
                              inputRef={register}
                              value={state.bracesProsthesisProsthesis}
                              onChange={handleValueChange}
                            />
                          </FormGroup>
                          <FormGroup>
                            <FormLabel component="legend">Brace</FormLabel>
                            <TextField
                              label="Write something"
                              id="bracesProsthesisBrace"
                              name="bracesProsthesisBrace"
                              multiline
                              fullWidth
                              variant="outlined"
                              className="outlineTextArea"
                              inputRef={register}
                              value={state.bracesProsthesisBrace}
                              onChange={handleValueChange}
                            />
                          </FormGroup>
                          <FormGroup>
                            <FormLabel component="legend">Other support or device</FormLabel>
                            <TextField
                              label="Write something"
                              id="bracesProsthesisOtherSupportDevice"
                              name="bracesProsthesisOtherSupportDevice"
                              multiline
                              fullWidth
                              variant="outlined"
                              className="outlineTextArea"
                              inputRef={register}
                              value={state.bracesProsthesisOtherSupportDevice}
                              onChange={handleValueChange}
                            />
                          </FormGroup>
                          <FormGroup>
                            <FormLabel component="legend">Braces, Prosthesis Etc Notes</FormLabel>
                            <TextField
                              label="Write something"
                              id="bracesProsthesisNotes"
                              name="bracesProsthesisNotes"
                              multiline
                              fullWidth
                              variant="outlined"
                              className="outlineTextArea"
                              inputRef={register}
                              value={state.bracesProsthesisNotes}
                              onChange={handleValueChange}
                            />
                          </FormGroup>
                        </FormControl>
                      </Accordion>
                    </form>
                  </Grid>
                </Grid>
                <Grid item container xs={3} className="RightCol">
                  <ProfileCard
                    firstName={pefaAssessment.pefArequest.worker.firstName}
                    lastName={pefaAssessment.pefArequest.worker.lastName}
                    participantAgeYears={participantAgeYears}
                  />
                  <NotesModal pageKey={dataStorePageKeys.GENERAL_OBSERVATIONS} pefaID={pefaAssessment.id} />
                  <InTestMedicalClearanceModal
                    firstName={referralDetails.firstName}
                    lastName={referralDetails.lastName}
                    pageKey={dataStorePageKeys.GENERAL_OBSERVATIONS}
                    pefaID={pefaAssessment.id}
                    medicalPractice={medicalHistoryData.medicalPractitioner}
                    medicalPractitioner={medicalHistoryData.medicalPractice}
                  />
                </Grid>
              </Grid>
            </Container>
            <FormNavigator
              forwardTarget={MSKSTANDING}
              handleSubmit={handleSubmit(submitForm)}
              warning="If you suspect a current injury, please follow protocol for obtaining a JobFit System Medical Clearance"
              disabledNextButton={
                endAssessment &&
                endAssessment.endAssessmentReason !== "" &&
                endAssessment.endAssessmentNotes !== "" &&
                endAssessment.endAssessmentPage === dataStorePageKeys.GENERAL_OBSERVATIONS
              }
              selectedKey={dataStorePageKeys.GENERAL_OBSERVATIONS}
            />
          </Grid>
        </div>
      </div>
    );
  })
);

export default withAppInsights(GeneralObservations);

import { inject, observer } from "mobx-react";
import React from "react";
import Button from "@material-ui/core/Button";
import { Root } from "../../stores";
import Grid from "@material-ui/core/Grid";
import Pefalogo from "../../assets/images/pefa-logo.png";
import JfLogo from "../../assets/images/jobfit-logo.png";
import { useAuth } from "oidc-react";

interface LoginProps {
  rootStore?: Root;
}

const Login = inject("rootStore")(
  observer((props: LoginProps) => {
    const auth = useAuth();

    const handleLogin = () => {
      if (!!auth) {
        auth.signIn();
      }
    };

    return (
      <div style={{ width: "100%", height: "100vh" }}>
        <Grid container spacing={0} style={{ height: "100vh" }}>
          <Grid
            container
            item
            xs={6}
            alignItems="center"
            style={{
              backgroundColor: "#222E50",
              textAlign: "center",
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img src={Pefalogo} alt="Logo" />
          </Grid>
          <Grid
            container
            item
            xs={6}
            alignItems="center"
            style={{
              textAlign: "center",
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div className="login">
              <p>
                Welcome to <br /> JobFit System PEFA
              </p>
              <br />
              <Button variant="contained" size="large" color="primary" onClick={handleLogin}>
                Log In
              </Button>
            </div>
            <div className="FooterLogin" style={{ position: "absolute", bottom: "40px" }}>
              <p>Powered by:</p>
              <img src={JfLogo} alt="Logo" />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  })
);

export default Login;

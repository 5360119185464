import React, { Component, ReactNode } from "react";
import { Howl } from "howler";

import { Click1mp3, Click1ogg, Click1aac, Click2mp3, Click2ogg, Click2aac, Click3mp3, Click3ogg, Click3aac } from "./react-pro-metronome/sounds/index";

interface PeeperProps {
  intervalMs: number;
  repeats: number;
  soundKey?: number;
  isPlaying: boolean;
  isEnabled: boolean;

  handlePeeperComplete: any;
}

class Peeper extends Component<PeeperProps> {
  private clickSounds = [
    new Howl({
      src: [Click1mp3, Click1ogg, Click1aac],
      preload: true,
    }),
    new Howl({
      src: [Click2mp3, Click2ogg, Click2aac],
      preload: true,
    }),
    new Howl({
      src: [Click3mp3, Click3ogg, Click3aac],
      preload: true,
    }),
  ];

  private repeatCount = 0;
  private timerID: NodeJS.Timeout | undefined;

  constructor(props: PeeperProps) {
    super(props);

    this.playSound = this.playSound.bind(this);
  }

  playSound = (): void => {
    if (this.repeatCount < this.props.repeats) {
      if (!this.props.soundKey) {
        this.clickSounds[0].play();
      } else {
        this.clickSounds[this.props.soundKey - 1].play();
      }
      this.repeatCount++;
    } else {
      this.props.handlePeeperComplete();
    }
  };

  componentDidMount(): void {
    if (this.props.isPlaying) {
      this.timerID = setInterval(this.playSound, this.props.intervalMs);
    }
  }

  componentWillReceiveProps(nextProps: PeeperProps): void {
    if (nextProps.isPlaying !== this.props.isPlaying) {
      if (nextProps.isPlaying && nextProps.isEnabled) {
        this.timerID = setInterval(this.playSound, this.props.intervalMs);
      } else {
        if (this.timerID) {
          this.repeatCount = 0;
          clearInterval(this.timerID);
        }
      }
    }
  }

  componentWillUnmount(): void {
    if (this.timerID) {
      clearInterval(this.timerID);
    }
  }

  render(): ReactNode {
    return <></>;
  }
}

export default Peeper;

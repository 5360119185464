import { applySnapshot, flow, types } from "mobx-state-tree";
import apiService from "../services/ApiService";

const UserStore = types
  .model("User", {
    userDetails: types.frozen(),
    userRole: types.string,
    userTokenExpiryTime: types.number,
    userCurrentPath: types.string,
  })
  .actions((self) => {
    /**
     * Retrieves the user's information via the apiService.
     */
    const fetchUserDetails = flow(function* fetchUserDetails() {
      //Make an asynchronous call to the relevant JF API to retrieve the current user's details.
      const userDetails = yield apiService.getUserDetails();
      applySnapshot(self, {
        ...self,
        userDetails: userDetails.data,
      });
    });

    const clearUserDetails = function clearUserDetails() {
      applySnapshot(self, {
        ...self,
        userDetails: null,
        userTokenExpiryTime: -1,
        userCurrentPath: "",
      });
    };

    const setCurrentUserPath = function setCurrentUserPath(currentPath: string) {
      applySnapshot(self, { ...self, userCurrentPath: currentPath });
    };

    const setUserRole = function setUserRole(roles: any) {
      let userRole = "";

      //Check if there is more than one role for this user. If there is, look for the assessor or provider_admin roles.
      if (Array.isArray(roles)) {
        if (roles.find((role: string) => role && role.toUpperCase() === "ASSESSOR" && !userRole)) {
          userRole = "ASSESSOR";
        }

        if (roles.find((role: string) => role && role.toUpperCase() === "PROVIDER_ADMIN" && !userRole)) {
          userRole = "PROVIDER_ADMIN";
        }
      } else {
        //Otherwise if they only have the one role, we can store that directly.
        //NOTE: this does not take into consideration users who have a single role other than assessor or provider_admin.
        userRole = roles;
      }

      //Ensure that userRole has a value.
      //This is only ever set immediately after login, but the login validation calls will return this value as undefined for some reason?
      if (userRole) {
        applySnapshot(self, { ...self, userRole });
      }
    };

    return {
      fetchUserDetails,
      clearUserDetails,
      setCurrentUserPath,
      setUserRole,
    };
  })
  .views((self) => ({
    /**
     * Retrieves the user's name and formats it {First} {Last}.
     */
    get getUserNameFormatted() {
      if (self.userDetails) {
        return `${self.userDetails.firstName} ${self.userDetails.lastName}`;
      }
      return "";
    },
    /**
     * Retrieves the user's profession.
     */
    get getUserProfession() {
      if (self.userDetails) {
        return self.userDetails.qualifications;
      }
      return "";
    },
    /**
     * Retrieves the user's JF ID.
     */
    get getUserAssessorNumber() {
      if (self.userDetails) {
        return self.userDetails.id;
      }
      return "";
    },
    /**
     * Gets the user's initials. Formatted {F}{L}
     */
    get getUserInitials() {
      return `${self.userDetails.firstName.substring(0, 1).toUpperCase()}${self.userDetails.lastName.substring(0, 1).toUpperCase()}`;
    },
    get isUserSessionExpired() {
      const now = new Date().getTime() / 1000;
      return now - self.userTokenExpiryTime > 0;
    },
    get isUserAssessor() {
      return self.userRole.toUpperCase() === "ASSESSOR";
    },
  }));

export { UserStore };

import React, { useEffect } from "react";
import { ProfileCard, FormNavigator, Stepper, Timer, NotTested, NotesModal, RemoteStart, InTestMedicalClearanceModal } from "../../../components/";
import { BalanceIcon } from "../../../assets/images/";
import balanceValidation from "./validation";
import { ExpandMore, ErrorOutline } from "@material-ui/icons";

import {
  Grid,
  Typography,
  Container,
  FormControl,
  FormLabel,
  OutlinedInput,
  Card,
  FormGroup,
  TextField,
  AccordionSummary,
  InputAdornment,
  AccordionDetails,
} from "@material-ui/core";
import { Accordion as StyledAccordion } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import { Root } from "../../../stores";
import { differenceInYears } from "date-fns";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { dataStorePageKeys } from "../../../stores/dataStore";
import { demand } from "../../../stores/pefaStore";
import { useHistory } from "react-router-dom";
import { DASHBOARD } from "../../../routes/paths";
import { getAppInsights } from "../../../components/telemetry";
import withAppInsights from "../AppInsights";

interface BalanceTestProps {
  rootStore?: Root;
}

const BalanceTest = inject("rootStore")(
  observer((props: BalanceTestProps) => {
    let pefaAssessment: any;
    let prevData: any = null;
    let referralDetails: any = null;
    let participantAgeYears = 0;
    let nextDemandPage: demand;
    let baselineMeasurements: any;
    let medicalHistoryData: any;
    let endAssessment: any;
    let BPSys: any;
    let BPDia: any;
    let isHighBPFlowTested = false;
    let isThirdBPHigh = false;
    const history = useHistory();
    const appInsights = getAppInsights();

    if (props.rootStore?.pefaStore.selectedPefa) {
      pefaAssessment = props.rootStore?.pefaStore.selectedPefa[0];
      baselineMeasurements = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.BASELINE_MEASUREMENTS);
      medicalHistoryData = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.MEDICAL_DETAILS);
      endAssessment = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.END_ASSESSMENT);
      BPSys = pefaAssessment.pefArequest.company.bpDia || 145;
      BPDia = pefaAssessment.pefArequest.company.bpSys || 95;
      isHighBPFlowTested = medicalHistoryData.highBloodPressureViewedMC === "ViewedAccepted" ? false : true;
      isThirdBPHigh =
        isHighBPFlowTested &&
        ((Number(baselineMeasurements.bloodPressureDiastolic3) > Number(BPDia) && Number(baselineMeasurements.bloodPressureSystolic3) > 0) ||
          (Number(baselineMeasurements.bloodPressureSystolic3) > Number(BPSys) && Number(baselineMeasurements.bloodPressureDiastolic3) > 0));
      // if 3rd bp is high, skip Fitness test
      if (isThirdBPHigh) {
        nextDemandPage = props.rootStore?.pefaStore.getNextStepAfterHighBP(dataStorePageKeys.FITNESS);
      } else {
        nextDemandPage = props.rootStore?.pefaStore.getNextPefaStep(-1);
      }
      if (props.rootStore?.dataStore.pefaData.hasOwnProperty(pefaAssessment.id)) {
        prevData = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.BALANCE);
        referralDetails = props.rootStore?.dataStore.getCheckInData(pefaAssessment.id, dataStorePageKeys.REFERRAL_DETAILS);
        participantAgeYears = differenceInYears(new Date(), new Date(referralDetails?.dob ?? pefaAssessment.pefArequest.worker.dateOfBirth));
      } else {
        participantAgeYears = differenceInYears(new Date(), new Date(pefaAssessment.pefArequest.worker.dateOfBirth));
      }
    } else {
      history.push(DASHBOARD);
      return <></>;
    }

    const { register, handleSubmit, errors } = useForm({
      resolver: yupResolver(balanceValidation),
    });

    enum testKeys {
      "LEFT_OPEN" = "LEFT_OPEN",
      "RIGHT_OPEN" = "RIGHT_OPEN",
      "LEFT_CLOSED" = "LEFT_CLOSED",
      "RIGHT_CLOSED" = "RIGHT_CLOSED",
    }

    const [state, setState] = useState({
      isLoading: true,
      timerSeconds: 0,
      timerPeriodString: "0:30",
      timerStopped: false,

      notTestedModalOpen: false,
      notTestedReason: prevData ? prevData.notTestedReason : "",
      notTestedNotes: prevData ? prevData.notTestedNotes : "",
      yesAllNilSignificant: false,
      viewAll: false,

      leftOpen: true,
      rightOpen: false,
      leftClosed: false,
      rightClosed: false,
      eyesClosedLeftDisabled: prevData ? !(prevData.leftLegEyesOpenAttempt1 >= 30 || prevData.leftLegEyesOpenAttempt2 >= 30 || prevData.leftLegEyesOpenAttempt3 >= 30) : true,
      eyesClosedRightDisabled: prevData ? !(prevData.rightLegEyesOpenAttempt1 >= 30 || prevData.rightLegEyesOpenAttempt2 >= 30 || prevData.rightLegEyesOpenAttempt3 >= 30) : true,
      leftLegEyesOpenAttempt1: prevData ? prevData.leftLegEyesOpenAttempt1 : "",
      leftLegEyesOpenAttempt2: prevData ? prevData.leftLegEyesOpenAttempt2 : "",
      leftLegEyesOpenAttempt3: prevData ? prevData.leftLegEyesOpenAttempt3 : "",
      rightLegEyesOpenAttempt1: prevData ? prevData.rightLegEyesOpenAttempt1 : "",
      rightLegEyesOpenAttempt2: prevData ? prevData.rightLegEyesOpenAttempt2 : "",
      rightLegEyesOpenAttempt3: prevData ? prevData.rightLegEyesOpenAttempt3 : "",
      leftLegEyesClosedAttempt1: prevData ? prevData.leftLegEyesClosedAttempt1 : "",
      leftLegEyesClosedAttempt2: prevData ? prevData.leftLegEyesClosedAttempt2 : "",
      leftLegEyesClosedAttempt3: prevData ? prevData.leftLegEyesClosedAttempt3 : "",
      rightLegEyesClosedAttempt1: prevData ? prevData.rightLegEyesClosedAttempt1 : "",
      rightLegEyesClosedAttempt2: prevData ? prevData.rightLegEyesClosedAttempt2 : "",
      rightLegEyesClosedAttempt3: prevData ? prevData.rightLegEyesClosedAttempt3 : "",

      leftLegEyesOpenComments: prevData ? prevData.leftLegEyesOpenComments : "",
      rightLegEyesOpenComments: prevData ? prevData.rightLegEyesOpenComments : "",
      leftLegEyesClosedComments: prevData ? prevData.leftLegEyesClosedComments : "",
      rightLegEyesClosedComments: prevData ? prevData.rightLegEyesClosedComments : "",

      isTimerStarted: false,
      remoteTimerVisible: true,
      timerRemoteTrigger: false,
      timerRemoteResumeTrigger: false,
    });

    const handleValueChange = (event: any): void => {
      event.persist();
      setState((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
      }));
    };

    const handleTimerStopped = (): void => {
      setState((prevState) => ({
        ...prevState,
        isTimerStarted: false,
        remoteTimerVisible: true,
      }));
    };

    const handleTimerEvents = (timeElapsedRaw: number, timeElapsedFormatted: string): void => {
      setState((prevState) => ({
        ...prevState,
        isTimerStarted: true,
        remoteTimerVisible: false,
        timerRemoteTrigger: false,
        timerRemoteResumeTrigger: false,
      }));
    };

    const handleTimerRemoteStart = (): void => {
      setState((prevState) => ({ ...prevState, timerRemoteTrigger: true }));
    };

    const handleTimerRemoteResume = (): void => {
      setState((prevState) => ({ ...prevState, timerRemoteResumeTrigger: true }));
    };

    const handleAccordianStates = (accordionKey: testKeys): void => {
      switch (accordionKey) {
        case testKeys.LEFT_OPEN: {
          setState((prevState) => ({
            ...prevState,
            leftOpen: true,
            leftClosed: false,
            rightOpen: false,
            rightClosed: false,
            timerPeriodString: "0:30",
          }));
          break;
        }
        case testKeys.RIGHT_OPEN: {
          setState((prevState) => ({
            ...prevState,
            leftOpen: false,
            leftClosed: false,
            rightOpen: true,
            rightClosed: false,
            timerPeriodString: "0:30",
          }));
          break;
        }
        case testKeys.LEFT_CLOSED: {
          if (!state.eyesClosedLeftDisabled) {
            setState((prevState) => ({
              ...prevState,
              leftOpen: false,
              leftClosed: true,
              rightOpen: false,
              rightClosed: false,
              timerPeriodString: "0:10",
            }));
          }
          break;
        }
        case testKeys.RIGHT_CLOSED: {
          if (!state.eyesClosedRightDisabled) {
            setState((prevState) => ({
              ...prevState,
              leftOpen: false,
              leftClosed: false,
              rightOpen: false,
              rightClosed: true,
              timerPeriodString: "0:10",
            }));
          }
          break;
        }
      }
    };

    useEffect(() => {
      setState((prevState) => ({
        ...prevState,
        eyesClosedLeftDisabled: state.leftLegEyesOpenAttempt1 < 30 && state.leftLegEyesOpenAttempt2 < 30 && state.leftLegEyesOpenAttempt3 < 30,
        eyesClosedRightDisabled: state.rightLegEyesOpenAttempt1 < 30 && state.rightLegEyesOpenAttempt2 < 30 && state.rightLegEyesOpenAttempt3 < 30,
      }));
    }, [
      state.leftLegEyesOpenAttempt1,
      state.leftLegEyesOpenAttempt2,
      state.leftLegEyesOpenAttempt3,
      state.rightLegEyesOpenAttempt1,
      state.rightLegEyesOpenAttempt2,
      state.rightLegEyesOpenAttempt3,
    ]);

    useEffect(() => {
      const timeout = setTimeout(() => {
        props.rootStore?.dataStore.savePartialData(state, pefaAssessment.id, dataStorePageKeys.BALANCE);
      }, 500);

      return () => clearTimeout(timeout);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      state.notTestedReason,
      state.notTestedNotes,
      state.leftLegEyesOpenAttempt1,
      state.leftLegEyesOpenAttempt2,
      state.leftLegEyesOpenAttempt3,
      state.rightLegEyesOpenAttempt1,
      state.rightLegEyesOpenAttempt2,
      state.rightLegEyesOpenAttempt3,
      state.leftLegEyesClosedAttempt1,
      state.leftLegEyesClosedAttempt2,
      state.leftLegEyesClosedAttempt3,
      state.rightLegEyesClosedAttempt1,
      state.rightLegEyesClosedAttempt2,
      state.rightLegEyesClosedAttempt3,
      state.leftLegEyesOpenComments,
      state.rightLegEyesOpenComments,
      state.leftLegEyesClosedComments,
      state.rightLegEyesClosedComments,
    ]);

    useEffect(() => {
      //Replicate the onUnmount event handler from class components.
      return () => {
        appInsights.trackEvent({ name: "intermediateSubmission", properties: { bookingId: pefaAssessment.id, dataKey: dataStorePageKeys.BALANCE, data: state } });
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submitForm = (data: any, forwardTarget: any) => {
      props.rootStore?.dataStore.saveData(data, pefaAssessment.id, dataStorePageKeys.BALANCE);
      const pefaData = { ...props.rootStore?.dataStore.pefaData[pefaAssessment.id] };
      // take snapshot of assessment and store against booking
      props.rootStore?.pefaStore.nextSubmit(pefaData, pefaAssessment.id);
      history.push(forwardTarget);
    };

    return (
      <div className="FormStyler">
        <div className="AssessmentDetails">
          <Grid item container direction="column" xs={12}>
            <Stepper targetStep={"Balance Test"} />
            <form className="PefaForm">
              <Container maxWidth={"lg"}>
                <Grid item container direction="row" xs={12}>
                  <Grid item container xs={9}>
                    <Card className="titleCard" variant="outlined">
                      <a href="/docs/PEFA Instructions_MSK_Balance.pdf" className="helpIcon" target="_blank">
                        Help
                      </a>
                      <Typography variant="h2" className="cardHeroHeading">
                        <img src={BalanceIcon} alt="Success" />
                        <span>Balance Checks</span>
                      </Typography>
                      <Typography variant="subtitle2" className="subtitleHeading">
                        Musuloskeletal Screen
                      </Typography>
                      <NotTested data={prevData} forwardTarget={nextDemandPage.pageKey} notTestedSaveHandler={submitForm} />
                      <input type="text" ref={register} name="notTestedReason" value={state.notTestedReason} style={{ visibility: "hidden" }} />
                      <input type="text" ref={register} name="notTestedNotes" value={state.notTestedNotes} style={{ visibility: "hidden" }} />
                    </Card>
                    <Grid item container xs={12}>
                      <RemoteStart
                        configKey="TIMER"
                        disabled={false}
                        visible={state.remoteTimerVisible}
                        isTimerStarted={state.isTimerStarted}
                        handleRemoteStart={handleTimerRemoteStart}
                        handleRemoteResume={handleTimerRemoteResume}
                      />
                      <StyledAccordion variant="outlined" className="innerOutlinedBox" expanded={state.leftOpen} onClick={() => handleAccordianStates(testKeys.LEFT_OPEN)}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                          <Typography variant="subtitle1">Left leg - eyes open (30secs)</Typography>
                          {!!errors.eyesOpenAttemptL?.message ? <ErrorOutline className="invalid-feedback" /> : <></>}
                        </AccordionSummary>
                        <AccordionDetails>
                          <FormGroup className="formGroupOveride">
                            <div className="flexer">
                              <div className="labelLeftSmall">
                                <FormLabel component="legend">Attempts</FormLabel>
                              </div>
                              <OutlinedInput
                                color="primary"
                                name="leftLegEyesOpenAttempt1"
                                autoComplete="off"
                                endAdornment={<InputAdornment position="end">Sec</InputAdornment>}
                                inputRef={register}
                                className="vasInput"
                                value={state.leftLegEyesOpenAttempt1}
                                onChange={handleValueChange}
                              />
                              <OutlinedInput
                                color="primary"
                                name="leftLegEyesOpenAttempt2"
                                autoComplete="off"
                                endAdornment={<InputAdornment position="end">Sec</InputAdornment>}
                                className="vasInput"
                                value={state.leftLegEyesOpenAttempt2}
                                inputRef={register}
                                onChange={handleValueChange}
                              />
                              <OutlinedInput
                                color="primary"
                                name="leftLegEyesOpenAttempt3"
                                autoComplete="off"
                                endAdornment={<InputAdornment position="end">Sec</InputAdornment>}
                                className="vasInput"
                                value={state.leftLegEyesOpenAttempt3}
                                inputRef={register}
                                onChange={handleValueChange}
                              />
                            </div>
                            <div className="invalidFeedback">
                              {errors.eyesOpenAttemptL?.message} <br />
                            </div>
                          </FormGroup>
                          <FormControl component="fieldset" className="fieldsetBalance">
                            <FormGroup className="commentsField">
                              <TextField
                                label="Comments here"
                                name="leftLegEyesOpenComments"
                                fullWidth
                                variant="outlined"
                                className="outlineTextArea"
                                value={state.leftLegEyesOpenComments}
                                inputRef={register}
                                onChange={handleValueChange}
                              />
                            </FormGroup>
                          </FormControl>
                        </AccordionDetails>
                      </StyledAccordion>
                      <StyledAccordion variant="outlined" className="innerOutlinedBox" onClick={() => handleAccordianStates(testKeys.RIGHT_OPEN)} expanded={state.rightOpen}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                          <Typography variant="subtitle1">Right leg - eyes open (30secs)</Typography>
                          {!!errors.eyesOpenAttemptR?.message ? <ErrorOutline className="invalid-feedback" /> : <></>}
                        </AccordionSummary>
                        <AccordionDetails>
                          <FormGroup className="formGroupOveride">
                            <div className="flexer">
                              <div className="labelLeftSmall">
                                <FormLabel component="legend">Attempts</FormLabel>
                              </div>
                              <OutlinedInput
                                color="primary"
                                name="rightLegEyesOpenAttempt1"
                                autoComplete="off"
                                endAdornment={<InputAdornment position="end">Sec</InputAdornment>}
                                className="vasInput"
                                value={state.rightLegEyesOpenAttempt1}
                                inputRef={register}
                                onChange={handleValueChange}
                              />
                              <OutlinedInput
                                color="primary"
                                name="rightLegEyesOpenAttempt2"
                                autoComplete="off"
                                endAdornment={<InputAdornment position="end">Sec</InputAdornment>}
                                className="vasInput"
                                value={state.rightLegEyesOpenAttempt2}
                                inputRef={register}
                                onChange={handleValueChange}
                              />
                              <OutlinedInput
                                color="primary"
                                name="rightLegEyesOpenAttempt3"
                                autoComplete="off"
                                value={state.rightLegEyesOpenAttempt3}
                                inputRef={register}
                                endAdornment={<InputAdornment position="end">Sec</InputAdornment>}
                                className="vasInput"
                                onChange={handleValueChange}
                              />
                            </div>
                            <div className="invalidFeedback">
                              {errors.eyesOpenAttemptR?.message} <br />
                            </div>
                          </FormGroup>
                          <FormControl component="fieldset" className="fieldsetBalance">
                            <FormGroup className="commentsField">
                              <TextField
                                label="Comments here"
                                name="rightLegEyesOpenComments"
                                fullWidth
                                variant="outlined"
                                className="outlineTextArea"
                                value={state.rightLegEyesOpenComments}
                                inputRef={register}
                                onChange={handleValueChange}
                              />
                            </FormGroup>
                          </FormControl>
                        </AccordionDetails>
                      </StyledAccordion>
                      <StyledAccordion
                        variant="outlined"
                        className="innerOutlinedBox"
                        expanded={state.leftClosed}
                        onClick={() => handleAccordianStates(testKeys.LEFT_CLOSED)}
                        disabled={state.eyesClosedLeftDisabled}
                      >
                        <AccordionSummary expandIcon={<ExpandMore />}>
                          <Typography variant="subtitle1">Left leg - eyes closed (10secs)</Typography>
                          {!!errors.eyesClosedAttemptL?.message ? <ErrorOutline className="invalid-feedback" /> : <></>}
                        </AccordionSummary>
                        <AccordionDetails>
                          <FormGroup className="formGroupOveride">
                            <div className="flexer">
                              <div className="labelLeftSmall">
                                <FormLabel component="legend">Attempts</FormLabel>
                              </div>
                              <OutlinedInput
                                color="primary"
                                name="leftLegEyesClosedAttempt1"
                                autoComplete="off"
                                endAdornment={<InputAdornment position="end">Sec</InputAdornment>}
                                className="vasInput"
                                value={state.leftLegEyesClosedAttempt1}
                                inputRef={register}
                                onChange={handleValueChange}
                              />
                              <OutlinedInput
                                color="primary"
                                name="leftLegEyesClosedAttempt2"
                                autoComplete="off"
                                endAdornment={<InputAdornment position="end">Sec</InputAdornment>}
                                className="vasInput"
                                value={state.leftLegEyesClosedAttempt2}
                                inputRef={register}
                                onChange={handleValueChange}
                              />
                              <OutlinedInput
                                color="primary"
                                name="leftLegEyesClosedAttempt3"
                                autoComplete="off"
                                endAdornment={<InputAdornment position="end">Sec</InputAdornment>}
                                className="vasInput"
                                value={state.leftLegEyesClosedAttempt3}
                                inputRef={register}
                                onChange={handleValueChange}
                              />
                            </div>
                            <div className="invalidFeedback">
                              {errors.eyesClosedAttemptL?.message} <br />
                            </div>
                          </FormGroup>
                          <FormControl component="fieldset" className="fieldsetBalance">
                            <FormGroup className="commentsField">
                              <TextField
                                label="Comments here"
                                name="leftLegEyesClosedComments"
                                fullWidth
                                variant="outlined"
                                className="outlineTextArea"
                                value={state.leftLegEyesClosedComments}
                                inputRef={register}
                                onChange={handleValueChange}
                              />
                            </FormGroup>
                          </FormControl>
                        </AccordionDetails>
                      </StyledAccordion>
                      <StyledAccordion
                        variant="outlined"
                        className="innerOutlinedBox"
                        expanded={state.rightClosed}
                        onClick={() => handleAccordianStates(testKeys.RIGHT_CLOSED)}
                        disabled={state.eyesClosedRightDisabled}
                      >
                        <AccordionSummary expandIcon={<ExpandMore />}>
                          <Typography variant="subtitle1">Right leg - eyes closed (10secs)</Typography>
                          {!!errors.eyesClosedAttemptR?.message ? <ErrorOutline className="invalid-feedback" /> : <></>}
                        </AccordionSummary>
                        <AccordionDetails>
                          <FormGroup className="formGroupOveride">
                            <div className="flexer">
                              <div className="labelLeftSmall">
                                <FormLabel component="legend">Attempts</FormLabel>
                              </div>
                              <OutlinedInput
                                color="primary"
                                name="rightLegEyesClosedAttempt1"
                                autoComplete="off"
                                endAdornment={<InputAdornment position="end">Sec</InputAdornment>}
                                className="vasInput"
                                value={state.rightLegEyesClosedAttempt1}
                                inputRef={register}
                                onChange={handleValueChange}
                              />
                              <OutlinedInput
                                color="primary"
                                name="rightLegEyesClosedAttempt2"
                                autoComplete="off"
                                endAdornment={<InputAdornment position="end">Sec</InputAdornment>}
                                className="vasInput"
                                value={state.rightLegEyesClosedAttempt2}
                                inputRef={register}
                                onChange={handleValueChange}
                              />
                              <OutlinedInput
                                color="primary"
                                name="rightLegEyesClosedAttempt3"
                                autoComplete="off"
                                endAdornment={<InputAdornment position="end">Sec</InputAdornment>}
                                className="vasInput"
                                value={state.rightLegEyesClosedAttempt3}
                                inputRef={register}
                                onChange={handleValueChange}
                              />
                            </div>
                            <div className="invalidFeedback">
                              {errors.eyesClosedAttemptR?.message} <br />
                            </div>
                          </FormGroup>
                          <FormControl component="fieldset" className="fieldsetBalance">
                            <FormGroup className="commentsField">
                              <TextField
                                label="Comments here"
                                name="rightLegEyesClosedComments"
                                fullWidth
                                variant="outlined"
                                className="outlineTextArea"
                                value={state.rightLegEyesClosedComments}
                                inputRef={register}
                                onChange={handleValueChange}
                              />
                            </FormGroup>
                          </FormControl>
                        </AccordionDetails>
                      </StyledAccordion>
                    </Grid>
                  </Grid>
                  <Grid item container xs={3} className="RightCol">
                    <ProfileCard
                      firstName={pefaAssessment.pefArequest.worker.firstName}
                      lastName={pefaAssessment.pefArequest.worker.lastName}
                      participantAgeYears={participantAgeYears}
                    />
                    <Timer
                      countUp={true}
                      timeTarget={state.timerPeriodString}
                      timerStoppedHandler={handleTimerStopped}
                      timerHandler={handleTimerEvents}
                      timerRemoteTrigger={state.timerRemoteTrigger}
                      timerRemoteResumeTrigger={state.timerRemoteResumeTrigger}
                    />
                    <NotesModal pageKey={dataStorePageKeys.BALANCE} pefaID={pefaAssessment.id} />
                    <InTestMedicalClearanceModal
                      firstName={referralDetails.firstName}
                      lastName={referralDetails.lastName}
                      pageKey={dataStorePageKeys.BALANCE}
                      pefaID={pefaAssessment.id}
                      medicalPractice={medicalHistoryData.medicalPractitioner}
                      medicalPractitioner={medicalHistoryData.medicalPractice}
                    />
                  </Grid>
                </Grid>
              </Container>
            </form>
            <FormNavigator
              forwardTarget={nextDemandPage.pageKey}
              handleSubmit={handleSubmit(submitForm)}
              disabledNextButton={
                endAssessment &&
                endAssessment.endAssessmentReason !== "" &&
                endAssessment.endAssessmentNotes !== "" &&
                endAssessment.endAssessmentPage === dataStorePageKeys.BALANCE
              }
              selectedKey={dataStorePageKeys.BALANCE}
            />
          </Grid>
        </div>
      </div>
    );
  })
);

export default withAppInsights(BalanceTest);

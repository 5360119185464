export const programRecommendationDataKeys = [
  {
    flag: "programRecommendationAerobicConditioning",
    friendlyName: "Aerobic Conditioning",
    note: "programRecommendationAerobicConditioningNote",
  },
  {
    flag: "programRecommendationBalanceRetraining",
    friendlyName: "Balance Retraining",
    note: "programRecommendationBalanceRetrainingNote",
  },
  {
    flag: "programRecommendationFlexibilityProgram",
    friendlyName: "Flexibility Program",
    note: "programRecommendationFlexibilityProgramNote",
  },
  {
    flag: "programRecommendationManualHandlingTraining",
    friendlyName: "Manual Handling Training",
    note: "programRecommendationManualHandlingTrainingNote",
  },
  {
    flag: "programRecommendationShoulderStabilityProgram",
    friendlyName: "Shoulder Stability Program",
    note: "programRecommendationShoulderStabilityProgramNote",
  },
  {
    flag: "programRecommendationTrunkStabilityProgram",
    friendlyName: "Trunk Stability Program",
    note: "programRecommendationTrunkStabilityProgramNote",
  },
  {
    flag: "programRecommendationTrunkStrenghthening",
    friendlyName: "Trunk Strenghthening",
    note: "programRecommendationTrunkStrenghtheningNote",
  },
  {
    flag: "programRecommendationGeneralStrengthening",
    friendlyName: "General Strengthening",
    note: "programRecommendationGeneralStrengtheningNote",
  },
  {
    flag: "programRecommendationLowerBodyStrengthening",
    friendlyName: "Lower Body Strengthening",
    note: "programRecommendationLowerBodyStrengtheningNote",
  },
  {
    flag: "programRecommendationUpperBodyStrengthening",
    friendlyName: "Upper Body Strengthening",
    note: "programRecommendationUpperBodyStrengtheningNote",
  },
  {
    flag: "programRecommendationMedicalReview",
    friendlyName: "Medical Review",
    note: "programRecommendationMedicalReviewNote",
  },
  {
    flag: "programRecommendationPhysiotherapyReview",
    friendlyName: "Physiotherapy Review",
    note: "programRecommendationPhysiotherapyReviewNote",
  },
  {
    flag: "programRecommendationOther",
    friendlyName: "Other",
    note: "programRecommendationOtherNote",
  },
];

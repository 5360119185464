import { differenceInYears } from "date-fns";
import * as yup from "yup";

const referralDetailsSchema = yup.object().shape({
  preferredName: yup.string().required("Please enter the participant's preferred name."),
  firstName: yup.string().required("Please enter the participant's first name."),
  biologicalSex: yup.string().required("Please enter the participant's gender."),
  lastName: yup.string().required("Please enter the participant's last name."),
  postalAddress: yup.string().required("Please enter the participant's Postal Address."),
  emergencyContactName: yup.string().required("Please enter the Emergency Contact Name."),
  emergencyContactPhone: yup.string().required("Please enter the Emergency Contact Phone."),
  jobDetailsCorrect: yup.boolean().oneOf([true], "Please confirm the job details are correct."),
  dob: yup.string().test(
    "dob",
    "Please enter valid participant's DOB in DD-MMM-YYYY format.",

    function () {
      const { dob } = this.parent;
      if (dob === "" || dob.length !== 11 || new Date(dob).toString() === "Invalid Date" || differenceInYears(new Date(), new Date(dob)) < 1) {
        return false;
      }
      return true;
    }
  ),
});

export default referralDetailsSchema;

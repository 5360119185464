import { dataStorePageKeys } from "../../../stores/dataStore";

// MSK DATA KEYS -----------------------------------------------------------------------

export const mskStandingTrunkMobilityDataKeys = [
  { setKey: "bendForward", friendlyName: "Bend Forward" },
  {
    setKey: "bendBackward",
    friendlyName: "Bend Backward",
  },
  { setKey: "rotateLeft", friendlyName: "Rotate Left" },
  { setKey: "rotateRight", friendlyName: "Rotate Right" },
];

export const mskStandingSquatMobilityDataKeys = [{ setKey: "squatFull", friendlyName: "Squat Full" }];

export const mskSittingNeckMobilityDataKeys = [
  { setKey: "headDown", friendlyName: "Head Down" },
  { setKey: "headUp", friendlyName: "Head Up" },
  { setKey: "rotateLeft", friendlyName: "Rotate Left" },
  { setKey: "rotateRight", friendlyName: "Rotate Right" },
];

export const mskSittingUpperLimbMobilityDataKeys = [
  { setKey: "leftShoulderFlexion", friendlyName: "Left Shoulder - Flexion" },
  {
    setKey: "leftShoulderAbduction",
    friendlyName: "Left Shoulder - Abduction",
  },
  {
    setKey: "leftShoulderHandBehindHead",
    friendlyName: "Left Shoulder - Hand Behind Head",
  },
  {
    setKey: "leftShoulderHandBehindBack",
    friendlyName: "Left Shoulder - Hand Behind Back",
  },
  {
    setKey: "leftElbowForearmExtension",
    friendlyName: "Left Elbow - Forearm Extension",
  },
  {
    setKey: "leftElbowForearmFlexion",
    friendlyName: "Left Elbow - Forearm Flexion",
  },
  {
    setKey: "leftElbowForearmSupination",
    friendlyName: "Left Elbow - Forearm Supination",
  },
  {
    setKey: "leftWristHandExtension",
    friendlyName: "Left Wrist - Hand Extension",
  },
  { setKey: "leftWristHandFlexion", friendlyName: "Left Wrist - Hand Flexion" },
  {
    setKey: "leftWristHandRadialDeviation",
    friendlyName: "Left Wrist - Hand Radial Deviation",
  },
  {
    setKey: "leftWristHandUlnarDeviation",
    friendlyName: "Left Wrist - Hand Ulnar Deviation",
  },
  {
    setKey: "leftHandFingerOppositionToThumb",
    friendlyName: "Left Hand - Finger Opposite to Thumb",
  },
  {
    setKey: "leftHandFingerFullFist",
    friendlyName: "Left Hand - Finger Full Fist",
  },
  { setKey: "rightShoulderFlexion", friendlyName: "Right Shoulder - Flexion" },
  {
    setKey: "rightShoulderAbduction",
    friendlyName: "Right Shoulder - Abduction",
  },
  {
    setKey: "rightShoulderHandBehindHead",
    friendlyName: "Right Shoulder - Hand Behind Head",
  },
  {
    setKey: "rightShoulderHandBehindBack",
    friendlyName: "Right Shoulder - Hand Behind Back",
  },
  {
    setKey: "rightElbowForearmExtension",
    friendlyName: "Right Elbow - Forearm Extension",
  },
  {
    setKey: "rightElbowForearmFlexion",
    friendlyName: "Right Elbow - Forearm Flexion",
  },
  {
    setKey: "rightElbowForearmSupination",
    friendlyName: "Right Elbow - Forearm Supination",
  },
  {
    setKey: "rightWristHandExtension",
    friendlyName: "Right Wrist - Hand Extension",
  },
  {
    setKey: "rightWristHandFlexion",
    friendlyName: "Right Wrist - Hand Flexion",
  },
  {
    setKey: "rightWristHandRadialDeviation",
    friendlyName: "Right Wrist - Hand Radial Deviation",
  },
  {
    setKey: "rightWristHandUlnarDeviation",
    friendlyName: "Right Wrist - Hand Ulnar Deviation",
  },
  {
    setKey: "rightHandFingerOppositionToThumb",
    friendlyName: "Right Hand - Finger Opposite to Thumb",
  },
  {
    setKey: "rightHandFingerFullFist",
    friendlyName: "Right Hand - Finger Full Fist",
  },
];

export const mskLyingHipKneeMobilityDataKeys = [
  { setKey: "leftHipFlexion", friendlyName: "Left Hip - Flexion" },
  {
    setKey: "leftHipExternalRotation",
    friendlyName: "Left Hip - External Rotation",
  },
  { setKey: "leftKneeFlexion", friendlyName: "Left Knee - Flexion" },
  { setKey: "leftKneeExtension", friendlyName: "Left Knee - Extension" },
  { setKey: "rightHipFlexion", friendlyName: "Right Hip - Flexion" },
  {
    setKey: "rightHipExternalRotation",
    friendlyName: "Right Hip - External Rotation",
  },
  { setKey: "rightKneeFlexion", friendlyName: "Right Knee - Flexion" },
  { setKey: "rightKneeExtension", friendlyName: "Right Knee - Extension" },
];

export const mskLyingAnkleMobilityDataKeys = [
  {
    setKey: "leftAnkleDorsiflexion",
    friendlyName: "Left Ankle - Dorsiflexion",
  },
  {
    setKey: "leftAnklePlantarFlexion",
    friendlyName: "Left Ankle - Plantar Flexion",
  },
  { setKey: "leftAnkleInversion", friendlyName: "Left Ankle - Inversion" },
  { setKey: "leftAnkleEversion", friendlyName: "Left Ankle - Eversion" },
  {
    setKey: "rightAnkleDorsiflexion",
    friendlyName: "Right Ankle - Dorsiflexion",
  },
  {
    setKey: "rightAnklePlantarFlexion",
    friendlyName: "Right Ankle - Plantar Flexion",
  },
  { setKey: "rightAnkleInversion", friendlyName: "Right Ankle - Inversion" },
  { setKey: "rightAnkleEversion", friendlyName: "Right Ankle - Eversion" },
];

export const mskSittingUpperLimbStrengthDataKeys = [
  {
    setKey: "leftShoulderStrengthFlexion",
    friendlyName: "Left Shoulder - Flexion",
  },
  {
    setKey: "leftShoulderStrengthAbduction",
    friendlyName: "Left Shoulder - Abduction",
  },
  {
    setKey: "leftShoulderStrengthInternalRotation",
    friendlyName: "Left Shoulder - Internal Rotation",
  },
  {
    setKey: "leftShoulderStrengthExternalRotation",
    friendlyName: "Left Shoulder - External Rotation",
  },
  { setKey: "leftElbowStrengthFlexion", friendlyName: "Left Elbow - Flexion" },
  {
    setKey: "leftElbowStrengthExtension",
    friendlyName: "Left Elbow - Extension",
  },
  {
    setKey: "leftElbowStrengthSupination",
    friendlyName: "Left Elbow - Supination",
  },
  {
    setKey: "leftThumbStrengthExtensions",
    friendlyName: "Left Thumb - Extensions",
  },
  {
    setKey: "leftThumbStrengthLumbricals",
    friendlyName: "Left Thumb - Lumbricals",
  },
  {
    setKey: "rightShoulderStrengthFlexion",
    friendlyName: "Right Shoulder - Flexion",
  },
  {
    setKey: "rightShoulderStrengthAbduction",
    friendlyName: "Right Shoulder - Abduction",
  },
  {
    setKey: "rightShoulderStrengthInternalRotation",
    friendlyName: "Right Shoulder - Internal Rotation",
  },
  {
    setKey: "rightShoulderStrengthExternalRotation",
    friendlyName: "Right Shoulder - External Rotation",
  },
  {
    setKey: "rightElbowStrengthFlexion",
    friendlyName: "Right Elbow - Flexion",
  },
  {
    setKey: "rightElbowStrengthExtension",
    friendlyName: "Right Elbow - Extension",
  },
  {
    setKey: "rightElbowStrengthSupination",
    friendlyName: "Right Elbow - Supination",
  },
  {
    setKey: "rightThumbStrengthExtensions",
    friendlyName: "Right Thumb - Extensions",
  },
  {
    setKey: "rightThumbStrengthLumbricals",
    friendlyName: "Right Thumb - Lumbricals",
  },
];

export const mskLyingTrunkStrengthDataKeys = [
  { setKey: "trunkStrengthExtensors", friendlyName: "Extensors" },
  { setKey: "trunkStrengthAbdominals", friendlyName: "Abdominals" },
];

export const mskLyingAnkleStrengthDataKeys = [
  {
    setKey: "leftAnkleStrengthPlantarflexion",
    friendlyName: "Left Ankle - Plantar Flexion",
  },
  {
    setKey: "leftAnkleStrengthDorsiflexion",
    friendlyName: "Left Ankle - Dorsiflexion",
  },
  {
    setKey: "leftAnkleStrengthEversion",
    friendlyName: "Left Ankle - Eversion",
  },
  {
    setKey: "leftAnkleStrengthInversion",
    friendlyName: "Left Ankle - Inversion",
  },
  { setKey: "leftToeExtension", friendlyName: "Left Toe - Extension" },
  {
    setKey: "rightAnkleStrengthPlantarflexion",
    friendlyName: "Right Ankle - Plantar Flexion",
  },
  {
    setKey: "rightAnkleStrengthDorsiflexion",
    friendlyName: "Right Ankle - Dorsiflexion",
  },
  {
    setKey: "rightAnkleStrengthEversion",
    friendlyName: "Right Ankle - Eversion",
  },
  {
    setKey: "rightAnkleStrengthInversion",
    friendlyName: "Right Ankle - Inversion",
  },
  { setKey: "rightToeExtension", friendlyName: "Right Toe - Extension" },
];

export enum mskSetType {
  "MOBILITY" = "MOBILITY",
  "STRENGTH" = "STRENGTH",
}

// POSTURAL TOLERANCE DATA KEYS -----------------------------------------------------------------------

export const posturalToleranceDataKeys = [
  {
    dataKey: dataStorePageKeys.PT_FORWARD,
    friendlyName: "Reach Forward",
    assessorObservationKeys: [
      {
        friendlyName: "Heart Rate",
        valueKeys: [
          { key: "heartRateStable", friendlyName: "Stable" },
          { key: "heartRateIncreasing", friendlyName: "Increasing" },
          { key: "heartRateInconsistent", friendlyName: "Inconsistent" },
        ],
        notes: {
          notesTriggers: ["heartRateInconsistent"],
          notesField: "heartRateIncreasingDecisionDenyNote",
        },
      },
      {
        friendlyName: "Posture",
        valueKeys: [
          { key: "symmetricalPostureChecked", friendlyName: "Symmetrical" },
          { key: "asymmetricalPostureChecked", friendlyName: "Asymmetrical" },
        ],
        notes: {
          notesTriggers: ["asymmetricalPostureChecked"],
          notesField: "asymmetricalNote",
        },
      },
      {
        friendlyName: "Performance",
        valueKeys: [
          {
            key: "performanceFineMotorDifficulty",
            friendlyName: "Difficulty with fine motor activities",
          },
          {
            key: "performanceFrequentCues",
            friendlyName: "Frequent cues to maintain position",
          },
          {
            key: "difficultyMaintainingPositions",
            friendlyName: "Difficulty maintaining positions",
          },
        ],
        notes: {
          notesTriggers: ["difficultyMaintainingPositions"],
          notesField: "performanceDifficultyNotes",
        },
      },
    ],
    discomfortSetKey: "reachForward",
  },
  {
    dataKey: dataStorePageKeys.PT_ABOVE,
    friendlyName: "Reach Above Shoulder",
    assessorObservationKeys: [
      {
        friendlyName: "Heart Rate",
        valueKeys: [
          { key: "heartRateStable", friendlyName: "Stable" },
          { key: "heartRateIncreasing", friendlyName: "Increasing" },
          { key: "heartRateInconsistent", friendlyName: "Inconsistent" },
        ],
        notes: {
          notesTriggers: ["heartRateInconsistent"],
          notesField: "heartRateIncreasingDecisionDenyNote",
        },
      },
      {
        friendlyName: "Posture",
        valueKeys: [
          { key: "symmetricalPostureChecked", friendlyName: "Symmetrical" },
          { key: "asymmetricalPostureChecked", friendlyName: "Asymmetrical" },
        ],
        notes: {
          notesTriggers: ["asymmetricalPostureChecked"],
          notesField: "asymmetricalNote",
        },
      },
      {
        friendlyName: "Performance",
        valueKeys: [
          {
            key: "performanceFineMotorDifficulty",
            friendlyName: "Difficulty with fine motor activities",
          },
          {
            key: "performanceFrequentCues",
            friendlyName: "Frequent cues to maintain position",
          },
          {
            key: "difficultyMaintainingPositions",
            friendlyName: "Difficulty maintaining positions",
          },
        ],
        notes: {
          notesTriggers: ["difficultyMaintainingPositions"],
          notesField: "performanceDifficultyNotes",
        },
      },
    ],
    discomfortSetKey: "reachAbove",
  },
  {
    dataKey: dataStorePageKeys.PT_STOOP,
    friendlyName: "Stoop",
    assessorObservationKeys: [
      {
        friendlyName: "Heart Rate",
        valueKeys: [
          { key: "heartRateStable", friendlyName: "Stable" },
          { key: "heartRateIncreasing", friendlyName: "Increasing" },
          { key: "heartRateInconsistent", friendlyName: "Inconsistent" },
        ],
        notes: {
          notesTriggers: ["heartRateInconsistent"],
          notesField: "heartRateIncreasingDecisionDenyNote",
        },
      },
      {
        friendlyName: "Posture",
        valueKeys: [
          { key: "symmetricalPostureChecked", friendlyName: "Symmetrical" },
          { key: "asymmetricalPostureChecked", friendlyName: "Asymmetrical" },
        ],
        notes: {
          notesTriggers: ["asymmetricalPostureChecked"],
          notesField: "asymmetricalNote",
        },
      },
      {
        friendlyName: "Performance",
        valueKeys: [
          {
            key: "performanceFineMotorDifficulty",
            friendlyName: "Difficulty with fine motor activities",
          },
          {
            key: "performanceFrequentCues",
            friendlyName: "Frequent cues to maintain position",
          },
          {
            key: "difficultyMaintainingPositions",
            friendlyName: "Difficulty maintaining positions",
          },
        ],
        notes: {
          notesTriggers: ["difficultyMaintainingPositions"],
          notesField: "performanceDifficultyNotes",
        },
      },
    ],
    discomfortSetKey: "stoop",
  },
  {
    dataKey: dataStorePageKeys.PT_SQUAT,
    friendlyName: "Squat",
    assessorObservationKeys: [
      {
        friendlyName: "Heart Rate",
        valueKeys: [
          { key: "heartRateStable", friendlyName: "Stable" },
          { key: "heartRateIncreasing", friendlyName: "Increasing" },
          { key: "heartRateInconsistent", friendlyName: "Inconsistent" },
        ],
        notes: {
          notesTriggers: ["heartRateInconsistent"],
          notesField: "heartRateIncreasingDecisionDenyNote",
        },
      },
      {
        friendlyName: "Posture",
        valueKeys: [
          { key: "symmetricalPostureChecked", friendlyName: "Symmetrical" },
          { key: "asymmetricalPostureChecked", friendlyName: "Asymmetrical" },
        ],
        notes: {
          notesTriggers: ["asymmetricalPostureChecked"],
          notesField: "asymmetricalNote",
        },
      },
      {
        friendlyName: "Performance",
        valueKeys: [
          {
            key: "performanceFineMotorDifficulty",
            friendlyName: "Difficulty with fine motor activities",
          },
          {
            key: "performanceFrequentCues",
            friendlyName: "Frequent cues to maintain position",
          },
          {
            key: "difficultyMaintainingPositions",
            friendlyName: "Difficulty maintaining positions",
          },
          {
            key: "unevenWeightBearing",
            friendlyName: "Uneven weightbearing through legs",
          },
        ],
        notes: {
          notesTriggers: ["difficultyMaintainingPositions", "unevenWeightBearing"],
          notesField: "performanceDifficultyNotes",
        },
      },
      {
        friendlyName: "Positioning",
        valueKeys: [
          {
            key: "difficultyMoving",
            friendlyName: "Difficulty moving into position",
          },
          {
            key: "difficultyStanding",
            friendlyName: "Difficulty returning to stand",
          },
        ],
        notes: {
          notesTriggers: ["difficultyStanding"],
          notesField: "difficultyPositioningNotes",
        },
      },
    ],
    discomfortSetKey: "squat",
  },
  {
    dataKey: dataStorePageKeys.PT_KNEEL,
    friendlyName: "Kneel",
    assessorObservationKeys: [
      {
        friendlyName: "Heart Rate",
        valueKeys: [
          { key: "heartRateStable", friendlyName: "Stable" },
          { key: "heartRateIncreasing", friendlyName: "Increasing" },
          { key: "heartRateInconsistent", friendlyName: "Inconsistent" },
        ],
        notes: {
          notesTriggers: ["heartRateInconsistent"],
          notesField: "heartRateIncreasingDecisionDenyNote",
        },
      },
      {
        friendlyName: "Posture",
        valueKeys: [
          { key: "symmetricalPostureChecked", friendlyName: "Symmetrical" },
          { key: "asymmetricalPostureChecked", friendlyName: "Asymmetrical" },
        ],
        notes: {
          notesTriggers: ["asymmetricalPostureChecked"],
          notesField: "asymmetricalNote",
        },
      },
      {
        friendlyName: "Performance",
        valueKeys: [
          {
            key: "performanceFineMotorDifficulty",
            friendlyName: "Difficulty with fine motor activities",
          },
          {
            key: "performanceFrequentCues",
            friendlyName: "Frequent cues to maintain position",
          },
          {
            key: "difficultyMaintainingPositions",
            friendlyName: "Difficulty maintaining positions",
          },
          {
            key: "unevenWeightBearing",
            friendlyName: "Uneven weightbearing through legs",
          },
        ],
        notes: {
          notesTriggers: ["difficultyMaintainingPositions", "unevenWeightBearing"],
          notesField: "performanceDifficultyNotes",
        },
      },
      {
        friendlyName: "Positioning",
        valueKeys: [
          {
            key: "difficultyMoving",
            friendlyName: "Difficulty moving into position",
          },
          {
            key: "difficultyStanding",
            friendlyName: "Difficulty returning to stand",
          },
        ],
        notes: {
          notesTriggers: ["difficultyStanding"],
          notesField: "difficultyPositioningNotes",
        },
      },
    ],
    discomfortSetKey: "kneel",
  },
];

// DYNAMIC TOLERANCE DATA KEYS -----------------------------------------------------------------------

export const dynamicToleranceDataKeys = [
  {
    dataKey: dataStorePageKeys.DT_SIDE,
    friendlyName: "Reach Side to Side",
    assessorObservationKeys: [
      {
        friendlyName: "Heart Rate",
        valueKeys: [
          {
            key: "heartRateCues",
            friendlyName: "Cues to slow pace to manage heart rate",
          },
        ],
        notes: {
          notesTriggers: [],
          notesField: "",
        },
      },
      {
        friendlyName: "Posture",
        valueKeys: [
          { key: "symmetricalPostureChecked", friendlyName: "Symmetrical" },
          {
            key: "asymmetricalPostureChecked",
            friendlyName: "Asymmetrical",
          },
        ],
        notes: {
          notesTriggers: ["asymmetricalPostureChecked"],
          notesField: "asymmetricalNote",
        },
      },
      {
        friendlyName: "Performance",
        valueKeys: [
          {
            key: "performanceUnevenWeightBearing",
            friendlyName: "Uneven weightbearing through lower limbs",
          },
          {
            key: "performanceUnevenTrunkMovement",
            friendlyName: "Uneven trunk movement",
          },
          {
            key: "performanceDifficultyMaintainingPace",
            friendlyName: "Difficulty maintaining pace",
          },
          {
            key: "performanceDifficultyRepetitiveGrasping",
            friendlyName: "Difficulty with repetitive grasping",
          },
        ],
        notes: {
          notesTriggers: ["performanceUnevenWeightBearing", "performanceUnevenTrunkMovement"],
          notesField: "performanceNotes",
        },
      },
    ],
    discomfortSetKey: "reachSideToSide",
  },
  {
    dataKey: dataStorePageKeys.DT_CRAWL,
    friendlyName: "Crawling",
    assessorObservationKeys: [
      {
        friendlyName: "Heart Rate",
        valueKeys: [
          {
            key: "heartRateCues",
            friendlyName: "Cues to slow pace to manage heart rate",
          },
        ],
        notes: {
          notesTriggers: [],
          notesField: "",
        },
      },
      {
        friendlyName: "Posture",
        valueKeys: [
          { key: "symmetricalPostureChecked", friendlyName: "Symmetrical" },
          {
            key: "asymmetricalPostureChecked",
            friendlyName: "Asymmetrical",
          },
        ],
        notes: {
          notesTriggers: ["asymmetricalPostureChecked"],
          notesField: "asymmetricalNote",
        },
      },
      {
        friendlyName: "Performance",
        valueKeys: [
          {
            key: "performanceUnevenWeightBearingKnees",
            friendlyName: "Uneven weightbearing through wrists",
          },
          {
            key: "performanceUnevenWeightBearingWrists",
            friendlyName: "Uneven weightbearing through knees",
          },
        ],
        notes: {
          notesTriggers: ["performanceUnevenWeightBearingWrists", "performanceUnevenWeightBearingKnees"],
          notesField: "performanceNotes",
        },
      },
      {
        friendlyName: "Positioning",
        valueKeys: [
          {
            key: "positioningDifficultyMoving",
            friendlyName: "Difficulty moving into position",
          },
          {
            key: "positioningDifficultyStanding",
            friendlyName: "Difficulty returning to stand",
          },
        ],
        notes: {
          notesTriggers: ["positioningDifficultyMoving", "positioningDifficultyStanding"],
          notesField: "positioningNotes",
        },
      },
    ],
    discomfortSetKey: "crawling",
  },
  {
    dataKey: dataStorePageKeys.DT_LADDER,
    friendlyName: "Ladder Climbing",
    assessorObservationKeys: [
      {
        friendlyName: "Heart Rate",
        valueKeys: [
          {
            key: "heartRateCues",
            friendlyName: "Cues to slow pace to manage heart rate",
          },
        ],
        notes: {
          notesTriggers: [],
          notesField: "",
        },
      },
      {
        friendlyName: "Posture",
        valueKeys: [
          { key: "symmetricalPostureChecked", friendlyName: "Symmetrical" },
          {
            key: "asymmetricalPostureChecked",
            friendlyName: "Asymmetrical",
          },
        ],
        notes: {
          notesTriggers: ["asymmetricalPostureChecked"],
          notesField: "asymmetricalNote",
        },
      },
      {
        friendlyName: "Performance",
        valueKeys: [
          {
            key: "performanceUnableAlternateClimb",
            friendlyName: "Unable to climb with alternate pattern",
          },
          {
            key: "performanceUnableWeightbearMidFoot",
            friendlyName: "Unable to weightbear through mid foot",
          },
          {
            key: "performanceDifficultyMaintainingPace",
            friendlyName: "Difficulty maintaining working pace",
          },
          {
            key: "performanceUnevenWeightBearingLowerLimbs",
            friendlyName: "Uneven weightbearing through lower limbs",
          },
        ],
        notes: {
          notesTriggers: ["performanceUnevenWeightBearingLowerLimbs"],
          notesField: "performanceNotes",
        },
      },
    ],
    discomfortSetKey: "ladderClimb",
  },
];

// MANUAL HANDLING DATA KEYS -----------------------------------------------------------------------

export const manualHandlingDataKeys = [
  {
    dataKey: dataStorePageKeys.MH_BENCH_TO_FLOOR,
    friendlyName: "Bench to Floor Lift",
    assessorObservationKeys: [
      {
        friendlyName: "Posture",
        valueKeys: [
          { key: "symmetricalPostureChecked", friendlyName: "Symmetrical" },
          { key: "asymmetricalPostureChecked", friendlyName: "Asymmetrical" },
        ],
        notes: {
          notesTriggers: ["asymmetricalPostureChecked"],
          notesField: "asymmetricalNote",
        },
      },
      {
        friendlyName: "LL weight-bearing",
        valueKeys: [
          { key: "weightBearingEven", friendlyName: "Even" },
          { key: "weightBearingUneven", friendlyName: "Uneven" },
        ],
        notes: {
          notesTriggers: [],
          notesField: "",
        },
      },
      {
        friendlyName: "UL load carrying",
        valueKeys: [
          { key: "loadCarryingEven", friendlyName: "Even" },
          { key: "loadCarryingUneven", friendlyName: "Uneven" },
        ],
        notes: {
          notesTriggers: [],
          notesField: "",
        },
      },
      {
        friendlyName: "Performance",
        valueKeys: [
          {
            key: "semiSquatTechnique",
            friendlyName: "semi-squat technique",
          },
          {
            key: "stanceTechnique",
            friendlyName: "Stance technique",
          },
          {
            key: "elevatedAt200",
            friendlyName: "Elevated at 200mm",
          },
        ],
        notes: {
          notesTriggers: [],
          notesField: "",
        },
      },
      {
        friendlyName: "Signs of safe max lift",
        valueKeys: [
          { key: "lossOfLoadControl", friendlyName: "Loss of load control" },
          { key: "lossOfTrunkControl", friendlyName: "Loss of trunk control" },
          {
            key: "insufficientLLStrength",
            friendlyName: "Insufficient LL strength",
          },
          {
            key: "insufficientULStrength",
            friendlyName: "Insufficient UL strength",
          },
          { key: "cvMax", friendlyName: "CV max" },
          { key: "discomfortReported", friendlyName: "Discomfort reported" },
          { key: "maxLiftOther", friendlyName: "Other" },
        ],
        notes: {
          notesTriggers: ["lossOfLoadControl", "lossOfTrunkControl", "insufficientLLStrength", "insufficientULStrength", "cvMax", "discomfortReported", "maxLiftOther"],
          notesField: "maxLiftNote",
        },
      },
    ],
    discomfortSetKey: "benchFloor",
    safeMaxKeys: [
      { key: "safeMaxFloor", friendlyName: "Safe max floor" },
      { key: "safeMaxBench", friendlyName: "Safe max bench" },
    ],
    ratioDataKeys: [
      {
        key: "loadCarryingUneven",
        ratioLKey: "loadCarryingRatioL",
        ratioRKey: "loadCarryingRatioR",
      },
      {
        key: "weightBearingUneven",
        ratioLKey: "weightBearingRatioL",
        ratioRKey: "weightBearingRatioR",
      },
    ],
  },
  {
    dataKey: dataStorePageKeys.MH_BENCH_TO_BENCH,
    friendlyName: "Bench to Bench Lift",
    assessorObservationKeys: [
      {
        friendlyName: "Posture",
        valueKeys: [
          { key: "symmetricalPostureChecked", friendlyName: "Symmetrical" },
          { key: "asymmetricalPostureChecked", friendlyName: "Asymmetrical" },
        ],
        notes: {
          notesTriggers: ["asymmetricalPostureChecked"],
          notesField: "asymmetricalNote",
        },
      },
      {
        friendlyName: "LL weight-bearing",
        valueKeys: [
          { key: "weightBearingEven", friendlyName: "Even" },
          { key: "weightBearingUneven", friendlyName: "Uneven" },
        ],
        notes: {
          notesTriggers: [],
          notesField: "",
        },
      },
      {
        friendlyName: "UL load carrying",
        valueKeys: [
          { key: "loadCarryingEven", friendlyName: "Even" },
          { key: "loadCarryingUneven", friendlyName: "Uneven" },
        ],
        notes: {
          notesTriggers: [],
          notesField: "",
        },
      },
      {
        friendlyName: "Signs of safe max lift",
        valueKeys: [
          { key: "lossOfLoadControl", friendlyName: "Loss of load control" },
          { key: "lossOfTrunkControl", friendlyName: "Loss of trunk control" },
          {
            key: "insufficientLLStrength",
            friendlyName: "Insufficient LL strength",
          },
          {
            key: "insufficientULStrength",
            friendlyName: "Insufficient UL strength",
          },
          { key: "cvMax", friendlyName: "CV max" },
          { key: "discomfortReported", friendlyName: "Discomfort reported" },
          { key: "maxLiftOther", friendlyName: "Other" },
        ],
        notes: {
          notesTriggers: ["lossOfLoadControl", "lossOfTrunkControl", "insufficientLLStrength", "insufficientULStrength", "cvMax", "discomfortReported", "maxLiftOther"],
          notesField: "maxLiftNote",
        },
      },
    ],
    discomfortSetKey: "benchBench",
    safeMaxKeys: [{ key: "safeMaxBench", friendlyName: "Safe max bench" }],
    ratioDataKeys: [
      {
        key: "loadCarryingUneven",
        ratioLKey: "loadCarryingRatioL",
        ratioRKey: "loadCarryingRatioR",
      },
      {
        key: "weightBearingUneven",
        ratioLKey: "weightBearingRatioL",
        ratioRKey: "weightBearingRatioR",
      },
    ],
  },
  {
    dataKey: dataStorePageKeys.MH_BENCH_TO_SHOULDER,
    friendlyName: "Bench to Shoulder Lift",
    assessorObservationKeys: [
      {
        friendlyName: "Posture",
        valueKeys: [
          { key: "symmetricalPostureChecked", friendlyName: "Symmetrical" },
          { key: "asymmetricalPostureChecked", friendlyName: "Asymmetrical" },
        ],
        notes: {
          notesTriggers: ["asymmetricalPostureChecked"],
          notesField: "asymmetricalNote",
        },
      },
      {
        friendlyName: "LL weight-bearing",
        valueKeys: [
          { key: "weightBearingEven", friendlyName: "Even" },
          { key: "weightBearingUneven", friendlyName: "Uneven" },
        ],
        notes: {
          notesTriggers: [],
          notesField: "",
        },
      },
      {
        friendlyName: "UL load carrying",
        valueKeys: [
          { key: "loadCarryingEven", friendlyName: "Even" },
          { key: "loadCarryingUneven", friendlyName: "Uneven" },
        ],
        notes: {
          notesTriggers: [],
          notesField: "",
        },
      },
      {
        friendlyName: "Signs of safe max lift",
        valueKeys: [
          { key: "lossOfLoadControl", friendlyName: "Loss of load control" },
          { key: "lossOfTrunkControl", friendlyName: "Loss of trunk control" },
          {
            key: "insufficientLLStrength",
            friendlyName: "Insufficient LL strength",
          },
          {
            key: "insufficientULStrength",
            friendlyName: "Insufficient UL strength",
          },
          { key: "cvMax", friendlyName: "CV max" },
          { key: "discomfortReported", friendlyName: "Discomfort reported" },
          { key: "maxLiftOther", friendlyName: "Other" },
        ],
        notes: {
          notesTriggers: ["lossOfLoadControl", "lossOfTrunkControl", "insufficientLLStrength", "insufficientULStrength", "cvMax", "discomfortReported", "maxLiftOther"],
          notesField: "maxLiftNote",
        },
      },
    ],
    discomfortSetKey: "benchShoulder",
    safeMaxKeys: [{ key: "safeMaxShoulder", friendlyName: "Safe max shoulder" }],
    ratioDataKeys: [
      {
        key: "loadCarryingUneven",
        ratioLKey: "loadCarryingRatioL",
        ratioRKey: "loadCarryingRatioR",
      },
      {
        key: "weightBearingUneven",
        ratioLKey: "weightBearingRatioL",
        ratioRKey: "weightBearingRatioR",
      },
    ],
  },
  {
    dataKey: dataStorePageKeys.MH_BENCH_TO_ABOVE,
    friendlyName: "Bench to Above Shoulder Lift",
    assessorObservationKeys: [
      {
        friendlyName: "Posture",
        valueKeys: [
          { key: "symmetricalPostureChecked", friendlyName: "Symmetrical" },
          { key: "asymmetricalPostureChecked", friendlyName: "Asymmetrical" },
        ],
        notes: {
          notesTriggers: ["asymmetricalPostureChecked"],
          notesField: "asymmetricalNote",
        },
      },
      {
        friendlyName: "LL weight-bearing",
        valueKeys: [
          { key: "weightBearingEven", friendlyName: "Even" },
          { key: "weightBearingUneven", friendlyName: "Uneven" },
        ],
        notes: {
          notesTriggers: [],
          notesField: "",
        },
      },
      {
        friendlyName: "UL load carrying",
        valueKeys: [
          { key: "loadCarryingEven", friendlyName: "Even" },
          { key: "loadCarryingUneven", friendlyName: "Uneven" },
        ],
        notes: {
          notesTriggers: [],
          notesField: "",
        },
      },
      {
        friendlyName: "Signs of safe max lift",
        valueKeys: [
          { key: "lossOfLoadControl", friendlyName: "Loss of load control" },
          { key: "lossOfTrunkControl", friendlyName: "Loss of trunk control" },
          {
            key: "insufficientLLStrength",
            friendlyName: "Insufficient LL strength",
          },
          {
            key: "insufficientULStrength",
            friendlyName: "Insufficient UL strength",
          },
          { key: "cvMax", friendlyName: "CV max" },
          { key: "discomfortReported", friendlyName: "Discomfort reported" },
          { key: "maxLiftOther", friendlyName: "Other" },
        ],
        notes: {
          notesTriggers: ["lossOfLoadControl", "lossOfTrunkControl", "insufficientLLStrength", "insufficientULStrength", "cvMax", "discomfortReported", "maxLiftOther"],
          notesField: "maxLiftNote",
        },
      },
    ],
    discomfortSetKey: "benchAboveShoulder",
    safeMaxKeys: [{ key: "safeMaxAboveShoulder", friendlyName: "Safe max above shoulder" }],
    ratioDataKeys: [
      {
        key: "loadCarryingUneven",
        ratioLKey: "loadCarryingRatioL",
        ratioRKey: "loadCarryingRatioR",
      },
      {
        key: "weightBearingUneven",
        ratioLKey: "weightBearingRatioL",
        ratioRKey: "weightBearingRatioR",
      },
    ],
  },
  {
    dataKey: dataStorePageKeys.MH_BILATERAL,
    friendlyName: "Bilateral Carry",
    assessorObservationKeys: [
      {
        friendlyName: "Posture",
        valueKeys: [
          { key: "symmetricalPostureChecked", friendlyName: "Symmetrical" },
          { key: "asymmetricalPostureChecked", friendlyName: "Asymmetrical" },
        ],
        notes: {
          notesTriggers: ["asymmetricalPostureChecked"],
          notesField: "asymmetricalNote",
        },
      },
      {
        friendlyName: "LL weight-bearing",
        valueKeys: [
          { key: "weightBearingEven", friendlyName: "Even" },
          { key: "weightBearingUneven", friendlyName: "Uneven" },
        ],
        notes: {
          notesTriggers: [],
          notesField: "",
        },
      },
      {
        friendlyName: "UL load carrying",
        valueKeys: [
          { key: "loadCarryingEven", friendlyName: "Even" },
          { key: "loadCarryingUneven", friendlyName: "Uneven" },
        ],
        notes: {
          notesTriggers: [],
          notesField: "",
        },
      },
      {
        friendlyName: "Performance",
        valueKeys: [
          {
            key: "performanceNoChangeInGait",
            friendlyName: "No change in gait pattern",
          },
          {
            key: "performanceChangeInGait",
            friendlyName: "Change in gait pattern",
          },
        ],
        notes: {
          notesTriggers: ["performanceChangeInGait"],
          notesField: "performanceChangeInGaitNote",
        },
      },
      {
        friendlyName: "Signs of safe max lift",
        valueKeys: [
          { key: "lossOfLoadControl", friendlyName: "Loss of load control" },
          { key: "lossOfTrunkControl", friendlyName: "Loss of trunk control" },
          {
            key: "insufficientLLStrength",
            friendlyName: "Insufficient LL strength",
          },
          {
            key: "insufficientULStrength",
            friendlyName: "Insufficient UL strength",
          },
          { key: "cvMax", friendlyName: "CV max" },
          { key: "discomfortReported", friendlyName: "Discomfort reported" },
          { key: "maxLiftOther", friendlyName: "Other" },
        ],
        notes: {
          notesTriggers: ["lossOfLoadControl", "lossOfTrunkControl", "insufficientLLStrength", "insufficientULStrength", "cvMax", "discomfortReported", "maxLiftOther"],
          notesField: "maxLiftNote",
        },
      },
    ],
    discomfortSetKey: "bilateral",
    safeMaxKeys: [{ key: "safeBilateral", friendlyName: "Safe max bilateral" }],
    ratioDataKeys: [
      {
        key: "loadCarryingUneven",
        ratioLKey: "loadCarryingRatioL",
        ratioRKey: "loadCarryingRatioR",
      },
      {
        key: "weightBearingUneven",
        ratioLKey: "weightBearingRatioL",
        ratioRKey: "weightBearingRatioR",
      },
    ],
  },
  {
    dataKey: dataStorePageKeys.MH_SINGLE_L,
    friendlyName: "Single Carry Left",
    assessorObservationKeys: [
      {
        friendlyName: "Posture",
        valueKeys: [
          { key: "symmetricalPostureChecked", friendlyName: "Symmetrical" },
          { key: "asymmetricalPostureChecked", friendlyName: "Asymmetrical" },
        ],
        notes: {
          notesTriggers: ["asymmetricalPostureChecked"],
          notesField: "asymmetricalNote",
        },
      },
      {
        friendlyName: "LL weight-bearing",
        valueKeys: [
          { key: "weightBearingEven", friendlyName: "Even" },
          { key: "weightBearingUneven", friendlyName: "Uneven" },
        ],
        notes: {
          notesTriggers: [],
          notesField: "",
        },
      },
      {
        friendlyName: "Performance",
        valueKeys: [
          {
            key: "performanceNoChangeInGait",
            friendlyName: "No change in gait pattern",
          },
          {
            key: "performanceChangeInGait",
            friendlyName: "Change in gait pattern",
          },
        ],
        notes: {
          notesTriggers: ["performanceChangeInGait"],
          notesField: "performanceChangeInGaitNote",
        },
      },
      {
        friendlyName: "Signs of safe max lift",
        valueKeys: [
          { key: "lossOfLoadControl", friendlyName: "Loss of load control" },
          { key: "lossOfTrunkControl", friendlyName: "Loss of trunk control" },
          {
            key: "insufficientLLStrength",
            friendlyName: "Insufficient LL strength",
          },
          { key: "cvMax", friendlyName: "CV max" },
          { key: "discomfortReported", friendlyName: "Discomfort reported" },
          { key: "maxLiftOther", friendlyName: "Other" },
        ],
        notes: {
          notesTriggers: ["lossOfLoadControl", "lossOfTrunkControl", "insufficientLLStrength", "cvMax", "discomfortReported", "maxLiftOther"],
          notesField: "maxLiftNote",
        },
      },
    ],
    discomfortSetKey: "singleCarryL",
    safeMaxKeys: [{ key: "safeSingleL", friendlyName: "Safe max single left" }],
    ratioDataKeys: [
      {
        key: "weightBearingUneven",
        ratioLKey: "weightBearingRatioL",
        ratioRKey: "weightBearingRatioR",
      },
    ],
  },
  {
    dataKey: dataStorePageKeys.MH_SINGLE_R,
    friendlyName: "Single Carry Right",
    assessorObservationKeys: [
      {
        friendlyName: "Posture",
        valueKeys: [
          { key: "symmetricalPostureChecked", friendlyName: "Symmetrical" },
          { key: "asymmetricalPostureChecked", friendlyName: "Asymmetrical" },
        ],
        notes: {
          notesTriggers: ["asymmetricalPostureChecked"],
          notesField: "asymmetricalNote",
        },
      },
      {
        friendlyName: "LL weight-bearing",
        valueKeys: [
          { key: "weightBearingEven", friendlyName: "Even" },
          { key: "weightBearingUneven", friendlyName: "Uneven" },
        ],
        notes: {
          notesTriggers: [],
          notesField: "",
        },
      },
      {
        friendlyName: "Performance",
        valueKeys: [
          {
            key: "performanceNoChangeInGait",
            friendlyName: "No change in gait pattern",
          },
          {
            key: "performanceChangeInGait",
            friendlyName: "Change in gait pattern",
          },
        ],
        notes: {
          notesTriggers: ["performanceChangeInGait"],
          notesField: "performanceChangeInGaitNote",
        },
      },
      {
        friendlyName: "Signs of safe max lift",
        valueKeys: [
          { key: "lossOfLoadControl", friendlyName: "Loss of load control" },
          { key: "lossOfTrunkControl", friendlyName: "Loss of trunk control" },
          {
            key: "insufficientLLStrength",
            friendlyName: "Insufficient LL strength",
          },
          { key: "cvMax", friendlyName: "CV max" },
          { key: "discomfortReported", friendlyName: "Discomfort reported" },
          { key: "maxLiftOther", friendlyName: "Other" },
        ],
        notes: {
          notesTriggers: ["lossOfLoadControl", "lossOfTrunkControl", "insufficientLLStrength", "cvMax", "discomfortReported", "maxLiftOther"],
          notesField: "maxLiftNote",
        },
      },
    ],
    discomfortSetKey: "singleCarryR",
    safeMaxKeys: [{ key: "safeSingleR", friendlyName: "Safe max single right" }],
    ratioDataKeys: [
      {
        key: "weightBearingUneven",
        ratioLKey: "weightBearingRatioL",
        ratioRKey: "weightBearingRatioR",
      },
    ],
  },
];

import React, { useEffect, useState } from "react";
import { Typography, FormControl, Button, FormGroup, Grid, MenuItem, Select, Backdrop, Slide, Modal } from "@material-ui/core";

import { inject, observer } from "mobx-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

interface MedicalClearanceProps {
  data: any;
  dataKey: string;
  label: string;
  note: string;
  medicalClearanceModalTrigger: boolean;
  medicalClearanceSavedHandler: any;
  medicalClearances: any;
}

export enum medicalClearanceSelectKeys {
  "SELECT" = "SELECT",
  "NOT_PROVIDED" = "NOT_PROVIDED",
  "NOT_REQUIRED" = "NOT_REQUIRED",
  "NOTES" = "NOTES",
  "DOCTOR" = "DOCTOR",
}

export const medicalClearanceSelectKeysFriendly: { [key: string]: string } = {
  SELECT: "Select option from list",
  NOT_PROVIDED: "Not Provided",
  NOT_REQUIRED: "Not Required",
  NOTES: "",
};
const medicalClearanceConfirmationValidation = yup.object().shape({
  medicalClearanceSelected: yup.string().test("medicalClearance", "Please choose a medical clearance option", function () {
    return this.parent.medicalClearanceOption && this.parent.medicalClearanceOption !== medicalClearanceSelectKeys.SELECT;
  }),
});
const MedicalClearance = inject("rootStore")(
  observer((props: MedicalClearanceProps) => {
    const { register, handleSubmit, errors } = useForm({
      resolver: yupResolver(medicalClearanceConfirmationValidation),
    });

    const [state, setState] = useState({
      medicalClearanceModalOpen: false,
      medicalClearanceOption: props.data
        ? props.data[`${props.dataKey}_medicalClearanceOption`]
          ? (props.data[`${props.dataKey}_medicalClearanceOption`] as medicalClearanceSelectKeys)
          : medicalClearanceSelectKeys.SELECT
        : medicalClearanceSelectKeys.SELECT,
      medicalClearanceText: props.data ? props.data[`${props.dataKey}_medicalClearanceText`] : "",
    });
    const handleCloseModal = (): void => {
      props.medicalClearanceSavedHandler(props.dataKey, "", "");
      setState((prevState) => ({
        ...prevState,
        medicalClearanceModalOpen: false,
        medicalClearanceOption: medicalClearanceSelectKeys.SELECT,
        medicalClearanceText: "",
      }));
    };

    const handleOpenModal = (): void => {
      setState((prevState) => ({
        ...prevState,
        medicalClearanceModalOpen: true,
      }));
    };

    const handleMedicalClearanceConfirmedClicked = (): void => {
      const { medicalClearanceOption, medicalClearanceText } = state;

      props.medicalClearanceSavedHandler(props.dataKey, medicalClearanceOption, medicalClearanceText);
      setState((prevState) => ({ ...prevState, medicalClearanceModalOpen: false }));
    };
    const dropDownStype = {
      maxHeight: "100px",
      menuPlacement: "top",
      paddingBottom: "37px",
    };
    useEffect(() => {
      if (props.medicalClearanceModalTrigger && !state.medicalClearanceText) {
        setState((prevState) => ({
          ...prevState,
          medicalClearanceModalOpen: true,
        }));
      }

      if (!props.medicalClearanceModalTrigger && state.medicalClearanceText) {
        setState((prevState) => ({
          ...prevState,
          medicalClearanceText: "",
          medicalClearanceOption: medicalClearanceSelectKeys.SELECT,
        }));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.medicalClearanceModalTrigger, state.medicalClearanceText]);

    return (
      <>
        {state.medicalClearanceText ? (
          <div className="fullWidthFlexerUnder">
            <Typography className="medicalFileClearance" onClick={handleOpenModal}>
              {state.medicalClearanceText}
            </Typography>
          </div>
        ) : (
          <></>
        )}

        <Modal
          aria-labelledby="modal-title"
          open={state.medicalClearanceModalOpen}
          onClose={handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Slide direction="up" in={state.medicalClearanceModalOpen} mountOnEnter unmountOnExit>
            <div className="modalPopSmall">
              <form>
                <div className="notesModalText">
                  <Typography variant="h2" className="helpHeader">
                    Medical Clearance - {props.label}
                  </Typography>
                  <Typography variant="caption" className="helpCaption">
                    Please select the relevant medical clearance - {props.note}
                  </Typography>
                  <FormGroup className="formGroupOveride">
                    <Grid xs={12} item container className="StrengthTestContainer">
                      <FormControl component="fieldset" className="fieldsetStrength">
                        <FormControl variant="outlined">
                          <Select
                            color="primary"
                            className="medicalClearanceSelect"
                            value={state.medicalClearanceOption}
                            onChange={(event) => {
                              setState((prevState) => ({
                                ...prevState,
                                medicalClearanceOption: event.target.value as medicalClearanceSelectKeys,
                                medicalClearanceText:
                                  medicalClearanceSelectKeysFriendly[event.target.value as string] ||
                                  props.medicalClearances.filter((mc: any) => mc.id === event.target.value).map((mc: any) => `${mc.condition} - ${mc.outcome}`)[0],
                              }));
                            }}
                          >
                            <MenuItem className="medicalClearanceOptions" style={{ paddingBottom: "10px" }} value={medicalClearanceSelectKeys.SELECT}>
                              {medicalClearanceSelectKeysFriendly.SELECT}
                            </MenuItem>
                            <MenuItem className="medicalClearanceOptions" style={{ paddingBottom: "10px" }} value={medicalClearanceSelectKeys.NOT_PROVIDED}>
                              {medicalClearanceSelectKeysFriendly.NOT_PROVIDED}
                            </MenuItem>
                            <MenuItem className="medicalClearanceOptions" style={{ paddingBottom: "10px" }} value={medicalClearanceSelectKeys.NOT_REQUIRED}>
                              {medicalClearanceSelectKeysFriendly.NOT_REQUIRED}
                            </MenuItem>
                            {props.medicalClearances.map((medicalClearance: any) => (
                              <MenuItem value={medicalClearance.id} style={dropDownStype}>
                                {medicalClearance.condition} - {medicalClearance.outcome}
                              </MenuItem>
                            ))}
                          </Select>
                          <div className="invalidFeedback">{errors.medicalClearanceSelected?.message}</div>
                          <input type="text" style={{ visibility: "hidden" }} name={"medicalClearanceOption"} value={state.medicalClearanceOption} ref={register} />
                        </FormControl>

                        <div className="flexerEnd">
                          <Button color="primary" variant="outlined" onClick={handleCloseModal}>
                            Cancel
                          </Button>
                          <Button color="primary" variant="contained" onClick={handleSubmit(handleMedicalClearanceConfirmedClicked)}>
                            Prepare Medical Clearance
                          </Button>
                        </div>
                      </FormControl>
                    </Grid>
                  </FormGroup>
                </div>
              </form>
            </div>
          </Slide>
        </Modal>
      </>
    );
  })
);

export default MedicalClearance;

import * as yup from "yup";

const fitnessTestValidation = yup.object().shape({
  preTestHr: yup.boolean().test(
    "preTestHr",
    "Please enter a pre-test HR",

    function () {
      const { notTestedReason, preTestHeartRate } = this.parent;
      if (!notTestedReason && !preTestHeartRate) {
        return false;
      }
      return true;
    }
  ),
  weightAchieved: yup.bool().test("weightAchieved", "Please complete the test and provide an achieved weight.", function () {
    const { notTestedReason, testWeight } = this.parent;

    if (!notTestedReason && !testWeight) {
      return false;
    }

    return true;
  }),
  posture: yup.boolean().test(
    "posture",
    "Please indicate participant's posture.",

    function () {
      const { notTestedReason, asymmetricalPostureChecked, symmetricalPostureChecked } = this.parent;
      if (!notTestedReason && !asymmetricalPostureChecked && !symmetricalPostureChecked) {
        return false;
      }
      return true;
    }
  ),
  postureAsymmetrical: yup.bool().test("postureAsymmetrical", "Please describe the participant's asymmetrical posture.", function () {
    const { notTestedReason, asymmetricalPostureChecked, postureNote } = this.parent;
    if (!notTestedReason && asymmetricalPostureChecked && !postureNote) {
      return false;
    }
    return true;
  }),
  weightBearing: yup.bool().test("weightBearing", "Please report on the participant's LL weight-bearing.", function () {
    if (!this.parent.notTestedReason && this.parent.weightBearingEven === false && this.parent.weightBearingUneven === false) {
      return false;
    }
    return true;
  }),
  loadCarrying: yup.bool().test("loadCarrying", "Please report on the participants UL load carrying.", function () {
    if (!this.parent.notTestedReason && this.parent.loadCarryingEven === false && this.parent.loadCarryingUneven === false) {
      return false;
    }
    return true;
  }),
  performance: yup.boolean().test(
    "performance",
    "Please indicate participant's performance.",

    function () {
      const { notTestedReason, performanceNoChangeInGait, performanceChangeInGait } = this.parent;
      if (!notTestedReason && performanceNoChangeInGait === false && performanceChangeInGait === false) {
        return false;
      }
      return true;
    }
  ),
  performanceNotes: yup.bool().test("performanceNotes", "Please describe the participant's change in gait.", function () {
    const { notTestedReason, performanceNoChangeInGait, performanceChangeInGaitNote } = this.parent;
    if (!notTestedReason && performanceNoChangeInGait === true && performanceChangeInGaitNote === "") {
      return false;
    }
    return true;
  }),
  safeMaxLift: yup.bool().test("safeMaxLift", "Please indicate signs of the safe maximum lift.", function () {
    if (
      !this.parent.notTestedReason &&
      (this.parent.safeMaxBench === true || this.parent.safeMaxFloor === true || this.parent.safeMaxShoulder === true || this.parent.safeMaxAboveShoulder === true) &&
      this.parent.lossOfLoadControl === false &&
      this.parent.lossOfTrunkControl === false &&
      this.parent.insufficientLLStrength === false &&
      this.parent.insufficientULStrength === false &&
      this.parent.cvMax === false &&
      this.parent.discomfortReported === false &&
      this.parent.maxLiftOther === false
    ) {
      return false;
    }
    return true;
  }),
  safeMaxLiftNotes: yup.bool().test("safeMaxLiftNotes", "Please describe the signs of the safe maximum lift.", function () {
    if (
      !this.parent.notTestedReason &&
      (this.parent.lossOfLoadControl === true ||
        this.parent.lossOfTrunkControl === true ||
        this.parent.insufficientLLStrength === true ||
        this.parent.insufficientULStrength === true ||
        this.parent.cvMax === true ||
        this.parent.discomfortReported === true ||
        this.parent.maxLiftOther === true) &&
      this.parent.maxLiftNote === ""
    ) {
      return false;
    }
    return true;
  }),
  participantReports: yup.bool().test("participantReports", "Please report the participant's discomfort.", function () {
    if (
      !this.parent.notTestedReason &&
      ((this.parent.benchFloor_deniedDiscomfort === false && this.parent.benchFloor_reportedDiscomfort === false) ||
        (this.parent.benchBench_deniedDiscomfort === false && this.parent.benchBench_reportedDiscomfort === false) ||
        (this.parent.benchShoulder_deniedDiscomfort === false && this.parent.benchShoulder_reportedDiscomfort === false) ||
        (this.parent.benchAboveShoulder_deniedDiscomfort === false && this.parent.benchAboveShoulder_reportedDiscomfort === false) ||
        (this.parent.bilateral_deniedDiscomfort === false && this.parent.bilateral_reportedDiscomfort === false) ||
        (this.parent.singleCarryR_deniedDiscomfort === false && this.parent.singleCarryR_reportedDiscomfort === false) ||
        (this.parent.singleCarryL_deniedDiscomfort === false && this.parent.singleCarryL_reportedDiscomfort === false))
    ) {
      return false;
    }
    return true;
  }),
  location: yup.bool().test("location", "Please provide the location of the discomfort.", function () {
    if (
      !this.parent.notTestedReason &&
      ((this.parent.benchFloor_reportedDiscomfort === true && this.parent.benchFloor_location === "") ||
        (this.parent.benchBench_reportedDiscomfort === true && this.parent.benchBench_location === "") ||
        (this.parent.benchShoulder_reportedDiscomfort === true && this.parent.benchShoulder_location === "") ||
        (this.parent.benchAboveShoulder_reportedDiscomfort === true && this.parent.benchAboveShoulder_location === "") ||
        (this.parent.bilateral_reportedDiscomfort === true && this.parent.bilateral_location === "") ||
        (this.parent.singleCarryR_reportedDiscomfort === true && this.parent.singleCarryR_location === "") ||
        (this.parent.singleCarryL_reportedDiscomfort === true && this.parent.singleCarryL_location === ""))
    ) {
      return false;
    }

    return true;
  }),
  intensity: yup.bool().test("intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      !this.parent.notTestedReason &&
      ((this.parent.benchFloor_reportedDiscomfort === true &&
        this.parent.benchFloor_intensityVas === "" &&
        this.parent.benchFloor_intensityMild === false &&
        this.parent.benchFloor_intensityMod === false &&
        this.parent.benchFloor_intensitySevere === false) ||
        (this.parent.benchBench_reportedDiscomfort === true &&
          this.parent.benchBench_intensityVas === "" &&
          this.parent.benchBench_intensityMild === false &&
          this.parent.benchBench_intensityMod === false &&
          this.parent.benchBench_intensitySevere === false) ||
        (this.parent.benchShoulder_reportedDiscomfort === true &&
          this.parent.benchShoulder_intensityVas === "" &&
          this.parent.benchShoulder_intensityMild === false &&
          this.parent.benchShoulder_intensityMod === false &&
          this.parent.benchShoulder_intensitySevere === false) ||
        (this.parent.benchAboveShoulder_reportedDiscomfort === true &&
          this.parent.benchAboveShoulder_intensityVas === "" &&
          this.parent.benchAboveShoulder_intensityMild === false &&
          this.parent.benchAboveShoulder_intensityMod === false &&
          this.parent.benchAboveShoulder_intensitySevere === false) ||
        (this.parent.bilateral_reportedDiscomfort === true &&
          this.parent.bilateral_intensityVas === "" &&
          this.parent.bilateral_intensityMild === false &&
          this.parent.bilateral_intensityMod === false &&
          this.parent.bilateral_intensitySevere === false) ||
        (this.parent.singleCarryR_reportedDiscomfort === true &&
          this.parent.singleCarryR_intensityVas === "" &&
          this.parent.singleCarryR_intensityMild === false &&
          this.parent.singleCarryR_intensityMod === false &&
          this.parent.singleCarryR_intensitySevere === false) ||
        (this.parent.singleCarryL_reportedDiscomfort === true &&
          this.parent.singleCarryL_intensityVas === "" &&
          this.parent.singleCarryL_intensityMild === false &&
          this.parent.singleCarryL_intensityMod === false &&
          this.parent.singleCarryL_intensitySevere === false))
    ) {
      return false;
    }

    return true;
  }),
  type: yup.bool().test("type", "Please describe the type of discomfort.", function () {
    if (
      !this.parent.notTestedReason &&
      ((this.parent.benchFloor_discomfortTypeAche === false &&
        this.parent.benchFloor_discomfortTypeSharp === false &&
        this.parent.benchFloor_discomfortTypeBurning === false &&
        this.parent.benchFloor_discomfortTypeTightness === false &&
        this.parent.benchFloor_discomfortTypePulling === false &&
        this.parent.benchFloor_discomfortTypeOther === false) ||
        (this.parent.benchBench_discomfortTypeAche === false &&
          this.parent.benchBench_discomfortTypeSharp === false &&
          this.parent.benchBench_discomfortTypeBurning === false &&
          this.parent.benchBench_discomfortTypeTightness === false &&
          this.parent.benchBench_discomfortTypePulling === false &&
          this.parent.benchBench_discomfortTypeOther === false) ||
        (this.parent.benchShoulder_discomfortTypeAche === false &&
          this.parent.benchShoulder_discomfortTypeSharp === false &&
          this.parent.benchShoulder_discomfortTypeBurning === false &&
          this.parent.benchShoulder_discomfortTypeTightness === false &&
          this.parent.benchShoulder_discomfortTypePulling === false &&
          this.parent.benchShoulder_discomfortTypeOther === false) ||
        (this.parent.benchAboveShoulder_discomfortTypeAche === false &&
          this.parent.benchAboveShoulder_discomfortTypeSharp === false &&
          this.parent.benchAboveShoulder_discomfortTypeBurning === false &&
          this.parent.benchAboveShoulder_discomfortTypeTightness === false &&
          this.parent.benchAboveShoulder_discomfortTypePulling === false &&
          this.parent.benchAboveShoulder_discomfortTypeOther === false) ||
        (this.parent.bilateral_discomfortTypeAche === false &&
          this.parent.bilateral_discomfortTypeSharp === false &&
          this.parent.bilateral_discomfortTypeBurning === false &&
          this.parent.bilateral_discomfortTypeTightness === false &&
          this.parent.bilateral_discomfortTypePulling === false &&
          this.parent.bilateral_discomfortTypeOther === false) ||
        (this.parent.singleCarryR_discomfortTypeAche === false &&
          this.parent.singleCarryR_discomfortTypeSharp === false &&
          this.parent.singleCarryR_discomfortTypeBurning === false &&
          this.parent.singleCarryR_discomfortTypeTightness === false &&
          this.parent.singleCarryR_discomfortTypePulling === false &&
          this.parent.singleCarryR_discomfortTypeOther === false) ||
        (this.parent.singleCarryL_discomfortTypeAche === false &&
          this.parent.singleCarryL_discomfortTypeSharp === false &&
          this.parent.singleCarryL_discomfortTypeBurning === false &&
          this.parent.singleCarryL_discomfortTypeTightness === false &&
          this.parent.singleCarryL_discomfortTypePulling === false &&
          this.parent.singleCarryL_discomfortTypeOther === false))
    ) {
      return false;
    }

    return true;
  }),
  typeOther: yup.bool().test("typeOther", "Please describe the discomfort further.", function () {
    if (
      !this.parent.notTestedReason &&
      ((this.parent.benchFloor_discomfortTypeOther === true && this.parent.benchFloor_typeOther === "") ||
        (this.parent.benchBench_discomfortTypeOther === true && this.parent.benchBench_typeOther === "") ||
        (this.parent.benchShoulder_discomfortTypeOther === true && this.parent.benchShoulder_typeOther === "") ||
        (this.parent.benchAboveShoulder_discomfortTypeOther === true && this.parent.benchAboveShoulder_typeOther === "") ||
        (this.parent.bilateral_discomfortTypeOther === true && this.parent.bilateral_typeOther === "") ||
        (this.parent.singleCarryR_discomfortTypeOther === true && this.parent.singleCarryR_typeOther === "") ||
        (this.parent.singleCarryL_discomfortTypeOther === true && this.parent.singleCarryL_typeOther === ""))
    ) {
      return false;
    }

    return true;
  }),
  status: yup.bool().test("status", "Please provide the status of the discomfort.", function () {
    if (
      !this.parent.notTestedReason &&
      ((this.parent.benchFloor_discomfortResolvedImmediately === false &&
        this.parent.benchFloor_discomfortPersisting === false &&
        this.parent.benchFloor_discomfortResolvedWithRest === false &&
        this.parent.benchFloor_discomfortIncreasing === false) ||
        (this.parent.benchBench_discomfortResolvedImmediately === false &&
          this.parent.benchBench_discomfortPersisting === false &&
          this.parent.benchBench_discomfortResolvedWithRest === false &&
          this.parent.benchBench_discomfortIncreasing === false) ||
        (this.parent.benchShoulder_discomfortResolvedImmediately === false &&
          this.parent.benchShoulder_discomfortPersisting === false &&
          this.parent.benchShoulder_discomfortResolvedWithRest === false &&
          this.parent.benchShoulder_discomfortIncreasing === false) ||
        (this.parent.benchAboveShoulder_discomfortResolvedImmediately === false &&
          this.parent.benchAboveShoulder_discomfortPersisting === false &&
          this.parent.benchAboveShoulder_discomfortResolvedWithRest === false &&
          this.parent.benchAboveShoulder_discomfortIncreasing === false) ||
        (this.parent.bilateral_discomfortResolvedImmediately === false &&
          this.parent.bilateral_discomfortPersisting === false &&
          this.parent.bilateral_discomfortResolvedWithRest === false &&
          this.parent.bilateral_discomfortIncreasing === false) ||
        (this.parent.singleCarryR_discomfortResolvedImmediately === false &&
          this.parent.singleCarryR_discomfortPersisting === false &&
          this.parent.singleCarryR_discomfortResolvedWithRest === false &&
          this.parent.singleCarryR_discomfortIncreasing === false) ||
        (this.parent.singleCarryL_discomfortResolvedImmediately === false &&
          this.parent.singleCarryL_discomfortPersisting === false &&
          this.parent.singleCarryL_discomfortResolvedWithRest === false &&
          this.parent.singleCarryL_discomfortIncreasing === false))
    ) {
      return false;
    }

    return true;
  }),
});

export default fitnessTestValidation;

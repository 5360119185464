import React, { ChangeEvent, useEffect, useState } from "react";
import { FormControl, FormLabel, FormGroup, Select, MenuItem, OutlinedInput, Grid } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import { strengthDiscomfortFactors, strengthDiscomfortFactorsFriendly } from "./StrengthTestDataKeys";
import { dataStorePageKeys } from "../../stores/dataStore";
import { Root } from "../../stores";
interface StrengthProps {
  rootStore?: Root;
  label: string;
  datasetName: string;

  handleDataChanged?: any;

  data?: any;
  register?: any;
  errors?: any;
  setValue?: any;

  assessmentID?: any;
  dataKey?: dataStorePageKeys;
}

const StrengthTestComponent = inject("rootStore")(
  observer((props: StrengthProps) => {
    const { label, datasetName } = props;

    const [state, setState] = useState({
      dataChanged: props.data ? props.data[`${datasetName}_discomfort`] !== strengthDiscomfortFactors.SELECT || props.data[`${datasetName}_grade`] !== "5" : false,

      [`${datasetName}_grade`]: props.data ? (props.data[`${datasetName}_grade`] ? props.data[`${datasetName}_grade`] : "5") : "5",
      [`${datasetName}_discomfort`]: props.data
        ? props.data[`${datasetName}_discomfort`]
          ? props.data[`${datasetName}_discomfort`]
          : strengthDiscomfortFactors.SELECT
        : strengthDiscomfortFactors.SELECT,
      [`${datasetName}_description`]: props.data ? props.data[`${datasetName}_description`] : "",
      showDescription: props.data ? props.data[`${datasetName}_discomfort`] === strengthDiscomfortFactors.YES : false,
      isLoaded: false,
    });

    const handleChange = (event: ChangeEvent<any>): void => {
      event.persist();

      //get whatever the previous showDescription state was.
      let showDescription = state.showDescription;

      //if we have changed the discomfort dropdown, set showDiscomfort accordingly.
      if (event.target.name === `${datasetName}_discomfort`) {
        showDescription = event.target.value === strengthDiscomfortFactors.YES;
      }

      setState((prevState) => ({
        ...prevState,
        dataChanged: true,
        [event.target.name]: event.target.value,
        showDescription,
      }));
    };

    useEffect(() => {
      if (props.handleDataChanged && state.dataChanged) {
        setState((prevState) => ({ ...prevState, dataChanged: false }));
        props.handleDataChanged(datasetName, state[`${datasetName}_discomfort`] !== strengthDiscomfortFactors.SELECT || state[`${datasetName}_grade`] !== "5");
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    useEffect(() => {
      const timeout = setTimeout(() => {
        if (!!props.dataKey && !!props.assessmentID && state.isLoaded) {
          props.rootStore?.dataStore.savePartialData(state, props.assessmentID, props.dataKey);
        }
      }, 500);

      return () => clearTimeout(timeout);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state[`${datasetName}_grade`], state[`${datasetName}_discomfort`], state[`${datasetName}_description`]]);

    useEffect(() => {
      setState((prevState) => ({ ...prevState, isLoaded: true }));
    }, []);

    return (
      <Grid xs={12} item container className="StrengthTestContainer">
        <FormControl component="fieldset" className="fieldsetStrength">
          <FormGroup className="formGroupOveride">
            <div className="flexer">
              <div className="labelLeftOveride">
                <FormLabel className="greyPill" component="legend">
                  {label}
                </FormLabel>
              </div>
              <FormControl variant="outlined" className="selectOveride">
                <FormLabel component="legend" className="textLabelSmall">
                  Grade
                </FormLabel>
                <Select color="primary" onChange={handleChange} value={state[`${datasetName}_grade`]} name={`${datasetName}_grade`}>
                  <MenuItem value="5">5</MenuItem>
                  <MenuItem value="4">4</MenuItem>
                  <MenuItem value="3">3</MenuItem>
                  <MenuItem value="2">2</MenuItem>
                  <MenuItem value="1">1</MenuItem>
                </Select>
                {/* This is about as disgusting as it gets. However this is still better than
                    replicating the contents of this component many times inside the MSK forms.
                    Think of the children! */}
                <input type="text" style={{ visibility: "hidden" }} name={datasetName + "_grade"} value={state[`${datasetName}_grade`]} ref={props.register} />
              </FormControl>
              <FormControl variant="outlined" className="selectOveride">
                <FormLabel component="legend" className="textLabelSmall">
                  Discomfort
                </FormLabel>
                <Select color="primary" value={state[`${datasetName}_discomfort`]} name={`${datasetName}_discomfort`} onChange={handleChange}>
                  <MenuItem value={strengthDiscomfortFactors.SELECT}>{strengthDiscomfortFactorsFriendly.SELECT}</MenuItem>
                  <MenuItem value={strengthDiscomfortFactors.NO}>{strengthDiscomfortFactorsFriendly.NO}</MenuItem>
                  <MenuItem value={strengthDiscomfortFactors.YES}>{strengthDiscomfortFactorsFriendly.YES}</MenuItem>
                </Select>
                <input type="text" style={{ visibility: "hidden" }} name={datasetName + "_discomfort"} value={state[`${datasetName}_discomfort`]} ref={props.register} />
              </FormControl>
              {state.showDescription ? (
                <FormGroup>
                  <OutlinedInput
                    color="primary"
                    value={state[`${datasetName}_description`]}
                    name={`${datasetName}_description`}
                    className="textInputLarge"
                    autoComplete="off"
                    inputRef={props.register}
                    onChange={(event) => {
                      event.persist();
                      setState((prevState) => ({
                        ...prevState,
                        [event.target.name]: event.target.value,
                      }));
                    }}
                  />
                </FormGroup>
              ) : (
                <></>
              )}
            </div>

            <div className="invalid-feedback">{props.errors[datasetName]?.message}</div>
          </FormGroup>
        </FormControl>
      </Grid>
    );
  })
);

export default StrengthTestComponent;

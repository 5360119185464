import React, { Component, ReactNode } from "react";
import { MetronomeIcon } from "../../assets/images";
import ProMetronome from "./react-pro-metronome";

import { Button } from "@material-ui/core";

interface MetronomeProps {
  bpm: number;
  isPlayingHandler?: any;
  metronomeRemoteTrigger?: any;
}

interface MetronomeState {
  isPlaying: boolean;
}

class Metronome extends Component<MetronomeProps, MetronomeState> {
  constructor(props: MetronomeProps) {
    super(props);

    this.state = {
      isPlaying: false,
    };

    this.handleMetronomeClick = this.handleMetronomeClick.bind(this);
  }

  handleMetronomeClick(override?: boolean): void {
    if (!!this.props.isPlayingHandler) {
      this.props.isPlayingHandler(override || !this.state.isPlaying);
    }

    this.setState((prevState) => ({
      ...prevState,
      isPlaying: override || !this.state.isPlaying,
    }));
  }

  componentWillReceiveProps(nextProps: MetronomeProps): void {
    //Only trigger the remote timer start if the previous remote trigger is false and the current one is true.
    //IE: there has been a change to the remote start trigger, from false to on.
    if (nextProps.metronomeRemoteTrigger !== this.props.metronomeRemoteTrigger) {
      this.handleMetronomeClick(nextProps.metronomeRemoteTrigger && !this.state.isPlaying);
    }
  }

  render(): ReactNode {
    return (
      <>
        <div className="metronomeContainer">
          <Button className="metronomeBtn" onClick={(event) => this.handleMetronomeClick()}>
            <ProMetronome
              bpm={this.props.bpm}
              isPlaying={this.state.isPlaying}
              subdivision={1}
              soundEnabled={true}
              soundPattern="2222"
              render={(props: any, state: any) => <></>}
            />
            <img src={MetronomeIcon} width="24" alt="Metronome Icon" />
            {this.state.isPlaying ? <div className="metronomeBpmStarted">{`${this.props.bpm}bpm`}</div> : <div className="metronomeBpmStopped">{`${this.props.bpm}bpm`}</div>}
            <div style={{ marginTop: "10px" }}>{this.state.isPlaying ? <span className="metronomeStop">Stop</span> : <span className="metronomeStart">Tap to start</span>}</div>
          </Button>
        </div>
      </>
    );
  }
}
export default Metronome;

import React, { useEffect, useState } from "react";
import { ProfileCard, FormNavigator, Stepper } from "../../../components";
import { IconGraph, ReferralIcon } from "../../../assets/images";
import { Root } from "../../../stores";
import referralDetailsSchema from "./validation";
import { dataStorePageKeys } from "../../../stores/dataStore";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { differenceInYears, format } from "date-fns";
import { inject, observer } from "mobx-react";
import { Grid, Typography, Card, Button, TextField, MenuItem, FormControlLabel, FormLabel, Checkbox, Container, Backdrop, OutlinedInput, Modal, Slide } from "@material-ui/core";
import { DASHBOARD, INFORMEDCONSENT } from "../../../routes/paths";
import { useAuth } from "oidc-react";
import { useHistory } from "react-router";
import axios from "axios";
import { tokenInterceptor } from "../../../services/auth/tokenInterceptor";
import { getAppInsights } from "../../../components/telemetry";
import withAppInsights from "../AppInsights";

interface ReferralDetailsProps {
  rootStore?: Root;
}

const ReferralDetails = inject("rootStore")(
  observer((props: ReferralDetailsProps) => {
    let pefaAssessment: any;
    let prevData: any = null;
    const history = useHistory();
    const auth = useAuth();
    const appInsights = getAppInsights();
    tokenInterceptor(axios, null, auth);

    if (props.rootStore?.pefaStore.selectedPefa) {
      pefaAssessment = props.rootStore?.pefaStore.selectedPefa[0];
      if (props.rootStore?.dataStore.checkinData.hasOwnProperty(pefaAssessment.id)) {
        prevData = props.rootStore?.dataStore.getCheckInData(pefaAssessment.id, dataStorePageKeys.REFERRAL_DETAILS);
      }
    } else {
      history.push(DASHBOARD);
      return <></>;
    }

    const { register, handleSubmit, errors } = useForm({
      resolver: yupResolver(referralDetailsSchema),
    });

    const participantInfo = props.rootStore?.pefaStore.selectedPefa[0].pefArequest.worker;
    const jobInfo = props.rootStore?.pefaStore.selectedPefa[0].pefArequest.pefaJob;
    const companyInfo = props.rootStore?.pefaStore.selectedPefa[0].pefArequest.company;
    const IncludeInformedConsentInCheckin =
      (!props.rootStore?.pefaStore.isAssignedAssessor(pefaAssessment.id, props.rootStore?.userStore.getUserAssessorNumber) &&
        props.rootStore?.pefaStore.isCheckInIncludingInformedConsent(pefaAssessment.id)) || //if they are not the assessor and checkin includes informed consent, then true
      props.rootStore?.pefaStore.isAssignedAssessor(pefaAssessment.id, props.rootStore?.userStore.getUserAssessorNumber); //Otherwise if they are the assessor, then true
    const [state, setState] = useState({
      jobDetailsModalOpen: false,
      preferredName: prevData ? prevData.preferredName : participantInfo.preferredName || "",
      firstName: prevData ? prevData.firstName : participantInfo.firstName || "",
      lastName: prevData ? prevData.lastName : participantInfo.lastName || "",
      dob:
        prevData && (participantInfo.dateOfBirth != null || prevData.dob)
          ? prevData.dob
          : participantInfo.dateOfBirth != null
          ? format(new Date(participantInfo.dateOfBirth), "dd-LLL-y")
          : "",
      biologicalSex: prevData ? prevData.biologicalSex : participantInfo.gender?.toUpperCase() || "",
      phone: prevData ? prevData.phone : participantInfo.phoneNumber || "",
      postalAddress: prevData ? prevData.postalAddress : participantInfo.homeAddress || "",
      photoIDSightedDL: prevData ? prevData.photoIDSightedDL : "",
      photoIDSighted18Plus: prevData ? prevData.photoIDSighted18Plus : "",
      photoIDSightedOther: prevData ? prevData.photoIDSightedOther : "",
      photoIDSightedOtherNote: prevData ? prevData.photoIDSightedOtherNote : "",
      emergencyContactName: prevData ? prevData.emergencyContactName : "",
      emergencyContactPhone: prevData ? prevData.emergencyContactPhone : "",
      jobTitle: jobInfo.name || "",
      company: companyInfo.name || "",
      jobSite: jobInfo.site || "",
      IdDl: prevData ? prevData.IdDl : false,
      Id18Plus: prevData ? prevData.Id18Plus : false,
      IdOther: prevData ? prevData.IdOther : false,
      IdOtherValue: prevData ? prevData.IdOtherValue : "",
      participantAgeYears:
        prevData && prevData.dob != null
          ? differenceInYears(new Date(), new Date(prevData.dob))
          : pefaAssessment.pefArequest.worker.dateOfBirth != null
          ? differenceInYears(new Date(), new Date(pefaAssessment.pefArequest.worker.dateOfBirth))
          : 0,
      jobDetailsCorrect: prevData ? prevData.jobDetailsCorrect : false,
      jobDetailsIncorrect: prevData ? prevData.jobDetailsIncorrect : false,
    });

    const handleValueChange = (event: any) => {
      event.persist();
      setState((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
      }));
      if (event.target.name === "dob") {
        if (props.rootStore?.pefaStore.isDate(event.target.value)) {
          setState((prevState) => ({
            ...prevState,
            participantAgeYears: differenceInYears(new Date(), new Date(event.target.value)),
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            participantAgeYears: 0,
          }));
        }
      }
    };

    const handlePhotoIdChanged = (event: any): void => {
      event.persist();
      setState((prevState) => ({
        ...prevState,
        IdDl: false,
        Id18Plus: false,
        IdOther: false,
        [event.target.name]: event.target.checked,
      }));
    };

    const handleDetailsConfirmed = (event: any): void => {
      event.persist();
      setState((prevState) => ({
        ...prevState,
        jobDetailsCorrect: false,
        jobDetailsIncorrect: false,
        [event.target.name]: event.target.checked,
      }));
    };

    const handleOpenModal = (event: any): void => {
      event.persist();
      if (event.target.checked) {
        setState((prevState) => ({
          ...prevState,
          jobDetailsCorrect: false,
          jobDetailsIncorrect: true,
          jobDetailsModalOpen: true,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          [event.target.name]: event.target.checked,
        }));
      }
    };

    const handleCloseModal = (redirect: boolean): void => {
      if (!redirect) {
        setState((prevState) => ({
          ...prevState,
          jobDetailsCorrect: false,
          jobDetailsIncorrect: false,
          jobDetailsModalOpen: false,
        }));
      } else {
        history.push(DASHBOARD);
      }
    };

    useEffect(() => {
      const timeout = setTimeout(() => {
        props.rootStore?.dataStore.savePartialCheckInData({ ...state, checkedIn: prevData ? prevData.checkedIn : false }, pefaAssessment.id, dataStorePageKeys.REFERRAL_DETAILS);
      }, 500);

      return () => clearTimeout(timeout);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      state.preferredName,
      state.firstName,
      state.lastName,
      state.dob,
      state.biologicalSex,
      state.phone,
      state.postalAddress,
      state.photoIDSightedDL,
      state.photoIDSighted18Plus,
      state.photoIDSightedOther,
      state.photoIDSightedOtherNote,
      state.emergencyContactName,
      state.emergencyContactPhone,
      state.jobTitle,
      state.company,
      state.jobSite,
      state.participantAgeYears,
    ]);

    useEffect(() => {
      //Replicate the onUnmount event handler from class components.
      return () => {
        appInsights.trackEvent({ name: "intermediateSubmission", properties: { bookingId: pefaAssessment.id, dataKey: dataStorePageKeys.REFERRAL_DETAILS, data: state } });
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submitForm = (data: any, forwardTarget: any) => {
      props.rootStore?.dataStore.saveCheckInData({ ...data, checkedIn: prevData ? prevData.checkedIn : false }, pefaAssessment.id, dataStorePageKeys.REFERRAL_DETAILS);
      props.rootStore?.dataStore.saveData({ ...data, checkedIn: prevData ? prevData.checkedIn : false }, pefaAssessment.id, dataStorePageKeys.REFERRAL_DETAILS);
      props.rootStore?.pefaStore.submitCheckIn({ ...data, checkedIn: prevData ? prevData.checkedIn : false }, pefaAssessment.id).then(() => {
        props.rootStore?.dataStore.saveCheckInData({ ...data, checkedIn: true }, pefaAssessment.id, dataStorePageKeys.REFERRAL_DETAILS);
        const pefaData = { ...props.rootStore?.dataStore.pefaData[pefaAssessment.id] };
        // take snapshot of assessment and store against booking
        props.rootStore?.pefaStore.nextSubmit(pefaData, pefaAssessment.id);
      });
      appInsights.trackEvent({ name: "intermediateSubmission", properties: { bookingId: pefaAssessment.id, dataKey: dataStorePageKeys.REFERRAL_DETAILS, data } });

      if (!IncludeInformedConsentInCheckin) {
        //If the checkin flow doesn't include consent, before redirecting to the dashboard, flag the PEFA as complete locally.
        props.rootStore?.dataStore.setLocalCheckInCompleteFlag(pefaAssessment.id);
        history.push(DASHBOARD);
      } else {
        history.push(forwardTarget);
      }
    };

    return (
      <div className="FormStyler">
        <div className="AssessmentDetails">
          <Grid item container direction="column" xs={12}>
            <Stepper targetStep={"Assessment Details"} />
            <Container maxWidth={"lg"}>
              <Grid item container xs={12} direction="row">
                <Grid item container sm={12} md={9}>
                  <form className="flexerGrow">
                    <Card className="uCard" variant="outlined">
                      <Typography variant="h2" className="heroHeading">
                        <img src={ReferralIcon} alt="Referral Detailsl" /> Referral Details
                      </Typography>
                      <Typography className="introPefaText" variant="body1">
                        Please confirm or correct the following
                      </Typography>
                    </Card>
                    <Card className="medicalCard" variant="outlined">
                      <Typography className="medicalHistoryTitle">Participant Details</Typography>
                      <div className="flexer">
                        <FormControlLabel
                          control={<OutlinedInput color="primary" name="preferredName" autoComplete="off" className="flexerGrow" defaultValue={state.preferredName} />}
                          label="Preferred Name"
                          labelPlacement="start"
                          className="detailsFlexer"
                          name="preferredName"
                          inputRef={register}
                          onChange={handleValueChange}
                        />
                        <div style={{ marginLeft: "200px", marginTop: "15px" }} className="invalidFeedback">
                          {errors.preferredName?.message}
                        </div>
                      </div>
                      <div className="flexer">
                        <FormControlLabel
                          control={<OutlinedInput color="primary" name="firstName" autoComplete="off" className="flexerGrow" defaultValue={state.firstName} />}
                          label="First Name"
                          labelPlacement="start"
                          className="detailsFlexer"
                          name="firstName"
                          inputRef={register}
                          onChange={handleValueChange}
                        />

                        <div style={{ marginLeft: "200px", marginTop: "15px" }} className="invalidFeedback">
                          {errors.firstName?.message}
                        </div>
                      </div>
                      <div className="flexer">
                        <FormControlLabel
                          control={<OutlinedInput color="primary" name="lastName" autoComplete="off" className="flexerGrow" defaultValue={state.lastName} />}
                          label="Last Name"
                          labelPlacement="start"
                          className="detailsFlexer"
                          name="lastName"
                          inputRef={register}
                          onChange={handleValueChange}
                        />
                        <div style={{ marginLeft: "200px", marginTop: "15px" }} className="invalidFeedback">
                          {errors.lastName?.message}
                        </div>
                      </div>
                      <div className="flexer">
                        <FormControlLabel
                          control={<OutlinedInput placeholder="DD-MMM-YYYY" color="primary" name="dob" autoComplete="off" className="referral-dob" defaultValue={state.dob} />}
                          label="DOB"
                          labelPlacement="start"
                          className="detailsFlexerDOB"
                          name="dob"
                          inputRef={register}
                          onChange={handleValueChange}
                        />
                        <Typography variant="body2" style={{ marginLeft: "30px", marginTop: "30px", fontSize: "13px" }} className="referral-dob">
                          e.g 01-JUL-2000
                        </Typography>
                      </div>
                      <div className="flexer">
                        <FormControlLabel
                          control={
                            <TextField id="select" value={state.biologicalSex} className="referral-biological" variant="outlined" select>
                              <MenuItem value="MALE">Male</MenuItem>
                              <MenuItem value="FEMALE">Female</MenuItem>
                            </TextField>
                          }
                          label="Sex At Birth"
                          labelPlacement="start"
                          className="detailsFlexerDOB"
                          name="biologicalSex"
                          onChange={handleValueChange}
                        />
                        <input type="hidden" name="biologicalSex" ref={register} value={state.biologicalSex} />
                        <div style={{ marginLeft: "200px", marginTop: "15px" }} className="invalidFeedback">
                          {errors.dob?.message} {errors.biologicalSex?.message}
                        </div>
                      </div>
                      <div className="flexer">
                        <FormControlLabel
                          control={<OutlinedInput color="primary" name="postalAddress" autoComplete="off" className="flexerGrow" defaultValue={state.postalAddress} />}
                          label="Postal Address"
                          labelPlacement="start"
                          className="detailsFlexer"
                          name="postalAddress"
                          inputRef={register}
                          onChange={handleValueChange}
                        />
                        <div style={{ marginLeft: "200px", marginTop: "15px" }} className="invalidFeedback">
                          {errors.postalAddress?.message}
                        </div>
                      </div>
                      <div className="photoId">
                        <FormLabel>Photo ID Sighted</FormLabel>
                        <div className="flexer">
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={<span className="checkboxIcon"></span>}
                                checkedIcon={<span className="checkboxIconChecked"></span>}
                                color="primary"
                                name="IdDl"
                                checked={state.IdDl}
                                onChange={handlePhotoIdChanged}
                              />
                            }
                            label="DL"
                            inputRef={register}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={<span className="checkboxIcon"></span>}
                                checkedIcon={<span className="checkboxIconChecked"></span>}
                                color="primary"
                                name="Id18Plus"
                                checked={state.Id18Plus}
                                onChange={handlePhotoIdChanged}
                              />
                            }
                            label="18+"
                            inputRef={register}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={<span className="checkboxIcon"></span>}
                                checkedIcon={<span className="checkboxIconChecked"></span>}
                                color="primary"
                                name="IdOther"
                                checked={state.IdOther}
                                onChange={handlePhotoIdChanged}
                              />
                            }
                            label="Other"
                            inputRef={register}
                          />
                        </div>
                        <div className={`flexer ${!state.IdOther ? "visibilityHidden" : ""}`}>
                          <OutlinedInput
                            color="primary"
                            name="IdOtherValue"
                            autoComplete="off"
                            className={`flexerGrow ${!state.IdOther ? "visibilityHidden" : ""}`}
                            placeholder="Describe other ID"
                            defaultValue={state.IdOtherValue}
                            onChange={handleValueChange}
                            inputRef={register}
                          />
                        </div>
                      </div>
                    </Card>
                    <Card className="medicalCard" variant="outlined">
                      <Typography className="medicalHistoryTitle">Emergency Contact</Typography>
                      <div className="flexer">
                        <FormControlLabel
                          control={
                            <OutlinedInput color="primary" name="emergencyContactName" autoComplete="off" className="flexerGrow" defaultValue={state.emergencyContactName} />
                          }
                          label="Name"
                          labelPlacement="start"
                          className="detailsFlexer"
                          name="emergencyContactName"
                          inputRef={register}
                          onChange={handleValueChange}
                        />
                        <div style={{ marginLeft: "200px", marginTop: "15px" }} className="invalidFeedback">
                          {errors.emergencyContactName?.message}
                        </div>
                      </div>
                      <div className="flexer">
                        <FormControlLabel
                          control={
                            <OutlinedInput color="primary" name="emergencyContactPhone" autoComplete="off" className="flexerGrow" defaultValue={state.emergencyContactPhone} />
                          }
                          label="Phone"
                          labelPlacement="start"
                          className="detailsFlexer"
                          name="emergencyContactPhone"
                          inputRef={register}
                          onChange={handleValueChange}
                        />
                        <div style={{ marginLeft: "200px", marginTop: "15px" }} className="invalidFeedback">
                          {errors.emergencyContactPhone?.message}
                        </div>
                      </div>
                    </Card>

                    <Card className="medicalCard" variant="outlined">
                      <Typography className="medicalHistoryTitle">Job Details</Typography>
                      <div className="flexer">
                        <FormControlLabel
                          control={<OutlinedInput color="primary" name="jobTitle" autoComplete="off" className="fullWidthGrey" value={state.jobTitle} disabled={true} />}
                          label="Job Title"
                          labelPlacement="start"
                          className="detailsFlexer"
                          name="jobTitle"
                          inputRef={register}
                        />
                        <input type="hidden" name="jobTitle" value={state.jobTitle} ref={register} />
                      </div>
                      <div className="flexer">
                        <FormControlLabel
                          control={<OutlinedInput color="primary" name="company" autoComplete="off" className="fullWidthGrey" value={state.company} disabled={true} />}
                          label="Company"
                          labelPlacement="start"
                          className="detailsFlexer"
                          name="company"
                          inputRef={register}
                        />
                        <input type="hidden" name="company" value={state.company} ref={register} />
                      </div>
                      <div className="flexer">
                        <FormControlLabel
                          control={<OutlinedInput color="primary" name="jobSite" autoComplete="off" className="fullWidthGrey" value={state.jobSite} disabled={true} />}
                          label="Job Site"
                          labelPlacement="start"
                          className="detailsFlexer"
                          name="jobSite"
                          inputRef={register}
                        />
                        <input type="hidden" name="jobSite" value={state.jobSite} ref={register} />
                      </div>
                      <div className="flexerEnd">
                        <Typography className="jobDetailsCorrect">Job details are correct</Typography>
                        <FormControlLabel
                          control={
                            <Checkbox
                              icon={<span className="checkboxIcon"></span>}
                              checkedIcon={<span className="checkboxIconChecked"></span>}
                              color="primary"
                              name="jobDetailsCorrect"
                              checked={state.jobDetailsCorrect}
                              onChange={handleDetailsConfirmed}
                            />
                          }
                          label="Yes"
                          inputRef={register}
                          className="flexerCheckbox"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              icon={<span className="checkboxIcon"></span>}
                              checkedIcon={<span className="checkboxIconChecked"></span>}
                              color="primary"
                              name="jobDetailsIncorrect"
                              onChange={handleOpenModal}
                              checked={state.jobDetailsIncorrect}
                            />
                          }
                          label="No"
                          inputRef={register}
                          className="flexerCheckbox"
                        />
                      </div>
                      <div style={{ marginLeft: "200px", marginTop: "15px" }} className="invalidFeedback">
                        {errors.jobDetailsCorrect?.message}
                      </div>
                    </Card>
                  </form>
                </Grid>
                <Grid item container md={3} sm={12} className="RightCol">
                  <ProfileCard
                    firstName={pefaAssessment.pefArequest.worker.firstName}
                    lastName={pefaAssessment.pefArequest.worker.lastName}
                    participantAgeYears={state.participantAgeYears}
                  />
                </Grid>
              </Grid>
            </Container>
            <FormNavigator
              backTarget={DASHBOARD}
              forwardTarget={INFORMEDCONSENT}
              handleSubmit={handleSubmit(submitForm)}
              forwardText={IncludeInformedConsentInCheckin ? "Next" : "Check-In"}
              pushProgressOnEnd={false}
              homeText={"Cancel Check-In"}
              selectedKey=""
            />
            <Modal
              aria-labelledby="modal-title"
              open={state.jobDetailsModalOpen}
              onClose={() => handleCloseModal(false)}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Slide direction="up" in={state.jobDetailsModalOpen} mountOnEnter unmountOnExit>
                <div className="modalPopSmall">
                  <form>
                    <div className="notesModalText">
                      <img src={IconGraph} alt="Graph Icon" />
                      <Typography variant="h2" className="helpHeaderTextr">
                        Job Details Incorrect?
                      </Typography>
                      <Typography className="callJobFitText">Please Contact Referrer</Typography>

                      <Button color="primary" variant="outlined" className="retestBtn" onClick={() => handleCloseModal(false)}>
                        Close
                      </Button>
                      <Button color="primary" variant="contained" className="saveNoteBtn" onClick={() => handleCloseModal(true)}>
                        Save and Return
                      </Button>
                    </div>
                  </form>
                </div>
              </Slide>
            </Modal>
          </Grid>
        </div>
      </div>
    );
  })
);

export default withAppInsights(ReferralDetails);

export enum SOB {
  "SELECT" = "SELECT",
  "WNL" = "WNL",
  "mild" = "mild",
  "mod" = "mod",
  "severe" = "severe",
}

export enum SOBFriendly {
  "SELECT" = "SOB",
  "WNL" = "WNL",
  "mild" = "Mild SOB",
  "mod" = "Mod SOB",
  "severe" = "Severe SOB",
}

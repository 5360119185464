//User
import Login from "../views/user/Login";
import LoginCallback from "../views/user/LoginCallback";
import LogoutCallback from "../views/user/LogoutCallback";

//App
import Dashboard from "../views/app/Dashboard";
import AssessmentDetails from "../views/app/AssessmentDetails/AssessmentDetails";
import AssessmentPreview from "../views/app/AssessmentPreview";
import ReferralDetails from "../views/app/ReferralDetails/ReferralDetails";
import MedicalDetails from "../views/app/MedicalConsent/MedicalDetails";
import InformedConsent from "../views/app/MedicalConsent/InformedConsent";
import BaselineMeasurements from "../views/app/BaselineMeasurements/BaselineMeasurements";
import GeneralObservations from "../views/app/GeneralObservations/GeneralObservations";
import MskStanding from "../views/app/Musculoskeletal/mskStanding";
import MskSitting from "../views/app/Musculoskeletal/mskSitting";
import MskLying from "../views/app/Musculoskeletal/mskLying";
import PtReachForward from "../views/app/PosturalTolerances/ReachForward";
import PtStoop from "../views/app/PosturalTolerances/Stoop";
import PtSquat from "../views/app/PosturalTolerances/Squat";
import PtKneel from "../views/app/PosturalTolerances/Kneel";
import PtReachAbove from "../views/app/PosturalTolerances/ReachAbove";
import DtReachSideToSide from "../views/app/DynamicTolerances/ReachSideToSide";
import DtLadderClimbing from "../views/app/DynamicTolerances/LadderClimbing";
import DtCrawling from "../views/app/DynamicTolerances/Crawling";
import FitnessTest from "../views/app/FitnessTest/FitnessTest";
import BalanceTest from "../views/app/BalanceTest/BalanceTest";
import MhBenchToFloor from "../views/app/ManualHandling/BenchToFloor";
import MhBenchToBench from "../views/app/ManualHandling/BenchToBench";
import MhBenchToShoulder from "../views/app/ManualHandling/BenchToShoulder";
import MhBenchToAboveShoulder from "../views/app/ManualHandling/BenchToAboveShoulder";
import MhBilateralCarry from "../views/app/ManualHandling/BilateralCarry";
import MhSingleCarryLeft from "../views/app/ManualHandling/SingleCarryLeft";
import MhSingleCarryRight from "../views/app/ManualHandling/SingleCarryRight";
import MhOptionalComments from "../views/app/ManualHandling/OptionalComments";
import ProgramRecommendations from "../views/app/ProgramRecommendations/ProgramRecommendations";
import PefaFinalisation from "../views/app/PefaFinalisation/PefaFinalisation";
import PefaReview from "../views/app/PefaFinalisation/PreviewResults";
import PefaSent from "../views/app/PefaSent";
import Error404 from "../views/Error404";
import Error from "../views/Error";
import AppRating from "../views/app/PefaFinalisation/AppRating";
import FinalBPReading from "../views/app/FinalBPReading/FinalBPReading";

import {
  ASSESSMENTDETAILS,
  ASSESSMENTPREVIEW,
  BASELINEMEASUREMENTS,
  DASHBOARD,
  ERROR404,
  GENERALOBSERVATIONS,
  LOGIN,
  LOGINCALLBACK,
  LOGOUTCALLBACK,
  MEDICALDETAILS,
  INFORMEDCONSENT,
  REFERRALDETAILS,
  MSKSTANDING,
  MSKSITTING,
  MSKLYING,
  PTREACHFORWARD,
  PTREACHABOVE,
  PTSTOOP,
  PTSQUAT,
  PTKNEEL,
  DTREACHSIDETOSIDE,
  DTCRAWLING,
  DTLADDERCLIMBING,
  FITNESSTEST,
  MHBENCHTOBENCH,
  MHBENCHTOFLOOR,
  MHBENCHTOSHOULDER,
  MHBENCHTOABOVESHOULDER,
  MHBILATERALCARRY,
  MHSINGLECARRYRIGHT,
  MHSINGLECARRYLEFT,
  MHOPTIONALCOMMENTS,
  PROGRAMRECOMMENDATIONS,
  PEFAFINALISATION,
  PEFAREVIEW,
  BALANCETEST,
  PEFASENT,
  ERROR,
  APPRATING,
  FINALBPREADING,
} from "./paths";

export interface IRoute {
  component: any;
  path: string;
  isPrivate: boolean;
  routes?: IRoute[];
  exact: boolean;
}

/***********************************
 *  Auth
 ***********************************/

export const login: IRoute = {
  component: Login,
  path: LOGIN,
  isPrivate: false,
  exact: false,
};
export const loginCallback: IRoute = {
  component: LoginCallback,
  path: LOGINCALLBACK,
  isPrivate: false,
  exact: false,
};

export const logout: IRoute = {
  component: LogoutCallback,
  path: LOGOUTCALLBACK,
  isPrivate: false,
  exact: false,
};

/***********************************
 *  Check In
 ***********************************/

export const medicalDetails: IRoute = {
  component: MedicalDetails,
  path: MEDICALDETAILS,
  isPrivate: true,
  exact: true,
};

export const informedConsent: IRoute = {
  component: InformedConsent,
  path: INFORMEDCONSENT,
  isPrivate: true,
  exact: true,
};

export const referralDetails: IRoute = {
  component: ReferralDetails,
  path: REFERRALDETAILS,
  isPrivate: true,
  exact: true,
};

/***********************************
 *  General Tests
 ***********************************/

export const assessmentDetails: IRoute = {
  component: AssessmentDetails,
  path: ASSESSMENTDETAILS,
  isPrivate: true,
  exact: true,
};

export const assessmentPreview: IRoute = {
  component: AssessmentPreview,
  path: ASSESSMENTPREVIEW,
  isPrivate: true,
  exact: true,
};

export const baselineMeasurements: IRoute = {
  component: BaselineMeasurements,
  path: BASELINEMEASUREMENTS,
  isPrivate: true,
  exact: true,
};

export const generalObservations: IRoute = {
  component: GeneralObservations,
  path: GENERALOBSERVATIONS,
  isPrivate: true,
  exact: true,
};

export const fitnessTest: IRoute = {
  component: FitnessTest,
  path: FITNESSTEST,
  isPrivate: true,
  exact: true,
};

export const balanceTest: IRoute = {
  component: BalanceTest,
  path: BALANCETEST,
  isPrivate: true,
  exact: true,
};

/***********************************
 *  MSK
 ***********************************/

export const mskStanding: IRoute = {
  component: MskStanding,
  path: MSKSTANDING,
  isPrivate: true,
  exact: true,
};

export const mskSitting: IRoute = {
  component: MskSitting,
  path: MSKSITTING,
  isPrivate: true,
  exact: true,
};

export const mskLying: IRoute = {
  component: MskLying,
  path: MSKLYING,
  isPrivate: true,
  exact: true,
};

/***********************************
 *  Postural Tolerance
 ***********************************/

export const ptReachForward: IRoute = {
  component: PtReachForward,
  path: PTREACHFORWARD,
  isPrivate: true,
  exact: true,
};

export const ptReachAbove: IRoute = {
  component: PtReachAbove,
  path: PTREACHABOVE,
  isPrivate: true,
  exact: true,
};

export const ptStoop: IRoute = {
  component: PtStoop,
  path: PTSTOOP,
  isPrivate: true,
  exact: true,
};

export const ptSquat: IRoute = {
  component: PtSquat,
  path: PTSQUAT,
  isPrivate: true,
  exact: true,
};

export const ptKneel: IRoute = {
  component: PtKneel,
  path: PTKNEEL,
  isPrivate: true,
  exact: true,
};

/***********************************
 *  Dynamic Tolerance
 ***********************************/

export const dtReachSideToSide: IRoute = {
  component: DtReachSideToSide,
  path: DTREACHSIDETOSIDE,
  isPrivate: true,
  exact: true,
};

export const dtCrawling: IRoute = {
  component: DtCrawling,
  path: DTCRAWLING,
  isPrivate: true,
  exact: true,
};

export const dtLadderClimbing: IRoute = {
  component: DtLadderClimbing,
  path: DTLADDERCLIMBING,
  isPrivate: true,
  exact: true,
};

/***********************************
 *  Manual Handling
 ***********************************/

export const mhBenchToFloor: IRoute = {
  component: MhBenchToFloor,
  path: MHBENCHTOFLOOR,
  isPrivate: true,
  exact: true,
};

export const mhBenchToBench: IRoute = {
  component: MhBenchToBench,
  path: MHBENCHTOBENCH,
  isPrivate: true,
  exact: true,
};

export const mhBenchToShoulder: IRoute = {
  component: MhBenchToShoulder,
  path: MHBENCHTOSHOULDER,
  isPrivate: true,
  exact: true,
};

export const mhBenchToAboveShoulder: IRoute = {
  component: MhBenchToAboveShoulder,
  path: MHBENCHTOABOVESHOULDER,
  isPrivate: true,
  exact: true,
};

export const mhBilateralCarry: IRoute = {
  component: MhBilateralCarry,
  path: MHBILATERALCARRY,
  isPrivate: true,
  exact: true,
};

export const mhSingleCarryLeft: IRoute = {
  component: MhSingleCarryLeft,
  path: MHSINGLECARRYLEFT,
  isPrivate: true,
  exact: true,
};

export const mhSingleCarryRight: IRoute = {
  component: MhSingleCarryRight,
  path: MHSINGLECARRYRIGHT,
  isPrivate: true,
  exact: true,
};

export const mhOptionalComments: IRoute = {
  component: MhOptionalComments,
  path: MHOPTIONALCOMMENTS,
  isPrivate: true,
  exact: true,
};

/***********************************
 *  Submission
 ***********************************/

export const programRecommendations: IRoute = {
  component: ProgramRecommendations,
  path: PROGRAMRECOMMENDATIONS,
  isPrivate: true,
  exact: true,
};

export const pefaReview: IRoute = {
  component: PefaReview,
  path: PEFAREVIEW,
  isPrivate: true,
  exact: true,
};

export const pefaFinalisation: IRoute = {
  component: PefaFinalisation,
  path: PEFAFINALISATION,
  isPrivate: true,
  exact: true,
};

export const pefaSent: IRoute = {
  component: PefaSent,
  path: PEFASENT,
  isPrivate: true,
  exact: true,
};

/***********************************
 *  Miscellaneous
 ***********************************/
export const finalBPReading: IRoute = {
  component: FinalBPReading,
  path: FINALBPREADING,
  isPrivate: true,
  exact: true,
};

export const dashboard: IRoute = {
  component: Dashboard,
  path: DASHBOARD,
  isPrivate: true,
  exact: true,
};
export const appRating: IRoute = {
  component: AppRating,
  path: APPRATING,
  isPrivate: true,
  exact: true,
};
export const error = {
  component: Error,
  path: ERROR,
  isPrivate: false,
  exact: false,
};

export const error404 = {
  component: Error404,
  path: ERROR404,
  isPrivate: false,
  exact: false,
};

const routes = [
  login,
  loginCallback,
  logout,
  dashboard,
  referralDetails,
  informedConsent,
  medicalDetails,
  assessmentDetails,
  assessmentPreview,
  baselineMeasurements,
  generalObservations,
  mskStanding,
  mskSitting,
  mskLying,
  ptReachForward,
  ptStoop,
  ptSquat,
  ptKneel,
  finalBPReading,
  ptReachAbove,
  dtReachSideToSide,
  dtLadderClimbing,
  dtCrawling,
  fitnessTest,
  balanceTest,
  mhBenchToFloor,
  mhBenchToBench,
  mhBenchToShoulder,
  mhBenchToAboveShoulder,
  mhBilateralCarry,
  mhSingleCarryLeft,
  mhSingleCarryRight,
  mhOptionalComments,
  programRecommendations,
  pefaFinalisation,
  pefaReview,
  pefaSent,
  error,
  appRating,
  error404,
];

export default routes;

import { applySnapshot, types } from "mobx-state-tree";

export enum dataStorePageKeys {
  "ASSESSMENT_DATA" = "ASSESSMENT_DATA",
  "MEDICAL_DETAILS" = "MEDICAL_DETAILS",
  "REFERRAL_DETAILS" = "REFERRAL_DETAILS",
  "INFORMED_CONSENT" = "INFORMED_CONSENT",
  "BASELINE_MEASUREMENTS" = "BASELINE_MEASUREMENTS",
  "GENERAL_OBSERVATIONS" = "GENERAL_OBSERVATIONS",
  "MSK_STANDING" = "MSK_STANDING",
  "MSK_SITTING" = "MSK_SITTING",
  "MSK_LYING" = "MSK_LYING",
  "BALANCE" = "BALANCE",
  "FITNESS" = "FITNESS",
  "PT_FORWARD" = "PT_FORWARD",
  "PT_ABOVE" = "PT_ABOVE",
  "PT_STOOP" = "PT_STOOP",
  "PT_SQUAT" = "PT_SQUAT",
  "PT_KNEEL" = "PT_KNEEL",
  "DT_SIDE" = "DT_SIDE",
  "DT_CRAWL" = "DT_CRAWL",
  "DT_LADDER" = "DT_LADDER",
  "MH_BENCH_TO_FLOOR" = "MH_BENCH_TO_FLOOR",
  "MH_BENCH_TO_BENCH" = "MH_BENCH_TO_BENCH",
  "MH_BENCH_TO_ABOVE" = "MH_BENCH_TO_ABOVE",
  "MH_BENCH_TO_SHOULDER" = "MH_BENCH_TO_SHOULDER",
  "MH_BILATERAL" = "MH_BILATERAL",
  "MH_SINGLE_L" = "MH_SINGLE_L",
  "MH_SINGLE_R" = "MH_SINGLE_R",
  "MH_OPTIONAL_COMMENTS" = "MH_OPTIONAL_COMMENTS",
  "PROGRAM_RECOMMENDATIONS" = "PROGRAM_RECOMMENDATIONS",
  "FINALISATION" = "FINALISATION",
  "FINAL_BP_READING" = "FINAL_BP_READING",
  "IN_TEST_MEDICAL_CLEARANCE" = "IN_TEST_MEDICAL_CLEARANCE",
  "PREVIEW_RESULTS" = "PREVIEW_RESULTS",
  "END_ASSESSMENT" = "END_ASSESSMENT",
}

export const dataStorePageKeysFriendly: { [key: string]: string } = {
  ASSESSMENT_DATA: "Assessment Data",
  MEDICAL_DETAILS: "Medical Details",
  REFERRAL_DETAILS: "Referral Details",
  INFORMED_CONSENT: "Informed Consent",
  BASELINE_MEASUREMENTS: "Baseline Measurements",
  GENERAL_OBSERVATIONS: "General Observations",
  MSK_STANDING: "Standing",
  MSK_SITTING: "Sitting",
  MSK_LYING: "Lying",
  BALANCE: "Balance",
  FITNESS: "Fitness",
  PT_FORWARD: "Reach Forward",
  PT_ABOVE: "Reach Above Shoulder",
  PT_STOOP: "Stoop",
  PT_SQUAT: "Squat",
  PT_KNEEL: "Kneel",
  DT_SIDE: "Reach Side to Side",
  DT_CRAWL: "Crawling",
  DT_LADDER: "Ladder Climbing",
  MH_BENCH_TO_FLOOR: "Floor Lift",
  MH_BENCH_TO_BENCH: "Bench Lift",
  MH_BENCH_TO_ABOVE: "Above Shoulder Lift",
  MH_BENCH_TO_SHOULDER: "Shoulder Lift",
  MH_BILATERAL: "Bilateral Carry",
  MH_SINGLE_L: "Carry Left",
  MH_SINGLE_R: "Carry Right",
  MH_OPTIONAL_COMMENTS: "Optional Comments",
  PROGRAM_RECOMMENDATIONS: "Program Recommendations",
  FINALISATION: "Finalisation",
  FINAL_BP_READING: "Final Blood Pressure Reading",
  IN_TEST_MEDICAL_CLEARANCE: "In Test Medical Clearance",
  PREVIEW_RESULTS: "Preview Results",
  END_ASSESSMENT: "End Assessment",
};

/**
 * A loose object type. This allows us to easily store data in a key-value based fashion.
 */
interface LooseObject {
  [key: string]: any;
}

const DataStore = types
  .model("DataStore", {
    pefaData: types.frozen(),
    checkinData: types.frozen(),
    pefaNotes: types.frozen(),
    isProduction: types.boolean,
  })
  .actions((self) => {
    /**
     * Stores the given check in data against the given assessment and page key.
     * @param data the page data to be stored.
     * @param pefaID the PEFA assessmentID.
     * @param storeKey the dataStore page key. Unique to each page.
     */
    function saveCheckInData(data: any, pefaID: string, storeKey: dataStorePageKeys) {
      applySnapshot(self, {
        ...self,
        checkinData: {
          ...self.checkinData,
          [pefaID]: {
            ...self.checkinData[pefaID],
            [storeKey]: data,
          },
        },
      });
    }

    function savePartialCheckInData(data: any, pefaID: string, storeKey: dataStorePageKeys) {
      const existingData = self.checkinData[pefaID] ? self.checkinData[pefaID][storeKey] : {};

      applySnapshot(self, {
        ...self,
        checkinData: {
          ...self.checkinData,
          [pefaID]: {
            ...self.checkinData[pefaID],
            [storeKey]: { ...existingData, ...data },
          },
        },
      });
    }

    /**
     * Flags the given PEFA as being checked in. This saves us retrieving the consent location, then checking what we have stored.
     * This will be called on the page as part of the existing logic to handle what to do next based on the consent location.
     * @param pefaID The PEFA we want to flag as having completed its local check in.
     */
    function setLocalCheckInCompleteFlag(pefaID: string) {
      applySnapshot(self, {
        ...self,
        checkinData: {
          ...self.checkinData,
          [pefaID]: {
            ...self.checkinData[pefaID],
            isCheckInCompleted: true,
          },
        },
      });
    }

    /**
     * Stores the given data against the given assessment and page key.
     * @param data the page data to be stored.
     * @param pefaID the PEFA assessmentID.
     * @param storeKey the dataStore page key. Unique to each page.
     */
    function saveData(data: any, pefaID: string, storeKey: dataStorePageKeys) {
      applySnapshot(self, {
        ...self,
        pefaData: {
          ...self.pefaData,
          [pefaID]: {
            ...self.pefaData[pefaID],
            [storeKey]: data,
          },
        },
      });
    }

    function clearDataStore() {
      applySnapshot(self, {
        ...self,
        pefaData: [],
      });
    }
    function clearCheckIn() {
      applySnapshot(self, {
        ...self,
        checkinData: [],
      });
    }

    function savePartialData(data: any, pefaID: string, storeKey: dataStorePageKeys) {
      const existingData = self.pefaData[pefaID] ? self.pefaData[pefaID][storeKey] : {};

      applySnapshot(self, {
        ...self,
        pefaData: {
          ...self.pefaData,
          [pefaID]: {
            ...self.pefaData[pefaID],
            [storeKey]: { ...existingData, ...data },
          },
        },
      });
    }

    /**
     * Saves the given note, storing it against the assessment and given page.
     * @param note the note text to be stored.
     * @param pefaID the PEFA assessment ID.
     * @param storeKey the dataStore page key. Unique to each page.
     */
    function saveNote(note: string, pefaID: string, storeKey: dataStorePageKeys) {
      applySnapshot(self, {
        ...self,
        pefaNotes: {
          ...self.pefaNotes,
          [pefaID]: {
            ...self.pefaNotes[pefaID],
            [storeKey]: { note: note, timestamp: new Date().toISOString() },
          },
        },
      });
    }

    /**
     * Removes a note with the given datastore page key from the state.
     * @param pefaID the PEFA assessment ID.
     * @param storeKey the dataStore page key. Unique to each assessment page.
     */
    function removeNote(pefaID: string, storeKey: dataStorePageKeys) {
      const pefaNotes: LooseObject = {};

      //Loop through the assessment notes, building a list of notes which don't match the given notekey.
      //If a matching note exists, it is not added to the list "deleting" it.
      if (self.pefaNotes && self.pefaNotes.hasOwnProperty(pefaID)) {
        Object.keys(self.pefaNotes[pefaID]).forEach((key: any) => {
          if (key !== storeKey) {
            pefaNotes[key] = self.pefaNotes[pefaID][key];
          }
        });
      }

      //If there is at least one note, store the notes inside the assessment's note object.
      if (Object.keys(self.pefaNotes[pefaID]).length > 0) {
        applySnapshot(self, {
          ...self,
          pefaNotes: {
            ...self.pefaNotes,
            [pefaID]: {
              ...pefaNotes,
            },
          },
        });
      } else {
        //Otherwise, we want to override the notes object so that it is empty.
        applySnapshot(self, {
          ...self,
          pefaNotes: {
            ...self.pefaNotes,
            [pefaID]: {},
          },
        });
      }
    }

    /**
     * Clears the notTestedReason and notTestedNotes from the datastore for the given page key.
     * @param pefaID the PEFA assessment ID.
     * @param storeKey the dataStore page key. Unique to each assessment page.
     */
    function removeNotTestedDetails(pefaID: string, storeKey: dataStorePageKeys) {
      const existingData = self.pefaData[pefaID] ? self.pefaData[pefaID][storeKey] : {};

      applySnapshot(self, {
        ...self,
        pefaData: {
          ...self.pefaData,
          [pefaID]: {
            ...self.pefaData[pefaID],
            [storeKey]: { ...existingData, notTestedReason: "", notTestedNotes: "" },
          },
        },
      });
    }

    return {
      saveCheckInData,
      savePartialCheckInData,
      setLocalCheckInCompleteFlag,
      saveData,
      savePartialData,
      saveNote,
      removeNote,
      removeNotTestedDetails,
      clearDataStore,
      clearCheckIn,
    };
  })
  .views((self) => {
    /**
     * retrieves the stored checkin data for the given assessment and page.
     * @param pefaID the PEFA assessment ID.
     * @param storeKey the dataStore page key. Unique to each assessment page.
     */
    function getCheckInData(pefaID: string, storeKey: dataStorePageKeys) {
      // there is a chance an admin has checked this person in on another device
      return self.checkinData[pefaID] ? self.checkinData[pefaID][storeKey] : null;
    }

    /**
     * retrieves the stored PEFA data for the given assessment and page.
     * @param pefaID the PEFA assessment ID.
     * @param storeKey the dataStore page key. Unique to each assessment page.
     */
    function getData(pefaID: string, storeKey: dataStorePageKeys) {
      return self.pefaData[pefaID] && self.pefaData[pefaID].hasOwnProperty(storeKey) ? self.pefaData[pefaID][storeKey] : null;
    }

    function dataExists(pefaID: string, storeKey: dataStorePageKeys) {
      return self.pefaData[pefaID] && self.pefaData[pefaID].hasOwnProperty(storeKey);
    }

    function checkedIn(pefaID: string) {
      return self.checkinData[pefaID] && self.checkinData[pefaID].hasOwnProperty("isCheckInCompleted") && self.checkinData[pefaID]["isCheckInCompleted"] === true;
    }

    /**
     * Returns the note for the given assessment and page. If no matching note exists, returns an empty object.
     * @param pefaID the pefa assessment ID.
     * @param storeKey the dataStore page key. Unique to each assessment page.
     */
    function getNote(pefaID: string, storeKey: dataStorePageKeys) {
      if (self.pefaNotes && self.pefaNotes.hasOwnProperty(pefaID)) {
        return self.pefaNotes[pefaID][storeKey];
      }
      return {};
    }

    /**
     * Returns a list of all notes for the given assessment.
     * @param pefaID the PEFA assessment ID.
     */
    function getNotes(pefaID: string) {
      const notesList: LooseObject = {};

      if (self.pefaNotes && self.pefaNotes.hasOwnProperty(pefaID)) {
        Object.keys(self.pefaNotes[pefaID]).forEach((key: any) => {
          notesList[key] = self.pefaNotes[pefaID][key].note;
        });
      }
      return { ...notesList };
    }
    /**
     * Returns a list of note keys for the given assessment.
     * @param pefaID the pefa assessment ID.
     */
    function getNoteKeys(pefaID: string) {
      const keysList: string[] = [];

      if (self.pefaNotes && self.pefaNotes.hasOwnProperty(pefaID)) {
        Object.keys(self.pefaNotes[pefaID]).forEach((key: string) => {
          keysList.push(key);
        });
      }
      return keysList;
    }

    /**
     * Returns a count of the number of notes for the given assessment.
     * @param pefaID the pefa assessment ID.
     */
    function getNotesCount(pefaID: string) {
      if (self.pefaNotes && self.pefaNotes.hasOwnProperty(pefaID)) {
        return Object.keys(self.pefaNotes[pefaID]).length;
      }
      return 0;
    }

    /**
     * Returns a boolean flag indicating if an extra blood pressure test is required.
     * @param pefaID the pefa assessment ID.
     */
    function isExtraPressureTestRequired(pefaID: string) {
      //Check if the pefadata has any records for this assessment.
      if (self.pefaData && self.pefaData.hasOwnProperty(pefaID)) {
        //check if a further test is required by the baseline results.
        const baselineExtraRequiredFlag = self.pefaData[pefaID][dataStorePageKeys.BASELINE_MEASUREMENTS]?.furtherTestRequired;

        //If we have a valid fitness test dataset, check for its value and return it.
        if (self.pefaData[pefaID].hasOwnProperty(dataStorePageKeys.FITNESS)) {
          return self.pefaData[pefaID][dataStorePageKeys.FITNESS]?.furtherTestRequired;
        } else {
          //Otherwise we want to use our baseline test value, as we haven't yet completed a fitness test.
          return baselineExtraRequiredFlag;
        }
      }

      //Fail safe and return a falsey. Chances are we are probably going back to the dashboard at this point anyway.
      return false;
    }
    function getPressureReadings(pefaID: string): string {
      const bloodPressures: string[] = [];
      let bloodPressureStringified = "";

      //Check if the pefadata has any records for this assessment.
      if (self.pefaData && self.pefaData.hasOwnProperty(pefaID)) {
        if (self.pefaData[pefaID][dataStorePageKeys.BASELINE_MEASUREMENTS]?.furtherTestRequired) {
          bloodPressures.push(
            `${self.pefaData[pefaID][dataStorePageKeys.BASELINE_MEASUREMENTS].bloodPressureSystolic}/${
              self.pefaData[pefaID][dataStorePageKeys.BASELINE_MEASUREMENTS].bloodPressureDiastolic
            }`
          );
          bloodPressures.push(
            `${self.pefaData[pefaID][dataStorePageKeys.BASELINE_MEASUREMENTS].bloodPressureSystolic2}/${
              self.pefaData[pefaID][dataStorePageKeys.BASELINE_MEASUREMENTS].bloodPressureDiastolic2
            }`
          );
        }

        if (self.pefaData[pefaID][dataStorePageKeys.FITNESS]?.furtherTestRequired) {
          bloodPressures.push(
            `${self.pefaData[pefaID][dataStorePageKeys.FITNESS].bloodPressureSystolic}/${self.pefaData[pefaID][dataStorePageKeys.FITNESS].bloodPressureDiastolic}`
          );
        }

        bloodPressureStringified = bloodPressures.join(", ");
      }

      return bloodPressureStringified;
    }

    function getMedicalClearanceDetails(pefaID: string) {
      if (self.pefaData[pefaID][dataStorePageKeys.MEDICAL_DETAILS]) {
        return {
          medicalPractitioner: self.pefaData[pefaID][dataStorePageKeys.MEDICAL_DETAILS].medicalPractitioner || "",
          medicalPractice: self.pefaData[pefaID][dataStorePageKeys.MEDICAL_DETAILS].medicalPractice || "",
        };
      }
      return;
    }
    /**
     * Returns a boolean flag indicating if an extra blood pressure test is required.
     * @param pefaID the pefa assessment ID.
     */
    function isExtraPressureTestRequiredMH(pefaID: string) {
      //Check if the pefadata has any records for this assessment.
      if (self.pefaData && self.pefaData.hasOwnProperty(pefaID)) {
        //check if a further test is required by the baseline results.
        const baselineExtraRequiredFlag = self.pefaData[pefaID][dataStorePageKeys.BASELINE_MEASUREMENTS]?.furtherTestRequired;
        const Ladder = self.pefaData[pefaID][dataStorePageKeys.FITNESS];
        //If we have a valid fitness test dataset, check for its value and return it.
        if (self.pefaData[pefaID].hasOwnProperty(dataStorePageKeys.FITNESS) && Ladder) {
          return self.pefaData[pefaID][dataStorePageKeys.FITNESS]?.furtherTestRequired;
        } else {
          return baselineExtraRequiredFlag;
          //Otherwise we want to use our baseline test value, as we haven't yet completed a fitness test.
        }
      }
      //Fail safe and return a falsey. Chances are we are probably going back to the dashboard at this point anyway.
      return false;
    }
    return {
      getCheckInData,
      getData,
      checkedIn,
      dataExists,
      getNote,
      getNotes,
      getNotesCount,
      getNoteKeys,
      isExtraPressureTestRequired,
      getPressureReadings,
      getMedicalClearanceDetails,
      isExtraPressureTestRequiredMH,
    };
  });

export { DataStore };

import React, { ChangeEvent, Component, ReactNode } from "react";
import { Backdrop, Button, FormControlLabel, FormGroup, FormLabel, Grid, InputAdornment, Modal, OutlinedInput, Slide, Typography } from "@material-ui/core";
import { Root } from "../../stores";
import { inject, observer } from "mobx-react";
import apiService from "../../services/ApiService";

interface ModalProps {
  isOpen: boolean;
  rootStore?: Root;
  handleCloseModalPressure: any;
  handleCloseModalClearance?: any;
  bpDia: string;
  bpSys: string;
  pefaId: string;
  bloodPressureSystolic?: string;
  bloodPressureDiastolic?: string;
  checkMedicalClearance?: boolean;
  isMedicalClearanceRequired?: boolean;
  bloodPressures?: string;
  bookingId?: number;
  participantName?: string;
  medicalPractitioner?: string;
  medicalPractice?: string;
}

interface ModalState {
  systolicPressure: string;
  diastolicPressure: string;
  invalid: boolean;
  clearanceGenerated: boolean;
  bpDia: "";
  bpSys: "";
  pefaID: "";
  medicalClearance: boolean;
  secondReadingRequired: boolean;
  clearanceRequired: boolean;
  lastModifiedId: "";
}
@inject("rootStore")
@observer
export default class HighBloodPressureModal extends Component<ModalProps, ModalState> {
  constructor(props: ModalProps) {
    super(props);

    this.state = {
      systolicPressure: "",
      diastolicPressure: "",
      invalid: false,
      pefaID: props.rootStore?.pefaStore.selectedPefa[0].id,
      bpDia: props.rootStore?.pefaStore.selectedPefa[0].pefArequest.company.bpDia || 145,
      bpSys: props.rootStore?.pefaStore.selectedPefa[0].pefArequest.company.bpSys || 95,
      medicalClearance: false,
      secondReadingRequired: false,
      clearanceRequired: false,
      lastModifiedId: "",
      clearanceGenerated: true,
    };
    this.handleClearanceDocumentDownload = this.handleClearanceDocumentDownload.bind(this);
    this.handlePressureChange = this.handlePressureChange.bind(this);
    this.handleBloodPressureClose = this.handleBloodPressureClose.bind(this);
  }

  handlePressureChange(event: ChangeEvent<any>): void {
    event.persist();
    /*const { bpDia, bpSys } = this.state;
    if (this.props.rootStore?.pefaStore.selectedPefa) {
      const id = this.props.rootStore?.pefaStore.selectedPefa[this.props.pefaId];
    }*/
    let { systolicPressure, diastolicPressure } = this.state;
    if (event.target.name === "systolicPressure") {
      systolicPressure = event.target.value;
    } else if (event.target.name === "diastolicPressure") {
      diastolicPressure = event.target.value;
    }
    this.setState((prevState) => ({
      ...prevState,
      systolicPressure,
      diastolicPressure,
    }));
  }

  async handleClearanceDocumentDownload(): Promise<void> {
    const clearances = { "Blood Pressure": `${this.props.bloodPressures}, ${this.state.systolicPressure}/${this.state.diastolicPressure}` };
    if (this.props.bookingId) {
      await apiService.getEndPEFAMedicalClearanceDocument(
        this.props.bookingId,
        `MedicalClearance - ${this.props.participantName}.docx`,
        clearances,
        this.props.medicalPractitioner || "",
        this.props.medicalPractice || ""
      );
      this.setState((prevState) => ({ ...prevState, clearanceGenerated: false }));
    }
  }
  handleBloodPressureClose(): void {
    const { systolicPressure, diastolicPressure, bpSys, bpDia } = this.state;
    //Check if any further tests are required due to elevated systolic or diastolic blood pressure readings.
    let furtherTestRequired = false;
    let clearanceRequired = false;
    if (Number(systolicPressure) > Number(bpDia) || Number(diastolicPressure) > Number(bpSys)) {
      furtherTestRequired = true;
      if (this.props.isMedicalClearanceRequired) {
        clearanceRequired = true;
      }
    }

    if (!clearanceRequired) {
      if (!!systolicPressure && !!diastolicPressure) {
        this.props.handleCloseModalPressure(systolicPressure, diastolicPressure, furtherTestRequired);
      } else {
        this.setState((prevState) => ({ ...prevState, invalid: true }));
      }
    } else {
      this.setState((prevState) => ({ ...prevState, clearanceRequired }));
    }
  }

  handleMedicalClearanceCheck(clearance: boolean): void {
    this.setState((prevState) => ({
      ...prevState,
      medicalClearance: clearance,
      secondReadingRequired: !clearance,
    }));

    if (!!this.props.handleCloseModalClearance && clearance) {
      this.props.handleCloseModalClearance();
    }
  }

  handleMedicalClearanceRequired(): void {
    if (!!this.props.handleCloseModalClearance) {
      this.props.handleCloseModalClearance(this.state.systolicPressure, this.state.diastolicPressure);
    }
  }

  render(): ReactNode {
    return (
      <Modal
        aria-labelledby="modal-title"
        open={this.props.isOpen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Slide direction="up" in={this.props.isOpen} mountOnEnter unmountOnExit>
          <div className="modalPop">
            <div className="hbpModalText">
              {this.props.checkMedicalClearance && !this.state.secondReadingRequired ? (
                <>
                  <Typography variant="h2" className="modalHeader noWrap">
                    Medical Clearance Required
                  </Typography>
                  <Typography variant="body2" className="modalText">
                    Have you seen a signed JobFit System Clearance for High Blood Pressure?
                  </Typography>
                  <Grid xs={11} item container>
                    <Grid item xs={5}>
                      <Button color="primary" variant="contained" fullWidth onClick={() => this.handleMedicalClearanceCheck(false)}>
                        {/*<Button color="primary" variant="contained" fullWidth onClick={() => this.handleClearanceDocumentDownload()}>8/}
                        {/*</Button><Button color="primary" variant="contained" fullWidth onClick={() => this.handleMedicalClearanceCheck(false)}>*/}
                        No
                      </Button>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={5}>
                      <Button color="primary" variant="contained" fullWidth onClick={() => this.handleMedicalClearanceCheck(true)}>
                        Yes
                      </Button>
                    </Grid>
                  </Grid>
                </>
              ) : this.state.clearanceRequired ? (
                <>
                  <Typography variant="h2" className="modalHeader noWrap">
                    Medical Clearance Required
                  </Typography>
                  <Typography variant="body2" className="modalText">
                    The blood pressure reading is still too high.  Please download a Medical Clearance Letter and follow the instructions.  The participant's information will be in
                    there for you.
                  </Typography>
                  <div>
                    <FormGroup className="formGroupOveride">
                      <Button color="primary" variant="contained" fullWidth onClick={() => this.handleClearanceDocumentDownload()}>
                        Prepare Medical Clearance Letter
                      </Button>
                    </FormGroup>
                    <FormGroup className="formGroupOveride">
                      <Button disabled={this.state.clearanceGenerated} color="primary" variant="contained" fullWidth onClick={() => this.handleMedicalClearanceRequired()}>
                        Preview Results
                      </Button>
                    </FormGroup>
                  </div>
                </>
              ) : (
                <>
                  {/* Check if the values have been provided and only show this div if they have. This makes this modal more generic. Yay! */}
                  {this.props.bloodPressureSystolic && this.props.bloodPressureDiastolic ? (
                    <>
                      <Typography variant="h2" className="modalHeader">
                        High Blood Pressure
                      </Typography>
                      <Typography variant="body2" className="modalText">
                        The participant's blood pressure is higher than the safety cutoff of {this.props.bpDia}/{this.props.bpSys} mmHg. Please check the cuff size and give them a
                        moment to relax and retest
                      </Typography>
                      <div className="spacer">
                        <span className="prevResult">Prev result</span>
                        <span className="actualResult">
                          {this.props.bloodPressureSystolic}/{this.props.bloodPressureDiastolic} mmHg
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <Typography variant="h2" className="modalHeader noWrap">
                        Re-check Blood Pressure
                      </Typography>
                      <Typography variant="body2" className="modalText">
                        The previous blood pressure readings were too high. Please check them again.
                      </Typography>
                    </>
                  )}
                  <FormGroup className="formGroupOveride">
                    <Grid xs={12} item container className="StrengthTestContainer">
                      <div>
                        <FormGroup className="formGroupOveride">
                          <div className="flexer">
                            <FormLabel className="bpLeft" component="legend">
                              Blood pressure <br />
                              sitting
                            </FormLabel>
                            <FormControlLabel
                              control={
                                <OutlinedInput
                                  color="primary"
                                  className="textInputJamar"
                                  autoComplete="off"
                                  endAdornment={<InputAdornment position="end">mmHg</InputAdornment>}
                                  value={this.state.systolicPressure}
                                  onChange={this.handlePressureChange}
                                />
                              }
                              label="&nbsp;"
                              labelPlacement="top"
                              name="systolicPressure"
                            />
                            <FormControlLabel
                              control={
                                <OutlinedInput
                                  color="primary"
                                  className="textInputJamar"
                                  autoComplete="off"
                                  endAdornment={<InputAdornment position="end">mmHg</InputAdornment>}
                                  value={this.state.diastolicPressure}
                                  onChange={this.handlePressureChange}
                                />
                              }
                              label="&nbsp;"
                              labelPlacement="top"
                              name="diastolicPressure"
                            />
                          </div>
                          {this.state.invalid ? (
                            <div className="invalid-feedback textInputMarginLeft">
                              <span>Please enter a second blood pressure reading.</span>
                            </div>
                          ) : (
                            <></>
                          )}
                        </FormGroup>
                      </div>
                    </Grid>
                  </FormGroup>
                  <Button color="primary" variant="contained" fullWidth onClick={this.handleBloodPressureClose}>
                    Enter result
                  </Button>
                </>
              )}
            </div>
          </div>
        </Slide>
      </Modal>
    );
  }
}

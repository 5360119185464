import assessmentDetails from "./assessmentDetails";
import baselineMeasurements from "./baselineMeasurements";
import generalObservations from "./generalObservations";
import musculoskeletal from "./musculoskeletal";
import balanceResults from "./balanceResults";
import fitnessResult from "./fitnessResult";
import toleranceResults from "./toleranceResults";
import handlingResults from "./handlingResults";
import recommendations from "./recommendations";
import noteComments from "./noteComments";
import medicalDetails from "./medicalDetails";

export const MedicalDetails = medicalDetails;
export const AssessmentDetails = assessmentDetails;
export const BaselineMeasurements = baselineMeasurements;
export const GeneralObservations = generalObservations;
export const Musculoskeletal = musculoskeletal;
export const BalanceResults = balanceResults;
export const FitnessResult = fitnessResult;
export const ToleranceResults = toleranceResults;
export const HandlingResults = handlingResults;
export const Recommendations = recommendations;
export const NoteComments = noteComments;

export interface keyPairData {
  id?: number;
  [key: string]: any;
}

export const resultTimeKeys = [
  { key: "030", value: "30" },
  { key: "100", value: "60" },
  { key: "130", value: "90" },
  { key: "200", value: "120" },
  { key: "230", value: "150" },
  { key: "300", value: "180" },
];

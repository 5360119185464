import * as yup from "yup";
import { limitingFactors } from "../../../components/groupedComponents/MobilityTestDataKeys";
import { strengthDiscomfortFactors } from "../../../components/groupedComponents/StrengthTestDataKeys";

const musculoskeletalValidation = yup.object().shape({
  ankleMobility: yup.bool().test("ankleMobility", "Please provide feedback on ankle mobility.", function () {
    let valid = false;

    if (
      !valid &&
      !this.parent.ankleMobilityPainFree &&
      (!this.parent.leftAnkleDorsiflexion_unable ||
        !this.parent.leftAnkleDorsiflexion_lessThanOneThird ||
        !this.parent.leftAnkleDorsiflexion_lessThanTwoThirds ||
        !this.parent.leftAnkleDorsiflexion_greaterThanTwoThirds) &&
      !this.parent.leftAnkleDorsiflexion_objectiveRange &&
      this.parent.leftAnkleDorsiflexion_limitingFactor === "SELECT" &&
      !this.parent.leftAnkleDorsiflexion_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.ankleMobilityPainFree &&
      (!this.parent.leftAnklePlantarFlexion_unable ||
        !this.parent.leftAnklePlantarFlexion_lessThanOneThird ||
        !this.parent.leftAnklePlantarFlexion_lessThanTwoThirds ||
        !this.parent.leftAnklePlantarFlexion_greaterThanTwoThirds) &&
      !this.parent.leftAnklePlantarFlexion_objectiveRange &&
      this.parent.leftAnklePlantarFlexion_limitingFactor === "SELECT" &&
      !this.parent.leftAnklePlantarFlexion_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.ankleMobilityPainFree &&
      (!this.parent.leftAnkleInversion_unable ||
        !this.parent.leftAnkleInversion_lessThanOneThird ||
        !this.parent.leftAnkleInversion_lessThanTwoThirds ||
        !this.parent.leftAnkleInversion_greaterThanTwoThirds) &&
      !this.parent.leftAnkleInversion_objectiveRange &&
      this.parent.leftAnkleInversion_limitingFactor === "SELECT" &&
      !this.parent.leftAnkleInversion_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.ankleMobilityPainFree &&
      (!this.parent.leftAnkleEversion_unable ||
        !this.parent.leftAnkleEversion_lessThanOneThird ||
        !this.parent.leftAnkleEversion_lessThanTwoThirds ||
        !this.parent.leftAnkleEversion_greaterThanTwoThirds) &&
      !this.parent.leftAnkleEversion_objectiveRange &&
      this.parent.leftAnkleEversion_limitingFactor === "SELECT" &&
      !this.parent.leftAnkleEversion_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.ankleMobilityPainFree &&
      (!this.parent.rightAnkleDorsiflexion_unable ||
        !this.parent.rightAnkleDorsiflexion_lessThanOneThird ||
        !this.parent.rightAnkleDorsiflexion_lessThanTwoThirds ||
        !this.parent.rightAnkleDorsiflexion_greaterThanTwoThirds) &&
      !this.parent.rightAnkleDorsiflexion_objectiveRange &&
      this.parent.rightAnkleDorsiflexion_limitingFactor === "SELECT" &&
      !this.parent.rightAnkleDorsiflexion_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.ankleMobilityPainFree &&
      (!this.parent.rightAnklePlantarFlexion_unable ||
        !this.parent.rightAnklePlantarFlexion_lessThanOneThird ||
        !this.parent.rightAnklePlantarFlexion_lessThanTwoThirds ||
        !this.parent.rightAnklePlantarFlexion_greaterThanTwoThirds) &&
      !this.parent.rightAnklePlantarFlexion_objectiveRange &&
      this.parent.rightAnklePlantarFlexion_limitingFactor === "SELECT" &&
      !this.parent.rightAnklePlantarFlexion_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.ankleMobilityPainFree &&
      (!this.parent.rightAnkleInversion_unable ||
        !this.parent.rightAnkleInversion_lessThanOneThird ||
        !this.parent.rightAnkleInversion_lessThanTwoThirds ||
        !this.parent.rightAnkleInversion_greaterThanTwoThirds) &&
      !this.parent.rightAnkleInversion_objectiveRange &&
      this.parent.rightAnkleInversion_limitingFactor === "SELECT" &&
      !this.parent.rightAnkleInversion_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.ankleMobilityPainFree &&
      (!this.parent.rightAnkleEversion_unable ||
        !this.parent.rightAnkleEversion_lessThanOneThird ||
        !this.parent.rightAnkleEversion_lessThanTwoThirds ||
        !this.parent.rightAnkleEversion_greaterThanTwoThirds) &&
      !this.parent.rightAnkleEversion_objectiveRange &&
      this.parent.rightAnkleEversion_limitingFactor === "SELECT" &&
      !this.parent.rightAnkleEversion_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    return valid;
  }),
  hipMobility: yup.bool().test("hipMobility", "Please provide feedback on hip mobility.", function () {
    let valid = false;

    if (
      !valid &&
      !this.parent.hipMobilityPainFree &&
      (!this.parent.leftHipFlexion_unable ||
        !this.parent.leftHipFlexion_lessThanOneThird ||
        !this.parent.leftHipFlexion_lessThanTwoThirds ||
        !this.parent.leftHipFlexion_greaterThanTwoThirds) &&
      !this.parent.leftHipFlexion_objectiveRange &&
      this.parent.leftHipFlexion_limitingFactor === "SELECT" &&
      !this.parent.leftHipFlexion_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.hipMobilityPainFree &&
      (!this.parent.leftHipExternalRotation_unable ||
        !this.parent.leftHipExternalRotation_lessThanOneThird ||
        !this.parent.leftHipExternalRotation_lessThanTwoThirds ||
        !this.parent.leftHipExternalRotation_greaterThanTwoThirds) &&
      !this.parent.leftHipExternalRotation_objectiveRange &&
      this.parent.leftHipExternalRotation_limitingFactor === "SELECT" &&
      !this.parent.leftHipExternalRotation_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.hipMobilityPainFree &&
      (!this.parent.leftKneeFlexion_unable ||
        !this.parent.leftKneeFlexion_lessThanOneThird ||
        !this.parent.leftKneeFlexion_lessThanTwoThirds ||
        !this.parent.leftKneeFlexion_greaterThanTwoThirds) &&
      !this.parent.leftKneeFlexion_objectiveRange &&
      this.parent.leftKneeFlexion_limitingFactor === "SELECT" &&
      !this.parent.leftKneeFlexion_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.hipMobilityPainFree &&
      (!this.parent.leftKneeExtension_unable ||
        !this.parent.leftKneeExtension_lessThanOneThird ||
        !this.parent.leftKneeExtension_lessThanTwoThirds ||
        !this.parent.leftKneeExtension_greaterThanTwoThirds) &&
      !this.parent.leftKneeExtension_objectiveRange &&
      this.parent.leftKneeExtension_limitingFactor === "SELECT" &&
      !this.parent.leftKneeExtension_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.hipMobilityPainFree &&
      (!this.parent.rightHipFlexion_unable ||
        !this.parent.rightHipFlexion_lessThanOneThird ||
        !this.parent.rightHipFlexion_lessThanTwoThirds ||
        !this.parent.rightHipFlexion_greaterThanTwoThirds) &&
      !this.parent.rightHipFlexion_objectiveRange &&
      this.parent.rightHipFlexion_limitingFactor === "SELECT" &&
      !this.parent.rightHipFlexion_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.hipMobilityPainFree &&
      (!this.parent.rightHipExternalRotation_unable ||
        !this.parent.rightHipExternalRotation_lessThanOneThird ||
        !this.parent.rightHipExternalRotation_lessThanTwoThirds ||
        !this.parent.rightHipExternalRotation_greaterThanTwoThirds) &&
      !this.parent.rightHipExternalRotation_objectiveRange &&
      this.parent.rightHipExternalRotation_limitingFactor === "SELECT" &&
      !this.parent.rightHipExternalRotation_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.hipMobilityPainFree &&
      (!this.parent.rightKneeFlexion_unable ||
        !this.parent.rightKneeFlexion_lessThanOneThird ||
        !this.parent.rightKneeFlexion_lessThanTwoThirds ||
        !this.parent.rightKneeFlexion_greaterThanTwoThirds) &&
      !this.parent.rightKneeFlexion_objectiveRange &&
      this.parent.rightKneeFlexion_limitingFactor === "SELECT" &&
      !this.parent.rightKneeFlexion_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.hipMobilityPainFree &&
      (!this.parent.rightKneeExtension_unable ||
        !this.parent.rightKneeExtension_lessThanOneThird ||
        !this.parent.rightKneeExtension_lessThanTwoThirds ||
        !this.parent.rightKneeExtension_greaterThanTwoThirds) &&
      !this.parent.rightKneeExtension_objectiveRange &&
      this.parent.rightKneeExtension_limitingFactor === "SELECT" &&
      !this.parent.rightKneeExtension_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    return valid;
  }),
  ankleStrength: yup.bool().test("ankleStrength", "Please provide feedback on ankle strength.", function () {
    let valid = false;

    if (!valid && !this.parent.ankleStrengthPainFree && this.parent.leftAnkleStrengthDorsiflexion_discomfort === strengthDiscomfortFactors.SELECT) {
      valid = false;
    } else valid = true;

    if (!valid && !this.parent.ankleStrengthPainFree && this.parent.leftAnkleStrengthPlantarflexion_discomfort === strengthDiscomfortFactors.SELECT) {
      valid = false;
    } else valid = true;

    if (!valid && !this.parent.ankleStrengthPainFree && this.parent.leftAnkleStrengthInversion_discomfort === strengthDiscomfortFactors.SELECT) {
      valid = false;
    } else valid = true;

    if (!valid && !this.parent.ankleStrengthPainFree && this.parent.leftAnkleStrengthEversion_discomfort === strengthDiscomfortFactors.SELECT) {
      valid = false;
    } else valid = true;

    if (!valid && !this.parent.ankleStrengthPainFree && this.parent.leftToeExtension_discomfort === strengthDiscomfortFactors.SELECT) {
      valid = false;
    } else valid = true;

    if (!valid && !this.parent.ankleStrengthPainFree && this.parent.rightAnkleStrengthDorsiflexion_discomfort === strengthDiscomfortFactors.SELECT) {
      valid = false;
    } else valid = true;

    if (!valid && !this.parent.ankleStrengthPainFree && this.parent.rightAnkleStrengthPlantarflexion_discomfort === strengthDiscomfortFactors.SELECT) {
      return false;
    }

    if (!valid && !this.parent.ankleStrengthPainFree && this.parent.rightAnkleStrengthInversion_discomfort === strengthDiscomfortFactors.SELECT) {
      valid = false;
    } else valid = true;

    if (!valid && !this.parent.ankleStrengthPainFree && this.parent.rightAnkleStrengthEversion_discomfort === strengthDiscomfortFactors.SELECT) {
      valid = false;
    } else valid = true;

    if (!valid && !this.parent.ankleStrengthPainFree && this.parent.rightToeExtension_discomfort === strengthDiscomfortFactors.SELECT) {
      valid = false;
    } else valid = true;

    return valid;
  }),
  trunkStrength: yup.bool().test("trunkStrength", "Please provide feedback on trunk strength.", function () {
    let valid = false;

    if (!valid && !this.parent.trunkStrengthPainFree && this.parent.trunkStrengthExtensors_discomfort === strengthDiscomfortFactors.SELECT) {
      valid = false;
    } else valid = true;

    if (!valid && !this.parent.trunkStrengthPainFree && this.parent.trunkStrengthAbdominals_discomfort === strengthDiscomfortFactors.SELECT) {
      valid = false;
    } else valid = true;
    return valid;
  }),
  leftAnkleDorsiflexion: yup.bool().test("leftAnkleDorsiflexion", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      ankleMobilityPainFree,
      leftAnkleDorsiflexion_unable,
      leftAnkleDorsiflexion_lessThanOneThird,
      leftAnkleDorsiflexion_lessThanTwoThirds,
      leftAnkleDorsiflexion_greaterThanTwoThirds,
      leftAnkleDorsiflexion_objectiveRange,
      leftAnkleDorsiflexion_limitingFactor,
      leftAnkleDorsiflexion_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (ankleMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (leftAnkleDorsiflexion_unable || leftAnkleDorsiflexion_lessThanOneThird || leftAnkleDorsiflexion_lessThanTwoThirds || leftAnkleDorsiflexion_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!leftAnkleDorsiflexion_objectiveRange ||
        //or the limiting factor has not been selected,c
        leftAnkleDorsiflexion_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((leftAnkleDorsiflexion_limitingFactor === "MUSCLETIGHTNESS" || leftAnkleDorsiflexion_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !leftAnkleDorsiflexion_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are set
      (!!leftAnkleDorsiflexion_objectiveRange || leftAnkleDorsiflexion_limitingFactor !== "SELECT" || !!leftAnkleDorsiflexion_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !leftAnkleDorsiflexion_unable &&
      !leftAnkleDorsiflexion_lessThanOneThird &&
      !leftAnkleDorsiflexion_lessThanTwoThirds &&
      !leftAnkleDorsiflexion_greaterThanTwoThirds
    ) {
      //then the leftAnkleDorsiflexion fields are not valid.
      return false;
    }

    return true;
  }),
  leftAnkleDorsiflexion_location: yup.string().test("leftAnkleDorsiflexion_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.leftAnkleDorsiflexion_limitingFactor === limitingFactors.PAIN && this.parent.leftAnkleDorsiflexion_location === "") {
      return false;
    }

    return true;
  }),

  leftAnkleDorsiflexion_intensity: yup.bool().test("leftAnkleDorsiflexion_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.leftAnkleDorsiflexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftAnkleDorsiflexion_intensityVas === "" &&
      this.parent.leftAnkleDorsiflexion_intensityMild === false &&
      this.parent.leftAnkleDorsiflexion_intensityMod === false &&
      this.parent.leftAnkleDorsiflexion_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  leftAnkleDorsiflexion_type: yup.bool().test("leftAnkleDorsiflexion_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.leftAnkleDorsiflexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftAnkleDorsiflexion_discomfortTypeAche === false &&
      this.parent.leftAnkleDorsiflexion_discomfortTypeSharp === false &&
      this.parent.leftAnkleDorsiflexion_discomfortTypeBurning === false &&
      this.parent.leftAnkleDorsiflexion_discomfortTypeTightness === false &&
      this.parent.leftAnkleDorsiflexion_discomfortTypePulling === false &&
      this.parent.leftAnkleDorsiflexion_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  leftAnkleDorsiflexion_typeOther: yup.string().test("leftAnkleDorsiflexion_typeOther", "Please describe the discomfort further.", function () {
    if (
      this.parent.leftAnkleDorsiflexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftAnkleDorsiflexion_discomfortTypeOther === true &&
      this.parent.leftAnkleDorsiflexion_typeOther === ""
    ) {
      return false;
    }

    return true;
  }),

  leftAnkleDorsiflexion_status: yup.bool().test("leftAnkleDorsiflexion_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.leftAnkleDorsiflexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftAnkleDorsiflexion_discomfortResolvedImmediately === false &&
      this.parent.leftAnkleDorsiflexion_discomfortPersisting === false &&
      this.parent.leftAnkleDorsiflexion_discomfortResolvedWithRest === false &&
      this.parent.leftAnkleDorsiflexion_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  leftAnklePlantarFlexion: yup.bool().test("leftAnklePlantarFlexion", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      ankleMobilityPainFree,
      leftAnklePlantarFlexion_unable,
      leftAnklePlantarFlexion_lessThanOneThird,
      leftAnklePlantarFlexion_lessThanTwoThirds,
      leftAnklePlantarFlexion_greaterThanTwoThirds,
      leftAnklePlantarFlexion_objectiveRange,
      leftAnklePlantarFlexion_limitingFactor,
      leftAnklePlantarFlexion_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (ankleMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (leftAnklePlantarFlexion_unable || leftAnklePlantarFlexion_lessThanOneThird || leftAnklePlantarFlexion_lessThanTwoThirds || leftAnklePlantarFlexion_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!leftAnklePlantarFlexion_objectiveRange ||
        //or the limiting factor has not been selected,c
        leftAnklePlantarFlexion_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((leftAnklePlantarFlexion_limitingFactor === "MUSCLETIGHTNESS" || leftAnklePlantarFlexion_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !leftAnklePlantarFlexion_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are set
      (!!leftAnklePlantarFlexion_objectiveRange || leftAnklePlantarFlexion_limitingFactor !== "SELECT" || !!leftAnklePlantarFlexion_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !leftAnklePlantarFlexion_unable &&
      !leftAnklePlantarFlexion_lessThanOneThird &&
      !leftAnklePlantarFlexion_lessThanTwoThirds &&
      !leftAnklePlantarFlexion_greaterThanTwoThirds
    ) {
      //then the leftAnklePlantarFlexion fields are not valid.
      return false;
    }

    return true;
  }),
  leftAnklePlantarFlexion_location: yup.string().test("leftAnklePlantarFlexion_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.leftAnklePlantarFlexion_limitingFactor === limitingFactors.PAIN && this.parent.leftAnklePlantarFlexion_location === "") {
      return false;
    }

    return true;
  }),

  leftAnklePlantarFlexion_intensity: yup.bool().test("leftAnklePlantarFlexion_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.leftAnklePlantarFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftAnklePlantarFlexion_intensityVas === "" &&
      this.parent.leftAnklePlantarFlexion_intensityMild === false &&
      this.parent.leftAnklePlantarFlexion_intensityMod === false &&
      this.parent.leftAnklePlantarFlexion_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  leftAnklePlantarFlexion_type: yup.bool().test("leftAnklePlantarFlexion_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.leftAnklePlantarFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftAnklePlantarFlexion_discomfortTypeAche === false &&
      this.parent.leftAnklePlantarFlexion_discomfortTypeSharp === false &&
      this.parent.leftAnklePlantarFlexion_discomfortTypeBurning === false &&
      this.parent.leftAnklePlantarFlexion_discomfortTypeTightness === false &&
      this.parent.leftAnklePlantarFlexion_discomfortTypePulling === false &&
      this.parent.leftAnklePlantarFlexion_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  leftAnklePlantarFlexion_typeOther: yup.string().test("leftAnklePlantarFlexion_typeOther", "Please describe the discomfort further.", function () {
    if (
      this.parent.leftAnklePlantarFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftAnklePlantarFlexion_discomfortTypeOther === true &&
      this.parent.leftAnklePlantarFlexion_typeOther === ""
    ) {
      return false;
    }

    return true;
  }),

  leftAnklePlantarFlexion_status: yup.bool().test("leftAnklePlantarFlexion_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.leftAnklePlantarFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftAnklePlantarFlexion_discomfortResolvedImmediately === false &&
      this.parent.leftAnklePlantarFlexion_discomfortPersisting === false &&
      this.parent.leftAnklePlantarFlexion_discomfortResolvedWithRest === false &&
      this.parent.leftAnklePlantarFlexion_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  leftAnkleInversion: yup.bool().test("leftAnkleInversion", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      ankleMobilityPainFree,
      leftAnkleInversion_unable,
      leftAnkleInversion_lessThanOneThird,
      leftAnkleInversion_lessThanTwoThirds,
      leftAnkleInversion_greaterThanTwoThirds,
      leftAnkleInversion_objectiveRange,
      leftAnkleInversion_limitingFactor,
      leftAnkleInversion_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (ankleMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (leftAnkleInversion_unable || leftAnkleInversion_lessThanOneThird || leftAnkleInversion_lessThanTwoThirds || leftAnkleInversion_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!leftAnkleInversion_objectiveRange ||
        //or the limiting factor has not been selected,c
        leftAnkleInversion_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((leftAnkleInversion_limitingFactor === "MUSCLETIGHTNESS" || leftAnkleInversion_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !leftAnkleInversion_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are set
      (!!leftAnkleInversion_objectiveRange || leftAnkleInversion_limitingFactor !== "SELECT" || !!leftAnkleInversion_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !leftAnkleInversion_unable &&
      !leftAnkleInversion_lessThanOneThird &&
      !leftAnkleInversion_lessThanTwoThirds &&
      !leftAnkleInversion_greaterThanTwoThirds
    ) {
      //then the leftAnkleInversion fields are not valid.
      return false;
    }

    return true;
  }),
  leftAnkleInversion_location: yup.string().test("leftAnkleInversion_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.leftAnkleInversion_limitingFactor === limitingFactors.PAIN && this.parent.leftAnkleInversion_location === "") {
      return false;
    }

    return true;
  }),

  leftAnkleInversion_intensity: yup.bool().test("leftAnkleInversion_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.leftAnkleInversion_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftAnkleInversion_intensityVas === "" &&
      this.parent.leftAnkleInversion_intensityMild === false &&
      this.parent.leftAnkleInversion_intensityMod === false &&
      this.parent.leftAnkleInversion_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  leftAnkleInversion_type: yup.bool().test("leftAnkleInversion_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.leftAnkleInversion_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftAnkleInversion_discomfortTypeAche === false &&
      this.parent.leftAnkleInversion_discomfortTypeSharp === false &&
      this.parent.leftAnkleInversion_discomfortTypeBurning === false &&
      this.parent.leftAnkleInversion_discomfortTypeTightness === false &&
      this.parent.leftAnkleInversion_discomfortTypePulling === false &&
      this.parent.leftAnkleInversion_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  leftAnkleInversion_typeOther: yup.string().test("leftAnkleInversion_typeOther", "Please describe the discomfort further.", function () {
    if (
      this.parent.leftAnkleInversion_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftAnkleInversion_discomfortTypeOther === true &&
      this.parent.leftAnkleInversion_typeOther === ""
    ) {
      return false;
    }

    return true;
  }),

  leftAnkleInversion_status: yup.bool().test("leftAnkleInversion_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.leftAnkleInversion_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftAnkleInversion_discomfortResolvedImmediately === false &&
      this.parent.leftAnkleInversion_discomfortPersisting === false &&
      this.parent.leftAnkleInversion_discomfortResolvedWithRest === false &&
      this.parent.leftAnkleInversion_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  leftAnkleEversion: yup.bool().test("leftAnkleEversion", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      ankleMobilityPainFree,
      leftAnkleEversion_unable,
      leftAnkleEversion_lessThanOneThird,
      leftAnkleEversion_lessThanTwoThirds,
      leftAnkleEversion_greaterThanTwoThirds,
      leftAnkleEversion_objectiveRange,
      leftAnkleEversion_limitingFactor,
      leftAnkleEversion_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (ankleMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (leftAnkleEversion_unable || leftAnkleEversion_lessThanOneThird || leftAnkleEversion_lessThanTwoThirds || leftAnkleEversion_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!leftAnkleEversion_objectiveRange ||
        //or the limiting factor has not been selected,c
        leftAnkleEversion_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((leftAnkleEversion_limitingFactor === "MUSCLETIGHTNESS" || leftAnkleEversion_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !leftAnkleEversion_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are set
      (!!leftAnkleEversion_objectiveRange || leftAnkleEversion_limitingFactor !== "SELECT" || !!leftAnkleEversion_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !leftAnkleEversion_unable &&
      !leftAnkleEversion_lessThanOneThird &&
      !leftAnkleEversion_lessThanTwoThirds &&
      !leftAnkleEversion_greaterThanTwoThirds
    ) {
      //then the leftAnkleEversion fields are not valid.
      return false;
    }

    return true;
  }),
  leftAnkleEversion_location: yup.string().test("leftAnkleEversion_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.leftAnkleEversion_limitingFactor === limitingFactors.PAIN && this.parent.leftAnkleEversion_location === "") {
      return false;
    }

    return true;
  }),

  leftAnkleEversion_intensity: yup.bool().test("leftAnkleEversion_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.leftAnkleEversion_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftAnkleEversion_intensityVas === "" &&
      this.parent.leftAnkleEversion_intensityMild === false &&
      this.parent.leftAnkleEversion_intensityMod === false &&
      this.parent.leftAnkleEversion_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  leftAnkleEversion_type: yup.bool().test("leftAnkleEversion_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.leftAnkleEversion_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftAnkleEversion_discomfortTypeAche === false &&
      this.parent.leftAnkleEversion_discomfortTypeSharp === false &&
      this.parent.leftAnkleEversion_discomfortTypeBurning === false &&
      this.parent.leftAnkleEversion_discomfortTypeTightness === false &&
      this.parent.leftAnkleEversion_discomfortTypePulling === false &&
      this.parent.leftAnkleEversion_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  leftAnkleEversion_typeOther: yup.string().test("leftAnkleEversion_typeOther", "Please describe the discomfort further.", function () {
    if (
      this.parent.leftAnkleEversion_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftAnkleEversion_discomfortTypeOther === true &&
      this.parent.leftAnkleEversion_typeOther === ""
    ) {
      return false;
    }

    return true;
  }),

  leftAnkleEversion_status: yup.bool().test("leftAnkleEversion_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.leftAnkleEversion_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftAnkleEversion_discomfortResolvedImmediately === false &&
      this.parent.leftAnkleEversion_discomfortPersisting === false &&
      this.parent.leftAnkleEversion_discomfortResolvedWithRest === false &&
      this.parent.leftAnkleEversion_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  rightAnkleDorsiflexion: yup.bool().test("rightAnkleDorsiflexion", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      ankleMobilityPainFree,
      rightAnkleDorsiflexion_unable,
      rightAnkleDorsiflexion_lessThanOneThird,
      rightAnkleDorsiflexion_lessThanTwoThirds,
      rightAnkleDorsiflexion_greaterThanTwoThirds,
      rightAnkleDorsiflexion_objectiveRange,
      rightAnkleDorsiflexion_limitingFactor,
      rightAnkleDorsiflexion_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (ankleMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (rightAnkleDorsiflexion_unable || rightAnkleDorsiflexion_lessThanOneThird || rightAnkleDorsiflexion_lessThanTwoThirds || rightAnkleDorsiflexion_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!rightAnkleDorsiflexion_objectiveRange ||
        //or the limiting factor has not been selected,c
        rightAnkleDorsiflexion_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((rightAnkleDorsiflexion_limitingFactor === "MUSCLETIGHTNESS" || rightAnkleDorsiflexion_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !rightAnkleDorsiflexion_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are set
      (!!rightAnkleDorsiflexion_objectiveRange || rightAnkleDorsiflexion_limitingFactor !== "SELECT" || !!rightAnkleDorsiflexion_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !rightAnkleDorsiflexion_unable &&
      !rightAnkleDorsiflexion_lessThanOneThird &&
      !rightAnkleDorsiflexion_lessThanTwoThirds &&
      !rightAnkleDorsiflexion_greaterThanTwoThirds
    ) {
      //then the rightAnkleDorsiflexion fields are not valid.
      return false;
    }

    return true;
  }),
  rightAnkleDorsiflexion_location: yup.string().test("rightAnkleDorsiflexion_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.rightAnkleDorsiflexion_limitingFactor === limitingFactors.PAIN && this.parent.rightAnkleDorsiflexion_location === "") {
      return false;
    }

    return true;
  }),

  rightAnkleDorsiflexion_intensity: yup.bool().test("rightAnkleDorsiflexion_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.rightAnkleDorsiflexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightAnkleDorsiflexion_intensityVas === "" &&
      this.parent.rightAnkleDorsiflexion_intensityMild === false &&
      this.parent.rightAnkleDorsiflexion_intensityMod === false &&
      this.parent.rightAnkleDorsiflexion_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  rightAnkleDorsiflexion_type: yup.bool().test("rightAnkleDorsiflexion_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.rightAnkleDorsiflexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightAnkleDorsiflexion_discomfortTypeAche === false &&
      this.parent.rightAnkleDorsiflexion_discomfortTypeSharp === false &&
      this.parent.rightAnkleDorsiflexion_discomfortTypeBurning === false &&
      this.parent.rightAnkleDorsiflexion_discomfortTypeTightness === false &&
      this.parent.rightAnkleDorsiflexion_discomfortTypePulling === false &&
      this.parent.rightAnkleDorsiflexion_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  rightAnkleDorsiflexion_typeOther: yup.string().test("rightAnkleDorsiflexion_typeOther", "Please describe the discomfort further.", function () {
    if (
      this.parent.rightAnkleDorsiflexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightAnkleDorsiflexion_discomfortTypeOther === true &&
      this.parent.rightAnkleDorsiflexion_typeOther === ""
    ) {
      return false;
    }

    return true;
  }),

  rightAnkleDorsiflexion_status: yup.bool().test("rightAnkleDorsiflexion_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.rightAnkleDorsiflexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightAnkleDorsiflexion_discomfortResolvedImmediately === false &&
      this.parent.rightAnkleDorsiflexion_discomfortPersisting === false &&
      this.parent.rightAnkleDorsiflexion_discomfortResolvedWithRest === false &&
      this.parent.rightAnkleDorsiflexion_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  rightAnklePlantarFlexion: yup.bool().test("rightAnklePlantarFlexion", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      ankleMobilityPainFree,
      rightAnklePlantarFlexion_unable,
      rightAnklePlantarFlexion_lessThanOneThird,
      rightAnklePlantarFlexion_lessThanTwoThirds,
      rightAnklePlantarFlexion_greaterThanTwoThirds,
      rightAnklePlantarFlexion_objectiveRange,
      rightAnklePlantarFlexion_limitingFactor,
      rightAnklePlantarFlexion_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (ankleMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (rightAnklePlantarFlexion_unable ||
        rightAnklePlantarFlexion_lessThanOneThird ||
        rightAnklePlantarFlexion_lessThanTwoThirds ||
        rightAnklePlantarFlexion_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!rightAnklePlantarFlexion_objectiveRange ||
        //or the limiting factor has not been selected,c
        rightAnklePlantarFlexion_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((rightAnklePlantarFlexion_limitingFactor === "MUSCLETIGHTNESS" || rightAnklePlantarFlexion_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !rightAnklePlantarFlexion_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are set
      (!!rightAnklePlantarFlexion_objectiveRange || rightAnklePlantarFlexion_limitingFactor !== "SELECT" || !!rightAnklePlantarFlexion_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !rightAnklePlantarFlexion_unable &&
      !rightAnklePlantarFlexion_lessThanOneThird &&
      !rightAnklePlantarFlexion_lessThanTwoThirds &&
      !rightAnklePlantarFlexion_greaterThanTwoThirds
    ) {
      //then the rightAnklePlantarFlexion fields are not valid.
      return false;
    }

    return true;
  }),
  rightAnklePlantarFlexion_location: yup.string().test("rightAnklePlantarFlexion_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.rightAnklePlantarFlexion_limitingFactor === limitingFactors.PAIN && this.parent.rightAnklePlantarFlexion_location === "") {
      return false;
    }

    return true;
  }),

  rightAnklePlantarFlexion_intensity: yup.bool().test("rightAnklePlantarFlexion_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.rightAnklePlantarFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightAnklePlantarFlexion_intensityVas === "" &&
      this.parent.rightAnklePlantarFlexion_intensityMild === false &&
      this.parent.rightAnklePlantarFlexion_intensityMod === false &&
      this.parent.rightAnklePlantarFlexion_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  rightAnklePlantarFlexion_type: yup.bool().test("rightAnklePlantarFlexion_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.rightAnklePlantarFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightAnklePlantarFlexion_discomfortTypeAche === false &&
      this.parent.rightAnklePlantarFlexion_discomfortTypeSharp === false &&
      this.parent.rightAnklePlantarFlexion_discomfortTypeBurning === false &&
      this.parent.rightAnklePlantarFlexion_discomfortTypeTightness === false &&
      this.parent.rightAnklePlantarFlexion_discomfortTypePulling === false &&
      this.parent.rightAnklePlantarFlexion_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  rightAnklePlantarFlexion_typeOther: yup.string().test("rightAnklePlantarFlexion_typeOther", "Please describe the discomfort further.", function () {
    if (
      this.parent.rightAnklePlantarFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightAnklePlantarFlexion_discomfortTypeOther === true &&
      this.parent.rightAnklePlantarFlexion_typeOther === ""
    ) {
      return false;
    }

    return true;
  }),

  rightAnklePlantarFlexion_status: yup.bool().test("rightAnklePlantarFlexion_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.rightAnklePlantarFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightAnklePlantarFlexion_discomfortResolvedImmediately === false &&
      this.parent.rightAnklePlantarFlexion_discomfortPersisting === false &&
      this.parent.rightAnklePlantarFlexion_discomfortResolvedWithRest === false &&
      this.parent.rightAnklePlantarFlexion_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  rightAnkleInversion: yup.bool().test("rightAnkleInversion", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      ankleMobilityPainFree,
      rightAnkleInversion_unable,
      rightAnkleInversion_lessThanOneThird,
      rightAnkleInversion_lessThanTwoThirds,
      rightAnkleInversion_greaterThanTwoThirds,
      rightAnkleInversion_objectiveRange,
      rightAnkleInversion_limitingFactor,
      rightAnkleInversion_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (ankleMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (rightAnkleInversion_unable || rightAnkleInversion_lessThanOneThird || rightAnkleInversion_lessThanTwoThirds || rightAnkleInversion_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!rightAnkleInversion_objectiveRange ||
        //or the limiting factor has not been selected,c
        rightAnkleInversion_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((rightAnkleInversion_limitingFactor === "MUSCLETIGHTNESS" || rightAnkleInversion_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !rightAnkleInversion_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are set
      (!!rightAnkleInversion_objectiveRange || rightAnkleInversion_limitingFactor !== "SELECT" || !!rightAnkleInversion_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !rightAnkleInversion_unable &&
      !rightAnkleInversion_lessThanOneThird &&
      !rightAnkleInversion_lessThanTwoThirds &&
      !rightAnkleInversion_greaterThanTwoThirds
    ) {
      //then the rightAnkleInversion fields are not valid.
      return false;
    }

    return true;
  }),
  rightAnkleInversion_location: yup.string().test("rightAnkleInversion_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.rightAnkleInversion_limitingFactor === limitingFactors.PAIN && this.parent.rightAnkleInversion_location === "") {
      return false;
    }

    return true;
  }),

  rightAnkleInversion_intensity: yup.bool().test("rightAnkleInversion_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.rightAnkleInversion_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightAnkleInversion_intensityVas === "" &&
      this.parent.rightAnkleInversion_intensityMild === false &&
      this.parent.rightAnkleInversion_intensityMod === false &&
      this.parent.rightAnkleInversion_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  rightAnkleInversion_type: yup.bool().test("rightAnkleInversion_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.rightAnkleInversion_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightAnkleInversion_discomfortTypeAche === false &&
      this.parent.rightAnkleInversion_discomfortTypeSharp === false &&
      this.parent.rightAnkleInversion_discomfortTypeBurning === false &&
      this.parent.rightAnkleInversion_discomfortTypeTightness === false &&
      this.parent.rightAnkleInversion_discomfortTypePulling === false &&
      this.parent.rightAnkleInversion_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  rightAnkleInversion_typeOther: yup.string().test("rightAnkleInversion_typeOther", "Please describe the discomfort further.", function () {
    if (
      this.parent.rightAnkleInversion_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightAnkleInversion_discomfortTypeOther === true &&
      this.parent.rightAnkleInversion_typeOther === ""
    ) {
      return false;
    }

    return true;
  }),

  rightAnkleInversion_status: yup.bool().test("rightAnkleInversion_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.rightAnkleInversion_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightAnkleInversion_discomfortResolvedImmediately === false &&
      this.parent.rightAnkleInversion_discomfortPersisting === false &&
      this.parent.rightAnkleInversion_discomfortResolvedWithRest === false &&
      this.parent.rightAnkleInversion_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  rightAnkleEversion: yup.bool().test("rightAnkleEversion", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      ankleMobilityPainFree,
      rightAnkleEversion_unable,
      rightAnkleEversion_lessThanOneThird,
      rightAnkleEversion_lessThanTwoThirds,
      rightAnkleEversion_greaterThanTwoThirds,
      rightAnkleEversion_objectiveRange,
      rightAnkleEversion_limitingFactor,
      rightAnkleEversion_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (ankleMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (rightAnkleEversion_unable || rightAnkleEversion_lessThanOneThird || rightAnkleEversion_lessThanTwoThirds || rightAnkleEversion_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!rightAnkleEversion_objectiveRange ||
        //or the limiting factor has not been selected,c
        rightAnkleEversion_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((rightAnkleEversion_limitingFactor === "MUSCLETIGHTNESS" || rightAnkleEversion_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !rightAnkleEversion_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are set
      (!!rightAnkleEversion_objectiveRange || rightAnkleEversion_limitingFactor !== "SELECT" || !!rightAnkleEversion_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !rightAnkleEversion_unable &&
      !rightAnkleEversion_lessThanOneThird &&
      !rightAnkleEversion_lessThanTwoThirds &&
      !rightAnkleEversion_greaterThanTwoThirds
    ) {
      //then the rightAnkleEversion fields are not valid.
      return false;
    }

    return true;
  }),
  rightAnkleEversion_location: yup.string().test("rightAnkleEversion_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.rightAnkleEversion_limitingFactor === limitingFactors.PAIN && this.parent.rightAnkleEversion_location === "") {
      return false;
    }

    return true;
  }),

  rightAnkleEversion_intensity: yup.bool().test("rightAnkleEversion_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.rightAnkleEversion_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightAnkleEversion_intensityVas === "" &&
      this.parent.rightAnkleEversion_intensityMild === false &&
      this.parent.rightAnkleEversion_intensityMod === false &&
      this.parent.rightAnkleEversion_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  rightAnkleEversion_type: yup.bool().test("rightAnkleEversion_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.rightAnkleEversion_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightAnkleEversion_discomfortTypeAche === false &&
      this.parent.rightAnkleEversion_discomfortTypeSharp === false &&
      this.parent.rightAnkleEversion_discomfortTypeBurning === false &&
      this.parent.rightAnkleEversion_discomfortTypeTightness === false &&
      this.parent.rightAnkleEversion_discomfortTypePulling === false &&
      this.parent.rightAnkleEversion_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  rightAnkleEversion_typeOther: yup.string().test("rightAnkleEversion_typeOther", "Please describe the discomfort further.", function () {
    if (
      this.parent.rightAnkleEversion_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightAnkleEversion_discomfortTypeOther === true &&
      this.parent.rightAnkleEversion_typeOther === ""
    ) {
      return false;
    }

    return true;
  }),

  rightAnkleEversion_status: yup.bool().test("rightAnkleEversion_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.rightAnkleEversion_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightAnkleEversion_discomfortResolvedImmediately === false &&
      this.parent.rightAnkleEversion_discomfortPersisting === false &&
      this.parent.rightAnkleEversion_discomfortResolvedWithRest === false &&
      this.parent.rightAnkleEversion_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  leftHipFlexion: yup.bool().test("leftHipFlexion", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      hipMobilityPainFree,
      leftHipFlexion_unable,
      leftHipFlexion_lessThanOneThird,
      leftHipFlexion_lessThanTwoThirds,
      leftHipFlexion_greaterThanTwoThirds,
      leftHipFlexion_objectiveRange,
      leftHipFlexion_limitingFactor,
      leftHipFlexion_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (hipMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (leftHipFlexion_unable || leftHipFlexion_lessThanOneThird || leftHipFlexion_lessThanTwoThirds || leftHipFlexion_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!leftHipFlexion_objectiveRange ||
        //or the limiting factor has not been selected,c
        leftHipFlexion_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((leftHipFlexion_limitingFactor === "MUSCLETIGHTNESS" || leftHipFlexion_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !leftHipFlexion_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are set
      (!!leftHipFlexion_objectiveRange || leftHipFlexion_limitingFactor !== "SELECT" || !!leftHipFlexion_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !leftHipFlexion_unable &&
      !leftHipFlexion_lessThanOneThird &&
      !leftHipFlexion_lessThanTwoThirds &&
      !leftHipFlexion_greaterThanTwoThirds
    ) {
      //then the leftHipFlexion fields are not valid.
      return false;
    }

    return true;
  }),
  leftHipFlexion_location: yup.string().test("leftHipFlexion_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.leftHipFlexion_limitingFactor === limitingFactors.PAIN && this.parent.leftHipFlexion_location === "") {
      return false;
    }

    return true;
  }),

  leftHipFlexion_intensity: yup.bool().test("leftHipFlexion_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.leftHipFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftHipFlexion_intensityVas === "" &&
      this.parent.leftHipFlexion_intensityMild === false &&
      this.parent.leftHipFlexion_intensityMod === false &&
      this.parent.leftHipFlexion_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  leftHipFlexion_type: yup.bool().test("leftHipFlexion_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.leftHipFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftHipFlexion_discomfortTypeAche === false &&
      this.parent.leftHipFlexion_discomfortTypeSharp === false &&
      this.parent.leftHipFlexion_discomfortTypeBurning === false &&
      this.parent.leftHipFlexion_discomfortTypeTightness === false &&
      this.parent.leftHipFlexion_discomfortTypePulling === false &&
      this.parent.leftHipFlexion_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  leftHipFlexion_typeOther: yup.string().test("leftHipFlexion_typeOther", "Please describe the discomfort further.", function () {
    if (
      this.parent.leftHipFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftHipFlexion_discomfortTypeOther === true &&
      this.parent.leftHipFlexion_typeOther === ""
    ) {
      return false;
    }

    return true;
  }),

  leftHipFlexion_status: yup.bool().test("leftHipFlexion_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.leftHipFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftHipFlexion_discomfortResolvedImmediately === false &&
      this.parent.leftHipFlexion_discomfortPersisting === false &&
      this.parent.leftHipFlexion_discomfortResolvedWithRest === false &&
      this.parent.leftHipFlexion_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  leftHipExternalRotation: yup.bool().test("leftHipExternalRotation", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      hipMobilityPainFree,
      leftHipExternalRotation_unable,
      leftHipExternalRotation_lessThanOneThird,
      leftHipExternalRotation_lessThanTwoThirds,
      leftHipExternalRotation_greaterThanTwoThirds,
      leftHipExternalRotation_objectiveRange,
      leftHipExternalRotation_limitingFactor,
      leftHipExternalRotation_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (hipMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (leftHipExternalRotation_unable || leftHipExternalRotation_lessThanOneThird || leftHipExternalRotation_lessThanTwoThirds || leftHipExternalRotation_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!leftHipExternalRotation_objectiveRange ||
        //or the limiting factor has not been selected,c
        leftHipExternalRotation_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((leftHipExternalRotation_limitingFactor === "MUSCLETIGHTNESS" || leftHipExternalRotation_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !leftHipExternalRotation_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are set
      (!!leftHipExternalRotation_objectiveRange || leftHipExternalRotation_limitingFactor !== "SELECT" || !!leftHipExternalRotation_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !leftHipExternalRotation_unable &&
      !leftHipExternalRotation_lessThanOneThird &&
      !leftHipExternalRotation_lessThanTwoThirds &&
      !leftHipExternalRotation_greaterThanTwoThirds
    ) {
      //then the leftHipExternalRotation fields are not valid.
      return false;
    }

    return true;
  }),
  leftHipExternalRotation_location: yup.string().test("leftHipExternalRotation_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.leftHipExternalRotation_limitingFactor === limitingFactors.PAIN && this.parent.leftHipExternalRotation_location === "") {
      return false;
    }

    return true;
  }),

  leftHipExternalRotation_intensity: yup.bool().test("leftHipExternalRotation_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.leftHipExternalRotation_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftHipExternalRotation_intensityVas === "" &&
      this.parent.leftHipExternalRotation_intensityMild === false &&
      this.parent.leftHipExternalRotation_intensityMod === false &&
      this.parent.leftHipExternalRotation_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  leftHipExternalRotation_type: yup.bool().test("leftHipExternalRotation_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.leftHipExternalRotation_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftHipExternalRotation_discomfortTypeAche === false &&
      this.parent.leftHipExternalRotation_discomfortTypeSharp === false &&
      this.parent.leftHipExternalRotation_discomfortTypeBurning === false &&
      this.parent.leftHipExternalRotation_discomfortTypeTightness === false &&
      this.parent.leftHipExternalRotation_discomfortTypePulling === false &&
      this.parent.leftHipExternalRotation_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  leftHipExternalRotation_typeOther: yup.string().test("leftHipExternalRotation_typeOther", "Please describe the discomfort further.", function () {
    if (
      this.parent.leftHipExternalRotation_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftHipExternalRotation_discomfortTypeOther === true &&
      this.parent.leftHipExternalRotation_typeOther === ""
    ) {
      return false;
    }

    return true;
  }),

  leftHipExternalRotation_status: yup.bool().test("leftHipExternalRotation_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.leftHipExternalRotation_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftHipExternalRotation_discomfortResolvedImmediately === false &&
      this.parent.leftHipExternalRotation_discomfortPersisting === false &&
      this.parent.leftHipExternalRotation_discomfortResolvedWithRest === false &&
      this.parent.leftHipExternalRotation_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  leftKneeFlexion: yup.bool().test("leftKneeFlexion", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      hipMobilityPainFree,
      leftKneeFlexion_unable,
      leftKneeFlexion_lessThanOneThird,
      leftKneeFlexion_lessThanTwoThirds,
      leftKneeFlexion_greaterThanTwoThirds,
      leftKneeFlexion_objectiveRange,
      leftKneeFlexion_limitingFactor,
      leftKneeFlexion_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (hipMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (leftKneeFlexion_unable || leftKneeFlexion_lessThanOneThird || leftKneeFlexion_lessThanTwoThirds || leftKneeFlexion_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!leftKneeFlexion_objectiveRange ||
        //or the limiting factor has not been selected,c
        leftKneeFlexion_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((leftKneeFlexion_limitingFactor === "MUSCLETIGHTNESS" || leftKneeFlexion_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !leftKneeFlexion_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are set
      (!!leftKneeFlexion_objectiveRange || leftKneeFlexion_limitingFactor !== "SELECT" || !!leftKneeFlexion_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !leftKneeFlexion_unable &&
      !leftKneeFlexion_lessThanOneThird &&
      !leftKneeFlexion_lessThanTwoThirds &&
      !leftKneeFlexion_greaterThanTwoThirds
    ) {
      //then the leftKneeFlexion fields are not valid.
      return false;
    }

    return true;
  }),
  leftKneeFlexion_location: yup.string().test("leftKneeFlexion_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.leftKneeFlexion_limitingFactor === limitingFactors.PAIN && this.parent.leftKneeFlexion_location === "") {
      return false;
    }

    return true;
  }),

  leftKneeFlexion_intensity: yup.bool().test("leftKneeFlexion_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.leftKneeFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftKneeFlexion_intensityVas === "" &&
      this.parent.leftKneeFlexion_intensityMild === false &&
      this.parent.leftKneeFlexion_intensityMod === false &&
      this.parent.leftKneeFlexion_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  leftKneeFlexion_type: yup.bool().test("leftKneeFlexion_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.leftKneeFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftKneeFlexion_discomfortTypeAche === false &&
      this.parent.leftKneeFlexion_discomfortTypeSharp === false &&
      this.parent.leftKneeFlexion_discomfortTypeBurning === false &&
      this.parent.leftKneeFlexion_discomfortTypeTightness === false &&
      this.parent.leftKneeFlexion_discomfortTypePulling === false &&
      this.parent.leftKneeFlexion_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  leftKneeFlexion_typeOther: yup.string().test("leftKneeFlexion_typeOther", "Please describe the discomfort further.", function () {
    if (
      this.parent.leftKneeFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftKneeFlexion_discomfortTypeOther === true &&
      this.parent.leftKneeFlexion_typeOther === ""
    ) {
      return false;
    }

    return true;
  }),

  leftKneeFlexion_status: yup.bool().test("leftKneeFlexion_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.leftKneeFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftKneeFlexion_discomfortResolvedImmediately === false &&
      this.parent.leftKneeFlexion_discomfortPersisting === false &&
      this.parent.leftKneeFlexion_discomfortResolvedWithRest === false &&
      this.parent.leftKneeFlexion_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  leftKneeExtension: yup.bool().test("leftKneeExtension", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      hipMobilityPainFree,
      leftKneeExtension_unable,
      leftKneeExtension_lessThanOneThird,
      leftKneeExtension_lessThanTwoThirds,
      leftKneeExtension_greaterThanTwoThirds,
      leftKneeExtension_objectiveRange,
      leftKneeExtension_limitingFactor,
      leftKneeExtension_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (hipMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (leftKneeExtension_unable || leftKneeExtension_lessThanOneThird || leftKneeExtension_lessThanTwoThirds || leftKneeExtension_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!leftKneeExtension_objectiveRange ||
        //or the limiting factor has not been selected,c
        leftKneeExtension_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((leftKneeExtension_limitingFactor === "MUSCLETIGHTNESS" || leftKneeExtension_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !leftKneeExtension_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are set
      (!!leftKneeExtension_objectiveRange || leftKneeExtension_limitingFactor !== "SELECT" || !!leftKneeExtension_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !leftKneeExtension_unable &&
      !leftKneeExtension_lessThanOneThird &&
      !leftKneeExtension_lessThanTwoThirds &&
      !leftKneeExtension_greaterThanTwoThirds
    ) {
      //then the leftKneeExtension fields are not valid.
      return false;
    }

    return true;
  }),
  leftKneeExtension_location: yup.string().test("leftKneeExtension_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.leftKneeExtension_limitingFactor === limitingFactors.PAIN && this.parent.leftKneeExtension_location === "") {
      return false;
    }

    return true;
  }),

  leftKneeExtension_intensity: yup.bool().test("leftKneeExtension_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.leftKneeExtension_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftKneeExtension_intensityVas === "" &&
      this.parent.leftKneeExtension_intensityMild === false &&
      this.parent.leftKneeExtension_intensityMod === false &&
      this.parent.leftKneeExtension_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  leftKneeExtension_type: yup.bool().test("leftKneeExtension_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.leftKneeExtension_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftKneeExtension_discomfortTypeAche === false &&
      this.parent.leftKneeExtension_discomfortTypeSharp === false &&
      this.parent.leftKneeExtension_discomfortTypeBurning === false &&
      this.parent.leftKneeExtension_discomfortTypeTightness === false &&
      this.parent.leftKneeExtension_discomfortTypePulling === false &&
      this.parent.leftKneeExtension_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  leftKneeExtension_typeOther: yup.string().test("leftKneeExtension_typeOther", "Please describe the discomfort further.", function () {
    if (
      this.parent.leftKneeExtension_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftKneeExtension_discomfortTypeOther === true &&
      this.parent.leftKneeExtension_typeOther === ""
    ) {
      return false;
    }

    return true;
  }),

  leftKneeExtension_status: yup.bool().test("leftKneeExtension_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.leftKneeExtension_limitingFactor === limitingFactors.PAIN &&
      this.parent.leftKneeExtension_discomfortResolvedImmediately === false &&
      this.parent.leftKneeExtension_discomfortPersisting === false &&
      this.parent.leftKneeExtension_discomfortResolvedWithRest === false &&
      this.parent.leftKneeExtension_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  rightHipFlexion: yup.bool().test("rightHipFlexion", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      hipMobilityPainFree,
      rightHipFlexion_unable,
      rightHipFlexion_lessThanOneThird,
      rightHipFlexion_lessThanTwoThirds,
      rightHipFlexion_greaterThanTwoThirds,
      rightHipFlexion_objectiveRange,
      rightHipFlexion_limitingFactor,
      rightHipFlexion_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (hipMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (rightHipFlexion_unable || rightHipFlexion_lessThanOneThird || rightHipFlexion_lessThanTwoThirds || rightHipFlexion_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!rightHipFlexion_objectiveRange ||
        //or the limiting factor has not been selected,c
        rightHipFlexion_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((rightHipFlexion_limitingFactor === "MUSCLETIGHTNESS" || rightHipFlexion_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !rightHipFlexion_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are set
      (!!rightHipFlexion_objectiveRange || rightHipFlexion_limitingFactor !== "SELECT" || !!rightHipFlexion_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !rightHipFlexion_unable &&
      !rightHipFlexion_lessThanOneThird &&
      !rightHipFlexion_lessThanTwoThirds &&
      !rightHipFlexion_greaterThanTwoThirds
    ) {
      //then the rightHipFlexion fields are not valid.
      return false;
    }

    return true;
  }),
  rightHipFlexion_location: yup.string().test("rightHipFlexion_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.rightHipFlexion_limitingFactor === limitingFactors.PAIN && this.parent.rightHipFlexion_location === "") {
      return false;
    }

    return true;
  }),

  rightHipFlexion_intensity: yup.bool().test("rightHipFlexion_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.rightHipFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightHipFlexion_intensityVas === "" &&
      this.parent.rightHipFlexion_intensityMild === false &&
      this.parent.rightHipFlexion_intensityMod === false &&
      this.parent.rightHipFlexion_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  rightHipFlexion_type: yup.bool().test("rightHipFlexion_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.rightHipFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightHipFlexion_discomfortTypeAche === false &&
      this.parent.rightHipFlexion_discomfortTypeSharp === false &&
      this.parent.rightHipFlexion_discomfortTypeBurning === false &&
      this.parent.rightHipFlexion_discomfortTypeTightness === false &&
      this.parent.rightHipFlexion_discomfortTypePulling === false &&
      this.parent.rightHipFlexion_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  rightHipFlexion_typeOther: yup.string().test("rightHipFlexion_typeOther", "Please describe the discomfort further.", function () {
    if (
      this.parent.rightHipFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightHipFlexion_discomfortTypeOther === true &&
      this.parent.rightHipFlexion_typeOther === ""
    ) {
      return false;
    }

    return true;
  }),

  rightHipFlexion_status: yup.bool().test("rightHipFlexion_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.rightHipFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightHipFlexion_discomfortResolvedImmediately === false &&
      this.parent.rightHipFlexion_discomfortPersisting === false &&
      this.parent.rightHipFlexion_discomfortResolvedWithRest === false &&
      this.parent.rightHipFlexion_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  rightHipExternalRotation: yup.bool().test("rightHipExternalRotation", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      hipMobilityPainFree,
      rightHipExternalRotation_unable,
      rightHipExternalRotation_lessThanOneThird,
      rightHipExternalRotation_lessThanTwoThirds,
      rightHipExternalRotation_greaterThanTwoThirds,
      rightHipExternalRotation_objectiveRange,
      rightHipExternalRotation_limitingFactor,
      rightHipExternalRotation_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (hipMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (rightHipExternalRotation_unable ||
        rightHipExternalRotation_lessThanOneThird ||
        rightHipExternalRotation_lessThanTwoThirds ||
        rightHipExternalRotation_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!rightHipExternalRotation_objectiveRange ||
        //or the limiting factor has not been selected,c
        rightHipExternalRotation_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((rightHipExternalRotation_limitingFactor === "MUSCLETIGHTNESS" || rightHipExternalRotation_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !rightHipExternalRotation_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are set
      (!!rightHipExternalRotation_objectiveRange || rightHipExternalRotation_limitingFactor !== "SELECT" || !!rightHipExternalRotation_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !rightHipExternalRotation_unable &&
      !rightHipExternalRotation_lessThanOneThird &&
      !rightHipExternalRotation_lessThanTwoThirds &&
      !rightHipExternalRotation_greaterThanTwoThirds
    ) {
      //then the rightHipExternalRotation fields are not valid.
      return false;
    }

    return true;
  }),
  rightHipExternalRotation_location: yup.string().test("rightHipExternalRotation_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.rightHipExternalRotation_limitingFactor === limitingFactors.PAIN && this.parent.rightHipExternalRotation_location === "") {
      return false;
    }

    return true;
  }),

  rightHipExternalRotation_intensity: yup.bool().test("rightHipExternalRotation_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.rightHipExternalRotation_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightHipExternalRotation_intensityVas === "" &&
      this.parent.rightHipExternalRotation_intensityMild === false &&
      this.parent.rightHipExternalRotation_intensityMod === false &&
      this.parent.rightHipExternalRotation_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  rightHipExternalRotation_type: yup.bool().test("rightHipExternalRotation_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.rightHipExternalRotation_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightHipExternalRotation_discomfortTypeAche === false &&
      this.parent.rightHipExternalRotation_discomfortTypeSharp === false &&
      this.parent.rightHipExternalRotation_discomfortTypeBurning === false &&
      this.parent.rightHipExternalRotation_discomfortTypeTightness === false &&
      this.parent.rightHipExternalRotation_discomfortTypePulling === false &&
      this.parent.rightHipExternalRotation_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  rightHipExternalRotation_typeOther: yup.string().test("rightHipExternalRotation_typeOther", "Please describe the discomfort further.", function () {
    if (
      this.parent.rightHipExternalRotation_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightHipExternalRotation_discomfortTypeOther === true &&
      this.parent.rightHipExternalRotation_typeOther === ""
    ) {
      return false;
    }

    return true;
  }),

  rightHipExternalRotation_status: yup.bool().test("rightHipExternalRotation_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.rightHipExternalRotation_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightHipExternalRotation_discomfortResolvedImmediately === false &&
      this.parent.rightHipExternalRotation_discomfortPersisting === false &&
      this.parent.rightHipExternalRotation_discomfortResolvedWithRest === false &&
      this.parent.rightHipExternalRotation_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  rightKneeFlexion: yup.bool().test("rightKneeFlexion", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      hipMobilityPainFree,
      rightKneeFlexion_unable,
      rightKneeFlexion_lessThanOneThird,
      rightKneeFlexion_lessThanTwoThirds,
      rightKneeFlexion_greaterThanTwoThirds,
      rightKneeFlexion_objectiveRange,
      rightKneeFlexion_limitingFactor,
      rightKneeFlexion_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (hipMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (rightKneeFlexion_unable || rightKneeFlexion_lessThanOneThird || rightKneeFlexion_lessThanTwoThirds || rightKneeFlexion_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!rightKneeFlexion_objectiveRange ||
        //or the limiting factor has not been selected,c
        rightKneeFlexion_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((rightKneeFlexion_limitingFactor === "MUSCLETIGHTNESS" || rightKneeFlexion_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !rightKneeFlexion_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are set
      (!!rightKneeFlexion_objectiveRange || rightKneeFlexion_limitingFactor !== "SELECT" || !!rightKneeFlexion_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !rightKneeFlexion_unable &&
      !rightKneeFlexion_lessThanOneThird &&
      !rightKneeFlexion_lessThanTwoThirds &&
      !rightKneeFlexion_greaterThanTwoThirds
    ) {
      //then the rightKneeFlexion fields are not valid.
      return false;
    }

    return true;
  }),
  rightKneeFlexion_location: yup.string().test("rightKneeFlexion_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.rightKneeFlexion_limitingFactor === limitingFactors.PAIN && this.parent.rightKneeFlexion_location === "") {
      return false;
    }

    return true;
  }),

  rightKneeFlexion_intensity: yup.bool().test("rightKneeFlexion_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.rightKneeFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightKneeFlexion_intensityVas === "" &&
      this.parent.rightKneeFlexion_intensityMild === false &&
      this.parent.rightKneeFlexion_intensityMod === false &&
      this.parent.rightKneeFlexion_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  rightKneeFlexion_type: yup.bool().test("rightKneeFlexion_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.rightKneeFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightKneeFlexion_discomfortTypeAche === false &&
      this.parent.rightKneeFlexion_discomfortTypeSharp === false &&
      this.parent.rightKneeFlexion_discomfortTypeBurning === false &&
      this.parent.rightKneeFlexion_discomfortTypeTightness === false &&
      this.parent.rightKneeFlexion_discomfortTypePulling === false &&
      this.parent.rightKneeFlexion_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  rightKneeFlexion_typeOther: yup.string().test("rightKneeFlexion_typeOther", "Please describe the discomfort further.", function () {
    if (
      this.parent.rightKneeFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightKneeFlexion_discomfortTypeOther === true &&
      this.parent.rightKneeFlexion_typeOther === ""
    ) {
      return false;
    }

    return true;
  }),

  rightKneeFlexion_status: yup.bool().test("rightKneeFlexion_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.rightKneeFlexion_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightKneeFlexion_discomfortResolvedImmediately === false &&
      this.parent.rightKneeFlexion_discomfortPersisting === false &&
      this.parent.rightKneeFlexion_discomfortResolvedWithRest === false &&
      this.parent.rightKneeFlexion_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  rightKneeExtension: yup.bool().test("rightKneeExtension", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      hipMobilityPainFree,
      rightKneeExtension_unable,
      rightKneeExtension_lessThanOneThird,
      rightKneeExtension_lessThanTwoThirds,
      rightKneeExtension_greaterThanTwoThirds,
      rightKneeExtension_objectiveRange,
      rightKneeExtension_limitingFactor,
      rightKneeExtension_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (hipMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (rightKneeExtension_unable || rightKneeExtension_lessThanOneThird || rightKneeExtension_lessThanTwoThirds || rightKneeExtension_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!rightKneeExtension_objectiveRange ||
        //or the limiting factor has not been selected,c
        rightKneeExtension_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((rightKneeExtension_limitingFactor === "MUSCLETIGHTNESS" || rightKneeExtension_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !rightKneeExtension_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are set
      (!!rightKneeExtension_objectiveRange || rightKneeExtension_limitingFactor !== "SELECT" || !!rightKneeExtension_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !rightKneeExtension_unable &&
      !rightKneeExtension_lessThanOneThird &&
      !rightKneeExtension_lessThanTwoThirds &&
      !rightKneeExtension_greaterThanTwoThirds
    ) {
      //then the rightKneeExtension fields are not valid.
      return false;
    }

    return true;
  }),
  rightKneeExtension_location: yup.string().test("rightKneeExtension_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.rightKneeExtension_limitingFactor === limitingFactors.PAIN && this.parent.rightKneeExtension_location === "") {
      return false;
    }

    return true;
  }),

  rightKneeExtension_intensity: yup.bool().test("rightKneeExtension_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.rightKneeExtension_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightKneeExtension_intensityVas === "" &&
      this.parent.rightKneeExtension_intensityMild === false &&
      this.parent.rightKneeExtension_intensityMod === false &&
      this.parent.rightKneeExtension_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),

  rightKneeExtension_type: yup.bool().test("rightKneeExtension_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.rightKneeExtension_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightKneeExtension_discomfortTypeAche === false &&
      this.parent.rightKneeExtension_discomfortTypeSharp === false &&
      this.parent.rightKneeExtension_discomfortTypeBurning === false &&
      this.parent.rightKneeExtension_discomfortTypeTightness === false &&
      this.parent.rightKneeExtension_discomfortTypePulling === false &&
      this.parent.rightKneeExtension_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),

  rightKneeExtension_typeOther: yup.string().test("rightKneeExtension_typeOther", "Please describe the discomfort further.", function () {
    if (
      this.parent.rightKneeExtension_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightKneeExtension_discomfortTypeOther === true &&
      this.parent.rightKneeExtension_typeOther === ""
    ) {
      return false;
    }

    return true;
  }),

  rightKneeExtension_status: yup.bool().test("rightKneeExtension_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.rightKneeExtension_limitingFactor === limitingFactors.PAIN &&
      this.parent.rightKneeExtension_discomfortResolvedImmediately === false &&
      this.parent.rightKneeExtension_discomfortPersisting === false &&
      this.parent.rightKneeExtension_discomfortResolvedWithRest === false &&
      this.parent.rightKneeExtension_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  leftAnkleStrengthDorsiflexion: yup.bool().test("leftAnkleStrengthDorsiflexion", "Please provide a description of the discomfort.", function () {
    const { leftAnkleStrengthDorsiflexion_discomfort, leftAnkleStrengthDorsiflexion_description } = this.parent;

    if (leftAnkleStrengthDorsiflexion_discomfort === strengthDiscomfortFactors.YES && leftAnkleStrengthDorsiflexion_description === "") {
      return false;
    }
    return true;
  }),
  leftAnkleStrengthPlantarflexion: yup.bool().test("leftAnkleStrengthPlantarflexion", "Please provide a description of the discomfort.", function () {
    const { leftAnkleStrengthPlantarflexion_discomfort, leftAnkleStrengthPlantarflexion_description } = this.parent;

    if (leftAnkleStrengthPlantarflexion_discomfort === strengthDiscomfortFactors.YES && leftAnkleStrengthPlantarflexion_description === "") {
      return false;
    }
    return true;
  }),
  leftAnkleStrengthInversion: yup.bool().test("leftAnkleStrengthInversion", "Please provide a description of the discomfort.", function () {
    const { leftAnkleStrengthInversion_discomfort, leftAnkleStrengthInversion_description } = this.parent;

    if (leftAnkleStrengthInversion_discomfort === strengthDiscomfortFactors.YES && leftAnkleStrengthInversion_description === "") {
      return false;
    }
    return true;
  }),
  leftAnkleStrengthEversion: yup.bool().test("leftAnkleStrengthEversion", "Please provide a description of the discomfort.", function () {
    const { leftAnkleStrengthEversion_discomfort, leftAnkleStrengthEversion_description } = this.parent;

    if (leftAnkleStrengthEversion_discomfort === strengthDiscomfortFactors.YES && leftAnkleStrengthEversion_description === "") {
      return false;
    }
    return true;
  }),
  leftToeExtension: yup.bool().test("leftToeExtension", "Please provide a description of the discomfort.", function () {
    const { leftToeExtension_discomfort, leftToeExtension_description } = this.parent;

    if (leftToeExtension_discomfort === strengthDiscomfortFactors.YES && leftToeExtension_description === "") {
      return false;
    }
    return true;
  }),
  rightAnkleStrengthDorsiflexion: yup.bool().test("rightAnkleStrengthDorsiflexion", "Please provide a description of the discomfort.", function () {
    const { rightAnkleStrengthDorsiflexion_discomfort, rightAnkleStrengthDorsiflexion_description } = this.parent;

    if (rightAnkleStrengthDorsiflexion_discomfort === strengthDiscomfortFactors.YES && rightAnkleStrengthDorsiflexion_description === "") {
      return false;
    }
    return true;
  }),
  rightAnkleStrengthPlantarflexion: yup.bool().test("rightAnkleStrengthPlantarflexion", "Please provide a description of the discomfort.", function () {
    const { rightAnkleStrengthPlantarflexion_discomfort, rightAnkleStrengthPlantarflexion_description } = this.parent;

    if (rightAnkleStrengthPlantarflexion_discomfort === strengthDiscomfortFactors.YES && rightAnkleStrengthPlantarflexion_description === "") {
      return false;
    }
    return true;
  }),
  rightAnkleStrengthInversion: yup.bool().test("rightAnkleStrengthInversion", "Please provide a description of the discomfort.", function () {
    const { rightAnkleStrengthInversion_discomfort, rightAnkleStrengthInversion_description } = this.parent;

    if (rightAnkleStrengthInversion_discomfort === strengthDiscomfortFactors.YES && rightAnkleStrengthInversion_description === "") {
      return false;
    }
    return true;
  }),
  rightAnkleStrengthEversion: yup.bool().test("rightAnkleStrengthEversion", "Please provide a description of the discomfort.", function () {
    const { rightAnkleStrengthEversion_discomfort, rightAnkleStrengthEversion_description } = this.parent;

    if (rightAnkleStrengthEversion_discomfort === strengthDiscomfortFactors.YES && rightAnkleStrengthEversion_description === "") {
      return false;
    }
    return true;
  }),
  rightToeExtension: yup.bool().test("rightToeExtension", "Please provide a description of the discomfort.", function () {
    const { rightToeExtension_discomfort, rightToeExtension_description } = this.parent;

    if (rightToeExtension_discomfort === strengthDiscomfortFactors.YES && rightToeExtension_description === "") {
      return false;
    }
    return true;
  }),
  trunkStrengthExtensors: yup.bool().test("trunkStrengthExtensors", "Please provide a description of the discomfort.", function () {
    const { trunkStrengthExtensors_discomfort, trunkStrengthExtensors_description } = this.parent;

    if (trunkStrengthExtensors_discomfort === strengthDiscomfortFactors.YES && trunkStrengthExtensors_description === "") {
      return false;
    }
    return true;
  }),
  trunkStrengthAbdominals: yup.bool().test("trunkStrengthAbdominals", "Please provide a description of the discomfort.", function () {
    const { trunkStrengthAbdominals_discomfort, trunkStrengthAbdominals_description } = this.parent;

    if (trunkStrengthAbdominals_discomfort === strengthDiscomfortFactors.YES && trunkStrengthAbdominals_description === "") {
      return false;
    }
    return true;
  }),
  hamstringFlexibility: yup.bool().test("hamstringFlexibility", "Please provide both Left and Right SLR values.", function () {
    const { hamstringLeftSLR, hamstringRightSLR } = this.parent;

    if (!hamstringRightSLR || !hamstringLeftSLR) {
      return false;
    }
    return true;
  }),
  trunkStability: yup.bool().test("trunkStability", "Please select a grade value for trunk stability.", function () {
    const { trunkStabilityGrade } = this.parent;
    if (!trunkStabilityGrade) {
      return false;
    }
    return true;
  }),
  leftShoulderStability: yup.bool().test("leftShoulderStability", "Please select a grade value for left shoulder stability.", function () {
    const { leftShoulderStabilityGrade } = this.parent;
    if (!leftShoulderStabilityGrade) {
      return false;
    }
    return true;
  }),
  rightShoulderStability: yup.bool().test("trunkStability", "Please select a grade value for right shoulder stability.", function () {
    const { rightShoulderStabilityGrade } = this.parent;
    if (!rightShoulderStabilityGrade) {
      return false;
    }
    return true;
  }),
  bloodPressureSystolic3: yup.string().test("bloodPressureSystolic3", "Please enter the participant's 3rd blood pressure reading.", function () {
    const { showHighBloodPressure, bloodPressureSystolic3, isHighBPFlowTested } = this.parent;
    if (bloodPressureSystolic3 === undefined) {
      return true;
    }
    return (
      showHighBloodPressure &&
      showHighBloodPressure === "true" &&
      isHighBPFlowTested &&
      isHighBPFlowTested === "true" &&
      bloodPressureSystolic3 !== null &&
      bloodPressureSystolic3 !== ""
    );
  }),
  bloodPressureDiastolic3: yup.string().test("bloodPressureDiastolic3", "Please enter the participant's 3rd blood pressure reading.", function () {
    const { showHighBloodPressure, bloodPressureDiastolic3, isHighBPFlowTested } = this.parent;
    if (bloodPressureDiastolic3 === undefined) {
      return true;
    }
    return (
      showHighBloodPressure &&
      showHighBloodPressure === "true" &&
      isHighBPFlowTested &&
      isHighBPFlowTested === "true" &&
      bloodPressureDiastolic3 !== null &&
      bloodPressureDiastolic3 !== ""
    );
  }),
});

export default musculoskeletalValidation;

import { inject, observer } from "mobx-react";
import { useAuth } from "oidc-react";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { DASHBOARD } from "../../routes/paths";
import { Root } from "../../stores";

interface LoginCallbackProps {
  rootStore?: Root;
}

const LoginCallback = inject("rootStore")(
  observer((props: LoginCallbackProps) => {
    const history = useHistory();
    const auth = useAuth();

    useEffect(() => {
      const redirect = async (): Promise<void> => {
        if (props.rootStore?.userStore.userDetails) {
          await auth?.userManager
            ?.getUser()
            .then((user) => {
              props.rootStore?.userStore.setUserRole(user?.profile.role);
            })
            .catch((err) => {
              // redirect to base url and try again
              window.location.href = window.location.protocol + "//" + window.location.hostname + window.location.port + "/";
            });

          auth.userManager.events.addUserSessionChanged(() => {
            //performs the following action(s) when the user session changes.
          });

          auth.userManager.events.addAccessTokenExpired(() => {
            //performs the following action(s) when the user's session token has expired.
          });

          auth.userManager.events.addAccessTokenExpiring(async () => {
            //performs the follow action(s) when the user's session token is expiring.
          });

          auth.userManager.events.addSilentRenewError(() => {
            //performs the following action(s) when an error occurrs when trying to silently renew the session token.
          });

          if (props.rootStore && props.rootStore.userStore.userCurrentPath !== "" && props.rootStore.pefaStore.selectedPefa) {
            history.push(props.rootStore.userStore.userCurrentPath);
            return;
          }
          history.push(DASHBOARD);
        }
      };
      redirect();
    }, [props.rootStore?.userStore.userDetails]);

    return (
      <div style={{ padding: "70px 0", textAlign: "center" }}>
        <style>
          {`.lds-ellipsis {display: inline-block;position: relative;width: 80px;height: 80px;}.lds-ellipsis div {position: absolute;top: 33px;width: 13px;height: 13px;border-radius: 50%;background: #1CBEA5;animation-timing-function: cubic-bezier(0, 1, 1, 0);}.lds-ellipsis div:nth-child(1) {left: 8px;animation: lds-ellipsis1 0.6s infinite;}.lds-ellipsis div:nth-child(2) {left: 8px;animation: lds-ellipsis2 0.6s infinite;}.lds-ellipsis div:nth-child(3) {left: 32px;animation: lds-ellipsis2 0.6s infinite;}.lds-ellipsis div:nth-child(4) {left: 56px;animation: lds-ellipsis3 0.6s infinite;}@keyframes lds-ellipsis1 {0% {transform: scale(0);}100% {transform: scale(1);}}@keyframes lds-ellipsis3 {0% {transform: scale(1);}100% {transform: scale(0);}}@keyframes lds-ellipsis2 {0% {transform: translate(0, 0);}100% {transform: translate(24px, 0);}}`}
        </style>
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="72px"
          height="72px"
          viewBox="0 0 425.2 425.2"
          xmlSpace="preserve"
          stroke="#1CBEA5"
          fill="#1CBEA5"
        >
          <g>
            <path
              d="M296.9,216.3l-90.2,90.2c-2.3,2.3-1.9,6,0.5,8.2c50.8,39,124,35,170.3-12.3c49.4-50.5,47.3-132.1-2.6-182.1
              l-85.7-85.7c-2-2-5.4-2-7.6,0l-75.7,75.7c-2,2-2,5.4,0,7.6l90.9,90.9C298.9,210.9,298.9,214.3,296.9,216.3z"
            />
            <path
              d="M190.9,133l75.7,75.7c2,2,2,5.4,0,7.6L92.4,390.6c-2,2-5.4,2-7.6,0L9,314.8c-2-2-2-5.4,0-7.6L183.3,133	
              C185.3,130.9,188.7,130.9,190.9,133z"
            />
          </g>
        </svg>
        <br />
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  })
);

export default LoginCallback;

import { keyPairData } from ".";

export default class assessmentDetails {
  public static formatForSubmission(assessmentData: { [key: string]: any }): keyPairData[] {
    const formattedData: keyPairData[] = [];

    Object.keys(assessmentData).forEach((key: string) => {
      formattedData.push({
        name: key,
        value: assessmentData[key].toString(),
      });
    });

    return formattedData;
  }
}

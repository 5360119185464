import React, { Component, ReactNode } from "react";
import { Button, Typography, FormGroup, Grid, FormControl, TextField, Modal, Backdrop, Slide, Checkbox, FormControlLabel } from "@material-ui/core";
import { dataStorePageKeys } from "../../stores/dataStore";
import { inject, observer } from "mobx-react";
import { Root } from "../../stores";
import LocalHospitalOutlined from "@material-ui/icons/LocalHospitalOutlined";
import apiService from "../../services/ApiService";
import { LoadingIndicator } from "../../components";

interface InTestMedicalClearanceModalProps {
  rootStore?: Root;
  pageKey: dataStorePageKeys;
  pefaID: string;
  firstName: string;
  lastName: string;
  medicalPractitioner: string;
  medicalPractice: string;
  handleSaveInTestMedical?: any;
}

interface InTestMedicalClearanceModalState {
  isModalOpen: boolean;
  extraMCNotesText: string;
  reasonText: string;
  selectedKey: string;
  enableNext: boolean;
  clearanceDownloaded: boolean;
  isUploading: boolean;
}

@inject("rootStore")
@observer
class InTestMedicalClearanceModal extends Component<InTestMedicalClearanceModalProps, InTestMedicalClearanceModalState> {
  constructor(props: InTestMedicalClearanceModalProps) {
    super(props);
    const prevData = props.rootStore?.dataStore.getData(this.props.pefaID, dataStorePageKeys.IN_TEST_MEDICAL_CLEARANCE);
    this.state = {
      isModalOpen: false,
      isUploading: false,
      extraMCNotesText: prevData ? prevData.extraMCNotesText : "",
      reasonText: prevData ? prevData.reasonText : "",
      enableNext: prevData ? prevData.enableNext : false,
      clearanceDownloaded: prevData ? prevData.clearanceDownloaded : false,
      selectedKey: prevData ? prevData.selectedKey : this.props.pageKey,
    };
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleSaveNote = this.handleSaveNote.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.handleEnableNext = this.handleEnableNext.bind(this);
  }
  handleCloseModal(): void {
    if ((!!this.state.extraMCNotesText && !!this.state.reasonText) || (this.state.extraMCNotesText === "" && this.state.reasonText === "")) {
      this.setState((prevState) => ({
        ...prevState,
        isModalOpen: false,
      }));
    }
  }

  handleOpenModal(): void {
    const prevData = this.props.rootStore?.dataStore.getData(this.props.pefaID, dataStorePageKeys.IN_TEST_MEDICAL_CLEARANCE);
    this.setState((prevState) => ({
      ...prevState,
      extraMCNotesText: prevData ? prevData.extraMCNotesText : "",
      reasonText: prevData ? prevData.reasonText : "",
      isModalOpen: true,
    }));
  }
  async handleDownload(): Promise<void> {
    //prepare the final list in Dictionary<string, string> format
    let requestData = {};
    requestData = { ...requestData, ["Suspected Injury"]: this.state.extraMCNotesText };

    this.setState((prevState) => ({ ...prevState, isUploading: true }));
    await apiService.getEndPEFAMedicalClearanceDocument(
      Number(this.props.pefaID),
      `MedicalClearance - ${this.props.firstName + " " + this.props.lastName}.docx`,
      requestData,
      this.props.medicalPractitioner,
      this.props.medicalPractice
    );
    this.props.rootStore?.dataStore.savePartialData(this.state, this.props.pefaID, dataStorePageKeys.IN_TEST_MEDICAL_CLEARANCE);
    this.setState((prevState) => ({ ...prevState, clearanceDownloaded: true, isUploading: false }));
  }

  handleSaveNote(): void {
    if ((!!this.state.extraMCNotesText && !!this.state.reasonText) || (this.state.extraMCNotesText === "" && this.state.reasonText === "")) {
      this.setState(
        (prevState) => ({
          ...prevState,
          enableNext: false,
          clearanceDownloaded: false,
        }),
        () => {
          this.props.rootStore?.dataStore.savePartialData(this.state, this.props.pefaID, dataStorePageKeys.IN_TEST_MEDICAL_CLEARANCE);
          if (this.props.handleSaveInTestMedical) {
            this.props.handleSaveInTestMedical(false, this.state.extraMCNotesText, this.state.reasonText);
          }
        }
      );
      this.handleCloseModal();
    }
  }

  handleChange(event: any): void {
    event.persist();
    this.setState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
      enableNext: false,
      clearanceDownloaded: false,
    }));
  }
  handleClear(): void {
    this.setState((prevState) => ({
      ...prevState,
      extraMCNotesText: "",
      reasonText: "",
      enableNext: false,
      clearanceDownloaded: false,
    }));
  }
  handleEnableNext(event: any): void {
    this.setState(
      (prevState) => ({
        ...prevState,
        enableNext: event.target.checked,
      }),
      () => {
        this.props.rootStore?.dataStore.savePartialData(this.state, this.props.pefaID, dataStorePageKeys.IN_TEST_MEDICAL_CLEARANCE);
        if (this.props.handleSaveInTestMedical) {
          this.props.handleSaveInTestMedical(event.target.checked, this.state.extraMCNotesText, this.state.reasonText);
        }
      }
    );
  }
  render(): ReactNode {
    const { isModalOpen, extraMCNotesText, reasonText } = this.state;
    return (
      <>
        <LoadingIndicator visible={this.state.isUploading} />
        <Button variant="outlined" className="extraNotesBtn" onClick={this.handleOpenModal}>
          <LocalHospitalOutlined style={{ color: "#1CBEAF", marginBottom: "-5px", marginRight: "5px" }} />
          In-test Medical Clearance&nbsp;
        </Button>
        {this.props.pageKey === dataStorePageKeys.PREVIEW_RESULTS && extraMCNotesText !== "" && reasonText !== "" ? (
          <>
            <Button variant="outlined" className="extraNotesBtn" onClick={this.handleDownload}>
              Download In-test MC&nbsp;
            </Button>
            <div className="agreeDownload">
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<span className="checkboxIcon require-enableEndPEFA"></span>}
                    checkedIcon={<span className="checkboxIconChecked"></span>}
                    color="primary"
                    className="mc-checkbox"
                    name="enableNext"
                    checked={this.state.enableNext}
                    disabled={!this.state.clearanceDownloaded}
                    onChange={this.handleEnableNext}
                  />
                }
                label="In-test MC downloaded, viewed and ready to send"
                className="in-testmc-chk"
              />
              {!this.state.enableNext ? (
                <div className="invalid-intest">Please indicate that you have viewed Medical clearance letter and confirmed that it is ready to send</div>
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
        <Modal
          aria-labelledby="modal-title"
          open={isModalOpen}
          onClose={this.handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Slide direction="up" in={isModalOpen} mountOnEnter unmountOnExit>
            <div className="modalPopNotes">
              <Button className="hideBtn" onClick={this.handleCloseModal}>
                Close
              </Button>
              <div className="InTestMedicalClearanceModalText">
                <Typography variant="h2" className="notesHeader">
                  <LocalHospitalOutlined style={{ color: "#FFFFFF", marginBottom: "-5px" }} />
                  MC Notes
                </Typography>
                <FormGroup className="formGroupOveride">
                  <Grid xs={12} item container className="StrengthTestContainer">
                    <FormControl component="fieldset" className="fieldsetStrength">
                      <Typography variant="caption" className="pastNotesHeader">
                        My Extra MC Notes (Will be displayed on Medical Clearance Letter)
                      </Typography>
                      <FormGroup className="formGroupOveride">
                        <TextField
                          id="extraMCNotes"
                          name="extraMCNotesText"
                          multiline
                          fullWidth
                          variant="outlined"
                          className="outlineTextArea"
                          value={extraMCNotesText}
                          onChange={this.handleChange}
                        />
                        {extraMCNotesText === "" && reasonText !== "" ? (
                          <div className="invalid-feedback textInputMarginLeft">
                            <span>Extra MC Notes is required when Reason is populated.</span>
                          </div>
                        ) : (
                          <></>
                        )}
                      </FormGroup>
                      <Typography variant="caption" className="pastNotesHeader">
                        Reason (Will be saved in your Results)
                      </Typography>
                      <FormGroup className="formGroupOveride">
                        <TextField
                          id="mcReason"
                          name="reasonText"
                          multiline
                          fullWidth
                          variant="outlined"
                          className="outlineTextArea"
                          value={reasonText}
                          onChange={this.handleChange}
                        />
                        {extraMCNotesText !== "" && reasonText === "" ? (
                          <div className="invalid-feedback textInputMarginLeft">
                            <span>Reason is required when Extra MC Notes is populated.</span>
                          </div>
                        ) : (
                          <></>
                        )}
                      </FormGroup>
                      <div className="intest-mc">
                        <Button onClick={this.handleClear} color="primary" variant="outlined">
                          Clear
                        </Button>
                        <Button onClick={this.handleSaveNote} color="primary" variant="contained">
                          Save MC Note
                        </Button>
                      </div>
                    </FormControl>
                  </Grid>
                </FormGroup>
              </div>
            </div>
          </Slide>
        </Modal>
      </>
    );
  }
}

export default InTestMedicalClearanceModal;

import * as yup from "yup";
import { limitingFactors } from "../../../components/groupedComponents/MobilityTestDataKeys";

const musculoskeletalValidation = yup.object().shape({
  trunkMobility: yup.bool().test("trunkMobility", "Please provide feedback on trunk mobility.", function () {
    let valid = false;

    if (
      !valid &&
      !this.parent.trunkMobilityPainFree &&
      (!this.parent.bendForward_unable ||
        !this.parent.bendForward_lessThanOneThird ||
        !this.parent.bendForward_lessThanTwoThirds ||
        !this.parent.bendForward_greaterThanTwoThirds) &&
      !this.parent.bendForward_objectiveRange &&
      this.parent.bendForward_limitingFactor === "SELECT" &&
      !this.parent.bendForward_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.trunkMobilityPainFree &&
      (!this.parent.bendBackward_unable ||
        !this.parent.bendBackward_lessThanOneThird ||
        !this.parent.bendBackward_lessThanTwoThirds ||
        !this.parent.bendBackward_greaterThanTwoThirds) &&
      !this.parent.bendBackward_objectiveRange &&
      this.parent.bendBackward_limitingFactor === "SELECT" &&
      !this.parent.bendBackward_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.trunkMobilityPainFree &&
      (!this.parent.rotateLeft_unable || !this.parent.rotateLeft_lessThanOneThird || !this.parent.rotateLeft_lessThanTwoThirds || !this.parent.rotateLeft_greaterThanTwoThirds) &&
      !this.parent.rotateLeft_objectiveRange &&
      this.parent.rotateLeft_limitingFactor === "SELECT" &&
      !this.parent.rotateLeft_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.trunkMobilityPainFree &&
      (!this.parent.rotateRight_unable ||
        !this.parent.rotateRight_lessThanOneThird ||
        !this.parent.rotateRight_lessThanTwoThirds ||
        !this.parent.rotateRight_greaterThanTwoThirds) &&
      !this.parent.rotateRight_objectiveRange &&
      this.parent.rotateRight_limitingFactor === "SELECT" &&
      !this.parent.rotateRight_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    return valid;
  }),
  squatFullPainFree: yup.bool().test("squatFullPainFree", "Please provide feedback on squatting.", function () {
    let valid = false;

    if (
      !valid &&
      !this.parent.squatFullPainFree &&
      (!this.parent.squatFull_unable || !this.parent.squatFull_lessThanOneThird || !this.parent.squatFull_lessThanTwoThirds || !this.parent.squatFull_greaterThanTwoThirds) &&
      !this.parent.squatFull_objectiveRange &&
      this.parent.squatFull_limitingFactor === "SELECT" &&
      !this.parent.squatFull_limitingFactorNotes
    ) {
      valid = false;
    } else valid = true;

    if (
      !valid &&
      !this.parent.squatFullPainFree &&
      !this.parent.squatFullWeightBearingRatioSymmetrical &&
      !this.parent.squatFullWeightBearingRatioAsymmetrical &&
      !this.parent.squatFullNoSupportRequired &&
      !this.parent.squatFullSupportToLower &&
      !this.parent.squatFullSupportToRise
    ) {
      valid = false;
    } else valid = true;

    return valid;
  }),
  bendForward: yup.bool().test("bendForward", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      trunkMobilityPainFree,
      bendForward_unable,
      bendForward_lessThanOneThird,
      bendForward_lessThanTwoThirds,
      bendForward_greaterThanTwoThirds,
      bendForward_objectiveRange,
      bendForward_limitingFactor,
      bendForward_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (trunkMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (bendForward_unable || bendForward_lessThanOneThird || bendForward_lessThanTwoThirds || bendForward_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!bendForward_objectiveRange ||
        //or the limiting factor has not been selected,
        bendForward_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((bendForward_limitingFactor === "MUSCLETIGHTNESS" || bendForward_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !bendForward_limitingFactorNotes))
    ) {
      //then consider the bend forward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are set
      (!!bendForward_objectiveRange || bendForward_limitingFactor !== "SELECT" || !!bendForward_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !bendForward_unable &&
      !bendForward_lessThanOneThird &&
      !bendForward_lessThanTwoThirds &&
      !bendForward_greaterThanTwoThirds
    ) {
      //then the bendForward fields are not valid.
      return false;
    }

    return true;
  }),
  bendBackward: yup.bool().test("bendBackward", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      trunkMobilityPainFree,
      bendBackward_unable,
      bendBackward_lessThanOneThird,
      bendBackward_lessThanTwoThirds,
      bendBackward_greaterThanTwoThirds,
      bendBackward_objectiveRange,
      bendBackward_limitingFactor,
      bendBackward_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (trunkMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (bendBackward_unable || bendBackward_lessThanOneThird || bendBackward_lessThanTwoThirds || bendBackward_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!bendBackward_objectiveRange ||
        //or the limiting factor has not been selected,
        bendBackward_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((bendBackward_limitingFactor === "MUSCLETIGHTNESS" || bendBackward_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !bendBackward_limitingFactorNotes))
    ) {
      //then consider the bendBackward data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are unset
      (!!bendBackward_objectiveRange || bendBackward_limitingFactor !== "SELECT" || !!bendBackward_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !bendBackward_unable &&
      !bendBackward_lessThanOneThird &&
      !bendBackward_lessThanTwoThirds &&
      !bendBackward_greaterThanTwoThirds
    ) {
      //then the bendBackward fields are not valid.
      return false;
    }

    return true;
  }),

  rotateLeft: yup.bool().test("rotateLeft", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      trunkMobilityPainFree,
      rotateLeft_unable,
      rotateLeft_lessThanOneThird,
      rotateLeft_lessThanTwoThirds,
      rotateLeft_greaterThanTwoThirds,
      rotateLeft_objectiveRange,
      rotateLeft_limitingFactor,
      rotateLeft_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (trunkMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (rotateLeft_unable || rotateLeft_lessThanOneThird || rotateLeft_lessThanTwoThirds || rotateLeft_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!rotateLeft_objectiveRange ||
        //or the limiting factor has not been selected,
        rotateLeft_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((rotateLeft_limitingFactor === "MUSCLETIGHTNESS" || rotateLeft_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !rotateLeft_limitingFactorNotes))
    ) {
      //then consider the rotateLeft data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are unset
      (!!rotateLeft_objectiveRange || rotateLeft_limitingFactor !== "SELECT" || !!rotateLeft_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !rotateLeft_unable &&
      !rotateLeft_lessThanOneThird &&
      !rotateLeft_lessThanTwoThirds &&
      !rotateLeft_greaterThanTwoThirds
    ) {
      //then the rotateLeft fields are not valid.
      return false;
    }

    return true;
  }),
  rotateRight: yup.bool().test("rotateRight", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      trunkMobilityPainFree,
      rotateRight_unable,
      rotateRight_lessThanOneThird,
      rotateRight_lessThanTwoThirds,
      rotateRight_greaterThanTwoThirds,
      rotateRight_objectiveRange,
      rotateRight_limitingFactor,
      rotateRight_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (trunkMobilityPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (rotateRight_unable || rotateRight_lessThanOneThird || rotateRight_lessThanTwoThirds || rotateRight_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!rotateRight_objectiveRange ||
        //or the limiting factor has not been selected,
        rotateRight_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((rotateRight_limitingFactor === "MUSCLETIGHTNESS" || rotateRight_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !rotateRight_limitingFactorNotes))
    ) {
      //then consider the rotateRight data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are unset
      (!!rotateRight_objectiveRange || rotateRight_limitingFactor !== "SELECT" || !!rotateRight_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !rotateRight_unable &&
      !rotateRight_lessThanOneThird &&
      !rotateRight_lessThanTwoThirds &&
      !rotateRight_greaterThanTwoThirds
    ) {
      //then the rotateRight fields are not valid.
      return false;
    }

    return true;
  }),
  squatFull: yup.bool().test("squatFull", "Please provide the objective range, limiting factor and appropriate checkbox.", function () {
    const {
      squatFullPainFree,
      squatFull_unable,
      squatFull_lessThanOneThird,
      squatFull_lessThanTwoThirds,
      squatFull_greaterThanTwoThirds,
      squatFull_objectiveRange,
      squatFull_limitingFactor,
      squatFull_limitingFactorNotes,
    } = this.parent;

    //If the accordion checkbox is ticked, this is "valid".
    if (squatFullPainFree) return true;

    if (
      //If a checkbox has been ticked and
      (squatFull_unable || squatFull_lessThanOneThird || squatFull_lessThanTwoThirds || squatFull_greaterThanTwoThirds) &&
      //the objective range is empty,
      (!squatFull_objectiveRange ||
        //or the limiting factor has not been selected,
        squatFull_limitingFactor === "SELECT" ||
        //or a limiting factor has been selected and
        ((squatFull_limitingFactor === "MUSCLETIGHTNESS" || squatFull_limitingFactor === "JOINTSTIFFNESS") &&
          //no notes have been provided,
          !squatFull_limitingFactorNotes))
    ) {
      //then consider the squatFull data invalid.
      return false;
    }

    if (
      //If the objective range, limiting factor or limiting factor notes are unset
      (!!squatFull_objectiveRange || squatFull_limitingFactor !== "SELECT" || !!squatFull_limitingFactorNotes) &&
      //and the checkboxes are not set,
      !squatFull_unable &&
      !squatFull_lessThanOneThird &&
      !squatFull_lessThanTwoThirds &&
      !squatFull_greaterThanTwoThirds
    ) {
      //then the squatFull fields are not valid.
      return false;
    }

    return true;
  }),
  bendForward_location: yup.string().test("bendForward_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.bendForward_limitingFactor === limitingFactors.PAIN && this.parent.bendForward_location === "") {
      return false;
    }

    return true;
  }),
  bendBackward_location: yup.string().test("bendBackward_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.bendBackward_limitingFactor === limitingFactors.PAIN && this.parent.bendBackward_location === "") {
      return false;
    }

    return true;
  }),
  rotateLeft_location: yup.string().test("rotateLeft_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.rotateLeft_limitingFactor === limitingFactors.PAIN && this.parent.rotateLeft_location === "") {
      return false;
    }

    return true;
  }),
  rotateRight_location: yup.string().test("rotateRight_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.rotateRight_limitingFactor === limitingFactors.PAIN && this.parent.rotateRight_location === "") {
      return false;
    }

    return true;
  }),
  squatFull_location: yup.string().test("squatFull_location", "Please provide the location of the discomfort.", function () {
    if (this.parent.squatFull_limitingFactor === limitingFactors.PAIN && this.parent.squatFull_location === "") {
      return false;
    }

    return true;
  }),
  bendForward_intensity: yup.bool().test("bendForward_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.bendForward_limitingFactor === limitingFactors.PAIN &&
      this.parent.bendForward_intensityVas === "" &&
      this.parent.bendForward_intensityMild === false &&
      this.parent.bendForward_intensityMod === false &&
      this.parent.bendForward_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),
  bendBackward_intensity: yup.bool().test("bendBackward_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.bendBackward_limitingFactor === limitingFactors.PAIN &&
      this.parent.bendBackward_intensityVas === "" &&
      this.parent.bendBackward_intensityMild === false &&
      this.parent.bendBackward_intensityMod === false &&
      this.parent.bendBackward_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),
  rotateLeft_intensity: yup.bool().test("rotateLeft_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.rotateLeft_limitingFactor === limitingFactors.PAIN &&
      this.parent.rotateLeft_intensityVas === "" &&
      this.parent.rotateLeft_intensityMild === false &&
      this.parent.rotateLeft_intensityMod === false &&
      this.parent.rotateLeft_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),
  rotateRight_intensity: yup.bool().test("rotateRight_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.rotateRight_limitingFactor === limitingFactors.PAIN &&
      this.parent.rotateRight_intensityVas === "" &&
      this.parent.rotateRight_intensityMild === false &&
      this.parent.rotateRight_intensityMod === false &&
      this.parent.rotateRight_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),
  squatFull_intensity: yup.bool().test("squatFull_intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.squatFull_limitingFactor === limitingFactors.PAIN &&
      this.parent.squatFull_intensityVas === "" &&
      this.parent.squatFull_intensityMild === false &&
      this.parent.squatFull_intensityMod === false &&
      this.parent.squatFull_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),
  bendForward_type: yup.bool().test("bendForward_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.bendForward_limitingFactor === limitingFactors.PAIN &&
      this.parent.bendForward_discomfortTypeAche === false &&
      this.parent.bendForward_discomfortTypeSharp === false &&
      this.parent.bendForward_discomfortTypeBurning === false &&
      this.parent.bendForward_discomfortTypeTightness === false &&
      this.parent.bendForward_discomfortTypePulling === false &&
      this.parent.bendForward_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),
  bendBackward_type: yup.bool().test("bendBackward_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.bendBackward_limitingFactor === limitingFactors.PAIN &&
      this.parent.bendBackward_discomfortTypeAche === false &&
      this.parent.bendBackward_discomfortTypeSharp === false &&
      this.parent.bendBackward_discomfortTypeBurning === false &&
      this.parent.bendBackward_discomfortTypeTightness === false &&
      this.parent.bendBackward_discomfortTypePulling === false &&
      this.parent.bendBackward_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),
  rotateLeft_type: yup.bool().test("rotateLeft_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.rotateLeft_limitingFactor === limitingFactors.PAIN &&
      this.parent.rotateLeft_discomfortTypeAche === false &&
      this.parent.rotateLeft_discomfortTypeSharp === false &&
      this.parent.rotateLeft_discomfortTypeBurning === false &&
      this.parent.rotateLeft_discomfortTypeTightness === false &&
      this.parent.rotateLeft_discomfortTypePulling === false &&
      this.parent.rotateLeft_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),
  rotateRight_type: yup.bool().test("rotateRight_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.rotateRight_limitingFactor === limitingFactors.PAIN &&
      this.parent.rotateRight_discomfortTypeAche === false &&
      this.parent.rotateRight_discomfortTypeSharp === false &&
      this.parent.rotateRight_discomfortTypeBurning === false &&
      this.parent.rotateRight_discomfortTypeTightness === false &&
      this.parent.rotateRight_discomfortTypePulling === false &&
      this.parent.rotateRight_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),
  squatFull_type: yup.bool().test("squatFull_type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.squatFull_limitingFactor === limitingFactors.PAIN &&
      this.parent.squatFull_discomfortTypeAche === false &&
      this.parent.squatFull_discomfortTypeSharp === false &&
      this.parent.squatFull_discomfortTypeBurning === false &&
      this.parent.squatFull_discomfortTypeTightness === false &&
      this.parent.squatFull_discomfortTypePulling === false &&
      this.parent.squatFull_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),
  bendForward_typeOther: yup.string().test("bendForward_typeOther", "Please describe the discomfort further.", function () {
    if (this.parent.bendForward_limitingFactor === limitingFactors.PAIN && this.parent.bendForward_discomfortTypeOther === true && this.parent.bendForward_typeOther === "") {
      return false;
    }

    return true;
  }),
  bendBackward_typeOther: yup.string().test("bendBackward_typeOther", "Please describe the discomfort further.", function () {
    if (this.parent.bendBackward_limitingFactor === limitingFactors.PAIN && this.parent.bendBackward_discomfortTypeOther === true && this.parent.bendBackward_typeOther === "") {
      return false;
    }

    return true;
  }),
  rotateLeft_typeOther: yup.string().test("rotateLeft_typeOther", "Please describe the discomfort further.", function () {
    if (this.parent.rotateLeft_limitingFactor === limitingFactors.PAIN && this.parent.rotateLeft_discomfortTypeOther === true && this.parent.rotateLeft_typeOther === "") {
      return false;
    }

    return true;
  }),
  rotateRight_typeOther: yup.string().test("rotateRight_typeOther", "Please describe the discomfort further.", function () {
    if (this.parent.rotateRight_limitingFactor === limitingFactors.PAIN && this.parent.rotateRight_discomfortTypeOther === true && this.parent.rotateRight_typeOther === "") {
      return false;
    }

    return true;
  }),
  squatFull_typeOther: yup.string().test("squatFull_typeOther", "Please describe the discomfort further.", function () {
    if (this.parent.squatFull_limitingFactor === limitingFactors.PAIN && this.parent.squatFull_discomfortTypeOther === true && this.parent.squatFull_typeOther === "") {
      return false;
    }

    return true;
  }),
  bendForward_status: yup.bool().test("bendForward_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.bendForward_limitingFactor === limitingFactors.PAIN &&
      this.parent.bendForward_discomfortResolvedImmediately === false &&
      this.parent.bendForward_discomfortPersisting === false &&
      this.parent.bendForward_discomfortResolvedWithRest === false &&
      this.parent.bendForward_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  bendBackward_status: yup.bool().test("bendBackward_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.bendBackward_limitingFactor === limitingFactors.PAIN &&
      this.parent.bendBackward_discomfortResolvedImmediately === false &&
      this.parent.bendBackward_discomfortPersisting === false &&
      this.parent.bendBackward_discomfortResolvedWithRest === false &&
      this.parent.bendBackward_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  rotateLeft_status: yup.bool().test("rotateLeft_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.rotateLeft_limitingFactor === limitingFactors.PAIN &&
      this.parent.rotateLeft_discomfortResolvedImmediately === false &&
      this.parent.rotateLeft_discomfortPersisting === false &&
      this.parent.rotateLeft_discomfortResolvedWithRest === false &&
      this.parent.rotateLeft_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  rotateRight_status: yup.bool().test("rotateRight_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.rotateRight_limitingFactor === limitingFactors.PAIN &&
      this.parent.rotateRight_discomfortResolvedImmediately === false &&
      this.parent.rotateRight_discomfortPersisting === false &&
      this.parent.rotateRight_discomfortResolvedWithRest === false &&
      this.parent.rotateRight_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
  squatFull_status: yup.bool().test("squatFull_status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.squatFull_limitingFactor === limitingFactors.PAIN &&
      this.parent.squatFull_discomfortResolvedImmediately === false &&
      this.parent.squatFull_discomfortPersisting === false &&
      this.parent.squatFull_discomfortResolvedWithRest === false &&
      this.parent.squatFull_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
});

export default musculoskeletalValidation;

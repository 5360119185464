import * as yup from "yup";

const informedConsentSchema = yup.object().shape({
  participantName: yup.string().required("A participant name is required."),
  participantSignature: yup.string().required("A participant signature is required."),
  witnessName: yup.string().required("A witness name is required."),
  witnessSignature: yup.string().required("A witness signature is required."),
  personResponsibleName: yup.string().when("age", {
    is: (age) => age < 18,
    then: yup.string().required("The person responsible's name is required."),
  }),
  address: yup.string().when("age", {
    is: (age) => age < 18,
    then: yup.string().required("The person responsible's address is required."),
  }),
  phoneNumber: yup.string().when("age", {
    is: (age) => age < 18,
    then: yup.string().required("The person responsible's phone number is required."),
  }),
  personResponsibleSignature: yup.string().when("age", {
    is: (age) => age < 18,
    then: yup.string().required("The person responsible's signature is required."),
  }),
});

export default informedConsentSchema;

import { keyPairData } from ".";

export default class medicalDetails {
  public static formatForSubmission(medicalDetails: { [key: string]: any }): keyPairData[] {
    const formattedData: keyPairData[] = [];

    Object.keys(medicalDetails).forEach((key: string) => {
      formattedData.push({
        name: key,
        value: medicalDetails[key]?.toString(),
      });
    });

    return formattedData;
  }
}

import { applySnapshot, types, flow } from "mobx-state-tree";
import apiService from "../services/ApiService";
import { addDays, compareAsc, format, subDays } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { dataStorePageKeys } from "./dataStore";
import {
  PTKNEEL,
  PTREACHFORWARD,
  PTREACHABOVE,
  PTSTOOP,
  PTSQUAT,
  FITNESSTEST,
  MHBENCHTOFLOOR,
  MHBENCHTOSHOULDER,
  MHBILATERALCARRY,
  MHSINGLECARRYLEFT,
  MHSINGLECARRYRIGHT,
  DTLADDERCLIMBING,
  DTCRAWLING,
  MHBENCHTOBENCH,
  DTREACHSIDETOSIDE,
  MHBENCHTOABOVESHOULDER,
  DASHBOARD,
  PROGRAMRECOMMENDATIONS,
  FINALBPREADING,
} from "../routes/paths";

export enum pefaStoreStates {
  "PENDING" = "PENDING",
  "EMPTY" = "EMPTY",
  "DONE" = "DONE",
  "ERROR" = "ERROR",
}

export enum filteredPeriodKeys {
  "TODAY" = "TODAY",
  "TOMORROW" = "TOMORROW",
  "THREEDAYS" = "THREEDAYS",
  "SEVENDAYS" = "SEVENDAYS",
}

export interface demand {
  key: keyof typeof dataStorePageKeys;
  frequency: string;
  value: number;
  proceduralOrder: number;
  functionalOrder: number;
  pageKey: string;
  testID: number | null;
}

export interface demandParticulars {
  key: dataStorePageKeys;
  pageKey: string;
  proceduralOrder: number;
}

const demandTypes: { [key: number]: demandParticulars } = {
  1: {
    key: dataStorePageKeys.PT_KNEEL,
    pageKey: PTKNEEL,
    proceduralOrder: 6,
  },
  2: {
    key: dataStorePageKeys.PT_FORWARD,
    pageKey: PTREACHFORWARD,
    proceduralOrder: 2,
  },
  3: {
    key: dataStorePageKeys.PT_ABOVE,
    pageKey: PTREACHABOVE,
    proceduralOrder: 3,
  },
  4: {
    key: dataStorePageKeys.PT_STOOP,
    pageKey: PTSTOOP,
    proceduralOrder: 4,
  },
  5: {
    key: dataStorePageKeys.PT_SQUAT,
    pageKey: PTSQUAT,
    proceduralOrder: 5,
  },
  6: {
    key: dataStorePageKeys.FITNESS,
    pageKey: FITNESSTEST,
    proceduralOrder: 1,
  },
  7: {
    key: dataStorePageKeys.MH_BENCH_TO_FLOOR,
    pageKey: MHBENCHTOFLOOR,
    proceduralOrder: 10,
  },
  8: {
    key: dataStorePageKeys.MH_BENCH_TO_SHOULDER,
    pageKey: MHBENCHTOSHOULDER,
    proceduralOrder: 12,
  },
  9: {
    key: dataStorePageKeys.MH_BILATERAL,
    pageKey: MHBILATERALCARRY,
    proceduralOrder: 14,
  },
  10: {
    key: dataStorePageKeys.MH_SINGLE_L,
    pageKey: MHSINGLECARRYLEFT,
    proceduralOrder: 15,
  },
  11: {
    key: dataStorePageKeys.MH_SINGLE_R,
    pageKey: MHSINGLECARRYRIGHT,
    proceduralOrder: 16,
  },
  12: {
    key: dataStorePageKeys.DT_LADDER,
    pageKey: DTLADDERCLIMBING,
    proceduralOrder: 9,
  },
  13: {
    key: dataStorePageKeys.DT_CRAWL,
    pageKey: DTCRAWLING,
    proceduralOrder: 8,
  },
  14: {
    key: dataStorePageKeys.MH_BENCH_TO_BENCH,
    pageKey: MHBENCHTOBENCH,
    proceduralOrder: 11,
  },
  15: {
    key: dataStorePageKeys.DT_SIDE,
    pageKey: DTREACHSIDETOSIDE,
    proceduralOrder: 7,
  },
  18: {
    key: dataStorePageKeys.MH_BENCH_TO_ABOVE,
    pageKey: MHBENCHTOABOVESHOULDER,
    proceduralOrder: 13,
  },
};

const PefaStore = types
  .model("PefaStore", {
    pefas: types.array(types.frozen()),
    status: types.enumeration<pefaStoreStates>(Object.values(pefaStoreStates)),
    selectedPefa: types.optional(types.frozen(), undefined),
  })
  .actions((self) => {
    /**
     * Calls the api service, which in turn retrieves any PEFAs in the period between
     * the last 3 and next 3 days.
     */
    const fetchPefas = flow(function* fetchPefas() {
      try {
        const now = new Date();

        const start = new Date(format(subDays(now, 3), "yyyy/MM/dd 00:00:00"));
        const end = new Date(format(addDays(now, 7), "yyyy/MM/dd 23:59:59"));

        //update MST to indicate that we are currently updating the pefaStore.
        applySnapshot(self, { ...self, status: pefaStoreStates.PENDING });

        //call the API via the apiService asynchronously for the given period.
        const response = yield apiService.getPefas(start.toISOString(), end.toISOString());
        const data = response.data;

        //update MST with the returned PEFAs if any came back, then update the state to
        //reflect the current data status. If pefas were returned, DONE, if none came back,
        //set the pefaStore state to EMPTY.
        applySnapshot(self, {
          ...self,
          pefas: data,
          //status: data && data.length >= 1 ? pefaStoreStates.DONE : pefaStoreStates.EMPTY,
        });
      } catch (e) {
        applySnapshot(self, { ...self, status: pefaStoreStates.ERROR });
        console.error("error: ", e);
      }
    });

    /**
     * Selects a PEFA from the list of available PEFAs, building the assessment flow based on the
     * PEFA's tests and demands.
     * @param pefaID the PEFA's unique id from console.
     */
    const selectPefa = async function selectPefa(pefaID: number) {
      const selectedPefa: any = self.pefas.filter((pefa) => {
        if (pefa.id === pefaID) return pefa;
      });

      //jobDemands is the raw list of demands provided as part of the pefa requirements.
      const jobDemands: any[] = selectedPefa[0].pefArequest.pefaJob.demands;

      //tests is the raw list of tests to be completed.
      const tests: any[] = selectedPefa[0].tests;

      //demands is the final demands object which is used throughout the PEFA for retrieving the next test and the test requirements.
      const demands: {
        [key: string]: demand;
      } = {};

      //demandsList is used as a marshalling list in order to correctly build the final demands object.
      const demandsList: demand[] = [];

      //call the API to get the latest demand
      const response = await apiService.getLatestDemands(selectedPefa[0].pefArequestID);

      //Go through each of the raw demands and transform them into our own demands list.
      tests.forEach((test: any) => {
        let demand: any;

        jobDemands.forEach((jobDemand) => {
          // apply latest job demand
          const latestDemand = response.filter((x) => x.demandType.name === jobDemand.demandType.name);
          if (latestDemand !== null && latestDemand.length > 0) {
            jobDemand.value = latestDemand[0].value;
            jobDemand.frequency = latestDemand[0].frequency;
          }
          if (Number(jobDemand.demandType.id) === Number(test.demandTypeID)) {
            //Check if the demand is for a manual handling test. If it is, only allow "O" frequency demands.
            if (jobDemand.demandType.type === "MH" && jobDemand.frequency.toString().toUpperCase() !== "O") {
              //if we aren't dealing with an "O" frequency demand, continue onto the next demand in the set.
              return;
            }

            demand = jobDemand;
          }
        });

        // ignore NT and MH with frequency F and C
        if (
          !demand ||
          (demand && demand.frequency === "NT") ||
          (demand && demand.demandType.type === "MH" && demand.frequency === "F") ||
          (demand && demand.demandType.type === "MH" && demand.frequency === "C")
        ) {
          return;
        }

        if (demandTypes.hasOwnProperty(demand.demandType.id)) {
          demandsList.push({
            key: demandTypes[demand.demandType.id].key,
            frequency: demand.frequency,
            value: demand.value,
            proceduralOrder: demandTypes[demand.demandType.id].proceduralOrder,
            functionalOrder: -1,
            pageKey: demandTypes[demand.demandType.id].pageKey,
            testID: test.id,
          });
        }
      });

      //Sort the list so that procedurally, they are in the correct order, not as they are sent in the raw list.
      demandsList.sort(function (demand1, demand2) {
        return demand1.proceduralOrder - demand2.proceduralOrder;
      });

      //Looping through our marshalling list of processed data, insert the demands into the final object with the correct functional order.
      let counter = 0;
      demandsList.forEach((demand: demand) => {
        demands[demand.key] = {
          ...demand,
          functionalOrder: counter,
        };

        counter++;
      });

      const ptDemands = demandsList.filter((x) => x.key.indexOf("PT_") > -1);
      let lastPTDemand: demand | undefined = undefined;
      if (ptDemands.length > 0) {
        lastPTDemand = ptDemands.pop();
        if (lastPTDemand) {
          lastPTDemand.functionalOrder = demands[lastPTDemand.key].functionalOrder;
        }
      }
      //get last DT demand before DT_Ladder
      const dtDemandsNotLadder = demandsList.filter((x) => x.key.indexOf("DT_") > -1 && x.key !== "DT_LADDER");

      //if there are no other DT demands, the last pt is lastPTDemand, otherwise its the last DTDemand
      let lastPTAndDTDemand: demand | undefined = undefined;
      lastPTAndDTDemand = dtDemandsNotLadder && dtDemandsNotLadder.length > 0 ? dtDemandsNotLadder[dtDemandsNotLadder.length - 1] : lastPTDemand;
      if (lastPTAndDTDemand) {
        lastPTAndDTDemand.functionalOrder = demands[lastPTAndDTDemand.key].functionalOrder;
      }
      applySnapshot(self, {
        ...self,
        selectedPefa: { ...selectedPefa, demands, lastPTAndDTDemand, dtDemandsNotLadder },
      });
    };

    /**
     * Allows for inserting a new/existing test after the current step.
     * @param currentStep The functional order of the current test. This is the page you are currently on.
     * @param subTestDatakey The substitute test data key. This represents the test you want to add.
     * @param isDuplicateTest Indicates if the test is a "duplicate" of an existing test, not an additional test. (default: false)
     * @returns the page key of the substitute test if it has been set.
     */
    function insertSubstituteTest(currentStep: number, subTestDatakey: dataStorePageKeys, isDuplicateTest = false) {
      //Get the demands and demand keys, the test index of the substitute test and a list of demandType keys.
      const demands = self.selectedPefa.demands;
      const demandKeys = Object.keys(demands);
      const substituteTestIndex = demandKeys.findIndex((demandKey) => demandKey === subTestDatakey);
      const demandTypeKeys = Object.keys(demandTypes);

      //If the substitute test has no matching index and we are not adding a duplicate test, then we need to add one.
      //OR if this is a duplicate test and the index DOES exist, then we want to perform the same action.
      //This prevents a case where the test is not included in the PEFA demands and is added unnecessarily because it is flagged as a duplicate.
      if ((substituteTestIndex === -1 && !isDuplicateTest) || (isDuplicateTest && substituteTestIndex !== -1)) {
        let modifiedDemandsList: { [key: number]: demand } = {};
        let pageKey = "";

        //loop through the demand keys,
        demandKeys.forEach((demandKey: string, demandIndex: number) => {
          const demand = self.selectedPefa.demands[demandKey];
          //Increase the functional order step counter for each step after the current step to make room.
          if (demand.functionalOrder > currentStep) {
            const modifiedDemand = {
              ...demand,
              functionalOrder: demandIndex + 1,
            };

            modifiedDemandsList = {
              ...modifiedDemandsList,
              [demand.key]: modifiedDemand,
            };

            //Otherwise, if we are on the current step, add the current step then create the new substitute demand step.
          } else if (demand.functionalOrder === currentStep) {
            //Add the current step to the modified demands list.
            modifiedDemandsList = {
              ...modifiedDemandsList,
              [demand.key]: { ...demand, functionalOrder: demandIndex },
            };

            let subTestParticulars: demandParticulars = {
              pageKey: DASHBOARD,
              key: dataStorePageKeys.ASSESSMENT_DATA,
              proceduralOrder: -1,
            };

            //loop over the demandType keys, retrieving the demandType particulars for the substitute test.
            demandTypeKeys.forEach((demandTypeKey: string) => {
              if (demandTypes[Number(demandTypeKey)].key === subTestDatakey) {
                subTestParticulars = demandTypes[Number(demandTypeKey)];
              }
            });

            //if a substitute test's particulars were found,
            if (!!subTestParticulars.pageKey) {
              //Build out the bulk of the substitute test's demands from the current test.
              const newDemand: demand = {
                key: subTestDatakey,
                frequency: demand.frequency,
                value: demand.value,
                proceduralOrder: subTestParticulars.proceduralOrder,
                functionalOrder: demandIndex + 1,
                pageKey: subTestParticulars.pageKey,
                testID: demand.testID,
              };

              //Store the page key for use later.
              pageKey = subTestParticulars.pageKey;

              modifiedDemandsList = {
                ...modifiedDemandsList,
                [subTestDatakey]: newDemand,
              };
            }
            //Otherwise, if we are not yet at the current step, push the demand into the demands list and go to the next step.
          } else {
            modifiedDemandsList = {
              ...modifiedDemandsList,
              [demand.key]: { ...demand, functionalOrder: demandIndex },
            };
          }
        });

        applySnapshot(self, {
          ...self,
          selectedPefa: { ...self.selectedPefa, demands: modifiedDemandsList },
        });

        //Finally, return the pageKey of the new next page.
        //Nothing that this can be null. Ensure that there is appropriate handling of this case.
        return pageKey;
      }
    }

    /**
     * Returns the next assessment test demand object immediately after the current one.
     * @param currentStep the current test index in the currently selected PEFA.
     * @returns the demand object for the next demand.
     */
    function getNextPefaStep(currentStep: number): demand {
      //get the next step index and the PEFA demands.
      const nextStep = currentStep + 1;
      const demandsList: Array<demand> = Object.values(self.selectedPefa.demands);

      //initialise the nextDemand object with the dashboard nav object name.
      let nextDemand: any = null;

      //if the next step has a matching demand, return it.
      demandsList.forEach((demand: demand) => {
        if (demand.functionalOrder === nextStep) {
          nextDemand = demand;
        }
      });

      //Otherwise, we can assume that we have finished working our way through the demands,
      //which means the next step is the program recommendations.
      if (!nextDemand) {
        nextDemand = {
          frequency: null,
          value: -1,
          proceduralOrder: 100,
          functionalOrder: nextStep,
          pageKey: PROGRAMRECOMMENDATIONS,
        };
      }

      return nextDemand;
    }
    /**
     * Returns the next assessment test demand object immediately after 3rd high bp.
     * @param stepToSkip the page key of the test that is being skipped.
     * @returns the demand object of the demand immediately after the skipped step.
     */
    function getNextStepAfterHighBP(stepToSkip: dataStorePageKeys): demand {
      //Get the PEFA demands, demand to skip and then use that to determine the next step's functional order.
      const demandsList: Array<demand> = Object.values(self.selectedPefa.demands);
      const demandToSkip: demand = self.selectedPefa.demands[stepToSkip];
      let nextStep;
      if (demandToSkip) {
        nextStep = demandToSkip.functionalOrder + 1;
      } else {
        nextStep = 0;
      }

      //initialise the nextDemand object with the dashboard nav object name.
      let nextDemand: any = null;

      //if the next step has a matching demand, return it
      //skip Fitness Test
      //however, if next demand is DT-Ladder or MH, next page should be the final bp reading
      demandsList.forEach((demand: demand) => {
        if (demand.functionalOrder === nextStep && (demand.key.indexOf("DT_LADDER") > -1 || demand.key.indexOf("MH_") > -1)) {
          nextDemand = {
            frequency: null,
            value: -1,
            proceduralOrder: 80,
            functionalOrder: nextStep,
            pageKey: FINALBPREADING,
          };
        }
        //PT test or DT crawl
        if (demand.functionalOrder === nextStep && demand.key.indexOf("DT_LADDER") < 0 && demand.key.indexOf("MH_") < 0) {
          nextDemand = demand;
        }
      });

      //Otherwise, we can assume that we have finished working our way through the demands,
      //which means the next step is the program recommendations.
      if (!nextDemand) {
        nextDemand = {
          frequency: null,
          value: -1,
          proceduralOrder: 100,
          functionalOrder: nextStep,
          pageKey: PROGRAMRECOMMENDATIONS,
        };
      }

      return nextDemand;
    }
    /**
     * Returns the next assessment test demand object immediately after 3rd high bp.
     * @param stepToSkip the page key of the test that is being skipped.
     * @returns the demand object of the demand immediately after the skipped step.
     */
    function getNextStepAfter4thLowBP(): demand {
      //Get the PEFA demands, demand to skip and then use that to determine the next step's functional order.
      const demandsList: Array<demand> = Object.values(self.selectedPefa.demands);

      //check if step test is in available demands, if not, skip the pt test go to next DT ladder
      const fitnessTest = demandsList.filter((x) => x.key === "FITNESS");
      if (fitnessTest && fitnessTest.length > 0) {
        return fitnessTest[0];
      }

      //continue checking for the next demand if step test is not available
      const ladderTest = demandsList.filter((x) => x.key === "DT_LADDER");
      if (ladderTest && ladderTest.length > 0) {
        return ladderTest[0];
      }

      const mhTest = demandsList.filter((x) => x.key.indexOf("MH_") > -1);
      if (mhTest && mhTest.length > 0) {
        // sort by functional order
        mhTest.sort(function (demand1, demand2) {
          return demand1.functionalOrder - demand2.functionalOrder;
        });
        return mhTest[0];
      }
      //Otherwise, we can assume that we have finished working our way through the demands,
      //which means the next step is the program recommendations.
      return {
        key: dataStorePageKeys.PROGRAM_RECOMMENDATIONS,
        testID: null,
        frequency: "",
        value: -1,
        proceduralOrder: 100,
        functionalOrder: 100,
        pageKey: PROGRAMRECOMMENDATIONS,
      };
    }

    function getNextStepAfterFitnessHighBPFlow(): demand {
      //Get the PEFA demands, demand to skip and then use that to determine the next step's functional order.
      const demandsList: Array<demand> = Object.values(self.selectedPefa.demands);
      const dtDemands = demandsList.filter((x) => x.key === "DT_LADDER");
      const mhDemands = self.selectedPefa.mhDemands;
      if (dtDemands && dtDemands.length > 0) {
        return dtDemands[0];
      } else if (mhDemands && mhDemands.length > 0) {
        // sort by functional order
        mhDemands.sort(function (demand1, demand2) {
          return demand1.functionalOrder - demand2.functionalOrder;
        });
        return mhDemands[0];
      } else {
        return {
          frequency: "",
          value: -1,
          proceduralOrder: 100,
          functionalOrder: 100,
          pageKey: PROGRAMRECOMMENDATIONS,
          key: dataStorePageKeys.PROGRAM_RECOMMENDATIONS,
          testID: null,
        };
      }
    }

    /**
     * Returns the next assessment test demand object immediately after the step that is being skipped.
     * @param stepToSkip the page key of the test that is being skipped.
     * @returns the demand object of the demand immediately after the skipped step.
     */
    function getNextStepAfter(stepToSkip: dataStorePageKeys): demand {
      //Get the PEFA demands, demand to skip and then use that to determine the next step's functional order.
      const demandsList: Array<demand> = Object.values(self.selectedPefa.demands);
      const demandToSkip: demand = self.selectedPefa.demands[stepToSkip];
      const nextStep = demandToSkip.functionalOrder + 1;

      //initialise the nextDemand object with the dashboard nav object name.
      let nextDemand: any = null;

      //if the next step has a matching demand, return it.
      demandsList.forEach((demand: demand) => {
        if (demand.functionalOrder === nextStep) {
          nextDemand = demand;
        }
      });

      //Otherwise, we can assume that we have finished working our way through the demands,
      //which means the next step is the program recommendations.
      if (!nextDemand) {
        nextDemand = {
          frequency: null,
          value: -1,
          proceduralOrder: 100,
          functionalOrder: nextStep,
          pageKey: PROGRAMRECOMMENDATIONS,
        };
      }

      return nextDemand;
    }

    function getPefaStepByKey(stepKey: dataStorePageKeys): demand {
      //Get the with the given key and return it.
      return self.selectedPefa.demands[stepKey];
    }

    const clearPefaData = function clearPefaData() {
      applySnapshot(self, {
        pefas: [],
        status: pefaStoreStates.EMPTY,
      });
    };

    const submitCheckIn = flow(function* submitCheckIn(data, pefaID) {
      return yield apiService.submitCheckIn(data, pefaID);
    });

    const submitConsent = flow(function* submitConsent(data, pefaID) {
      return yield apiService.submitConsent(data, pefaID);
    });

    const nextSubmit = function nextSubmit(pefaData: any, id: number) {
      apiService.nextSubmit(pefaData, id, self.selectedPefa.demands);
    };
    const submitPefa = flow(function* submitPefa(pefa: any, notes: any, pefaID: number, demands: any, isCompleted: boolean) {
      //TODO do something with this response.
      return yield apiService.submitPefa(pefa, notes, pefaID, demands, isCompleted);
    });

    return {
      fetchPefas,
      selectPefa,
      insertSubstituteTest,
      getNextPefaStep,
      getNextStepAfter,
      getNextStepAfterHighBP,
      getNextStepAfter4thLowBP,
      getNextStepAfterFitnessHighBPFlow,
      getPefaStepByKey,
      submitCheckIn,
      submitConsent,
      submitPefa,
      clearPefaData,
      nextSubmit,
    };
  })
  .views((self) => ({
    /**
     * Get the number of currently assigned PEFAs. Regardless of completion status.
     */
    get pefaCount() {
      return self.pefas.length || 0;
    },
    /**
     * Returns a list of PEFAs based on the period selected and completion status.
     * Note that when returning completed PEFAs, all completed PEFAs for the last 3 days will be shown.
     * @param periodKey the key defining the period which PEFAs will be returned for.
     * @param showCurrent when true, only incomplete PEFAs will  be returned.
     */
    filteredPefas(periodKey: string, showCurrent: boolean) {
      const now = new Date();

      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const sortedPefas = self.pefas.sort((a, b) => {
        return compareAsc(utcToZonedTime(new Date(a.appointmentDateTime), timezone), utcToZonedTime(new Date(b.appointmentDateTime), timezone));
      });

      const groupedPefas = sortedPefas.reduce((groupedPefasSoFar: { [key: string]: any[] }, pefa) => {
        //Trim the time component from the PEFA, allowing for grouping by date.
        const date = utcToZonedTime(new Date(pefa.appointmentDateTime), timezone).toDateString();

        const appointmentDate = utcToZonedTime(new Date(pefa.appointmentDateTime), timezone);

        let startDate: Date;
        let endDate: Date;

        //Using the supplied periodKey, determine the start and finish timestamps to display PEFAs between.
        switch (periodKey) {
          case filteredPeriodKeys.TODAY:
            startDate = new Date(format(addDays(now, -10), "yyyy/MM/dd 00:00:00"));
            endDate = new Date(format(now, "yyyy/MM/dd 23:59:59"));
            break;
          case filteredPeriodKeys.TOMORROW:
            startDate = new Date(format(addDays(now, -10), "yyyy/MM/dd 00:00:00"));
            endDate = new Date(format(addDays(now, 1), "yyyy/MM/dd 23:59:59"));
            break;
          case filteredPeriodKeys.SEVENDAYS:
            startDate = new Date(format(addDays(now, -10), "yyyy/MM/dd 00:00:00"));
            endDate = new Date(format(addDays(now, 7), "yyyy/MM/dd 23:59:59"));
            break;
          default:
            //the default response is equivalent to filteredPeriodKeys.3DAYS
            startDate = new Date(format(addDays(now, -10), "yyyy/MM/dd 00:00:00"));
            endDate = new Date(format(addDays(now, 2), "yyyy/MM/dd 23:59:59"));
        }

        //If there is no existing date key, add a new array for storing PEFAS.
        if (!groupedPefasSoFar[date]) groupedPefasSoFar[date] = [];

        if (showCurrent) {
          if (
            appointmentDate > startDate &&
            appointmentDate < endDate &&
            (pefa.status.toUpperCase() === "NEW" ||
              pefa.status.toUpperCase() === "RESHEDULED" ||
              pefa.status.toUpperCase() === "CHECKEDIN" ||
              pefa.status.toUpperCase() === "CONFIRMED")
          ) {
            groupedPefasSoFar[date].push(pefa);
          }
        } else {
          //Get the current formatted datetime and the formatted datetime of the start of the period.
          const endFormatted = new Date(format(now, "yyyy/MM/dd 23:59:59"));
          const startFormatted = new Date(format(subDays(now, 2), "yyyy/MM/dd 00:00:00"));

          if (appointmentDate < endFormatted && appointmentDate > startFormatted && pefa.status.toUpperCase() === "COMPLETED") {
            groupedPefasSoFar[date].push(pefa);
          }
        }

        //Return the grouped pefas ready for the next iteration. If this is the last iteration, then this is the returned list.
        return groupedPefasSoFar;
      }, {});

      return groupedPefas;
    },

    pefaStatus(pefaID: number): string {
      const selectedPefa: any = self.pefas.filter((pefa) => {
        if (pefa.id === pefaID) return pefa;
      });

      return selectedPefa[0].status;
    },

    isPefaCheckedIn(pefaID: number): boolean {
      const selectedPefa: any = self.pefas.filter((pefa) => {
        if (pefa.id === pefaID) return pefa;
      });

      return selectedPefa[0].status.toUpperCase() === "CHECKEDIN";
    },

    isAssignedAssessor(pefaID: number, id: number): boolean {
      const selectedPefa: any = self.pefas.filter((pefa) => {
        if (pefa.id === pefaID) return pefa;
      });

      return selectedPefa[0]?.assessor.id === id;
    },

    isCheckInIncludingInformedConsent(pefaID: number): boolean {
      const selectedPefa: any = self.pefas.filter((pefa) => {
        if (pefa.id === pefaID) return pefa;
      });

      //We want to make sure that only when the consentLocation is ADMIN that we let them do it.
      //This means that if the consentLocation is Null or ASSESSOR, the assessor will need to do it.
      return selectedPefa[0]?.provider?.consentLocation?.toUpperCase() === "ADMIN";
    },
    isDate(stringDate: string): boolean {
      // format needs to be DD-MMM-YYYY
      return stringDate.length === 11 && new Date(stringDate).toString() !== "Invalid Date";
    },
    getSelectedPefaMedicalClearances() {
      return self.selectedPefa[0].pefArequest.worker.medicalClearances;
    },

    getTotalNumberOfAppointments() {
      return self.pefas.length;
    },
    getLast20Years() {
      const currentYear = new Date().getFullYear();
      // eslint-disable-next-line no-var
      var years: number[] = [];
      if (currentYear) {
        for (let i = currentYear; i >= currentYear - 19; i--) {
          years.push(i);
        }
      }
      return years;
    },
    getMonths() {
      return ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    },
  }));

export { PefaStore };

import * as React from "react";
import { Link } from "@material-ui/core";
import { File } from "./File";
import UploadIcon from "@material-ui/icons/FileCopy";
import ApiService from "../../../services/ApiService";

interface UploaderProps {
  condition: string;
  conditionText: string;
  otherInfo: string;
  pefaId: string;
  bookingId: string;
  doctor: string;
  practice: string;
  handlePaperMC?: any;
  startUpload?: any;
  paperMCs: any[];
}

interface IFile {
  file: File;
  uploadError: string;
}

export const Upload = (props: UploaderProps) => {
  const [selectedFiles] = React.useState<IFile[] | undefined | null>([]);
  const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const currentSelected: File[] = Array.prototype.slice.call(event?.target?.files);
    const fileToUpload = currentSelected[0];
    props.startUpload(true);
    handleFileUpload(props.condition, props.conditionText, props.otherInfo ?? props.conditionText, props.pefaId, props.doctor, props.practice, fileToUpload, props.bookingId);
  };
  const handleFileUpload = async (condition: string, conditionText: string, otherInfo: string, id: string, doctorName: string, practice: string, file: File, bookingId: string) => {
    return await ApiService.uploadPaperMC(condition, conditionText, otherInfo, id, doctorName, practice, file, bookingId).then((response) => {
      props.handlePaperMC(response?.data, props.condition, props.paperMCs);
    });
  };
  return (
    <>
      <Link component="label">
        <input hidden type="file" multiple onChange={handleFileSelect} />

        {!selectedFiles?.length && (
          <span>
            <UploadIcon></UploadIcon> Upload Paper Medical Clearance
          </span>
        )}
        {!!selectedFiles?.length && (
          <span>
            <File {...{ selectedFiles }} />
          </span>
        )}
      </Link>
    </>
  );
};

import React, { useEffect, FocusEvent } from "react";
import {
  ProfileCard,
  FormNavigator,
  Stepper,
  Timer,
  TimerStopped,
  NotTested,
  ParticipantReports,
  NotesModal,
  Peeper,
  RemoteStart,
  InTestMedicalClearanceModal,
} from "../../../components/";
import { LadderClimbIcon, StarIcon, Baseline, WarningIcon } from "../../../assets/images/";
import ptValidation from "./validation";
import { ExpandMore, ErrorOutline } from "@material-ui/icons";

import {
  Grid,
  Typography,
  Container,
  FormLabel,
  FormControlLabel,
  OutlinedInput,
  Card,
  FormGroup,
  Checkbox,
  TextField,
  AccordionSummary,
  AccordionDetails,
  Radio,
  RadioGroup,
  InputAdornment,
} from "@material-ui/core";
import { Accordion as StyledAccordion } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import { Root } from "../../../stores";
import { differenceInYears } from "date-fns";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { dataStorePageKeys } from "../../../stores/dataStore";
import { demand } from "../../../stores/pefaStore";
import { useHistory } from "react-router-dom";
import { DASHBOARD } from "../../../routes/paths";
import { getAppInsights } from "../../../components/telemetry";
import withAppInsights from "../AppInsights";
import ratingHelper from "../../../helpers/ratingHelper";

interface DynamicTolerancesProps {
  rootStore?: Root;
}

const dtLadderClimb = inject("rootStore")(
  observer((props: DynamicTolerancesProps) => {
    let pefaAssessment: any;
    let currentDemands: demand;
    let nextDemandPage: demand;
    let prevData: any;
    let referralDetails: any;
    let medicalHistoryData: any;
    let endAssessment: any;
    let participantAgeYears = 0;
    let extraPressureTestRequired = false;
    const history = useHistory();
    const appInsights = getAppInsights();

    if (props.rootStore?.pefaStore.selectedPefa) {
      pefaAssessment = props.rootStore?.pefaStore.selectedPefa[0];
      currentDemands = props.rootStore?.pefaStore.selectedPefa.demands[dataStorePageKeys.DT_LADDER];
      nextDemandPage = props.rootStore?.pefaStore.getNextPefaStep(currentDemands.functionalOrder);
      prevData = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.DT_LADDER);
      extraPressureTestRequired = props.rootStore?.dataStore.isExtraPressureTestRequired(pefaAssessment.id);
      referralDetails = props.rootStore?.dataStore.getCheckInData(pefaAssessment.id, dataStorePageKeys.REFERRAL_DETAILS);
      participantAgeYears = differenceInYears(new Date(), new Date(referralDetails?.dob ?? pefaAssessment.pefArequest.worker.dateOfBirth));
      medicalHistoryData = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.MEDICAL_DETAILS);
      endAssessment = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.END_ASSESSMENT);
    } else {
      history.push(DASHBOARD);
      return <></>;
    }

    const bodyWeight = props.rootStore?.dataStore.pefaData[pefaAssessment.id].BASELINE_MEASUREMENTS.bodyWeight;

    const { register, handleSubmit, errors, setValue } = useForm({
      resolver: yupResolver(ptValidation),
    });

    const [state, setState] = useState({
      isLoading: true,
      timeElapsedFormatted: "",
      timeElapsedRaw: prevData ? parseInt(prevData.timeElapsedRaw) : 0,
      timeRange: 0,
      isTimerStoppedModalOpen: false,
      timerStopped: false,
      targetDemand: currentDemands.frequency,
      notTestedReason: prevData ? prevData.notTestedReason : "",
      notTestedNotes: prevData ? prevData.notTestedNotes : "",
      recoveryModalOpen: false,
      yesAllNilSignificant: false,
      viewAll: false,
      discomfortReported: false,
      currentHeartRate: "",
      timerStoppedReason: prevData ? prevData.timerStoppedReason : "",
      timerStoppedNotes: prevData ? prevData.timerStoppedNotes : "",

      peeperPlaying: false,
      peeperEnabled: false,
      peeperInterval: 100,
      peeperRepeats: 2,

      isTimerStarted: false,
      timerRemoteTrigger: false,
      timerRemoteResumeTrigger: false,
      remoteTimerVisible: prevData ? prevData.remoteTimerVisible : true,

      heartRateCues: prevData ? (prevData.heartRateCues ? prevData.heartRateCues : false) : false,

      asymmetricalPostureChecked: prevData ? prevData.asymmetricalPostureChecked : false,
      symmetricalPostureChecked: prevData ? prevData.symmetricalPostureChecked : false,
      asymmetricalNote: prevData ? prevData.asymmetricalNote : "",

      performanceUnableAlternateClimb: prevData ? prevData.performanceUnableAlternateClimb : false,
      performanceUnableWeightbearMidFoot: prevData ? prevData.performanceUnableWeightbearMidFoot : false,
      performanceDifficultyMaintainingPace: prevData ? prevData.performanceDifficultyMaintainingPace : false,
      performanceUnevenWeightBearingLowerLimbs: prevData ? prevData.performanceUnevenWeightBearingLowerLimbs : false,
      performanceNotes: prevData ? prevData.performanceNotes : "",

      toleranceRating: prevData ? prevData.toleranceRating : "",

      bloodPressureModalOpen: prevData ? prevData.bloodPressureModalOpen : extraPressureTestRequired,
      bloodPressureModalLocked: prevData ? !!prevData.bloodPressureSystolic && !!prevData.bloodPressureDiastolic : false,
      bloodPressureSystolic: prevData ? prevData.bloodPressureSystolic : "",
      bloodPressureDiastolic: prevData ? prevData.bloodPressureDiastolic : "",
      bloodPressureBlocksFurtherTests: prevData ? prevData.bloodPressureBlocksFurtherTests : false,

      Comment100: prevData ? prevData.Comment100 : "",
      Bpm100: prevData ? prevData.Bpm100 : "",
      Comment130: prevData ? prevData.Comment130 : "",
      Bpm130: prevData ? prevData.Bpm130 : "",
      Comment200: prevData ? prevData.Comment200 : "",
      Bpm200: prevData ? prevData.Bpm200 : "",
      Comment230: prevData ? prevData.Comment230 : "",
      Bpm230: prevData ? prevData.Bpm230 : "",
      Comment300: prevData ? prevData.Comment300 : "",
      Bpm300: prevData ? prevData.Bpm300 : "",
      preTestHeartRate: prevData ? prevData.preTestHeartRate : "",
    });

    const handleCheckboxChange = (event: any): void => {
      event.persist();
      setState((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.checked,
      }));
    };

    const handleValueChange = (event: any): void => {
      event.persist();
      setState((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
      }));
    };

    const handleHeartRateChanged = (event: FocusEvent<HTMLInputElement>): void => {
      event.persist();
      setState((prevState) => ({
        ...prevState,
        currentHeartRate: event.target.value,
        [event.target.name]: event.target.value,
      }));
    };

    const handleStoppedModal = () => {
      setState((prevState) => ({
        ...prevState,
        isTimerStoppedModalOpen: true,
      }));
    };

    const handleTimerEvents = (timeElapsedRaw: number, timeElapsedFormatted: string): void => {
      let timeRange = 0;
      let peeperInterval = 0;
      let peeperRepeats = 0;
      let peeperEnabled = false;

      switch (Math.floor(timeElapsedRaw / 30)) {
        case 0:
          timeRange = 0;
          break;
        case 1:
          timeRange = 1;
          break;
        case 2:
          timeRange = 2;
          break;
        case 3:
          timeRange = 3;
          break;
        case 4:
          timeRange = 4;
          break;
        case 5:
          timeRange = 5;
          break;
      }

      if (timeRange > state.timeRange && timeRange > 0) {
        peeperInterval = 200;
        peeperRepeats = 2;
        peeperEnabled = true;
      } else if (timeElapsedRaw === 180) {
        peeperInterval = 100;
        peeperRepeats = 5;
        peeperEnabled = true;
      }

      setState((prevState) => ({
        ...prevState,
        timeRange,
        timeElapsedFormatted,
        timeElapsedRaw,
        timerRemoteTrigger: false,
        timerRemoteResumeTrigger: false,
        remoteTimerVisible: false,
        isTimerStarted: true,
        peeperInterval,
        peeperRepeats,
        peeperEnabled,
      }));
    };

    const handlePeeperCompleted = (): void => {
      setState((prevState) => ({ ...prevState, peeperPlaying: false }));
    };

    const handleAOPostureChange = (event: any): void => {
      event.persist();

      setState((prevState) => ({
        ...prevState,
        symmetricalPostureChecked: false,
        asymmetricalPostureChecked: false,
        [event.target.name]: event.target.checked,
      }));
    };

    const handleTimerRemoteStart = (): void => {
      setState((prevState) => ({
        ...prevState,
        timerRemoteTrigger: true,
        remoteTimerVisible: false,
      }));
    };

    const handleTimerRemoteResume = (): void => {
      setState((prevState) => ({ ...prevState, timerStoppedReason: "", timerStoppedNotes: "", timerRemoteResumeTrigger: true, isTimerStoppedModalOpen: false }));
    };

    const handleTimerStopped = (): void => {
      handleStoppedModal();
    };
    const handleStoppedRetestClicked = (): void => {
      setState((prevState) => ({
        ...prevState,
        timerStoppedReason: "",
        timerStoppedNotes: "",
        isTimerStoppedModalOpen: false,
        timerRemoteTrigger: true,
        timeRange: 0,
      }));
    };

    const handleStoppedContinueClicked = (stoppedReason: string, stoppedNotes: string): void => {
      setState((prevState) => ({
        ...prevState,
        isTimerStoppedModalOpen: false,
        timerStoppedReason: stoppedReason,
        timerStoppedNotes: stoppedNotes,
      }));
    };

    const discomfortReportedChange = (discomfortReported: boolean): void => {
      let { toleranceRating } = state;

      if (discomfortReported && toleranceRating === "X") {
        toleranceRating = "";
      }
      setState((prevState) => ({
        ...prevState,
        toleranceRating,
        discomfortReported,
      }));
    };

    useEffect(() => {
      if (!state.peeperPlaying && state.peeperEnabled) {
        setState((prevState) => ({
          ...prevState,
          peeperPlaying: true,
        }));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.peeperInterval, state.peeperRepeats]);

    useEffect(() => {
      const timeout = setTimeout(() => {
        props.rootStore?.dataStore.savePartialData(state, pefaAssessment.id, dataStorePageKeys.DT_LADDER);
      }, 500);

      return () => clearTimeout(timeout);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      state.notTestedReason,
      state.notTestedNotes,
      state.heartRateCues,
      state.asymmetricalPostureChecked,
      state.symmetricalPostureChecked,
      state.asymmetricalNote,
      state.performanceUnableAlternateClimb,
      state.performanceUnableWeightbearMidFoot,
      state.performanceDifficultyMaintainingPace,
      state.performanceUnevenWeightBearingLowerLimbs,
      state.performanceNotes,
      state.toleranceRating,
      state.Comment100,
      state.Bpm100,
      state.Comment130,
      state.Bpm130,
      state.Comment200,
      state.Bpm200,
      state.Comment230,
      state.Bpm230,
      state.Comment300,
      state.Bpm300,
      state.preTestHeartRate,
    ]);

    useEffect(() => {
      //Replicate the onUnmount event handler from class components.
      return () => {
        appInsights.trackEvent({ name: "intermediateSubmission", properties: { bookingId: pefaAssessment.id, dataKey: dataStorePageKeys.DT_LADDER, data: state } });
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
      calculateToleranceRating();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      state.Bpm100,
      state.Bpm130,
      state.Bpm200,
      state.Bpm230,
      state.Bpm300,
      state.discomfortReported,
      state.performanceUnableWeightbearMidFoot,
      state.performanceDifficultyMaintainingPace,
      state.timeElapsedRaw,
    ]);

    const calculateToleranceRating = (): string => {
      const discomfort = state.discomfortReported || state.performanceUnableWeightbearMidFoot || state.performanceDifficultyMaintainingPace;
      const toleranceRating = ratingHelper.calculateToleranceRating(state.timeElapsedRaw, discomfort);
      setState((prevState) => ({
        ...prevState,
        toleranceRating,
      }));
      return toleranceRating;
    };
    const submitForm = (data: any, forwardTarget: any) => {
      props.rootStore?.dataStore.saveData(
        {
          ...data,
        },
        pefaAssessment.id,
        dataStorePageKeys.DT_LADDER
      );
      setState((prevState) => ({ ...prevState, isSubmitting: true }));

      const pefaData = { ...props.rootStore?.dataStore.pefaData[pefaAssessment.id] };
      // take snapshot of assessment and store against booking
      props.rootStore?.pefaStore.nextSubmit(pefaData, pefaAssessment.id);

      history.push(forwardTarget);
    };

    return (
      <div className="FormStyler">
        <TimerStopped
          currentTime={state.timeElapsedFormatted}
          isOpen={state.isTimerStoppedModalOpen}
          retestHandler={handleStoppedRetestClicked}
          continueHandler={handleStoppedContinueClicked}
          resumeHandler={handleTimerRemoteResume}
        />
        <div className="AssessmentDetails">
          <Grid item container direction="column" xs={12}>
            <Stepper targetStep={"Postural & Dynamic Tolerances"} />
            <Container maxWidth={"lg"}>
              <Grid item container direction="row" xs={12}>
                <Grid item container xs={9}>
                  <Card className="titleCard" variant="outlined">
                    <a href="/docs/PEFA Instructions_DT_LadderClimb.pdf" className="helpIcon" target="_blank">
                      Help
                    </a>
                    <Typography variant="h2" className="cardHeroHeading">
                      <img src={LadderClimbIcon} alt="Success" /> Ladder Climb
                    </Typography>
                    <Typography variant="subtitle2" className="subtitleHeading">
                      Repetitive Movement
                    </Typography>
                    <div className="targetWeight">
                      <span className="targetWeightText">{state.targetDemand}</span>
                      <span className="percentage">Requirement</span>
                    </div>
                    <NotTested data={prevData} forwardTarget={nextDemandPage.pageKey} notTestedSaveHandler={submitForm} />
                    <input type="hidden" name="notTestedReason" ref={register} value={state.notTestedReason} />
                    <input type="hidden" name="notTestedNotes" ref={register} value={state.notTestedNotes} />
                    <input type="hidden" name="timeElapsedRaw" ref={register} value={state.timeElapsedRaw} />
                    <input type="hidden" name="timerStoppedReason" ref={register} value={state.timerStoppedReason} />
                    <input type="hidden" name="timerStoppedNotes" ref={register} value={state.timerStoppedNotes} />
                  </Card>
                  <Card className="fitnessTitleCard" variant="outlined">
                    <Typography variant="h3" className="cardHeroHeading">
                      <img src={Baseline} alt="Success" /> Pre-test information
                    </Typography>
                    <Grid item xs={12}>
                      {bodyWeight >= 100 ? (
                        <span style={{ marginTop: "17.5px", maxWidth: "400px", float: "right" }} className="inline-warningMsg">
                          <img src={WarningIcon} alt="Warning Icon" />
                          This person's body weight is 100kg or more. Please check the SWL of your ladder.
                        </span>
                      ) : (
                        <></>
                      )}
                    </Grid>
                    <FormControlLabel
                      control={
                        <OutlinedInput
                          color="primary"
                          name="preTestHeartRate"
                          className="textInputFull"
                          autoComplete="off"
                          endAdornment={<InputAdornment position="end">bpm</InputAdornment>}
                          inputRef={register}
                          value={state.preTestHeartRate}
                          onChange={handleValueChange}
                          onBlur={handleHeartRateChanged}
                        />
                      }
                      label="Heart rate"
                      labelPlacement="start"
                      className="inputBoldBlueSolo"
                    />
                    <div style={{ marginLeft: "170px" }} className="invalidFeedback">
                      {errors.preTestHr?.message}
                    </div>
                  </Card>
                  <Grid item container xs={12}>
                    <form className="PefaForm">
                      <Peeper
                        intervalMs={state.peeperInterval}
                        isPlaying={state.peeperPlaying}
                        isEnabled={state.peeperEnabled}
                        repeats={state.peeperRepeats}
                        soundKey={3}
                        handlePeeperComplete={handlePeeperCompleted}
                      />
                      <RemoteStart
                        configKey="TIMER"
                        disabled={false}
                        visible={state.remoteTimerVisible}
                        isTimerStarted={state.isTimerStarted}
                        handleRemoteStart={handleTimerRemoteStart}
                        handleRemoteResume={handleTimerRemoteResume}
                      />
                      <StyledAccordion variant="outlined" className="innerOutlinedBox" defaultExpanded={true}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                          <Typography variant="subtitle1">Test recordings</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="hrWrapper">
                            <div className={state.timeRange === 1 ? "hrCurrent" : ""}>
                              <Typography variant="caption" className="hrCountText">
                                1:00
                              </Typography>
                              <TextField
                                placeholder="BPM"
                                className="bpmInput"
                                name="Bpm100"
                                inputRef={register}
                                value={state.Bpm100}
                                variant="outlined"
                                InputLabelProps={{ shrink: false }}
                                onChange={handleHeartRateChanged}
                              />
                              <TextField
                                placeholder="Enter comments here"
                                className="bpmComment"
                                name="Comment100"
                                inputRef={register}
                                value={state.Comment100}
                                variant="outlined"
                                InputLabelProps={{ shrink: false }}
                                onChange={handleValueChange}
                              />
                            </div>
                            <div style={{ marginLeft: "60px" }} className="invalidFeedback">
                              {errors.sec100?.message} <br />
                            </div>
                          </div>
                          <div className="hrWrapper">
                            <div className={state.timeRange === 2 ? "hrCurrent" : ""}>
                              <Typography variant="caption" className="hrCountText">
                                1:30
                              </Typography>
                              <TextField
                                placeholder="BPM"
                                className="bpmInput"
                                name="Bpm130"
                                inputRef={register}
                                value={state.Bpm130}
                                variant="outlined"
                                InputLabelProps={{ shrink: false }}
                                onChange={handleHeartRateChanged}
                              />
                              <TextField
                                placeholder="Enter comments here"
                                className="bpmComment"
                                name="Comment130"
                                inputRef={register}
                                value={state.Comment130}
                                variant="outlined"
                                InputLabelProps={{ shrink: false }}
                                onChange={handleValueChange}
                              />
                            </div>
                            <div style={{ marginLeft: "60px" }} className="invalidFeedback">
                              {errors.sec130?.message} <br />
                            </div>
                          </div>
                          <div className="hrWrapper">
                            <div className={state.timeRange === 3 ? "hrCurrent" : ""}>
                              <Typography variant="caption" className="hrCountText">
                                2:00
                              </Typography>
                              <TextField
                                placeholder="BPM"
                                className="bpmInput"
                                name="Bpm200"
                                inputRef={register}
                                value={state.Bpm200}
                                variant="outlined"
                                InputLabelProps={{ shrink: false }}
                                onChange={handleHeartRateChanged}
                              />
                              <TextField
                                placeholder="Enter comments here"
                                className="bpmComment"
                                name="Comment200"
                                inputRef={register}
                                value={state.Comment200}
                                variant="outlined"
                                InputLabelProps={{ shrink: false }}
                                onChange={handleValueChange}
                              />
                            </div>
                            <div style={{ marginLeft: "60px" }} className="invalidFeedback">
                              {errors.sec200?.message} <br />
                            </div>
                          </div>
                          <div className="hrWrapper">
                            <div className={state.timeRange === 4 ? "hrCurrent" : ""}>
                              <Typography variant="caption" className="hrCountText">
                                2:30
                              </Typography>
                              <TextField
                                placeholder="BPM"
                                className="bpmInput"
                                name="Bpm230"
                                inputRef={register}
                                value={state.Bpm230}
                                variant="outlined"
                                InputLabelProps={{ shrink: false }}
                                onChange={handleHeartRateChanged}
                              />
                              <TextField
                                placeholder="Enter comments here"
                                className="bpmComment"
                                name="Comment230"
                                inputRef={register}
                                value={state.Comment230}
                                variant="outlined"
                                InputLabelProps={{ shrink: false }}
                                onChange={handleValueChange}
                              />
                            </div>
                            <div style={{ marginLeft: "60px" }} className="invalidFeedback">
                              {errors.sec230?.message} <br />
                            </div>
                          </div>
                          <div className="hrWrapper">
                            <div className={state.timeRange === 5 ? "hrCurrent" : ""}>
                              <Typography variant="caption" className="hrCountText">
                                3:00
                              </Typography>
                              <TextField
                                placeholder="BPM"
                                className="bpmInput"
                                name="Bpm300"
                                inputRef={register}
                                value={state.Bpm300}
                                variant="outlined"
                                InputLabelProps={{ shrink: false }}
                                onChange={handleHeartRateChanged}
                              />
                              <TextField
                                placeholder="Enter comments here"
                                className="bpmComment"
                                name="Comment300"
                                inputRef={register}
                                value={state.Comment300}
                                variant="outlined"
                                InputLabelProps={{ shrink: false }}
                                onChange={handleValueChange}
                              />
                            </div>
                            <div style={{ marginLeft: "60px" }} className="invalidFeedback">
                              {errors.sec300?.message} <br />
                            </div>
                          </div>
                        </AccordionDetails>
                      </StyledAccordion>
                      <StyledAccordion variant="outlined" className="innerOutlinedBox">
                        <AccordionSummary expandIcon={<ExpandMore />}>
                          <Typography variant="subtitle1">Assessor Observations</Typography>
                          {!!errors.postureAsymmetrical?.message || !!errors.posture?.message || !!errors.performanceDifficulty?.message ? (
                            <ErrorOutline className="invalid-feedback" />
                          ) : (
                            <></>
                          )}
                        </AccordionSummary>
                        <AccordionDetails>
                          <div>
                            <div>
                              <FormGroup>
                                <FormLabel component="legend">Heart Rate</FormLabel>
                                <div className="flexer">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        icon={<span className="checkboxIcon"></span>}
                                        checkedIcon={<span className="checkboxIconChecked"></span>}
                                        color="primary"
                                        inputRef={register}
                                        checked={state.heartRateCues}
                                        onChange={handleCheckboxChange}
                                      />
                                    }
                                    label="Cues to slow pace to manage heart rate"
                                    name="heartRateCues"
                                  />
                                </div>
                              </FormGroup>
                            </div>
                            <div>
                              <FormGroup>
                                <FormLabel component="legend">Posture</FormLabel>
                                <div className="flexer">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="symmetricalPostureChecked"
                                        inputRef={register}
                                        checked={state.symmetricalPostureChecked}
                                        icon={<span className="checkboxIcon"></span>}
                                        checkedIcon={<span className="checkboxIconChecked"></span>}
                                        color="primary"
                                        onChange={(event) => handleAOPostureChange(event)}
                                      />
                                    }
                                    label="Symmetrical reach"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="asymmetricalPostureChecked"
                                        inputRef={register}
                                        checked={state.asymmetricalPostureChecked}
                                        icon={<span className="checkboxIcon"></span>}
                                        checkedIcon={<span className="checkboxIconChecked"></span>}
                                        color="primary"
                                        onChange={(event) => handleAOPostureChange(event)}
                                      />
                                    }
                                    label="Asymmetrical (describe)"
                                  />
                                </div>
                                {state.asymmetricalPostureChecked ? (
                                  <OutlinedInput
                                    color="primary"
                                    placeholder="Describe here"
                                    name="asymmetricalNote"
                                    inputRef={register}
                                    value={state.asymmetricalNote}
                                    className="textInputFull"
                                    autoComplete="off"
                                    onChange={handleValueChange}
                                  />
                                ) : (
                                  <></>
                                )}
                                <div style={{ marginLeft: "0px" }} className="invalidFeedback">
                                  {errors.postureAsymmetrical?.message}
                                  {errors.posture?.message} <br />
                                </div>
                              </FormGroup>
                            </div>
                            <div>
                              <FormGroup>
                                <FormLabel component="legend">Performance</FormLabel>
                                <div className="flexer">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="performanceUnableAlternateClimb"
                                        checked={state.performanceUnableAlternateClimb}
                                        icon={<span className="checkboxIcon"></span>}
                                        checkedIcon={<span className="checkboxIconChecked"></span>}
                                        color="primary"
                                        inputRef={register}
                                        onChange={handleCheckboxChange}
                                      />
                                    }
                                    label="Unable to climb with alternative pattern"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="performanceUnableWeightbearMidFoot"
                                        checked={state.performanceUnableWeightbearMidFoot}
                                        icon={<span className="checkboxIcon"></span>}
                                        checkedIcon={<span className="checkboxIconChecked"></span>}
                                        color="primary"
                                        inputRef={register}
                                        onChange={handleCheckboxChange}
                                      />
                                    }
                                    label="Unable to weightbear through mid foot"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="performanceDifficultyMaintainingPace"
                                        checked={state.performanceDifficultyMaintainingPace}
                                        icon={<span className="checkboxIcon"></span>}
                                        checkedIcon={<span className="checkboxIconChecked"></span>}
                                        color="primary"
                                        inputRef={register}
                                        onChange={handleCheckboxChange}
                                      />
                                    }
                                    label="Difficulty maintaining working pace "
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="performanceUnevenWeightBearingLowerLimbs"
                                        checked={state.performanceUnevenWeightBearingLowerLimbs}
                                        icon={<span className="checkboxIcon"></span>}
                                        checkedIcon={<span className="checkboxIconChecked"></span>}
                                        color="primary"
                                        inputRef={register}
                                        onChange={handleCheckboxChange}
                                      />
                                    }
                                    label="Uneven weightbearing through lower limbs (describe)"
                                  />
                                </div>
                                {state.performanceUnevenWeightBearingLowerLimbs ? (
                                  <OutlinedInput
                                    color="primary"
                                    placeholder="Describe here"
                                    name="performanceNotes"
                                    className="textInputFull"
                                    autoComplete="off"
                                    inputRef={register}
                                    value={state.performanceNotes}
                                    onChange={handleValueChange}
                                  />
                                ) : (
                                  <></>
                                )}
                                <div style={{ marginLeft: "0px" }} className="invalidFeedback">
                                  {errors.performanceDifficulty?.message} <br />
                                </div>
                              </FormGroup>
                            </div>
                          </div>
                        </AccordionDetails>
                      </StyledAccordion>
                      <ParticipantReports
                        datasetName="ladderClimb"
                        register={register}
                        data={prevData}
                        errors={errors}
                        setValue={setValue}
                        assessmentID={pefaAssessment.id}
                        dataKey={dataStorePageKeys.DT_LADDER}
                        handleDiscomfortReported={discomfortReportedChange}
                      />
                      <Card variant="outlined" className="toleranceOutlinedBox">
                        <Typography variant="h2" className="toleranceHeading">
                          <img src={StarIcon} alt="Success" />
                          Tolerance Rating
                        </Typography>
                        <RadioGroup className="toleranceRatingGroup" name="toleranceRating" value={state.toleranceRating} onChange={handleValueChange}>
                          <div className="flexer">
                            <FormControlLabel
                              control={<Radio color="primary" name="toleranceRating" value="N" />}
                              className="RadioAgreementTolerance"
                              inputRef={register}
                              label="N"
                              disabled={true}
                            />
                            <FormControlLabel
                              control={<Radio color="primary" name="toleranceRating" value="O" />}
                              className="RadioAgreementTolerance"
                              inputRef={register}
                              label="O"
                              disabled={true}
                            />
                            <FormControlLabel
                              control={<Radio color="primary" name="toleranceRating" value="F" />}
                              className="RadioAgreementTolerance"
                              inputRef={register}
                              label="F"
                              disabled={true}
                            />
                            <FormControlLabel
                              control={<Radio color="primary" name="toleranceRating" value="X" />}
                              className="RadioAgreementTolerance"
                              inputRef={register}
                              label="X"
                              disabled={true}
                            />
                          </div>
                          <div style={{ marginLeft: "10px", marginBottom: "10px" }} className="invalidFeedback">
                            {errors.testNotPerformed?.message}
                            {errors.toleranceRating?.message} <br />
                          </div>
                        </RadioGroup>
                        <div style={{ marginLeft: "10px", marginBottom: "10px" }} className="invalidFeedback">
                          {errors.testNotPerformed?.message}
                        </div>
                      </Card>
                    </form>
                  </Grid>
                </Grid>
                <Grid item container xs={3} className="RightCol">
                  <ProfileCard
                    firstName={pefaAssessment.pefArequest.worker.firstName}
                    lastName={pefaAssessment.pefArequest.worker.lastName}
                    participantAgeYears={participantAgeYears}
                    currentHeartRate={state.currentHeartRate}
                  />
                  <Timer
                    countUp={true}
                    timeTarget={"3:00"}
                    timerHandler={handleTimerEvents}
                    timerStoppedHandler={handleTimerStopped}
                    timerRemoteTrigger={state.timerRemoteTrigger}
                    timerRemoteResumeTrigger={state.timerRemoteResumeTrigger}
                  />
                  <NotesModal pageKey={dataStorePageKeys.DT_LADDER} pefaID={pefaAssessment.id} />
                  <InTestMedicalClearanceModal
                    firstName={referralDetails.firstName}
                    lastName={referralDetails.lastName}
                    pageKey={dataStorePageKeys.DT_LADDER}
                    pefaID={pefaAssessment.id}
                    medicalPractice={medicalHistoryData.medicalPractitioner}
                    medicalPractitioner={medicalHistoryData.medicalPractice}
                  />
                </Grid>
              </Grid>
            </Container>
            <FormNavigator
              forwardTarget={nextDemandPage.pageKey}
              handleSubmit={handleSubmit(submitForm)}
              warning="Stop the test if a participant reports increasing discomfort"
              disabledNextButton={
                endAssessment &&
                endAssessment.endAssessmentReason !== "" &&
                endAssessment.endAssessmentNotes !== "" &&
                endAssessment.endAssessmentPage === dataStorePageKeys.DT_LADDER
              }
              selectedKey={dataStorePageKeys.DT_LADDER}
            />
          </Grid>
        </div>
      </div>
    );
  })
);

export default withAppInsights(dtLadderClimb);

import React, { useState } from "react";
import { ProfileCard, FormNavigator, Stepper, InTestMedicalClearanceModal } from "../../../components/";
import { Success, StarIcon, TickIcon } from "../../../assets/images/";
import { Root } from "../../../stores";
import { dataStorePageKeys } from "../../../stores/dataStore";

import { useForm } from "react-hook-form";
import { differenceInYears } from "date-fns";
import { inject, observer } from "mobx-react";
import { Grid, Typography, Card, Container, TextField, AccordionDetails, AccordionSummary } from "@material-ui/core";
import { Accordion as StyledAccordion } from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { notTestedSelectKeys, notTestedSelectKeysFriendly } from "../../../components/modals/NotTestedModal";
import { limitingFactors, limitingFactorsFriendly } from "../../../components/groupedComponents/MobilityTestDataKeys";

import { ManualHandlingDecisions } from "../../../components/groupedComponents/ManualHandlingTest";
import { SOBFriendly, SOB } from "../FitnessTest/FitnessTestDataKeys";
import { strengthDiscomfortFactors, strengthDiscomfortFactorsFriendly } from "../../../components/groupedComponents/StrengthTestDataKeys";
import {
  mskSetType,
  mskStandingTrunkMobilityDataKeys,
  mskStandingSquatMobilityDataKeys,
  mskSittingNeckMobilityDataKeys,
  mskSittingUpperLimbMobilityDataKeys,
  mskLyingHipKneeMobilityDataKeys,
  mskLyingAnkleMobilityDataKeys,
  mskLyingTrunkStrengthDataKeys,
  mskSittingUpperLimbStrengthDataKeys,
  mskLyingAnkleStrengthDataKeys,
  posturalToleranceDataKeys,
  dynamicToleranceDataKeys,
  manualHandlingDataKeys,
} from "./previewResultsDataKeys";
import { useHistory } from "react-router-dom";
import { APPRATING, DASHBOARD, LOGIN, PEFAFINALISATION } from "../../../routes/paths";
import { useAuth } from "oidc-react";

interface PefaFinalisationProps {
  rootStore?: Root;
}

const PefaReview = inject("rootStore")(
  observer((props: PefaFinalisationProps) => {
    let pefaAssessment: any;
    let prevData: any = [];
    let pefaNotes: any = [];
    let demands: any = null;
    let referralDetails: any = null;
    let medicalHistoryData: any;
    let inTestMCData: any;
    let participantAgeYears = 0;
    const history = useHistory();
    const auth = useAuth();

    if (props.rootStore?.pefaStore.selectedPefa) {
      pefaAssessment = props.rootStore?.pefaStore.selectedPefa[0];
      demands = props.rootStore?.pefaStore.selectedPefa.demands;
      if (props.rootStore?.dataStore.pefaData.hasOwnProperty(pefaAssessment.id)) {
        prevData = props.rootStore?.dataStore.pefaData[pefaAssessment.id];
        referralDetails = props.rootStore?.dataStore.getCheckInData(pefaAssessment.id, dataStorePageKeys.REFERRAL_DETAILS);
        medicalHistoryData = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.MEDICAL_DETAILS);
        participantAgeYears = differenceInYears(new Date(), new Date(referralDetails?.dob ?? pefaAssessment.pefArequest.worker.dateOfBirth));
        inTestMCData = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.IN_TEST_MEDICAL_CLEARANCE);
      } else {
        participantAgeYears = differenceInYears(new Date(), new Date(pefaAssessment.pefArequest.worker.dateOfBirth));
      }

      if (props.rootStore?.dataStore.pefaNotes.hasOwnProperty(pefaAssessment.id)) {
        pefaNotes = props.rootStore?.dataStore.pefaNotes[pefaAssessment.id];
      }
    } else {
      history.push(DASHBOARD);
      return <></>;
    }

    const { register, handleSubmit, errors } = useForm({});

    const [state, setState] = useState({
      isLoading: true,
      mskStandingTrunkMobilityAllListed: false,
      mskStandingSquatMobilityAllListed: false,
      mskSittingNeckMobilityAllListed: false,
      mskSittingUpperLimbMobilityAllListed: false,
      mskLyingHipKneeMobilityAllListed: false,
      mskLyingAnkleMobilityAllListed: false,

      mskSittingUpperLimbStrengthAllListed: false,
      mskLyingTrunkStrengthAllListed: false,
      mskLyingLowerLimbStrengthAllListed: false,

      //inTest MC
      enableNext: inTestMCData ? inTestMCData.enableNext : false,
      mcText: inTestMCData ? inTestMCData.extraMCNotesText : "",
      reason: inTestMCData ? inTestMCData.reasonText : "",
    });
    const handleSaveInTestMedical = (enableNext: boolean, mcText: string, reason: string): void => {
      setState((prevState) => ({
        ...prevState,
        enableNext: enableNext,
        mcText: mcText,
        reason: reason,
      }));
    };
    const renderNotTestedResults = (dataKey: dataStorePageKeys) => {
      return (
        <div className="full-width">
          <Typography variant="h4" className="typeHeading">
            Not Tested
          </Typography>
          <div className="flexer">
            <Typography variant="h5" className="greyPillHeading">
              <strong>Reason</strong> {notTestedSelectKeysFriendly[prevData[dataKey].notTestedReason as notTestedSelectKeys]}
            </Typography>
          </div>
          <TextField name={`${dataKey}_notTestedNotes`} variant="outlined" fullWidth className="outlineTextAreaFinal" defaultValue={prevData[dataKey].notTestedNotes} />
        </div>
      );
    };

    const renderPainDiscomfortResults = (dataKey: dataStorePageKeys, setKey: string) => {
      return (
        <>
          <div className="flexer">
            <Typography variant="h5" className="greyPillHeading">
              <strong>Location</strong> {prevData[dataKey][`${setKey}_location`]}
            </Typography>
            <Typography variant="h5" className="greyPillHeading">
              <strong>Intensity</strong> {prevData[dataKey][`${setKey}_intensityVas`] ? prevData[dataKey][`${setKey}_intensityVas`] : ""}
              {prevData[dataKey][`${setKey}_intensityMild`] ? "Mild" : ""}
              {prevData[dataKey][`${setKey}_intensityMod`] ? "Moderate" : ""}
              {prevData[dataKey][`${setKey}_intensitySevere`] ? "Severe" : ""}
            </Typography>

            {prevData[dataKey][`${setKey}_discomfortTypeAche`] ? (
              <Typography variant="h5" className="greyPillHeading">
                Ache
              </Typography>
            ) : (
              <></>
            )}
            {prevData[dataKey][`${setKey}_discomfortTypeSharp`] ? (
              <Typography variant="h5" className="greyPillHeading">
                Sharp
              </Typography>
            ) : (
              <></>
            )}
            {prevData[dataKey][`${setKey}_discomfortTypeBurning`] ? (
              <Typography variant="h5" className="greyPillHeading">
                Burning
              </Typography>
            ) : (
              <></>
            )}
            {prevData[dataKey][`${setKey}_discomfortTypeTightness`] ? (
              <Typography variant="h5" className="greyPillHeading">
                Tightness
              </Typography>
            ) : (
              <></>
            )}
            {prevData[dataKey][`${setKey}_discomfortTypePulling`] ? (
              <Typography variant="h5" className="greyPillHeading">
                Pulling
              </Typography>
            ) : (
              <></>
            )}
            {prevData[dataKey][`${setKey}_discomfortTypeOther`] ? (
              <Typography variant="h5" className="greyPillHeading">
                Other
              </Typography>
            ) : (
              <></>
            )}
            <Typography variant="h5" className="greyPillHeading">
              <strong>Status</strong> {prevData[dataKey][`${setKey}_discomfortResolvedImmediately`] ? "Resolved immediately" : ""}
              {prevData[dataKey][`${setKey}_discomfortPersisting`] ? "Persisting" : ""}
              {prevData[dataKey][`${setKey}_discomfortResolvedWithRest`] ? "Resolved with rest" : ""}
              {prevData[dataKey][`${setKey}_discomfortIncreasing`] ? "Increasing" : ""}
            </Typography>
          </div>
          {prevData[dataKey][`${setKey}_discomfortTypeOther`] ? (
            <TextField name={`${setKey}_typeOther`} variant="outlined" fullWidth className="outlineTextAreaFinal" defaultValue={prevData[dataKey][`${setKey}_typeOther`]} />
          ) : (
            <></>
          )}
        </>
      );
    };

    const renderMSKTestResults = (dataKey: dataStorePageKeys, set: { setKey: string; friendlyName: string }, setType: mskSetType) => {
      if (setType === mskSetType.MOBILITY) {
        //If we have mobility range values, display the data.
        if (
          prevData[dataKey][`${set.setKey}_lessThanOneThird`] ||
          prevData[dataKey][`${set.setKey}_lessThanTwoThirds`] ||
          prevData[dataKey][`${set.setKey}_greaterThanTwoThirds`] ||
          prevData[dataKey][`${set.setKey}_unable`]
        ) {
          let limitingFactorControls = <></>;

          switch (prevData[dataKey][`${set.setKey}_limitingFactor`]) {
            case "MUSCLETIGHTNESS":
            case "JOINTSTIFFNESS": {
              limitingFactorControls = (
                <TextField
                  name={`${set.setKey}_limitingFactorNotes`}
                  variant="outlined"
                  fullWidth
                  className="outlineTextAreaFinal"
                  inputRef={register}
                  defaultValue={prevData[dataKey][`${set.setKey}_limitingFactorNotes`]}
                />
              );
              break;
            }
            case "PAIN": {
              limitingFactorControls = renderPainDiscomfortResults(dataKey, set.setKey);
            }
          }

          return (
            <>
              <Typography variant="h4" className="typeHeading">
                {set.friendlyName}
              </Typography>
              <Typography variant="h5" className="greyPillHeading">
                {prevData[dataKey][`${set.setKey}_lessThanOneThird`] ? "< 1/3" : ""}
                {prevData[dataKey][`${set.setKey}_lessThanTwoThirds`] ? "< 2/3" : ""}
                {prevData[dataKey][`${set.setKey}_greaterThanTwoThirds`] ? "> 2/3" : ""}
                {prevData[dataKey][`${set.setKey}_unable`] ? "unable" : ""}
              </Typography>
              <Typography variant="h5" className="greyPillHeading">
                <strong>Objective Range</strong> {prevData[dataKey][`${set.setKey}_objectiveRange`]}
              </Typography>
              <Typography variant="h5" className="greyPillHeading">
                <strong>Limiting Factor</strong> {limitingFactorsFriendly[prevData[dataKey][`${set.setKey}_limitingFactor`] as limitingFactors]}
              </Typography>
              {limitingFactorControls}
            </>
          );
        } else {
          //get all of the MSK mobility listed flags.
          let {
            mskStandingTrunkMobilityAllListed,
            mskStandingSquatMobilityAllListed,
            mskSittingNeckMobilityAllListed,
            mskSittingUpperLimbMobilityAllListed,
            mskLyingHipKneeMobilityAllListed,
            mskLyingAnkleMobilityAllListed,
          } = state;
          let dataChanged = false;

          //ensure that mskStandingMobility will remain true if has been set.
          if (!mskStandingTrunkMobilityAllListed && setType === mskSetType.MOBILITY && dataKey === dataStorePageKeys.MSK_STANDING) {
            mskStandingTrunkMobilityAllListed = true;
            dataChanged = true;
          }

          if (!mskStandingSquatMobilityAllListed && setType === mskSetType.MOBILITY && dataKey === dataStorePageKeys.MSK_STANDING) {
            mskStandingSquatMobilityAllListed = true;
            dataChanged = true;
          }

          if (!mskSittingNeckMobilityAllListed && setType === mskSetType.MOBILITY && dataKey === dataStorePageKeys.MSK_SITTING) {
            mskSittingNeckMobilityAllListed = true;
            dataChanged = true;
          }

          if (!mskSittingUpperLimbMobilityAllListed && setType === mskSetType.MOBILITY && dataKey === dataStorePageKeys.MSK_SITTING) {
            mskSittingUpperLimbMobilityAllListed = true;
            dataChanged = true;
          }

          if (!mskLyingHipKneeMobilityAllListed && setType === mskSetType.MOBILITY && dataKey === dataStorePageKeys.MSK_LYING) {
            mskLyingHipKneeMobilityAllListed = true;
            dataChanged = true;
          }

          if (!mskLyingAnkleMobilityAllListed && setType === mskSetType.MOBILITY && dataKey === dataStorePageKeys.MSK_LYING) {
            mskLyingAnkleMobilityAllListed = true;
            dataChanged = true;
          }

          if (dataChanged) {
            setState((prevState) => ({
              ...prevState,
              mskStandingTrunkMobilityAllListed,
              mskStandingSquatMobilityAllListed,
              mskSittingNeckMobilityAllListed,
              mskSittingUpperLimbMobilityAllListed,
              mskLyingHipKneeMobilityAllListed,
              mskLyingAnkleMobilityAllListed,
            }));
          }
        }
      } else if (setType === mskSetType.STRENGTH) {
        if (prevData[dataKey][`${set.setKey}_discomfort`] !== strengthDiscomfortFactors.SELECT) {
          return (
            <>
              <Typography variant="h4" className="typeHeading">
                {set.friendlyName}
              </Typography>
              <Typography variant="h5" className="greyPillHeading">
                <strong>Grade</strong> {prevData[dataKey][`${set.setKey}_grade`]}
              </Typography>
              <Typography variant="h5" className="greyPillHeading">
                <strong>Discomfort</strong>{" "}
                {prevData[dataKey][`${set.setKey}_discomfort`] === strengthDiscomfortFactors.YES ? strengthDiscomfortFactorsFriendly.YES : strengthDiscomfortFactorsFriendly.NO}
              </Typography>
              {prevData[dataKey][`${set.setKey}_discomfort`] === strengthDiscomfortFactors.YES ? (
                <TextField
                  name={`${set.setKey}_description`}
                  variant="outlined"
                  fullWidth
                  className="outlineTextAreaFinal"
                  inputRef={register}
                  defaultValue={prevData[dataKey][`${set.setKey}_description`]}
                />
              ) : (
                <></>
              )}
            </>
          );
        } else {
          let { mskSittingUpperLimbStrengthAllListed, mskLyingTrunkStrengthAllListed, mskLyingLowerLimbStrengthAllListed } = state;
          let dataChanged = false;

          if (!mskLyingTrunkStrengthAllListed && dataKey === dataStorePageKeys.MSK_SITTING) {
            mskSittingUpperLimbStrengthAllListed = true;
            dataChanged = true;
          }

          if (!mskLyingTrunkStrengthAllListed && dataKey === dataStorePageKeys.MSK_LYING) {
            mskLyingTrunkStrengthAllListed = true;
            dataChanged = true;
          }

          if (!mskLyingLowerLimbStrengthAllListed && dataKey === dataStorePageKeys.MSK_LYING) {
            mskLyingLowerLimbStrengthAllListed = true;
            dataChanged = true;
          }

          if (dataChanged) {
            setState((prevState) => ({
              ...prevState,
              mskSittingUpperLimbStrengthAllListed,
              mskLyingTrunkStrengthAllListed,
              mskLyingLowerLimbStrengthAllListed,
            }));
          }
        }
      }
      return <></>;
    };

    const renderTolerances = (struct: any) => {
      return (
        <StyledAccordion variant="outlined" className="innerOutlinedBox">
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>{struct.friendlyName}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="trialWrapper">
              {!!prevData[struct.dataKey].notTestedReason ? (
                <>{renderNotTestedResults(struct.dataKey)}</>
              ) : (
                <div className="full-width">
                  <Typography variant="h4" className="typeHeading">
                    Test recordings
                  </Typography>
                  <div className="stepTestSection">
                    <span className="stepTime">1:00</span>
                    <Typography variant="h5" className="greyPillHeading">
                      {!!prevData[struct.dataKey].Bpm100 ? `${prevData[struct.dataKey].Bpm100}BPM` : " - "}
                    </Typography>
                    <TextField
                      name={`${struct.dataKey}_comment100`}
                      variant="outlined"
                      label="Comments"
                      className="outlineTextAreaFinal"
                      defaultValue={prevData[struct.dataKey].Comment100}
                    />
                  </div>
                  <div className="stepTestSection">
                    <span className="stepTime">1:30</span>
                    <Typography variant="h5" className="greyPillHeading">
                      {!!prevData[struct.dataKey].Bpm130 ? `${prevData[struct.dataKey].Bpm130}BPM` : " - "}
                    </Typography>
                    <TextField
                      name={`${struct.dataKey}_comment130`}
                      variant="outlined"
                      label="Comments"
                      className="outlineTextAreaFinal"
                      defaultValue={prevData[struct.dataKey].Comment130}
                    />
                  </div>
                  <div className="stepTestSection">
                    <span className="stepTime">2:00</span>
                    <Typography variant="h5" className="greyPillHeading">
                      {!!prevData[struct.dataKey].Bpm200 ? `${prevData[struct.dataKey].Bpm200}BPM` : " - "}
                    </Typography>
                    <TextField
                      name={`${struct.dataKey}_comment200`}
                      variant="outlined"
                      label="Comments"
                      className="outlineTextAreaFinal"
                      defaultValue={prevData[struct.dataKey].Comment200}
                    />
                  </div>
                  <div className="stepTestSection">
                    <span className="stepTime">2:30</span>
                    <Typography variant="h5" className="greyPillHeading">
                      {!!prevData[struct.dataKey].Bpm230 ? `${prevData[struct.dataKey].Bpm230}BPM` : " - "}
                    </Typography>
                    <TextField
                      name={`${struct.dataKey}_comment230`}
                      variant="outlined"
                      label="Comments"
                      className="outlineTextAreaFinal"
                      defaultValue={prevData[struct.dataKey].Comment230}
                    />
                  </div>
                  <div className="stepTestSection">
                    <span className="stepTime">3:00</span>
                    <Typography variant="h5" className="greyPillHeading">
                      {!!prevData[struct.dataKey].Bpm300 ? `${prevData[struct.dataKey].Bpm300}BPM` : " - "}
                    </Typography>
                    <TextField
                      name={`${struct.dataKey}_comment300`}
                      variant="outlined"
                      label="Comments"
                      className="outlineTextAreaFinal"
                      defaultValue={prevData[struct.dataKey].Comment300}
                    />
                  </div>

                  <Typography variant="h4" className="typeHeading">
                    Tolerance Rating
                  </Typography>
                  <Typography variant="h5" className="greyPillHeading">
                    {prevData[struct.dataKey].toleranceRating}
                  </Typography>

                  <Typography variant="h4" className="typeHeading">
                    Assessor observations
                  </Typography>
                  {struct.assessorObservationKeys.map((assessorObservation: any) => {
                    let showObservation = false;
                    let showObservationNote = false;

                    const assessorObservations = (
                      <div>
                        <Typography variant="h5" className="greyPillHeading">
                          <strong>{assessorObservation.friendlyName}</strong>{" "}
                          {assessorObservation.valueKeys.map((valueKey: any) => {
                            //Now that we are at an individual field, check if this field matches the a notesTrigger.
                            //Also check if the notes are already triggered and if the trigger field is actually used.
                            assessorObservation.notes.notesTriggers.forEach((noteTrigger: string) => {
                              if (!!showObservationNote) {
                                showObservationNote = noteTrigger === valueKey.key && prevData[struct.dataKey][valueKey.key] === true;
                              }
                            });
                            //Check if we have an observation value to show. If not, this observation will not be shown.
                            if (prevData[struct.dataKey][valueKey.key] === true && !showObservation) {
                              showObservation = true;
                            }

                            //check if the current valueKey's corresponding value is true, if it is, we want to display
                            //the friendly value instead of a boolean. Otherwise return nothing.
                            return <>{prevData[struct.dataKey][valueKey.key] === true ? valueKey.friendlyName : <></>}</>;
                          })}
                        </Typography>
                        {showObservationNote ? (
                          <TextField
                            name={`${struct.dataKey}_${assessorObservation.notes.notesField}`}
                            variant="outlined"
                            label="Comments"
                            className="outlineTextAreaFinal"
                            defaultValue={prevData[struct.dataKey][assessorObservation.notes.notesField]}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    );

                    //as determined above, if we have something to show, show it.
                    if (showObservation) {
                      return assessorObservations;
                    }

                    return <></>;
                  })}

                  {prevData[struct.dataKey][`${struct.discomfortSetKey}_reportedDiscomfort`] ? (
                    <>
                      <Typography variant="h4" className="typeHeading">
                        Discomfort reported
                      </Typography>
                      {renderPainDiscomfortResults(struct.dataKey, struct.discomfortSetKey)}
                    </>
                  ) : (
                    <></>
                  )}
                  <div>
                    <Typography variant="h4" className="typeHeading">
                      Extra Notes
                    </Typography>
                    <TextField
                      name={`${struct.dataKey}_notes`}
                      variant="outlined"
                      label="notes"
                      fullWidth
                      className="outlineTextAreaFinal"
                      defaultValue={pefaNotes[struct.dataKey] ? pefaNotes[struct.dataKey] : ""}
                    />
                  </div>
                </div>
              )}
            </div>
          </AccordionDetails>
        </StyledAccordion>
      );
    };

    const renderManualHandling = (struct: any) => {
      return (
        <StyledAccordion variant="outlined" className="innerOutlinedBox">
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>{struct.friendlyName}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="trialWrapper">
              {!!prevData[struct.dataKey].notTestedReason ? (
                <>{renderNotTestedResults(struct.dataKey)}</>
              ) : (
                <div className="full-width">
                  <div>
                    <Typography variant="h4" className="typeHeading">
                      Pre test information
                    </Typography>
                    <Typography variant="h5" className="greyPillHeading">
                      <strong>Target</strong> {`${demands[struct.dataKey].value}kg`}
                    </Typography>
                    <Typography variant="h5" className="greyPillHeading">
                      {`${prevData[struct.dataKey].preTestHeartRate}BPM`}
                    </Typography>
                    <Typography variant="h4" className="typeHeading">
                      Test recordings
                    </Typography>
                    {prevData[struct.dataKey].attempts.map((attempt: any) => {
                      return (
                        <div className="mhTestSection">
                          <span className="stepTime">{`#${attempt.attempt}`}</span>
                          <Typography variant="h5" className="greyPillHeading">
                            {`${prevData[struct.dataKey][`weight_${attempt.attempt}`]}kg`}
                          </Typography>
                          <Typography variant="h5" className="greyPillHeading">
                            {`${prevData[struct.dataKey][`bpm_${attempt.attempt}`]}BPM`}
                          </Typography>

                          {prevData[struct.dataKey][`safeRepeats_${attempt.attempt}`] ? (
                            <Typography variant="h5" className="greyPillHeading">
                              Safe x3
                            </Typography>
                          ) : (
                            <></>
                          )}
                          {ManualHandlingDecisions[prevData[struct.dataKey][`decision_${attempt.attempt}`]] ? (
                            <Typography variant="h5" className="greyPillHeading">
                              {prevData[struct.dataKey][`decision_${attempt.attempt}`] === "ASSESSOR_STOPPED" ||
                              prevData[struct.dataKey][`decision_${attempt.attempt}`] === "PARTICIPANT_STOPPED" ? (
                                <strong>{ManualHandlingDecisions[prevData[struct.dataKey][`decision_${attempt.attempt}`]]?.value}</strong>
                              ) : (
                                ManualHandlingDecisions[prevData[struct.dataKey][`decision_${attempt.attempt}`]]?.value
                              )}
                            </Typography>
                          ) : (
                            <></>
                          )}

                          {prevData[struct.dataKey][`decision_${attempt.attempt}`] === "REST_TO_LOWER_HR" ? (
                            <Typography variant="h5" className="greyPillHeading">
                              <strong>Rest time</strong> {prevData[struct.dataKey].recoveryTimeFormatted}
                            </Typography>
                          ) : (
                            <></>
                          )}

                          <TextField
                            name={`${struct.dataKey}_comment_${attempt.attempt}`}
                            variant="outlined"
                            fullWidth
                            label="Comments"
                            className="outlineTextAreaFinalFloor"
                            defaultValue={prevData[struct.dataKey][`comment_${attempt.attempt}`]}
                          />
                        </div>
                      );
                    })}

                    {!!prevData[struct.dataKey].stoppedReason ? (
                      <>
                        <Typography variant="h4" className="typeHeading">
                          Reason for stopping
                        </Typography>
                        <Typography variant="h5" className="greyPillHeading">
                          <strong>{prevData[struct.dataKey].stoppedReason}</strong> {prevData[struct.dataKey].stoppedMessage}
                        </Typography>
                      </>
                    ) : (
                      <></>
                    )}
                    <Typography variant="h4" className="typeHeading">
                      <img src={StarIcon} width="24" alt="Success" /> Safe Weight Achieved
                    </Typography>
                    <Typography variant="h5" className="greyPillHeading">
                      {`${prevData[struct.dataKey].testWeight}kg`}
                    </Typography>
                    {struct.safeMaxKeys.map((safeMaxKey: any) => {
                      if (!!prevData[struct.dataKey][safeMaxKey.key]) {
                        return (
                          <Typography variant="h5" className="greyPillHeading">
                            {safeMaxKey.friendlyName}
                          </Typography>
                        );
                      }
                    })}
                  </div>
                  <Typography variant="h4" className="typeHeading">
                    Assessor observations
                  </Typography>
                  {struct.assessorObservationKeys.map((assessorObservation: any) => {
                    let showObservation = false;
                    let showObservationNote = false;

                    let ratioLKey = "";
                    let ratioRKey = "";

                    const assessorObservations = (
                      <div>
                        <Typography variant="h5" className="greyPillHeading">
                          <strong>{assessorObservation.friendlyName}</strong>{" "}
                          {assessorObservation.valueKeys.map((valueKey: any) => {
                            //Now that we are at an individual field, check if this field matches the a notesTrigger.
                            //Also check if the notes are already triggered and if the trigger field is actually used.
                            assessorObservation.notes.notesTriggers.forEach((noteTrigger: string) => {
                              if (!showObservationNote) {
                                showObservationNote = noteTrigger === valueKey.key && prevData[struct.dataKey][valueKey.key] === true;
                              }
                            });
                            //Check if we have an observation value to show. If not, this observation will not be shown.
                            if (prevData[struct.dataKey][valueKey.key] === true && !showObservation) {
                              showObservation = true;
                            }

                            //check if the current valueKey's corresponding value is true, if it is, we want to display
                            //the friendly value instead of a boolean. Otherwise return nothing.
                            if (prevData[struct.dataKey][valueKey.key] === true) {
                              //Check if this value has an associated ratio.
                              struct.ratioDataKeys.forEach((ratioKey: any) => {
                                if (ratioKey.key === valueKey.key && !ratioLKey && !ratioRKey) {
                                  ratioLKey = ratioKey.ratioLKey;
                                  ratioRKey = ratioKey.ratioRKey;
                                }
                              });

                              return valueKey.friendlyName;
                            }

                            return <></>;
                          })}
                        </Typography>
                        {!!ratioLKey && !!ratioRKey ? (
                          <Typography variant="h5" className="greyPillHeading">{`${prevData[struct.dataKey][ratioLKey]} : ${prevData[struct.dataKey][ratioRKey]}`}</Typography>
                        ) : (
                          <></>
                        )}
                        {showObservationNote ? (
                          <TextField
                            name={`${struct.dataKey}_${assessorObservation.notes.notesField}`}
                            variant="outlined"
                            label="Comments"
                            className="outlineTextAreaFinal"
                            defaultValue={prevData[struct.dataKey][assessorObservation.notes.notesField]}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    );

                    //as determined above, if we have something to show, show it.
                    if (showObservation) {
                      return assessorObservations;
                    }

                    return <></>;
                  })}
                  {prevData[struct.dataKey][`${struct.discomfortSetKey}_reportedDiscomfort`] ? (
                    <>
                      <Typography variant="h4" className="typeHeading">
                        Discomfort reported
                      </Typography>
                      {renderPainDiscomfortResults(struct.dataKey, struct.discomfortSetKey)}
                    </>
                  ) : (
                    <></>
                  )}

                  <Typography variant="h4" className="typeHeading">
                    Extra Notes
                  </Typography>
                  <TextField
                    name={`${struct.dataKey}_notes`}
                    variant="outlined"
                    label="notes"
                    fullWidth
                    className="outlineTextAreaFinal"
                    defaultValue={pefaNotes[struct.dataKey] ? pefaNotes[struct.dataKey] : ""}
                  />
                </div>
              )}
            </div>
          </AccordionDetails>
        </StyledAccordion>
      );
    };

    const submitForm = (data: any, forwardTarget: any) => {
      // take snapshot of assessment and store against booking
      const pefaData = { ...props.rootStore?.dataStore.pefaData[pefaAssessment.id] };
      props.rootStore?.pefaStore.nextSubmit(pefaData, pefaAssessment.id);
      history.push(forwardTarget);
    };

    return (
      <div className="FormStyler">
        <div className="AssessmentDetails">
          <Grid item container direction="column" xs={12}>
            <Stepper targetStep={"Preview & Recomendations"} />
            <Container maxWidth={"lg"}>
              <Grid item container xs={12} direction="row">
                <Grid item container xs={9}>
                  <form style={{ flexGrow: 1 }}>
                    <Card className="uCard" variant="outlined">
                      <Typography variant="h2" className="heroHeading">
                        <img src={Success} alt="Success" /> Preview results
                      </Typography>
                    </Card>
                    {/*------- General Observations ------------------------------------------------------------------------- */}
                    <StyledAccordion variant="outlined" className="innerOutlinedBox">
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography>General Observations</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="trialWrapper">
                          {prevData[dataStorePageKeys.GENERAL_OBSERVATIONS] ? (
                            <div>
                              {prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].yesAllNilSignificant ||
                              (prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].postureNilSignificant &&
                                prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].buildNilSignificant &&
                                prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].oedemainflammationNilSignificant &&
                                prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].scarsNilSignificant &&
                                prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].gaitNilSignificant &&
                                prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].bracesProsthesisNilSignificant) ? (
                                <Typography variant="h5" className="greyPillHeading">
                                  All Nil Significant <img src={TickIcon} alt="tick" />
                                </Typography>
                              ) : (
                                <>
                                  <Typography variant="h4" className="typeHeading">
                                    Posture
                                  </Typography>
                                  {prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].postureNilSignificant ? (
                                    <Typography variant="h5" className="greyPillHeading">
                                      Nil Significant <img src={TickIcon} alt="tick" />
                                    </Typography>
                                  ) : (
                                    <>
                                      <Grid item container xs={12} direction="row">
                                        {prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].postureForwardHeadedPosture ? (
                                          <Typography variant="h5" className="greyPillHeading">
                                            Forward head posture
                                          </Typography>
                                        ) : (
                                          <></>
                                        )}
                                        {prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].postureFlatBackPosture ? (
                                          <Typography variant="h5" className="greyPillHeading">
                                            Flat Back Posture
                                          </Typography>
                                        ) : (
                                          <></>
                                        )}
                                        {prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].postureSwayBackPosture ? (
                                          <Typography variant="h5" className="greyPillHeading">
                                            Sway Back Posture
                                          </Typography>
                                        ) : (
                                          <></>
                                        )}
                                        {prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].postureScapularWinging ? (
                                          <Typography variant="h5" className="greyPillHeading">
                                            Scapular Winging
                                          </Typography>
                                        ) : (
                                          <></>
                                        )}
                                        {prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].postureRoundedShoulders ? (
                                          <Typography variant="h5" className="greyPillHeading">
                                            Rounded Shoulders
                                          </Typography>
                                        ) : (
                                          <></>
                                        )}
                                        {prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].postureGenusVarusBow ? (
                                          <Typography variant="h5" className="greyPillHeading">
                                            Genus Varus (Bow)
                                          </Typography>
                                        ) : (
                                          <></>
                                        )}
                                        {prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].postureAssymetry ? (
                                          <Typography variant="h5" className="greyPillHeading">
                                            Assymetry
                                          </Typography>
                                        ) : (
                                          <></>
                                        )}
                                      </Grid>
                                      {prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].postureAssymetry ? (
                                        <TextField
                                          name="postureNotes"
                                          variant="outlined"
                                          label="Comments"
                                          fullWidth
                                          className="outlineTextAreaFinal"
                                          inputRef={register}
                                          defaultValue={prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].postureNotes}
                                        />
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  )}
                                  <Typography variant="h4" className="typeHeading">
                                    Build
                                  </Typography>
                                  {prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].buildNilSignificant ? (
                                    <Typography variant="h5" className="greyPillHeading">
                                      Nil Significant <img src={TickIcon} alt="tick" />
                                    </Typography>
                                  ) : (
                                    <>
                                      <Grid item container xs={12} direction="row">
                                        {prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].buildGeneralReducedMuscleBulk ? (
                                          <Typography variant="h5" className="greyPillHeading">
                                            General reduced muscle bulk
                                          </Typography>
                                        ) : (
                                          <></>
                                        )}
                                        {prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].buildMuscularBuild ? (
                                          <Typography variant="h5" className="greyPillHeading">
                                            Muscular build
                                          </Typography>
                                        ) : (
                                          <></>
                                        )}
                                        {prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].buildIncreasedAdiposeTissue ? (
                                          <Typography variant="h5" className="greyPillHeading">
                                            Increased adipose tissue
                                          </Typography>
                                        ) : (
                                          <></>
                                        )}
                                        {prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].buildMuscularAtrophy ? (
                                          <Typography variant="h5" className="greyPillHeading">
                                            Muscular atrophy
                                          </Typography>
                                        ) : (
                                          <></>
                                        )}
                                      </Grid>
                                      {prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].buildMuscularAtrophy ? (
                                        <TextField
                                          name="buildNotes"
                                          variant="outlined"
                                          label="Comments"
                                          fullWidth
                                          className="outlineTextAreaFinal"
                                          defaultValue={prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].buildNotes}
                                        />
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  )}
                                  <Typography variant="h4" className="typeHeading">
                                    Oedema &amp; Inflammation
                                  </Typography>
                                  {prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].oedemainflammationNilSignificant ? (
                                    <Typography variant="h5" className="greyPillHeading">
                                      Nil Significant <img src={TickIcon} alt="tick" />
                                    </Typography>
                                  ) : (
                                    <>
                                      <Grid item container xs={12} direction="row">
                                        {!!prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].oedemaLocation ? (
                                          <Typography variant="h5" className="greyPillHeading">
                                            <strong>Location</strong> {prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].oedemaLocation}
                                          </Typography>
                                        ) : (
                                          <></>
                                        )}
                                        {prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].oedemaSeverityMild ||
                                        prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].oedemaSeverityModerate ||
                                        prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].oedemaSeveritySevere ? (
                                          <Typography variant="h5" className="greyPillHeading">
                                            <strong>Severity</strong> {prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].oedemaSeverityMild ? "Mild" : ""}
                                            {prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].oedemaSeverityModerate ? "Moderate" : ""}
                                            {prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].oedemaSeveritySevere ? "Severe" : ""}
                                          </Typography>
                                        ) : (
                                          <></>
                                        )}
                                      </Grid>
                                      <TextField
                                        name="oedemainflammationNotes"
                                        variant="outlined"
                                        label="Comments"
                                        fullWidth
                                        className="outlineTextAreaFinal"
                                        defaultValue={prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].oedemainflammationNotes}
                                      />
                                    </>
                                  )}
                                  <Typography variant="h4" className="typeHeading">
                                    Scars
                                  </Typography>
                                  {prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].scarsNilSignificant ? (
                                    <Typography variant="h5" className="greyPillHeading">
                                      Nil Significant <img src={TickIcon} alt="tick" />
                                    </Typography>
                                  ) : (
                                    <TextField
                                      name="scarNotes"
                                      variant="outlined"
                                      label="Comments"
                                      fullWidth
                                      className="outlineTextAreaFinal"
                                      defaultValue={prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].scarNotes}
                                    />
                                  )}
                                  <Typography variant="h4" className="typeHeading">
                                    Gait
                                  </Typography>
                                  {prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].gaitNilSignificant ? (
                                    <Typography variant="h5" className="greyPillHeading">
                                      Nil Significant <img src={TickIcon} alt="tick" />
                                    </Typography>
                                  ) : (
                                    <>
                                      {!!prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].gaitReducedWeightBearingLeft ? (
                                        <Typography variant="h5" className="greyPillHeading">
                                          <strong>Reduced weight bearing left:</strong> {prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].gaitReducedWeightBearingLeft}
                                        </Typography>
                                      ) : (
                                        <></>
                                      )}
                                      {!!prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].gaitReducedWeightBearingRight ? (
                                        <Typography variant="h5" className="greyPillHeading">
                                          <strong>Reduced weight bearing right:</strong> {prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].gaitReducedWeightBearingRight}
                                        </Typography>
                                      ) : (
                                        <></>
                                      )}
                                      {!!prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].gaitTrendelenburg ? (
                                        <Typography variant="h5" className="greyPillHeading">
                                          <strong>Trendelenburg</strong> {prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].gaitTrendelenburg}
                                        </Typography>
                                      ) : (
                                        <></>
                                      )}
                                      <TextField
                                        name="gaitNotes"
                                        variant="outlined"
                                        label="Comments"
                                        fullWidth
                                        className="outlineTextAreaFinal"
                                        defaultValue={prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].gaitNotes}
                                      />
                                    </>
                                  )}
                                  <Typography variant="h4" className="typeHeading">
                                    Braces, prosthesis etc
                                  </Typography>
                                  {prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].bracesProsthesisNilSignificant ? (
                                    <Typography variant="h5" className="greyPillHeading">
                                      Nil Significant <img src={TickIcon} alt="tick" />
                                    </Typography>
                                  ) : (
                                    <>
                                      {!!prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].bracesProsthesisProsthesis ? (
                                        <Typography variant="h5" className="greyPillHeading">
                                          <strong>Prosthesis</strong> {prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].bracesProsthesisProsthesis}
                                        </Typography>
                                      ) : (
                                        <></>
                                      )}
                                      {!!prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].bracesProsthesisBrace ? (
                                        <Typography variant="h5" className="greyPillHeading">
                                          <strong>Brace</strong> {prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].bracesProsthesisBrace}
                                        </Typography>
                                      ) : (
                                        <></>
                                      )}
                                      {!!prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].bracesProsthesisOtherSupportDevice ? (
                                        <Typography variant="h5" className="greyPillHeading">
                                          <strong>Other support or device</strong> {prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].bracesProsthesisOtherSupportDevice}
                                        </Typography>
                                      ) : (
                                        <></>
                                      )}
                                      <TextField
                                        name="bracesProsthesisNotes"
                                        variant="outlined"
                                        label="Comments"
                                        fullWidth
                                        className="outlineTextAreaFinal"
                                        defaultValue={prevData[dataStorePageKeys.GENERAL_OBSERVATIONS].bracesProsthesisNotes}
                                      />
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          ) : (
                            <>Not Tested</>
                          )}
                        </div>
                      </AccordionDetails>
                    </StyledAccordion>

                    {/*------- Musculoskeletal ------------------------------------------------------------------------- */}

                    <StyledAccordion variant="outlined" className="innerOutlinedBox">
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography>Musculoskeletal Screen</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div>
                          <Typography variant="h3" className="typeSectionHeading">
                            Mobility
                          </Typography>
                          <div className="trialWrapper">
                            <div>
                              <Typography variant="h4" className="typeHeading">
                                Trunk
                              </Typography>
                              {prevData[dataStorePageKeys.MSK_STANDING] ? (
                                <>
                                  {prevData[dataStorePageKeys.MSK_STANDING].trunkMobilityPainFree ? (
                                    <Typography variant="h5" className="greyPillHeading">
                                      All within functional limits painfree <img src={TickIcon} alt="tick" />
                                    </Typography>
                                  ) : (
                                    <>
                                      <div>
                                        {mskStandingTrunkMobilityDataKeys.map((element) => {
                                          return renderMSKTestResults(dataStorePageKeys.MSK_STANDING, element, mskSetType.MOBILITY);
                                        })}
                                      </div>
                                      {state.mskStandingTrunkMobilityAllListed ? (
                                        <>
                                          <div className="previewSection"></div>
                                          <div>
                                            <Typography variant="h5" className="greyPillHeading">
                                              All others within functional limits painfree <img src={TickIcon} alt="tick" />
                                            </Typography>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  )}
                                </>
                              ) : (
                                <>Not Tested</>
                              )}
                            </div>
                          </div>
                          <div className="trialWrapper">
                            <div>
                              <Typography variant="h4" className="typeHeading">
                                Squat
                              </Typography>
                              {/*------- Standing - Squat ------- */}
                              {prevData[dataStorePageKeys.MSK_STANDING] ? (
                                <>
                                  {prevData[dataStorePageKeys.MSK_STANDING].squatFullPainFree ? (
                                    <Typography variant="h5" className="greyPillHeading">
                                      All within functional limits painfree <img src={TickIcon} alt="tick" />
                                    </Typography>
                                  ) : (
                                    <>
                                      {mskStandingSquatMobilityDataKeys.map((element) => {
                                        return renderMSKTestResults(dataStorePageKeys.MSK_STANDING, element, mskSetType.MOBILITY);
                                      })}
                                      {state.mskStandingSquatMobilityAllListed ? (
                                        <>
                                          <div className="previewSection"></div>
                                          <div>
                                            <Typography variant="h5" className="greyPillHeading">
                                              All others within functional limits painfree <img src={TickIcon} alt="tick" />
                                            </Typography>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  )}
                                </>
                              ) : (
                                <>Not Tested</>
                              )}
                            </div>
                          </div>
                          <div className="trialWrapper">
                            <div>
                              <Typography variant="h4" className="typeHeading">
                                Neck
                              </Typography>
                              {/*------- Sitting - Neck ------- */}
                              {prevData[dataStorePageKeys.MSK_SITTING] ? (
                                <>
                                  {prevData[dataStorePageKeys.MSK_SITTING].neckMobilityPainFree ? (
                                    <Typography variant="h5" className="greyPillHeading">
                                      All within functional limits painfree <img src={TickIcon} alt="tick" />
                                    </Typography>
                                  ) : (
                                    <>
                                      {mskSittingNeckMobilityDataKeys.map((element) => {
                                        return renderMSKTestResults(dataStorePageKeys.MSK_SITTING, element, mskSetType.MOBILITY);
                                      })}
                                      {state.mskSittingNeckMobilityAllListed ? (
                                        <>
                                          <div className="previewSection"></div>
                                          <div>
                                            <Typography variant="h5" className="greyPillHeading">
                                              All others within functional limits painfree <img src={TickIcon} alt="tick" />
                                            </Typography>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  )}
                                </>
                              ) : (
                                <>Not Tested</>
                              )}
                            </div>
                          </div>
                          <div className="trialWrapper">
                            <div>
                              <Typography variant="h4" className="typeHeading">
                                Upper Limbs
                              </Typography>
                              {/*------- Sitting - Neck ------- */}
                              {prevData[dataStorePageKeys.MSK_SITTING] ? (
                                <>
                                  {prevData[dataStorePageKeys.MSK_SITTING].upperLimbMobilityPainFree ? (
                                    <Typography variant="h5" className="greyPillHeading">
                                      All within functional limits painfree <img src={TickIcon} alt="tick" />
                                    </Typography>
                                  ) : (
                                    <>
                                      {mskSittingUpperLimbMobilityDataKeys.map((element) => {
                                        return renderMSKTestResults(dataStorePageKeys.MSK_SITTING, element, mskSetType.MOBILITY);
                                      })}
                                      {state.mskSittingUpperLimbMobilityAllListed ? (
                                        <>
                                          <div className="previewSection"></div>
                                          <div>
                                            <Typography variant="h5" className="greyPillHeading">
                                              All others within functional limits painfree <img src={TickIcon} alt="tick" />
                                            </Typography>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  )}
                                </>
                              ) : (
                                <>Not Tested</>
                              )}
                            </div>
                          </div>
                          <div className="trialWrapper">
                            <div>
                              <Typography variant="h4" className="typeHeading">
                                Hip and Knees
                              </Typography>
                              {/*------- Lying - Hip and Knees ------- */}
                              {prevData[dataStorePageKeys.MSK_LYING] ? (
                                <>
                                  {prevData[dataStorePageKeys.MSK_LYING].hipMobilityPainFree ? (
                                    <Typography variant="h5" className="greyPillHeading">
                                      All within functional limits painfree <img src={TickIcon} alt="tick" />
                                    </Typography>
                                  ) : (
                                    <>
                                      {mskLyingHipKneeMobilityDataKeys.map((element) => {
                                        return renderMSKTestResults(dataStorePageKeys.MSK_LYING, element, mskSetType.MOBILITY);
                                      })}
                                      {state.mskLyingHipKneeMobilityAllListed ? (
                                        <>
                                          <div className="previewSection"></div>
                                          <div>
                                            <Typography variant="h5" className="greyPillHeading">
                                              All others within functional limits painfree <img src={TickIcon} alt="tick" />
                                            </Typography>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  )}
                                </>
                              ) : (
                                <>Not Tested</>
                              )}
                            </div>
                          </div>
                          <div className="trialWrapper">
                            <div>
                              <Typography variant="h4" className="typeHeading">
                                Ankles
                              </Typography>
                              {/*------- Lying - Hip and Knees ------- */}
                              {prevData[dataStorePageKeys.MSK_LYING] ? (
                                <>
                                  {prevData[dataStorePageKeys.MSK_LYING].ankleMobilityPainFree ? (
                                    <Typography variant="h5" className="greyPillHeading">
                                      All within functional limits painfree <img src={TickIcon} alt="tick" />
                                    </Typography>
                                  ) : (
                                    <>
                                      {mskLyingAnkleMobilityDataKeys.map((element) => {
                                        return renderMSKTestResults(dataStorePageKeys.MSK_LYING, element, mskSetType.MOBILITY);
                                      })}
                                      {state.mskLyingAnkleMobilityAllListed ? (
                                        <>
                                          <div className="previewSection"></div>
                                          <div>
                                            <Typography variant="h5" className="greyPillHeading">
                                              All others within functional limits painfree <img src={TickIcon} alt="tick" />
                                            </Typography>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  )}
                                </>
                              ) : (
                                <>Not Tested</>
                              )}
                            </div>
                          </div>
                          <Typography variant="h3" className="typeSectionHeading">
                            Strength
                          </Typography>
                          <div className="trialWrapper">
                            <div>
                              <Typography variant="h4" className="typeHeading">
                                Trunk
                              </Typography>
                              {prevData[dataStorePageKeys.MSK_LYING] ? (
                                <>
                                  {prevData[dataStorePageKeys.MSK_LYING].trunkStrengthPainFree ? (
                                    <Typography variant="h5" className="greyPillHeading">
                                      All within functional limits painfree <img src={TickIcon} alt="tick" />
                                    </Typography>
                                  ) : (
                                    <>
                                      <div>
                                        {mskLyingTrunkStrengthDataKeys.map((element) => {
                                          return renderMSKTestResults(dataStorePageKeys.MSK_LYING, element, mskSetType.STRENGTH);
                                        })}
                                      </div>
                                      {state.mskLyingTrunkStrengthAllListed ? (
                                        <>
                                          <div className="previewSection"></div>
                                          <div>
                                            <Typography variant="h5" className="greyPillHeading">
                                              All others within functional limits painfree <img src={TickIcon} alt="tick" />
                                            </Typography>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  )}
                                </>
                              ) : (
                                <>Not Tested</>
                              )}
                            </div>
                          </div>
                          <div className="trialWrapper">
                            <div>
                              <Typography variant="h4" className="typeHeading">
                                Upper Limbs
                              </Typography>
                              {prevData[dataStorePageKeys.MSK_SITTING] ? (
                                <>
                                  {prevData[dataStorePageKeys.MSK_SITTING].upperLimbStrengthPainFree ? (
                                    <Typography variant="h5" className="greyPillHeading">
                                      All within functional limits painfree <img src={TickIcon} alt="tick" />
                                    </Typography>
                                  ) : (
                                    <>
                                      <div>
                                        {mskSittingUpperLimbStrengthDataKeys.map((element) => {
                                          return renderMSKTestResults(dataStorePageKeys.MSK_SITTING, element, mskSetType.STRENGTH);
                                        })}
                                      </div>
                                      {state.mskSittingUpperLimbStrengthAllListed ? (
                                        <>
                                          <div className="previewSection"></div>
                                          <div>
                                            <Typography variant="h5" className="greyPillHeading">
                                              All others within functional limits painfree <img src={TickIcon} alt="tick" />
                                            </Typography>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  )}
                                </>
                              ) : (
                                <>Not Tested</>
                              )}
                            </div>
                          </div>
                          <div className="trialWrapper">
                            <div>
                              <Typography variant="h4" className="typeHeading">
                                Lower Limbs
                              </Typography>
                              {prevData[dataStorePageKeys.MSK_LYING] ? (
                                <>
                                  {prevData[dataStorePageKeys.MSK_LYING].ankleStrengthPainFree ? (
                                    <Typography variant="h5" className="greyPillHeading">
                                      All within functional limits painfree <img src={TickIcon} alt="tick" />
                                    </Typography>
                                  ) : (
                                    <>
                                      <div>
                                        {mskLyingAnkleStrengthDataKeys.map((element) => {
                                          return renderMSKTestResults(dataStorePageKeys.MSK_LYING, element, mskSetType.STRENGTH);
                                        })}
                                      </div>
                                      {state.mskLyingLowerLimbStrengthAllListed ? (
                                        <>
                                          <div className="previewSection"></div>
                                          <div>
                                            <Typography variant="h5" className="greyPillHeading">
                                              All others within functional limits painfree <img src={TickIcon} alt="tick" />
                                            </Typography>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  )}
                                </>
                              ) : (
                                <>Not Tested</>
                              )}
                            </div>
                          </div>
                          <div className="trialWrapper">
                            <div>
                              <Typography variant="h3" className="typeSectionHeading">
                                Special Tests
                              </Typography>
                              <Typography variant="h4" className="typeHeading">
                                Empty Can Test
                              </Typography>
                              <Grid item container xs={12} direction="row">
                                <Typography variant="h5" className="greyPillHeading">
                                  <strong>Left</strong>{" "}
                                  {prevData[dataStorePageKeys.MSK_SITTING] ? (
                                    <>
                                      {prevData[dataStorePageKeys.MSK_SITTING].leftEmptyCanTestNegative && "Negative"}
                                      {prevData[dataStorePageKeys.MSK_SITTING].leftEmptyCanTestPositiveWeakness && "Positive Weakness"}
                                      {prevData[dataStorePageKeys.MSK_SITTING].leftEmptyCanTestPositiveDiscomfort && "Positive Discomfort"}
                                    </>
                                  ) : (
                                    <>Not Tested</>
                                  )}
                                </Typography>
                                <Typography variant="h5" className="greyPillHeading">
                                  <strong>Right</strong>{" "}
                                  {prevData[dataStorePageKeys.MSK_SITTING] ? (
                                    <>
                                      {prevData[dataStorePageKeys.MSK_SITTING].rightEmptyCanTestNegative && "Negative"}
                                      {prevData[dataStorePageKeys.MSK_SITTING].rightEmptyCanTestPositiveWeakness && "Positive Weakness"}
                                      {prevData[dataStorePageKeys.MSK_SITTING].rightEmptyCanTestPositiveDiscomfort && "Positive Discomfort"}
                                    </>
                                  ) : (
                                    <>Not Tested</>
                                  )}
                                </Typography>
                              </Grid>
                              <Typography variant="h4" className="typeHeading">
                                Grip Strength
                              </Typography>
                              <Grid item container xs={12} direction="row">
                                <Typography variant="h5" className="greyPillHeading">
                                  <strong>Left</strong>{" "}
                                  {prevData[dataStorePageKeys.MSK_SITTING] ? <>{`${prevData[dataStorePageKeys.MSK_SITTING].leftJamarGripTrialResult}kg`}</> : <>Not Tested</>}
                                </Typography>
                                <Typography variant="h5" className="greyPillHeading">
                                  <strong>Right</strong>{" "}
                                  {prevData[dataStorePageKeys.MSK_SITTING] ? <>{`${prevData[dataStorePageKeys.MSK_SITTING].rightJamarGripTrialResult}kg`}</> : <>Not Tested</>}
                                </Typography>
                              </Grid>
                              <Typography variant="h4" className="typeHeading">
                                Hamstring Flexibility
                              </Typography>
                              <Grid item container xs={12} direction="row">
                                <Typography variant="h5" className="greyPillHeading">
                                  <strong>Left</strong>{" "}
                                  {prevData[dataStorePageKeys.MSK_LYING] ? <>{`${prevData[dataStorePageKeys.MSK_LYING].hamstringLeftSLR}deg`}</> : <>Not Tested</>}
                                </Typography>
                                <Typography variant="h5" className="greyPillHeading">
                                  <strong>Right</strong>{" "}
                                  {prevData[dataStorePageKeys.MSK_LYING] ? <>{`${prevData[dataStorePageKeys.MSK_LYING].hamstringRightSLR}deg`}</> : <>Not Tested</>}
                                </Typography>
                              </Grid>
                              <Typography variant="h4" className="typeHeading">
                                Trunk Stability
                              </Typography>
                              <Typography variant="h4" className="typeHeading">
                                Trunk
                              </Typography>
                              <Typography variant="h5" className="greyPillHeading">
                                <strong>Grade</strong>{" "}
                                {prevData[dataStorePageKeys.MSK_LYING] ? (
                                  <>
                                    {prevData[dataStorePageKeys.MSK_LYING].trunkStabilityGrade === "NT" ? "Not tested" : prevData[dataStorePageKeys.MSK_LYING].trunkStabilityGrade}
                                  </>
                                ) : (
                                  <>Not Tested</>
                                )}
                              </Typography>
                              <Typography variant="h4" className="typeHeading">
                                Shoulder Stability
                              </Typography>
                              <Typography variant="h4" className="typeHeading">
                                Left Shoulder
                              </Typography>
                              <Typography variant="h5" className="greyPillHeading">
                                <strong>Left</strong>{" "}
                                {prevData[dataStorePageKeys.MSK_LYING] ? (
                                  <>
                                    {prevData[dataStorePageKeys.MSK_LYING].leftShoulderStabilityGrade === "NT"
                                      ? "Not tested"
                                      : prevData[dataStorePageKeys.MSK_LYING].leftShoulderStabilityGrade}
                                  </>
                                ) : (
                                  <>Not Tested</>
                                )}
                              </Typography>
                              <Typography variant="h4" className="typeHeading">
                                Right Shoulder
                              </Typography>
                              <Typography variant="h5" className="greyPillHeading">
                                <strong>Right</strong>{" "}
                                {prevData[dataStorePageKeys.MSK_LYING] ? (
                                  <>
                                    {prevData[dataStorePageKeys.MSK_LYING]?.rightShoulderStabilityGrade === "NT"
                                      ? "Not tested"
                                      : prevData[dataStorePageKeys.MSK_LYING].rightShoulderStabilityGrade}
                                  </>
                                ) : (
                                  <>Not Tested</>
                                )}
                              </Typography>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                    </StyledAccordion>

                    {/*------- Balance ------------------------------------------------------------------------- */}

                    <StyledAccordion variant="outlined" className="innerOutlinedBox">
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography>Balance Test</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="trialWrapper">
                          {prevData[dataStorePageKeys.BALANCE] ? (
                            <>
                              {!!prevData[dataStorePageKeys.BALANCE].notTestedReason ? (
                                <>{renderNotTestedResults(dataStorePageKeys.BALANCE)}</>
                              ) : (
                                <div className="full-width">
                                  <Typography variant="h4" className="typeHeading">
                                    Test Recordings
                                  </Typography>

                                  {/* ------------- Left Leg Eyes Open ----------------*/}
                                  <Typography variant="h4" className="typeHeading">
                                    Left Leg - eyes open
                                  </Typography>
                                  <Grid item container xs={12} direction="row">
                                    {!!prevData[dataStorePageKeys.BALANCE].leftLegEyesOpenAttempt1 ? (
                                      <Typography variant="h5" className="greyPillHeading">
                                        {`${prevData[dataStorePageKeys.BALANCE].leftLegEyesOpenAttempt1} 
                                    sec`}
                                      </Typography>
                                    ) : (
                                      <></>
                                    )}
                                    {!!prevData[dataStorePageKeys.BALANCE].leftLegEyesOpenAttempt2 ? (
                                      <Typography variant="h5" className="greyPillHeading">
                                        {`${prevData[dataStorePageKeys.BALANCE].leftLegEyesOpenAttempt2} 
                                    sec`}
                                      </Typography>
                                    ) : (
                                      <></>
                                    )}
                                    {!!prevData[dataStorePageKeys.BALANCE].leftLegEyesOpenAttempt3 ? (
                                      <Typography variant="h5" className="greyPillHeading">
                                        {`${prevData[dataStorePageKeys.BALANCE].leftLegEyesOpenAttempt3} 
                                    sec`}
                                      </Typography>
                                    ) : (
                                      <></>
                                    )}
                                    <Typography variant="h5" className="greyPillHeading">
                                      {prevData[dataStorePageKeys.BALANCE].standardPillowUsed1 && "Pillow"}
                                      {prevData[dataStorePageKeys.BALANCE].participantDeclinedPillow1 && "Declined"}
                                    </Typography>
                                    <TextField
                                      name="leftLegEyesOpenComments"
                                      variant="outlined"
                                      label="Comments"
                                      className="outlineTextAreaFinal"
                                      defaultValue={prevData[dataStorePageKeys.BALANCE].leftLegEyesOpenComments}
                                      inputRef={register}
                                    />
                                  </Grid>

                                  {/* ------------- Right Leg Eyes Open ----------------*/}
                                  <Typography variant="h4" className="typeHeading">
                                    Right Leg - eyes open
                                  </Typography>
                                  <Grid item container xs={12} direction="row">
                                    {!!prevData[dataStorePageKeys.BALANCE].rightLegEyesOpenAttempt1 ? (
                                      <Typography variant="h5" className="greyPillHeading">
                                        {`${prevData[dataStorePageKeys.BALANCE].rightLegEyesOpenAttempt1} 
                                    sec`}
                                      </Typography>
                                    ) : (
                                      <></>
                                    )}
                                    {!!prevData[dataStorePageKeys.BALANCE].rightLegEyesOpenAttempt2 ? (
                                      <Typography variant="h5" className="greyPillHeading">
                                        {`${prevData[dataStorePageKeys.BALANCE].rightLegEyesOpenAttempt2} 
                                    sec`}
                                      </Typography>
                                    ) : (
                                      <></>
                                    )}
                                    {!!prevData[dataStorePageKeys.BALANCE].rightLegEyesOpenAttempt3 ? (
                                      <Typography variant="h5" className="greyPillHeading">
                                        {`${prevData[dataStorePageKeys.BALANCE].rightLegEyesOpenAttempt3} 
                                    sec`}
                                      </Typography>
                                    ) : (
                                      <></>
                                    )}
                                    <Typography variant="h5" className="greyPillHeading">
                                      {prevData[dataStorePageKeys.BALANCE].standardPillowUsed2 && "Pillow"}
                                      {prevData[dataStorePageKeys.BALANCE].participantDeclinedPillow2 && "Declined"}
                                    </Typography>
                                    <TextField
                                      name="rightLegEyesOpenComments"
                                      variant="outlined"
                                      label="Comments"
                                      className="outlineTextAreaFinal"
                                      defaultValue={prevData[dataStorePageKeys.BALANCE].rightLegEyesOpenComments}
                                      inputRef={register}
                                    />
                                  </Grid>

                                  {/* ------------- Left Leg Eyes Closed ----------------*/}
                                  <Typography variant="h4" className="typeHeading">
                                    Left Leg - eyes closed
                                  </Typography>
                                  <Grid item container xs={12} direction="row">
                                    {!!prevData[dataStorePageKeys.BALANCE].leftLegEyesClosedAttempt1 ? (
                                      <Typography variant="h5" className="greyPillHeading">
                                        {`${prevData[dataStorePageKeys.BALANCE].leftLegEyesClosedAttempt1} 
                                    sec`}
                                      </Typography>
                                    ) : (
                                      <></>
                                    )}
                                    {!!prevData[dataStorePageKeys.BALANCE].leftLegEyesClosedAttempt2 ? (
                                      <Typography variant="h5" className="greyPillHeading">
                                        {`${prevData[dataStorePageKeys.BALANCE].leftLegEyesClosedAttempt2} 
                                    sec`}
                                      </Typography>
                                    ) : (
                                      <></>
                                    )}
                                    {!!prevData[dataStorePageKeys.BALANCE].leftLegEyesClosedAttempt3 ? (
                                      <Typography variant="h5" className="greyPillHeading">
                                        {`${prevData[dataStorePageKeys.BALANCE].leftLegEyesClosedAttempt3} 
                                    sec`}
                                      </Typography>
                                    ) : (
                                      <></>
                                    )}
                                    <Typography variant="h5" className="greyPillHeading">
                                      {prevData[dataStorePageKeys.BALANCE].standardPillowUsed3 && "Pillow"}
                                      {prevData[dataStorePageKeys.BALANCE].participantDeclinedPillow3 && "Declined"}
                                    </Typography>
                                    <TextField
                                      name="leftLegEyesClosedComments"
                                      variant="outlined"
                                      label="Comments"
                                      className="outlineTextAreaFinal"
                                      defaultValue={prevData[dataStorePageKeys.BALANCE].leftLegEyesClosedComments}
                                      inputRef={register}
                                    />
                                  </Grid>

                                  {/* ------------- Right Leg Eyes Open ----------------*/}
                                  <Typography variant="h4" className="typeHeading">
                                    Right Leg - eyes closed
                                  </Typography>
                                  <Grid item container xs={12} direction="row">
                                    {!!prevData[dataStorePageKeys.BALANCE].rightLegEyesClosedAttempt1 ? (
                                      <Typography variant="h5" className="greyPillHeading">
                                        {`${prevData[dataStorePageKeys.BALANCE].rightLegEyesClosedAttempt1} 
                                    sec`}
                                      </Typography>
                                    ) : (
                                      <></>
                                    )}
                                    {!!prevData[dataStorePageKeys.BALANCE].rightLegEyesClosedAttempt2 ? (
                                      <Typography variant="h5" className="greyPillHeading">
                                        {`${prevData[dataStorePageKeys.BALANCE].rightLegEyesClosedAttempt2} 
                                    sec`}
                                      </Typography>
                                    ) : (
                                      <></>
                                    )}
                                    {!!prevData[dataStorePageKeys.BALANCE].rightLegEyesClosedAttempt3 ? (
                                      <Typography variant="h5" className="greyPillHeading">
                                        {`${prevData[dataStorePageKeys.BALANCE].rightLegEyesClosedAttempt3} 
                                    sec`}
                                      </Typography>
                                    ) : (
                                      <></>
                                    )}
                                    <Typography variant="h5" className="greyPillHeading">
                                      {prevData[dataStorePageKeys.BALANCE].standardPillowUsed4 && "Pillow"}
                                      {prevData[dataStorePageKeys.BALANCE].participantDeclinedPillow4 && "Declined"}
                                    </Typography>
                                    <TextField
                                      name="rightLegEyesClosedComments"
                                      variant="outlined"
                                      label="Comments"
                                      className="outlineTextAreaFinal"
                                      defaultValue={prevData[dataStorePageKeys.BALANCE].rightLegEyesClosedComments}
                                      inputRef={register}
                                    />
                                  </Grid>
                                  <div>
                                    <Typography variant="h4" className="typeHeading">
                                      Extra Notes
                                    </Typography>
                                    <TextField
                                      name={`${dataStorePageKeys.BALANCE}_notes`}
                                      variant="outlined"
                                      label="notes"
                                      fullWidth
                                      className="outlineTextAreaFinal"
                                      defaultValue={!!pefaNotes[dataStorePageKeys.BALANCE] ? pefaNotes[dataStorePageKeys.BALANCE] : ""}
                                    />
                                  </div>
                                </div>
                              )}
                            </>
                          ) : (
                            <>Not Tested</>
                          )}
                        </div>
                      </AccordionDetails>
                    </StyledAccordion>

                    {/*------- Fitness ------------------------------------------------------------------------- */}

                    {!!prevData[dataStorePageKeys.FITNESS] ? (
                      <StyledAccordion variant="outlined" className="innerOutlinedBox">
                        <AccordionSummary expandIcon={<ExpandMore />}>
                          <Typography>3 Min Step Test</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="trialWrapper">
                            {prevData[dataStorePageKeys.MSK_LYING] ? (
                              <>
                                {!!prevData[dataStorePageKeys.FITNESS].notTestedReason ? (
                                  <>{renderNotTestedResults(dataStorePageKeys.FITNESS)}</>
                                ) : (
                                  <div className="full-width">
                                    <Typography variant="h4" className="typeHeading">
                                      Pre test information
                                    </Typography>
                                    <Grid item container xs={12} direction="row">
                                      <Typography variant="h5" className="greyPillHeading">
                                        {`
                                    ${prevData[dataStorePageKeys.FITNESS].preTestHeartRate}BPM`}
                                      </Typography>
                                      <Typography variant="h5" className="greyPillHeading">
                                        {!prevData[dataStorePageKeys.FITNESS].startingLeadLeg ? "L" : "R"}
                                      </Typography>
                                    </Grid>
                                    <Typography variant="h4" className="typeHeading">
                                      Test recordings
                                    </Typography>
                                    <div className="stepTestSection">
                                      <span className="stepTime">0:30</span>
                                      <Typography variant="h5" className="greyPillHeading">
                                        {!!prevData[dataStorePageKeys.FITNESS].fitnessBpm030 ? `${prevData[dataStorePageKeys.FITNESS].fitnessBpm030}BPM` : " - "}
                                      </Typography>
                                      <Typography variant="h5" className="greyPillHeading">
                                        {SOBFriendly[prevData[dataStorePageKeys.FITNESS].fitnessSob030 as SOB]}
                                      </Typography>
                                      <Typography variant="h5" className="greyPillHeading">
                                        {!prevData[dataStorePageKeys.FITNESS].leadLeg030 ? "L" : "R"}
                                      </Typography>
                                      <TextField
                                        name="fitnessComment030"
                                        variant="outlined"
                                        label="Comments"
                                        className="outlineTextAreaFinal"
                                        defaultValue={prevData[dataStorePageKeys.FITNESS].fitnessComment030}
                                        inputRef={register}
                                      />
                                    </div>
                                    <div className="stepTestSection">
                                      <span className="stepTime">1:00</span>
                                      <Typography variant="h5" className="greyPillHeading">
                                        {!!prevData[dataStorePageKeys.FITNESS].fitnessBpm100 ? `${prevData[dataStorePageKeys.FITNESS].fitnessBpm100}BPM` : " - "}
                                      </Typography>
                                      <Typography variant="h5" className="greyPillHeading">
                                        {SOBFriendly[prevData[dataStorePageKeys.FITNESS].fitnessSob100 as SOB]}
                                      </Typography>
                                      <Typography variant="h5" className="greyPillHeading">
                                        {!prevData[dataStorePageKeys.FITNESS].leadLeg100 ? "L" : "R"}
                                      </Typography>
                                      <TextField
                                        name="fitnessComment100"
                                        variant="outlined"
                                        label="Comments"
                                        className="outlineTextAreaFinal"
                                        defaultValue={prevData[dataStorePageKeys.FITNESS].fitnessComment100}
                                        inputRef={register}
                                      />
                                    </div>
                                    <div className="stepTestSection">
                                      <span className="stepTime">1:30</span>
                                      <Typography variant="h5" className="greyPillHeading">
                                        {!!prevData[dataStorePageKeys.FITNESS].fitnessBpm130 ? `${prevData[dataStorePageKeys.FITNESS].fitnessBpm130}BPM` : " - "}
                                      </Typography>
                                      <Typography variant="h5" className="greyPillHeading">
                                        {SOBFriendly[prevData[dataStorePageKeys.FITNESS].fitnessSob130 as SOB]}
                                      </Typography>
                                      <Typography variant="h5" className="greyPillHeading">
                                        {!prevData[dataStorePageKeys.FITNESS].leadLeg130 ? "L" : "R"}
                                      </Typography>
                                      <TextField
                                        name="fitnessComment130"
                                        variant="outlined"
                                        label="Comments"
                                        className="outlineTextAreaFinal"
                                        defaultValue={prevData[dataStorePageKeys.FITNESS].fitnessComment130}
                                        inputRef={register}
                                      />
                                    </div>
                                    <div className="stepTestSection">
                                      <span className="stepTime">2:00</span>
                                      <Typography variant="h5" className="greyPillHeading">
                                        {!!prevData[dataStorePageKeys.FITNESS].fitnessBpm200 ? `${prevData[dataStorePageKeys.FITNESS].fitnessBpm200}BPM` : " - "}
                                      </Typography>
                                      <Typography variant="h5" className="greyPillHeading">
                                        {SOBFriendly[prevData[dataStorePageKeys.FITNESS].fitnessSob200 as SOB]}
                                      </Typography>
                                      <Typography variant="h5" className="greyPillHeading">
                                        {!prevData[dataStorePageKeys.FITNESS].leadLeg200 ? "L" : "R"}
                                      </Typography>
                                      <TextField
                                        name="fitnessComment200"
                                        variant="outlined"
                                        label="Comments"
                                        className="outlineTextAreaFinal"
                                        defaultValue={prevData[dataStorePageKeys.FITNESS].fitnessComment200}
                                        inputRef={register}
                                      />
                                    </div>
                                    <div className="stepTestSection">
                                      <span className="stepTime">2:30</span>
                                      <Typography variant="h5" className="greyPillHeading">
                                        {!!prevData[dataStorePageKeys.FITNESS].fitnessBpm230 ? `${prevData[dataStorePageKeys.FITNESS].fitnessBpm230}BPM` : " - "}
                                      </Typography>
                                      <Typography variant="h5" className="greyPillHeading">
                                        {SOBFriendly[prevData[dataStorePageKeys.FITNESS].fitnessSob230 as SOB]}
                                      </Typography>
                                      <Typography variant="h5" className="greyPillHeading">
                                        {!prevData[dataStorePageKeys.FITNESS].leadLeg230 ? "L" : "R"}
                                      </Typography>
                                      <TextField
                                        name="fitnessComment230"
                                        variant="outlined"
                                        label="Comments"
                                        className="outlineTextAreaFinal"
                                        defaultValue={prevData[dataStorePageKeys.FITNESS].fitnessComment230}
                                        inputRef={register}
                                      />
                                    </div>
                                    <div className="stepTestSection">
                                      <span className="stepTime">3:00</span>
                                      <Typography variant="h5" className="greyPillHeading">
                                        {!!prevData[dataStorePageKeys.FITNESS].fitnessBpm300 ? `${prevData[dataStorePageKeys.FITNESS].fitnessBpm300}BPM` : " - "}
                                      </Typography>
                                      <Typography variant="h5" className="greyPillHeading">
                                        {SOBFriendly[prevData[dataStorePageKeys.FITNESS].fitnessSob300 as SOB]}
                                      </Typography>
                                      <Typography variant="h5" className="greyPillHeading">
                                        {!prevData[dataStorePageKeys.FITNESS].leadLeg300 ? "L" : "R"}
                                      </Typography>
                                      <TextField
                                        name="fitnessComment300"
                                        variant="outlined"
                                        label="Comments"
                                        className="outlineTextAreaFinal"
                                        defaultValue={prevData[dataStorePageKeys.FITNESS].fitnessComment300}
                                        inputRef={register}
                                      />
                                    </div>
                                    <Typography variant="h4" className="typeHeading">
                                      Assessor observations
                                    </Typography>
                                    <Typography variant="h5" className="greyPillHeading">
                                      <strong>Shortness of breath</strong> {prevData[dataStorePageKeys.FITNESS].fitnessHrRateRecoveringWell ? "Recovering Well" : <></>}
                                      {prevData[dataStorePageKeys.FITNESS].fitnessHrRateRecoveringSlowly ? "Recovering Slowly" : <></>}
                                      {prevData[dataStorePageKeys.FITNESS].fitnessHrRateNotRecovering ? "Not Recovering" : <></>}
                                    </Typography>
                                    <Typography variant="h5" className="greyPillHeading">
                                      <strong>Skin colour</strong> {prevData[dataStorePageKeys.FITNESS].fitnessTestSkinColourNormal ? "Normal" : <></>}
                                      {prevData[dataStorePageKeys.FITNESS].fitnessTestSkinColourMildRedness ? "Mild Redness" : <></>}
                                      {prevData[dataStorePageKeys.FITNESS].fitnessTestSkinColourSignificantRedness ? "Significant Redness" : <></>}
                                    </Typography>
                                    <Typography variant="h5" className="greyPillHeading">
                                      <strong>Sweating</strong> {prevData[dataStorePageKeys.FITNESS].fitnessTestSweatingNil ? "Nil " : <></>}
                                      {prevData[dataStorePageKeys.FITNESS].fitnessTestSweatingMild ? "Mild " : <></>}
                                      {prevData[dataStorePageKeys.FITNESS].fitnessTestSweatingMod ? "Mod " : <></>}
                                      {prevData[dataStorePageKeys.FITNESS].fitnessTestSweatingSignificant ? "Significant" : <></>}
                                    </Typography>
                                    <Typography variant="h4" className="typeHeading">
                                      Aerobic fitness rating
                                    </Typography>
                                    <Typography variant="h5" className="greyPillHeading">
                                      <strong>1 min recovery heart rate</strong>{" "}
                                      {`
                                  ${prevData[dataStorePageKeys.FITNESS].postTestHeartRate}BPM`}
                                    </Typography>
                                    <Typography variant="h5" className="greyPillHeading">
                                      <strong>Rating</strong> {prevData[dataStorePageKeys.FITNESS].fRating}
                                    </Typography>
                                    <div>
                                      <Typography variant="h4" className="typeHeading">
                                        Extra Notes
                                      </Typography>
                                      <TextField
                                        name={`${dataStorePageKeys.FITNESS}_notes`}
                                        variant="outlined"
                                        label="notes"
                                        fullWidth
                                        className="outlineTextAreaFinal"
                                        defaultValue={pefaNotes[dataStorePageKeys.FITNESS] ? pefaNotes[dataStorePageKeys.FITNESS] : ""}
                                      />
                                    </div>
                                  </div>
                                )}
                              </>
                            ) : (
                              <>Not Tested</>
                            )}
                          </div>
                        </AccordionDetails>
                      </StyledAccordion>
                    ) : (
                      <></>
                    )}

                    {/*------- Postural & Dynamic Tolerances -------------------------------------------------------------- */}

                    {posturalToleranceDataKeys.map((element) => {
                      if (!!prevData[element.dataKey]) {
                        return renderTolerances(element);
                      }
                      return <></>;
                    })}

                    {dynamicToleranceDataKeys.map((element) => {
                      if (!!prevData[element.dataKey]) {
                        return renderTolerances(element);
                      }
                      return <></>;
                    })}

                    {/*------- Manual Handling ---------------------------------------------------------------------------- */}

                    {manualHandlingDataKeys.map((element) => {
                      if (!!prevData[element.dataKey]) {
                        return renderManualHandling(element);
                      }
                      return <></>;
                    })}
                  </form>
                </Grid>
                <Grid item container xs={3} className="RightCol">
                  <ProfileCard
                    firstName={pefaAssessment.pefArequest.worker.firstName}
                    lastName={pefaAssessment.pefArequest.worker.lastName}
                    participantAgeYears={participantAgeYears}
                  />
                  <InTestMedicalClearanceModal
                    firstName={referralDetails.firstName}
                    lastName={referralDetails.lastName}
                    pageKey={dataStorePageKeys.PREVIEW_RESULTS}
                    pefaID={pefaAssessment.id}
                    medicalPractice={medicalHistoryData.medicalPractitioner}
                    medicalPractitioner={medicalHistoryData.medicalPractice}
                    handleSaveInTestMedical={handleSaveInTestMedical}
                  />
                </Grid>
              </Grid>
            </Container>
            <FormNavigator
              forwardTarget={PEFAFINALISATION}
              handleSubmit={handleSubmit(submitForm)}
              disabledNextButton={state.reason !== "" && state.mcText !== "" ? !state.enableNext : false}
              showEndButton={false}
              selectedKey=""
            />
          </Grid>
        </div>
      </div>
    );
  })
);

export default PefaReview;

import { Backdrop, Fade, Modal } from "@material-ui/core";
import React, { Component, ReactNode } from "react";
import "./LoadingIndicatorTheme.scss";

interface LoadingIndicatorProps {
  visible: boolean;
}

interface LoadingIndicatorState {
  isModalOpen: boolean;
}

class LoadingIndicator extends Component<LoadingIndicatorProps, LoadingIndicatorState> {
  handleCloseClick(): void {
    this.setState({
      isModalOpen: false,
    });
  }

  render(): ReactNode {
    return (
      <Modal
        aria-labelledby="modal-title"
        open={this.props.visible}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={this.props.visible}>
          <div className="ModalSuper">
            <div className="ModalBox">
              <div style={{ height: "100px" }}>
                <div className="dna_container">
                  <div className="child">
                    <div className="dna">
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                      <div className="ele">
                        <div className="dot"></div>
                      </div>
                    </div>
                  </div>
                  <div className="loadingIndicatorText">Loading</div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    );
  }
}

export default LoadingIndicator;

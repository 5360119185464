import React, { Component, ReactElement, ReactNode } from "react";
import { Modal, Backdrop, Fade, Button, Slide } from "@material-ui/core";

interface ModalProps {
  title?: string;
  confirmation?: boolean;
  fullWidthWhite?: boolean;
  children?: ReactNode;

  transitionKey?: modalTransitions;
  isOpen?: boolean;
  handleClose?: any;

  buttonText?: string;

  parentState?: any;
}

interface ModalState {
  isModalOpen: boolean;
}

/**
 * This is a list of the accepted modal transitions for the base modal component.
 */
export enum modalTransitions {
  "FADE",
  "SLIDE_UP",
  "SLIDE_DOWN",
}

/**
 * A modal component for a basic modal.
 *
 * NOTE: the prop isOpen is mutually exclusive with the prop buttonText, with isOpen overriding buttonText.
 * Related to this, if you provide the isOpen value, you need to provide the external handleClose function.
 */
class ModalComponent extends Component<ModalProps, ModalState> {
  content: ReactNode;

  static defaultProps = {
    transitionKey: modalTransitions.FADE,
    buttonText: "Open Modal",
  };

  constructor(props: ModalProps) {
    super(props);

    const { children, isOpen } = this.props;

    this.content = children || <></>;
    this.state = {
      isModalOpen: isOpen || false,
    };

    this.handleOpenClick = this.handleOpenClick.bind(this);
    this.handleCloseClick = this.handleCloseClick.bind(this);
    this.renderModalContent = this.renderModalContent.bind(this);
  }

  componentDidUpdate(): void {
    if (this.props.isOpen !== undefined && this.state.isModalOpen !== this.props.isOpen) {
      this.setState({ isModalOpen: this.props.isOpen });
    }
  }

  componentWillUpdate(): void {
    this.content = this.props.children || <></>;
  }

  handleOpenClick(): void {
    this.setState({
      isModalOpen: true,
    });
  }

  handleCloseClick(): void {
    //If a handleClose function has been provided, we want to use it over directly modifying the local state.
    if (!!this.props.handleClose) {
      this.props.handleClose();
      return;
    }
    this.setState({
      isModalOpen: false,
    });
  }

  renderModalContent(): ReactElement<any, any> {
    const { title, confirmation } = this.props;

    return (
      <div className="ModalSuper">
        {confirmation ? (
          <div className="ModalBox">
            {!!title ? <h2 id="modal-title">{title}</h2> : <></>}
            <div>{this.content}</div>
          </div>
        ) : (
          <div>
            {!!title ? <h2 id="modal-title">{title}</h2> : <></>}
            <div>{this.content}</div>
          </div>
        )}
      </div>
    );
  }

  render(): ReactNode {
    const { isModalOpen } = this.state;
    const { buttonText, fullWidthWhite } = this.props;
    return (
      <>
        {buttonText && this.props.isOpen === undefined ? <Button onClick={() => this.handleOpenClick()}>{buttonText}</Button> : <></>}
        <Modal
          aria-labelledby="modal-title"
          open={isModalOpen}
          onClose={this.handleCloseClick}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          {(() => {
            switch (this.props.transitionKey) {
              case modalTransitions.SLIDE_UP:
                return (
                  <Slide direction="up" in={isModalOpen} mountOnEnter unmountOnExit>
                    {fullWidthWhite ? <div className="modalPopFull">{this.renderModalContent()}</div> : <div className="modalPop">{this.renderModalContent()}</div>}
                  </Slide>
                );
              case modalTransitions.SLIDE_DOWN:
                return (
                  <Slide direction="down" in={isModalOpen} mountOnEnter unmountOnExit>
                    <div className="modalSlideDown">{this.renderModalContent()}</div>
                  </Slide>
                );
              default:
                //acceptedModalTransitions.FADE
                return <Fade in={isModalOpen}>{this.renderModalContent()}</Fade>;
            }
          })()}
        </Modal>
      </>
    );
  }
}

export default ModalComponent;

import React, { Component, ReactNode } from "react";
import { FormControlLabel, Accordion, AccordionSummary, AccordionDetails, Checkbox, Typography } from "@material-ui/core";
import { ExpandMore, ErrorOutline } from "@material-ui/icons";
interface AccordionProps {
  label: string;
  inputName: string;
  innerRef?: any;
  edited?: boolean;
  children?: ReactNode;
  checked?: boolean;
  expanded?: boolean;

  innerValidationError?: boolean;
  validationMessage?: string;

  checkedHandler?: any;
  expandedHandler: any;
}

export default class AccordionComponent extends Component<AccordionProps> {
  content: ReactNode;

  componentDidMount(): void {
    this.content = this.props.children;
  }

  componentWillUpdate(nextProps: any): void {
    this.content = nextProps.children;
  }

  render(): ReactNode {
    const { inputName, checkedHandler, checked, label, expandedHandler, expanded, edited, innerValidationError, innerRef } = this.props;
    return (
      <Accordion variant="outlined" expanded={expanded} onChange={() => expandedHandler(!expanded)}>
        <AccordionSummary expandIcon={<ExpandMore />} aria-label="Expand" aria-controls="additional-actions1-content" id="additional-actions1-header">
          {!!this.props.checkedHandler ? (
            <FormControlLabel
              aria-label="Acknowledge"
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
              control={<Checkbox icon={<span className="checkboxIcon"></span>} checkedIcon={<span className="checkboxIconChecked"></span>} color="primary" name={inputName} />}
              onChange={(event) => checkedHandler(event)}
              checked={checked}
              label={label}
              color="primary"
              inputRef={innerRef}
            />
          ) : (
            <Typography className="MuiFormControlLabel-root accordianLabelPadding">{label}</Typography>
          )}
          {!!innerValidationError ? <ErrorOutline className="invalid-feedback" /> : <></>}
          {edited ? <span className="editedTag">Edited</span> : <></>}
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ flexGrow: 1 }}>{this.content}</div>
          <div className="invalidFeedback">{!!this.props.validationMessage ? this.props.validationMessage : <></>}</div>
        </AccordionDetails>
      </Accordion>
    );
  }
}

export enum eHeartrateStatus {
  "STABLE" = "Stable",
  "VARIABLE" = "Variable",
  "INCREASING" = "Increasing",
}
//sample
export default class ratingHelper {
  public static sum(a: number, b: number): number {
    return a + b;
  }

  public static calculateHRStatus(bpm100: number, bpm130: number, bpm200: number, bpm230: number, bpm300: number): string {
    // check if increasing
    const firstLastDiff = bpm300 - bpm100;
    const lastDiff = bpm300 - bpm200;
    if (firstLastDiff > 5 && lastDiff >= 4) {
      return eHeartrateStatus.INCREASING;
    }
    // check if the difference between interval are +-3 difference, if there are at least 2, then it's variable
    const bpms = [bpm100, bpm130, bpm200, bpm230, bpm300];
    const bpmDiff = ratingHelper.diff(bpms);
    const variableCount = bpmDiff.filter((x) => Math.abs(x) >= 3);
    if (variableCount.length >= 2) {
      return eHeartrateStatus.VARIABLE;
    }
    return eHeartrateStatus.STABLE;
  }
  public static diff(ary: number[]): number[] {
    const newArray: number[] = [];
    for (let i = 1; i < ary.length; i++) {
      newArray.push(ary[i] - ary[i - 1]);
    }
    return newArray;
  }
  public static calculateToleranceRating(timeElapsed: number, discomfort: boolean): string {
    switch (true) {
      case timeElapsed <= 30:
        return "N";
      case timeElapsed < 180:
        return "O";
      case timeElapsed >= 180: {
        if (discomfort) {
          return "F";
        } else {
          return "X";
        }
      }
      default:
        return "N";
    }
  }
}

export const mskStandingMobilityDataKeys: { [key: string]: string } = {
  BENDFORWARD: "bendForward",
  BENDBACKWARD: "bendBackward",
  ROTATELEFT: "rotateLeft",
  ROTATERIGHT: "rotateRight",
  SQUATFULL: "squatFull",
};

export const mskSittingMobilityDataKeys: { [key: string]: string } = {
  HEADDOWN: "headDown",
  HEADUP: "headUp",
  ROTATELEFT: "rotateLeft",
  ROTATERIGHT: "rotateRight",
  LEFTSHOULDERFLEXION: "leftShoulderFlexion",
  LEFTSHOULDERABDUCTION: "leftShoulderAbduction",
  LEFTSHOULDERHANDBEHINDHEAD: "leftShoulderHandBehindHead",
  LEFTSHOULDERHANDBEHINDBACK: "leftShoulderHandBehindBack",
  LEFTELBOWFOREARMEXTENSION: "leftElbowForearmExtension",
  LEFTELBOWFOREARMFLEXION: "leftElbowForearmFlexion",
  LEFTELBOWFOREARMSUPINATION: "leftElbowForearmSupination",
  LEFTWRISTHANDEXTENSION: "leftWristHandExtension",
  LEFTWRISTHANDFLEXION: "leftWristHandFlexion",
  LEFTWRISTHANDRADIALDEVIATION: "leftWristHandRadialDeviation",
  LEFTWRISTHANDULNARDEVIATION: "leftWristHandUlnarDeviation",
  LEFTHANDFINGEROPPOSITIONTOTHUMB: "leftHandFingerOppositionToThumb",
  LEFTHANDFINGERFULLFIST: "leftHandFingerFullFist",
  RIGHTSHOULDERFLEXION: "rightShoulderFlexion",
  RIGHTSHOULDERABDUCTION: "rightShoulderAbduction",
  RIGHTSHOULDERHANDBEHINDHEAD: "rightShoulderHandBehindHead",
  RIGHTSHOULDERHANDBEHINDBACK: "rightShoulderHandBehindBack",
  RIGHTELBOWFOREARMEXTENSION: "rightElbowForearmExtension",
  RIGHTELBOWFOREARMFLEXION: "rightElbowForearmFlexion",
  RIGHTELBOWFOREARMSUPINATION: "rightElbowForearmSupination",
  RIGHTWRISTHANDEXTENSION: "rightWristHandExtension",
  RIGHTWRISTHANDFLEXION: "rightWristHandFlexion",
  RIGHTWRISTHANDRADIALDEVIATION: "rightWristHandRadialDeviation",
  RIGHTWRISTHANDULNARDEVIATION: "rightWristHandUlnarDeviation",
  RIGHTHANDFINGEROPPOSITIONTOTHUMB: "rightHandFingerOppositionToThumb",
  RIGHTHANDFINGERFULLFIST: "rightHandFingerFullFist",
};

export const mskSittingStrengthDataKeys: { [key: string]: string } = {
  LEFTSHOULDERSTRENGTHFLEXION: "leftShoulderStrengthFlexion",
  LEFTSHOULDERSTRENGTHABDUCTION: "leftShoulderStrengthAbduction",
  LEFTSHOULDERSTRENGTHINTERNALROTATION: "leftShoulderStrengthInternalRotation",
  LEFTSHOULDERSTRENGTHEXTERNALROTATION: "leftShoulderStrengthExternalRotation",
  LEFTELBOWSTRENGTHFLEXION: "leftElbowStrengthFlexion",
  LEFTELBOWSTRENGTHEXTENSION: "leftElbowStrengthExtension",
  LEFTELBOWSTRENGTHSUPINATION: "leftElbowStrengthSupination",
  LEFTTHUMBSTRENGTHEXTENSIONS: "leftThumbStrengthExtensions",
  LEFTTHUMBSTRENGTHLUMBRICALS: "leftThumbStrengthLumbricals",
  RIGHTSHOULDERSTRENGTHFLEXION: "rightShoulderStrengthFlexion",
  RIGHTSHOULDERSTRENGTHABDUCTION: "rightShoulderStrengthAbduction",
  RIGHTSHOULDERSTRENGTHINTERNALROTATION: "rightShoulderStrengthInternalRotation",
  RIGHTSHOULDERSTRENGTHEXTERNALROTATION: "rightShoulderStrengthExternalRotation",
  RIGHTELBOWSTRENGTHFLEXION: "rightElbowStrengthFlexion",
  RIGHTELBOWSTRENGTHEXTENSION: "rightElbowStrengthExtension",
  RIGHTELBOWSTRENGTHSUPINATION: "rightElbowStrengthSupination",
  RIGHTTHUMBSTRENGTHEXTENSIONS: "rightThumbStrengthExtensions",
  RIGHTTHUMBSTRENGTHLUMBRICALS: "rightThumbStrengthLumbricals",
};

export const mskLyingMobilityDataKeys: { [key: string]: string } = {
  LEFTANKLEDORSIFLEXION: "leftAnkleDorsiflexion",
  LEFTANKLEPLANTARFLEXION: "leftAnklePlantarFlexion",
  LEFTANKLEINVERSION: "leftAnkleInversion",
  LEFTANKLEEVERSION: "leftAnkleEversion",
  RIGHTANKLEDORSIFLEXION: "rightAnkleDorsiflexion",
  RIGHTANKLEPLANTARFLEXION: "rightAnklePlantarFlexion",
  RIGHTANKLEINVERSION: "rightAnkleInversion",
  RIGHTANKLEEVERSION: "rightAnkleEversion",
  LEFTHIPFLEXION: "leftHipFlexion",
  LEFTHIPEXTERNALROTATION: "leftHipExternalRotation",
  LEFTKNEEFLEXION: "leftKneeFlexion",
  LEFTKNEEEXTENSION: "leftKneeExtension",
  RIGHTHIPFLEXION: "rightHipFlexion",
  RIGHTHIPEXTERNALROTATION: "rightHipExternalRotation",
  RIGHTKNEEFLEXION: "rightKneeFlexion",
  RIGHTKNEEEXTENSION: "rightKneeExtension",
};

export const mskLyingStrengthDataKeys: { [key: string]: string } = {
  LEFTANKLESTRENGTHDORSIFLEXION: "leftAnkleStrengthDorsiflexion",
  LEFTANKLESTRENGTHPLANTARFLEXION: "leftAnkleStrengthPlantarflexion",
  LEFTANKLESTRENGTHINVERSION: "leftAnkleStrengthInversion",
  LEFTANKLESTRENGTHEVERSION: "leftAnkleStrengthEversion",
  LEFTTOEEXTENSION: "leftToeExtension",
  RIGHTANKLESTRENGTHDORSIFLEXION: "rightAnkleStrengthDorsiflexion",
  RIGHTANKLESTRENGTHPLANTARFLEXION: "rightAnkleStrengthPlantarflexion",
  RIGHTANKLESTRENGTHINVERSION: "rightAnkleStrengthInversion",
  RIGHTANKLESTRENGTHEVERSION: "rightAnkleStrengthEversion",
  RIGHTTOEEXTENSION: "rightToeExtension",
  TRUNKSTRENGTHEXTENSORS: "trunkStrengthExtensors",
  TRUNKSTRENGTHABDOMINALS: "trunkStrengthAbdominals",
};

export const mskStandingSpecialTestDataKeys: { [key: string]: string } = {
  SQUATFULLWEIGHTBEARINGRATIOASYMMETRICAL: "squatFullWeightBearingRatioAsymmetrical",
  SQUATFULLWEIGHTBEARINGRATIOSYMMETRICAL: "squatFullWeightBearingRatioSymmetrical",
  SQUATFULLWEIGHTBEARINGRATIOL: "squatFullWeightBearingRatioL",
  SQUATFULLWEIGHTBEARINGRATIOR: "squatFullWeightBearingRatioR",
  SQUATFULLNOSUPPORTREQUIRED: "squatFullNoSupportRequired",
  SQUATFULLSUPPORTTOLOWER: "squatFullSupportToLower",
  SQUATFULLSUPPORTTORISE: "squatFullSupportToRise",
};

export const mskSittingSpecialTestDataKeys: { [key: string]: string } = {
  RIGHTEMPTYCANTESTNEGATIVE: "rightEmptyCanTestNegative",
  RIGHTEMPTYCANTESTPOSITIVEDISCOMFORT: "rightEmptyCanTestPositiveDiscomfort",
  RIGHTEMPTYCANTESTPOSITIVEWEAKNESS: "rightEmptyCanTestPositiveWeakness",
  LEFTEMPTYCANTESTNEGATIVE: "leftEmptyCanTestNegative",
  LEFTEMPTYCANTESTPOSITIVEDISCOMFORT: "leftEmptyCanTestPositiveDiscomfort",
  LEFTEMPTYCANTESTPOSITIVEWEAKNESS: "leftEmptyCanTestPositiveWeakness",
  LEFTJAMARGRIPTRIAL1: "leftJamarGripTrial1",
  LEFTJAMARGRIPTRIAL2: "leftJamarGripTrial2",
  LEFTJAMARGRIPTRIAL3: "leftJamarGripTrial3",
  LEFTJAMARGRIPTRIALRESULT: "leftJamarGripTrialResult",
  RIGHTJAMARGRIPTRIAL1: "rightJamarGripTrial1",
  RIGHTJAMARGRIPTRIAL2: "rightJamarGripTrial2",
  RIGHTJAMARGRIPTRIAL3: "rightJamarGripTrial3",
  RIGHTJAMARGRIPTRIALRESULT: "rightJamarGripTrialResult",
};

export const mskLyingSpecialTestDataKeys: { [key: string]: string } = {
  HAMSTRINGLEFTSLR: "hamstringLeftSLR",
  HAMSTRINGRIGHTSLR: "hamstringRightSLR",
  TRUNKSTABILITYGRADE: "trunkStabilityGrade",
  LEFTSHOULDERSTABILITYGRADE: "leftShoulderStabilityGrade",
  RIGHTSHOULDERSTABILITYGRADE: "rightShoulderStabilityGrade",
};

import * as yup from "yup";

const ptValidation = yup.object().shape({
  preTestHr: yup.boolean().test(
    "preTestHr",
    "Please enter a pre-test HR",

    function () {
      const { notTestedReason, preTestHeartRate } = this.parent;
      if (!notTestedReason && !preTestHeartRate) {
        return false;
      }
      return true;
    }
  ),
  testNotPerformed: yup.bool().test("testNotPerformed", "Please complete the test to calculate tolerance rating.", function () {
    const { notTestedReason, timeElapsedRaw } = this.parent;

    if (!notTestedReason && parseInt(timeElapsedRaw) === -1) {
      return false;
    }
    return true;
  }),
  // toleranceRating: yup.string().test("toleranceRating", "Please enter a rating.", function () {
  //   const { notTestedReason, timeElapsedRaw, toleranceRating } = this.parent;

  //   if (!notTestedReason && parseInt(timeElapsedRaw) !== -1 && !toleranceRating) {
  //     return false;
  //   }

  //   return true;
  // }),
  sec100: yup.boolean().test(
    "sec30",
    "Please enter a HR",

    function () {
      const { notTestedReason, timeElapsedRaw, Bpm100 } = this.parent;
      if (!notTestedReason && parseInt(timeElapsedRaw) + 30 >= 90 && !Bpm100) {
        return false;
      }
      return true;
    }
  ),
  sec130: yup.boolean().test(
    "sec30",
    "Please enter a HR",

    function () {
      const { notTestedReason, timeElapsedRaw, Bpm130 } = this.parent;
      if (!notTestedReason && parseInt(timeElapsedRaw) + 30 >= 120 && !Bpm130) {
        return false;
      }
      return true;
    }
  ),
  sec200: yup.boolean().test(
    "sec30",
    "Please enter a HR",

    function () {
      const { notTestedReason, timeElapsedRaw, Bpm200 } = this.parent;
      if (!notTestedReason && parseInt(timeElapsedRaw) + 30 >= 150 && !Bpm200) {
        return false;
      }
      return true;
    }
  ),
  sec230: yup.boolean().test(
    "sec30",
    "Please enter a HR",

    function () {
      const { notTestedReason, timeElapsedRaw, Bpm230 } = this.parent;
      if (!notTestedReason && parseInt(timeElapsedRaw) + 30 >= 180 && !Bpm230) {
        return false;
      }
      return true;
    }
  ),
  sec300: yup.boolean().test(
    "sec30",
    "Please enter a HR",

    function () {
      const { notTestedReason, timeElapsedRaw, Bpm300 } = this.parent;
      if (!notTestedReason && parseInt(timeElapsedRaw) + 30 >= 210 && !Bpm300) {
        return false;
      }
      return true;
    }
  ),
  // heartRate: yup.bool().test("heartRate", "Please indicate the participant's heart rate.", function () {
  //   const { notTestedReason, heartRateStable, heartRateInconsistent, heartRateIncreasing } = this.parent;

  //   if (!notTestedReason && !heartRateStable && !heartRateInconsistent && !heartRateIncreasing) {
  //     return false;
  //   }

  //   return true;
  // }),
  heartRateIncreasingDecision: yup.string().test("heartRateIncreasingDecision", "Please confirm or deny if heart rate was increasing", function () {
    const { notTestedReason, heartRateIncreasing, heartRateIncreasingDecision } = this.parent;

    if (!notTestedReason && heartRateIncreasing && !heartRateIncreasingDecision) {
      return false;
    }

    return true;
  }),
  heartRateIncreasingDecisionDenyNote: yup.string().test("heartRateIncreasingDecisionDenyNote", "Please indicate the deny reason.", function () {
    const { notTestedReason, heartRateIncreasing, heartRateIncreasingDecision, heartRateIncreasingDecisionDenyNote } = this.parent;

    if (!notTestedReason && heartRateIncreasing && heartRateIncreasingDecision === "deny" && !heartRateIncreasingDecisionDenyNote) {
      return false;
    }

    return true;
  }),
  posture: yup.boolean().test(
    "posture",
    "Please indicate participant's posture",

    function () {
      const { notTestedReason, asymmetricalPostureChecked, symmetricalPostureChecked } = this.parent;
      if (!notTestedReason && !asymmetricalPostureChecked && !symmetricalPostureChecked) {
        return false;
      }
      return true;
    }
  ),
  postureAsymmetrical: yup.bool().test("postureAsymmetrical", "Please describe the participant's asymmetrical posture.", function () {
    const { notTestedReason, asymmetricalPostureChecked, asymmetricalNote } = this.parent;
    if (!notTestedReason && asymmetricalPostureChecked && !asymmetricalNote) {
      return false;
    }
    return true;
  }),
  performanceDifficulty: yup.bool().test("performanceDifficulty", "Please describe the participant's difficulty performing.", function () {
    const { notTestedReason, difficultyMaintainingPositions, unevenWeightBearing, performanceDifficultyNotes } = this.parent;

    if (!notTestedReason && (difficultyMaintainingPositions || unevenWeightBearing === true) && !performanceDifficultyNotes) {
      return false;
    }

    return true;
  }),
  positioningDifficulty: yup.bool().test("positioningDifficulty", "Please describe the participant's difficulty changing positions.", function () {
    const { notTestedReason, difficultyMoving, difficultyStanding, difficultyPositioningNotes } = this.parent;

    if (!notTestedReason && (difficultyMoving === true || difficultyStanding === true) && !difficultyPositioningNotes) {
      return false;
    }

    return true;
  }),
  participantReports: yup.bool().test("participantReports", "Please report the participant's discomfort.", function () {
    if (
      !this.parent.notTestedReason &&
      ((this.parent.kneel_deniedDiscomfort === false && this.parent.kneel_reportedDiscomfort === false) ||
        (this.parent.reachAbove_deniedDiscomfort === false && this.parent.reachAbove_reportedDiscomfort === false) ||
        (this.parent.reachForward_deniedDiscomfort === false && this.parent.reachForward_reportedDiscomfort === false) ||
        (this.parent.squat_deniedDiscomfort === false && this.parent.squat_reportedDiscomfort === false) ||
        (this.parent.stoop_deniedDiscomfort === false && this.parent.stoop_reportedDiscomfort === false))
    ) {
      return false;
    }
    return true;
  }),
  location: yup.bool().test("location", "Please provide the location of the discomfort.", function () {
    if (
      !this.parent.notTestedReason &&
      ((this.parent.kneel_reportedDiscomfort === true && this.parent.kneel_location === "") ||
        (this.parent.reachAbove_reportedDiscomfort === true && this.parent.reachAbove_location === "") ||
        (this.parent.reachForward_reportedDiscomfort === true && this.parent.reachForward_location === "") ||
        (this.parent.squat_reportedDiscomfort === true && this.parent.squat_location === "") ||
        (this.parent.stoop_reportedDiscomfort === true && this.parent.stoop_location === ""))
    ) {
      return false;
    }

    return true;
  }),
  intensity: yup.bool().test("intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      !this.parent.notTestedReason &&
      ((this.parent.kneel_reportedDiscomfort === true &&
        this.parent.kneel_intensityVas === "" &&
        this.parent.kneel_intensityMild === false &&
        this.parent.kneel_intensityMod === false &&
        this.parent.kneel_intensitySevere === false) ||
        (this.parent.reachAbove_reportedDiscomfort === true &&
          this.parent.reachAbove_intensityVas === "" &&
          this.parent.reachAbove_intensityMild === false &&
          this.parent.reachAbove_intensityMod === false &&
          this.parent.reachAbove_intensitySevere === false) ||
        (this.parent.reachForward_reportedDiscomfort === true &&
          this.parent.reachForward_intensityVas === "" &&
          this.parent.reachForward_intensityMild === false &&
          this.parent.reachForward_intensityMod === false &&
          this.parent.reachForward_intensitySevere === false) ||
        (this.parent.squat_reportedDiscomfort === true &&
          this.parent.squat_intensityVas === "" &&
          this.parent.squat_intensityMild === false &&
          this.parent.squat_intensityMod === false &&
          this.parent.squat_intensitySevere === false) ||
        (this.parent.stoop_reportedDiscomfort === true &&
          this.parent.stoop_intensityVas === "" &&
          this.parent.stoop_intensityMild === false &&
          this.parent.stoop_intensityMod === false &&
          this.parent.stoop_intensitySevere === false))
    ) {
      return false;
    }

    return true;
  }),
  type: yup.bool().test("type", "Please describe the type of discomfort.", function () {
    if (
      !this.parent.notTestedReason &&
      ((this.parent.kneel_discomfortTypeAche === false &&
        this.parent.kneel_discomfortTypeSharp === false &&
        this.parent.kneel_discomfortTypeBurning === false &&
        this.parent.kneel_discomfortTypeTightness === false &&
        this.parent.kneel_discomfortTypePulling === false &&
        this.parent.kneel_discomfortTypeOther === false) ||
        (this.parent.reachAbove_discomfortTypeAche === false &&
          this.parent.reachAbove_discomfortTypeSharp === false &&
          this.parent.reachAbove_discomfortTypeBurning === false &&
          this.parent.reachAbove_discomfortTypeTightness === false &&
          this.parent.reachAbove_discomfortTypePulling === false &&
          this.parent.reachAbove_discomfortTypeOther === false) ||
        (this.parent.reachForward_discomfortTypeAche === false &&
          this.parent.reachForward_discomfortTypeSharp === false &&
          this.parent.reachForward_discomfortTypeBurning === false &&
          this.parent.reachForward_discomfortTypeTightness === false &&
          this.parent.reachForward_discomfortTypePulling === false &&
          this.parent.reachForward_discomfortTypeOther === false) ||
        (this.parent.squat_discomfortTypeAche === false &&
          this.parent.squat_discomfortTypeSharp === false &&
          this.parent.squat_discomfortTypeBurning === false &&
          this.parent.squat_discomfortTypeTightness === false &&
          this.parent.squat_discomfortTypePulling === false &&
          this.parent.squat_discomfortTypeOther === false) ||
        (this.parent.stoop_discomfortTypeAche === false &&
          this.parent.stoop_discomfortTypeSharp === false &&
          this.parent.stoop_discomfortTypeBurning === false &&
          this.parent.stoop_discomfortTypeTightness === false &&
          this.parent.stoop_discomfortTypePulling === false &&
          this.parent.stoop_discomfortTypeOther === false))
    ) {
      return false;
    }

    return true;
  }),
  typeOther: yup.bool().test("typeOther", "Please describe the discomfort further.", function () {
    if (
      !this.parent.notTestedReason &&
      ((this.parent.kneel_discomfortTypeOther === true && this.parent.kneel_typeOther === "") ||
        (this.parent.reachAbove_discomfortTypeOther === true && this.parent.reachAbove_typeOther === "") ||
        (this.parent.reachForward_discomfortTypeOther === true && this.parent.reachForward_typeOther === "") ||
        (this.parent.squat_discomfortTypeOther === true && this.parent.squat_typeOther === "") ||
        (this.parent.stoop_discomfortTypeOther === true && this.parent.stoop_typeOther === ""))
    ) {
      return false;
    }

    return true;
  }),
  status: yup.bool().test("status", "Please provide the status of the discomfort.", function () {
    if (
      !this.parent.notTestedReason &&
      ((this.parent.kneel_discomfortResolvedImmediately === false &&
        this.parent.kneel_discomfortPersisting === false &&
        this.parent.kneel_discomfortResolvedWithRest === false &&
        this.parent.kneel_discomfortIncreasing === false) ||
        (this.parent.reachAbove_discomfortResolvedImmediately === false &&
          this.parent.reachAbove_discomfortPersisting === false &&
          this.parent.reachAbove_discomfortResolvedWithRest === false &&
          this.parent.reachAbove_discomfortIncreasing === false) ||
        (this.parent.reachForward_discomfortResolvedImmediately === false &&
          this.parent.reachForward_discomfortPersisting === false &&
          this.parent.reachForward_discomfortResolvedWithRest === false &&
          this.parent.reachForward_discomfortIncreasing === false) ||
        (this.parent.squat_discomfortResolvedImmediately === false &&
          this.parent.squat_discomfortPersisting === false &&
          this.parent.squat_discomfortResolvedWithRest === false &&
          this.parent.squat_discomfortIncreasing === false) ||
        (this.parent.stoop_discomfortResolvedImmediately === false &&
          this.parent.stoop_discomfortPersisting === false &&
          this.parent.stoop_discomfortResolvedWithRest === false &&
          this.parent.stoop_discomfortIncreasing === false))
    ) {
      return false;
    }

    return true;
  }),
});

export default ptValidation;

import { truncate } from "fs";
import * as yup from "yup";

const ptValidation = yup.object().shape({
  preTestHr: yup.boolean().test(
    "preTestHr",
    "Please enter a pre-test HR",

    function () {
      const { notTestedReason, preTestHeartRate } = this.parent;
      if (!notTestedReason && !preTestHeartRate) {
        return false;
      }
      return true;
    }
  ),
  testNotPerformed: yup.bool().test("testNotPerformed", "Please complete the test to calculate tolerance rating.", function () {
    const { notTestedReason, timeElapsedRaw } = this.parent;

    if (!notTestedReason && parseInt(timeElapsedRaw) === -1) {
      return false;
    }
    return true;
  }),
  // toleranceRating: yup.string().test("toleranceRating", "Please enter a rating.", function () {
  //   const { notTestedReason, timeElapsedRaw, toleranceRating } = this.parent;

  //   if (!notTestedReason && parseInt(timeElapsedRaw) !== -1 && !toleranceRating) {
  //     return false;
  //   }

  //   return true;
  // }),
  sec100: yup.boolean().test(
    "sec30",
    "Please enter a HR",

    function () {
      const { notTestedReason, timeElapsedRaw, Bpm100 } = this.parent;
      if (!notTestedReason && parseInt(timeElapsedRaw) + 30 >= 90 && !Bpm100) {
        return false;
      }
      return true;
    }
  ),
  sec130: yup.boolean().test(
    "sec30",
    "Please enter a HR",

    function () {
      const { notTestedReason, timeElapsedRaw, Bpm130 } = this.parent;
      if (!notTestedReason && parseInt(timeElapsedRaw) + 30 >= 120 && !Bpm130) {
        return false;
      }
      return true;
    }
  ),
  sec200: yup.boolean().test(
    "sec30",
    "Please enter a HR",

    function () {
      const { notTestedReason, timeElapsedRaw, Bpm200 } = this.parent;
      if (!notTestedReason && parseInt(timeElapsedRaw) + 30 >= 150 && !Bpm200) {
        return false;
      }
      return true;
    }
  ),
  sec230: yup.boolean().test(
    "sec30",
    "Please enter a HR",

    function () {
      const { notTestedReason, timeElapsedRaw, Bpm230 } = this.parent;
      if (!notTestedReason && parseInt(timeElapsedRaw) + 30 >= 180 && !Bpm230) {
        return false;
      }
      return true;
    }
  ),
  sec300: yup.boolean().test(
    "sec30",
    "Please enter a HR",

    function () {
      const { notTestedReason, timeElapsedRaw, Bpm300 } = this.parent;
      if (!notTestedReason && parseInt(timeElapsedRaw) + 30 >= 210 && !Bpm300) {
        return false;
      }
      return true;
    }
  ),
  posture: yup.boolean().test(
    "posture",
    "Please indicate participant's posture",

    function () {
      const { notTestedReason, asymmetricalPostureChecked, symmetricalPostureChecked } = this.parent;
      if (!notTestedReason && !asymmetricalPostureChecked && !symmetricalPostureChecked) {
        return false;
      }
      return true;
    }
  ),
  postureAsymmetrical: yup.bool().test("postureAsymmetrical", "Please describe the participant's asymmetrical posture.", function () {
    const { notTestedReason, asymmetricalPostureChecked, asymmetricalNote } = this.parent;
    if (!notTestedReason && asymmetricalPostureChecked && !asymmetricalNote) {
      return false;
    }
    return true;
  }),
  performanceDifficulty: yup.bool().test("performanceDifficulty", "Please describe the participant's difficulty performing.", function () {
    const { notTestedReason, performanceUnevenWeightBearing, performanceUnevenTrunkMovement, performanceUnevenWeightBearingLowerLimbs, performanceNotes } = this.parent;

    if (
      !notTestedReason &&
      (performanceUnevenWeightBearing === true || performanceUnevenTrunkMovement === true || performanceUnevenWeightBearingLowerLimbs === true) &&
      !performanceNotes
    ) {
      return false;
    }

    return true;
  }),
  positioningDifficulty: yup.bool().test("positioningDifficulty", "Please describe the participant's difficulty positioning.", function () {
    const { notTestedReason, positioningDifficultyMoving, positioningDifficultyStanding, positioningNotes } = this.parent;

    if (!notTestedReason && (positioningDifficultyMoving === true || positioningDifficultyStanding === true) && !positioningNotes) {
      return false;
    }

    return true;
  }),
  participantReports: yup.bool().test("participantReports", "Please report the participant's discomfort.", function () {
    if (
      !this.parent.notTestedReason &&
      ((this.parent.ladderClimb_deniedDiscomfort === false && this.parent.ladderClimb_reportedDiscomfort === false) ||
        (this.parent.reachSideToSide_deniedDiscomfort === false && this.parent.reachSideToSide_reportedDiscomfort === false) ||
        (this.parent.crawling_deniedDiscomfort === false && this.parent.crawling_reportedDiscomfort === false))
    ) {
      return false;
    }
    return true;
  }),
  location: yup.bool().test("location", "Please provide the location of the discomfort.", function () {
    if (
      !this.parent.notTestedReason &&
      ((this.parent.ladderClimb_reportedDiscomfort === true && this.parent.ladderClimb_location === "") ||
        (this.parent.reachSideToSide_reportedDiscomfort === true && this.parent.reachSideToSide_location === "") ||
        (this.parent.crawling_reportedDiscomfort === true && this.parent.crawling_location === ""))
    ) {
      return false;
    }

    return true;
  }),
  intensity: yup.bool().test("intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      !this.parent.notTestedReason &&
      ((this.parent.ladderClimb_reportedDiscomfort === true &&
        this.parent.ladderClimb_intensityVas === "" &&
        this.parent.ladderClimb_intensityMild === false &&
        this.parent.ladderClimb_intensityMod === false &&
        this.parent.ladderClimb_intensitySevere === false) ||
        (this.parent.reachSideToSide_reportedDiscomfort === true &&
          this.parent.reachSideToSide_intensityVas === "" &&
          this.parent.reachSideToSide_intensityMild === false &&
          this.parent.reachSideToSide_intensityMod === false &&
          this.parent.reachSideToSide_intensitySevere === false) ||
        (this.parent.crawling_reportedDiscomfort === true &&
          this.parent.crawling_intensityVas === "" &&
          this.parent.crawling_intensityMild === false &&
          this.parent.crawling_intensityMod === false &&
          this.parent.crawling_intensitySevere === false))
    ) {
      return false;
    }

    return true;
  }),
  type: yup.bool().test("type", "Please describe the type of discomfort.", function () {
    if (
      !this.parent.notTestedReason &&
      ((this.parent.ladderClimb_discomfortTypeAche === false &&
        this.parent.ladderClimb_discomfortTypeSharp === false &&
        this.parent.ladderClimb_discomfortTypeBurning === false &&
        this.parent.ladderClimb_discomfortTypeTightness === false &&
        this.parent.ladderClimb_discomfortTypePulling === false &&
        this.parent.ladderClimb_discomfortTypeOther === false) ||
        (this.parent.reachSideToSide_discomfortTypeAche === false &&
          this.parent.reachSideToSide_discomfortTypeSharp === false &&
          this.parent.reachSideToSide_discomfortTypeBurning === false &&
          this.parent.reachSideToSide_discomfortTypeTightness === false &&
          this.parent.reachSideToSide_discomfortTypePulling === false &&
          this.parent.reachSideToSide_discomfortTypeOther === false) ||
        (this.parent.crawling_discomfortTypeAche === false &&
          this.parent.crawling_discomfortTypeSharp === false &&
          this.parent.crawling_discomfortTypeBurning === false &&
          this.parent.crawling_discomfortTypeTightness === false &&
          this.parent.crawling_discomfortTypePulling === false &&
          this.parent.crawling_discomfortTypeOther === false))
    ) {
      return false;
    }

    return true;
  }),
  typeOther: yup.bool().test("typeOther", "Please describe the discomfort further.", function () {
    if (
      !this.parent.notTestedReason &&
      ((this.parent.ladderClimb_discomfortTypeOther === true && this.parent.ladderClimb_typeOther === "") ||
        (this.parent.reachSideToSide_discomfortTypeOther === true && this.parent.reachSideToSide_typeOther === "") ||
        (this.parent.crawling_discomfortTypeOther === true && this.parent.crawling_typeOther === ""))
    ) {
      return false;
    }

    return true;
  }),
  status: yup.bool().test("status", "Please provide the status of the discomfort.", function () {
    if (
      !this.parent.notTestedReason &&
      ((this.parent.ladderClimb_discomfortResolvedImmediately === false &&
        this.parent.ladderClimb_discomfortPersisting === false &&
        this.parent.ladderClimb_discomfortResolvedWithRest === false &&
        this.parent.ladderClimb_discomfortIncreasing === false) ||
        (this.parent.reachSideToSide_discomfortResolvedImmediately === false &&
          this.parent.reachSideToSide_discomfortPersisting === false &&
          this.parent.reachSideToSide_discomfortResolvedWithRest === false &&
          this.parent.reachSideToSide_discomfortIncreasing === false) ||
        (this.parent.crawling_discomfortResolvedImmediately === false &&
          this.parent.crawling_discomfortPersisting === false &&
          this.parent.crawling_discomfortResolvedWithRest === false &&
          this.parent.crawling_discomfortIncreasing === false))
    ) {
      return false;
    }

    return true;
  }),
});

export default ptValidation;

import refresh from "./refresh.svg";
import male from "./male.svg";
import female from "./female.svg";
import clearanceGrey from "./clearance-grey.svg";
import clearanceBlue from "./clearance-blue.svg";
import userIcon from "./userIcon.svg";
import success from "./success.svg";
import jfLogo from "./jobfit-logo.png";
import pefaLogo from "./pefa-logo.png";
import baseline from "./baseline.svg";
import checkboxicon from "./checkbox.svg";
import checked from "./checked.svg";
import wave from "./wave.png";
import mskStanding from "./icon-standing.svg";
import mskSitting from "./icon-sitting.svg";
import mskLying from "./icon-lying.svg";
import notesIcon from "./icon-notes.svg";
import editIconBlue from "./icon-edit-blue.svg";
import trashIconBlue from "./icon-trash-blue.svg";
import timerIcon from "./icon-timer.svg";
import reachForwardIcon from "./icon-reachforward.svg";
import helpIcon from "./icon-help.svg";
import warningIcon from "./icon-warning.svg";
import fitnessIcon from "./icon-fitness.svg";
import metronomeIcon from "./icon-metronome.svg";
import reachsideIcon from "./icon-reachside.svg";
import balanceIcon from "./icon-balance.svg";
import reachaboveIcon from "./icon-reachabove.svg";
import stoopIcon from "./icon-stoop.svg";
import squatIcon from "./icon-squat.svg";
import kneelIcon from "./icon-kneel.svg";
import crawlIcon from "./icon-crawl.svg";
import ladderClimbIcon from "./icon-ladderclimb.svg";
import benchToFloorIcon from "./icon-benchToFloor.svg";
import benchToBenchIcon from "./icon-benchToBench.svg";
import benchToShoulderIcon from "./icon-benchToShoulder.svg";
import benchToAboveShoulderIcon from "./icon-benchToAboveShoulder.svg";
import bilateralCaryIcon from "./icon-bilateralCarry.svg";
import singleCaryIcon from "./icon-singleCarry.svg";
import singleCaryRightIcon from "./icon-singleCarryRight.svg";
import starIcon from "./icon-star.svg";
import tickIcon from "./icon-tick.svg";
import completeFooterL from "./jf-submit-bl.svg";
import completeFooterR from "./jf-submit-br.svg";
import bigTick from "./tick.svg";
import tickNoOutline from "./tick-no-outline.svg";
import pdfIcon from "./pdfIcon.svg";
import referralIcon from "./icon-referral.svg";
import iconGraph from "./icon-graph.svg";

export const IconGraph = iconGraph;
export const CompleteFooterL = completeFooterL;
export const CompleteFooterR = completeFooterR;
export const BigTick = bigTick;
export const TickIcon = tickIcon;
export const TickNoOutline = tickNoOutline;
export const StarIcon = starIcon;
export const ReachForwardIcon = reachForwardIcon;
export const SingleCaryLeftIcon = singleCaryIcon;
export const SingleCaryRightIcon = singleCaryRightIcon;
export const BilateralCaryIcon = bilateralCaryIcon;
export const ReachaboveIcon = reachaboveIcon;
export const BenchToShoulderIcon = benchToShoulderIcon;
export const BenchToAboveShoulderIcon = benchToAboveShoulderIcon;
export const BenchToFloorIcon = benchToFloorIcon;
export const BenchToBenchIcon = benchToBenchIcon;
export const LadderClimbIcon = ladderClimbIcon;
export const StoopIcon = stoopIcon;
export const CrawlIcon = crawlIcon;
export const KneelIcon = kneelIcon;
export const SquatIcon = squatIcon;
export const BalanceIcon = balanceIcon;
export const ReachsideIcon = reachsideIcon;
export const EditIconBlue = editIconBlue;
export const FitnessIcon = fitnessIcon;
export const MetronomeIcon = metronomeIcon;
export const TrashIconBlue = trashIconBlue;
export const WarningIcon = warningIcon;
export const HelpIcon = helpIcon;
export const NotesIcon = notesIcon;
export const TimerIcon = timerIcon;
export const CheckboxChecked = checked;
export const MskStanding = mskStanding;
export const MskSitting = mskSitting;
export const MskLying = mskLying;
export const CheckboxIcon = checkboxicon;
export const RefreshIcon = refresh;
export const Wave = wave;
export const Baseline = baseline;
export const UserIcon = userIcon;
export const Success = success;
export const MaleIcon = male;
export const FemaleIcon = female;
export const ClearanceGreyIcon = clearanceGrey;
export const ClearanceBlueIcon = clearanceBlue;
export const PdfIcon = pdfIcon;
export const ReferralIcon = referralIcon;
export const JFLogo = jfLogo;
export const PefaLogo = pefaLogo;

import { keyPairData } from ".";

interface generalObservationGroup {
  name: string;
  fields: string[];
  notes: string;
}

const generalObservationsGroups: generalObservationGroup[] = [
  {
    name: "Posture",
    fields: [
      "postureNilSignificant",
      "postureForwardHeadedPosture",
      "postureFlatBackPosture",
      "postureSwayBackPosture",
      "postureScapularWinging",
      "postureRoundedShoulders",
      "postureGenusVarusBow",
      "postureAssymetry",
    ],
    notes: "postureNotes",
  },
  {
    name: "Build",
    fields: ["buildNilSignificant", "buildGeneralReducedMuscleBulk", "buildIncreasedAdiposeTissue", "buildMuscularAtrophy"],
    notes: "buildNotes",
  },
  {
    name: "Oedema",
    fields: ["oedemainflammationNilSignificant", "oedemaLocation", "oedemaSeverityMild", "oedemaSeverityModerate", "oedemaSeveritySevere"],
    notes: "oedemainflammationNotes",
  },
  {
    name: "Scars",
    fields: ["scarsNilSignificant"],
    notes: "scarNotes",
  },
  {
    name: "Gait",
    fields: ["gaitNilSignificant", "gaitReducedWeightBearingLeft", "gaitReducedWeightBearingRight", "gaitTrendelenburg"],
    notes: "gaitNotes",
  },
  {
    name: "Braces",
    fields: ["bracesProsthesisNilSignificant", "bracesProsthesisProsthesis", "bracesProsthesisBrace", "bracesProsthesisOtherSupportDevice"],
    notes: "bracesProsthesisNotes",
  },
];

export default class generalObservations {
  public static formatForSubmission(generalObservations: { [key: string]: any }): keyPairData[] {
    const formattedData: keyPairData[] = [];

    //loop through each of the general observation groups.
    generalObservationsGroups.forEach((group: generalObservationGroup) => {
      //For each group, loop through the fields listed, building a list of values.
      let values: { [key: string]: string } = {};

      //Loop through each of the fields,
      let isNilSignificant = true;
      group.fields.forEach((field: string) => {
        //If we are handling the nilSigificant field, assume the name will just be nilSignificant.
        let fieldName = field;
        if (isNilSignificant) {
          fieldName = "nilSignificant";
          isNilSignificant = false;
        }

        //Append the field and its value to the existing values object.
        values = {
          ...values,
          [fieldName]: generalObservations[field].toString(),
        };
      });

      //Finally, compile the data and add it to the list of general observations formatted data.
      formattedData.push({
        name: group.name,
        values: values,
        notes: generalObservations[group.notes],
      });
    });

    return formattedData;
  }
}

import React, { ChangeEvent, useEffect, useState } from "react";
import {
  ProfileCard,
  FormNavigator,
  Stepper,
  Modal,
  NotTested,
  ParticipantReports,
  ManualHandlingTest,
  DrawerTimer,
  IOSSwitch,
  AssessorStoppedModal,
  ParticipantStoppedModal,
  NotesModal,
  InTestMedicalClearanceModal,
} from "../../../components/";
import { BenchToAboveShoulderIcon, StarIcon, Baseline } from "../../../assets/images/";
import validation from "./validation";
import { ExpandMore, ErrorOutline } from "@material-ui/icons";

import {
  Grid,
  Typography,
  Container,
  FormControlLabel,
  OutlinedInput,
  Card,
  FormLabel,
  Checkbox,
  FormGroup,
  Button,
  AccordionSummary,
  InputAdornment,
  AccordionDetails,
  Accordion as StyledAccordion,
} from "@material-ui/core";
import { inject, observer } from "mobx-react";
import { Root } from "../../../stores";
import { differenceInYears } from "date-fns";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { modalTransitions } from "../../../components/modals/modalBase";
import { ManualHandlingDecisions } from "../../../components/groupedComponents/ManualHandlingTest";
import { dataStorePageKeys } from "../../../stores/dataStore";
import { demand } from "../../../stores/pefaStore";
import { useHistory } from "react-router-dom";
import { DASHBOARD } from "../../../routes/paths";
import { getAppInsights } from "../../../components/telemetry";
import withAppInsights from "../AppInsights";

interface manualHandlingTestProps {
  rootStore?: Root;
}

interface attempt {
  attempt: number;
  result: string;
  weight?: number;
  bpm?: number;
}

const mhBenchToAboveShoulder = inject("rootStore")(
  observer((props: manualHandlingTestProps) => {
    let pefaAssessment: any;
    let currentDemands: demand;
    let nextDemandPage: demand;
    let prevData: any;
    let medicalHistoryData: any;
    let endAssessment: any;
    let referralDetails: any = null;
    let participantAgeYears = 0;
    const history = useHistory();
    const appInsights = getAppInsights();

    if (props.rootStore?.pefaStore.selectedPefa) {
      pefaAssessment = props.rootStore?.pefaStore.selectedPefa[0];

      currentDemands = props.rootStore?.pefaStore.selectedPefa.demands[dataStorePageKeys.MH_BENCH_TO_ABOVE];

      nextDemandPage = props.rootStore?.pefaStore.getNextPefaStep(currentDemands.functionalOrder);

      prevData = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.MH_BENCH_TO_ABOVE);
      referralDetails = props.rootStore?.dataStore.getCheckInData(pefaAssessment.id, dataStorePageKeys.REFERRAL_DETAILS);
      participantAgeYears = differenceInYears(new Date(), new Date(referralDetails?.dob ?? pefaAssessment.pefArequest.worker.dateOfBirth));
      medicalHistoryData = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.MEDICAL_DETAILS);
      endAssessment = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.END_ASSESSMENT);
    } else {
      history.push(DASHBOARD);
      return <></>;
    }

    const { register, handleSubmit, errors, setValue } = useForm({
      resolver: yupResolver(validation),
    });

    const [state, setState] = useState({
      isLoading: true,
      timeElapsedFormatted: "",
      isTimerStoppedModalOpen: false,

      notTestedModalOpen: false,
      notTestedReason: prevData ? prevData.notTestedReason : "",
      notTestedNotes: prevData ? prevData.notTestedNotes : "",
      recoveryModalOpen: false,
      yesAllNilSignificant: false,
      viewAll: false,
      preTestHeartRateModalOpen: false,
      targetWeight: currentDemands.value,
      weightPercentage: Math.ceil((0.85 * currentDemands.value) / 0.5) * 0.5,
      currentHeartRate: "",

      preTestHeartRateModalLocked: prevData ? !!prevData.preTestHeartRate : false,
      preTestHeartRate: prevData ? prevData.preTestHeartRate : "",
      attempts: prevData ? (prevData.attempts as attempt[]) : [{ attempt: 1, result: "" } as attempt],
      lastAttempt: prevData ? prevData.lastAttempt : 1,
      isBreakTaken: prevData ? prevData.recoveryTimeFormatted !== "" : false,
      preTestRecoveryTime: prevData ? prevData.preTestRecoveryTime : "",
      preTestRecoveryModalOpen: false,
      recoveryTimeFormatted: prevData ? prevData.recoveryTimeFormatted : "",
      isTestComplete: prevData ? prevData.isTestComplete : false,
      testWeight: prevData ? prevData.testWeight : 0,
      decision: "",

      stoppedReason: prevData ? prevData.stoppedReason : "",
      stoppedMessage: prevData ? prevData.stoppedMessage : "",
      assessorStoppedKey: prevData ? prevData.assessorStoppedKey : "",
      assessorStoppedModalOpen: false,
      assessorStoppedOptions: [] as any[],

      participantStoppedModalOpen: false,

      safeMaxAboveShoulder: prevData ? prevData.safeMaxAboveShoulder : false,

      symmetricalPostureChecked: prevData ? prevData.symmetricalPostureChecked : false,
      asymmetricalPostureChecked: prevData ? prevData.asymmetricalPostureChecked : false,
      postureNote: prevData ? prevData.postureNote : "",

      weightBearingEven: prevData ? prevData.weightBearingEven : false,
      weightBearingUneven: prevData ? prevData.weightBearingUneven : false,
      weightBearingRatioL: prevData ? prevData.weightBearingRatioL : "",
      weightBearingRatioR: prevData ? prevData.weightBearingRatioR : "",
      loadCarryingEven: prevData ? prevData.loadCarryingEven : false,
      loadCarryingUneven: prevData ? prevData.loadCarryingUneven : false,
      loadCarryingRatioL: prevData ? prevData.loadCarryingRatioL : "",
      loadCarryingRatioR: prevData ? prevData.loadCarryingRatioR : "",

      lossOfLoadControl: prevData ? prevData.lossOfLoadControl : false,
      lossOfTrunkControl: prevData ? prevData.lossOfTrunkControl : false,
      insufficientLLStrength: prevData ? prevData.insufficientLLStrength : false,
      insufficientULStrength: prevData ? prevData.insufficientULStrength : false,
      cvMax: prevData ? prevData.cvMax : false,
      discomfortReported: prevData ? prevData.discomfortReported : false,
      maxLiftOther: prevData ? prevData.maxLiftOther : false,
      maxLiftNote: prevData ? prevData.maxLiftNote : "",
    });

    const handleCheckboxChange = (event: any): void => {
      event.persist();
      setState((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.checked,
      }));
    };

    const handleValueChange = (event: any): void => {
      event.persist();
      setState((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
      }));
    };

    const handleHeartRateChanged = (heartRate: string): void => {
      setState((prevState) => ({
        ...prevState,
        currentHeartRate: heartRate,
      }));
    };

    const handlePreTestHeartRateChanged = (event: any): void => {
      event.persist();
      setState((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
        currentHeartRate: event.target.value,
      }));
    };

    const handlePreTestRecoveryStopped = (recoveryTimeFormatted: string) => {
      setState((prevState) => ({
        ...prevState,
        preTestRecoveryModalOpen: false,
        preTestRecoveryTime: recoveryTimeFormatted,
      }));
    };

    const handleRecoveryOpenModal = () => {
      setState((prevState) => ({ ...prevState, recoveryModalOpen: true }));
    };

    const handleRecoveryStopped = (recoveryTimeFormatted: string) => {
      const isBreakTaken = state.testWeight > 0 && state.decision !== "";

      setState((prevState) => ({
        ...prevState,
        recoveryModalOpen: false,
        isBreakTaken,
        recoveryTimeFormatted,
      }));

      if (isBreakTaken) {
        incrementAttempts(ManualHandlingDecisions.REST_TO_LOWER_HR.key, state.testWeight, Number(state.currentHeartRate));
      }
    };

    const handleAssessorStoppedOpenModal = (): void => {
      setState((prevState) => ({
        ...prevState,
        assessorStoppedModalOpen: true,
      }));
    };

    const handleAssessorStoppedCloseModal = (stoppedMessage = "", stoppedKey = "", stoppedReason = ""): void => {
      setState((prevState) => ({
        ...prevState,
        assessorStoppedModalOpen: false,
        isTestComplete: typeof stoppedMessage !== "object",
        stoppedMessage: typeof stoppedMessage === "object" ? "" : stoppedMessage,
        stoppedReason: stoppedReason,
        assessorStoppedKey: typeof stoppedMessage === "object" ? "" : stoppedKey,
      }));

      if (typeof stoppedMessage !== "object") {
        finaliseAttempts(ManualHandlingDecisions.ASSESSOR_STOPPED.key, state.testWeight, Number(state.currentHeartRate));
      }
    };

    const handlePreTestCloseModal = (restRequired: boolean) => {
      setState((prevState) => ({
        ...prevState,
        preTestHeartRateModalOpen: false,
        preTestRecoveryModalOpen: restRequired,
      }));
    };

    const handleParticipantStoppedOpenModal = (): void => {
      setState((prevState) => ({
        ...prevState,
        participantStoppedModalOpen: true,
      }));
    };

    const handleParticipantStoppedCloseModal = (stoppedMessageParam = "", stoppedReasonParam = ""): void => {
      let isTestComplete = false;
      let { stoppedMessage, stoppedReason } = state;

      if (!!stoppedReasonParam) {
        isTestComplete = true;
        stoppedMessage = stoppedMessageParam;
        stoppedReason = stoppedReasonParam;
      }

      setState((prevState) => ({
        ...prevState,
        isTestComplete,
        stoppedMessage,
        stoppedReason,
        participantStoppedModalOpen: false,
      }));

      if (isTestComplete) {
        finaliseAttempts(ManualHandlingDecisions.PARTICIPANT_STOPPED.key, state.testWeight, Number(state.currentHeartRate));
      }
    };

    const handleTestDecision = (decision: string, weight: number, bpm: number) => {
      let isTestComplete = false;
      switch (decision) {
        case ManualHandlingDecisions.REST_TO_LOWER_HR.key: {
          handleRecoveryOpenModal();
          break;
        }
        case ManualHandlingDecisions.MET_DEMANDS.key: {
          finaliseAttempts(ManualHandlingDecisions.MET_DEMANDS.key, weight, bpm);
          isTestComplete = true;
          weight = state.targetWeight;
          break;
        }
        case ManualHandlingDecisions.ASSESSOR_STOPPED.key: {
          handleAssessorStoppedOpenModal();
          break;
        }
        case ManualHandlingDecisions.PARTICIPANT_STOPPED.key: {
          handleParticipantStoppedOpenModal();
          break;
        }
        case ManualHandlingDecisions.SAFE_TO_CONTINUE.key: {
          incrementAttempts(decision, weight, bpm);
          break;
        }
      }

      setState((prevState) => ({
        ...prevState,
        testWeight: weight,
        currentHeartRate: bpm.toString(),
        isTestComplete,
        decision,
      }));
    };

    const handleStoppedOpenModal = (key: string) => {
      key === ManualHandlingDecisions.PARTICIPANT_STOPPED.key ? handleParticipantStoppedOpenModal() : handleAssessorStoppedOpenModal();
    };

    const incrementAttempts = (prevResult: string, weight: number, bpm: number): void => {
      const pastAttempts = state.attempts;
      const numAttempts = state.attempts.length;
      const lastAttempt = numAttempts + 1;

      pastAttempts[numAttempts - 1].result = prevResult;
      pastAttempts[numAttempts - 1].weight = weight;
      pastAttempts[numAttempts - 1].bpm = bpm;

      const attempt = {
        attempt: lastAttempt,
        result: "",
      } as attempt;

      const attempts: attempt[] = [];

      pastAttempts.forEach((pastAttempt) => {
        attempts.push(pastAttempt);
      });

      attempts.push(attempt);
      setState((prevState) => ({
        ...prevState,
        attempts,
        lastAttempt,
      }));
    };

    const finaliseAttempts = (prevResult: string, weight: number, bpm: number): void => {
      const pastAttempts = state.attempts;
      const numAttempts = state.attempts.length;
      const lastAttempt = numAttempts + 1;

      pastAttempts[numAttempts - 1].result = prevResult;
      pastAttempts[numAttempts - 1].weight = weight;
      pastAttempts[numAttempts - 1].bpm = bpm;

      const attempts: attempt[] = [];

      pastAttempts.forEach((pastAttempt) => {
        attempts.push(pastAttempt);
      });

      setState((prevState) => ({
        ...prevState,
        attempts,
        lastAttempt,
      }));
    };

    const handleWeightBearingChange = (event: ChangeEvent<any>) => {
      event.persist();

      let {
        weightBearingEven,
        weightBearingUneven,
        weightBearingRatioL,
        weightBearingRatioR,
        loadCarryingEven,
        loadCarryingUneven,
        loadCarryingRatioL,
        loadCarryingRatioR,
      } = state;

      let value = "";

      switch (event.target.name) {
        case "weightBearingEven":
        case "weightBearingUneven": {
          value = event.target.checked;

          weightBearingEven = false;
          weightBearingUneven = false;
          weightBearingRatioL = event.target.checked ? "50" : "";
          weightBearingRatioR = event.target.checked ? "50" : "";
          break;
        }
        case "weightBearingRatioL": {
          value = event.target.value;
          weightBearingRatioR = (100 - Number(event.target.value)).toString();
          break;
        }
        case "weightBearingRatioR": {
          value = event.target.value;
          weightBearingRatioL = (100 - Number(event.target.value)).toString();
          break;
        }
        case "loadCarryingEven":
        case "loadCarryingUneven": {
          value = event.target.checked;

          loadCarryingEven = false;
          loadCarryingUneven = false;
          loadCarryingRatioL = event.target.checked ? "50" : "";
          loadCarryingRatioR = event.target.checked ? "50" : "";
          break;
        }
        case "loadCarryingRatioL": {
          value = event.target.value;
          loadCarryingRatioR = (100 - Number(event.target.value)).toString();
          break;
        }
        case "loadCarryingRatioR": {
          value = event.target.value;
          loadCarryingRatioL = (100 - Number(event.target.value)).toString();
          break;
        }
      }

      setState((prevState) => ({
        ...prevState,
        weightBearingEven,
        weightBearingUneven,
        weightBearingRatioL,
        weightBearingRatioR,
        loadCarryingEven,
        loadCarryingUneven,
        loadCarryingRatioL,
        loadCarryingRatioR,
        [event.target.name]: value,
      }));
    };

    const handleAOPostureChange = (event: any): void => {
      event.persist();

      setState((prevState) => ({
        ...prevState,
        symmetricalPostureChecked: false,
        asymmetricalPostureChecked: false,
        [event.target.name]: event.target.checked,
      }));
    };

    useEffect(() => {
      //When the page is loading, check for previous data and if there is any previous attempts data.
      if (prevData && prevData.attempts) {
        //If there is already data, we want to loop through it and populate the state. This tricks
        //react into allowing us to push values in later attempts. If we dump the value directly, we
        //get an error that the attempts array is not extensible.

        let attempts: [
          {
            attempt: number;
            result: string;
          }
        ];

        prevData.attempts.forEach((attempt: { attempt: number; result: string }) => {
          //If the attempts array has already been initialised, push the values.
          if (!!attempts) {
            attempts.push(attempt);
          } else {
            //otherwise, initialise it with the first attempt.
            attempts = [attempt];
          }
        });

        setState((prevState) => ({
          ...prevState,
          attempts,
          lastAttempt: attempts.length,
        }));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      const timeOutId = setTimeout(() => {
        if (!state.preTestHeartRateModalLocked && state.preTestHeartRate > 110) {
          setState((prevState) => ({
            ...prevState,
            preTestHeartRateModalOpen: true,
          }));
        }

        if (state.preTestHeartRateModalOpen && state.preTestHeartRate) {
          setState((prevState) => ({
            ...prevState,
            preTestHeartRateModalLocked: true,
          }));
        }
      }, 500);
      return () => clearTimeout(timeOutId);
    }, [state.preTestHeartRateModalLocked, state.preTestHeartRateModalOpen, state.preTestHeartRate]);

    useEffect(() => {
      const timeout = setTimeout(() => {
        props.rootStore?.dataStore.savePartialData(state, pefaAssessment.id, dataStorePageKeys.MH_BENCH_TO_ABOVE);
      }, 500);

      return () => clearTimeout(timeout);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      state.preTestHeartRate,
      state.lastAttempt,
      state.notTestedReason,
      state.notTestedNotes,
      state.stoppedReason,
      state.stoppedMessage,
      state.assessorStoppedKey,
      state.safeMaxAboveShoulder,
      state.symmetricalPostureChecked,
      state.asymmetricalPostureChecked,
      state.postureNote,
      state.weightBearingEven,
      state.weightBearingUneven,
      state.weightBearingRatioL,
      state.weightBearingRatioR,
      state.loadCarryingEven,
      state.loadCarryingUneven,
      state.loadCarryingRatioL,
      state.loadCarryingRatioR,
      state.lossOfLoadControl,
      state.lossOfTrunkControl,
      state.insufficientLLStrength,
      state.insufficientULStrength,
      state.cvMax,
      state.discomfortReported,
      state.maxLiftOther,
      state.maxLiftNote,
    ]);

    useEffect(() => {
      //Replicate the onUnmount event handler from class components.
      return () => {
        appInsights.trackEvent({ name: "intermediateSubmission", properties: { bookingId: pefaAssessment.id, dataKey: dataStorePageKeys.MH_BENCH_TO_ABOVE, data: state } });
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submitForm = (data: any, forwardTarget: any) => {
      const { attempts, stoppedReason, stoppedMessage, assessorStoppedKey, recoveryTimeFormatted, preTestRecoveryTime, isTestComplete } = state;
      props.rootStore?.dataStore.saveData(
        {
          ...data,
          isTestComplete,
          attempts,
          stoppedReason,
          stoppedMessage,
          assessorStoppedKey,
          recoveryTimeFormatted,
          preTestRecoveryTime,
        },
        pefaAssessment.id,
        dataStorePageKeys.MH_BENCH_TO_ABOVE
      );
      const pefaData = { ...props.rootStore?.dataStore.pefaData[pefaAssessment.id] };
      // take snapshot of assessment and store against booking
      props.rootStore?.pefaStore.nextSubmit(pefaData, pefaAssessment.id);
      history.push(forwardTarget);
    };

    return (
      <>
        <div className="FormStyler">
          <div className="AssessmentDetails">
            <Grid item container direction="column" xs={12}>
              <Stepper targetStep={"Manual Handling"} />
              <Container maxWidth={"lg"}>
                <Grid item container direction="row" xs={12}>
                  <Grid item container xs={9}>
                    <Card className="titleCard" variant="outlined">
                      <a href="/docs/PEFA Instructions_MH_LiftAboveShoulder.pdf" className="helpIcon" target="_blank">
                        Help
                      </a>
                      <Typography variant="h2" className="cardHeroHeading">
                        <img src={BenchToAboveShoulderIcon} alt="Bench to above shoulder icon" /> <span>Bench to Above Shoulder Lift</span>
                      </Typography>
                      <Typography variant="subtitle2" className="subtitleHeading">
                        Manual Handling
                      </Typography>
                      <div className="targetWeight">
                        <span className="targetWeightText">{state.targetWeight}kg</span>
                        <span className="percentage">
                          <span className="percent">85%</span> weight {state.weightPercentage}kg
                        </span>
                      </div>
                      <NotTested data={prevData} forwardTarget={nextDemandPage.pageKey} notTestedSaveHandler={submitForm} />
                      <input readOnly={true} type="text" ref={register} name="notTestedReason" value={state.notTestedReason} style={{ visibility: "hidden" }} />
                      <input readOnly={true} type="text" ref={register} name="notTestedNotes" value={state.notTestedNotes} style={{ visibility: "hidden" }} />
                    </Card>
                    <Card className="fitnessTitleCard" variant="outlined">
                      <Typography variant="h3" className="cardHeroHeading">
                        <img src={Baseline} alt="Success" /> Pre-test information
                      </Typography>
                      <FormGroup aria-label="position" row>
                        <div className="flexerSpaceBetween">
                          <FormControlLabel
                            control={
                              <OutlinedInput
                                color="primary"
                                name="preTestHeartRate"
                                className="textInputFull"
                                autoComplete="off"
                                endAdornment={<InputAdornment position="end">bpm</InputAdornment>}
                                inputRef={register}
                                value={state.preTestHeartRate}
                                onChange={handlePreTestHeartRateChanged}
                              />
                            }
                            label="Heart rate"
                            labelPlacement="start"
                            className="inputBoldBlue"
                          />
                        </div>
                        <div style={{ marginLeft: "170px" }} className="invalidFeedback">
                          {errors.preTestHr?.message} <br />
                          {errors.weightAchieved?.message}
                        </div>
                      </FormGroup>
                    </Card>
                    <Grid item container xs={12}>
                      <form className="PefaForm">
                        <StyledAccordion variant="outlined" className="innerOutlinedBox" defaultExpanded={true}>
                          <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography variant="subtitle1">Trials</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {state.attempts.map((attempt: attempt) => (
                              <>
                                <ManualHandlingTest
                                  index={attempt.attempt}
                                  lastAttemptIndex={state.lastAttempt}
                                  demandsTarget={state.targetWeight}
                                  recoveryModalOpen={state.recoveryModalOpen}
                                  isBreakTaken={state.isBreakTaken}
                                  breakTimeFormatted={attempt.result === ManualHandlingDecisions.REST_TO_LOWER_HR.key ? state.recoveryTimeFormatted : ""}
                                  testHandler={handleTestDecision}
                                  isLift={true}
                                  stoppedReason={state.stoppedReason}
                                  stoppedMessage={
                                    attempt.result === ManualHandlingDecisions.ASSESSOR_STOPPED.key || attempt.result === ManualHandlingDecisions.PARTICIPANT_STOPPED.key
                                      ? state.stoppedMessage
                                      : ""
                                  }
                                  stoppedEditHandler={handleStoppedOpenModal}
                                  maxStartWeight={10}
                                  testWeight={prevData && prevData[`weight_${attempt}`] ? prevData[`weight_${attempt}`] : state.testWeight}
                                  data={prevData}
                                  errors={errors}
                                  setValue={setValue}
                                  register={register}
                                  assessmentID={pefaAssessment.id}
                                  dataKey={dataStorePageKeys.MH_BENCH_TO_ABOVE}
                                  handleHeartRateChange={handleHeartRateChanged}
                                />
                                <div>
                                  <input
                                    readOnly={true}
                                    type="text"
                                    ref={register}
                                    name={`weight_${attempt.attempt}`}
                                    value={attempt.weight || ""}
                                    style={{ visibility: "hidden" }}
                                  />
                                  <input readOnly={true} type="text" ref={register} name={`bpm_${attempt.attempt}`} value={attempt.bpm || ""} style={{ visibility: "hidden" }} />
                                </div>
                              </>
                            ))}
                          </AccordionDetails>
                        </StyledAccordion>

                        {state.isTestComplete ? (
                          <Card variant="outlined" className="mhinnerOutlinedBox">
                            <Typography variant="h2" className="weightAchieved">
                              <img src={StarIcon} alt="Success" />
                              Safe weight achieved
                            </Typography>
                            <div className="mhFormRight">
                              <FormControlLabel
                                control={
                                  <OutlinedInput
                                    color="primary"
                                    className="safeWeightTextInputFull"
                                    autoComplete="off"
                                    defaultValue={state.testWeight}
                                    inputRef={register}
                                    onChange={handleValueChange}
                                  />
                                }
                                name="testWeight"
                                label="Weight"
                                labelPlacement="start"
                              />
                              <FormControlLabel
                                control={<IOSSwitch checked={state.safeMaxAboveShoulder} onChange={handleCheckboxChange} />}
                                name="safeMaxAboveShoulder"
                                className="YN"
                                label="Safe max above shoulder"
                                labelPlacement="start"
                                inputRef={register}
                              />
                            </div>
                          </Card>
                        ) : (
                          <></>
                        )}

                        <StyledAccordion variant="outlined" className="innerOutlinedBox">
                          <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography variant="subtitle1">Assessor Observations</Typography>
                            {!!errors.postureAsymmetrical?.message ||
                            !!errors.posture?.message ||
                            !!errors.weightBearing?.message ||
                            !!errors.loadCarrying?.message ||
                            !!errors.safeMaxLift?.message ||
                            !!errors.safeMaxLiftNotes?.message ? (
                              <ErrorOutline className="invalid-feedback" />
                            ) : (
                              <></>
                            )}
                          </AccordionSummary>
                          <AccordionDetails>
                            <div>
                              <div>
                                <FormGroup>
                                  <FormLabel component="legend">Posture</FormLabel>
                                  <div className="flexer">
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="symmetricalPostureChecked"
                                          checked={state.symmetricalPostureChecked}
                                          icon={<span className="checkboxIcon"></span>}
                                          checkedIcon={<span className="checkboxIconChecked"></span>}
                                          color="primary"
                                          inputRef={register}
                                          onChange={handleAOPostureChange}
                                        />
                                      }
                                      label="Symmetrical"
                                    />
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="asymmetricalPostureChecked"
                                          checked={state.asymmetricalPostureChecked}
                                          icon={<span className="checkboxIcon"></span>}
                                          checkedIcon={<span className="checkboxIconChecked"></span>}
                                          color="primary"
                                          inputRef={register}
                                          onChange={handleAOPostureChange}
                                        />
                                      }
                                      label="Asymmetrical (describe)"
                                    />
                                  </div>
                                  {state.asymmetricalPostureChecked ? (
                                    <OutlinedInput
                                      color="primary"
                                      placeholder="Describe here"
                                      name="postureNote"
                                      className="textInputFull"
                                      autoComplete="off"
                                      inputRef={register}
                                      value={state.postureNote}
                                      onChange={handleValueChange}
                                    />
                                  ) : (
                                    <></>
                                  )}
                                  <div style={{ marginLeft: "0px" }} className="invalidFeedback">
                                    {errors.postureAsymmetrical?.message}
                                    {errors.posture?.message} <br />
                                  </div>
                                </FormGroup>
                              </div>
                              <div>
                                <FormGroup className="formGroupOveride">
                                  <FormLabel component="legend">LL weight-bearing</FormLabel>
                                  <div className="flexer">
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          icon={<span className="checkboxIcon"></span>}
                                          checkedIcon={<span className="checkboxIconChecked"></span>}
                                          color="primary"
                                          checked={state.weightBearingEven}
                                          onChange={handleWeightBearingChange}
                                        />
                                      }
                                      inputRef={register}
                                      label="Even"
                                      name="weightBearingEven"
                                    />
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          icon={<span className="checkboxIcon"></span>}
                                          checkedIcon={<span className="checkboxIconChecked"></span>}
                                          color="primary"
                                          checked={state.weightBearingUneven}
                                          onChange={handleWeightBearingChange}
                                        />
                                      }
                                      inputRef={register}
                                      label="Uneven"
                                      name="weightBearingUneven"
                                    />
                                    <FormLabel component="legend" className="wbRatio">
                                      Ratio
                                    </FormLabel>
                                    <FormControlLabel
                                      control={
                                        <OutlinedInput
                                          color="primary"
                                          className="textInputTiny"
                                          autoComplete="off"
                                          value={state.weightBearingRatioL}
                                          onChange={handleWeightBearingChange}
                                          disabled={!state.weightBearingUneven}
                                        />
                                      }
                                      inputRef={register}
                                      label="L"
                                      labelPlacement="start"
                                      name="weightBearingRatioL"
                                    />
                                    <FormControlLabel
                                      control={
                                        <OutlinedInput
                                          color="primary"
                                          className="textInputTiny"
                                          autoComplete="off"
                                          value={state.weightBearingRatioR}
                                          onChange={handleWeightBearingChange}
                                          disabled={!state.weightBearingUneven}
                                        />
                                      }
                                      inputRef={register}
                                      label="R"
                                      labelPlacement="start"
                                      name="weightBearingRatioR"
                                    />
                                  </div>
                                  <div style={{ marginLeft: "0px" }} className="invalidFeedback">
                                    {errors.weightBearing?.message} <br />
                                  </div>
                                </FormGroup>
                              </div>
                              <div>
                                <FormGroup className="formGroupOveride">
                                  <FormLabel component="legend">UL load carrying</FormLabel>
                                  <div className="flexer">
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          icon={<span className="checkboxIcon"></span>}
                                          checkedIcon={<span className="checkboxIconChecked"></span>}
                                          color="primary"
                                          checked={state.loadCarryingEven}
                                          onChange={handleWeightBearingChange}
                                        />
                                      }
                                      inputRef={register}
                                      label="Even"
                                      name="loadCarryingEven"
                                    />
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          icon={<span className="checkboxIcon"></span>}
                                          checkedIcon={<span className="checkboxIconChecked"></span>}
                                          color="primary"
                                          checked={state.loadCarryingUneven}
                                          onChange={handleWeightBearingChange}
                                        />
                                      }
                                      inputRef={register}
                                      label="Uneven"
                                      name="loadCarryingUneven"
                                    />
                                    <FormLabel component="legend" className="wbRatio">
                                      Ratio
                                    </FormLabel>
                                    <FormControlLabel
                                      control={
                                        <OutlinedInput
                                          color="primary"
                                          className="textInputTiny"
                                          autoComplete="off"
                                          value={state.loadCarryingRatioL}
                                          onChange={handleWeightBearingChange}
                                          disabled={!state.loadCarryingUneven}
                                        />
                                      }
                                      inputRef={register}
                                      label="L"
                                      labelPlacement="start"
                                      name="loadCarryingRatioL"
                                    />
                                    <FormControlLabel
                                      control={
                                        <OutlinedInput
                                          color="primary"
                                          className="textInputTiny"
                                          autoComplete="off"
                                          value={state.loadCarryingRatioR}
                                          onChange={handleWeightBearingChange}
                                          disabled={!state.loadCarryingUneven}
                                        />
                                      }
                                      inputRef={register}
                                      label="R"
                                      labelPlacement="start"
                                      name="loadCarryingRatioR"
                                    />
                                  </div>
                                  <div style={{ marginLeft: "0px" }} className="invalidFeedback">
                                    {errors.loadCarrying?.message} <br />
                                  </div>
                                </FormGroup>
                              </div>
                              <div>
                                <FormGroup className="formGroupOveride">
                                  <FormLabel component="legend">Signs of safe max lift</FormLabel>
                                  <div className="flexer">
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="lossOfLoadControl"
                                          checked={state.lossOfLoadControl}
                                          icon={<span className="checkboxIcon"></span>}
                                          checkedIcon={<span className="checkboxIconChecked"></span>}
                                          color="primary"
                                          inputRef={register}
                                          onChange={handleCheckboxChange}
                                        />
                                      }
                                      label="Loss of load control (describe)"
                                    />
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="lossOfTrunkControl"
                                          checked={state.lossOfTrunkControl}
                                          icon={<span className="checkboxIcon"></span>}
                                          checkedIcon={<span className="checkboxIconChecked"></span>}
                                          color="primary"
                                          inputRef={register}
                                          onChange={handleCheckboxChange}
                                        />
                                      }
                                      label="Loss of trunk control (describe)"
                                    />
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="insufficientLLStrength"
                                          checked={state.insufficientLLStrength}
                                          icon={<span className="checkboxIcon"></span>}
                                          checkedIcon={<span className="checkboxIconChecked"></span>}
                                          color="primary"
                                          inputRef={register}
                                          onChange={handleCheckboxChange}
                                        />
                                      }
                                      label="Insufficient LL strength (describe)"
                                    />
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="insufficientULStrength"
                                          checked={state.insufficientULStrength}
                                          icon={<span className="checkboxIcon"></span>}
                                          checkedIcon={<span className="checkboxIconChecked"></span>}
                                          color="primary"
                                          inputRef={register}
                                          onChange={handleCheckboxChange}
                                        />
                                      }
                                      label="Insufficient UL strength (describe)"
                                    />
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="cvMax"
                                          checked={state.cvMax}
                                          icon={<span className="checkboxIcon"></span>}
                                          checkedIcon={<span className="checkboxIconChecked"></span>}
                                          color="primary"
                                          inputRef={register}
                                          onChange={handleCheckboxChange}
                                        />
                                      }
                                      label="CV max (describe)"
                                    />
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="discomfortReported"
                                          checked={state.discomfortReported}
                                          icon={<span className="checkboxIcon"></span>}
                                          checkedIcon={<span className="checkboxIconChecked"></span>}
                                          color="primary"
                                          inputRef={register}
                                          onChange={handleCheckboxChange}
                                        />
                                      }
                                      label="Discomfort reported (will still need to fill in the Discomfort report section)"
                                    />
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="maxLiftOther"
                                          checked={state.maxLiftOther}
                                          icon={<span className="checkboxIcon"></span>}
                                          checkedIcon={<span className="checkboxIconChecked"></span>}
                                          color="primary"
                                          inputRef={register}
                                          onChange={handleCheckboxChange}
                                        />
                                      }
                                      label="Other (describe)"
                                    />
                                  </div>
                                  {state.lossOfLoadControl ||
                                  state.lossOfTrunkControl ||
                                  state.insufficientLLStrength ||
                                  state.insufficientULStrength ||
                                  state.cvMax ||
                                  state.maxLiftOther ? (
                                    <OutlinedInput
                                      color="primary"
                                      placeholder="Describe here"
                                      name="maxLiftNote"
                                      className="textInputFull"
                                      autoComplete="off"
                                      inputRef={register}
                                      value={state.maxLiftNote}
                                      onChange={handleValueChange}
                                    />
                                  ) : (
                                    <></>
                                  )}
                                  <div style={{ marginLeft: "0px" }} className="invalidFeedback">
                                    {errors.safeMaxLift?.message}
                                    {errors.safeMaxLiftNotes?.message}
                                    <br />
                                  </div>
                                </FormGroup>
                              </div>
                            </div>
                          </AccordionDetails>
                        </StyledAccordion>
                        <ParticipantReports
                          datasetName="benchAboveShoulder"
                          register={register}
                          data={prevData}
                          errors={errors}
                          setValue={setValue}
                          assessmentID={pefaAssessment.id}
                          dataKey={dataStorePageKeys.MH_BENCH_TO_ABOVE}
                        />
                        <Modal isOpen={state.preTestHeartRateModalOpen} transitionKey={modalTransitions.SLIDE_UP} handleClose={handlePreTestCloseModal}>
                          <div className="hbpModalText">
                            <Typography variant="h2" className="modalHeader">
                              High pre-test heart rate
                            </Typography>
                            <Typography variant="body2" className="modalText">
                              Does the participant need to rest before starting?
                            </Typography>
                            <div className="spacer">
                              <Button color="primary" variant="contained" fullWidth onClick={() => handlePreTestCloseModal(true)}>
                                Brief rest to lower heart rate
                              </Button>
                              <Button color="primary" variant="outlined" fullWidth className="retestBtn" onClick={() => handlePreTestCloseModal(false)}>
                                No rest time required
                              </Button>
                            </div>
                          </div>
                        </Modal>
                        <AssessorStoppedModal
                          isOpen={state.assessorStoppedModalOpen}
                          handleStoppedCloseModal={handleAssessorStoppedCloseModal}
                          stoppedKey={state.assessorStoppedKey}
                          stoppedText={state.stoppedMessage}
                        />
                        <ParticipantStoppedModal
                          isOpen={state.participantStoppedModalOpen}
                          handleStoppedCloseModal={handleParticipantStoppedCloseModal}
                          stoppedText={state.stoppedMessage}
                        />
                      </form>
                    </Grid>
                  </Grid>
                  <Grid item container xs={3} className="RightCol">
                    <ProfileCard
                      firstName={pefaAssessment.pefArequest.worker.firstName}
                      lastName={pefaAssessment.pefArequest.worker.lastName}
                      participantAgeYears={participantAgeYears}
                      currentHeartRate={state.currentHeartRate}
                    />
                    <DrawerTimer countUp={true} timeTarget="10:00" isTimerOpen={state.recoveryModalOpen} timerStopHandler={handleRecoveryStopped} />
                    <DrawerTimer countUp={true} timeTarget="10:00" isTimerOpen={state.preTestRecoveryModalOpen} timerStopHandler={handlePreTestRecoveryStopped} />
                    <NotesModal pageKey={dataStorePageKeys.MH_BENCH_TO_ABOVE} pefaID={pefaAssessment.id} />
                    <InTestMedicalClearanceModal
                      firstName={referralDetails.firstName}
                      lastName={referralDetails.lastName}
                      pageKey={dataStorePageKeys.MH_BENCH_TO_ABOVE}
                      pefaID={pefaAssessment.id}
                      medicalPractice={medicalHistoryData.medicalPractitioner}
                      medicalPractitioner={medicalHistoryData.medicalPractice}
                    />
                  </Grid>
                </Grid>
              </Container>
              <FormNavigator
                forwardTarget={nextDemandPage.pageKey}
                handleSubmit={handleSubmit(submitForm)}
                warning="Please remember to gradually increase weights as per protocol"
                disabledNextButton={
                  endAssessment &&
                  endAssessment.endAssessmentReason !== "" &&
                  endAssessment.endAssessmentNotes !== "" &&
                  endAssessment.endAssessmentPage === dataStorePageKeys.MH_BENCH_TO_ABOVE
                }
                selectedKey={dataStorePageKeys.MH_BENCH_TO_ABOVE}
              />
            </Grid>
          </div>
        </div>
      </>
    );
  })
);

export default withAppInsights(mhBenchToAboveShoulder);

/* eslint-disable prettier/prettier */
import { inject, observer } from "mobx-react";
import React from "react";
import Button from "@material-ui/core/Button";
import { Root } from "../../stores";
import Grid from "@material-ui/core/Grid";
import { CompleteFooterL, BigTick, CompleteFooterR } from "../../assets/images/";
import { useHistory } from "react-router-dom";
import { DASHBOARD } from "../../routes/paths";
import AppRating from "./PefaFinalisation/AppRating";

interface DoneProps {
  rootStore?: Root;
}

const Login = inject("rootStore")(
  observer((props: DoneProps) => {
    const history = useHistory();

    const handleDoneClicked = (): void => {
      history.push(DASHBOARD);
    };

    return (
      <div className="PefaSent">
        <Grid container spacing={0} style={{ marginTop: "6%", height: "80vh" }}>
          <Grid
            container
            item
            xs={12}
            style={{
              textAlign: "center",
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div className="login">
              <img src={BigTick} alt="tick" />
              <h1 className="PefaSentHeading">PEFA Sent!</h1>
              <br />
              <p className="PefaSentCopy" style={{marginBottom: "5%"}}>
                These <span>PEFA</span> Results have been submitted. A copy of the <span>Results</span> for you to check has been sent
                to the <span>Provider and Assessor </span> emails associated with this referral.
              </p>
            <div>
              <AppRating />
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                bottom: "0px",
                overflow: "hidden",
                left: "40px",
              }}
            >
              <img src={CompleteFooterL} style={{ marginBottom: "-10px" }} alt="Logo" />
            </div>
            <div
              style={{
                position: "absolute",
                bottom: "0px",
                right: "140px",
                overflow: "hidden",
              }}
            >
              <img src={CompleteFooterR} style={{ marginBottom: "-150px" }} alt="Logo" />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  })
);

export default Login;

import React, { ChangeEvent, Component, ReactNode } from "react";
import { Typography, FormControl, Button, FormGroup, Grid, MenuItem, Select, TextField, Modal, Backdrop, Slide } from "@material-ui/core";
import { HelpIcon } from "../../assets/images";

import { inject, observer } from "mobx-react";

interface TimerStoppedProps {
  currentTime: string;
  isOpen: boolean;
  retestHandler: any;
  continueHandler: any;
  resumeHandler?: any;
}

interface TimerStoppedState {
  stoppedModalOpen: boolean;

  notTestedReason: string;
  notTestedNotes: string;

  invalid: boolean;
}
@inject("rootStore")
@observer
class NotTested extends Component<TimerStoppedProps, TimerStoppedState> {
  constructor(props: TimerStoppedProps) {
    super(props);

    this.state = {
      stoppedModalOpen: this.props.isOpen || false,

      notTestedReason: "SELECT",
      notTestedNotes: "",

      invalid: false,
    };

    this.handleContinue = this.handleContinue.bind(this);
    this.handleNotesChange = this.handleNotesChange.bind(this);
  }

  componentWillReceiveProps(nextProps: TimerStoppedProps): void {
    if (nextProps.isOpen !== this.props.isOpen) {
      this.setState((prevState) => ({
        ...prevState,
        stoppedModalOpen: nextProps.isOpen,
      }));
    }
  }

  handleNotesChange(event: ChangeEvent<any>): void {
    event.persist();
    this.setState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  }

  handleContinue(): void {
    const { notTestedNotes, notTestedReason } = this.state;

    if (!!notTestedNotes && !!notTestedReason) {
      this.props.continueHandler(this.state.notTestedReason, this.state.notTestedNotes);
    } else {
      this.setState((prevState) => ({
        ...prevState,
        invalid: true,
      }));
    }
  }

  render(): ReactNode {
    const { stoppedModalOpen, notTestedNotes, notTestedReason, invalid } = this.state;
    return (
      <div className="notTestedHeader">
        <Modal
          aria-labelledby="modal-title"
          open={stoppedModalOpen}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Slide direction="up" in={stoppedModalOpen} mountOnEnter unmountOnExit>
            <div className="modalPop">
              <div className="notesModalText">
                <Typography variant="h2" className="helpHeader">
                  <img src={HelpIcon} alt="Notes Icon" />
                  Timer stopped
                </Typography>
                <Typography variant="caption" className="stopCaption">
                  Stopped time: <span className="stoppedTime">{this.props.currentTime}</span>
                </Typography>
                <Typography variant="caption" className="helpCaption">
                  Why was the timer stopped?
                </Typography>
                <FormGroup className="formGroupOveride">
                  <Grid xs={12} item container className="StrengthTestContainer">
                    <FormControl component="fieldset" className="fieldsetStrength">
                      <FormControl variant="outlined">
                        <Select
                          color="primary"
                          value={notTestedReason}
                          className="notTestedSelect"
                          name="notTestedReason"
                          onChange={(event) => {
                            event.persist();
                            this.setState((prevState) => ({
                              ...prevState,
                              notTestedReason: event.target.value as string,
                            }));
                          }}
                        >
                          <MenuItem value="SELECT">Select option from list</MenuItem>
                          <MenuItem value="STOPPEDBYPARTICIPANT">Stopped by the participant</MenuItem>
                          <MenuItem value="STOPPEDBYASSESSOR">Stopped by the assessor</MenuItem>
                          <MenuItem value="STOPPEDHEARTRATEEXCEEEDMHR">Heart rate &gt;85% MHR</MenuItem>
                          <MenuItem value="STOPPEDEQUIPMENTFAILURE">Equipment failure</MenuItem>
                          <MenuItem value="STOPPEDOTHER">Other</MenuItem>
                        </Select>
                      </FormControl>
                      <FormGroup className="formGroupOveride">
                        <FormGroup>
                          <TextField
                            name="notTestedNotes"
                            multiline
                            fullWidth
                            variant="outlined"
                            className="outlineTextArea"
                            label="Enter notes here"
                            value={notTestedNotes}
                            onChange={this.handleNotesChange}
                          />
                        </FormGroup>
                        {invalid ? (
                          <div className="invalid-feedback textInputMarginLeft">
                            <span>Please provide the reason and a description of why the timer was stopped.</span>
                          </div>
                        ) : (
                          <></>
                        )}
                      </FormGroup>
                      <div className="buttonsRight">
                        {!!this.props.resumeHandler ? (
                          <Button color="primary" variant="outlined" className="retestBtn" onClick={this.props.resumeHandler}>
                            Resume
                          </Button>
                        ) : (
                          <></>
                        )}

                        <Button color="primary" variant="outlined" className="retestBtn" onClick={this.props.retestHandler}>
                          Retest
                        </Button>
                        <Button color="primary" variant="contained" className="saveNoteBtn" onClick={this.handleContinue}>
                          Save and continue
                        </Button>
                      </div>
                    </FormControl>
                  </Grid>
                </FormGroup>
              </div>
            </div>
          </Slide>
        </Modal>
      </div>
    );
  }
}

export default NotTested;

import React, { useEffect } from "react";
import { ProfileCard, FormNavigator, Stepper, LoadingIndicator, NotesModal } from "../../../components";
import { MskSitting } from "../../../assets/images";
import { ExpandMore } from "@material-ui/icons";

import {
  Grid,
  Typography,
  Container,
  FormLabel,
  FormControlLabel,
  OutlinedInput,
  Card,
  FormGroup,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  InputAdornment,
  Button,
  Checkbox,
} from "@material-ui/core";
import { Accordion as StyledAccordion } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import { Root } from "../../../stores";
import { differenceInYears } from "date-fns";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { dataStorePageKeys } from "../../../stores/dataStore";
import { useHistory } from "react-router-dom";
import { DASHBOARD, PEFAREVIEW, FITNESSTEST } from "../../../routes/paths";
import { getAppInsights } from "../../../components/telemetry";
import ApiService from "../../../services/ApiService";
import finalBPValidation from "./validation";

interface FinalBPReadingProps {
  rootStore?: Root;
}
const FinalBPReading = inject("rootStore")(
  observer((props: FinalBPReadingProps) => {
    let pefaAssessment: any;
    let prevData: any;
    let referralDetails: any;
    let participantAgeYears = 0;
    let baselineMeasurements: any;
    let medicalHistoryData: any;
    let endAssessment: any;
    let BPSys: any;
    let BPDia: any;
    let isHighBPFlowTested = false;
    let showHighBloodPressure = false;
    let forwardTarget: any;
    const history = useHistory();
    const appInsights = getAppInsights();

    if (props.rootStore?.pefaStore.selectedPefa) {
      pefaAssessment = props.rootStore?.pefaStore.selectedPefa[0];
      prevData = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.FINAL_BP_READING);
      referralDetails = props.rootStore?.dataStore.getCheckInData(pefaAssessment.id, dataStorePageKeys.REFERRAL_DETAILS);
      participantAgeYears = differenceInYears(new Date(), new Date(referralDetails?.dob ?? pefaAssessment.pefArequest.worker.dateOfBirth));
      baselineMeasurements = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.BASELINE_MEASUREMENTS);
      medicalHistoryData = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.MEDICAL_DETAILS);
      endAssessment = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.END_ASSESSMENT);
      isHighBPFlowTested = medicalHistoryData.highBloodPressureViewedMC === "ViewedAccepted" ? false : true;
      BPSys = pefaAssessment.pefArequest.company.bpDia || 145;
      BPDia = pefaAssessment.pefArequest.company.bpSys || 95;
      showHighBloodPressure =
        isHighBPFlowTested &&
        ((Number(baselineMeasurements.bloodPressureDiastolic3) > Number(BPDia) && Number(baselineMeasurements.bloodPressureSystolic3) > 0) ||
          (Number(baselineMeasurements.bloodPressureSystolic3) > Number(BPSys) && Number(baselineMeasurements.bloodPressureDiastolic3) > 0));
      forwardTarget = FITNESSTEST;
    } else {
      history.push(DASHBOARD);
      return <></>;
    }

    const { register, handleSubmit, errors } = useForm({
      resolver: yupResolver(finalBPValidation),
    });

    const [state, setState] = useState({
      isLoading: true,
      isUploading: false,
      isChanged: false,
      enablePreview: prevData ? prevData.enablePreview : false,
      finalBPSubmittted: prevData ? prevData.finalBPSubmittted : false,
      clearanceDownloaded: prevData ? prevData.clearanceDownloaded : false,
      bloodPressureSystolic4: prevData ? prevData.bloodPressureSystolic4 : "",
      bloodPressureDiastolic4: prevData ? prevData.bloodPressureDiastolic4 : "",
      mcBloodPressureSystolic4: prevData ? prevData.mcBloodPressureSystolic4 : "",
      mcBloodPressureDiastolic4: prevData ? prevData.mcBloodPressureDiastolic4 : "",
    });
    useEffect(() => {
      const timeout = setTimeout(() => {
        props.rootStore?.dataStore.savePartialData(state, pefaAssessment.id, dataStorePageKeys.FINAL_BP_READING);
      }, 500);

      return () => clearTimeout(timeout);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      state.finalBPSubmittted,
      state.bloodPressureSystolic4,
      state.bloodPressureDiastolic4,
      state.clearanceDownloaded,
      state.enablePreview,
      state.mcBloodPressureSystolic4,
      state.mcBloodPressureDiastolic4,
    ]);

    const handleClearanceDocumentDownload = async (): Promise<void> => {
      setState((prevState) => ({ ...prevState, isUploading: true }));
      const clearances = {
        "Blood Pressure": `${baselineMeasurements.bloodPressureSystolic}/${baselineMeasurements.bloodPressureDiastolic}, ${baselineMeasurements.bloodPressureSystolic2}/${baselineMeasurements.bloodPressureDiastolic2}, ${baselineMeasurements.bloodPressureSystolic3}/${baselineMeasurements.bloodPressureDiastolic3}, ${state.bloodPressureSystolic4}/${state.bloodPressureDiastolic4}`,
      };
      if (pefaAssessment.id) {
        await ApiService.getEndPEFAMedicalClearanceDocument(
          pefaAssessment.id,
          `MedicalClearance - ${pefaAssessment.pefArequest.worker.firstName} ${pefaAssessment.pefArequest.worker.lastName}.docx`,
          clearances,
          medicalHistoryData.medicalPractitioner || "",
          medicalHistoryData.medicalPractice || ""
        );
        const mcBloodPressureSystolic4 = state.bloodPressureSystolic4;
        const mcBloodPressureDiastolic4 = state.bloodPressureDiastolic4;
        setState((prevState) => ({ ...prevState, clearanceDownloaded: true, isChanged: false, isUploading: false, mcBloodPressureSystolic4, mcBloodPressureDiastolic4 }));
      }
    };
    const handleValueChange = (event: any): void => {
      event.persist();
      const isChanged =
        (state.mcBloodPressureSystolic4 !== "" && state.mcBloodPressureSystolic4 !== state.bloodPressureSystolic4) ||
        (state.mcBloodPressureDiastolic4 !== "" && state.mcBloodPressureDiastolic4 !== state.bloodPressureDiastolic4)
          ? true
          : false;

      setState((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
        clearanceDownloaded: false,
        enablePreview: false,
        isChanged,
      }));
    };

    const handleEndPefa = () => {
      const bloodPressureSystolic4 = state.bloodPressureSystolic4;
      const bloodPressureDiastolic4 = state.bloodPressureDiastolic4;
      props.rootStore?.dataStore.saveData(
        {
          ...baselineMeasurements,
          bloodPressureSystolic4,
          bloodPressureDiastolic4,
        },
        pefaAssessment.id,
        dataStorePageKeys.BASELINE_MEASUREMENTS
      );
    };

    const submitForm = (data: any, forwardTarget: any) => {
      if (Number(state.bloodPressureDiastolic4) > 0 && Number(state.bloodPressureSystolic4) > 0) {
        const pefaData = { ...props.rootStore?.dataStore.pefaData[pefaAssessment.id] };
        // take snapshot of assessment and store against booking
        props.rootStore?.pefaStore.nextSubmit(pefaData, pefaAssessment.id);
        const finalBPSubmittted = true;
        const clearanceDownloaded = state.clearanceDownloaded;
        const enablePreview = state.enablePreview;
        const mcBloodPressureSystolic4 = state.mcBloodPressureSystolic4;
        const mcBloodPressureDiastolic4 = state.mcBloodPressureDiastolic4;
        props.rootStore?.dataStore.saveData(
          { ...data, finalBPSubmittted, clearanceDownloaded, mcBloodPressureSystolic4, mcBloodPressureDiastolic4, enablePreview },
          pefaAssessment.id,
          dataStorePageKeys.FINAL_BP_READING
        );
        const bloodPressureSystolic4 = state.bloodPressureSystolic4;
        const bloodPressureDiastolic4 = state.bloodPressureDiastolic4;
        props.rootStore?.dataStore.saveData(
          {
            ...baselineMeasurements,
            bloodPressureSystolic4,
            bloodPressureDiastolic4,
          },
          pefaAssessment.id,
          dataStorePageKeys.BASELINE_MEASUREMENTS
        );

        if (is4thBPReadingHigh() && state.enablePreview) {
          const endAssessmentReason = "Sent for MC";
          const endAssessmentNotes = "High BP";
          const endAssessmentPage = dataStorePageKeys.FINAL_BP_READING;
          const submissionData = { endAssessmentReason, endAssessmentNotes, endAssessmentPage };
          props.rootStore?.dataStore.saveData(submissionData, pefaAssessment.id, dataStorePageKeys.END_ASSESSMENT);
          history.push(PEFAREVIEW);
        } else {
          // if 4th bp reading is low, go back to step test or to next functional order
          const newTargetPage = props.rootStore?.pefaStore.getNextStepAfter4thLowBP();
          if (newTargetPage) {
            history.push(newTargetPage.pageKey);
          }
        }
      }
    };
    const is4thBPReadingHigh = (): boolean => {
      return (
        showHighBloodPressure &&
        ((Number(state.bloodPressureDiastolic4) > Number(BPDia) && Number(state.bloodPressureSystolic4) > 0) ||
          (Number(state.bloodPressureSystolic4) > Number(BPSys) && Number(state.bloodPressureDiastolic4) > 0))
      );
    };
    return (
      <div className="FormStyler">
        <div className="AssessmentDetails">
          <Grid item container direction="column" xs={12}>
            <Stepper targetStep={"Postural & Dynamic Tolerances"} />
            <Container maxWidth={"lg"}>
              <Grid item container direction="row" xs={12}>
                <Grid item container xs={9}>
                  <Card className="titleCard" variant="outlined">
                    <Typography variant="h2" className="cardHeroHeading">
                      <img src={MskSitting} className="bpSitting" alt="Success" /> Sitting: Final Blood Pressure Reading
                    </Typography>
                  </Card>

                  <Grid item container xs={12}>
                    <form className="PefaForm">
                      {showHighBloodPressure ? (
                        <StyledAccordion variant="outlined" className="innerOutlinedBox" defaultExpanded={true}>
                          <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography variant="h3" className="bpCardHeroHeading">
                              4th Blood Pressure Reading
                              {errors.bloodPressureDiastolic4?.message || errors.bloodPressureSystolic4?.message ? (
                                <svg className="MuiSvgIcon-root invalid-bpReading" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                  <path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
                                </svg>
                              ) : (
                                <></>
                              )}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <input type="text" style={{ visibility: "hidden" }} name={`isHighBPFlowTested`} defaultValue={isHighBPFlowTested?.toString()} ref={register} />
                            <input type="text" style={{ visibility: "hidden" }} name={`showHighBloodPressure`} defaultValue={showHighBloodPressure.toString()} ref={register} />
                            <FormControl component="fieldset" className="fieldsetStrength">
                              <FormLabel component="legend">
                                The participant's blood pressure is higher than the safety cutoff of{" "}
                                <span className="bpLimit">
                                  {BPSys}/{BPDia} mmHg
                                </span>
                                . Please check the cuff size and give them a moment to relax and retest
                              </FormLabel>
                              <div className="spacer">
                                <span className="prevResult">Previous result</span>
                                <span className="actualResult">
                                  {baselineMeasurements?.bloodPressureSystolic3}/{baselineMeasurements?.bloodPressureDiastolic3} mmHg
                                </span>
                              </div>
                              <FormGroup className="formGroupOveride">
                                <Grid xs={12} item container className="StrengthTestContainer">
                                  <div>
                                    <FormGroup className="formGroupOveride">
                                      <div className="flexer">
                                        <FormLabel className="bpLeft" component="legend">
                                          Blood pressure sitting
                                        </FormLabel>
                                        <FormControlLabel
                                          control={
                                            <OutlinedInput
                                              color="primary"
                                              className={state.finalBPSubmittted ? "disabled-bp" : "bp"}
                                              endAdornment={<InputAdornment position="end">mmhg</InputAdornment>}
                                              name="bloodPressureSystolic4"
                                              aria-describedby="standard-weight-helper-text"
                                              inputRef={register}
                                              autoComplete="off"
                                              defaultValue={state.bloodPressureSystolic4}
                                              readOnly={state.finalBPSubmittted}
                                              onChange={handleValueChange}
                                            />
                                          }
                                          label="&nbsp;"
                                          labelPlacement="top"
                                          name="systolicPressure"
                                        />
                                        <FormControlLabel
                                          control={
                                            <OutlinedInput
                                              color="primary"
                                              className={state.finalBPSubmittted ? "disabled-bp" : "bp"}
                                              endAdornment={<InputAdornment position="end">mmhg</InputAdornment>}
                                              name="bloodPressureDiastolic4"
                                              aria-describedby="standard-weight-helper-text"
                                              inputRef={register}
                                              autoComplete="off"
                                              defaultValue={state.bloodPressureDiastolic4}
                                              onChange={handleValueChange}
                                              readOnly={state.finalBPSubmittted}
                                            />
                                          }
                                          label="&nbsp;"
                                          labelPlacement="top"
                                          name="diastolicPressure"
                                        />
                                      </div>
                                    </FormGroup>
                                  </div>
                                </Grid>
                              </FormGroup>
                            </FormControl>
                            {state.bloodPressureSystolic4 == null ||
                            state.bloodPressureSystolic4 === "" ||
                            state.bloodPressureDiastolic4 == null ||
                            state.bloodPressureDiastolic4 === "" ? (
                              <div className="invalid-feedback full-width">Please enter the participant's final blood pressure reading.</div>
                            ) : (
                              <></>
                            )}
                            {is4thBPReadingHigh() ? (
                              <>
                                {state.isChanged ? (
                                  <div className="invalid-feedback full-width">
                                    Blood pressure readings have been changed. Please download and review the medical clearance again.
                                  </div>
                                ) : (
                                  <></>
                                )}
                                <Typography variant="h3" className="bpCardHeroHeading">
                                  Medical Clearance Required
                                </Typography>
                                <Typography variant="body2" className="modalText">
                                  The blood pressure reading is still too high. Please download a Medical Clearance Letter and follow the instructions. The participant's
                                  information will be in there for you.
                                </Typography>
                                <div>
                                  <FormGroup className="downloadhighbp">
                                    <Button color="primary" variant="contained" fullWidth onClick={handleClearanceDocumentDownload}>
                                      Download High BP Medical Clearance
                                    </Button>
                                  </FormGroup>
                                </div>
                                <div className="agreeDownload">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        icon={<span className="checkboxIcon require-enableEndPEFA"></span>}
                                        checkedIcon={<span className="checkboxIconChecked"></span>}
                                        color="primary"
                                        className="mc-checkbox"
                                        name="enablePreview"
                                        checked={state.enablePreview}
                                        disabled={is4thBPReadingHigh() && !state.clearanceDownloaded}
                                        onChange={(event) => {
                                          setState((prevState) => ({
                                            ...prevState,
                                            enablePreview: event.target.checked,
                                          }));
                                        }}
                                      />
                                    }
                                    label="Medical clearance letter downloaded, viewed and ready to send"
                                    className="enableEndPEFA"
                                    inputRef={register}
                                  />
                                </div>
                                {!state.enablePreview ? (
                                  <div className="invalid-medical">Please indicate that you have viewed Medical clearance letter and confirmed that it is ready to send</div>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </AccordionDetails>
                        </StyledAccordion>
                      ) : (
                        <></>
                      )}
                    </form>
                  </Grid>
                </Grid>
                <Grid item container xs={3} className="RightCol">
                  <ProfileCard
                    firstName={pefaAssessment.pefArequest.worker.firstName}
                    lastName={pefaAssessment.pefArequest.worker.lastName}
                    participantAgeYears={participantAgeYears}
                  />
                  <NotesModal pageKey={dataStorePageKeys.FINAL_BP_READING} pefaID={pefaAssessment.id} />
                </Grid>
              </Grid>
            </Container>
            <FormNavigator
              forwardTarget={forwardTarget}
              handleSubmit={handleSubmit(submitForm)}
              disabledNextButton={
                !is4thBPReadingHigh()
                  ? endAssessment &&
                    endAssessment.endAssessmentReason !== "" &&
                    endAssessment.endAssessmentNotes !== "" &&
                    endAssessment.endAssessmentPage === dataStorePageKeys.FINAL_BP_READING
                  : !state.enablePreview
              }
              showEndButton={!is4thBPReadingHigh()}
              forwardText={is4thBPReadingHigh() ? "Preview Results" : "Next"}
              disabledEndButton={
                state.bloodPressureSystolic4 == null || state.bloodPressureSystolic4 === "" || state.bloodPressureDiastolic4 == null || state.bloodPressureDiastolic4 === ""
              }
              handleEnd={handleEndPefa}
              selectedKey={dataStorePageKeys.FINAL_BP_READING}
            />
          </Grid>
        </div>
        <LoadingIndicator visible={state.isUploading} />
      </div>
    );
  })
);
export default FinalBPReading;

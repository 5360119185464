import { inject, observer } from "mobx-react";
import React from "react";
import { Route, useLocation } from "react-router-dom";
import { LOGIN, LOGINCALLBACK, LOGOUTCALLBACK } from "../../routes/paths";
import { IRoute } from "../../routes/web";
import { Root } from "../../stores";

const AppRoute = inject("rootStore")(
  observer(
    (props: any): JSX.Element => {
      const routePath = useLocation();

      //Rebuild our IRoute object, excluding the rootstore.
      //This is a bit cludgy, but it works.
      const routeRequest = { ...props, rootStore: undefined } as IRoute;
      const rootStore = props.rootStore as Root;

      if (routePath.pathname !== LOGIN && routePath.pathname !== LOGINCALLBACK && routePath.pathname !== LOGOUTCALLBACK) {
        rootStore?.userStore.setCurrentUserPath(routePath.pathname);
      }

      //IF we got to this point, the route is either not private, or the user's session has not yet expired.
      return (
        <Route
          path={routeRequest.path}
          render={(props) => {
            // pass the sub-routes down to keep nesting
            return <routeRequest.component {...props} {...(routeRequest.exact ? "exact" : "")} routes={routeRequest.routes} />;
          }}
        />
      );
    }
  )
);

export default AppRoute;

import click1mp3 from "./click1.mp3";
import click1aac from "./click1.aac";
import click1ogg from "./click1.ogg";
import click1wav from "./click1.wav";

import click2mp3 from "./click2.mp3";
import click2aac from "./click2.aac";
import click2ogg from "./click2.ogg";
import click2wav from "./click2.wav";

import click3mp3 from "./click3.mp3";
import click3aac from "./click3.aac";
import click3ogg from "./click3.ogg";
import click3wav from "./click3.wav";

export const Click1mp3 = click1mp3;
export const Click1aac = click1aac;
export const Click1ogg = click1ogg;
export const Click1wav = click1wav;

export const Click2mp3 = click2mp3;
export const Click2aac = click2aac;
export const Click2ogg = click2ogg;
export const Click2wav = click2wav;

export const Click3mp3 = click3mp3;
export const Click3aac = click3aac;
export const Click3ogg = click3ogg;
export const Click3wav = click3wav;

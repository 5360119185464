import React, { useEffect, FocusEvent } from "react";
import {
  ProfileCard,
  FormNavigator,
  Stepper,
  Timer,
  TimerStopped,
  NotTested,
  ParticipantReports,
  NotesModal,
  Peeper,
  RemoteStart,
  InTestMedicalClearanceModal,
} from "../../../components/";
import { ReachaboveIcon, StarIcon, Baseline } from "../../../assets/images/";
import ptValidation from "./validation";
import { ExpandMore, ErrorOutline } from "@material-ui/icons";

import {
  Grid,
  Typography,
  Container,
  FormLabel,
  FormControlLabel,
  OutlinedInput,
  Card,
  FormGroup,
  Checkbox,
  TextField,
  AccordionSummary,
  AccordionDetails,
  Radio,
  RadioGroup,
  InputAdornment,
} from "@material-ui/core";
import { Accordion as StyledAccordion } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import { Root } from "../../../stores";
import { differenceInYears } from "date-fns";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { dataStorePageKeys } from "../../../stores/dataStore";
import { demand } from "../../../stores/pefaStore";
import { DASHBOARD, FINALBPREADING } from "../../../routes/paths";
import { useHistory } from "react-router";
import { getAppInsights } from "../../../components/telemetry";
import withAppInsights from "../AppInsights";
import ratingHelper, { eHeartrateStatus } from "../../../helpers/ratingHelper";

interface posturalTolerancesProps {
  rootStore?: Root;
}

const ptReachAbove = inject("rootStore")(
  observer((props: posturalTolerancesProps) => {
    let pefaAssessment: any;
    let currentDemands: demand;
    let nextDemandPage: demand;
    let prevData: any;
    let referralDetails: any;
    let participantAgeYears = 0;
    let baselineMeasurements: any;
    let medicalHistoryData: any;
    let endAssessment: any;
    let BPSys: any;
    let BPDia: any;
    let isHighBPFlowTested = false;
    let showHighBloodPressure = false;
    let isThisPageTheLastPTDemand = false;
    let lastPTAndDTDemand: demand;
    const history = useHistory();
    const appInsights = getAppInsights();

    if (props.rootStore?.pefaStore.selectedPefa) {
      pefaAssessment = props.rootStore?.pefaStore.selectedPefa[0];
      currentDemands = props.rootStore?.pefaStore.selectedPefa.demands[dataStorePageKeys.PT_ABOVE];
      nextDemandPage = props.rootStore?.pefaStore.getNextPefaStep(currentDemands.functionalOrder);
      prevData = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.PT_ABOVE);
      referralDetails = props.rootStore?.dataStore.getCheckInData(pefaAssessment.id, dataStorePageKeys.REFERRAL_DETAILS);
      participantAgeYears = differenceInYears(new Date(), new Date(referralDetails?.dob ?? pefaAssessment.pefArequest.worker.dateOfBirth));
      baselineMeasurements = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.BASELINE_MEASUREMENTS);
      medicalHistoryData = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.MEDICAL_DETAILS);
      isHighBPFlowTested = medicalHistoryData.highBloodPressureViewedMC === "ViewedAccepted" ? false : true;
      BPSys = pefaAssessment.pefArequest.company.bpDia || 145;
      BPDia = pefaAssessment.pefArequest.company.bpSys || 95;
      showHighBloodPressure =
        isHighBPFlowTested &&
        ((Number(baselineMeasurements.bloodPressureDiastolic3) > Number(BPDia) && Number(baselineMeasurements.bloodPressureSystolic3) > 0) ||
          (Number(baselineMeasurements.bloodPressureSystolic3) > Number(BPSys) && Number(baselineMeasurements.bloodPressureDiastolic3) > 0));
      lastPTAndDTDemand = props.rootStore?.pefaStore.selectedPefa.lastPTAndDTDemand;
      isThisPageTheLastPTDemand = lastPTAndDTDemand?.key === currentDemands.key;
      endAssessment = props.rootStore?.dataStore.getData(pefaAssessment.id, dataStorePageKeys.END_ASSESSMENT);
    } else {
      history.push(DASHBOARD);
      return <></>;
    }

    const { register, handleSubmit, errors, setValue } = useForm({
      resolver: yupResolver(ptValidation),
    });

    const [state, setState] = useState({
      isLoading: true,
      timeElapsedFormatted: "",
      timeElapsedRaw: prevData ? parseInt(prevData.timeElapsedRaw) : -1,
      timeRange: -1,
      isTimerStoppedModalOpen: false,
      timerStoppedReason: prevData ? prevData.timerStoppedReason : "",
      timerStoppedNotes: prevData ? prevData.timerStoppedNotes : "",
      targetDemand: currentDemands.frequency,

      notTestedModalOpen: false,
      notTestedReason: prevData ? prevData.notTestedReason : "",
      notTestedNotes: prevData ? prevData.notTestedNotes : "",
      recoveryModalOpen: false,
      yesAllNilSignificant: false,
      viewAll: false,
      discomfortReported: false,
      currentHeartRate: "",

      peeperPlaying: false,
      peeperEnabled: false,
      peeperInterval: 100,
      peeperRepeats: 2,

      isTimerStarted: false,
      timerRemoteTrigger: false,
      timerRemoteResumeTrigger: false,
      remoteTimerVisible: prevData ? prevData.remoteTimerVisible : true,

      heartRateStable: prevData ? (prevData.heartRateStable ? prevData.heartRateStable : false) : false,
      heartRateInconsistent: prevData ? (prevData.heartRateInconsistent ? prevData.heartRateInconsistent : false) : false,
      heartRateIncreasing: prevData ? (prevData.heartRateIncreasing ? prevData.heartRateIncreasing : false) : false,

      asymmetricalPostureChecked: prevData ? prevData.asymmetricalPostureChecked : false,
      symmetricalPostureChecked: prevData ? prevData.symmetricalPostureChecked : false,
      asymmetricalNote: prevData ? prevData.asymmetricalNote : "",

      performanceDifficultyNotes: prevData ? prevData.performanceDifficultyNotes : "",
      difficultyMaintainingPositions: prevData ? prevData.difficultyMaintainingPositions : false,
      performanceFineMotorDifficulty: prevData ? prevData.performanceFineMotorDifficulty : false,
      performanceFrequentCues: prevData ? prevData.performanceFrequentCues : false,

      toleranceRating: prevData ? prevData.toleranceRating : "",
      Comment100: prevData ? prevData.Comment100 : "",
      Bpm100: prevData ? prevData.Bpm100 : "",
      Comment130: prevData ? prevData.Comment130 : "",
      Bpm130: prevData ? prevData.Bpm130 : "",
      Comment200: prevData ? prevData.Comment200 : "",
      Bpm200: prevData ? prevData.Bpm200 : "",
      Comment230: prevData ? prevData.Comment230 : "",
      Bpm230: prevData ? prevData.Bpm230 : "",
      Comment300: prevData ? prevData.Comment300 : "",
      Bpm300: prevData ? prevData.Bpm300 : "",
      preTestHeartRate: prevData ? prevData.preTestHeartRate : "",

      heartRateIncreasingDecision: prevData ? prevData.heartRateIncreasingDecision : "",
      heartRateStatus: prevData ? prevData.heartRateStatus : "",
      heartRateIncreasingDecisionDenyNote: prevData ? prevData.heartRateIncreasingDecisionDenyNote : "",
    });

    const handleCheckboxChange = (event: any): void => {
      event.persist();
      setState((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.checked,
      }));
    };

    const handleValueChange = (event: any): void => {
      event.persist();
      setState((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
      }));
      if (event.target.name === "heartRateIncreasingDecision") {
        setState((prevState) => ({
          ...prevState,
          heartRateIncreasingDecisionDenyNote: "",
        }));
      }
    };

    const handleHeartRateChanged = (event: FocusEvent<HTMLInputElement>): void => {
      event.persist();
      setState((prevState) => ({
        ...prevState,
        currentHeartRate: event.target.value,
        [event.target.name]: event.target.value,
        heartRateIncreasingDecision: "",
        heartRateIncreasingDecisionDenyNote: "",
      }));
    };

    const handleAOHeartRateChange = (event: any): void => {
      event.persist();

      setState((prevState) => ({
        ...prevState,
        heartRateStable: false,
        heartRateInconsistent: false,
        heartRateIncreasing: false,
        [event.target.name]: event.target.checked,
      }));
    };

    const handleAOPostureChange = (event: any): void => {
      event.persist();

      setState((prevState) => ({
        ...prevState,
        symmetricalPostureChecked: false,
        asymmetricalPostureChecked: false,
        [event.target.name]: event.target.checked,
      }));
    };

    const handleStoppedModal = () => {
      setState((prevState) => ({
        ...prevState,
        isTimerStoppedModalOpen: true,
      }));
    };

    const handleTimerEvents = (timeElapsedRaw: number, timeElapsedFormatted: string): void => {
      let timeRange = -1;
      let peeperInterval = 0;
      let peeperRepeats = 0;
      let peeperEnabled = false;

      switch (Math.floor(timeElapsedRaw / 30)) {
        case 0:
          timeRange = 0;
          break;
        case 1:
          timeRange = 1;
          break;
        case 2:
          timeRange = 2;
          break;
        case 3:
          timeRange = 3;
          break;
        case 4:
          timeRange = 4;
          break;
        case 5:
          timeRange = 5;
          break;
      }

      if (timeRange > state.timeRange && timeRange > 0) {
        peeperInterval = 200;
        peeperRepeats = 2;
        peeperEnabled = true;
      } else if (timeElapsedRaw === 180) {
        peeperInterval = 100;
        peeperRepeats = 5;
        peeperEnabled = true;
      }

      setState((prevState) => ({
        ...prevState,
        timeRange,
        timeElapsedFormatted,
        timeElapsedRaw,
        timerRemoteTrigger: false,
        timerRemoteResumeTrigger: false,
        remoteTimerVisible: false,
        isTimerStarted: true,
        peeperInterval,
        peeperRepeats,
        peeperEnabled,
      }));
    };

    const handlePeeperCompleted = (): void => {
      setState((prevState) => ({ ...prevState, peeperPlaying: false }));
    };

    const handleTimerRemoteStart = (): void => {
      setState((prevState) => ({
        ...prevState,
        timerRemoteTrigger: true,
        remoteTimerVisible: false,
      }));
    };

    const handleTimerRemoteResume = (): void => {
      setState((prevState) => ({ ...prevState, timerStoppedReason: "", timerStoppedNotes: "", timerRemoteResumeTrigger: true, isTimerStoppedModalOpen: false }));
    };

    const handleTimerStopped = (): void => {
      handleStoppedModal();
    };

    const handleStoppedRetestClicked = (): void => {
      setState((prevState) => ({
        ...prevState,
        timerStoppedReason: "",
        timerStoppedNotes: "",
        isTimerStoppedModalOpen: false,
        timerRemoteTrigger: true,
        timeRange: -1,
      }));
    };

    const discomfortReportedChange = (discomfortReported: boolean): void => {
      let { toleranceRating } = state;

      if (discomfortReported && toleranceRating === "X") {
        toleranceRating = "";
      }
      setState((prevState) => ({
        ...prevState,
        toleranceRating,
        discomfortReported,
      }));
    };

    const handleStoppedContinueClicked = (stoppedReason: string, stoppedNotes: string): void => {
      setState((prevState) => ({
        ...prevState,
        isTimerStoppedModalOpen: false,
        timerStoppedReason: stoppedReason,
        timerStoppedNotes: stoppedNotes,
      }));
    };

    useEffect(() => {
      if (!state.peeperPlaying && state.peeperEnabled) {
        setState((prevState) => ({
          ...prevState,
          peeperPlaying: true,
        }));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.peeperInterval, state.peeperRepeats]);

    useEffect(() => {
      const timeout = setTimeout(() => {
        props.rootStore?.dataStore.savePartialData(state, pefaAssessment.id, dataStorePageKeys.PT_ABOVE);
      }, 500);

      return () => clearTimeout(timeout);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      state.notTestedReason,
      state.notTestedNotes,
      state.heartRateStable,
      state.heartRateInconsistent,
      state.heartRateIncreasing,
      state.asymmetricalPostureChecked,
      state.symmetricalPostureChecked,
      state.asymmetricalNote,
      state.performanceDifficultyNotes,
      state.difficultyMaintainingPositions,
      state.performanceFineMotorDifficulty,
      state.performanceFrequentCues,
      state.toleranceRating,
      state.heartRateIncreasingDecision,
      state.heartRateStatus,
      state.heartRateIncreasingDecisionDenyNote,
      state.Comment100,
      state.Bpm100,
      state.Comment130,
      state.Bpm130,
      state.Comment200,
      state.Bpm200,
      state.Comment230,
      state.Bpm230,
      state.Comment300,
      state.Bpm300,
      state.preTestHeartRate,
    ]);

    useEffect(() => {
      //Replicate the onUnmount event handler from class components.
      return () => {
        appInsights.trackEvent({ name: "intermediateSubmission", properties: { bookingId: pefaAssessment.id, dataKey: dataStorePageKeys.PT_ABOVE, data: state } });
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      calculateToleranceRating();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      state.Bpm100,
      state.Bpm130,
      state.Bpm200,
      state.Bpm230,
      state.Bpm300,
      state.discomfortReported,
      state.heartRateIncreasing,
      state.heartRateIncreasingDecision,
      state.performanceFrequentCues,
      state.difficultyMaintainingPositions,
      state.timeElapsedRaw,
    ]);

    const calculateToleranceRating = (): string => {
      const discomfort =
        state.discomfortReported ||
        (state.heartRateIncreasing && state.heartRateIncreasingDecision === "confirm") ||
        state.performanceFrequentCues ||
        state.difficultyMaintainingPositions;
      const toleranceRating = ratingHelper.calculateToleranceRating(state.timeElapsedRaw, discomfort);
      setState((prevState) => ({
        ...prevState,
        toleranceRating,
      }));
      return toleranceRating;
    };
    useEffect(() => {
      calculateHeartRateStatus();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.Bpm100, state.Bpm130, state.Bpm200, state.Bpm230, state.Bpm300]);

    const calculateHeartRateStatus = () => {
      let heartRateStatus = "";
      if (
        state.Bpm100 !== "" &&
        !isNaN(state.Bpm100) &&
        state.Bpm130 !== "" &&
        !isNaN(state.Bpm130) &&
        state.Bpm200 !== "" &&
        !isNaN(state.Bpm200) &&
        state.Bpm230 !== "" &&
        !isNaN(state.Bpm230) &&
        state.Bpm300 !== "" &&
        !isNaN(state.Bpm300)
      ) {
        heartRateStatus = ratingHelper.calculateHRStatus(Number(state.Bpm100), Number(state.Bpm130), Number(state.Bpm200), Number(state.Bpm230), Number(state.Bpm300));
      }

      setState((prevState) => ({
        ...prevState,
        heartRateStatus,
        heartRateStable: heartRateStatus === eHeartrateStatus.STABLE,
        heartRateInconsistent: heartRateStatus === eHeartrateStatus.VARIABLE,
        heartRateIncreasing: heartRateStatus === eHeartrateStatus.INCREASING,
      }));
    };
    const submitForm = (data: any, forwardTarget: any) => {
      const heartRateStatus = state.heartRateStatus;
      const heartRateIncreasingDecision = state.heartRateIncreasingDecision;
      const heartRateIncreasingDecisionDenyNote = state.heartRateIncreasingDecisionDenyNote;
      props.rootStore?.dataStore.saveData(
        { ...data, heartRateStatus, heartRateIncreasingDecision, heartRateIncreasingDecisionDenyNote },
        pefaAssessment.id,
        dataStorePageKeys.PT_ABOVE
      );
      const pefaData = { ...props.rootStore?.dataStore.pefaData[pefaAssessment.id] };
      // take snapshot of assessment and store against booking
      props.rootStore?.pefaStore.nextSubmit(pefaData, pefaAssessment.id);
      if (showHighBloodPressure && isThisPageTheLastPTDemand) {
        history.push(FINALBPREADING);
      } else {
        history.push(forwardTarget);
      }
    };

    return (
      <div className="FormStyler">
        <TimerStopped
          currentTime={state.timeElapsedFormatted}
          isOpen={state.isTimerStoppedModalOpen}
          retestHandler={handleStoppedRetestClicked}
          continueHandler={handleStoppedContinueClicked}
          resumeHandler={handleTimerRemoteResume}
        />
        <div className="AssessmentDetails">
          <Grid item container direction="column" xs={12}>
            <Stepper targetStep={"Postural & Dynamic Tolerances"} />
            <Container maxWidth={"lg"}>
              <Grid item container direction="row" xs={12}>
                <Grid item container xs={9}>
                  <Card className="titleCard" variant="outlined">
                    <a href="/docs/PEFA Instructions_PT_ReachAboveShlder.pdf" className="helpIcon" target="_blank">
                      Help
                    </a>
                    <Typography variant="h2" className="cardHeroHeading">
                      <img src={ReachaboveIcon} alt="Success" /> Reach Above shoulder
                    </Typography>
                    <Typography variant="subtitle2" className="subtitleHeading">
                      Sustained Posture
                    </Typography>
                    <div className="targetWeight">
                      <span className="targetWeightText">{state.targetDemand}</span>
                      <span className="percentage">Requirement</span>
                    </div>
                    <NotTested data={prevData} forwardTarget={nextDemandPage.pageKey} notTestedSaveHandler={submitForm} />
                    <input type="hidden" name="notTestedReason" ref={register} value={state.notTestedReason} />
                    <input type="hidden" name="notTestedNotes" ref={register} value={state.notTestedNotes} />
                    <input type="hidden" name="timeElapsedRaw" ref={register} value={state.timeElapsedRaw} />
                    <input type="hidden" name="timerStoppedReason" ref={register} value={state.timerStoppedReason} />
                    <input type="hidden" name="timerStoppedNotes" ref={register} value={state.timerStoppedNotes} />
                  </Card>
                  <Card className="fitnessTitleCard" variant="outlined">
                    <Typography variant="h3" className="cardHeroHeading">
                      <img src={Baseline} alt="Success" /> Pre-test information
                    </Typography>
                    <FormControlLabel
                      control={
                        <OutlinedInput
                          color="primary"
                          name="preTestHeartRate"
                          className="textInputFull"
                          autoComplete="off"
                          endAdornment={<InputAdornment position="end">bpm</InputAdornment>}
                          inputRef={register}
                          value={state.preTestHeartRate}
                          onChange={handleValueChange}
                          onBlur={handleHeartRateChanged}
                        />
                      }
                      label="Heart rate"
                      labelPlacement="start"
                      className="inputBoldBlueSolo"
                    />
                    <div style={{ marginLeft: "170px" }} className="invalidFeedback">
                      {errors.preTestHr?.message}
                    </div>
                  </Card>
                  <Grid item container xs={12}>
                    <form className="PefaForm">
                      <Peeper
                        intervalMs={state.peeperInterval}
                        isPlaying={state.peeperPlaying}
                        isEnabled={state.peeperEnabled}
                        repeats={state.peeperRepeats}
                        soundKey={3}
                        handlePeeperComplete={handlePeeperCompleted}
                      />
                      <RemoteStart
                        configKey="TIMER"
                        disabled={false}
                        visible={state.remoteTimerVisible}
                        isTimerStarted={state.isTimerStarted}
                        handleRemoteStart={handleTimerRemoteStart}
                        handleRemoteResume={handleTimerRemoteResume}
                      />
                      <StyledAccordion variant="outlined" className="innerOutlinedBox" defaultExpanded={true}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                          <Typography variant="subtitle1">Test recordings</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="hrWrapper">
                            <div className={state.timeRange === 1 ? "hrCurrent" : ""}>
                              <Typography variant="caption" className="hrCountText">
                                1:00
                              </Typography>
                              <TextField
                                placeholder="BPM"
                                className="bpmInput"
                                name="Bpm100"
                                inputRef={register}
                                value={state.Bpm100}
                                variant="outlined"
                                InputLabelProps={{ shrink: false }}
                                onChange={handleHeartRateChanged}
                              />
                              <TextField
                                placeholder="Enter comments here"
                                className="bpmComment"
                                name="Comment100"
                                inputRef={register}
                                value={state.Comment100}
                                variant="outlined"
                                InputLabelProps={{ shrink: false }}
                                onChange={handleValueChange}
                              />
                            </div>
                            <div style={{ marginLeft: "60px" }} className="invalidFeedback">
                              {errors.sec100?.message} <br />
                            </div>
                          </div>
                          <div className="hrWrapper">
                            <div className={state.timeRange === 2 ? "hrCurrent" : ""}>
                              <Typography variant="caption" className="hrCountText">
                                1:30
                              </Typography>
                              <TextField
                                placeholder="BPM"
                                className="bpmInput"
                                name="Bpm130"
                                inputRef={register}
                                value={state.Bpm130}
                                variant="outlined"
                                InputLabelProps={{ shrink: false }}
                                onChange={handleHeartRateChanged}
                              />
                              <TextField
                                placeholder="Enter comments here"
                                className="bpmComment"
                                name="Comment130"
                                inputRef={register}
                                value={state.Comment130}
                                variant="outlined"
                                InputLabelProps={{ shrink: false }}
                                onChange={handleValueChange}
                              />
                            </div>
                            <div style={{ marginLeft: "60px" }} className="invalidFeedback">
                              {errors.sec130?.message} <br />
                            </div>
                          </div>
                          <div className="hrWrapper">
                            <div className={state.timeRange === 3 ? "hrCurrent" : ""}>
                              <Typography variant="caption" className="hrCountText">
                                2:00
                              </Typography>
                              <TextField
                                placeholder="BPM"
                                className="bpmInput"
                                name="Bpm200"
                                inputRef={register}
                                value={state.Bpm200}
                                variant="outlined"
                                InputLabelProps={{ shrink: false }}
                                onChange={handleHeartRateChanged}
                              />
                              <TextField
                                placeholder="Enter comments here"
                                className="bpmComment"
                                name="Comment200"
                                inputRef={register}
                                value={state.Comment200}
                                variant="outlined"
                                InputLabelProps={{ shrink: false }}
                                onChange={handleValueChange}
                              />
                            </div>
                            <div style={{ marginLeft: "60px" }} className="invalidFeedback">
                              {errors.sec200?.message} <br />
                            </div>
                          </div>
                          <div className="hrWrapper">
                            <div className={state.timeRange === 4 ? "hrCurrent" : ""}>
                              <Typography variant="caption" className="hrCountText">
                                2:30
                              </Typography>
                              <TextField
                                placeholder="BPM"
                                className="bpmInput"
                                name="Bpm230"
                                inputRef={register}
                                value={state.Bpm230}
                                variant="outlined"
                                InputLabelProps={{ shrink: false }}
                                onChange={handleHeartRateChanged}
                              />
                              <TextField
                                placeholder="Enter comments here"
                                className="bpmComment"
                                name="Comment230"
                                inputRef={register}
                                value={state.Comment230}
                                variant="outlined"
                                InputLabelProps={{ shrink: false }}
                                onChange={handleValueChange}
                              />
                            </div>
                            <div style={{ marginLeft: "60px" }} className="invalidFeedback">
                              {errors.sec230?.message} <br />
                            </div>
                          </div>
                          <div className="hrWrapper">
                            <div className={state.timeRange === 5 ? "hrCurrent" : ""}>
                              <Typography variant="caption" className="hrCountText">
                                3:00
                              </Typography>
                              <TextField
                                placeholder="BPM"
                                className="bpmInput"
                                name="Bpm300"
                                inputRef={register}
                                value={state.Bpm300}
                                variant="outlined"
                                InputLabelProps={{ shrink: false }}
                                onChange={handleHeartRateChanged}
                              />
                              <TextField
                                placeholder="Enter comments here"
                                className="bpmComment"
                                name="Comment300"
                                inputRef={register}
                                value={state.Comment300}
                                variant="outlined"
                                InputLabelProps={{ shrink: false }}
                                onChange={handleValueChange}
                              />
                            </div>
                            <div style={{ marginLeft: "60px" }} className="invalidFeedback">
                              {errors.sec300?.message} <br />
                            </div>
                          </div>
                        </AccordionDetails>
                      </StyledAccordion>
                      <StyledAccordion variant="outlined" className="innerOutlinedBox">
                        <AccordionSummary expandIcon={<ExpandMore />}>
                          <Typography variant="subtitle1">Assessor Observations</Typography>
                          {!!errors.heartRateIncreasingDecision?.message ||
                          !!errors.heartRateIncreasingDecisionDenyNote?.message ||
                          !!errors.postureAsymmetrical?.message ||
                          !!errors.posture?.message ||
                          !!errors.performance?.message ||
                          !!errors.performanceDifficulty?.message ? (
                            <ErrorOutline className="invalid-feedback" />
                          ) : (
                            <></>
                          )}
                        </AccordionSummary>
                        <AccordionDetails>
                          <div>
                            <div>
                              <FormGroup>
                                <FormLabel component="legend">Heart Rate</FormLabel>
                                <div className="flexer">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        icon={<span className="checkboxIcon"></span>}
                                        checkedIcon={<span className="checkboxIconChecked"></span>}
                                        color="primary"
                                        inputRef={register}
                                        checked={state.heartRateStable}
                                      />
                                    }
                                    label="Stable"
                                    name="heartRateStable"
                                    disabled={true}
                                    onChange={handleAOHeartRateChange}
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        icon={<span className="checkboxIcon"></span>}
                                        checkedIcon={<span className="checkboxIconChecked"></span>}
                                        color="primary"
                                        inputRef={register}
                                        checked={state.heartRateInconsistent}
                                      />
                                    }
                                    label="Variable"
                                    name="heartRateInconsistent"
                                    disabled={true}
                                    onChange={handleAOHeartRateChange}
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        icon={<span className="checkboxIcon"></span>}
                                        checkedIcon={<span className="checkboxIconChecked"></span>}
                                        color="primary"
                                        inputRef={register}
                                        checked={state.heartRateIncreasing}
                                      />
                                    }
                                    label="Increasing"
                                    name="heartRateIncreasing"
                                    disabled={true}
                                    onChange={handleAOHeartRateChange}
                                  />
                                </div>
                                {state.heartRateIncreasing ? (
                                  <div>
                                    <RadioGroup
                                      aria-label="heartRateIncreasingDecision"
                                      name="heartRateIncreasingDecision"
                                      className="flexer"
                                      row
                                      value={state.heartRateIncreasingDecision}
                                      onChange={handleValueChange}
                                    >
                                      <span className="hr-pt">It appears that the HR was increasing (sign of fatigue or discomfort)</span>
                                      <FormControlLabel
                                        control={<Radio color="primary" name="heartRateIncreasingDecision" id="heartRateIncreasingConfirm" value="confirm" />}
                                        inputRef={register}
                                        label="Confirm"
                                        className="hr-pt-radio"
                                      />
                                      <FormControlLabel
                                        control={<Radio color="primary" name="heartRateIncreasingDecision" id="heartRateIncreasingDeny" value="deny" />}
                                        inputRef={register}
                                        label="Deny"
                                        className="hr-pt-radio"
                                      />
                                      {state.heartRateIncreasingDecision === "deny" ? (
                                        <OutlinedInput
                                          color="primary"
                                          placeholder="Enter reason"
                                          name="heartRateIncreasingDecisionDenyNote"
                                          inputRef={register}
                                          value={state.heartRateIncreasingDecisionDenyNote}
                                          className="textInputFull"
                                          autoComplete="off"
                                          onChange={handleValueChange}
                                        />
                                      ) : (
                                        <></>
                                      )}
                                      {errors.heartRateIncreasingDecision?.message != null ? (
                                        <div style={{ marginLeft: "0px" }} className="invalidFeedback">
                                          {errors.heartRateIncreasingDecision?.message} <br />
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                      {errors.heartRateIncreasingDecisionDenyNote?.message != null ? (
                                        <div style={{ marginLeft: "0px" }} className="invalidFeedback">
                                          {errors.heartRateIncreasingDecisionDenyNote?.message} <br />
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </RadioGroup>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </FormGroup>
                            </div>
                            <div className="flexer">
                              <FormGroup>
                                <FormLabel component="legend">Posture</FormLabel>
                                <div className="flexer">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="symmetricalPostureChecked"
                                        inputRef={register}
                                        checked={state.symmetricalPostureChecked}
                                        icon={<span className="checkboxIcon"></span>}
                                        checkedIcon={<span className="checkboxIconChecked"></span>}
                                        color="primary"
                                        onChange={(event) => handleAOPostureChange(event)}
                                      />
                                    }
                                    label="Symmetrical"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="asymmetricalPostureChecked"
                                        inputRef={register}
                                        checked={state.asymmetricalPostureChecked}
                                        icon={<span className="checkboxIcon"></span>}
                                        checkedIcon={<span className="checkboxIconChecked"></span>}
                                        color="primary"
                                        onChange={(event) => handleAOPostureChange(event)}
                                      />
                                    }
                                    label="Asymmetrical (describe)"
                                  />
                                </div>
                                {state.asymmetricalPostureChecked ? (
                                  <OutlinedInput
                                    color="primary"
                                    placeholder="Describe here"
                                    name="asymmetricalNote"
                                    inputRef={register}
                                    value={state.asymmetricalNote}
                                    className="textInputFull"
                                    autoComplete="off"
                                    onChange={handleValueChange}
                                  />
                                ) : (
                                  <></>
                                )}
                                <div style={{ marginLeft: "0px" }} className="invalidFeedback">
                                  {errors.postureAsymmetrical?.message}
                                  {errors.posture?.message} <br />
                                </div>
                              </FormGroup>
                            </div>
                            <div>
                              <FormGroup>
                                <FormLabel component="legend">Performance</FormLabel>
                                <div className="flexer">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="performanceFineMotorDifficulty"
                                        checked={state.performanceFineMotorDifficulty}
                                        icon={<span className="checkboxIcon"></span>}
                                        checkedIcon={<span className="checkboxIconChecked"></span>}
                                        color="primary"
                                        inputRef={register}
                                        onChange={handleCheckboxChange}
                                      />
                                    }
                                    label="Difficulty with fine motor activities"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="performanceFrequentCues"
                                        checked={state.performanceFrequentCues}
                                        icon={<span className="checkboxIcon"></span>}
                                        checkedIcon={<span className="checkboxIconChecked"></span>}
                                        color="primary"
                                        inputRef={register}
                                        onChange={handleCheckboxChange}
                                      />
                                    }
                                    label="Frequent cues to maintain position"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="difficultyMaintainingPositions"
                                        checked={state.difficultyMaintainingPositions}
                                        icon={<span className="checkboxIcon"></span>}
                                        checkedIcon={<span className="checkboxIconChecked"></span>}
                                        color="primary"
                                        inputRef={register}
                                        onChange={handleCheckboxChange}
                                      />
                                    }
                                    label="Difficulty maintaining positions (describe)"
                                  />
                                </div>
                                {state.difficultyMaintainingPositions ? (
                                  <OutlinedInput
                                    color="primary"
                                    placeholder="Describe here"
                                    name="performanceDifficultyNotes"
                                    className="textInputFull"
                                    autoComplete="off"
                                    inputRef={register}
                                    value={state.performanceDifficultyNotes}
                                    onChange={handleValueChange}
                                  />
                                ) : (
                                  <></>
                                )}
                                <div style={{ marginLeft: "0px" }} className="invalidFeedback">
                                  {errors.performance?.message}
                                  {errors.performanceDifficulty?.message} <br />
                                </div>
                              </FormGroup>
                            </div>
                          </div>
                        </AccordionDetails>
                      </StyledAccordion>
                      <ParticipantReports
                        datasetName="reachAbove"
                        register={register}
                        data={prevData}
                        errors={errors}
                        setValue={setValue}
                        assessmentID={pefaAssessment.id}
                        dataKey={dataStorePageKeys.PT_ABOVE}
                        handleDiscomfortReported={discomfortReportedChange}
                      />
                      <Card variant="outlined" className="toleranceOutlinedBox">
                        <Typography variant="h2" className="toleranceHeading">
                          <img src={StarIcon} alt="Success" />
                          Tolerance Rating
                        </Typography>
                        <RadioGroup className="toleranceRatingGroup" name="toleranceRating" value={state.toleranceRating} onChange={handleValueChange}>
                          <div className="flexer">
                            <FormControlLabel
                              control={<Radio color="primary" name="toleranceRating" value="N" />}
                              className="RadioAgreementTolerance"
                              inputRef={register}
                              label="N"
                              disabled={true}
                            />
                            <FormControlLabel
                              control={<Radio color="primary" name="toleranceRating" value="O" />}
                              className="RadioAgreementTolerance"
                              inputRef={register}
                              label="O"
                              disabled={true}
                            />
                            <FormControlLabel
                              control={<Radio color="primary" name="toleranceRating" value="F" />}
                              className="RadioAgreementTolerance"
                              inputRef={register}
                              label="F"
                              disabled={true}
                            />
                            <FormControlLabel
                              control={<Radio color="primary" name="toleranceRating" value="X" />}
                              className="RadioAgreementTolerance"
                              inputRef={register}
                              label="X"
                              disabled={true}
                            />
                          </div>
                          <div style={{ marginLeft: "10px", marginBottom: "10px" }} className="invalidFeedback">
                            {errors.testNotPerformed?.message}
                          </div>
                        </RadioGroup>
                      </Card>
                    </form>
                  </Grid>
                </Grid>
                <Grid item container xs={3} className="RightCol">
                  <ProfileCard
                    firstName={pefaAssessment.pefArequest.worker.firstName}
                    lastName={pefaAssessment.pefArequest.worker.lastName}
                    participantAgeYears={participantAgeYears}
                    currentHeartRate={state.currentHeartRate}
                  />
                  <Timer
                    countUp={true}
                    timeTarget={"3:00"}
                    timerHandler={handleTimerEvents}
                    timerStoppedHandler={handleTimerStopped}
                    timerRemoteTrigger={state.timerRemoteTrigger}
                    timerRemoteResumeTrigger={state.timerRemoteResumeTrigger}
                  />
                  <NotesModal pageKey={dataStorePageKeys.PT_ABOVE} pefaID={pefaAssessment.id} />
                  <InTestMedicalClearanceModal
                    firstName={referralDetails.firstName}
                    lastName={referralDetails.lastName}
                    pageKey={dataStorePageKeys.PT_ABOVE}
                    pefaID={pefaAssessment.id}
                    medicalPractice={medicalHistoryData.medicalPractitioner}
                    medicalPractitioner={medicalHistoryData.medicalPractice}
                  />
                </Grid>
              </Grid>
            </Container>
            <FormNavigator
              forwardTarget={nextDemandPage.pageKey}
              handleSubmit={handleSubmit(submitForm)}
              warning="Stop the test if a participant reports increasing discomfort"
              disabledNextButton={
                endAssessment &&
                endAssessment.endAssessmentReason !== "" &&
                endAssessment.endAssessmentNotes !== "" &&
                endAssessment.endAssessmentPage === dataStorePageKeys.PT_ABOVE
              }
              selectedKey={dataStorePageKeys.PT_ABOVE}
            />
          </Grid>
        </div>
      </div>
    );
  })
);

export default withAppInsights(ptReachAbove);

/* eslint-disable prettier/prettier */
import { inject, observer } from "mobx-react";
import React, { useState } from "react";
import { Root } from "../../../stores";
import Grid from "@material-ui/core/Grid";
import { CompleteFooterL, CompleteFooterR } from "../../../assets/images/";
import { useHistory } from "react-router-dom";
import { DASHBOARD } from "../../../routes/paths";
import Rating, { IconContainerProps } from "@mui/material/Rating";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import apiService from "../../../services/ApiService";
import { Button, InputAdornment, OutlinedInput, FormControlLabel, RadioGroup, Radio } from "@material-ui/core";

interface DoneProps {
  rootStore?: Root;
}

const labels: { [index: string]: string } = {
  1: "Useless",
  2: "Poor",
  3: "Ok",
  4: "Good",
  5: "Excellent"
};
let rate = "How was your experience with the App today?";
const thanks = "Thank you for your feedback!";
let clicked = false;
const content = {
  comment: "",
  ratingValue: 0
};
const AppRating = inject("rootStore")(
  observer((props: DoneProps) => {
    const [state, setState] = useState({
      clicked: false,
      ratingValue: 0,
      comment: "",
      call: false
    });
    const bookingID = props.rootStore?.pefaStore.selectedPefa[0].id;
    const history = useHistory();
    const [value, setValue] = React.useState<number | null>();
    const [comment, setComment] = React.useState<string>();
    const handleDoneClicked = (): void => {
      rate = thanks;
      clicked = true;
      if (state.call) {
        state.comment = "Yes Call - " + state.comment;
      }
      content.comment = state.comment;
      content.ratingValue = state.ratingValue;
      if (!content.comment) {
        content.comment = "No Comment";
      }
      apiService.submitRating(bookingID, content);
      const timer = setTimeout(() => {
        history.push(DASHBOARD);
        clicked = false;
        rate = "How was your experience with the App today?";
        props.rootStore?.pefaStore.clearPefaData();
        props.rootStore?.dataStore.clearDataStore();
        props.rootStore?.dataStore.clearCheckIn();
        props.rootStore?.pefaStore.fetchPefas();
      }, 2000);
    };

    function getLabelText(value: number) {
      return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
    }
    function skip() {
      handleDoneClicked();
      history.push(DASHBOARD);
      clicked = false;
      rate = "How was your experience with the App today?";
      props.rootStore?.pefaStore.clearPefaData();
      props.rootStore?.dataStore.clearDataStore();
      props.rootStore?.dataStore.clearCheckIn();
      props.rootStore?.pefaStore.fetchPefas();
    }
    const StyledRating = styled(Rating)(({ theme }) => ({
      "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
        color: theme.palette.action.disabled
      }
    }));

    const customIcons: {
      [index: number]: {
        icon: React.ReactElement;
        label: string;
        value: number;
      };
    } = {
      1: {
        icon: <SentimentVeryDissatisfiedIcon color="error" fontSize="inherit" />,
        label: "1",
        value: 1
      },
      2: {
        icon: <SentimentDissatisfiedIcon color="error" fontSize="inherit" />,
        label: "2",
        value: 2
      },
      3: {
        icon: <SentimentSatisfiedIcon color="warning" fontSize="inherit" />,
        label: "3",
        value: 3
      },
      4: {
        icon: <SentimentSatisfiedAltIcon color="success" fontSize="inherit" />,
        label: "4",
        value: 4
      },
      5: {
        icon: <SentimentVerySatisfiedIcon color="success" fontSize="inherit" />,
        label: "5",
        value: 5
      }
    };
    const handleValueChange = (event: any) => {
      event.persist();
      setState((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
        comment: event.target.value
      }));
    };
    function IconContainer(props: IconContainerProps) {
      const { value, ...other } = props;
      return <span {...other}>{customIcons[value].icon}</span>;
    }
    return (
      <div>
        <Grid container spacing={1} style={{ height: "100vh" }}>
          <Grid
            container
            item
            xs={12}
            style={{
              textAlign: "center",
              display: "flex"
            }}
          >
            <div className="login">
              {!clicked ? (
                <>
                  <h1 className="PefaSentCopy" style={{ textAlign: "center", justifyContent: "center", display: "flex", alignItems: "center" }}>
                    <span>{rate}</span>
                  </h1>
                  <StyledRating
                    name="highlight-selected-only"
                    style={{ fontSize: "80px", justifyContent: "center" }}
                    value={value}
                    IconContainerComponent={IconContainer}
                    getLabelText={(value: number) => customIcons[value].label}
                    highlightSelectedOnly
                    onChange={(event, newValue) => {
                      setValue((state.ratingValue = newValue!));
                    }}
                  />
                {state.ratingValue === 1 || state.ratingValue === 2 || state.ratingValue === 3 ? (
                <>
                  <OutlinedInput
                    id={state.comment}
                    name="outlined-multiline-flexible"
                    onChange={handleValueChange}
                    placeholder="Please tell us why you feel that way"
                    multiline
                    fullWidth
                  />
                  <div className="flexer">
                    <p style={{ marginTop: "2%" }}>Do you want us to call you about the feedback?</p>
                    <RadioGroup aria-label="Do you want us to call you about the feedback?" name="Call" style={{ marginLeft: "5%", float: "right" }} className="" row>
                      <FormControlLabel
                        control={<Radio color="primary" name="Call" id="Yes" value="Yes" />}
                        label="Yes"
                        onChange={() => {
                          state.call = true;
                        }}
                      />
                      <FormControlLabel
                        control={<Radio color="primary" name="No" id="No" value="No" />}
                        label="No"
                        onChange={() => {
                          state.call = false;
                        }}
                      />
                    </RadioGroup>
                  </div>
                  </>
              ) : (
                <>
                </>
              )}
                  {value !== null && <Box sx={{ ml: 2 }}></Box>}
                </>
              ) : (
                <>
                <br />
                  <h1 className="PefaSentCopy" style={{ textAlign: "center", justifyContent: "center", display: "flex", alignItems: "center" }}>
                    <span>{thanks}</span>
                  </h1>
                </>
              )}
              <div>
              <Button color="primary" label="All Done" className="pefaSentButton" style={{ float: "Right", marginTop:"2%", position: "relative" }} variant="contained" onClick={skip}>
                All Done
              </Button>
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                bottom: "0px",
                overflow: "hidden",
                left: "40px"
              }}
            >
              <img src={CompleteFooterL} style={{ marginBottom: "-10px" }} alt="Logo" />
            </div>
            <div
              style={{
                position: "absolute",
                bottom: "0px",
                right: "140px",
                overflow: "hidden"
              }}
            >
              <img src={CompleteFooterR} style={{ marginBottom: "-150px" }} alt="Logo" />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  })
);

export default AppRating;

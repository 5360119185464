import React, { useState } from "react";
import { Typography, FormControl, FormControlLabel, Checkbox, Button, FormGroup, Grid, MenuItem, Select, TextField, Backdrop, Slide, Modal } from "@material-ui/core";
import { HelpIcon } from "../../assets/images";

import { inject, observer } from "mobx-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

interface NotTestedProps {
  data?: any;
  notTestedSaveHandler?: any;
  forwardTarget?: any;
}

export enum notTestedSelectKeys {
  "SELECT" = "SELECT",
  "PARTICIPANT_DECLINED" = "PARTICIPANT_DECLINED",
  "ASSESSOR_DECISION" = "ASSESSOR_DECISION",
  "MEDICAL_RESTRICTION" = "MEDICAL_RESTRICTION",
  "EQUIPMENT_FAILURE" = "EQUIPMENT_FAILURE",
}

export enum notTestedSelectKeysFriendly {
  "SELECT" = "Select option from list",
  "PARTICIPANT_DECLINED" = "Participant declined",
  "ASSESSOR_DECISION" = "Assessor decision",
  "MEDICAL_RESTRICTION" = "Medical restriction",
  "EQUIPMENT_FAILURE" = "Equipment failure or limitation",
}

const notTestedValidation = yup.object().shape({
  notTestedReason: yup.string().test("notTestedReason", "Please choose a valid not tested reason", function () {
    return this.parent.notTestedReason && this.parent.notTestedReason !== notTestedSelectKeys.SELECT;
  }),
  notTestedNotes: yup.string().required("Please provide extra notes on why the test was not performed"),
});

const NotTested = inject("rootStore")(
  observer((props: NotTestedProps) => {
    const { register, handleSubmit, errors } = useForm({
      resolver: yupResolver(notTestedValidation),
    });

    const [state, setState] = useState({
      notTested: props.data ? !!props.data.notTestedReason || !!props.data.extraNotes : false,

      notTestedModalOpen: false,
      //Check that there is incoming data, if there is actual data, use it.if the incoming data is an empty string, default to SELECT. If there is nothing, default to SELECT.
      notTestedReason: props.data ? (props.data.notTestedReason ? (props.data.notTestedReason as notTestedSelectKeys) : notTestedSelectKeys.SELECT) : notTestedSelectKeys.SELECT,
      notTestedNotes: props.data ? props.data.notTestedNotes : "",
    });

    const handleCloseModal = (): void => {
      setState((prevState) => ({
        ...prevState,
        notTestedModalOpen: false,
        notTested: props.data ? !!props.data.notTestedReason || !!props.data.extraNotes : false,
      }));
    };

    const handleOpenModal = (): void => {
      setState((prevState) => ({
        ...prevState,
        notTestedModalOpen: true,
        notTested: true,
      }));
    };

    const handleSaveNotTestedClicked = (): void => {
      const { notTestedReason, notTestedNotes } = state;
      props.notTestedSaveHandler({ ...props.data, notTestedReason, notTestedNotes }, props.forwardTarget);
    };

    return (
      <>
        <div className="notTestedHeader">
          <FormControlLabel
            control={
              <Checkbox
                id="notTested"
                name="notTested"
                icon={<span className="checkboxIcon"></span>}
                checkedIcon={<span className="checkboxIconChecked"></span>}
                color="primary"
                checked={state.notTested}
              />
            }
            onClick={handleOpenModal}
            label="Not Tested"
          />
        </div>

        <Modal
          aria-labelledby="modal-title"
          open={state.notTestedModalOpen}
          onClose={handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Slide direction="up" in={state.notTestedModalOpen} mountOnEnter unmountOnExit>
            <div className="modalPopFull">
              <Button className="hideBtn-end" onClick={handleCloseModal}>
                Close
              </Button>
              <form>
                <div className="notesModalText">
                  <Typography variant="h2" className="helpHeader">
                    <img src={HelpIcon} alt="Notes Icon" />
                    Not Tested
                  </Typography>
                  <Typography variant="caption" className="helpCaption">
                    Please advise why this activity was not tested
                  </Typography>
                  <FormGroup className="formGroupOveride">
                    <Grid xs={12} item container className="StrengthTestContainer">
                      <FormControl component="fieldset" className="fieldsetStrength">
                        <FormControl variant="outlined">
                          <Select
                            color="primary"
                            className="notTestedSelect"
                            value={state.notTestedReason}
                            onChange={(event) => {
                              setState((prevState) => ({
                                ...prevState,
                                notTestedReason: event.target.value as notTestedSelectKeys,
                              }));
                            }}
                          >
                            <MenuItem value={notTestedSelectKeys.SELECT}>{notTestedSelectKeysFriendly.SELECT}</MenuItem>
                            <MenuItem value={notTestedSelectKeys.PARTICIPANT_DECLINED}>{notTestedSelectKeysFriendly.PARTICIPANT_DECLINED}</MenuItem>
                            <MenuItem value={notTestedSelectKeys.ASSESSOR_DECISION}>{notTestedSelectKeysFriendly.ASSESSOR_DECISION}</MenuItem>
                            <MenuItem value={notTestedSelectKeys.MEDICAL_RESTRICTION}>{notTestedSelectKeysFriendly.MEDICAL_RESTRICTION}</MenuItem>
                            <MenuItem value={notTestedSelectKeys.EQUIPMENT_FAILURE}>{notTestedSelectKeysFriendly.EQUIPMENT_FAILURE}</MenuItem>
                          </Select>
                          <input type="text" style={{ visibility: "hidden" }} name={"notTestedReason"} value={state.notTestedReason} ref={register} />
                          <div className="invalid-feedback">{errors.notTestedReason?.message}</div>
                        </FormControl>
                        <FormGroup className="formGroupOveride">
                          <FormControlLabel
                            control={
                              <TextField
                                name="notTestedNotes"
                                multiline
                                fullWidth
                                variant="outlined"
                                className="outlineTextArea"
                                label="Enter notes here"
                                defaultValue={state.notTestedNotes}
                                onChange={(event) => {
                                  setState((prevState) => ({ ...prevState, notTestedNotes: event.target.value }));
                                }}
                              />
                            }
                            inputRef={register}
                            label=""
                          />
                          <div className="invalid-feedback">{errors.notTestedNotes?.message}</div>
                        </FormGroup>
                        <Button color="primary" variant="contained" className="saveNoteBtn" onClick={handleSubmit(handleSaveNotTestedClicked)}>
                          Save note
                        </Button>
                      </FormControl>
                    </Grid>
                  </FormGroup>
                </div>
              </form>
            </div>
          </Slide>
        </Modal>
      </>
    );
  })
);

export default NotTested;

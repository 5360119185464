import * as yup from "yup";

const programRecommendationsSchema = yup.object().shape({
  discomfortComment: yup.string().required("Please provide a suggested comment on participant discomfort."),
  gait: yup.boolean().test(
    "gait",
    "Please observe the participant's gait",

    function () {
      const { gaitChangesNoneChecked, gaitChangesObservedChecked } = this.parent;
      if (!gaitChangesNoneChecked && !gaitChangesObservedChecked) {
        return false;
      }
      return true;
    }
  ),
  gaitHasChanged: yup.bool().test("gaitHasChanged", "Please describe the change in the participant's gait.", function () {
    const { gaitChangesObservedChecked, gaitChangesObservedNote } = this.parent;
    if (gaitChangesObservedChecked && !gaitChangesObservedNote) {
      return false;
    }
    return true;
  }),
  inflammation: yup.boolean().test("inflammation", "Please observe the participant for any signs of inflammation", function () {
    const { inflammationChangesNoneChecked, inflammationChangesObservedChecked } = this.parent;
    if (!inflammationChangesNoneChecked && !inflammationChangesObservedChecked) {
      return false;
    }
    return true;
  }),
  inflammationHasChanged: yup.bool().test("inflammationHasChanged", "Please describe the participant's signs of inflammation.", function () {
    const { inflammationChangesObservedChecked, inflammationChangesObservedNote } = this.parent;
    if (inflammationChangesObservedChecked && !inflammationChangesObservedNote) {
      return false;
    }
    return true;
  }),
  other: yup.boolean().test("other", "Please observe the participant for any other signs of discomfort", function () {
    const { otherChangesNoneChecked, otherChangesObservedChecked } = this.parent;
    if (!otherChangesNoneChecked && !otherChangesObservedChecked) {
      return false;
    }
    return true;
  }),
  otherHasChanged: yup.bool().test("otherHasChanged", "Please describe the other signs of participant discomfort", function () {
    const { otherChangesObservedChecked, otherChangesObservedNote } = this.parent;
    if (otherChangesObservedChecked && !otherChangesObservedNote) {
      return false;
    }
    return true;
  }),
  ppe: yup.boolean().test("ppe", "Please observe the participant and note any concerns with wearing or using PPE", function () {
    const { ppeAnticipatedConcernsNoneChecked, ppeAnticipatedConcernsObservedChecked } = this.parent;
    if (!ppeAnticipatedConcernsNoneChecked && !ppeAnticipatedConcernsObservedChecked) {
      return false;
    }
    return true;
  }),
  ppeHasChanged: yup.bool().test("ppeHasChanged", "Please describe the concerns of wearing or using PPE", function () {
    const { ppeAnticipatedConcernsObservedChecked, ppeAnticipatedConcernsNote } = this.parent;
    if (ppeAnticipatedConcernsObservedChecked && !ppeAnticipatedConcernsNote) {
      return false;
    }
    return true;
  }),

  postDiscomfort: yup.boolean().test("postDiscomfort", "Please observe the participant and note any concerns with Post PEFA Discomfort", function () {
    const { participantDeniedDiscomfortChecked, participantReportedDiscomfortChecked } = this.parent;
    if (!participantDeniedDiscomfortChecked && !participantReportedDiscomfortChecked) {
      return false;
    }
    return true;
  }),

  location: yup.bool().test("location", "Please provide the location of the discomfort.", function () {
    if (this.parent.participantReportedDiscomfortChecked && this.parent.programRecommendations_location === "") {
      return false;
    }

    return true;
  }),
  intensity: yup.bool().test("intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      this.parent.participantReportedDiscomfortChecked &&
      this.parent.programRecommendations_intensityVas === "" &&
      this.parent.programRecommendations_intensityMild === false &&
      this.parent.programRecommendations_intensityMod === false &&
      this.parent.programRecommendations_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),
  type: yup.bool().test("type", "Please describe the type of discomfort.", function () {
    if (
      this.parent.participantReportedDiscomfortChecked &&
      this.parent.programRecommendations_discomfortTypeAche === false &&
      this.parent.programRecommendations_discomfortTypeSharp === false &&
      this.parent.programRecommendations_discomfortTypeBurning === false &&
      this.parent.programRecommendations_discomfortTypeTightness === false &&
      this.parent.programRecommendations_discomfortTypePulling === false &&
      this.parent.programRecommendations_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),
  typeOther: yup.bool().test("typeOther", "Please describe the discomfort further.", function () {
    if (
      this.parent.participantReportedDiscomfortChecked &&
      this.parent.programRecommendations_discomfortTypeOther === true &&
      this.parent.programRecommendations_typeOther === ""
    ) {
      return false;
    }

    return true;
  }),
  status: yup.bool().test("status", "Please provide the status of the discomfort.", function () {
    if (
      this.parent.participantReportedDiscomfortChecked &&
      this.parent.programRecommendations_discomfortResolvedImmediately === false &&
      this.parent.programRecommendations_discomfortPersisting === false &&
      this.parent.programRecommendations_discomfortResolvedWithRest === false &&
      this.parent.programRecommendations_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
});

export default programRecommendationsSchema;

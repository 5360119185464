import { Root, RootStore } from ".";
import { onSnapshot } from "mobx-state-tree";
import { PefaStore, pefaStoreStates } from "./pefaStore";
import { UserStore } from "./userStore";
import { DataStore } from "./dataStore";
import localStoreService from "../services/LocalStoreService";
import { Constants } from "../helpers/Constants";

/**
 * Builds the initial rootStore MST structure.
 */
export const setupRootStore = async (): Promise<any> => {
  const storedRoot = (await localStoreService.getData(Constants.rootStoreKey)) as Root;

  const rootStore = RootStore.create({
    userStore: storedRoot?.userStore
      ? storedRoot?.userStore
      : UserStore.create({
          userDetails: undefined,
          userRole: "",
          userTokenExpiryTime: -1,
          userCurrentPath: "",
        }),
    pefaStore: storedRoot?.pefaStore
      ? storedRoot?.pefaStore
      : PefaStore.create({
          pefas: [],
          status: pefaStoreStates.EMPTY,
          //status: pefaStoreStates.PENDING,
          selectedPefa: (storedRoot?.pefaStore && storedRoot?.pefaStore.selectedPefa) || undefined,
        }),
    dataStore: storedRoot?.dataStore
      ? storedRoot?.dataStore
      : DataStore.create({
          pefaData: [],
          checkinData: [],
          pefaNotes: [],
          isProduction: Constants.environment().isProduction,
        }),
  });

  onSnapshot(rootStore, (snapshot) => {
    localStoreService.setData(Constants.rootStoreKey, snapshot);
    if (!rootStore.dataStore.isProduction) {
      //console.log("snapshot: ", snapshot);
    }
  });

  return { rootStore };
};

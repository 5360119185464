export const DASHBOARD = "/";
export const PEFASENT = "/complete";
export const LOGIN = "/login";
export const LOGINCALLBACK = "/authentication/login-callback";
export const LOGOUTCALLBACK = "/signout-callback-oidc";
export const MEDICALDETAILS = "/medical-details";
export const INFORMEDCONSENT = "/informed-consent";
export const REFERRALDETAILS = "/referral-details";
export const ASSESSMENTDETAILS = "/assessment-details";
export const ASSESSMENTPREVIEW = "/assessment-preview";
export const BASELINEMEASUREMENTS = "/baseline-measurements";
export const GENERALOBSERVATIONS = "/general-observations";
export const MSKSTANDING = "/musculoskeletal/standing";
export const MSKSITTING = "/musculoskeletal/sitting";
export const MSKLYING = "/musculoskeletal/lying";
export const PTREACHFORWARD = "/postural-tolerances/reach-forward";
export const PTREACHABOVE = "/postural-tolerances/reach-above";
export const PTSTOOP = "/postural-tolerances/stoop";
export const PTSQUAT = "/postural-tolerances/squat";
export const PTKNEEL = "/postural-tolerances/kneel";
export const DTREACHSIDETOSIDE = "/dynamic-tolerances/reach-side-to-side";
export const DTCRAWLING = "/dynamic-tolerances/crawling";
export const DTLADDERCLIMBING = "/dynamic-tolerances/ladder-climbing";
export const FITNESSTEST = "/fitness-test";
export const BALANCETEST = "/balance-test";
export const MHBENCHTOFLOOR = "/manual-handling/bench-to-floor";
export const MHBENCHTOBENCH = "/manual-handling/bench-to-bench";
export const MHBENCHTOSHOULDER = "/manual-handling/bench-to-shoulder";
export const MHBENCHTOABOVESHOULDER = "/manual-handling/bench-to-above-shoulder";
export const MHBILATERALCARRY = "/manual-handling/bilateral-carry";
export const MHSINGLECARRYLEFT = "/manual-handling/single-carry-left";
export const MHSINGLECARRYRIGHT = "/manual-handling/single-carry-right";
export const MHOPTIONALCOMMENTS = "/manual-handling/optional-comments";
export const PROGRAMRECOMMENDATIONS = "/program-recommendations";
export const PEFAREVIEW = "/pefa-review";
export const PEFAFINALISATION = "/finalisation";
export const APPRATING = "/app-rating";
export const FINALBPREADING = "/final-bp-reading";
export const ERROR = "/error";

//Note that this will always match in react-router.
export const ERROR404 = "*";

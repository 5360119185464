import { inject, observer } from "mobx-react";
import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { Root } from "../stores";
import ErrorIcon from "../assets/images/icon-error.svg";
import { Typography } from "@material-ui/core";
import { useHistory } from "react-router";
import { Modal } from "../components";

interface ErrorProps {
  rootStore?: Root;
}

const Error = inject("rootStore")(
  observer((props: ErrorProps) => {
    const history = useHistory();

    const [state, setState] = useState({
      isDiagnosisModalOpen: false,
    });

    const handleRefresh = () => {
      history.goBack();
    };

    const handleDiagnosis = () => {
      setState((prevState) => ({
        ...prevState,
        isDiagnosisModalOpen: !state.isDiagnosisModalOpen,
      }));
    };

    const handleCopy = async () => {
      await navigator.clipboard.writeText(JSON.stringify(props.rootStore, null, 2));
    };
    return (
      <>
        <div className="errorPage">
          <div className="errorContainer">
            <img src={ErrorIcon} alt="Error Icon" />
            <Typography className="errorTitle">We're Sorry</Typography>
            <Typography className="errorMessage">
              Something has gone wrong on our end, and we can't display the screen you are after. We are doing our best to get this issue resolved as soon as we can!
            </Typography>
            <div className="flexerCenter">
              <Button variant="outlined" className="outlinedButton mr-2" color="primary" onClick={handleDiagnosis}>
                View Diagnosis
              </Button>
              <Button variant="contained" color="primary" onClick={handleRefresh}>
                Refresh
              </Button>
            </div>
          </div>
        </div>

        <Modal isOpen={state.isDiagnosisModalOpen} handleClose={handleDiagnosis}>
          <div className="ModalSuper">
            <div className="ModalBox Error">
              <div className="flex">
                <Typography className="errorTitle">Diagnostic Information</Typography>
                <textarea className="ErrorModalTextArea">{JSON.stringify(props.rootStore, null, 2)}</textarea>

                <div>
                  <Button variant="outlined" className="outlinedButton" color="primary" onClick={handleCopy}>
                    Copy Diagnosis
                  </Button>
                  <Button variant="contained" color="primary" onClick={handleDiagnosis}>
                    Close
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  })
);

export default Error;

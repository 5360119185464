import * as yup from "yup";
import { SOB } from "./FitnessTestDataKeys";

const fitnessTestValidation = yup.object().shape({
  preTestHr: yup.boolean().test(
    "preTestHr",
    "Please enter a pre-test HR",

    function () {
      const { notTestedReason, preTestHeartRate } = this.parent;
      if (!notTestedReason && !preTestHeartRate) {
        return false;
      }
      return true;
    }
  ),
  postRestHr: yup.boolean().test(
    "postRestHr",
    "Please enter a post-rest HR",

    function () {
      const { notTestedReason, postRestHeartRate } = this.parent;
      //exclude undefined from our checks. This should only be a response when the field is not displayed.
      if (postRestHeartRate !== undefined) {
        if (!notTestedReason && !postRestHeartRate) {
          return false;
        }
      }
      return true;
    }
  ),
  postTestHr: yup.bool().test(
    "postTestHr",
    "Please complete the step test and enter a post-test heart rate.",

    function () {
      const { notTestedReason, timerStoppedReason, postTestHeartRate } = this.parent;
      //exclude undefined from our checks. This should only be a response when the field is not displayed.
      if (!notTestedReason && !timerStoppedReason && !postTestHeartRate) {
        return false;
      }
      return true;
    }
  ),
  toleranceRating: yup.string().test("toleranceRating", "Please enter a rating.", function () {
    const { notTestedReason, timeElapsedRaw, toleranceRating } = this.parent;

    if (!notTestedReason && parseInt(timeElapsedRaw) !== -1 && !toleranceRating) {
      return false;
    }

    return true;
  }),
  sec30: yup.boolean().test(
    "sec30",
    "Please enter a HR and select SOB",

    function () {
      const { notTestedReason, timeElapsedRaw, fitnessBpm030, fitnessSob030 } = this.parent;
      if (!notTestedReason && parseInt(timeElapsedRaw) + 30 >= 60 && (!fitnessBpm030 || fitnessSob030 === SOB.SELECT)) {
        return false;
      }
      return true;
    }
  ),
  sec100: yup.boolean().test(
    "sec100",
    "Please enter a HR and select SOB",

    function () {
      const { notTestedReason, timeElapsedRaw, fitnessBpm100, fitnessSob100 } = this.parent;
      if (!notTestedReason && parseInt(timeElapsedRaw) + 30 >= 90 && (!fitnessBpm100 || fitnessSob100 === SOB.SELECT)) {
        return false;
      }
      return true;
    }
  ),
  sec130: yup.boolean().test(
    "sec130",
    "Please enter a HR and select SOB",

    function () {
      const { notTestedReason, timeElapsedRaw, fitnessBpm130, fitnessSob130 } = this.parent;
      if (!notTestedReason && parseInt(timeElapsedRaw) + 30 >= 120 && (!fitnessBpm130 || fitnessSob130 === SOB.SELECT)) {
        return false;
      }
      return true;
    }
  ),
  sec200: yup.boolean().test(
    "sec200",
    "Please enter a HR and select SOB",

    function () {
      const { notTestedReason, timeElapsedRaw, fitnessBpm200, fitnessSob200 } = this.parent;
      if (!notTestedReason && parseInt(timeElapsedRaw) + 30 >= 150 && (!fitnessBpm200 || fitnessSob200 === SOB.SELECT)) {
        return false;
      }
      return true;
    }
  ),
  sec230: yup.boolean().test(
    "sec230",
    "Please enter a HR and select SOB",

    function () {
      const { notTestedReason, timeElapsedRaw, fitnessBpm230, fitnessSob230 } = this.parent;
      if (!notTestedReason && parseInt(timeElapsedRaw) + 30 >= 180 && (!fitnessBpm230 || fitnessSob230 === SOB.SELECT)) {
        return false;
      }
      return true;
    }
  ),
  sec300: yup.boolean().test(
    "sec300",
    "Please enter a HR and select SOB",

    function () {
      const { notTestedReason, timeElapsedRaw, fitnessBpm300, fitnessSob300 } = this.parent;
      if (!notTestedReason && parseInt(timeElapsedRaw) + 30 >= 180 && !fitnessBpm300 && fitnessSob300 === SOB.SELECT) {
        return false;
      }
      return true;
    }
  ),
  aoHR: yup.boolean().test(
    "aoHR",
    "Please indicate how a participant's recovering",

    function () {
      const { notTestedReason, fitnessHrRateRecoveringWell, fitnessHrRateRecoveringSlowly, fitnessHrRateNotRecovering } = this.parent;
      if (!notTestedReason && !fitnessHrRateRecoveringWell && !fitnessHrRateRecoveringSlowly && !fitnessHrRateNotRecovering) {
        return false;
      }
      return true;
    }
  ),
  participantReports: yup.bool().test("participantReports", "Please report the participant's discomfort.", function () {
    if (!this.parent.notTestedReason && this.parent.fitness_deniedDiscomfort === false && this.parent.fitness_reportedDiscomfort === false) {
      return false;
    }
    return true;
  }),
  location: yup.bool().test("location", "Please provide the location of the discomfort.", function () {
    if (!this.parent.notTestedReason && this.parent.fitness_reportedDiscomfort === true && this.parent.fitness_location === "") {
      return false;
    }

    return true;
  }),
  intensity: yup.bool().test("intensity", "Please describe the intensity of the discomfort.", function () {
    if (
      !this.parent.notTestedReason &&
      this.parent.fitness_reportedDiscomfort === true &&
      this.parent.fitness_intensityVas === "" &&
      this.parent.fitness_intensityMild === false &&
      this.parent.fitness_intensityMod === false &&
      this.parent.fitness_intensitySevere === false
    ) {
      return false;
    }

    return true;
  }),
  type: yup.bool().test("type", "Please describe the type of discomfort.", function () {
    if (
      !this.parent.notTestedReason &&
      this.parent.fitness_discomfortTypeAche === false &&
      this.parent.fitness_discomfortTypeSharp === false &&
      this.parent.fitness_discomfortTypeBurning === false &&
      this.parent.fitness_discomfortTypeTightness === false &&
      this.parent.fitness_discomfortTypePulling === false &&
      this.parent.fitness_discomfortTypeOther === false
    ) {
      return false;
    }

    return true;
  }),
  typeOther: yup.bool().test("typeOther", "Please describe the discomfort further.", function () {
    if (!this.parent.notTestedReason && this.parent.fitness_discomfortTypeOther === true && this.parent.fitness_typeOther === "") {
      return false;
    }

    return true;
  }),
  status: yup.bool().test("status", "Please provide the status of the discomfort.", function () {
    if (
      !this.parent.notTestedReason &&
      this.parent.fitness_discomfortResolvedImmediately === false &&
      this.parent.fitness_discomfortPersisting === false &&
      this.parent.fitness_discomfortResolvedWithRest === false &&
      this.parent.fitness_discomfortIncreasing === false
    ) {
      return false;
    }

    return true;
  }),
});

export default fitnessTestValidation;
